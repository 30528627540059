import Selector from "components/selector/selector";
import { AllCountriesListUSAOderFirst } from "configs/static-drop-down-options";
import React from "react";

const CountrySelector = (props: any) => {
  const { width = 200, onCountrySelect = () => {}, value = "" } = props;

  const _onUpdate = (country: string) => {
    if (country) {
      onCountrySelect(country);
    } else {
      onCountrySelect("");
    }
  };

  const allCountries = Object.keys(AllCountriesListUSAOderFirst).map(
    (shortForm: string) => ({
      value: shortForm,
      label: AllCountriesListUSAOderFirst[shortForm],
    })
  );

  return (
    <>
      <Selector
        width={width}
        placeholder="Select a country"
        options={allCountries}
        value={value}
        allowClear={true}
        onUpdate={_onUpdate}
      />
    </>
  );
};

export default CountrySelector;
