import React from "react";

import { Badge, Button } from "antd";

import ProCard from "@ant-design/pro-card";
import "../QuickAccessPage.scss";
import {
  UserOutlined,
  CreditCardOutlined,
  DollarOutlined,
  VideoCameraAddOutlined,
  FieldTimeOutlined,
  MailOutlined,
  MessageOutlined,
  PhoneOutlined,
  SendOutlined,
} from "@ant-design/icons";

import { ClientList } from "interfaces/client.int";
import { ClientStoreClass } from "stores/ClientStore";
import { PhoneCallStoreClass } from "stores/PhoneCallStore";
import { AuthStoreClass } from "stores/AuthStore";
import { inject, observer } from "mobx-react";
interface Props {
  clientStore: ClientStoreClass;
  handleClick: any;
  invoiceStore?: any;
  handleTabChange?: any;
  phoneCallStore?: PhoneCallStoreClass;
  setOpenCalender?: any;
  setOpenCalenderForAppointment?: any;
  setOpenSMSDrawer?: any;
  authStore?: AuthStoreClass;
}

const QAClientActionItems: React.FC<Props> = ({
  clientStore,
  handleClick,
  invoiceStore,
  handleTabChange,
  phoneCallStore,
  setOpenCalender,
  setOpenCalenderForAppointment,
  setOpenSMSDrawer,
  authStore,
}) => {
  const client: ClientList | undefined = clientStore.clientInfo;
  const filteredOpenBalaceInvoice = invoiceStore?.invoiceList?.filter((invoice: any) => {
    return invoice?.OpenBalance > 0;
  });
  return (
    <>
      <ProCard gutter={[8, 8]} wrap ghost className="ProCardBtn ActionItems">
        {/* <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
          <Button block className="ant-btn-default-orange">
            <DollarOutlined />
            <br />
            New Customer Deposit
          </Button>
        </ProCard> */}
        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} ghost className="ProCardBtn">
          <Button
            onClick={() => setOpenCalender(true)}
            block
            className="ant-btn-default-orange"
            style={{ color: "#92b0b6", borderColor: "#92b0b6" }}
          >
            <VideoCameraAddOutlined />
            <br />
            {authStore?.companySettings.JobDescriptorSingular
              ? "New " + authStore?.companySettings.JobDescriptorSingular
              : "New Session"}
          </Button>
        </ProCard>
        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} ghost className="ProCardBtn">
          <Button
            onClick={() => {
              phoneCallStore?.setSelectToggle(true);
            }}
            block
            className="ant-btn-default-orange"
            style={{ color: "#92b0b6", borderColor: "#92b0b6" }}
          >
            <PhoneOutlined />
            <br />
            New Call Record
          </Button>
        </ProCard>
        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
          <Button
            onClick={() => setOpenCalenderForAppointment(true)}
            block
            className="ant-btn-default-orange"
            style={{ color: "#92b0b6", borderColor: "#92b0b6" }}
          >
            <SendOutlined />
            <br />
            New Appointment
          </Button>
        </ProCard>

        {/* <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} ghost className="ProCardBtn">
          <Button block className="ant-btn-default-green">
            <MailOutlined />
            <br />
            Send Email
          </Button>
        </ProCard> */}
        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} ghost className="ProCardBtn">
          <Button
            block
            className="ant-btn-default-green"
            style={{ color: "#708D65", borderColor: "#708D65" }}
            onClick={() => {
              setOpenSMSDrawer(true);
            }}
          >
            <MessageOutlined />
            <br />
            Send Text
          </Button>
        </ProCard>
      </ProCard>
      <ProCard gutter={[8, 8]} style={{ padding: "8px 0 0" }} wrap ghost className="ProCardBtn">
        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} ghost className="ProCardBtn">
          <Button
            block
            onClick={() => handleClick(client, "client")}
            className="ant-btn-default-blue"
            style={{ color: "#526171", borderColor: "#526171" }}
          >
            <UserOutlined />
            <br />
            View Client
          </Button>
        </ProCard>
        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} ghost className="ProCardBtn">
          <Button
            onClick={() => handleTabChange("Invoices", "Invoices")}
            block
            className="ant-btn-default-blue"
            style={{ color: "#526171", borderColor: "#526171" }}
          >
            <CreditCardOutlined style={{ marginLeft: 8 }} />
            {filteredOpenBalaceInvoice?.length > 0 && (
              <Badge style={{ position: "absolute", bottom: "40px", left: "40px" }} dot />
            )}
            <br />
            Open-balance Invoices
          </Button>
        </ProCard>
        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} ghost className="ProCardBtn">
          <Button
            onClick={() => {
              // handleClick(client, "upcomingSessions");
              handleTabChange(
                authStore?.companySettings.JobDescriptorPlural
                  ? authStore?.companySettings.JobDescriptorPlural
                  : "Sessions",
                "upcoming" + authStore?.companySettings.JobDescriptorPlural
                  ? authStore?.companySettings.JobDescriptorPlural
                  : "Sessions"
              );
            }}
            block
            className="ant-btn-default-blue"
            style={{ color: "#526171", borderColor: "#526171" }}
          >
            <FieldTimeOutlined />
            <br />
            {authStore?.companySettings.JobDescriptorPlural
              ? "Upcoming " + authStore?.companySettings.JobDescriptorPlural
              : "Upcoming Sessions"}
          </Button>
        </ProCard>
        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} ghost className="ProCardBtn">
          <Button
            onClick={() =>
              handleTabChange(
                authStore?.companySettings.JobDescriptorPlural
                  ? authStore?.companySettings.JobDescriptorPlural
                  : "Sessions",
                "past" + authStore?.companySettings.JobDescriptorPlural
                  ? authStore?.companySettings.JobDescriptorPlural
                  : "Sessions"
              )
            }
            block
            className="ant-btn-default-blue"
            style={{ color: "#526171", borderColor: "#526171" }}
          >
            <FieldTimeOutlined />
            <br />
            {authStore?.companySettings.JobDescriptorPlural
              ? "Past " + authStore?.companySettings.JobDescriptorPlural
              : "Past Sessions"}
          </Button>
        </ProCard>
      </ProCard>
    </>
  );
};

export default inject("authStore")(observer(QAClientActionItems));
