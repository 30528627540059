import React, { useState, useRef } from "react";
import { Row, Col } from "antd";
import classNames from "classnames";

import DatePicker from "components/util/DatePicker";
import "./Input.scss";
import type { DateFieldProps } from "interfaces/formDate.int";

interface Props extends DateFieldProps {
  label?: string;
  prefix?: React.ReactNode;
}

const DateField: React.FC<Props> = React.forwardRef(
  ({ label, prefix, ...props }: Props, _ref): React.ReactElement => {
    const [focus, setFocus] = useState<boolean>(false);
    const inputRef = useRef<any>(null);

    const defaultInputProps: DateFieldProps = {
      bordered: false,
      style: {
        width: "100%",
      },
    };

    const newInputProps = {
      ...props,
      ...defaultInputProps,
    };

    const handleBlur = () => {
      setFocus(false);
    };

    const handleFocus = () => {
      setFocus(true);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    };

    const handleSelect = () => {
      if (!props?.showTime) {
        setTimeout(() => {
          inputRef.current?.blur();
        }, 100);
      }
    };

    const handleOnOk = () => {
      setTimeout(() => {
        inputRef.current?.blur();
      }, 100);
    };

    return (
      <Row
        className={classNames(props?.className, {
          "form-control isActive isFocus": true,
          isActive: props?.value,
          isFocus: focus,
          isDisabled: props?.disabled,
        })}
      >
        {prefix && (
          <Col className="form-control-label-col" onClick={() => inputRef.current?.focus()}>
            {prefix}
          </Col>
        )}
        <Col className="form-control-content-col">
          <div className="form-control-content">
            {label && (
              <label
                htmlFor={props?.id}
                className="form-control-label"
                // onClick={() => inputRef.current?.focus()}
              >
                {label}
              </label>
            )}
            {props?.defaultValue ? (
              <DatePicker
                onFocus={handleFocus}
                onBlur={handleBlur}
                onSelect={handleSelect}
                onOk={handleOnOk}
                open={focus}
                ref={inputRef}
                format={`${props.showTime ? "MM/DD/YYYY hh:mm A" : "MM/DD/YYYY"}`}
                {...defaultInputProps}
                defaultValue={props?.defaultValue}
              />
            ) : (
              <DatePicker
                onFocus={handleFocus}
                onBlur={handleBlur}
                onSelect={handleSelect}
                onOk={handleOnOk}
                open={focus}
                ref={inputRef}
                format={`${props.showTime ? "MM/DD/YYYY hh:mm A" : "MM/DD/YYYY"}`}
                {...newInputProps}
              />
            )}
          </div>
        </Col>
      </Row>
    );
  }
);

export default DateField;
