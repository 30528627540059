import React from "react";
import { Layout, Row, Col, Grid } from "antd";

import AccountInfo from "components/settings/AccountInfo";
import Help from "components/settings/Help";

import "./SettingsPage.scss";
import CustomHeader from "components/header/CustomHeader";

const { Content } = Layout;
const { useBreakpoint } = Grid;

const SettingsPage = () => {
  const screens = useBreakpoint();

  return (
    <Content className="main-content">
      <CustomHeader title="Settings" />
      <div className="main-content-pads">
        <Row gutter={screens.sm ? 20 : 10}>
          <Col
            xs={24}
            sm={12}
            style={{
              paddingBottom: screens.sm ? 20 : 10,
            }}
          >
            {/*Not needed right now, not offering sync <SyncInfo /> */}
            {/*<div
              style={{
                paddingTop: screens.sm ? 20 : 10,
              }}
            >*/}
            <AccountInfo />
            {/*</div>*/}
          </Col>
          <Col
            xs={24}
            sm={12}
            style={{
              paddingBottom: screens.sm ? 20 : 10,
            }}
          >
            <Help />
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default SettingsPage;
