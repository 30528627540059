import React from "react";
import { Input } from "antd";
import classnames from "classnames";
import { SearchOutlined } from "@ant-design/icons";

import type { InputProps } from "antd/lib/input";

interface Props extends InputProps {
  searchClass?: string;
  addonAfter?: React.ReactElement;
}

const SearchInput: React.FC<Props> = ({
  searchClass,
  addonAfter,
  ...props
}): React.ReactElement => {
  return (
    <div className={classnames("search-container", searchClass)}>
      <Input
        style={{ backgroundColor: "#F1F2F4" }}
        placeholder="Search here..."
        allowClear
        size="large"
        prefix={<SearchOutlined />}
        addonAfter={addonAfter}
        {...props}
      />
    </div>
  );
};

export default SearchInput;
