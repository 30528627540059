import React, { forwardRef, useEffect, useState } from "react";
import "./QAInvoicePrint.scss";
import dayjs from "dayjs";
import { Col, Row, Table, Typography } from "antd";
import { AuthStoreClass } from "stores/AuthStore";
import { inject, observer } from "mobx-react";
import { ClientStoreClass } from "stores/ClientStore";

const { Title, Paragraph } = Typography;

interface Props {
  invoiceDetails?: any;
  clientStore?: ClientStoreClass;
  authStore?: AuthStoreClass;
}

const QAInvoicePrint = forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => {
  const { invoiceDetails, clientStore, authStore } = props;

  const [billToClient, setBillToClient] = useState(false);

  const [pageTitle, setPageTitle] = useState("Invoice");
  const [printWord, setPrintWord] = useState("Invoice");

  useEffect(() => {
    if (invoiceDetails?.ClientKey != invoiceDetails?.BillToClientKey) setBillToClient(true);
    if (invoiceDetails?.IsUk) {
      setPageTitle("Sales Order");
      setPrintWord("Order");
    }
    if(invoiceDetails?.InvoiceType === 'SessionFee') {
      setPageTitle("Session Confirmation");
    }
  }, [invoiceDetails]);
  
  // print head table config

  const dataSourceHead = [
    {
      key: "1",
      Image: authStore?.companySettings.Logo,
      Address: [
        authStore?.companySettings.CompanyName,
        authStore?.companySettings.CompanyAdd1,
        authStore?.companySettings.CompanyAdd2,
        authStore?.companySettings.CompanyCity,
        authStore?.companySettings.CompanyState,
        authStore?.companySettings.CompanyCountry,
        authStore?.companySettings.CompanyEmail,
      ],
      Title: pageTitle,
    },
  ];

  const columnsHead = [
    {
      title: "Image",
      dataIndex: "Image",
      key: "Image",
      render: (Image: any) => <img src={`${Image}`} alt="" width={150} />,
      width: "10%",
    },
    {
      title: "Address",
      dataIndex: "Address",
      key: "Address",
      render: (Address: any) =>
        Address.map((item: any, index: any) => {
          return <p key={index} style={{ textTransform: 'capitalize' }}>{item}</p>;
        }),
    },
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
      render: (Title: any) => <h3 className="printInvoiceHeadTitle">{Title}</h3>,
    },
  ];

  // Invoice details table config
  const DetailArray = [
    invoiceDetails?.InvoiceDetails?.map(function (item: any) {
      return {
        Qty: item.Quantity,
        Description: item.Description,
        Image: item.ImageNumber,
        Price: item.Price,
        Extended: item.Extended,
      };
    }),
  ];

  const detailsData = DetailArray[0];

  const detailsColumns = [
    {
      title: "Qty",
      dataIndex: "Qty",
      key: "Qty",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Image",
      dataIndex: "Image",
      key: "Image",
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "Price",
    },
    {
      title: "Extended",
      dataIndex: "Extended",
      key: "Extended",
    },
  ];

  // Payment Table config

  const paymentData = [
    {
      Date: `${dayjs(invoiceDetails?.PaymentDueDate).format("MM/DD/YYYY")}`,
      Method: "Visa",
      Amount: `${invoiceDetails?.PaymentsTotal.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}`,
    },
  ];

  const paymentColumns = [
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "Method",
      dataIndex: "Method",
      key: "Method",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
  ];

  return (
    <Row ref={ref} className="printInvoice">
      <Table
        rowClassName={(record, index) => (index === 1 ? "table-row-first" : "")}
        className="printInvoice__Head"
        dataSource={dataSourceHead}
        columns={columnsHead}
        pagination={false}
        showHeader={false}
      />

      <Row className="printInvoiceRow">
        <Title className="printInvoiceTitle" level={5}>
          Client Information
        </Title>
      </Row>
      <Row className="printInvoiceRow clientInfo">
        {/* <Table className="printInvoice__Client" dataSource={clientData} columns={clientColumns} pagination={false} showHeader={false} /> */}
        <Col span={8}>
          {billToClient ? (
            <Paragraph className="paragraph">
              <strong>Sold to: </strong>
            </Paragraph>
          ) : null}
          <Paragraph className="paragraph">
            {clientStore?.clientInfo?.FirstName + " " + clientStore?.clientInfo?.LastName}
          </Paragraph>
          <Paragraph className="paragraph">{clientStore?.clientInfo?.AddressLine1}</Paragraph>
          <Paragraph className="paragraph">
            {clientStore?.clientInfo?.AddressLine2} {clientStore?.clientInfo?.AddressCity}
          </Paragraph>
          <Paragraph className="paragraph">
            {clientStore?.clientInfo?.AddressState} {clientStore?.clientInfo?.AddressPostcode}
          </Paragraph>
          <Paragraph className="paragraph">{clientStore?.clientInfo?.AddressCountry}</Paragraph>
          <Paragraph className="paragraph">{clientStore?.clientInfo?.Email}</Paragraph>
          {billToClient ? (
            <>
              <Paragraph className="paragraph">
                Client No: <strong>{clientStore?.clientInfo?.ClientNumber}</strong>
              </Paragraph>
              <Paragraph className="paragraph">
                Main Phone: <strong>{clientStore?.clientInfo?.PhoneNumber0}</strong>
              </Paragraph>
            </>
          ) : null}
        </Col>
        <Col span={8}>
          {billToClient ? (
            <>
              <Paragraph className="paragraph">
                <strong>${authStore?.companySettings?.JobDescriptorSingular ? authStore?.companySettings?.JobDescriptorSingular : "Session"} for: </strong>
              </Paragraph>
              <Paragraph className="paragraph">{invoiceDetails?.ShippingAddressName}</Paragraph>
              <Paragraph className="paragraph">{invoiceDetails?.ShippingAddressLine1}</Paragraph>
              <Paragraph className="paragraph">{invoiceDetails?.ShippingAddressLine2}</Paragraph>
              <Paragraph className="paragraph">
                {invoiceDetails?.ShippingAddressCity} {invoiceDetails?.ShippingAddressState}
              </Paragraph>
              <Paragraph className="paragraph">
                {invoiceDetails?.ShippingAddressCountry} {invoiceDetails?.ShippingAddressPostalCode}
              </Paragraph>
              <Paragraph className="paragraph">
                Client No:{" "}
                <strong>
                  {clientStore?.getClientDataFromList(
                    invoiceDetails?.BillToClientKey,
                    "ClientNumber"
                  )}
                </strong>{" "}
              </Paragraph>
              <Paragraph className="paragraph">
                Main Phone:{" "}
                <strong>
                  {clientStore?.getClientDataFromList(
                    invoiceDetails?.BillToClientKey,
                    "PhoneNumber0"
                  )}
                </strong>
              </Paragraph>
            </>
          ) : null}
        </Col>
        <Col span={8} className="textRight">
          <Paragraph className="paragraph">
            <label>{printWord} Number:</label> <strong>{invoiceDetails?.InvoiceNumber}</strong>
          </Paragraph>
          <Paragraph className="paragraph">
            <label>{printWord} Date:</label>{" "}
            <strong>{dayjs(invoiceDetails?.InvoiceDate).format("MM/DD/YYYY")}</strong>
          </Paragraph>
          <Paragraph className="paragraph">
            <label>{authStore?.companySettings?.JobDescriptorSingular ? authStore?.companySettings?.JobDescriptorSingular : "Session"} Number:</label> <strong>{invoiceDetails?.SessionNumber}</strong>
          </Paragraph>
          <Paragraph className="paragraph">
            <label>{authStore?.companySettings?.JobDescriptorSingular ? authStore?.companySettings?.JobDescriptorSingular : "Session"} Date:</label>{" "}
            <strong>{dayjs(invoiceDetails?.StartDateTime).format("MM/DD/YYYY")}</strong>
          </Paragraph>
          <Paragraph className="paragraph">
            <label>Approx. Delivery Date:</label>
            <strong>{dayjs(invoiceDetails?.ApproximateDeliveryDate).format("MM/DD/YYYY")}</strong>
          </Paragraph>
          {!billToClient ? (
            <Paragraph className="paragraph">
              <label>Client No:</label> <strong>{clientStore?.clientInfo?.ClientNumber}</strong>
            </Paragraph>
          ) : null}
          <Paragraph className="paragraph">
            <label>Sales Rep:</label> <strong>{invoiceDetails?.SalesRepKey}</strong>
          </Paragraph>
          {!billToClient ? (
            <Paragraph className="paragraph">
              <label>Main Phone:</label> <strong>{clientStore?.clientInfo?.PhoneNumber0}</strong>
            </Paragraph>
          ) : null}
        </Col>
      </Row>

      {billToClient ? (
        <>
          <Row className="printInvoiceRow">
            <Title className="printInvoiceTitle" level={5}>
              Shipping Information
            </Title>
          </Row>
          <Row className="printInvoiceRow">
            <Col span={24}>
              <Paragraph className="paragraph">{invoiceDetails?.ShippingAddressName}</Paragraph>
              <Paragraph className="paragraph">{invoiceDetails?.ShippingAddressLine1}</Paragraph>
              <Paragraph className="paragraph">{invoiceDetails?.ShippingAddressLine2}</Paragraph>
              <Paragraph className="paragraph">
                {invoiceDetails?.ShippingAddressCity}, {invoiceDetails?.ShippingAddressState}
              </Paragraph>
              <Paragraph className="paragraph">
                {invoiceDetails?.ShippingAddressCountry}, {invoiceDetails?.ShippingAddressPostalCode}
              </Paragraph>
            </Col>
          </Row>
        </>
      ) : null}

      {invoiceDetails?.PrintNotes ? (
        <Row className="printInvoiceRow">
          <Title className="printInvoiceTitle" level={5}>
            Note
          </Title>
          <Paragraph className="paragraph">{invoiceDetails?.Notes}</Paragraph>
        </Row>
      ) : null}

      <Row className="printInvoiceRow">
        <Title className="printInvoiceTitle" level={5}>
          Details
        </Title>
        <Table
          className="printInvoice__Details"
          dataSource={detailsData}
          columns={detailsColumns}
          pagination={false}
        />
      </Row>

      <Row className="printInvoiceRow">
        <Col span={13}>
          <Title className="printInvoiceTitle" level={5}>
            Payments
          </Title>
          <Table
            className="printInvoice__Details"
            dataSource={paymentData}
            columns={paymentColumns}
            pagination={false}
          />
        </Col>
        <Col span={1}></Col>
        <Col span={10}>
          <Row>
            <Col span={12}>
              <strong>Sub Total</strong>
            </Col>
            <Col span={12} className="textRight">
              {invoiceDetails?.Subtotal.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </Col>
          </Row>
          {invoiceDetails?.DiscountAmount ? (
            <Row>
              <Col span={12}>
                <strong>Discount</strong>
              </Col>
              <Col span={12} className="textRight">
                {invoiceDetails?.DiscountAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col span={12}>
              <strong>Sales Tax (6.875%)</strong>
            </Col>
            <Col span={12} className="textRight">
              {invoiceDetails?.Tax1Amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <strong>Shipping</strong>
            </Col>
            <Col span={12} className="textRight">
              {invoiceDetails?.ShippingAmount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <strong>{printWord} Total</strong>
            </Col>
            <Col span={12} className="textRight">
              {invoiceDetails?.Total.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <strong>Total Payments</strong>
            </Col>
            <Col span={12} className="textRight">
              {invoiceDetails?.Total.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <strong>Balance Due</strong>
            </Col>
            <Col span={12} className="textRight">
              {invoiceDetails?.OpenBalance.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </Col>
          </Row>
          <br />
          {invoiceDetails?.DiscountAmount ? (
            <Row>Total Discounts Included Are {invoiceDetails?.DiscountAmount.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}!</Row>
          ) : null}
        </Col>
      </Row>

      <Row className="printInvoiceRow">
        <Title className="printInvoiceTitle" level={5}>
          {printWord} Terms
        </Title>
        <Col span={24}>
          <Paragraph className="paragraph">Thank you!</Paragraph>
        </Col>
        <Col span={24} style={{ marginTop: 10 }}>
          <Paragraph className="paragraph">
            Terms: All photographs are (c) The Studio 2017; all rights reserved. Photographs may not
            be reproduced without writtenpermission from The Studio, Inc.
          </Paragraph>
        </Col>
        <Col span={24} style={{ marginTop: 20 }}>
          <Paragraph className="paragraph">
            Signature <span className="sign__line"></span> Date <span className="sign__line"></span>
          </Paragraph>
        </Col>
      </Row>

      <Row className="printInvoiceFooter">
        <Paragraph>{String(dayjs().format("ddd, MMMM DD , YYYY hh:mm A"))}</Paragraph>
      </Row>
    </Row>
  );
});

export default inject("authStore", "clientStore")(observer(QAInvoicePrint));
