import { List } from "antd";
import React from "react";
import InputField from "components/forms/items/InputField";
import "../QuickAccessPage.scss";
import SelectField from "components/forms/items/SelectField";
import { ClientStoreClass } from "stores/ClientStore";
import dayjs from "dayjs";
import DateField from "components/forms/items/DateField";
import { CalendarOutlined } from "@ant-design/icons";

interface Props {
  clientStore: ClientStoreClass;
  clientsArray: any;
  setClientsArray: any;
}

const QADetails: React.FC<Props> = ({ clientStore, clientsArray, setClientsArray }) => {
  // useEffect(() => {
  //   clientStore?.getOrganization();
  //   clientStore?.getClient(clientStore?.clientInfo?.Key);
  // }, []);

  //item, clientArray[item].value
  return (
    <div>
      <List itemLayout="horizontal" className="client-details-list">
        {Object.keys(clientsArray).map((item: string | number, index: any) => {
          return (
            <>
              {!clientsArray[item].isExtraData && (
                <List.Item key={index} style={{ padding: "8px 0" }}>
                  <List.Item.Meta
                    // avatar={<UserOutlined />}
                    title={
                      clientsArray[item].clientStatusDropdown === true ? (
                        <SelectField
                          style={{ width: "100%" }}
                          options={clientStore?.selectClientStatuses}
                          prefix={clientsArray[item].icon}
                          label={"Client Status"}
                          showSearch={false}
                          showArrow={false}
                          suffixIcon={<></>}
                          value={clientsArray[item].value}
                          onChange={(currentItem: any, obj: any) => {
                            setClientsArray({
                              ...clientsArray,
                              [item]: {
                                ...clientsArray[item],
                                value: obj.value,
                              },
                            });
                          }}
                        />
                      ) : clientsArray[item].organizationDropdown === true ? (
                        <SelectField
                          style={{ width: "100%" }}
                          options={clientStore?.selectOrganization}
                          prefix={clientsArray[item].icon}
                          label={"Organization Name"}
                          showSearch={false}
                          showArrow={false}
                          suffixIcon={<></>}
                          value={clientsArray[item].value}
                          onChange={(currentItem: any, obj: any) => {
                            setClientsArray({
                              ...clientsArray,
                              [item]: {
                                ...clientsArray[item],
                                value: obj.value,
                              },
                            });
                          }}
                        />
                      ) : clientsArray[item].isBirthday === true ? (
                        <DateField
                          label="Client Birthday"
                          prefix={<CalendarOutlined />}
                          suffixIcon={<></>}
                          allowClear={false}
                          format={"YYYY-MM-DD"}
                          value={
                            clientsArray[item].value !== "0001-01-01T00:00:00"
                              ? dayjs(clientsArray[item].value, "YYYY-MM-DD")
                              : undefined
                          }
                          placeholder={""}
                          onChange={(value: any) => {
                            setClientsArray({
                              ...clientsArray,
                              [item]: {
                                ...clientsArray[item],
                                value: dayjs(value.$d).format("YYYY-MM-DD"),
                              },
                            });
                          }}
                        />
                      ) : (
                        <>
                          <InputField
                            // onBlur =
                            className={
                              clientsArray[item].label === "First Name" ||
                              clientsArray[item].label === "Phone Number"
                                ? "multiFieldLeft"
                                : ""
                            }
                            prefix={clientsArray[item]?.icon}
                            label={clientsArray[item].label}
                            value={clientsArray[item].value}
                            onChange={(e) =>
                              setClientsArray({
                                ...clientsArray,
                                [item]: {
                                  ...clientsArray[item],
                                  value: e.target.value,
                                },
                              })
                            }
                          />

                          {clientsArray[item]?.extraData && (
                            <InputField
                              prefix={null}
                              className="multiFieldRight"
                              label={clientsArray[clientsArray[item].extraData]?.label}
                              value={clientsArray[clientsArray[item].extraData]?.value}
                              onChange={(e) => {
                                setClientsArray({
                                  ...clientsArray,
                                  [clientsArray[item].extraData]: {
                                    ...clientsArray[clientsArray[item].extraData],
                                    value: e.target.value,
                                  },
                                });
                                // console.log(e.target.value);
                              }}
                            />
                          )}
                          {clientsArray[item]?.extraSwitch}
                        </>
                      )
                    }
                    // description="Full Name"
                  />
                </List.Item>
              )}
            </>
          );
        })}
      </List>
    </div>
  );
};

export default QADetails;
