import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Button, Menu, Popover } from "antd";
import {
  MoreOutlined,
  CalendarOutlined,
  FilterOutlined,
  SyncOutlined,
  PlusOutlined,
  CarryOutOutlined,
} from "@ant-design/icons";

import type { ScheduleStoreClass } from "stores/ScheduleStore";
import ScheduleDropdownSelect from "../ScheduleDropdownSelect";

interface Props {
  handleOnClick?: (action: string) => void;
  isResync?: boolean;
  scheduleStore?: ScheduleStoreClass;
}

const ActionsScheduleMobile: React.FC<Props> = ({
  handleOnClick,
  isResync = false,
  scheduleStore,
}: Props): React.ReactElement => {
  const [showPopover, setShowPopover] = useState(false);
  const handleClick = (action: string) => {
    handleOnClick && handleOnClick(action);
  };

  const moreNavigation = (
    <Menu className="navigation-popover">
      <Menu.Item key="refresh" onClick={() => handleClick("handleResync")} icon={<SyncOutlined />}>
        Refresh
      </Menu.Item>
      <Menu.Item
        key="filter"
        onClick={() => handleClick("toggleFilterModal")}
        icon={<FilterOutlined />}
      >
        Filter
      </Menu.Item>
      <Menu.Item
        key="toggle"
        onClick={() =>
          handleClick(
            scheduleStore?.calendarMode === "month"
              ? "handleCalendarViewYear"
              : "handleCalendarViewMonth"
          )
        }
        icon={<CalendarOutlined />}
      >
        {scheduleStore?.calendarMode === "month" ? "Year View" : "Month View"}
      </Menu.Item>
      <Menu.Item
        key="today"
        onClick={() => handleClick("handleCalendarToday")}
        icon={<CarryOutOutlined />}
      >
        Today
      </Menu.Item>
    </Menu>
  );

  return (
    <Row align="middle">
      <Col style={{ paddingRight: 5 }}>
        <Popover
          content={<ScheduleDropdownSelect onClick={() => setShowPopover(false)} />}
          placement="bottomRight"
          overlayClassName="navigation-popover-container"
          visible={showPopover}
        >
          <Button
            icon={<PlusOutlined style={{ color: "#5DAEFF" }} />}
            disabled={isResync || scheduleStore?.isLoading}
            type="text"
            className="no-label"
            onClick={() => setShowPopover(true)}
          />
        </Popover>
      </Col>
      <Col>
        <Popover
          content={moreNavigation}
          trigger="focus"
          placement="bottomRight"
          overlayClassName="navigation-popover-container"
        >
          <Button
            icon={<MoreOutlined />}
            type="text"
            disabled={isResync || scheduleStore?.isLoading}
            className="no-label"
          />
        </Popover>
      </Col>
    </Row>
  );
};

export default inject("scheduleStore")(observer(ActionsScheduleMobile));
