import React from "react";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { Row, Col, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

import type { AuthStoreClass } from "stores/AuthStore";

import "./Profile.scss";

interface Props {
  authStore?: AuthStoreClass;
}

const Profile: React.FC<Props> = ({ authStore }: Props) => {
  const history = useHistory();

  const handleHistoryPush = () => {
    history.push("/settings");
    authStore?.setIsNavToggle();
  };

  return (
    <div
      className="navigation-profile"
      role="menuitem"
      onClick={handleHistoryPush}
    >
      <Row>
        <Col className="navigation-profile__avatar">
          <Avatar
            size={44}
            icon={<UserOutlined />}
            style={{ backgroundColor: "#5DAEFF" }}
          />
        </Col>
        <Col className="navigation-profile__details">
          <span className="name">{authStore?.authenticatedUser.FirstName}</span>
          <span className="title">My Profile</span>
        </Col>
      </Row>
    </div>
  );
};

export default inject("authStore")(observer(Profile));
