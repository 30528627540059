import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Card, Button, Modal, message, Select } from "antd";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ClockCircleOutlined } from "@ant-design/icons";

import type { ClockStoreClass } from "stores/ClockStore";
import type { AuthStoreClass } from "stores/AuthStore";

interface Props {
  clockStore?: ClockStoreClass;
  authStore?: AuthStoreClass;
}

dayjs.extend(customParseFormat);

const ClockInOut: React.FC<Props> = ({ clockStore, authStore }: Props): React.ReactElement => {
  const [isModalComfirmVisible, setIsModalComfirmVisible] = useState<boolean>(false);
  const [clockStart, setClockStart] = useState<Dayjs>(dayjs("00:00:00", "HH:mm:ss"));
  const [timeCode, setTimeCode] = useState<
    "Regular" | "Vacation/PTO" | "Holiday" | "Sick" | "Bereavement"
  >("Regular");
  var timeLapsed: NodeJS.Timeout;

  useEffect(() => {
    var difference: number = 0;

    if (clockStore?.isClockRunning && typeof clockStore?.localObservedClock !== "undefined") {
      let originalClock = dayjs.isDayjs(clockStore?.localObservedClock?.start)
        ? clockStore?.localObservedClock?.start
        : dayjs(clockStore?.localObservedClock?.start);
      difference = dayjs().diff(originalClock, "s");
      clockStore?.setClockTimer(dayjs("00:00:00", "HH:mm:ss").add(difference, "s"));
      setClockStart(dayjs("00:00:00", "HH:mm:ss").add(difference, "s"));

      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeLapsed = setInterval((): void => {
        difference++;
        setClockStart(clockStart.add(difference, "s"));
        clockStore?.setClockTimer(clockStart.add(difference, "s"));
      }, 1000);
    } else {
      setClockStart(dayjs("00:00:00", "HH:mm:ss"));
      clockStore?.setClockTimer(dayjs("00:00:00", "HH:mm:ss"));
      clearInterval(timeLapsed);
    }

    return () => clearInterval(timeLapsed);
  }, [clockStore?.isClockRunning]);

  const toggleConfirmModal = () => {
    setIsModalComfirmVisible(!isModalComfirmVisible);
  };

  const handleClockToggle = async () => {
    if (clockStore?.isClockRunning) {
      try {
        await clockStore
          ?.clockInOut({
            UserKey: authStore?.authenticatedUser?.UserKey,
            Action: "ClockOut",
            IncludeWeekSummary: true,
          })
          .then(() => {
            clockStore?.setClockToggle(false);
          });
      } catch (e) {
        console.log(e);
      }
      clockStore?.getClockList();
    } else {
      try {
        await clockStore
          ?.clockInOut({
            UserKey: authStore?.authenticatedUser?.UserKey,
            Action: "ClockIn",
            TimeCode: timeCode,
          })
          .then(() => {
            clockStore?.setClockToggle(true);
            message.success("Clock is running!");
          });
      } catch (e) {
        message.error("Sorry, something has gone wrong. Please try again later.");
      }
    }
  };

  const handleConfirmClockOut = () => {
    handleClockToggle();
    toggleConfirmModal();
  };

  const handleChange = (
    timeCode: "Regular" | "Vacation/PTO" | "Holiday" | "Sick" | "Bereavement"
  ) => {
    setTimeCode(timeCode);
  };

  return (
    <>
      <Card>
        <Select
          style={{ width: 120, marginBottom: "1rem" }}
          onChange={handleChange}
          defaultValue="Regular"
          options={[
            { label: "Regular", value: "Regular" },
            { label: "Vacation/PTO", value: "Vacation/PTO" },
            { label: "Holiday", value: "Holiday" },
            { label: "Sick", value: "Sick" },
            { label: "Bereavement", value: "Bereavement" },
          ]}
        />
        <Button
          icon={<ClockCircleOutlined />}
          disabled={clockStore?.isLoading}
          style={{ backgroundColor: "#16425B", borderColor: "#16425B", color: "white" }}
          danger={clockStore?.isClockRunning}
          className="btn-action"
          shape="round"
          block
          onClick={clockStore?.isClockRunning ? toggleConfirmModal : handleClockToggle}
          // onClick={() => { clockStore?.setSelectToggle(true) }}
        >
          Clock {clockStore?.isClockRunning ? "Out" : "In"}
        </Button>
      </Card>

      <Modal
        title="Clock Out"
        visible={isModalComfirmVisible}
        onOk={handleConfirmClockOut}
        onCancel={toggleConfirmModal}
      >
        <p>Are you sure you want to clock out?</p>
      </Modal>
    </>
  );
};

export default inject("clockStore", "authStore")(observer(ClockInOut));
