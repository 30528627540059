import { List } from "antd";
import React from "react";
import InputField from "components/forms/items/InputField";
import "../QuickAccessPage.scss";
import SelectField from "components/forms/items/SelectField";
import { SessionStoreClass } from "stores/SessionStore";
import TimeRangeField from "components/forms/items/TimeRangeField";
import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import type { RangeValue } from "rc-picker/lib/interface";
import dayjs, { Dayjs } from "dayjs";
import DateField from "components/forms/items/DateField";
import { UserStoreClass } from "stores/UserStore";
import { AuthStoreClass } from "stores/AuthStore"
import { inject, observer } from "mobx-react";
interface Props {
  sessionStore: SessionStoreClass;
  sessionArray: any;
  setSessionArray: any;
  userStore?: UserStoreClass;
  authStore?: AuthStoreClass;
}

const QASessionDetails: React.FC<Props> = ({
  sessionStore,
  sessionArray,
  setSessionArray,
  userStore,
  authStore
}) => {
  //item, clientArray[item].value
  return (
    <div>
      <List itemLayout="horizontal" className="client-details-list">
        {Object.keys(sessionArray).map((item: string | number, index: any) => {
          return (
            <List.Item key={index} style={{ padding: "8px 0" }}>
              <List.Item.Meta
                // avatar={<UserOutlined />}
                title={
                  sessionArray[item].sessionTypeDropdown === true ? (
                    <SelectField
                      style={{ width: "100%" }}
                      options={sessionStore?.selectSessionList}
                      prefix={sessionArray[item].icon}
                      label={`${authStore?.companySettings.JobDescriptorSingular ? authStore?.companySettings.JobDescriptorSingular : "Session"} Type`}
                      showSearch={false}
                      showArrow={false}
                      suffixIcon={<></>}
                      value={sessionArray[item].value}
                      onChange={(currentItem: any, obj: any) => {
                        setSessionArray({
                          ...sessionArray,
                          [item]: {
                            ...sessionArray[item],
                            value: obj.value,
                          },
                        });
                      }}
                    />
                  ) : sessionArray[item].sessionStatusDropdown === true ? (
                    <SelectField
                      style={{ width: "100%" }}
                      options={sessionStore?.selectSessionStatusList}
                      prefix={sessionArray[item].icon}
                      label={`${authStore?.companySettings.JobDescriptorSingular ? authStore?.companySettings.JobDescriptorSingular : "Session"} Status`}
                      showSearch={false}
                      showArrow={false}
                      suffixIcon={<></>}
                      value={sessionArray[item].value}
                      onChange={(currentItem: any, obj: any) => {
                        setSessionArray({
                          ...sessionArray,
                          [item]: {
                            ...sessionArray[item],
                            value: obj.value,
                          },
                        });
                      }}
                    />
                  ) : sessionArray[item].timeField === true ? (
                    <TimeRangeField
                      label="Time"
                      prefix={<ClockCircleOutlined />}
                      // disabled={isLoading}
                      className=""
                      suffixIcon={<></>}
                      allowClear={false}
                      format="hh:mm A"
                      use12Hours={true}
                      defaultValue={[sessionArray[item]?.value, sessionArray[item]?.value1]}
                      onCalendarChange={(values?: RangeValue<Dayjs>) => {
                        if (values) {
                          setSessionArray({
                            ...sessionArray,
                            [item]: {
                              ...sessionArray[item],
                              value: values[0],
                              value1: values[1],
                            },
                          });
                        }
                      }}
                    />
                  ) : sessionArray[item].dateField === true ? (
                    <DateField
                      label="Date"
                      prefix={<CalendarOutlined />}
                      disabled={true}
                      suffixIcon={<></>}
                      allowClear={false}
                      value={dayjs(sessionArray[item].value)}
                    />
                  ) : sessionArray[item].PrimaryPhotographerNameDropdown === true ? (
                    <SelectField
                      style={{ width: "100%" }}
                      options={userStore?.selectPhotographerList}
                      prefix={sessionArray[item].icon}
                      label={"Photographer"}
                      showSearch={false}
                      showArrow={false}
                      suffixIcon={<></>}
                      value={sessionArray[item].value}
                      onChange={(currentItem: any, obj: any) => {
                        setSessionArray({
                          ...sessionArray,
                          [item]: {
                            ...sessionArray[item],
                            value: obj.value,
                          },
                        });
                      }}
                    />
                  ) : (
                    <InputField
                      // onBlur =
                      // multiline={true}
                      // disabled={sessionArray[item]?.disabled}
                      maxLength={60}
                      prefix={sessionArray[item]?.icon}
                      label={sessionArray[item].label}
                      value={sessionArray[item].value}
                      onChange={(e) =>
                        setSessionArray({
                          ...sessionArray,
                          [item]: {
                            ...sessionArray[item],
                            value: e.target.value,
                          },
                        })
                      }
                    />
                  )
                }
                // description="Full Name"
              />

              {sessionArray[item]?.extraData && sessionArray[item]?.extraData}
            </List.Item>
          );
        })}
      </List>
    </div>
  );
};

export default inject("authStore")(observer(QASessionDetails));
