import React, { useEffect, useState } from "react";
import ActionsReports from "./ActionsReports";
import { Responsive, WidthProvider } from "react-grid-layout";
import "./react-grid-layout.css";
import "./react-resizable.css";
import PersonalDashboardPropertiesDrawerView from "components/drawers/DashboardReports/PersonalDashboardPropertiesDrawerView";
import "./PersonalDashboard.scss";
import PersonalPerformancePanelDrawerView from "components/drawers/DashboardReports/PersonalPerformancePanelDrawerView";
import { inject, observer } from "mobx-react";
import { Button, Card, Checkbox, MenuProps, message, Modal, Spin, Tabs } from "antd";
import { CheckCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import TabPane from "@ant-design/pro-card/lib/components/TabPane";
import ChartTabPane from "./ChartTabs/ChartTabPane";
import { useHistory } from "react-router-dom";
import { PersonalDashboardStoreClass } from "stores/PersonalDashboardStore";
import PerformancePanel from "./ChartTabs/PerformancePanel";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface Props {
  personalDashboardStore?: PersonalDashboardStoreClass;
}

const PersonalDashboardPage: React.FC<Props> = ({ personalDashboardStore }) => {

  const [removeGroupCheck, setRemoveGroupCheck] = useState<boolean>(false);
  const [groupCheckData, setGroupCheckData] = useState<any>([]);
  const [editChartValues, setEditChartValues] = useState<any>({});
  const [chartLength, setChartLength] = useState<number>(0);
  const [groupLength, setGroupLength] = useState<number>(0);
  const [isDashboardUpdated, setIsDashboardUpdated] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState<any>();

  const history = useHistory();


  const defaultProps = {
    className: "layout",
    items: 20,
    rowHeight: 40,
    width: 400,
    minH: 300,
    minW: 400,
    allowOverlap: false,
    onLayoutChange: (layout: any, layouts: any) => {
      // personalDashboardStore?.generateGridLayout();
      for (let item of layout) {
        if (item.w === 1 && item.h === 1) {
          item.w = 3;
          item.h = 10;
        }
      }
      personalDashboardStore?.setGridLayout(layout);
    }
  };

  useEffect(() => {
    if (isDashboardUpdated) {
      history.block(({ pathname }) => {
        setCurrentPath(pathname);
        setShowPrompt(true);
        return false;
      });
    } else {
      history.block(() => { });
    }
    return () => {
      history.block(() => { });
    };
  }, [history, isDashboardUpdated]);

  const onPromtOkHandler = () => {
    personalDashboardStore?.savePersonalDashboard().then((data: any) => {
      setShowPrompt(false);
      setTimeout(() => {
        if (currentPath === "/search") {
          history.push({
            pathname: currentPath,
            state: { searchType: "Clients" }
          });
        } else {
          history.push(currentPath);
        }
      }, 500);
      message.success('Dashboard Saved');
      setIsDashboardUpdated(false);
    });
  }

  const onPromtCancelHandler = () => {
    setShowPrompt(false);
    setTimeout(() => {
      if (currentPath === "/search") {
        history.push({
          pathname: currentPath,
          state: { searchType: "Clients" }
        });
      } else {
        history.push(currentPath);
      }
    }, 500);
    setIsDashboardUpdated(false);
  }

  useEffect(() => {
    personalDashboardStore?.updateGridLayout(personalDashboardStore?.gridLayout);
  }, [personalDashboardStore?.gridLayout])

  const onCheckHandler = (event: any, chartItem: any) => {
    let group_chart_data = personalDashboardStore?.groupedChartsData;
    if (event.target.checked) {
      if (!group_chart_data.find((item: any) => { return item == chartItem })) group_chart_data.push(chartItem);
    } else {
      group_chart_data = group_chart_data.filter((item: any) => { return item.Key != chartItem.Key });
    }
    personalDashboardStore?.setGroupedChartsData(group_chart_data)
  }

  const removeChartFromGroupHandler = (GroupKey: string, Key: string) => {
    let groupedCharts = personalDashboardStore?.personalDashboard.XMLSettings?.DockAreas?.find((item: any) => { return item.GroupKey === GroupKey });
    // console.log('groupedCharts', groupedCharts);
    if (groupedCharts.ObjectKeys.length <= 2) {
      personalDashboardStore?.removeTabsGroupedCharts(GroupKey);
    } else {
      personalDashboardStore?.updateTabGroupedCharts(GroupKey, Key);
    }
  }

  const addMoreChartsHandler = (chartArray: any) => {
    if (personalDashboardStore?.allowAddMore) {
      personalDashboardStore?.setAllowAddMore(false);
      if (personalDashboardStore?.groupedChartsData.length) {
        let availableGroups = personalDashboardStore?.tabsGroupedCharts;
        for (let item of availableGroups) {
          if (item === chartArray) {
            Array.prototype.push.apply(item, personalDashboardStore?.groupedChartsData);
          }
          personalDashboardStore?.removeGroupedChartsFromChartsData(item);
        }
        personalDashboardStore?.addMoreTabGroupedCharts(availableGroups);
      }
      personalDashboardStore?.setGroupedChartsData([]);
    } else {
      personalDashboardStore?.setAllowAddMore(true);
    }

  }

  useEffect(() => {
    personalDashboardStore?.getPersonalDashboard();
    personalDashboardStore?.setGroupedChartsData([]);
    setGroupCheckData([]);
    setEditChartValues({});
    // console.log('tabs', personalDashboardStore?.tabsGroupedCharts);
  }, []);

  const onGroupCheckHandler = async (checked: boolean, GroupKey: string) => {
    let chartGroupSelected = groupCheckData;
    if (checked) {
      chartGroupSelected.push(GroupKey);
    } else {
      chartGroupSelected = chartGroupSelected.filter((item: any) => { return item != GroupKey });
    }
    await setGroupCheckData(chartGroupSelected);
  }

  const handleMenuClick: MenuProps['onClick'] = e => {
    if (e.key === 'remove') {
      setIsDashboardUpdated(true);
      if (editChartValues.Type === 'group') {
        removeChartFromGroupHandler(editChartValues.GroupKey, editChartValues.Key);
      } else {
        personalDashboardStore?.removeChartData(editChartValues.GroupKey);
      }
    } else if (e.key === 'edit') {
      personalDashboardStore?.setSelectToggle(true);
    }
  };

  const handleEditButtonClick = (Type: string, GroupKey: number, Key: number) => {
    // debugger
    let editValues = {
      Type: Type,
      GroupKey: GroupKey,
      Key: Key
    }
    setEditChartValues(editValues);
  };

  useEffect(() => {
    personalDashboardStore?.getAllPrefereneces();
  }, []);

  const resetGroupAndRemoveHandler = () => {
    setGroupLength(0);
    setChartLength(0);
    if (personalDashboardStore?.personalDashboard?.XMLSettings?.DockAreas) {
      for (let area of personalDashboardStore?.personalDashboard?.XMLSettings?.DockAreas) {
        if (area.ObjectKeys.length > 1) {
          setGroupLength(groupLength + 1);
        } else if (area.ObjectKeys.length === 1) {
          setChartLength(chartLength + 1);
        }
      }
    }
  }

  useEffect(() => {
    resetGroupAndRemoveHandler();
    personalDashboardStore?.generateGridLayout();
  }, [personalDashboardStore?.personalDashboard?.XMLSettings?.DockAreas]);

  return (
    <div className="ant-layout-content main-content">
      <Spin spinning={personalDashboardStore?.isLoading} size={'default'}>
        <ActionsReports resetGroupAndRemoveHandler={resetGroupAndRemoveHandler} isDashboardUpdated={isDashboardUpdated} setIsDashboardUpdated={setIsDashboardUpdated} setEditChartValues={setEditChartValues} groupLength={groupLength} chartLength={chartLength} groupCheckData={groupCheckData} removeGroupCheck={removeGroupCheck} setRemoveGroupCheck={setRemoveGroupCheck} setGroupCheckData={setGroupCheckData} />
        {/* <Spin size={'large'} spinning={personalDashboardStore?.isLoading}> */}
        <div className="main-content-pads">
          <ResponsiveGridLayout onResize={() => setIsDashboardUpdated(true)} onDragStop={() => setIsDashboardUpdated(true)} layouts={{ lg: personalDashboardStore ? personalDashboardStore?.gridLayout : [], }} cols={{ lg: 12, md: 6, sm: 4, xs: 1, xxs: 1 }} breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 480 }} {...defaultProps}>
            {personalDashboardStore?.personalDashboard?.XMLSettings?.DockAreas?.map((chartArray: any, index: number) => {
              if (chartArray?.ObjectKeys?.length > 1 && chartArray?.ReportType != 1) {
                return (
                  <Card className="chartGroupTabs" key={chartArray.GroupKey}>
                    {removeGroupCheck ? <Checkbox onChange={(e) => { onGroupCheckHandler(e.target.checked, chartArray.GroupKey) }} className="groupCheckBox" /> : null}
                    <Tabs type="card" tabBarExtraContent={
                      <>
                        {personalDashboardStore?.chartData.length >= 1 ? <Button className="addMoreCharts" icon={personalDashboardStore?.allowAddMore ? <CheckCircleOutlined /> : <PlusCircleOutlined />} onClick={() => { addMoreChartsHandler(chartArray) }}></Button> : null}
                      </>
                    }>
                      {chartArray.ObjectKeys.map((chartItem: any, childIndex: any) => {
                        let title = personalDashboardStore?.dashboardReports.map((report: any) => {
                          if (report.Key === chartItem) return report.Name;
                        })
                        return (
                          <TabPane tab={title} key={'tab-' + childIndex}>
                            <ChartTabPane
                              chartData={chartItem}
                              groupKey={chartArray.GroupKey}
                              handleEditButtonClick={handleEditButtonClick}
                              handleMenuClick={handleMenuClick}
                              cardType={'group'}
                            />
                          </TabPane>
                        )
                      })}
                    </Tabs>
                  </Card>
                )
              } else if (chartArray?.ObjectKeys.length > 0 && chartArray?.ReportType != 1) {
                return (
                  <div key={chartArray.GroupKey} className="chartCard">
                    <ChartTabPane
                      chartData={chartArray?.ObjectKeys[0]}
                      groupKey={chartArray.GroupKey}
                      handleEditButtonClick={handleEditButtonClick}
                      handleMenuClick={handleMenuClick}
                      cardType={'chart'}
                      onCheckHandler={onCheckHandler}
                    />
                  </div>
                )
              } else if (chartArray?.ReportType === 1) {
                return (
                  <div key={chartArray?.GroupKey} className="chartCard">
                    <PerformancePanel
                      chartData={chartArray?.ObjectKeys[0]}
                      groupKey={chartArray.GroupKey}
                      handleEditButtonClick={handleEditButtonClick}
                      handleMenuClick={handleMenuClick}
                    />
                  </div>
                )
              } else {
                return null
              }
            })
            }
          </ResponsiveGridLayout>
        </div>
        {/* </Spin> */}
        <PersonalDashboardPropertiesDrawerView setIsDashboardUpdated={setIsDashboardUpdated} editChartValues={editChartValues} setEditChartValues={setEditChartValues} />
        <PersonalPerformancePanelDrawerView setIsDashboardUpdated={setIsDashboardUpdated} />
      </Spin>
      <Modal
        title={`Save Dashboard`}
        visible={showPrompt}
        onCancel={() => { onPromtCancelHandler() }}
        onOk={() => { onPromtOkHandler() }}
        cancelText="Discard"
        okText="Save Dashboard"
      >
        <p>Are you sure you want to leave and discard the changes?</p>
      </Modal>
    </div >
  );
};

export default inject("personalDashboardStore")(observer(PersonalDashboardPage));