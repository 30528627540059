import { CloseCircleOutlined, EditFilled, SettingTwoTone } from "@ant-design/icons";
import { Card, Checkbox, Dropdown, Empty, Menu, Space, Typography } from "antd";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ReportStoreClass } from "stores/ReportStore";
import BarChart from "../Charts/BarChart";
import ColumnChart from "../Charts/ColumnChart";
import PieChart from "../Charts/PieChart";

interface Props {
    reportStore?: ReportStoreClass;
    chartData?: any;
    groupKey?: string;
    handleEditButtonClick?: any;
    handleMenuClick?: any;
    cardType?: string;
    onCheckHandler?: any;
}

const { Title } = Typography;

const ChartTabPane: React.FC<Props> = ({ reportStore, chartData, groupKey, handleEditButtonClick, handleMenuClick, cardType, onCheckHandler }) => {
    const [reportData, setReportData] = useState<any>();

    const generateChartReport = async (Key: string) => {
        let chartData: any = {};
        chartData.Key = Key;
        let savedReport: any = {};
        await reportStore?.getReportData(Key).then(async (data: any) => {
            data.map((data: any) => {
                savedReport[data.name] = data.value;

            });
            chartData.Report = {
                report_key: chartData.Key,
                report_title: savedReport.report_title,
                data_type: reportStore?.dataTypeOptions.find((i: any) => i.enumvalue === Number(savedReport.data_type))?.label,
                chart_type: reportStore?.chartTypeOptions[Number(savedReport.chart_type)]?.value,
                chart_color1: reportStore?.chartColorOptions[Number(savedReport.chart_color1)]?.value,
                chart_color2: reportStore?.chartColorOptions[Number(savedReport.chart_color2)]?.value,
                data_comparison: reportStore?.dataComparisonOptions[Number(savedReport.data_comparison)]?.value,
                date_range: savedReport.date_range === "101" ? reportStore?.dateRangeOptions[20]?.value
                    : savedReport.date_range === "100" ? reportStore?.dateRangeOptions[19]?.value
                        : savedReport.date_range === "999" ? reportStore?.dateRangeOptions[21]?.value
                            : reportStore?.dateRangeOptions[Number(savedReport.date_range)]?.value,
                date_from: savedReport.date_from,
                date_to: savedReport.date_to,
                apply_filter: savedReport.apply_filter,
                auto_refresh_rate: savedReport.auto_refresh_rate
            }
            await reportStore?.getChartData(Key).then((data: any) => {
                chartData.ChartData = data;
            });
            setReportData(chartData);
        });
    }

    useEffect(() => {
        generateChartReport(chartData);
    }, [chartData, reportStore?.isSelectToggle]);

    const menu = (
        <Menu
            onClick={handleMenuClick}
        >
            <Menu.Item key="edit"><EditFilled /> Edit</Menu.Item>
            <Menu.Item key="remove"><CloseCircleOutlined /> Remove</Menu.Item>
        </Menu>
    );

    return (
        <Card
            className="chartCard"
            title={
                cardType === 'group' ?
                    <Dropdown overlay={menu} trigger={['click']}>
                        <a onClick={() => { handleEditButtonClick(cardType, groupKey, chartData) }}>
                            <SettingTwoTone />
                        </a>
                    </Dropdown>
                    :
                    <Space className="chartCardHead">
                        <Checkbox onChange={(e) => { e.stopPropagation(); onCheckHandler(e, chartData) }} style={{ zIndex: 10 }} className={`${reportStore?.allowGrouping || reportStore?.allowAddMore ? 'showCheck' : 'hideCheckbox'}`} />
                        <Title level={5}>{reportData?.Report?.report_title}</Title>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <a onClick={() => { handleEditButtonClick(cardType, groupKey, chartData) }}>
                                <SettingTwoTone />
                            </a>
                        </Dropdown>
                    </Space>
            }
        >
            {/* <Spin spinning={loading} size={'small'}> */}
            {reportData ?
                <>
                    {reportData?.Report?.chart_type === 'Bar' ?
                        <BarChart chartData={reportData} />
                        : reportData?.Report?.chart_type === 'Column' ?
                            <ColumnChart chartData={reportData} />
                            :
                            <PieChart chartData={reportData} />
                    }
                </>
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {/* </Spin> */}
        </Card >
    )
}

export default inject("reportStore")(observer(ChartTabPane));