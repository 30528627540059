import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { inject, observer } from "mobx-react";
import dayjs from "dayjs";
import {
  Row,
  Col,
  Card,
  Image,
  Layout,
  Typography,
  Button,
  Select,
  Radio,
  Skeleton,
  Descriptions,
  Result,
  Form,
  Tooltip,
  Alert,
  Popconfirm,
  message,
} from "antd";

import "./SessionInfoPage.scss";

import type { SessionStoreClass } from "stores/SessionStore";
import type { AuthStoreClass } from "stores/AuthStore";

import { PhotographerSchedule, NotCompletedResult, SessionDetail } from "interfaces/session.int";

import {
  CalendarOutlined,
  ShoppingCartOutlined,
  ClockCircleOutlined,
  QrcodeOutlined,
  PhoneOutlined,
  InteractionOutlined,
  EnvironmentOutlined,
  CheckSquareOutlined,
  MessageOutlined,
  UsergroupAddOutlined,
  FieldTimeOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

interface Props {
  sessionStore?: SessionStoreClass;
  authStore?: AuthStoreClass;
}

const SessionInfoPage: React.FC<Props> = ({ sessionStore, authStore }) => {
  const { Content } = Layout;
  const { Title } = Typography;

  // A custom hook that builds on useLocation to parse the query string for you.
  const useQueryParams = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQueryParams();

  // let isLoading = false;
  let scheduleDate = query.get("scheduleDate") || "";
  let authToken = query.get("authToken") || "7e51871c904d4efbaffd3a0e6609e0b7";
  let accountName = query.get("accountName");
  // let accountName = query.get("accountName") || "life365";
  let defaultSelected = sessionStore?.photographerSessionInfo?.SessionKey || "";
  let messageText =
    sessionStore?.photographerSessionInfo?.OnMyWayTextMessageBody || "On My Way Text Message";
  const [sessionCompleted, setSessionCompleted] = useState<"Yes" | "No">("Yes");
  const [incompleteReason, setIncompleteReason] = useState<any>("");
  const [latitude, setLatitude] = useState<string>("");
  const [longitude, setLongitude] = useState<string>("");
  // const [lastLocationTime, setLastLocationTime] = useState<string>("");
  useEffect(() => {
    // if (authStore.authErrorMessage.length > 0) {
    //   message.error(authStore.authErrorMessage);
    // }
    if (accountName) {
      sessionStore?.setPhotographerAPIAccountName(accountName);
    }
    if (authToken) {
      sessionStore?.getPhotographerSchedule(scheduleDate, authToken);
      _getGeoLocation();
    }
    // defaultSelected = sessionStore?.photographerSessionInfo?.SessionKey || "" ;
  }, []);

  const _getGeoLocation = async () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLatitude(String(position.coords.latitude));
      setLongitude(String(position.coords.longitude));
    });
    // console.log(latitude + " - " + longitude);
    if (defaultSelected)
      sessionStore?.getPhotographerSessionInfo(defaultSelected, authToken, latitude, longitude);
  };

  const _handleChange = async (selectedSession: any) => {
    sessionStore?.clearPhotographerInfo();
    defaultSelected = selectedSession;
    sessionStore?.getPhotographerSessionInfo(selectedSession, authToken, latitude, longitude);
  };

  const _handleNoViewingScheduled = async () => {
    let selectedSession = sessionStore?.photographerSessionInfo?.SessionKey;
    if (selectedSession) {
      await sessionStore?.setNoViewingScheduled(selectedSession, authToken);
      message.success("Phone call & trigger records have been set successfuly.");
    }
  };

  const _handleTextClient = async () => {
    // messageText = sessionStore?.photographerSessionInfo?.OnMyWayTextMessageBody || "";
    if (defaultSelected) sessionStore?.textClientImComing(defaultSelected, authToken, messageText);
  };

  const _handleNotCompletedReason = async () => {
    var session: SessionDetail = {};
    session.Key = defaultSelected;
    if (sessionCompleted === "No") {
      session.ResultKey = incompleteReason;
    } else {
      session.ResultKey = sessionStore?.photographerSessionInfo?.CompletedResultKey;
    }
    sessionStore?.updateSession(session, authToken, latitude, longitude);
  };

  return (
    <Content className="session-info">
      {sessionStore &&
      sessionStore?.photographerSessionsList.length <= 0 &&
      !sessionStore?.isLoading ? (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, there are no sessions for this user. Please check the URL and try again."
          extra={
            <Button type="primary" href="/">
              Back Home
            </Button>
          }
        />
      ) : (
        <div className="container">
          {latitude && longitude ? (
            <></>
          ) : (
            <Alert message="Please allow access to your current location." banner />
          )}
          <Row>
            <Col span={24}>
              <Card>
                <Row>
                  <Col span={24}>
                    <Image
                      width={160}
                      src="/assets/logo.png"
                      alt="logo"
                      className="logo"
                      preview={false}
                    />
                    <Title level={4}>Photographer Sessions</Title>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div className="quick-links">
                      <Row gutter={[16, 16]} justify="space-between">
                        <Col span={4}>
                          <Button
                            href={sessionStore?.photographerSessionCommon?.PhotographerChecklistUrl}
                            target="_blank"
                            type="primary"
                            size="small"
                            icon={<CheckSquareOutlined />}
                          ></Button>
                        </Col>
                        <Col span={4}>
                          <Button
                            href={sessionStore?.photographerSessionCommon?.TimeClockUrl}
                            target="_blank"
                            type="primary"
                            size="small"
                            icon={<ClockCircleOutlined />}
                          ></Button>
                        </Col>
                        <Col span={4}>
                          <Button
                            href={sessionStore?.photographerSessionCommon?.QRCodeUrl}
                            target="_blank"
                            type="primary"
                            size="small"
                            icon={<QrcodeOutlined />}
                          ></Button>
                        </Col>
                        <Col span={4}>
                          <Button
                            href={sessionStore?.photographerSessionCommon?.PickUpSessionUrl}
                            target="_blank"
                            type="primary"
                            size="small"
                            icon={<InteractionOutlined />}
                          ></Button>
                        </Col>
                        <Col span={4}>
                          <Button
                            href={`tel:${sessionStore?.photographerSessionCommon?.NotHomePhoneNumber}`}
                            target="_blank"
                            type="primary"
                            size="small"
                            icon={<PhoneOutlined />}
                          ></Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div className="session-select">
                      <Select
                        // defaultValue={defaultSelected}
                        placeholder="Please select a session"
                        style={{ width: "100%" }}
                        // bordered={false}
                        autoFocus={true}
                        loading={sessionStore?.isLoading}
                        onSelect={_handleChange}
                        options={sessionStore?.photographerSessionsList.map(
                          (session: PhotographerSchedule) => {
                            return {
                              label: `${session.StartTime} - ${session.ClientName} - ${session.SessionNumber}`,
                              value: session.Key,
                            };
                          }
                        )}
                      ></Select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {/*<Card>*/}
                    {sessionStore?.isLoading ? (
                      <Skeleton active />
                    ) : !sessionStore?.photographerSessionInfo?.SessionKey ? (
                      <Skeleton />
                    ) : (
                      <Descriptions
                        title={authStore?.companySettings.JobDescriptorSingular ? authStore?.companySettings.JobDescriptorSingular + "  Details" : "Session Details" }
                        layout="horizontal"
                        size="small"
                        column={1}
                        contentStyle={{ fontWeight: 600 }}
                      >
                        <Descriptions.Item label="Client Name">
                          {sessionStore?.photographerSessionInfo?.ClientFirstName}{" "}
                          {sessionStore?.photographerSessionInfo?.ClientLastName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Client Phone">
                          <a href={`tel:${sessionStore?.photographerSessionInfo?.ClientPhone1}`}>
                            {sessionStore?.photographerSessionInfo?.ClientPhone1} <PhoneOutlined />
                          </a>
                          <br />
                          {sessionStore?.photographerSessionInfo?.ClientPhone2 ? (
                            <a href={`tel:${sessionStore?.photographerSessionInfo?.ClientPhone2}`}>
                              {sessionStore?.photographerSessionInfo?.ClientPhone2}{" "}
                              <PhoneOutlined />
                            </a>
                          ) : (
                            <></>
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Status">
                          {sessionStore?.photographerSessionInfo?.SessionStatusDescription}
                        </Descriptions.Item>
                        <Descriptions.Item label="Address" span={2}>
                          <a
                            href={`https://www.google.com/maps/place/${sessionStore?.photographerSessionInfo?.ClientAddress}`}
                            target="blank"
                          >
                            {sessionStore?.photographerSessionInfo?.ClientAddress}{" "}
                            <EnvironmentOutlined />
                          </a>
                        </Descriptions.Item>
                        {sessionStore?.photographerSessionInfo?.OfferEntitlement ? (
                          <Descriptions.Item label="Offer/Entitlement" span={2}>
                            {sessionStore?.photographerSessionInfo?.OfferEntitlement}
                          </Descriptions.Item>
                        ) : (
                          <></>
                        )}
                        {sessionStore?.photographerSessionInfo?.AptHouseNotes ? (
                          <Descriptions.Item label="Apt/House/Notes" span={2}>
                            {sessionStore?.photographerSessionInfo?.AptHouseNotes}
                          </Descriptions.Item>
                        ) : (
                          <></>
                        )}
                        {sessionStore?.photographerSessionInfo?.OtherDirections ? (
                          <Descriptions.Item label="Other Directions" span={2}>
                            {sessionStore?.photographerSessionInfo?.OtherDirections}
                          </Descriptions.Item>
                        ) : (
                          <></>
                        )}
                        {sessionStore?.photographerSessionInfo?.FamilyMembers ? (
                          <Descriptions.Item label="Family Members" span={2}>
                            <ul>
                              {sessionStore?.photographerSessionInfo?.FamilyMembers.map(
                                (object) => (
                                  <li>{object}</li>
                                )
                              )}
                            </ul>
                          </Descriptions.Item>
                        ) : (
                          <></>
                        )}
                      </Descriptions>
                    )}
                    {/*</Card>       */}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          {!sessionStore?.photographerSessionInfo?.TravelTimeMinutes &&
          !sessionStore?.photographerSessionInfo?.OnMyWayTextMessageBody ? (
            <></>
          ) : (
            <Row>
              <Col span={24}>
                {sessionStore?.isLoading ? (
                  <Skeleton active />
                ) : Number(sessionStore?.photographerSessionInfo?.TravelTimeMinutes) >= 0 ? (
                  <Card
                    title="Notify Client"
                    extra={
                      latitude && longitude ? (
                        <Tooltip title="Refresh Location">
                          <Button
                            type="link"
                            onClick={(e) => {
                              _getGeoLocation();
                            }}
                            icon={<EnvironmentOutlined />}
                            className="refresh-location"
                          />
                        </Tooltip>
                      ) : (
                        <></>
                      )
                    }
                  >
                    {sessionStore?.photographerSessionInfo?.TravelTimeMinutes ? (
                      <p>
                        Drive time to Session:{" "}
                        {sessionStore?.photographerSessionInfo?.TravelTimeMinutes} minutes
                      </p>
                    ) : (
                      <></>
                    )}
                    {sessionStore?.photographerSessionInfo?.OnMyWayTextMessageBody ? (
                      <p>
                        <Button
                          type="primary"
                          onClick={(e) => {
                            _handleTextClient();
                          }}
                        >
                          Text Client I'm Coming
                        </Button>
                      </p>
                    ) : (
                      <></>
                    )}
                  </Card>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          )}

          {!sessionStore?.photographerSessionInfo?.SessionKey ? (
            <></>
          ) : (
            <Row>
              <Col span={24}>
                <Card title={`${authStore?.companySettings.JobDescriptorSingular ? authStore?.companySettings.JobDescriptorSingular : "Session"} Result`}>
                  {sessionStore?.isLoading ? (
                    <Skeleton active />
                  ) : sessionStore?.photographerSessionInfo?.SessionResultDescription == null ? (
                    <>
                      <Form
                        name="basic"
                        // labelCol={{ span: 8 }}
                        // wrapperCol={{ span: 16 }}
                        // initialValues={{ remember: true }}
                        onFinish={_handleNotCompletedReason}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                      >
                        <Form.Item
                          label="Did you complete the session?"
                          name="sessionCompleted"
                          // rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                          <Radio.Group
                            options={["Yes", "No"]}
                            value={sessionCompleted}
                            defaultValue={"Yes"}
                            onChange={(e) => {
                              setSessionCompleted(e.target.value);
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.sessionCompleted !== currentValues.sessionCompleted
                          }
                        >
                          {({ getFieldValue }) =>
                            getFieldValue("sessionCompleted") === "No" ? (
                              <Form.Item
                                label="Please select a reason?"
                                name="sessionIncompleteReason"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select a reason!",
                                  },
                                ]}
                              >
                                <Select
                                  style={{ width: "100%" }}
                                  options={sessionStore?.photographerSessionInfo?.NotCompletedResults?.map(
                                    (ncr: NotCompletedResult) => {
                                      return {
                                        label: ncr.Description || "",
                                        value: ncr.Key || "",
                                      };
                                    }
                                  )}
                                  onSelect={(val: any, option: any) => {
                                    setIncompleteReason(val);
                                  }}
                                ></Select>
                              </Form.Item>
                            ) : null
                          }
                        </Form.Item>

                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Submit Result
                          </Button>
                        </Form.Item>
                      </Form>
                      <br />
                      <Popconfirm
                        title="Are you sure？"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={(e) => {
                          _handleNoViewingScheduled();
                        }}
                      >
                        <Button type="default">No viewing scheduled</Button>
                      </Popconfirm>
                    </>
                  ) : (
                    <>
                      <p>{authStore?.companySettings.JobDescriptorSingular ? authStore?.companySettings.JobDescriptorSingular : "Session"} Result, set to :</p>
                      <p>{sessionStore?.photographerSessionInfo?.SessionResultDescription}</p>
                    </>
                  )}
                </Card>
              </Col>
            </Row>
          )}

          {!sessionStore?.photographerSessionInfo?.SessionKey ? (
            <></>
          ) : (
            <Row>
              <Col span={24}>
                <Card title="Quick Links" className="action-buttons">
                  {sessionStore?.isLoading ? (
                    <Skeleton active />
                  ) : (
                    <Row gutter={[16, 10]}>
                      <Col span={24}>
                        <Button
                          href={sessionStore?.photographerSessionInfo?.AppointmentBookingUrl}
                          target="_blank"
                          type="link"
                          icon={<CalendarOutlined />}
                        >
                          Open Online Booking
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button
                          href={sessionStore?.photographerSessionInfo?.CustomerCheckoutUrl}
                          target="_blank"
                          type="link"
                          icon={<ShoppingCartOutlined />}
                        >
                          Customer Checkout
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button
                          href={sessionStore?.photographerSessionInfo?.TextCheckoutPageToClientUrl}
                          target="_blank"
                          type="link"
                          icon={<MessageOutlined />}
                        >
                          Text Checkout Page to Client
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button
                          href={sessionStore?.photographerSessionInfo?.JoinReferralProgramUrl}
                          target="_blank"
                          type="link"
                          icon={<UsergroupAddOutlined />}
                        >
                          Join Referral Program
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button
                          href={sessionStore?.photographerSessionInfo?.RescheduleUrl}
                          target="_blank"
                          type="link"
                          icon={<FieldTimeOutlined />}
                        >
                          Reschedule Photoshoot
                        </Button>
                      </Col>
                      <Col span={24}>
                        <Button
                          href={sessionStore?.photographerSessionInfo?.PhotographerChecklistUrl}
                          target="_blank"
                          type="link"
                          icon={<CheckCircleOutlined />}
                        >
                          Photographer Checklist
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Card>
              </Col>
            </Row>
          )}

          <Row justify="space-between" align="middle" className="copyright">
            <Col order={2}>
              <ul className="list-inline">
                <li>
                  <Link to="">Help</Link>
                </li>
              </ul>
            </Col>
            <Col order={1}>&copy; {dayjs().format("YYYY")} myStratus</Col>
          </Row>
        </div>
      )}
    </Content>
  );
};

export default inject("sessionStore", "authStore")(observer(SessionInfoPage));

// export function geolocated();
