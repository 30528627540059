import React from "react";
import { Layout, Row, Col, Grid, Typography } from "antd";
import "../navigation/Navigation.scss";
import "../../pages/QuickAccess/QuickAccessPage.scss";
import Branding from "components/navigation/Branding";

const { Title } = Typography;
const { useBreakpoint } = Grid;
const { Header } = Layout;

const CustomHeader = (props: { children?: any; title?: string }) => {
  const { children, title } = props;
  const screens = useBreakpoint();
  return (
    <>
      <div>
        <Header className="main-navigation-header">
          <Row style={{ border: "3px solid white" }} justify="space-between" align="middle">
            {!screens.md && (
              <Col>
                <Branding src="assets/cloud-icon1.jpg" onHeader />
              </Col>
            )}
            <Col md={24} className="navigation-action">
              <Row align="middle">
                {screens.md && (
                  <Col>
                    <Title className="page-header-title">{title}</Title>
                  </Col>
                )}
                {children}
              </Row>
            </Col>
          </Row>
        </Header>
      </div>
    </>
  );
};

export default CustomHeader;
