import dayjs, { Dayjs } from "dayjs";

import AuthStore from "stores/AuthStore";

export interface LocalStorageProps {
  name: "Updated" | "Added" | "Deleted" | "Viewed" | "Synced";
  value: string;
  date?: Dayjs;
  url?: string;
  type?: object;
}

export function saveLocalStorage(
  _this: LocalStorageProps,
  name: string = "spsLocal"
) {
  var storageJson: LocalStorageProps[] = [];
  const storageData = localStorage.getItem(name);
  const newData: LocalStorageProps = { ..._this, date: dayjs() };
  if (storageData) {
    storageJson = [...JSON.parse(storageData)];
    if (
      storageJson[0].value !== _this.value ||
      storageJson[0].name !== _this.name
    ) {
      storageJson.unshift(newData);
      if (storageJson.length > 50) storageJson.pop();
      localStorage.setItem(name, JSON.stringify(storageJson));
    }
  } else {
    localStorage.setItem(name, JSON.stringify([newData]));
  }

  AuthStore.setToggleLocalStorage();
}

export function getLocalStorage(
  name: string = "spsLocal"
): Array<LocalStorageProps> {
  const storageData = localStorage.getItem(name);

  if (!storageData) {
    return [];
  }

  return JSON.parse(storageData);
}

export function clearLocalStorage(name: string = "spsLocal") {
  localStorage.removeItem(name);
  AuthStore.setToggleLocalStorage();
}
