import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Collapse, List } from "antd";

import { ClientStoreClass } from "stores/ClientStore";
import type { InvoiceList } from "interfaces/invoice.int";
import type { InvoiceStoreClass } from "stores/InvoiceStore";

import RenderInvoice from "components/invoice/RenderInvoice";

interface Props {
  clientStore?: ClientStoreClass;
  invoiceStore: InvoiceStoreClass;
  setToggleActionItems?: any;
  viewSection?: any;
  setToggleInvoiceActionItems?: any;
  currentTab?: string
  setCurrentTab?:any
}

const QuickAccessInvoicesList: React.FC<Props> = ({
  clientStore,
  invoiceStore,
  viewSection,
  setToggleInvoiceActionItems,
  currentTab,
  setCurrentTab
}) => {
  const { Panel } = Collapse;
  let [defaultKey, setDefaultKey] = useState<any>("1");

  const filteredOpenBalaceInvoice = invoiceStore?.invoiceList?.filter((invoice: any) => {
    return invoice?.OpenBalance > 0;
  });

  useEffect(() => {
    if (clientStore?.clientInfo?.ClientNumber && viewSection === "Invoices")
      invoiceStore?.getInvoiceList({
        action: "ClientNumber",
        value: clientStore?.clientInfo.ClientNumber,
      });
  }, [clientStore?.activeClientKey,viewSection]);

  useEffect(() => {
  
    if(viewSection === "Invoices" && 
        (defaultKey === "1" || 
        defaultKey === undefined)){  
        
      filteredOpenBalaceInvoice?.length? 
      setDefaultKey("1")
      : setDefaultKey("2");
      
        }
    else if( viewSection !== "Invoices") {}
    
    else setDefaultKey("2")
     
 }, [viewSection]);



  return (
    <div className="sessions-list">
      <Collapse
        ghost
        bordered
        accordion
        onChange={(a) => {
          setDefaultKey(a);
        }}
        activeKey={defaultKey}
        defaultActiveKey={defaultKey}
      >
        <Panel header={"Open Balance Invoices"} key="1">
          <List
            dataSource={filteredOpenBalaceInvoice}
            renderItem={(item: InvoiceList) => (
              <RenderInvoice
                setToggleInvoiceActionItems={setToggleInvoiceActionItems}
                item={item}
                currentTab ={currentTab}
                setCurrentTab= {setCurrentTab}
              />
            )}
            bordered
            style={{ border: 0}}
          />
        </Panel>
        <Panel header={"All Invoices"} key="2">
          <List
            dataSource={invoiceStore?.invoiceList}
            renderItem={(item: InvoiceList) => (
              <RenderInvoice
                setToggleInvoiceActionItems={setToggleInvoiceActionItems}
                item={item}
                currentTab ={currentTab}
                setCurrentTab= {setCurrentTab}
              />
            )}
            bordered
            style={{ border: 0}}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default inject("clientStore", "invoiceStore")(observer(QuickAccessInvoicesList));
