import { inject, observer } from "mobx-react";
import React, { useEffect, useState, useRef } from "react";
import "./gallery-slide-viewer.scss";
import {
  ShoppingCartOutlined,
  HeartFilled,
  HeartOutlined,
  FolderAddOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import ImageGallery from "react-image-gallery";
import EventEmitter from "utils/event-emitter";

const GallerySlideViewer = (props: any) => {
  const sliderRef: any = useRef(null);

  const [images, setImages] = useState([]);
  const [currentimageIndex, setCurrentimageIndex] = useState(0);
  const [slideOptions, setSlideOptions] = useState<any>();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    _registerEvents();
  }, []);

  useEffect(() => {
    if (isVisible && slideOptions) {
      // Check if selected image is passed
      if (slideOptions.image) {
        // Find image index
        const imageIndex = slideOptions.groupImages.findIndex(
          (img: any) => img.key === slideOptions.image.key
        );
        if (imageIndex > -1) {
          sliderRef.current.slideToIndex(imageIndex);
        }
      }
      // Check if autoplay is true
      if (slideOptions.autoPlay) {
        sliderRef.current.fullScreen();
        sliderRef.current.play();
      }
    }
  }, [isVisible, slideOptions]);

  const _registerEvents = () => {
    EventEmitter.listen("show-slide-view", (slideOptions: any) => {
      const images = slideOptions.groupImages.map((image: any) => ({
        original: image.thumbnailPath,
        thumbnail: image.thumbnailPath,
        ...image,
      }));
      setImages(images);
      setIsVisible(true);
      setSlideOptions(slideOptions);
    });
  };

  const _hideViewer = () => {
    setIsVisible(false);
    setImages([]);
  };

  const _isFavourite = () => {
    const image = slideOptions.groupImages[currentimageIndex];
    const favouriteFolder = image.folders?.find(
      (folderName: string) => folderName === "Favorites"
    );
    if (favouriteFolder) {
      return true;
    }
  };

  const _modifyImageFolder = () => {
    const image = slideOptions.groupImages[currentimageIndex];
    EventEmitter.trigger("modify-image-folders", image);
  };

  const _isHidden = () => {
    const image = slideOptions.groupImages[currentimageIndex];
    const hiddenFolder = image.folders?.find(
      (folderName: string) => folderName === "Hidden"
    );
    if (hiddenFolder) {
      return true;
    }
  };

  const _toggleHiddenMode = () => {
    const image = slideOptions.groupImages[currentimageIndex];
    if (_isHidden()) {
      // Unhide it
      props.galleryStore.removeImageFromFolder("Hidden", image.fileName);
    } else {
      // Hide it
      props.galleryStore.addImageToFolder("Hidden", image.fileName);
    }
  };

  const _toggleFavouriteMode = () => {
    const image = slideOptions.groupImages[currentimageIndex];
    if (_isFavourite()) {
      // Unhide it
      props.galleryStore.removeImageFromFolder("Favorites", image.fileName);
    } else {
      // Hide it
      props.galleryStore.addImageToFolder("Favorites", image.fileName);
    }
  };

  const _addToCart = () => {
    const image = slideOptions.groupImages[currentimageIndex];
    EventEmitter.trigger("add-to-cart", image);
    _hideViewer();
  };

  if (isVisible) {
    return (
      <>
        <div className="slideViewer">
          <div className="slideViewerInnerWrapper">
            <div className="topActionOuterWrapper">
              <div className="topActionInnerWrapper">
                <div className="actions">
                  <div className="picture_options">
                    <div className="otherActions">
                      <button className="gridAction" onClick={_addToCart}>
                        <ShoppingCartOutlined />
                      </button>
                      <button
                        className="gridAction"
                        onClick={_toggleFavouriteMode}
                      >
                        {_isFavourite() ? <HeartFilled /> : <HeartOutlined />}
                      </button>
                      <button
                        className="gridAction"
                        onClick={(e) => _modifyImageFolder()}
                      >
                        <FolderAddOutlined />
                      </button>
                      <button
                        className="gridAction"
                        onClick={_toggleHiddenMode}
                      >
                        {_isHidden() ? (
                          <EyeInvisibleOutlined />
                        ) : (
                          <EyeOutlined />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="closeBtn" onClick={_hideViewer}>
                  Close
                </div>
              </div>
            </div>
            <div className="slide">
              <ImageGallery
                items={images}
                ref={sliderRef}
                onSlide={(index) => {
                  setCurrentimageIndex(index);
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default inject("galleryStore")(observer(GallerySlideViewer));
