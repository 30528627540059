import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { ClientStoreClass } from "stores/ClientStore";

const { Option, OptGroup } = Select;

const SearchDropDown = (props: {
  defaultValue?: any;
  label?: string;
  searchValues?: any;
  setSearchType?: any;
  clientStore?: ClientStoreClass;
  options?: any;
}) => {
  const { defaultValue, label, searchValues, setSearchType, clientStore } = props;

  const [currentValue, setCurrentValue] = useState<any>(defaultValue);

  const handleChange = (value: any, obj: any) => {
    if (label === "Search Type") {
      setSearchType(value);
    } else {
      clientStore?.setSearchBy(obj);
    }
    setCurrentValue(value);
  };

  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue?.key]);

  return (
    <div className="search-container">
      <Select
        value={currentValue}
        style={{
          minWidth: 160,
          textTransform: "capitalize",
          color: setSearchType && "#1890ff",
        }}
        size="large"
        onChange={handleChange}
      >
        <OptGroup label={`${label}`}>
          {label === "Search Type"
            ? searchValues?.map(
                (
                  value:
                    | boolean
                    | React.ReactChild
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined,
                  index: string | number | undefined
                ) => {
                  return (
                    <Option style={{ textTransform: "capitalize" }} key={index} value={`${value}`}>
                      {value}
                    </Option>
                  );
                }
              )
            : searchValues?.map(
                (
                  value: {
                    key: any;
                    value:
                      | boolean
                      | React.ReactChild
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined;
                  },
                  index: string | number | undefined
                ) => {
                  return (
                    <Option
                      style={{ textTransform: "capitalize" }}
                      key={index}
                      value={`${value.value}`}
                    >
                      {value.key}
                    </Option>
                  );
                }
              )}
        </OptGroup>
      </Select>
    </div>
  );
};

export default SearchDropDown;
