import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Button, Card, Col, Empty, Row, Spin, Typography } from "antd";
import { SessionStoreClass } from "stores/SessionStore";

import "./StatusBucket.scss";
import Bucket from "./Bucket";
import { PlusCircleFilled, ThunderboltFilled } from "@ant-design/icons";

const { Title, Text } = Typography;

interface Props {
  sessionStore?: SessionStoreClass;
  selectedSessionType?: string;
}

const StatusBucket: React.FC<Props> = ({ sessionStore, selectedSessionType }) => {
  const [sessionStatusOnType, setSessionStatusOnType] = useState<any>();

  const generateSessionStatusList = (selectedSessionType: string | undefined) => {
    let sessionStatusOnType: any = [];
    if (sessionStore?.sessionStatusList) {
      for (let status of sessionStore?.sessionStatusList) {
        if (status.SessionTypeKey && status.SessionTypeKey == selectedSessionType) {
          sessionStatusOnType.push(status);
        }
      }
    }
    setSessionStatusOnType(sessionStatusOnType);
  };

  useEffect(() => {
    generateSessionStatusList(selectedSessionType);
  }, [selectedSessionType]);

  return (
    <Spin size={"default"} spinning={sessionStore?.isLoading}>
      <Row className="StatusBucket" gutter={10}>
        <Col sm={24} xs={24}>
          <Row gutter={10} className="StatusBucket__Row">
            {sessionStatusOnType ? (
              sessionStatusOnType.map((status: any) => {
                return (
                  <Col xs={8} key={status.Key}>
                    <Row gutter={10}>
                      <Col sm={15} xs={24}>
                        <Card
                          title={<Title level={4}>{status.Description}</Title>}
                          className="StatusBucket__Card"
                        >
                          <Bucket sessionStatusKey={status.Key} />
                        </Card>
                        <span className="connet right"></span>
                      </Col>
                      <Col sm={9} xs={24} className="TriggerBox">
                        <Card className="StatusBucket__Triggers">
                          <span className="trigger-line left"></span>
                          <span className="trigger-line right"></span>
                          <Text className="trigger">
                            <ThunderboltFilled /> Change Session Date{" "}
                          </Text>
                          <Text className="trigger">
                            <ThunderboltFilled /> Send Confirmation{" "}
                          </Text>
                          <Button icon={<PlusCircleFilled />} className="TriggerBtn">
                            Add Trigger
                          </Button>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                );
              })
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default inject("sessionStore")(observer(StatusBucket));
