import React, { useState, useEffect } from "react";
import { Card, Affix, Grid, Row, Col } from "antd";
import { inject, observer } from "mobx-react";
import SearchInput from "components/forms/SearchInput";
import MessagesList from "components/lists/MessagesList";
import { MessageStoreClass } from "stores/MessageStore";
import ClientTabs from "components/tabs/ClientTabs";
import classnames from "classnames";
import { AuthStoreClass } from "stores/AuthStore";
import ActionsMessages from "components/messages/actions/ActionsMessages";
import SearchDropDown from "components/search/SearchDropdown";
import { ClientStoreClass } from "stores/ClientStore";
import SearchSelect from "components/forms/items/SearchSelect";
import "./MessagesPage.scss";
import { UserStoreClass } from "stores/UserStore";

const { useBreakpoint } = Grid;

interface Props {
  messageStore: MessageStoreClass;
  authStore: AuthStoreClass;
  clientStore?: ClientStoreClass;
  userStore?: UserStoreClass;
}

const MessagesPage: React.FC<Props> = ({ messageStore, authStore, clientStore, userStore }) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [value, setValue] = useState<string>("");
  const [searchAutocomplete, setSearchAutocomplete] = useState<any>([]);

  const screens = useBreakpoint();

  const searchEntities: any = {
    Messages: [
      { value: "assignedToUser", key: "Assigned to User" },
      { value: "department", key: "Department" },
      { value: "emailType", key: "Email Type" },
      { value: "emailStatus", key: "Email Status" },
      { value: "emailSubject", key: "Email Subject" },
    ],
  };

  const onSearchSelect = async (value: any) => {
    // let messageData: any;

    let messageData = { action: clientStore?.searchBy?.value, value: value.key };
    setValue(value.key);
    messageStore?.setActiveSearch(messageData);

    try {
      await messageStore?.getMessagesViaSearch();
    } catch (err) {}
  };

  const onEnter = async () => {
    // clearTimeout(timeout);

    const messageData = { action: clientStore?.searchBy?.value, value: value };
    messageStore?.setActiveSearch(messageData);
    try {
      await messageStore?.getMessagesViaSearch();
    } catch (err) {}
  };

  const onKey = async (e: { keyCode: number; target: { value: any } }) => {
    // clearTimeout(timeout);
    if (e.keyCode === 13) {
      // Prevents loading again for enter value
      return;
    }
    const messageData = { action: clientStore?.searchBy?.value, value: value };
    messageStore?.setActiveSearch(messageData);
    try {
      await messageStore?.getMessagesViaSearch();
    } catch (err) {}
  };

  useEffect(() => {
    messageStore.getDepartments();
  }, [messageStore]);

  const generateSearchAutocomplete = (searchByValue: any) => {
    let options: any[] = [];
    if (searchByValue === "department") {
      options.push(
        { value: "All departments", key: "all department" },
        { value: "Unassigned", key: "un assigned" }
      );
      messageStore?.departments.map((item: any) => {
        options.push({ value: item.Description, key: item.Key });
      });
      setSearchAutocomplete(options);
    } else if (searchByValue === "emailType") {
      options.push({ value: "All Type", key: "all emailtype" });
      setSearchAutocomplete(options);
    } else if (searchByValue === "emailStatus") {
      options.push({ value: "All Statuses", key: "all emailstatus" });
      setSearchAutocomplete(options);
    } else if (searchByValue === "assignedToUser") {
      options.push({ value: "Any", key: "any" }, { value: "Unassigned", key: "unassigned" });
      userStore?.selectUserList?.map((item: any) => {
        options.push({ value: item.label, key: item.value });
      });
      setSearchAutocomplete(options);
    } else {
      setSearchAutocomplete("");
    }
  };

  useEffect(() => {
    generateSearchAutocomplete(clientStore?.searchBy.value);
    setValue("");
  }, [clientStore?.searchBy]);

  useEffect(() => {
    clientStore?.setSearchBy(searchEntities["Messages"][0]);
  }, [clientStore]);

  useEffect(() => {
    // messageStore.getMessages();
    // messageStore?.getMessages(
    //   "",
    //   messageStore?.filterUserKey || authStore?.authenticatedUser?.UserKey
    // );
    return () => {
      messageStore.setSmsDetails(undefined);
      messageStore.setEmailDetails(undefined);
    };
  }, [messageStore.filterUserKey]);

  useEffect(() => {
    messageStore?.setMessagesList([]);
  }, []);

  return (
    <>
      <div className="ant-layout-content main-content" ref={setContainer}>
        <ActionsMessages />
        <div
          className={classnames({
            "main-content-pads": screens.md,
          })}
        >
          <Card
            headStyle={{ border: "none" }}
            title={
              <>
                <Row
                  style={{ alignItems: "center" }}
                  gutter={[16, 16]}
                  align="middle"
                  justify="center"
                >
                  <Col xs={12} sm={8}>
                    <Affix target={() => container} offsetTop={80}>
                      <SearchDropDown
                        defaultValue={searchEntities["Messages"][0]}
                        label={"Search By"}
                        searchValues={searchEntities["Messages"]}
                        clientStore={clientStore}
                      />
                    </Affix>
                  </Col>
                  <Col xs={24} sm={16}>
                    <Affix target={() => container} offsetTop={80}>
                      {clientStore?.searchBy.value === "department" ||
                      clientStore?.searchBy.value === "emailType" ||
                      clientStore?.searchBy.value === "emailStatus" ||
                      clientStore?.searchBy.value === "assignedToUser" ? (
                        <SearchSelect
                          placeholder={`Please select ${
                            clientStore?.searchBy?.children
                              ? clientStore?.searchBy?.children.toLowerCase()
                              : clientStore?.searchBy?.key.toLowerCase()
                          } here to search`}
                          searchClass="search-listing"
                          searchAutocomplete={searchAutocomplete}
                          onEnter={onSearchSelect}
                          searchValue={value}
                        />
                      ) : (
                        <SearchInput
                          onKeyUp={(e: any) => {
                            onKey(e);
                          }}
                          onChange={(e) => {
                            setValue(e.target.value);
                            // clearTimeout(timeout);
                          }}
                          onPressEnter={() => {
                            onEnter();
                            // clearTimeout(timeout);
                          }}
                          value={value}
                          searchClass="search-listing"
                        />
                      )}
                    </Affix>
                  </Col>
                </Row>
              </>
            }
            className="card-listing"
            bordered={false}
          >
            <MessagesList style={{ border: 0 }} />
          </Card>
        </div>
        {/* <MessageDrawerView /> */}
      </div>
      <ClientTabs />
    </>
  );
};

export default inject(
  "messageStore",
  "authStore",
  "clientStore",
  "userStore"
)(observer(MessagesPage));
