import MasonryGridViewer from "components/masonry-grid-viewer/masonry-grid-viewer";
import React, { useEffect, useState } from "react";
import "./global-image-viewer.scss";
import { inject, observer } from "mobx-react";
import { autorun } from "mobx";

const GlobalImageViewer = (props: any) => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const _initiateLoad = async () => {
    setIsLoading(true);
    _getSessionImages();
    await props.galleryStore.fetchSessionImages();
    _getSessionImages();
    setIsLoading(false);
  };

  const _getSessionImages = () => {
    if (props.galleryStore?.sessionImages?.length) {
      setIsLoading(false);
      setImages(props.galleryStore.sessionImages);
    }
  };

  useEffect(() => {
    autorun(() => {
      const images = JSON.parse(
        JSON.stringify(props.galleryStore.sessionImages)
      );
      setImages(images);
    });
    _initiateLoad();
  }, []);

  const trimmedValue = props.searchText.trim().toLowerCase();
  const filteredImages = images.filter(
    (image: any) => image.fileName.toLowerCase().indexOf(trimmedValue) > -1
  );

  return (
    <>
      {filteredImages.length || isLoading ? (
        <MasonryGridViewer images={filteredImages} isLoading={isLoading} />
      ) : (
        <div className="no-item">No Items Available</div>
      )}
    </>
  );
};

export default inject(
  "galleryStore",
  "scheduleStore"
)(observer(GlobalImageViewer));
