import React from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Card, Checkbox, Form } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import InputField from "components/forms/items/InputField";
import DateField from "components/forms/items/DateField";
import SelectField from "components/forms/items/SelectField";
import ClientsSelectField from "components/forms/items/ClientsSelectField";

import "./InvoiceDrawerView.scss";
dayjs.extend(customParseFormat);

const InvoiceDetails: React.FC<any> = ({ clientStore, invoiceStore, authStore, ...props }) => {
  const {
    billCard,
    setBillCard,
    shippingCard,
    setShippingCard,
    client,
    billToClient,
    setBillToClient,
    session,
    isQuickSale,
    form,
  } = props;

  return (
    <div style={{ padding: "2rem" }}>
      <Row gutter={[16, 16]}>
        <Col className="gutter-row" span={12} xs={24} sm={12}>
          <Card title="Invoice Information">
            <Row>
              <Col span={24}>
                <Form.Item name="InvoiceNumber">
                  <InputField label={"Invoice Number"} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="WebOrderNumber">
                  <InputField
                    label={"Web Order No"}
                    value={invoiceStore?.activeInvoice?.WebOrderNumber}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="InvoiceDate">
                  <DateField
                    label="Invoice Date"
                    suffixIcon={<></>}
                    allowClear={false}
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="InvoiceType">
                  <SelectField
                    value={invoiceStore?.activeInvoice?.InvoiceType}
                    options={[
                      { label: "Session Fee", value: "SessionFee" },
                      { label: "InitialOrder", value: "Initial Order" },
                      { label: "Reorder", value: "Reorder" },
                      { label: "Credit Memo", value: "CreditMemo" },
                      { label: "Generic", value: "Generic" },
                      { label: "Estimate", value: "Estimate" },
                      { label: "Web Order", value: "WebOrder" },
                      { label: "Pending Web Order", value: "PendingWebOrder" },
                      { label: "Subscription Fee", value: "SubscriptionFee" },
                    ]}
                    label="Invoice Type"
                    showSearch={false}
                    showArrow={false}
                    suffixIcon={<></>}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="InvoiceDescription">
                  <InputField
                    label={"Invoice Description"}
                    value={invoiceStore?.activeInvoice?.InvoiceDescription}
                  />
                </Form.Item>
              </Col>
              {/* <Col span={24}>
                <InputField
                  label={"Invoice Classification"}
                  value={invoiceStore?.activeInvoice?.InvoiceClassificationDescription}
                />
              </Col> */}

              <Col span={24}>
                <Form.Item name="InvoiceStatusKey">
                  <SelectField
                    value={invoiceStore?.activeInvoice?.InvoiceStatusKey}
                    options={invoiceStore?.invoiceStatuses.map((status: any) => {
                      return {
                        label: status.Description || "",
                        value: status.Key || "",
                      };
                    })}
                    label="Invoice Status"
                    showSearch={false}
                    showArrow={false}
                    suffixIcon={<></>}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="InvoiceStatusDueDate">
                  <DateField
                    label={"Status Due Date"}
                    suffixIcon={<></>}
                    allowClear={false}
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              {!isQuickSale ? (
                <>
                  <Col span={24}>
                    <DateField
                      label={`${
                        authStore?.companySettings?.JobDescriptorSingular
                          ? authStore?.companySettings?.JobDescriptorSingular
                          : "Session"
                      } Date`}
                      suffixIcon={<></>}
                      allowClear={false}
                      placeholder=""
                      value={session?.StartDateTime ? dayjs(session.StartDateTime) : null}
                    />
                  </Col>
                  <Col span={24}>
                    <InputField
                      label={`${
                        authStore?.companySettings?.JobDescriptorSingular
                          ? authStore?.companySettings?.JobDescriptorSingular
                          : "Session"
                      } No`}
                      value={session.SessionNumber || ""}
                    />
                  </Col>
                </>
              ) : null}
              {/* <Col span={24}>
                <Form.Item name="SalesRepKey">
                  <UsersSelectField
                    value={invoiceStore?.activeInvoice?.SalesRepKey}
                    label="Sales Rep"
                    prefix={<></>}
                  />
                </Form.Item>
              </Col> */}
              <Col span={24}>
                <Form.Item name="PaymentDueDate">
                  <DateField
                    label={"Payment Due Date"}
                    suffixIcon={<></>}
                    allowClear={false}
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="ApproximateDeliveryDate">
                  <DateField
                    label={"Approximate Delivery Date"}
                    suffixIcon={<></>}
                    allowClear={false}
                    placeholder=""
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="DeliveredDate">
                  <DateField
                    label={"Delivery Date"}
                    suffixIcon={<></>}
                    allowClear={true}
                    placeholder=""
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col className="gutter-row" span={12} xs={24} sm={12}>
          {!isQuickSale ? (
            <>
              <Row style={{ marginBottom: "2rem" }}>
                <Col span={24}>
                  <Card title="Invoice Client">
                    <div>
                      <label style={{ color: "gray", fontSize: "0.75rem" }}>Name</label>
                      <p>
                        {client?.FirstName} {client?.LastName}
                      </p>
                    </div>
                    <div>
                      <label style={{ color: "gray", fontSize: "0.75rem" }}>Address Line 1</label>
                      <p>{client?.AddressLine1}</p>
                    </div>
                    <div>
                      <label style={{ color: "gray", fontSize: "0.75rem" }}>Address Line 2</label>
                      <p>{client?.AddressLine2}</p>
                    </div>
                    <Row>
                      <Col span={8}>
                        <label style={{ color: "gray", fontSize: "0.75rem" }}>City</label>
                        <p>{client?.AddressCity}</p>
                      </Col>
                      <Col span={8}>
                        <label style={{ color: "gray", fontSize: "0.75rem" }}>State</label>
                        <p>{client?.AddressState}</p>
                      </Col>
                      <Col span={8}>
                        <label style={{ color: "gray", fontSize: "0.75rem" }}>Postcode</label>
                        <p>{client?.AddressPostcode}</p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Checkbox
                  defaultChecked={!billCard}
                  style={{ marginBottom: "2rem" }}
                  onChange={(e: any) => {
                    setBillCard(!billCard);
                  }}
                >
                  Billing Address is same as Invoice Client Address
                </Checkbox>
              </Row>
            </>
          ) : null}

          {billCard && !isQuickSale && (
            <Row style={{ marginBottom: "2rem" }}>
              <Col className="gutter-row" span={24}>
                <Card title={"Bill-To Client"}>
                  <Form.Item name="BillToClientKey">
                    <ClientsSelectField
                      form={form}
                      onChange={async (e: any) => {
                        if (e.length) {
                          const ajaxBillClient = await clientStore?.getClient(e);
                          setBillToClient(ajaxBillClient);
                        }
                      }}
                    />
                  </Form.Item>
                  <div>
                    <label style={{ color: "gray", fontSize: "0.75rem" }}>Name</label>
                    <p>
                      {billToClient?.FirstName} {billToClient?.LastName}
                    </p>
                  </div>
                  <div>
                    <label style={{ color: "gray", fontSize: "0.75rem" }}>Address Line 1</label>
                    <p>{billToClient?.AddressLine1}</p>
                  </div>
                  <div>
                    <label style={{ color: "gray", fontSize: "0.75rem" }}>Address Line 2</label>
                    <p>{billToClient?.AddressLine2}</p>
                  </div>
                  <Row>
                    <Col span={8}>
                      <label style={{ color: "gray", fontSize: "0.75rem" }}>City</label>
                      <p>{billToClient?.AddressCity}</p>
                    </Col>
                    <Col span={8}>
                      <label style={{ color: "gray", fontSize: "0.75rem" }}>State</label>
                      <p>{billToClient?.AddressState}</p>
                    </Col>
                    <Col span={8}>
                      <label style={{ color: "gray", fontSize: "0.75rem" }}>Postcode</label>
                      <p>{billToClient?.AddressPostcode}</p>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          )}
          {!isQuickSale ? (
            <Row>
              <Checkbox
                defaultChecked={!shippingCard}
                style={{ marginBottom: "2rem" }}
                onChange={(e: any) => {
                  setShippingCard(!shippingCard);
                }}
              >
                Shipping Address is same as Invoice Client Address
              </Checkbox>
            </Row>
          ) : null}
          {shippingCard && (
            <Row>
              <Col className="gutter-row" span={24}>
                <Card title="Shipping Address">
                  <Form.Item name="ShippingAddressName">
                    <InputField label={"Name"} />
                  </Form.Item>
                  <Form.Item name="ShippingAddressLine1">
                    <InputField label={"Address Line 1"} />
                  </Form.Item>
                  <Form.Item name="ShippingAddressLine2">
                    <InputField label={"Address Line 2"} />
                  </Form.Item>
                  <Row>
                    <Col span={8}>
                      <Form.Item name="ShippingAddressCity">
                        <InputField label={"City"} />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item name="ShippingAddressState">
                        <InputField label={"State"} />
                      </Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item name="ShippingAddressPostalCode">
                        <InputField label={"Postcode"} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default inject(
  "invoiceStore",
  "clientStore",
  "sessionStore",
  "userStore",
  "cartStore",
  "authStore"
)(observer(InvoiceDetails));
