import React from "react";
import { inject, observer } from "mobx-react";
import { Col, Grid, Button, Space, message } from "antd";
import { AlignLeftOutlined, PlusOutlined } from "@ant-design/icons";
import CustomHeader from "components/header/CustomHeader";
import { PersonalDashboardStoreClass } from "stores/PersonalDashboardStore";

interface Props {
  personalDashboardStore?: PersonalDashboardStoreClass;
  groupCheckData?: any;
  removeGroupCheck?: boolean;
  setRemoveGroupCheck?: any;
  setGroupCheckData?: any;
  groupLength?: number;
  chartLength?: number;
  setEditChartValues?: any;
  setIsDashboardUpdated?: any;
  isDashboardUpdated?: boolean;
  resetGroupAndRemoveHandler?: any;
}

const { useBreakpoint } = Grid;

const ActionsClients: React.FC<Props> = ({
  personalDashboardStore,
  groupCheckData,
  removeGroupCheck,
  setRemoveGroupCheck,
  setGroupCheckData,
  groupLength,
  chartLength,
  setEditChartValues,
  setIsDashboardUpdated,
  isDashboardUpdated,
  resetGroupAndRemoveHandler,
}: Props): React.ReactElement => {
  const screens = useBreakpoint();

  const handleClick = () => {
    setEditChartValues({});
    personalDashboardStore?.setSelectToggle(true);
  };

  const groupChartHandler = () => {
    if (personalDashboardStore?.allowGrouping) {
      personalDashboardStore?.setAllowGrouping(false);
      if (personalDashboardStore?.groupedChartsData.length)
        personalDashboardStore?.setTabsGroupedCharts(personalDashboardStore?.groupedChartsData);
      personalDashboardStore?.setGroupedChartsData([]);
      setIsDashboardUpdated(true);
      resetGroupAndRemoveHandler();
    } else {
      personalDashboardStore?.setAllowGrouping(true);
    }
  };

  const removeGroupHandler = () => {
    if (removeGroupCheck) {
      setRemoveGroupCheck(false);
      if (groupCheckData.length) personalDashboardStore?.removeFromTabsGroup(groupCheckData);
      setGroupCheckData([]);
      setIsDashboardUpdated(true);
      resetGroupAndRemoveHandler();
    } else {
      setRemoveGroupCheck(true);
    }
  };

  const saveDashboardHandler = () => {
    personalDashboardStore?.savePersonalDashboard().then((data: any) => {
      message.success("Dashboard Saved");
      setIsDashboardUpdated(false);
      personalDashboardStore?.getPersonalDashboard();
    });
  };

  return (
    <CustomHeader title="Personal Dashboard">
      <Col style={{ marginLeft: "auto" }}>
        <Space>
          {!personalDashboardStore?.allowGrouping && groupLength && groupLength > 0 ? (
            <Button
              shape="round"
              type={screens.lg ? "primary" : "text"}
              className="RemoveGroupsBtn"
              onClick={() => removeGroupHandler()}
            >
              {removeGroupCheck ? "FINISH REMOVE" : "REMOVE GROUPS"}
            </Button>
          ) : null}
          {!removeGroupCheck && chartLength && chartLength > 1 ? (
            <Button
              shape="round"
              type={screens.lg ? "primary" : "text"}
              className="GroupChartsBtn"
              onClick={() => groupChartHandler()}
            >
              {personalDashboardStore?.allowGrouping ? "FINISH GROUPING" : "GROUP CHARTS"}
            </Button>
          ) : null}
          <Button
            disabled={!isDashboardUpdated}
            shape="round"
            type={screens.lg ? "primary" : "text"}
            className="saveDashboardBtn"
            onClick={() => saveDashboardHandler()}
          >
            Save Dashboard
          </Button>
          <Button
            // disabled={personalDashboardStore?.isLoading}
            icon={<AlignLeftOutlined />}
            type="text"
            onClick={() => personalDashboardStore?.setPerformancePanelToggle(true)}
          >
            Performance panel
          </Button>
          <Button
            icon={<PlusOutlined style={{ color: screens.lg ? "" : "#5DAEFF" }} />}
            // disabled={personalDashboardStore?.isLoading}
            // type={screens.lg ? "primary" : "text"}
            style={{ backgroundColor: "#708D65", borderColor: "white", color: "white" }}
            className={screens.lg ? "btn-action" : "no-label"}
            shape="round"
            onClick={handleClick}
          >
            Create
          </Button>
        </Space>
      </Col>
    </CustomHeader>
  );
};

export default inject("personalDashboardStore")(observer(ActionsClients));
