import React, { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Alert, Form, Input, Switch, Row, Col, Grid, message } from "antd";
import { inject, observer } from "mobx-react";
import {
  AlignLeftOutlined,
  TeamOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import type { FormInstance } from "antd/lib/form/hooks/useForm";
import type { RangeValue } from "rc-picker/lib/interface";

import "./switch.scss";
import "./timePicker.scss";

import type { AppointmentDetail, AppointmentFormValues } from "interfaces/appointment.int";
import ClientRow from "../rows/ClientRow";
import type { ScheduleStoreClass } from "stores/ScheduleStore";
import type { AuthStoreClass } from "stores/AuthStore";
import type { ClientStoreClass } from "stores/ClientStore";
import type { UserStoreClass } from "stores/UserStore";
import type { AppointmentStoreClass } from "stores/AppointmentStore";
import ClientsSelectField from "./items/ClientsSelectField";
import AppointmentTypesSelectField from "./items/AppointmentTypesSelectField";
import AppointmentStatusSelectField from "./items/AppointmentStatusSelectField";
import UsersSelectField from "./items/UsersSelectField";
import LocationsSelectField from "./items/LocationsSelectField";
import NotesField from "./items/NotesField";
import DateField from "./items/DateField";
import TimeRangeField from "./items/TimeRangeField";
import InputField from "./items/InputField";
import { saveLocalStorage } from "components/util/localStorage";

dayjs.extend(customParseFormat);

const { useBreakpoint } = Grid;

interface Props {
  form?: FormInstance;
  onSuccess?: () => void;
  onError?: () => void;
  clientStore?: ClientStoreClass;
  authStore?: AuthStoreClass;
  scheduleStore?: ScheduleStoreClass;
  userStore?: UserStoreClass;
  appointmentStore?: AppointmentStoreClass;
  scheduleCustomFields?: any;
  clientKey?: string;
  sessionKey?: string;
}

const AddEditAppointment: React.FC<Props> = ({
  form,
  onSuccess,
  onError,
  clientStore,
  clientKey = "",
  sessionKey = "",
  authStore,
  scheduleStore,
  userStore,
  appointmentStore,
  scheduleCustomFields,
}): React.ReactElement => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>();
  const [isRecurring, setIsRecurring] = useState(false);
  const [timeDiff, setTimeDiff] = useState<any>(60);
  const [newTimeValues, setNewTimeValues] = useState<any>([
    dayjs(scheduleStore?.activeDateTime),
    dayjs(scheduleStore?.activeDateTime).add(1, "hour"),
  ]);
  const isLoading: boolean = appointmentStore?.isLoading || false;
  const screens = useBreakpoint();
  const formGrid = (isEnd = false, inForce = true) => {
    const marginVar = inForce ? 10 : "";
    return {
      xs: 24,
      sm: 12,
      style: {
        marginBottom: isEnd ? "" : marginVar,
      },
    };
  };

  const initialFormValues: AppointmentFormValues = {
    key: "",
    client: clientStore?.activeClientKey,
    SessionKey: sessionKey,
    user: authStore?.authenticatedUser?.UserKey,
    description: "",
    type: "",
    location: "",
    notes: "",
    date: dayjs(scheduleStore?.activeDate),
    time: newTimeValues,
    all_day: false,
    confirmed: false,
    no_show: false,
    canceled: false,
  };

  useEffect(() => {
    form?.setFieldValue("date", scheduleStore?.activeDate);
  }, [scheduleStore?.activeDate]);

  useEffect(() => {
    return () => {
      scheduleStore?.setActiveSchedule(undefined);
      appointmentStore?.setUnlinkedAppointment(false);
      // clientStore?.setActiveClientKey("");
    };
    // eslint-disable-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Number(authStore?.companySettings?.AppointmentLength) < 60) {
      setTimeDiff(Number(authStore?.companySettings?.AppointmentLength));
    } else if (Number(authStore?.companySettings?.AppointmentLength) >= 60) {
      setTimeDiff(Math.round(Number(authStore?.companySettings?.AppointmentLength) / 60));
    }
  }, [authStore?.companySettings?.AppointmentLength]);

  useEffect(() => {
    if (typeof scheduleStore?.activeSchedule !== "undefined") {
      setIsEdit(true);
      fetchSchedule();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleStore?.activeSchedule]);

  const fetchSchedule = async () => {
    try {
      var details: AppointmentDetail | undefined;
      var dateTime = scheduleStore?.getDateTimeRange;

      if (scheduleStore?.activeSchedule?.AppointmentKey !== undefined) {
        details = await appointmentStore?.getAppointmentDetail(
          scheduleStore?.activeSchedule?.AppointmentKey
        );
        dateTime = {
          date: dayjs(details?.StartDateTime),
          time: [dayjs(details?.StartDateTime), dayjs(details?.EndDateTime)],
        };
      }

      if (details.GUIRecurrence) {
        setIsRecurring(true);
      }

      const fillValues: AppointmentFormValues = {
        ...dateTime,
        key: details?.Key,
        client: details?.ClientKey,
        user: details?.PrimaryUserKey,
        type: details?.AppointmentTypeKey,
        description: details?.Description,
        location: details?.ResourceKey,
        all_day: details?.AllDayEvent || false,
        confirmed: details?.Confirmed || false,
        no_show: details?.NoShow || false,
        canceled: details?.Canceled || false,
      };

      form?.setFieldsValue(fillValues);
      setNotes(details?.Notes || "");

      await clientStore?.getClient(details?.ClientKey);

      if (details?.Key) {
        saveLocalStorage({
          name: "Viewed",
          value: `Appointment - ${
            clientStore?.clientInfo?.FirstName + " " + clientStore?.clientInfo?.LastName
          }${details.Description && ` - ${details.Description}`}`,
          // url: `/schedule/appointment/edit/${details.Key}`,
          type: {
            key: details.Key,
            value: "Appointment",
          },
        });
      }
    } catch (e) {
      onError && onError();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  // setNewTimeValues
  // const handleValueChanges = (changedValues: any) => {
  //   if (changedValues.time) {
  //     setNewTimeValues([dayjs(changedValues.time[0]), dayjs(changedValues.time[1])]);
  //   }
  // };

  const handleDateChange = (date: any) => {
    form?.setFieldsValue({
      date: date,
    });
  };

  const handleSubmit = async (values: AppointmentFormValues) => {
    const {
      key,
      client,
      description,
      type,
      user,
      notes,
      confirmed,
      no_show,
      canceled,
      all_day,
      date,
      // time,
      location,
    } = values;
    let time = newTimeValues;
    let convertDate = dayjs(date).format("YYYY-MM-DD");
    let convertStartTime = time ? dayjs(time[0]).format("HH:mm A") : "";
    let convertEndTime = time ? dayjs(time[1]).format("HH:mm A") : "";
    let StartDateTime = dayjs(`${convertDate}:${convertStartTime}`, "YYYY-MM-DD HH:mm A").format(
      "YYYY-MM-DDTHH:mm:ss.SSS"
    );
    let EndDateTime = dayjs(`${convertDate}:${convertEndTime}`, "YYYY-MM-DD HH:mm A").format(
      "YYYY-MM-DDTHH:mm:ss.SSS"
    );
    // 2021-09-14T10:46:01.968Z"
    // let ClientNumber = client ? clientStore?.getClientDataFromList(client, "ClientNumber") : "";
    let UserName = user ? userStore?.getUserDataFromList(user, "Name") : "";
    let TypeDescription = type
      ? appointmentStore?.getAppointmentTypeFromList(type, "Description")
      : "";
    let ResourceName = location
      ? scheduleStore?.getResourceDataFromList(location, "Description")
      : "";

    let data: AppointmentDetail = {
      Key: key ? key : null,
      ClientKey: client,
      Description: description,
      AppointmentTypeKey: type ? type : null,
      AppointmentTypeDescription: type ? TypeDescription?.toString() : null,
      PrimaryUserKey: user,
      Confirmed: confirmed,
      NoShow: no_show,
      SessionKey: sessionKey,
      Canceled: canceled,
      AllDayEvent: all_day,
      StartDateTime,
      EndDateTime,
      ResourceKey: location || undefined,
      ResourceName: location ? ResourceName?.toString() : null,
      CreateByUserKey: authStore?.authenticatedUser?.UserKey,
      PrimaryUserName: UserName?.toString(),
      CustomDate01: scheduleCustomFields.CustomDate01,
      CustomDate02: scheduleCustomFields.CustomDate02,
      CustomDate03: scheduleCustomFields.CustomDate03,
      CustomDate04: scheduleCustomFields.CustomDate04,
      CustomDate05: scheduleCustomFields.CustomDate05,
      CustomText01: scheduleCustomFields.CustomText01,
      CustomText02: scheduleCustomFields.CustomText02,
      CustomText03: scheduleCustomFields.CustomText03,
      CustomText04: scheduleCustomFields.CustomText04,
      CustomText05: scheduleCustomFields.CustomText05,
      CustomText06: scheduleCustomFields.CustomText06,
      CustomText07: scheduleCustomFields.CustomText07,
      CustomText08: scheduleCustomFields.CustomText08,
      CustomText09: scheduleCustomFields.CustomText09,
      CustomText10: scheduleCustomFields.CustomText10,
      CustomBoolean01: scheduleCustomFields.CustomBoolean01,
      CustomBoolean02: scheduleCustomFields.CustomBoolean02,
      CustomBoolean03: scheduleCustomFields.CustomBoolean03,
      CustomBoolean04: scheduleCustomFields.CustomBoolean04,
      CustomBoolean05: scheduleCustomFields.CustomBoolean05,
    };

    if (notes !== "") data = { ...data, Notes: notes };

    if (scheduleStore?.activeScheduleDetail) {
      let changedData: AppointmentDetail = {};
      for (let line in data) {
        let dataLine: any = data[line as keyof AppointmentDetail];
        if (
          dataLine !== scheduleStore?.activeScheduleDetail[line] &&
          ["CreateByUserKey"].indexOf(line) === -1
        ) {
          changedData[line] = dataLine;
        }
      }
      if (Object.keys(changedData).length === 0) {
        message.warning("No Data Changed");
        return;
      }

      data = { Key: key, ...changedData };
    } else {
      data = { ...data, ClientKey: clientStore?.activeClientKey };
    }

    const loadingMsgKey = "appointment-key";
    message.loading({ content: "Saving...", key: loadingMsgKey, duration: 0 });

    try {
      await appointmentStore?.addAppointment(data).then(async (res: AppointmentDetail) => {
        message.success({
          content: "Appointment successfully saved!",
          key: loadingMsgKey,
          duration: 1,
        });
        appointmentStore?.getAppointmentList(clientStore?.activeClientKey);

        onSuccess && onSuccess();

        await clientStore?.getClient(res.ClientKey);

        if (data.Key) {
          saveLocalStorage({
            name: "Updated",
            value: `Appointment - ${
              clientStore?.clientInfo?.FirstName + " " + clientStore?.clientInfo?.LastName
            }${res.Description && ` - ${res.Description}`}`,
            // url: `/schedule/appointment/edit/${res.Key}`,
            type: {
              key: res.Key,
              value: "Appointment",
            },
          });
        } else {
          saveLocalStorage({
            name: "Added",
            value: `Appointment - ${
              clientStore?.clientInfo?.FirstName + " " + clientStore?.clientInfo?.LastName
            }${res.Description && ` - ${res.Description}`}`,
            // url: `/schedule/appointment/edit/${res.Key}`,
            type: {
              key: res.Key,
              value: "Appointment",
            },
          });
        }
      });
    } catch (e) {
      message.error({
        content: "Sorry, something has gone wrong. Please try again later.",
        key: loadingMsgKey,
        duration: 1,
      });
    }
  };

  return (
    <Form
      form={form}
      initialValues={initialFormValues}
      onFinish={handleSubmit}
      // onValuesChange={handleValueChanges}
    >
      <Form.Item label="Key" name="key" hidden={true}>
        <Input id="key" disabled={isLoading} />
      </Form.Item>

      {isRecurring && (
        <Alert
          message="Currently, recurring appointments are read only in the web app. This appointment can be modified in the desktop app."
          type="warning"
        />
      )}

      {isEdit &&
      (clientStore?.activeClientKey ||
        (scheduleStore?.activeScheduleDetail && scheduleStore?.activeScheduleDetail?.ClientKey)) ? (
        <div className="form-group">
          <ClientRow clientKey={scheduleStore?.activeScheduleDetail?.ClientKey} />
        </div>
      ) : null}
      {((!isEdit && !appointmentStore?.unlinkedAppointment) ||
        (!appointmentStore?.unlinkedAppointment &&
          !scheduleStore?.activeScheduleDetail?.ClientKey &&
          !clientStore?.activeClientKey)) && (
        <div className="form-group">
          <Form.Item
            name="client"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
            rules={[{ required: false, message: "Please select a client!" }]}
          >
            {/*<ClientsSelectField disabled={isLoading || isEdit} />*/}
            <ClientsSelectField
              form={form}
              // defaultValue={clientStore?.activeClientKey}
              disabled={isLoading}
            />
          </Form.Item>
        </div>
      )}

      <Row className="form-group" gutter={20}>
        <Col {...formGrid(false, !screens.sm)}>
          <Form.Item
            name="date"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select date!",
            //   },
            // ]}
          >
            <DateField
              label="Date"
              prefix={<CalendarOutlined />}
              disabled={isLoading}
              suffixIcon={<></>}
              allowClear={false}
              placeholder=""
              defaultValue={dayjs(scheduleStore?.activeDate)}
              // value={dayjs(scheduleStore?.activeDate)}
            />
          </Form.Item>
        </Col>
        <Col {...formGrid(true)}>
          <Form.Item hasFeedback={isLoading} validateStatus={isLoading ? "validating" : undefined}>
            <TimeRangeField
              label="Time"
              prefix={<ClockCircleOutlined />}
              disabled={isLoading}
              suffixIcon={<></>}
              allowClear={false}
              format="hh:mm A"
              use12Hours={true}
              defaultValue={newTimeValues}
              value={newTimeValues}
              onCalendarChange={(values: RangeValue<Dayjs>) => {
                if (values?.[0] === null || values?.[1] === null) return;
                if (values?.[0].format("hh:mm A") === values?.[1].format("hh:mm A")) {
                  setNewTimeValues([values?.[0], dayjs(values?.[0]).add(1, "hour")]);
                  form?.setFieldsValue({
                    ...form.getFieldsValue(),
                    time: [values?.[0], dayjs(values?.[0]).add(1, "hour")],
                  });
                  setNewTimeValues([values?.[0], dayjs(values?.[0]).add(1, "hour")]);
                } else {
                  setNewTimeValues(values);
                  form?.setFieldsValue({
                    ...form.getFieldsValue(),
                    time: values,
                  });
                }
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <div className="form-group">
        <Form.Item
          name="description"
          hasFeedback={isLoading}
          validateStatus={isLoading ? "validating" : undefined}
        >
          <InputField
            prefix={<AlignLeftOutlined />}
            disabled={isLoading}
            label="Description"
            isTextarea={true}
          />
        </Form.Item>
        <Row gutter={20}>
          <Col {...formGrid()}>
            <Form.Item
              name="type"
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
              rules={[{ required: true, message: "Please select a type!" }]}
            >
              <AppointmentTypesSelectField disabled={isLoading} />
            </Form.Item>
          </Col>
          <Col {...formGrid()}>
            <Form.Item
              name="user"
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
              rules={[{ required: true, message: "Please select a user!" }]}
            >
              <UsersSelectField disabled={isLoading} prefix={<TeamOutlined />} />
            </Form.Item>
          </Col>
          <Col {...formGrid()}>
            <Form.Item
              name="status"
              rules={[{ required: true, message: "Please select status!" }]}
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
            >
              <AppointmentStatusSelectField disabled={isLoading} />
            </Form.Item>
          </Col>
          <Col {...formGrid()}>
            <Form.Item
              name="location"
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
            >
              <LocationsSelectField
                disabled={isLoading}
                label={
                  authStore?.companySettings?.LocationDescriptorSingular
                    ? authStore?.companySettings?.LocationDescriptorSingular
                    : "Location"
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="notes"
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
            >
              <NotesField noteType="appointment" disabled={isLoading} notes={notes} />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <Row className="form-group">
        <Col style={{ width: "3.5rem", paddingTop: 6 }}>
          <ProfileOutlined style={{ fontSize: 21 }} />
        </Col>
        <Col style={{ flexGrow: 1 }}>
          <Form.Item
            label="All Day"
            name="all_day"
            valuePropName="checked"
            className="checkedInline"
            colon={false}
          >
            <Switch disabled={isLoading} loading={isLoading} />
          </Form.Item>
          <Form.Item
            label="Cofirmed"
            name="confirmed"
            valuePropName="checked"
            className="checkedInline"
            colon={false}
          >
            <Switch disabled={isLoading} loading={isLoading} />
          </Form.Item>
          <Form.Item
            label="No Show"
            name="no_show"
            valuePropName="checked"
            className="checkedInline"
            colon={false}
          >
            <Switch disabled={isLoading} loading={isLoading} />
          </Form.Item>
          <Form.Item
            label="Canceled"
            name="canceled"
            valuePropName="checked"
            className="checkedInline"
            colon={false}
          >
            <Switch disabled={isLoading} loading={isLoading} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default inject(
  "clientStore",
  "authStore",
  "scheduleStore",
  "userStore",
  "appointmentStore"
)(observer(AddEditAppointment));
