import React, { useState, useEffect } from "react";
import "./image-editor.scss";
import { Slider } from "antd";
import { RotateLeftOutlined, ClearOutlined } from "@ant-design/icons";
import EffectSelector from "../effect-selector/effect-selector";
import { Input } from "antd";
import Cropper from "react-easy-crop";
import EventEmitter from "utils/event-emitter";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";

const { TextArea } = Input;
function slideformatter(value: any) {
  return `${value}x`;
}

const ImageEditor = (props: any) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [notes, setNotes] = useState("");
  const [item, setItem] = useState<any>(null);
  const [colorizationKey, setcolorizationKey] = useState("");
  const [ratioOrientaiton, setRatioOrientaiton] = useState(1);
  const [isVisible, setIsVisible] = useState(false);
  const [initialCropped, setInitialCropped] = useState<any>();
  const [cropApi, setCropApi] = useState<any>(null);
  const [currentProduct, setCurrentProduct] = useState<any>(null);
  const { galleryStore } = props;
  const { sessionImages } = galleryStore;

  useEffect(() => {
    // Register events
    _registerEvents();
  }, []);

  useEffect(() => {
    if (item) {
      _setProduct(item);
    }
  }, [item]);

  useEffect(() => {
    if (currentProduct) {
      _computeStateValues(item);
    }
  }, [currentProduct, item]);

  useEffect(() => {
    if (!isVisible) {
      _clearState();
    }
  }, [isVisible]);

  const _setProduct = (item: any) => {
    console.log("_setProduct item :>> ", item);
    console.log(
      "_setProduct flat :>> ",
      props.cartStore.categories.map((item: any) => item.Items).flat(1)
    );
    const product = props.cartStore.categories
      .map((item: any) => item.Items)
      .flat(1)
      .find((i: any) => i.Key === item.ItemKey);
    setCurrentProduct(toJS(product));
    console.log("_setProduct product :>> ", toJS(product));
  };

  const _computeStateValues = (item: any) => {
    console.log("currentProduct :>> ", currentProduct);
    if (item) {
      // Set initial notes
      setNotes(item.Notes);
      // Set initial colorizationKey
      setcolorizationKey(item.ColorizationKey);
      // Need to change / bind
      setCrop({ x: item.CropLeft * 100, y: item.CropTop * 100 });
      setZoom(1);
      setRatioOrientaiton(
        item.CropWidth < item.CropHeight
          ? currentProduct.CropRatio
          : 1 / currentProduct.CropRatio
      );
      setInitialCropped({
        x: Number(item.CropLeft),
        y: Number(item.CropTop),
        width: Number(item.CropWidth),
        height: Number(item.CropHeight),
      });
      setCropApi({
        x: item?.CropLeft * 100,
        y: item?.CropTop * 100,
        width: item?.CropWidth * 100,
        height: item?.CropHeight * 100,
      });
    }
  };

  const _resetImageEdits = () => {
    _computeStateValues(item);
  };

  const _clearState = () => {
    setNotes("");
    setcolorizationKey("");
    setItem(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setRatioOrientaiton(4 / 3);
  };

  const _registerEvents = () => {
    EventEmitter.listen("edit-item-image", (item: any) => {
      setItem(item);
      setIsVisible(true);
    });
  };

  const _hideEditor = () => {
    setIsVisible(false);
  };

  const _onSave = () => {
    // Prepare the data
    const editorPayload = {
      CropRotated: currentProduct.CropRatio !== ratioOrientaiton,
      CropLeft: cropApi.x / 100,
      CropWidth: cropApi.width / 100,
      CropHeight: cropApi.height / 100,
      CropTop: cropApi.y / 100,
      // OptionKey: optionKey,
      // EnhancementKey: enhancementKey,
      ColorizationKey: colorizationKey,
      Notes: notes,
    };
    console.log("editorPayload :>> ", editorPayload);
    props.cartStore.updateItem({
      ...item,
      ...editorPayload,
    });
  };

  async function firstMediaLoaded(e: any) {
    console.log("firstMediaLoaded");
    if (
      !!item.CropLeft ||
      !!item.CropTop ||
      !!item.CropWidth ||
      !!item.CropHeight
    ) {
      await setInitialCropped({
        x: e.naturalWidth * Number(item.CropLeft),
        y: e.naturalHeight * Number(item.CropTop),
        width: e.naturalWidth * Number(item.CropWidth),
        height: e.naturalHeight * Number(item.CropHeight),
      });
      setFirstLoaded(true);
    } else {
      await setInitialCropped({
        x: e.naturalWidth,
        y: 0,
        width: e.naturalWidth,
        height: e.naturalHeight,
      });
      setRatioOrientaiton(
        e.naturalWidth > e.naturalHeight
          ? currentProduct.CropRatio
          : 1 / currentProduct.CropRatio
      );
      setFirstLoaded(true);
    }
  }

  if (isVisible) {
    const image = toJS(sessionImages).find(
      (img: any) => img.fileName === item.FileName
    );
    let imageLink = image.thumbnailPath;
    if (colorizationKey && colorizationKey.length) {
      imageLink += `&colorizationKey=${colorizationKey}`;
    }
    return (
      <>
        <div className="cartImageEditor">
          <div className="previewer">
            {currentProduct && (
              <>
                <Cropper
                  style={{ containerStyle: { zIndex: -1000 } }}
                  image={image.thumbnailPath}
                  crop={crop}
                  zoom={zoom}
                  onCropChange={() => null}
                  onZoomChange={() => null}
                  onMediaLoaded={(e) => {
                    firstMediaLoaded(e);
                  }}
                  showGrid={false}
                  zoomSpeed={0.07}
                />
                {firstLoaded ? (
                  <Cropper
                    image={imageLink}
                    crop={crop}
                    zoom={zoom}
                    aspect={ratioOrientaiton}
                    initialCroppedAreaPixels={initialCropped}
                    onCropChange={setCrop}
                    onCropComplete={setCropApi}
                    onZoomChange={setZoom}
                    zoomSpeed={0.07}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          <div className="controlsWrapper">
            <div className="leftPart">
              <div className="sliderWrapper">
                <p>Adjust zoom level</p>
                <Slider
                  min={1}
                  max={3}
                  defaultValue={1}
                  step={0.1}
                  value={zoom}
                  tipFormatter={slideformatter}
                  trackStyle={{ backgroundColor: "black" }}
                  onChange={(zoom: number) => setZoom(zoom)}
                  handleStyle={{ borderColor: "black" }}
                />
              </div>
              <div className="controls">
                <button
                  onClick={(e) => setRatioOrientaiton(1 / ratioOrientaiton)}
                >
                  <RotateLeftOutlined />
                  &nbsp; Crop Orientation
                </button>
                <button onClick={_resetImageEdits}>
                  <ClearOutlined />
                  &nbsp; Reset
                </button>
              </div>
            </div>
            <div className="rightPart">
              <div className="label">Effect</div>
              <EffectSelector
                onEffectUpdate={(effectKey: string) =>
                  setcolorizationKey(effectKey)
                }
                value={colorizationKey}
              />
              <div className="label">Notes</div>
              <TextArea
                rows={4}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
            <div className="actions" onClick={_hideEditor}>
              <button className="cancelBtn">Cancel</button>
              <button className="saveBtn" onClick={_onSave}>
                Apply
              </button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default inject("galleryStore", "cartStore")(observer(ImageEditor));
