import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Button, Col, Form, Spin, Typography } from "antd";
import dayjs from "dayjs";
import ClientRow from "../rows/ClientRow";
import { MessageStoreClass } from "stores/MessageStore";
import { ClientStoreClass } from "stores/ClientStore";
import InnerHTML from "dangerously-set-html-content";
import { LeftCircleFilled, UserOutlined } from "@ant-design/icons";

interface Props {
  messageStore?: MessageStoreClass;
  clientStore?: ClientStoreClass;
  setShowMessageDetail?: any;
  setSelectedKey?: any;
}

const { Title, Text } = Typography;

const ViewEmail: React.FC<Props> = ({ messageStore, clientStore, setShowMessageDetail, setSelectedKey }) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (messageStore?.emailDetails?.ClientKey) {
      clientStore?.setActiveClientKey(messageStore.emailDetails.ClientKey);
    }
    if (typeof messageStore?.emailDetails !== "undefined") {
      form?.setFieldsValue({
        ClientKey: messageStore?.emailDetails.ClientKey,
      });
    }
  }, [messageStore?.emailDetails]);

  useEffect(() => {
    return () => {
      messageStore?.setEmailDetails(undefined);
    };
  }, []);

  return (
    <Col xs={24} sm={18} className="Message_Detail">
      <Spin size={'default'} spinning={messageStore?.isLoading}>
        <Col className="MD__Panel">
          <Col className="MD__Panel--Head">
            <Col className="BackButton">
              <Button onClick={() => { setShowMessageDetail(false); setSelectedKey(''); }}><LeftCircleFilled /></Button>
            </Col>
            <Col className="MD__Panel--Head__Left">
              {messageStore?.emailDetails?.ClientKey && (
                <Col className="MD__Panel--Head__Subject">
                  {/* <Title level={3}>{getClientName()}</Title> */}
                  <ClientRow clientKey={messageStore?.emailDetails?.ClientKey} />
                </Col>
              )}
              <Col className="MD__Panel--Head__Detail">
                <Col className="MD__Panel--Head__Detail--Icon">
                  <UserOutlined />
                </Col>
                <Col className="MD__Panel--Head__Detail--Data">
                  <Text><b>From:</b> {messageStore?.emailDetails?.From}</Text>
                  <Text><b>To:</b> {messageStore?.emailDetails?.To}</Text>
                  <Text><b>Date/Time:</b> {dayjs(messageStore?.emailDetails?.SentDateTime).format("YYYY-MM-DD / hh:mm A")}</Text>
                </Col>
              </Col>
            </Col>
            <Col className="MD__Panel--Head__Right">
              {/* <Button><DeleteFilled /></Button> */}
            </Col>
          </Col>
          <Col className="MD__Panel--Body">
            <Col className="MD__Panel--Body__Title">
              <Title level={4}>{messageStore?.emailDetails?.Subject}</Title>
              <Text>{messageStore?.emailDetails?.Incoming === false ? "Outgoing" : "Incoming"}</Text>
            </Col>
            <Col className="MD__Panel--Body__Description">
              {messageStore?.emailDetails?.Body && (
                <InnerHTML html={messageStore?.emailDetails?.Body} />
              )}
            </Col>
          </Col>
        </Col>
      </Spin>
    </Col>
    // <>
    //   {messageStore?.emailDetails?.Key &&
    //     messageStore?.emailDetails?.ClientKey && (
    //       <div className="form-group">
    //         <Form.Item
    //           name="ClientKey"
    //           hasFeedback={isLoading}
    //           validateStatus={isLoading ? "validating" : undefined}
    //         >
    //           <ClientRow clientKey={messageStore?.emailDetails?.ClientKey} />
    //         </Form.Item>
    //       </div>
    //     )}
    //   <Row className="form-group" gutter={20}>
    //     <Descriptions
    //       title="Message Information"
    //       layout="vertical"
    //       colon={false}
    //       column={2}
    //     >
    //       <Descriptions.Item label="From" labelStyle={labelStyle}>
    //         {messageStore?.emailDetails?.From}
    //       </Descriptions.Item>
    //       <Descriptions.Item label="To" labelStyle={labelStyle}>
    //         {messageStore?.emailDetails?.To}
    //       </Descriptions.Item>
    //       <Descriptions.Item label="CC" labelStyle={labelStyle}>
    //         {messageStore?.emailDetails?.CC}
    //       </Descriptions.Item>
    //       <Descriptions.Item label="BCC" labelStyle={labelStyle}>
    //         {messageStore?.emailDetails?.BCC}
    //       </Descriptions.Item>
    //       <Descriptions.Item label="Subject" labelStyle={labelStyle}>
    //         {messageStore?.emailDetails?.Subject}
    //       </Descriptions.Item>
    //       <Descriptions.Item label="Date/Time" labelStyle={labelStyle}>
    //         {dayjs(messageStore?.emailDetails?.SentDateTime).format(
    //           "YYYY-MM-DD / hh:mm A"
    //         )}
    //       </Descriptions.Item>
    //       <Descriptions.Item label="Direction" labelStyle={labelStyle}>
    //         {messageStore?.emailDetails?.Incoming === false
    //           ? "Outgoing"
    //           : "Incoming"}
    //       </Descriptions.Item>
    //     </Descriptions>
    //   </Row>

    //   <Row className="form-group" gutter={20}>
    //     <Descriptions title="Email Body">
    //       <Descriptions.Item>
    //         {messageStore?.emailDetails?.Body && (
    //           <InnerHTML html={messageStore?.emailDetails?.Body} />
    //         )}
    //       </Descriptions.Item>
    //     </Descriptions>
    //   </Row>
    // </>
  );
};

export default inject(
  "messageStore",
  "scheduleStore",
  "clientStore"
)(observer(ViewEmail));
