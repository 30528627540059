import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { List, Typography } from "antd";

import "./StatusBucket.scss";
import { UserOutlined } from "@ant-design/icons";
import { ClientStoreClass } from "stores/ClientStore";

const { Text } = Typography;


interface Props {
    clientStatusKey?: any;
    clientStore?: ClientStoreClass;
}

const ClientBucket: React.FC<Props> = ({ clientStatusKey, clientStore }) => {
    
    const [clientList, setClientList] = useState<any>();

    const generateClientList = async () => {
        const list = await clientStore?.getClientsByStatus(clientStatusKey);
        setClientList(list);
    }

    useEffect(() => {
        if (clientStatusKey) generateClientList();
    }, [clientStatusKey]);


    return (
        <>
            {clientList ?
                <List
                    dataSource={clientList}
                    loadMore={true}
                    pagination={{ pageSize: 5 }}
                    renderItem={(item: any) => {
                        return (
                            <List.Item>
                                <div className="quickAccessList">
                                    <div className="quickAccessList__Date">
                                        <label className="client_icon">
                                            <UserOutlined />
                                        </label>
                                    </div>
                                    <div className="quickAccessList__Content">
                                        <div className="quickAccessList__Content--Body">
                                            <div className="data-panel">
                                                <div className="status-block">
                                                    <Text className="taskStatus red" mark>Client No: {item.ClientNumber}</Text>
                                                </div>
                                                <p>{item.FirstName + ' ' + item.LastName}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </List.Item>
                        );
                    }}
                    bordered
                    style={{ border: 0, backgroundColor: "#fff" }}
                /> : null}
        </>
    );

}

export default inject("clientStore")(observer(ClientBucket));