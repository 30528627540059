import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import {
  Layout,
  Card,
  Grid,
  Button,
  Col,
  Form,
  Row,
  Select,
  Drawer,
  Space,
  Affix,
  DatePicker,
} from "antd";
import classnames from "classnames";
import TaskDrawerForm from "components/drawers/TaskDrawerForm";
import TasksList from "../../components/lists/TasksList";
import ClientTabs from "components/tabs/ClientTabs";
import { TaskStoreClass } from "stores/TaskStore";
import { AuthStoreClass } from "stores/AuthStore";

import "./TasksPage.scss";
import ActionsTasks from "components/tasks/actions/ActionsTasks";
import SearchDropDown from "components/search/SearchDropdown";
import { ClientStoreClass } from "stores/ClientStore";
import SearchInput from "components/forms/SearchInput";
import dayjs from "dayjs";
import SearchSelect from "components/forms/items/SearchSelect";
import { UserStoreClass } from "stores/UserStore";

const { Option, OptGroup } = Select;
const { Content } = Layout;
const { useBreakpoint } = Grid;

interface Props {
  taskStore?: TaskStoreClass;
  authStore?: AuthStoreClass;
  clientStore?: ClientStoreClass;
  userStore?: UserStoreClass;
}

const TasksPage: React.FC<Props> = ({ taskStore, authStore, clientStore, userStore }) => {
  const screens = useBreakpoint();
  const [container] = useState<HTMLDivElement | null>(null);

  const [value, setValue] = useState<string>("");
  const [searchAutocomplete, setSearchAutocomplete] = useState<any>([]);

  let timeout: ReturnType<typeof setTimeout>;

  const searchEntities: any = {
    Tasks: [
      { value: "assignedToUser", key: "Assigned to User" },
      { value: "taskNumber", key: "Task Number" },
      { value: "taskType", key: "Task Type" },
      { value: "taskStatus", key: "Task Status" },
      { value: "taskDueDate", key: "Task Due Date" },
      { value: "assignedToDepartment", key: "Department" },
    ],
  };

  useEffect(() => {
    taskStore?.getTaskTypes();
    taskStore?.getTaskStatuses();
    taskStore?.getDepartments();
  }, [taskStore]);

  //Re used the same function for Date Select
  const onSearchSelect = async (value: any) => {
    let taskData: any;
    if (clientStore?.searchBy?.value === "taskDueDate") {
      taskData = {
        action: clientStore?.searchBy?.value,
        value: dayjs(value._d).format("YYYY-MM-DD"),
      };
      setValue(dayjs(value._d).format("YYYY-MM-DD"));
    }

    if (
      clientStore?.searchBy?.value === "taskType" ||
      clientStore?.searchBy?.value === "taskStatus" ||
      clientStore?.searchBy?.value === "assignedToDepartment" ||
      clientStore?.searchBy?.value === "assignedToUser"
    ) {
      taskData = { action: clientStore?.searchBy?.value, value: value.key };
      setValue(value.key);
    }

    taskStore?.setActiveSearch(taskData);
    try {
      await taskStore?.getTasksViaSearch();
    } catch (err) {}
  };

  const onEnter = async () => {
    // clearTimeout(timeout);

    const taskData = { action: clientStore?.searchBy?.value, value: value };
    taskStore?.setActiveSearch(taskData);
    try {
      await taskStore?.getTasksViaSearch();
    } catch (err) {}

    clearTimeout(timeout);
  };

  const onKey = async (e: { keyCode: number; target: { value: any } }) => {
    // clearTimeout(timeout);
    if (e.keyCode === 13) {
      // Prevents loading again for enter value
      return;
    }
    const taskData = { action: clientStore?.searchBy?.value, value: value };
    taskStore?.setActiveSearch(taskData);
    try {
      await taskStore?.getTasksViaSearch();
    } catch (err) {}

    clearTimeout(timeout);
  };

  const generateSearchAutocomplete = (searchByValue: any) => {
    let options: any[] = [];
    if (searchByValue === "taskType") {
      options.push({ value: "All Types", key: "all types" });
      taskStore?.taskTypes.map((item: any) => {
        options.push({ value: item.Description, key: item.Key });
      });
      setSearchAutocomplete(options);
    } else if (searchByValue === "taskStatus") {
      options.push({ value: "All Statuses", key: "all statuses" });
      taskStore?.taskStatuses.map((item: any) => {
        options.push({
          value: `${item.Description} - ${item.TaskTypeDescription} Type`,
          key: item.Key,
        });
      });
      setSearchAutocomplete(options);
    } else if (searchByValue === "assignedToDepartment") {
      options.push(
        { value: "All departments", key: "all department" },
        { value: "Unassigned", key: "un assigned" }
      );
      taskStore?.departments.map((item: any) => {
        options.push({ value: item.Description, key: item.Key });
      });
      setSearchAutocomplete(options);
    } else if (searchByValue === "assignedToUser") {
      options.push({ value: "Any", key: "any" }, { value: "Unassigned", key: "unassigned" });
      userStore?.selectUserList?.map((item: any) => {
        options.push({ value: item.label, key: item.value });
      });
      setSearchAutocomplete(options);
    } else {
      setSearchAutocomplete("");
    }
  };

  useEffect(() => {
    generateSearchAutocomplete(clientStore?.searchBy.value);
    setValue("");
  }, [clientStore?.searchBy]);

  useEffect(() => {
    clientStore?.setSearchBy(searchEntities["Tasks"][0]);
  }, [clientStore]);

  useEffect(() => {
    setValue("");
    clientStore?.updateSearchType("Tasks");
  }, [clientStore?.searchType]);

  const getTaskByAuthUser = async () => {
    const taskData = { action: "assignedToUser", value: authStore?.authenticatedUser.UserKey };
    taskStore?.setActiveSearch(taskData);
    await taskStore?.getTasksViaSearch();
  };

  useEffect(() => {
    getTaskByAuthUser();
  }, []);

  useEffect(() => {
    return () => {
      taskStore?.cleanUp();
    };
  }, []);

  const _showDrawer = async () => {
    setDrawerVisibility(true);
  };

  const _hideDrawer = async () => {
    setDrawerVisibility(false);
  };
  const [drawerVisibility, setDrawerVisibility] = useState(false);

  return (
    <>
      <Content className="main-content">
        <ActionsTasks onShowDrawer={_showDrawer} />
        <Drawer
          title="Filter Tasks"
          extra={
            <Space>
              <Button onClick={_hideDrawer} type="primary">
                Submit
              </Button>
            </Space>
          }
          onClose={_hideDrawer}
          visible={drawerVisibility}
        >
          <Form layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="user"
                  label="User name"
                  rules={[{ required: true, message: "Please enter user name" }]}
                >
                  <Select defaultValue="lucy">
                    <OptGroup label="">
                      <Option value="jack">Jack</Option>
                      <Option value="lucy">Lucy</Option>
                      <Option value="Yiminghe">yiminghe</Option>
                    </OptGroup>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="user"
                  label="Task Status"
                  rules={[{ required: true, message: "Please enter user name" }]}
                >
                  <Select defaultValue="jack">
                    <OptGroup label="">
                      <Option value="jack">All</Option>
                      <Option value="lucy">Completed</Option>
                      <Option value="Yiminghe">Incomplete</Option>
                    </OptGroup>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
        <div
          className={classnames({
            "main-content-pads task-page": screens.md,
          })}
        >
          <Card
            bordered={false}
            className=""
            headStyle={{ border: "none" }}
            title={
              <>
                <Row
                  style={{ alignItems: "center" }}
                  gutter={[16, 16]}
                  align="middle"
                  justify="center"
                >
                  <Col xs={12} sm={8}>
                    <SearchDropDown
                      defaultValue={searchEntities["Tasks"][0]}
                      label={"Search By"}
                      searchValues={searchEntities["Tasks"]}
                      clientStore={clientStore}
                    />
                  </Col>
                  <Col xs={24} sm={16}>
                    <Affix target={() => container} offsetTop={70}>
                      {clientStore?.searchBy.value === "taskDueDate" ? (
                        <DatePicker
                          onChange={onSearchSelect}
                          size={"large"}
                          allowClear={false}
                          className="search-date-picker"
                          placeholder="Please select Task Due Date"
                        />
                      ) : clientStore?.searchBy.value === "taskType" ||
                        clientStore?.searchBy.value === "taskStatus" ||
                        clientStore?.searchBy.value === "assignedToDepartment" ||
                        clientStore?.searchBy.value === "assignedToUser" ? (
                        <SearchSelect
                          placeholder={`Please select ${
                            clientStore?.searchBy?.children
                              ? clientStore?.searchBy?.children.toLowerCase()
                              : clientStore?.searchBy?.key.toLowerCase()
                          } here to search`}
                          searchClass="search-listing"
                          searchAutocomplete={searchAutocomplete}
                          onEnter={onSearchSelect}
                          searchValue={value}
                          defaultValues={
                            clientStore?.searchBy.value === "assignedToUser"
                              ? authStore?.authenticatedUser?.Name
                              : ""
                          }
                        />
                      ) : (
                        <SearchInput
                          placeholder={`Please type ${
                            clientStore?.searchBy?.children
                              ? clientStore?.searchBy?.children.toLowerCase()
                              : clientStore?.searchBy?.key.toLowerCase()
                          } here to search`}
                          searchClass="search-listing"
                          onKeyUp={(e: any) => {
                            onKey(e);
                          }}
                          onChange={(e) => {
                            setValue(e.target.value);
                            // clearTimeout(timeout);
                          }}
                          onPressEnter={() => {
                            onEnter();
                            // clearTimeout(timeout);
                          }}
                          value={value}
                        />
                      )}
                    </Affix>
                  </Col>
                </Row>
              </>
            }
          >
            <TasksList style={{ border: 0 }} />
          </Card>
        </div>
      </Content>
      <TaskDrawerForm />
      <ClientTabs />
    </>
  );
};

export default inject("taskStore", "authStore", "clientStore", "userStore")(observer(TasksPage));
