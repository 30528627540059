import { observable, action, computed, makeObservable, toJS } from "mobx";
import { create, persist } from "mobx-persist";
import axios, { AxiosResponse, AxiosError } from "axios";
import dayJs from "dayjs";

import ScheduleStore from "./ScheduleStore";
import ClientStore from "./ClientStore";
import {
  SessionList,
  SessionDetail,
  SessionTypeList,
  SessionStatusList,
  PhotographerSchedule,
  PhotographerSessionInfo,
  PhotographerSessionCommon,
  SessionPromotionList,
} from "interfaces/session.int";
import { saveLocalStorage } from "components/util/localStorage";
import setAxiosDefaults from "../components/axios-config";
import AlertStore from "./AlertStore";

export class SessionStoreClass {
  @persist("list") sessionList: Array<SessionList> | undefined;
  @persist("list") sessionTypesList: Array<SessionTypeList> = [];
  @persist("list") sessionStatusList: Array<SessionStatusList> = [];
  @persist("list") sessionPromotionList: Array<SessionPromotionList> = [];
  @persist("list") photographerSessionsList: Array<PhotographerSchedule> = [];
  @persist("object") photographerSessionInfo: PhotographerSessionInfo | undefined;
  @persist("object") photographerSessionCommon: PhotographerSessionCommon = {};
  isSelectToggle: boolean = false;
  isLoading: boolean = false;
  activeSearch: any = {};
  currentSessionDetail: any = {};
  sessionDetail: any = {};
  photographerAPIAccountName: string = "life365";

  constructor() {
    makeObservable(this, {
      init: action,
      cleanUp: action,
      isLoading: observable,
      sessionList: observable,
      getSessionList: action,
      getSessionTypes: action,
      sessionTypesList: observable,
      selectSessionList: computed,
      addSession: action,
      getSessionDetail: action,
      sessionStatusList: observable,
      sessionPromotionList: observable,
      getSessionTypeFromList: action,
      selectSessionStatusList: computed,
      selectSessionPromotionList: computed,
      selectSessionPromotionCodeList: computed,
      getSessionStatusFromList: action,
      getSessionStatusFromListByDescription: action,
      getSessionsFromScheduleList: action,
      isSelectToggle: observable,
      setSelectToggle: action,
      photographerSessionsList: observable,
      photographerSessionInfo: observable,
      activeSearch: observable,
      setActiveSearch: action,
      setPhotographerAPIAccountName: action,
      currentSessionDetail: observable,
      sessionDetail: observable,
      getSessionsByStatus: action,
    });
  }

  async init() {
    await this.getSessionPromotion();
  }

  cleanUp() {
    this.sessionTypesList = [];
    this.sessionStatusList = [];
    ScheduleStore.resourceList = [];
    this.sessionPromotionList = [];
  }

  setSelectToggle(toggle: boolean) {
    this.isSelectToggle = toggle;
  }

  async getSessionList(clientKey: string | null | undefined) {
    if (clientKey === null || clientKey === undefined) return;

    this.isLoading = true;
    return await axios({
      method: "GET",
      url: "/Sessions",
      params: {
        clientKey,
      },
    })
      .then(
        action((res: AxiosResponse) => {
          let sortedData = res.data.sort((a: any, b: any) =>
            dayJs(b.StartDate).diff(dayJs(a.StartDate))
          );
          this.sessionList = toJS(sortedData);

          this.isLoading = false;
          saveLocalStorage({
            name: "Synced",
            value: `Sessions`,
          });
          return sortedData;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async getSessionDetail(id: string | null | undefined) {
    if (id === null || id === undefined) return;
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/Session/${id}`,
    })
      .then(
        action((res: AxiosResponse) => {
          ScheduleStore.setActiveScheduleDetail(res.data);
          ClientStore.setActiveClientKey(res.data.ClientKey);
          this.isLoading = false;
          this.currentSessionDetail = toJS(res.data);
          this.sessionTypesList = res.data.SessionTypeList;
          // ScheduleStore.resourceList = res.data.ResourceList;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async getSessionTypes() {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/SessionType`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.sessionTypesList = res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async addSession(session: SessionDetail) {
    this.isLoading = true;
    return await axios({
      method: "PUT",
      url: "/Session",
      data: session,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.sessionDetail = res.data;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async updateSession(
    session: SessionDetail,
    authToken: string,
    latitude?: string,
    longitude?: string
  ) {
    setAxiosDefaults(this.photographerAPIAccountName, authToken);
    this.isLoading = true;
    return await axios({
      method: "PUT",
      url: "/Session",
      data: session,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          if (session && session.Key)
            this.getPhotographerSessionInfo(session.Key, authToken, latitude, longitude);
          // return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async getPhotographerSchedule(scheduleDate: string, authToken: string) {
    this.clearPhotographerInfo();
    setAxiosDefaults(this.photographerAPIAccountName, authToken);
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: "/PhotographerSchedule",
      params: {
        ...(scheduleDate ? { scheduleDate: scheduleDate } : {}),
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.photographerSessionsList = res.data.Sessions;
          this.photographerSessionCommon.NotHomePhoneNumber = res.data.NotHomePhoneNumber;
          this.photographerSessionCommon.PhotographerChecklistUrl =
            res.data.PhotographerChecklistUrl;
          this.photographerSessionCommon.PickUpSessionUrl = res.data.PickUpSessionUrl;
          this.photographerSessionCommon.QRCodeUrl = res.data.QRCodeUrl;
          this.photographerSessionCommon.TimeClockUrl = res.data.TimeClockUrl;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        // throw new Error(err.message);
      });
  }

  async getPhotographerSessionInfo(
    sessionKey: string,
    authToken: string,
    latitude?: string,
    longitude?: string
  ) {
    // latitude  = "44.9736";
    // longitude = "-98.2575";
    setAxiosDefaults(this.photographerAPIAccountName, authToken);
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: "/PhotographerSessionInfo",
      params: {
        sessionKey,
        ...(latitude ? { currentLatitude: latitude } : {}),
        ...(longitude ? { currentLongitude: longitude } : {}),
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.photographerSessionInfo = res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        // throw new Error(err.message);
      });
  }

  async clearPhotographerInfo() {
    this.photographerSessionInfo = undefined;
  }

  async textClientImComing(sessionKey: string, authToken: string, messageText: any) {
    setAxiosDefaults(this.photographerAPIAccountName, authToken);
    this.isLoading = true;
    return await axios({
      method: "PUT",
      url: "/SMSTextMessage?sendMessage=true",
      data: {
        sessionKey,
        messageText,
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          return "Message sent to client";
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
        // return err.message
      });
  }

  async setNoViewingScheduled(sessionKey: string, authToken: string) {
    setAxiosDefaults(this.photographerAPIAccountName, authToken);
    this.isLoading = true;
    return await axios({
      method: "PUT",
      url: "/Life365Trigger",
      data: {
        recordKey: sessionKey,
        triggerKey: "00-999-10262",
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          return "Trigger set.";
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
        // return err.message
      });
  }

  getSessionTypeByKey(key: string) {
    return this.sessionTypesList?.find((v: SessionTypeList) => {
      return v.Key === key;
    });
  }

  getSessionTypeFromList(key: string, field?: string): SessionTypeList | string {
    let filtered: any = this.sessionTypesList?.filter((v: SessionTypeList) => key === v.Key)[0];
    let mapped = field ? filtered[field] : filtered;

    return mapped;
  }

  async getSessionResults(sessionStatusKey: string) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/SessionResults`,
      params: {
        sessionStatusKey,
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        AlertStore.setAlertMessage(err.message, "error");
      });
  }

  get selectSessionList() {
    return this.sessionTypesList
      .map((v: SessionTypeList) => {
        return {
          label: v.Description,
          value: v.Key,
        };
      })
      .sort((a, b) => {
        var nameA = a?.label?.toUpperCase();
        var nameB = b?.label?.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  }

  async getSessionStatus() {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/SessionStatus`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.sessionStatusList = res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        // throw new Error(err.message);
      });
  }

  async getSessionPromotion() {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/SessionPromotion`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.sessionPromotionList = res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        // throw new Error(err.message);
      });
  }

  getSessionStatusFromList(key: string, field?: string): SessionStatusList | string {
    let filtered: any = this.sessionStatusList?.filter((v: SessionStatusList) => key === v.Key)[0];
    let mapped = field ? filtered[field] : filtered;

    return mapped;
  }

  getSessionStatusFromListByDescription(
    description: string,
    field?: string
  ): SessionStatusList | string {
    let filtered: any = this.sessionStatusList?.filter(
      (v: SessionStatusList) => description === v.Description
    )[0];
    let mapped = field ? filtered[field] : filtered;

    return mapped;
  }

  get selectSessionStatusList() {
    return this.sessionStatusList.map((v: SessionStatusList) => {
      return {
        label: v.Description,
        value: v.Key,
      };
    });
    // // Removed sorting as requested in Bug 8128
    // .sort((a, b) => {
    //   var nameA = a?.label?.toUpperCase();
    //   var nameB = b?.label?.toUpperCase();
    //   if (nameA < nameB) {
    //     return -1;
    //   }
    //   if (nameA > nameB) {
    //     return 1;
    //   }
    //   return 0;
    // });
  }

  get selectSessionPromotionList() {
    return this.sessionPromotionList.map((v: SessionPromotionList) => {
      return {
        label: v.Description,
        value: v.Key,
      };
    });
  }

  get selectSessionPromotionCodeList() {
    return this.sessionPromotionList.map((v: SessionPromotionList) => {
      return {
        label: v.PromotionCode,
        value: v.Key,
      };
    });
  }

  async getSessionsFromScheduleList(clientKey: string) {
    const sessionKeys: Array<string | undefined> = [];
    await this.getSessionList(clientKey).then((res: Array<SessionList>) => {
      res.map((r) => {
        sessionKeys.push(r.Key);
        return true;
      });
    });
    if (ScheduleStore?.scheduleList.length <= 0) {
      await ScheduleStore?.getCalendarList(
        ScheduleStore.filterUserKey,
        ScheduleStore.filterResourceKey
      );
    }

    return ScheduleStore?.scheduleList
      .filter((item) => sessionKeys.includes(item?.SessionKey))
      .sort((a, b) => {
        if (a.StartDateTime && b.StartDateTime) {
          if (a.StartDateTime < b.StartDateTime) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
        }
        return 0;
      });
  }

  setActiveSearch(search: any) {
    this.activeSearch = search;
  }

  setPhotographerAPIAccountName(accountName: string) {
    this.photographerAPIAccountName = accountName;
  }

  async getSessionsViaSearch() {
    this.isLoading = true;
    const searchTerm = toJS(this.activeSearch);
    const searchAction = searchTerm?.action;
    const searchValue = searchTerm?.value;
    return await axios({
      method: "POST",
      url: "/Sessions/search",
      data: { [searchAction]: searchValue },
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.sessionList = res.data;
          saveLocalStorage({
            name: "Synced",
            value: `Sessions`,
          });
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
        // return err.message;
      });
  }

  async getSessionsByStatus(StatusKey: string) {
    this.isLoading = true;
    return await axios({
      method: "POST",
      url: "/Sessions/search",
      data: { SessionStatus: StatusKey },
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
        // return err.message;
      });
  }
}

const hydrate = create({});

const SessionStore = new SessionStoreClass();

export default SessionStore;

hydrate("session", SessionStore).then(() => {});
