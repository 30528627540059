import { observable, action, makeObservable, computed } from "mobx";
import { create, persist } from "mobx-persist";
import axios, { AxiosResponse, AxiosError } from "axios";
import update from "immutability-helper";

import type { OnSearchProps } from "components/forms/SearchInvoice";
import type { InvoiceList, Invoice, InvoiceProductLine } from "interfaces/invoice.int";
import { saveLocalStorage } from "components/util/localStorage";
import AlertStore from "./AlertStore";

export class InvoiceStoreClass {
  @persist("list") invoiceList: Array<InvoiceList> | undefined;
  @persist("list") invoiceProductLineList: Array<InvoiceProductLine> = [];
  @persist("object") activeSearch: OnSearchProps = {
    action: "ClientNumber",
    value: "",
  };
  activeInvoice: Invoice | undefined;
  isSelectToggle: boolean = false;
  isLoading: boolean = false;
  activePayment: any = {};
  isCreatingNew: boolean = false;
  @persist("list") invoiceStatuses: Array<any> = [];
  @persist drawerType: "payment" | "detail" = "detail";
  @persist("list") priceList: Array<any> = [];
  @persist("list") sessionImages: Array<any> = [];
  @persist("list") renderProfiles: Array<any> = [];
  constructor() {
    makeObservable(this, {
      invoiceList: observable,
      activeInvoice: observable,
      invoiceStatuses: observable,
      priceList: observable,
      activePayment: observable,
      renderProfiles: observable,
      isCreatingNew: observable,
      setActiveInvoice: action,
      activeSearch: observable,
      drawerType: observable,
      getInvoiceStatuses: action,
      setDrawerType: action,
      setActiveSearch: action,
      getInvoiceList: action,
      getInvoiceDetails: action,
      getPayment: action,
      getRenderProfiles: action,
      setActivePayment: action,
      putInvoiceLineItem: action,
      deleteInvoiceLineItem: action,
      deleteInvoice: action,
      setCreatingNew: action,
      addInvoice: action,
      getPriceList: action,
      getSessionImages: action,
      addPayment: action,
      openBalanceInvoiceList: computed,
      sessionImages: observable,
      isLoading: observable,
      isSelectToggle: observable,
      setSelectToggle: action,
      invoiceProductLineList: observable,
      getInvoiceProductLineList: action,
      cleanUp: action,
    });
  }

  get openBalanceInvoiceList() {
    return this.invoiceList?.filter((invoice: InvoiceList) => invoice.OpenBalance! > 0);
  }

  async getInvoiceList(params: OnSearchProps) {
    this.isLoading = true;

    return await axios({
      method: "GET",
      url: "/InvoiceList",
      params,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.invoiceList = res.data;
          saveLocalStorage({
            name: "Synced",
            value: `Invoices`,
          });
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error();
      });
  }

  async getRenderProfiles() {
    this.isLoading = true;

    return await axios({
      method: "GET",
      url: "/RenderProfilesList",
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.renderProfiles = res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  async getInvoices(params: any = {}) {
    this.isLoading = true;
    let searchParams: any = {};
    searchParams[params["action"]] = params["value"];

    return await axios({
      method: "GET",
      url: "/Invoices",
      params: searchParams,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.invoiceList = res.data;
          saveLocalStorage({
            name: "Synced",
            value: `Invoices`,
          });
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error();
      });
  }

  async getInvoicesByStatus(StatusKey: string) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/Invoices?invoiceStatus=${StatusKey}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error();
      });
  }

  async getInvoiceDetails(key: string) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/Invoice/${key}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.activeInvoice = res.data;
          this.isLoading = false;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error();
      });
  }

  async getPayment(key: string) {
    return await axios({
      method: "GET",
      url: `/payment/${key}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.activePayment = res.data;
        })
      )
      .catch((err: AxiosError) => {
        AlertStore.setAlertMessage(err.message, "error");
      });
  }

  async getInvoiceStatuses() {
    return await axios({
      method: "GET",
      url: `/InvoiceStatus`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.invoiceStatuses = res.data;
        })
      )
      .catch((err: AxiosError) => {
        AlertStore.setAlertMessage(err.message, "error");
      });
  }

  async addInvoice(data: Invoice) {
    // Client number: 1565 / abcdefg hi
    // Session number: hi2164
    // Invoice number: 4314
    this.isLoading = true;

    return await axios({
      method: "PUT",
      url: "/Invoice",
      data,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.activeInvoice = { ...res.data };
          if (data.Key) AlertStore.setAlertMessage("Success", "success");
          this.isCreatingNew = false;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        AlertStore.setAlertMessage(err.message, "error");
      });
  }

  async putInvoiceLineItem(data: any) {
    this.isLoading = true;
    AlertStore.setAlertMessage("Saving", "info", 1000);
    return await axios({
      method: "PUT",
      url: "/ibyInvoiceLineItem",
      data,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.getInvoiceDetails(res.data.Key);
          AlertStore.setAlertMessage("Success", "success");
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        AlertStore.setAlertMessage(err.message, "error");
      });
  }
  async deleteInvoiceLineItem(invoiceKey: string, lineItemKey: string) {
    this.isLoading = true;

    return await axios({
      method: "DELETE",
      url: `/ibyInvoiceLineItem/${lineItemKey}`,
      params: {
        invoiceKey,
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.getInvoiceDetails(invoiceKey);
          AlertStore.setAlertMessage("Deleted Item", "success");
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        AlertStore.setAlertMessage(err.message, "error");
      });
  }

  async deleteInvoice(invoiceKey: string) {
    this.isLoading = true;

    return await axios({
      method: "DELETE",
      url: `/IBYInvoice/${invoiceKey}`,
      params: {
        invoiceKey,
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.activeInvoice = {};
          AlertStore.setAlertMessage("Deleted Invoice", "success");
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        AlertStore.setAlertMessage(err.message, "error");
      });
  }

  async addPayment(data: any) {
    this.isLoading = true;

    return await axios({
      method: "PUT",
      url: "/Payment",
      data,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          AlertStore.setAlertMessage("Success", "success");
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        AlertStore.setAlertMessage(err.message, "error");
      });
  }

  async getPriceList(sessionKey: string) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/ibyPriceList/${sessionKey}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.priceList = res.data.Groups;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        AlertStore.setAlertMessage(err.message, "error");
      });
  }

  async getSessionImages(sessionKey: string) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/ibySession/${sessionKey}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          if (res.data.Images) this.sessionImages = res.data.Images.Images;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        AlertStore.setAlertMessage(err.message, "error");
      });
  }

  async getInvoiceProductLineList() {
    this.isLoading = true;

    return await axios({
      method: "GET",
      url: "/ProductLine",
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.invoiceProductLineList = res.data;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error();
      });
  }

  setActiveInvoice(data: Invoice | undefined) {
    this.activeInvoice = data;
  }

  setDrawerType(type: "detail" | "payment") {
    this.drawerType = type;
  }

  setActiveSearch(search: any) {
    this.activeSearch = update(this.activeSearch, {
      $merge: search,
    });
  }

  setSelectToggle(toggle: boolean) {
    this.isSelectToggle = toggle;
  }

  setActivePayment(newPayment: any) {
    this.activePayment = newPayment;
  }

  setCreatingNew(isCreatingNew: boolean) {
    this.isCreatingNew = isCreatingNew;
  }

  cleanUp() {
    this.invoiceList = [];
    this.invoiceStatuses = [];
  }
}

const hydrate = create({});

const InvoiceStore = new InvoiceStoreClass();

export default InvoiceStore;

hydrate("invoice", InvoiceStore).then(() => {});
