import React, { useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import dayjs from "dayjs";
import { Form, Button, Row, Col, Layout, Card, Typography, Image, message } from "antd";
import { UserOutlined, PaperClipOutlined, LockOutlined } from "@ant-design/icons";

import "./LoginPage.scss";

import InputField from "components/forms/items/InputField";

const { Title } = Typography;

const LoginPage: React.FC<any> = ({ authStore, match }) => {
  const { Content } = Layout;
  const history = useHistory();
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const navigateTo = params.get("navigateTo");
  const [form] = Form.useForm();

  useEffect(() => {
    if (authStore.isAuthenticated && navigateTo) {
      history.push(`/${navigateTo}`);
    }
    if (authStore.isAuthenticated && history.location.pathname === "/") {
      history.push("/home");
    }
  }, [authStore.isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authStore.authErrorMessage.length > 0) {
      message.error(authStore.authErrorMessage);
    }
  }, [authStore.authErrorMessage]);

  const initialValues = {
    accountName: authStore.accountName,
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const handleOnSubmit = async (values: any) => {
    const { accountName, userName, password } = values;
    await authStore.login(accountName, userName, password);
  };

  return (
    <Content className="login-module">
      <div className="container">
        <Card>
          <Image width={160} src="/assets/logo.png" alt="logo" className="logo" preview={false} />
          <Title level={1}>An easier way to manage your business</Title>
          <p></p>
          <Form
            form={form}
            initialValues={initialValues}
            onFinish={handleOnSubmit}
            className="login-form"
            requiredMark="optional"
          >
            <Form.Item
              name="accountName"
              hasFeedback={authStore?.isLoading}
              rules={[{ required: true, message: "Account Name is required" }]}
              validateStatus={authStore?.isLoading ? "validating" : undefined}
            >
              <InputField
                prefix={<PaperClipOutlined />}
                disabled={authStore?.isLoading}
                label="Account Name"
                className="form-control-rounded"
                isLogin={true}
              />
            </Form.Item>
            <Form.Item
              name="userName"
              hasFeedback={authStore?.isLoading}
              rules={[{ required: true, message: "Username is required" }]}
              validateStatus={authStore?.isLoading ? "validating" : undefined}
            >
              <InputField
                prefix={<UserOutlined />}
                disabled={authStore?.isLoading}
                label="Username"
                className="form-control-rounded"
                isLogin={true}
              />
            </Form.Item>
            <Form.Item
              name="password"
              hasFeedback={authStore?.isLoading}
              rules={[{ required: true, message: "Password is required" }]}
              validateStatus={authStore?.isLoading ? "validating" : undefined}
            >
              <InputField
                prefix={<LockOutlined />}
                disabled={authStore?.isLoading}
                label="Password"
                className="form-control-rounded"
                type="password"
                isLogin={true}
              />
            </Form.Item>
            <Row align="middle" className="action">
              <Col className="action-btn">
                <Button
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  className="btn-action"
                  loading={authStore?.isLoading}
                  size="large"
                  block
                >
                  Sign In
                </Button>
              </Col>
              <Col className="action-link">
                <Link to="">Forgot password?</Link>
              </Col>
            </Row>
          </Form>
        </Card>
        <Row justify="space-between" align="middle" className="copyright">
          <Col order={2}>
            <ul className="list-inline">
              <li>
                <Link to="">Help</Link>
              </li>
            </ul>
          </Col>
          <Col order={2}>
            <ul className="list-inline">
              <li>
                <a href="https://studioplussoftware.com/privacy-policy/">Privacy Policy</a>
              </li>
            </ul>
          </Col>
          <Col order={2}>
            <ul className="list-inline">
              <li>
                <a href="https://studioplussoftware.com/terms-of-use/">Terms of Use</a>
              </li>
            </ul>
          </Col>
          <Col order={1}>
            &copy; {dayjs().format("YYYY")} StudioPlus Software LLC. All Rights Reserved.
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default inject("authStore")(observer(LoginPage));
