import { observable, action, makeObservable, computed, toJS } from "mobx";
import { create, persist } from "mobx-persist";
import axios, { AxiosResponse, AxiosError } from "axios";
import type {
  Message,
  EmailDetails,
  SMSDetails,
  Department,
  EmailType,
  EmailStatus,
  EmailCategory,
} from "interfaces/message.int";
import AuthStore from "./AuthStore";
import AlertStore from "./AlertStore";
export class MessageStoreClass {
  @persist("list") messagesList: Array<Message> = [];
  @persist("object") emailDetails: EmailDetails | undefined = {};
  @persist("object") smsDetails: SMSDetails | undefined = {};
  @persist("list") departments: Array<Department> = [];
  @persist("list") emailTypes: Array<EmailType> = [];
  @persist("list") emailStatusList: Array<EmailStatus> = [];
  @persist("list") emailCategories: Array<EmailCategory> = [];
  @persist includeSMS: boolean = false;
  filterUserKey: string = "";
  isIncomingFilter: boolean = false; // default to showing outgoing messages, more common currently
  isSelectToggle: boolean = false;
  searchTerm: string = "";
  isLoading = false;
  activeSearch: any = {};
  constructor() {
    makeObservable(this, {
      departments: observable,
      getDepartments: action,
      messagesList: observable,
      emailDetails: observable,
      smsDetails: observable,
      filterUserKey: observable,
      searchTerm: observable,
      isSelectToggle: observable,
      isIncomingFilter: observable,
      includeSMS: observable,
      setIncludeSMS: action,
      filteredMessages: computed,
      sortedMessages: computed,
      setEmailDetails: action,
      setSmsDetails: action,
      sendSMS: action,
      updateSearchTerm: action,
      getMessages: action,
      getMessage: action,
      setMessagesList: action,
      setUserKeyFilter: action,
      setIncomingFilter: action,
      cleanUp: action,
      activeSearch: observable,
      setActiveSearch: action,
      emailTypes: observable,
      emailStatusList: observable,
      emailCategories: observable,
      getEmailTypes: action,
      getEmailStatusList: action,
      getEmailCategories: action,
    });
  }

  get filteredMessages() {
    let filteredList = [...this.messagesList];

    // Filter based on incoming/Outgoing
    filteredList = filteredList.filter((message) => {
      return message.Incoming === this.isIncomingFilter;
    });

    // Filter list if there is a search term
    if (this.searchTerm.length) {
      return filteredList.filter((message) => {
        return (
          message.Description?.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          message.Subject?.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      });
    }

    if (this.includeSMS === false) {
      return filteredList.filter((message: Message) => message.Type === "Email");
    }

    return filteredList;
  }

  get sortedMessages() {
    let sortedList = [...this.filteredMessages];

    sortedList.sort(function (a: any, b: any) {
      return new Date(b.CompleteDate).valueOf() - new Date(a.CompleteDate).valueOf();
    });

    return sortedList;
  }

  async getDepartments() {
    return await axios({ method: "GET", url: "/Departments" })
      .then((res: AxiosResponse) => {
        this.departments = res.data;
      })
      .catch((err: AxiosError) => {
        AlertStore.setAlertMessage(err.message, "error");
      });
  }

  async getEmailTypes() {
    return await axios({ method: "GET", url: "/EmailTypes" })
      .then((res: AxiosResponse) => {
        this.emailTypes = res.data;
      })
      .catch((err: AxiosError) => {
        AlertStore.setAlertMessage(err.message, "error");
      });
  }
  async getEmailStatusList() {
    return await axios({ method: "GET", url: "/EmailStatuses" })
      .then((res: AxiosResponse) => {
        this.emailStatusList = res.data;
      })
      .catch((err: AxiosError) => {
        AlertStore.setAlertMessage(err.message, "error");
      });
  }
  async getEmailCategories() {
    return await axios({ method: "GET", url: "/EmailCategories" })
      .then((res: AxiosResponse) => {
        this.emailCategories = res.data;
      })
      .catch((err: AxiosError) => {
        AlertStore.setAlertMessage(err.message, "error");
      });
  }

  setActiveSearch(search: any) {
    this.activeSearch = search;
  }

  async getMessagesViaSearch() {
    this.isLoading = true;
    const searchTerm = toJS(this.activeSearch);
    let searchParams: any = {};
    searchParams[searchTerm["action"]] = searchTerm["value"];
    return await axios({
      method: "GET",
      url: "/Messages/search",
      params: searchParams,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.setMessagesList(res.data);
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async getMessages(clientKey?: string, userKey?: string) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: "/Messages",
      params: {
        UserKey: userKey ? userKey : AuthStore.authenticatedUser.Key,
        clientKey: clientKey ? clientKey : undefined,
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.setMessagesList(res.data);
          this.isLoading = false;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  async getMessage(messageId: string) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/SMSTextMessage/${messageId}`,
    })
      .then(
        action((res: AxiosResponse) => {
          // debugger
          this.isLoading = false;
          this.setSmsDetails(res.data);
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  async sendSMS(message: any) {
    this.isLoading = true;
    AlertStore.setAlertMessage("Sending...", "info", 1000);
    return await axios({
      method: "PUT",
      url: `/SMSTextMessage`,
      data: message,
      params: {
        sendMessage: true,
      },
    })
      .then(
        action((res: AxiosResponse) => {
          AlertStore.setAlertMessage("SMS Sent!", "success");
          this.isLoading = false;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        AlertStore.setAlertMessage(err.message, "error");
      });
  }

  setEmailDetails(newMessage?: EmailDetails) {
    this.emailDetails = newMessage;
  }

  setSmsDetails(newMessage?: SMSDetails) {
    this.smsDetails = newMessage;
  }

  async getEmail(messageId: string) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/Email/${messageId}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.setEmailDetails(res.data);
          this.isLoading = false;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  updateSearchTerm(searchTerm: string) {
    this.searchTerm = searchTerm;
  }

  setUserKeyFilter(key: string) {
    this.filterUserKey = key;
  }

  setSelectToggle(isVisible: boolean) {
    this.isSelectToggle = isVisible;
  }

  setIncomingFilter(isIncoming: boolean) {
    this.isIncomingFilter = isIncoming;
  }

  setMessagesList(messagesList: Message[]) {
    this.messagesList = messagesList;
  }
  setIncludeSMS(toggle: boolean) {
    this.includeSMS = toggle;
  }

  cleanUp() {
    this.smsDetails = {};
    this.emailDetails = {};
    this.filterUserKey = "";
    this.emailTypes = [];
    this.emailStatusList = [];
    this.emailCategories = [];
  }
}

const hydrate = create({});

const MessageStore = new MessageStoreClass();
export default MessageStore;

hydrate("message", MessageStore).then((MessageStore) => {});
