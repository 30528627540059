import { observable, action, makeObservable, computed, toJS } from "mobx";
import { create, persist } from "mobx-persist";
import axios, { AxiosResponse, AxiosError } from "axios";
import update from "immutability-helper";

import AuthStore from "./AuthStore";
import type {
  Department,
  PhoneCall,
  PhoneCallList,
  PhoneCallOutcome,
  PhoneCallResult,
  PhoneCallType,
} from "interfaces/phonecall.int";
import AlertStore from "./AlertStore";

interface PhoneCallQuery {
  userKey: string;
  clientKey?: string;
}

export class PhoneCallStoreClass {
  @persist("list") phoneCallList: Array<PhoneCallList> = [];
  @persist("list") phoneCallTypes: Array<PhoneCallType> = [];
  @persist("list") phoneCallOutcomes: Array<PhoneCallOutcome> = [];
  @persist("list") phoneCallResults: Array<PhoneCallResult> = [];
  @persist("list") departments: Array<Department> = [];
  @persist showCompleted: boolean = false;
  activePhoneCall: PhoneCall | undefined;
  selectedList: Array<string> = [];
  isSelectToggle: boolean = false;
  isDeleteToggleActive: boolean = false;
  isLoading: boolean = false;
  activeSearch: any = {};

  constructor() {
    makeObservable(this, {
      phoneCallList: observable,
      activePhoneCall: observable,
      phoneCallOutcomes: observable,
      phoneCallTypes: observable,
      phoneCallResults: observable,
      departments: observable,
      showCompleted: observable,
      setShowCompleted: action,
      setActivePhoneCall: action,
      getPhoneCallList: action,
      getPhoneCall: action,
      getPhoneCallTypes: action,
      getPhoneCallOutcomes: action,
      getPhoneCallResults: action,
      getDepartments: action,
      addPhoneCall: action,
      deletePhoneCall: action,
      isDeleteToggleActive: observable,
      sortedCallsList: computed,
      setDeleteToggle: action,
      isSelectToggle: observable,
      setSelectToggle: action,
      selectedList: observable,
      setSelectedList: action,
      isLoading: observable,
      cleanUp: action,
      activeSearch: observable,
      setActiveSearch: action,
    });
  }

  get sortedCallsList() {
    const filteredList = this.showCompleted
      ? this.phoneCallList
      : this.phoneCallList.filter((call: any) => !call.Completed);
    return filteredList;
  }

  setActiveSearch(search: any) {
    this.activeSearch = search;
  }

  async getPhoneCallsViaSearch() {
    this.isLoading = true;
    const searchTerm = toJS(this.activeSearch);
    let searchParams: any = {};
    searchParams[searchTerm["action"]] = searchTerm["value"];
    return await axios({
      method: "GET",
      url: "/PhoneCalls/search",
      params: searchParams,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.phoneCallList = res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async getPhoneCallTypes() {
    return await axios({ method: "GET", url: "/PhoneCallTypes" })
      .then((res: AxiosResponse) => {
        this.phoneCallTypes = res.data;
      })
      .catch((err: AxiosError) => {
        AlertStore.setAlertMessage(err.message, "error");
      });
  }
  async getPhoneCallOutcomes() {
    return await axios({ method: "GET", url: "/CommunicationOutcomes" })
      .then((res: AxiosResponse) => {
        this.phoneCallOutcomes = res.data;
      })
      .catch((err: AxiosError) => {
        AlertStore.setAlertMessage(err.message, "error");
      });
  }
  async getPhoneCallResults() {
    return await axios({ method: "GET", url: "/CommunicationResults" })
      .then((res: AxiosResponse) => {
        this.phoneCallResults = res.data;
      })
      .catch((err: AxiosError) => {
        AlertStore.setAlertMessage(err.message, "error");
      });
  }

  async getDepartments() {
    return await axios({ method: "GET", url: "/Departments" })
      .then((res: AxiosResponse) => {
        this.departments = res.data;
      })
      .catch((err: AxiosError) => {
        AlertStore.setAlertMessage(err.message, "error");
      });
  }

  async getPhoneCallList(userKey?: string, clientKey?: string) {
    var params: any = {};

    if (userKey) {
      if (userKey !== "all") {
        params = update(params, { $merge: { userKey } });
      }
    } else {
      params = update(params, {
        $merge: { userKey: AuthStore.authenticatedUser.UserKey },
      });
    }

    if (clientKey) {
      params = update(params, { $merge: { clientKey } });
    }

    this.isLoading = true;
    return await axios({
      method: "GET",
      url: "/PhoneCalls",
      params,
    })
      .then(
        action((res: AxiosResponse) => {
          this.phoneCallList = res.data;
          this.isLoading = false;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async getPhoneCall(key?: string) {
    //00-000-12012
    if (!key) {
      key = this.activePhoneCall?.Key;
    }

    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/PhoneCall/${key}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.activePhoneCall = res.data;
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async addPhoneCall(data: PhoneCall) {
    // client 00-000-10181 - archie
    // 00-000-10031 - andrew
    // 00-000-10182 - billy
    if (!data.Key && !data.UserKey) {
      data = {
        ...data,
        UserKey: data.UserKey ? data.UserKey : AuthStore?.authenticatedUser?.UserKey,
      };
    }

    this.isLoading = true;
    return await axios({
      method: "PUT",
      url: "/PhoneCall",
      data,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async editPhoneCall(data: PhoneCall) {
    this.isLoading = true;
    return await axios({
      method: "PUT",
      url: "/PhoneCall",
      data,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.getPhoneCallList();
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  setDeleteToggle(toggle?: boolean) {
    this.isDeleteToggleActive = toggle ? toggle : !this.isDeleteToggleActive;
  }

  setSelectedList(key: string, action: "add" | "remove") {
    if (action === "add") {
      this.selectedList = [...this.selectedList, key];
      return;
    }

    const findIndex = this.selectedList.findIndex((v) => v === key);
    this.selectedList = update(this.selectedList, {
      $splice: [[findIndex, 1]],
    });
  }

  setSelectToggle(toggle: boolean) {
    this.isSelectToggle = toggle;
  }

  async deletePhoneCall(key: string) {
    this.isLoading = true;

    return await axios({
      method: "DELETE",
      url: `/PhoneCall/${key}`,
    })
      .then(
        action((res: AxiosResponse) => {
          const findIndex = this.phoneCallList.findIndex((v: PhoneCallList) => v.Key === key);
          this.phoneCallList = update(this.phoneCallList, {
            $splice: [[findIndex, 1]],
          });
          this.isLoading = false;
          return key;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async deletePhoneCalls(keys?: Array<string>) {
    this.isLoading = true;
    keys = keys ? keys : this.selectedList;
    if (keys.length <= 0) return;

    let promises = [];
    for (var i = 0; i < keys.length; i++) {
      promises.push(this.deletePhoneCall(keys[i]));
    }
    return await Promise.all(promises)
      .then(() => {
        this.isLoading = false;
        this.isDeleteToggleActive = false;
      })
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  setActivePhoneCall(data: PhoneCall | undefined) {
    this.activePhoneCall =
      typeof this.activePhoneCall !== "undefined" && typeof data !== "undefined"
        ? update(this.activePhoneCall, {
            $merge: data,
          })
        : data;
  }

  setShowCompleted(toggle: boolean) {
    this.showCompleted = toggle;
  }

  cleanUp() {
    this.phoneCallList = [];
    this.phoneCallOutcomes = [];
    this.phoneCallResults = [];
  }
}

const hydrate = create({});

const PhoneCallStore = new PhoneCallStoreClass();

export default PhoneCallStore;

hydrate("call", PhoneCallStore).then(() => {});
