import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Card, List, Modal, Radio } from "antd";
//import dayjs from "dayjs";

import type { ClockStoreClass } from "stores/ClockStore";
//import convertToHHmm from "components/util/ConvertToHhMm";

interface Props {
  clockStore?: ClockStoreClass;
}

const CurrentClock: React.FC<Props> = ({ clockStore }: Props): React.ReactElement => {
  const [timeCode, setTimeCode] = useState<
    "Regular" | "Vacation/PTO" | "Holiday" | "Sick" | "Bereavement"
  >("Regular");
  const [isModalTimeCodeVisible, setIsModalTimeCodeVisible] = useState<boolean>(false);

  const timeCodesArr: Array<string> = ["Regular", "Vacation/PTO", "Holiday", "Sick", "Bereavement"];

  useEffect(() => {
    setTimeCode(clockStore?.clockList?.TimeCode || "Regular");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clockStore?.clockList]);

  const toggleModalTimeCode = () => {
    setIsModalTimeCodeVisible(!isModalTimeCodeVisible);
  };

  const handleTimeCode = async () => {
    clockStore?.updateClockList({
      TimeCode: timeCode,
    });
    toggleModalTimeCode();
  };

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  return (
    <>
      <Card
        style={{ borderBottom: "0rem" }}
        headStyle={{ backgroundColor: "#bed1df", color: "white" }}
        title="Current Clock"
      >
        <List>
          {/* <List.Item onClick={clockStore?.isClockRunning ? toggleModalTimeCode : () => false}> */}
          {clockStore?.isClockRunning ? (
            <List.Item>
              <div>Time Code: {clockStore?.clockList?.TimeCode}</div>
              <div>{`Total Time: ${clockStore?.clockList?.TotalMinutes} minute(s)`}</div>
            </List.Item>
          ) : (
            <h2 style={{ textAlign: "center" }}>You are currently not clocked in</h2>
          )}
        </List>
      </Card>

      <Modal
        title="Select Time Code"
        open={isModalTimeCodeVisible}
        onOk={handleTimeCode}
        onCancel={toggleModalTimeCode}
        destroyOnClose
      >
        <Radio.Group onChange={(e) => setTimeCode(e.target.value)} defaultValue={timeCode}>
          {timeCodesArr.map((v) => (
            <Radio value={v} key={v} style={radioStyle}>
              {v}
            </Radio>
          ))}
        </Radio.Group>
      </Modal>
    </>
  );
};

export default inject("clockStore")(observer(CurrentClock));
