import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Form, Radio, message } from "antd";

import type { FormInstance } from "antd/lib/form/hooks/useForm";

import type { PhoneCallStoreClass } from "stores/PhoneCallStore";
import type { UserStoreClass } from "stores/UserStore";
import type { AuthStoreClass } from "stores/AuthStore";

const initialFormValues = {
  userKey: "",
};

interface Props {
  form?: FormInstance;
  userStore?: UserStoreClass;
  phoneCallStore?: PhoneCallStoreClass;
  authStore?: AuthStoreClass;
}

const FilterPhoneCall: React.FC<Props> = ({
  form,
  userStore,
  phoneCallStore,
  authStore,
}): React.ReactElement => {
  useEffect(() => {
    form?.setFieldsValue({
      userKey: authStore?.authenticatedUser?.UserKey,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      await phoneCallStore?.getPhoneCallList(values.userKey);
    } catch (e) {
      message.error("Unable to fetch phone calls. Try logging in again.");
    }
  };

  return (
    <Form form={form} initialValues={initialFormValues} onFinish={handleSubmit}>
      <Form.Item label="User" name="userKey">
        <Radio.Group>
        <Radio
                key={'any'}
                value={'any'}
                className="filterRadio"
              >
                  Any one
        </Radio>
          {userStore?.selectUserList?.map((item) => {
            return (
              <Radio
                key={item.value}
                value={item.value}
                className="filterRadio"
              >
                {item.label}
              </Radio>
            );
          })}
        </Radio.Group>
      </Form.Item>
    </Form>
  );
};

export default inject(
  "userStore",
  "phoneCallStore",
  "authStore"
)(observer(FilterPhoneCall));
