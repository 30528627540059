import React, { useState, useEffect } from "react";
import { Card, List, Affix, Grid, Avatar, Select, Skeleton, Empty } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { inject, observer } from "mobx-react";
import classnames from "classnames";
import { FixedSizeList as VList } from "react-window";

import ClientsWideView from "./ClientsWideView";
import SearchInput from "components/forms/SearchInput";
import ClientTabs from "components/tabs/ClientTabs";
import { ClientStoreClass } from "stores/ClientStore";
import { ClientList } from "interfaces/client.int";
import { Option } from "antd/lib/mentions";
import ActionsClients from "components/clients/actions/ActionsClients";
// import Title from "antd/lib/skeleton/Title";
// const { useBreakpoint } = Grid;

interface Props {
  clientStore?: ClientStoreClass;
}

const { useBreakpoint } = Grid;

const ClientsHubPage: React.FC<Props> = ({ clientStore }) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const screens = useBreakpoint();
  let timeout: ReturnType<typeof setTimeout>;

  useEffect(() => {
    //Added this code to set searchby term to default value.
    clientStore?.setSearchBy({
      value: "lastName",
      children: "LastName",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      clientStore?.clearLists();
    };
  }, []);

  const handleClick = (item: ClientList) => {
    clientStore?.setActiveClientNumber(item.ClientNumber || "");
    clientStore?.setSelectToggle(true);
    clientStore?.setActiveClientKey(item.Key);
  };

  const renderRow = (props: any) => {
    const client: ClientList = clientStore!.sortedClientsList[props.index];

    return (
      <Skeleton loading={clientStore?.isLoading}>
        <List.Item
          key={props.key}
          style={{ ...props.style, cursor: "pointer" }}
          onClick={() => handleClick(client)}
          className="has-action"
        >
          <List.Item.Meta
            avatar={<Avatar size="large" icon={<UserOutlined />} />}
            title={`${client?.LastName ? `${client?.LastName}, ` : ""}${client?.FirstName}`}
            description={client?.Email}
            // className="list-meta-float"
          />
        </List.Item>
      </Skeleton>
    );
  };

  const selectAfter = (
    <Select
      onChange={(value) => {
        clientStore?.setSearchBy({
          value: value,
          children: value.charAt(0).toUpperCase() + value.slice(1),
        });
      }}
      defaultValue={"lastName"}
    >
      <Option value="firstName">First Name</Option>
      <Option value="lastName">Last Name</Option>
      <Option value="email">Email</Option>
      <Option value="companyName">Company Name</Option>
      <Option value="phoneNumber">Phone Number</Option>
    </Select>
  );

  return (
    <>
      <div className="ant-layout-content main-content" ref={setContainer}>
        <ActionsClients />
        <div
          className={classnames({
            "main-content-pads": screens.md,
          })}
        >
          <Card
            title={
              <Affix target={() => container} offsetTop={70}>
                <SearchInput
                  onKeyUp={(e: any) => {
                    clearTimeout(timeout);
                    if (e.keyCode === 13) {
                      // Prevents loading again for enter key
                      return;
                    }

                    timeout = setTimeout(async () => {
                      await clientStore?.getClients();
                    }, 1000);
                  }}
                  onChange={(e) => {
                    clientStore?.updateSearchTerm(e.target.value);
                    clearTimeout(timeout);
                  }}
                  onPressEnter={() => {
                    clientStore?.getClients();
                    clearTimeout(timeout);
                  }}
                  searchClass="search-listing"
                  addonAfter={selectAfter}
                />
              </Affix>
            }
            bordered={screens.md}
            // className="card-listing"
          >
            {clientStore?.sortedClientsList.length ? (
              <Skeleton active loading={clientStore?.isLoading}>
                {screens.xs ? (
                  <List bordered style={{ border: 0 }} dataSource={clientStore?.sortedClientsList}>
                    <VList
                      height={window.innerHeight}
                      width={"100%"}
                      itemCount={clientStore?.sortedClientsList.length || 0}
                      itemSize={75}
                      overscanCount={100}
                    >
                      {renderRow}
                    </VList>
                  </List>
                ) : (
                  <ClientsWideView />
                )}
              </Skeleton>
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={"Please search something to display "}
              />
            )}
          </Card>
        </div>
      </div>
      <ClientTabs />
    </>
  );
};

export default inject(
  "clientStore",
  "invoiceStore",
  "taskStore",
  "appointmentStore"
)(observer(ClientsHubPage));
