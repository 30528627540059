import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Card, List, Modal, Form, Input, message } from "antd";
import { LockOutlined, LogoutOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import type { AuthStoreClass } from "stores/AuthStore";

import "./settings-navlist.scss";

interface Props {
  authStore?: AuthStoreClass;
}

interface PasswordUpdate {
  OldPassword: string;
  NewPassword: string;
  ConfirmNewPassword: string;
}

const AccountInfo: React.FC<Props> = ({ authStore }) => {
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [isModalLogoutShown, setIsModalLogoutShown] = useState<boolean>(false);
  const [form] = Form.useForm();
  const history = useHistory();

  const toggleModal = () => {
    setIsModalShown(!isModalShown);
  };

  const toggleModalLogout = () => {
    setIsModalLogoutShown(!isModalLogoutShown);
  };

  const handleSubmit = async (formValues: PasswordUpdate) => {
    const { NewPassword, ConfirmNewPassword } = formValues;
    if (NewPassword !== ConfirmNewPassword) {
      message.error("Passwords do not match!");

      return;
    }
    await authStore?.updatePassword(formValues);
    form.resetFields();
    setIsModalShown(false);
  };

  const handleLogout = async () => {
    await authStore?.logout();
    history.push("/");
    setIsModalShown(false);
  };

  return (
    <Card headStyle={{ backgroundColor: "#92b0b6 " }} title="Account Information">
      <List className="nav-list">
        <List.Item>
          <div>Account name</div>
          <div>{`${authStore?.accountName}`}</div>
        </List.Item>
        <List.Item>
          <div>User name</div>
          <div>{`${authStore?.userName}`}</div>
        </List.Item>
        <List.Item onClick={toggleModal} className="nav-list-clickable">
          <List.Item.Meta avatar={<LockOutlined />} title="Update password" />
        </List.Item>
        <List.Item onClick={toggleModalLogout} className="nav-list-clickable">
          <List.Item.Meta avatar={<LogoutOutlined />} title="Logout" />
        </List.Item>
      </List>

      <Modal
        title="Update password"
        visible={isModalShown}
        okText="Update"
        onOk={() => form.submit()}
        onCancel={toggleModal}
      >
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            name="OldPassword"
            rules={[{ required: true, message: "Please enter your old password!" }]}
          >
            <Input placeholder="Old password" type="password" />
          </Form.Item>
          <Form.Item
            name="NewPassword"
            rules={[
              { required: true, message: "Please enter new password!" },
              { min: 6, message: "Minimm of 6 characters" },
            ]}
          >
            <Input placeholder="New password" type="password" />
          </Form.Item>
          <Form.Item name="ConfirmNewPassword" rules={[]}>
            <Input
              placeholder="Confirm New Password"
              type="password"
              onPressEnter={() => form.submit()}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Logging out"
        visible={isModalLogoutShown}
        okText="Yes, log me out"
        onOk={handleLogout}
        onCancel={toggleModalLogout}
      >
        <p>Are you sure you want to log out?</p>
      </Modal>
    </Card>
  );
};

export default inject("authStore")(observer(AccountInfo));
