import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Form, Space, Button, Drawer, Typography, Modal, message, Grid } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import type { DrawerProps } from "antd/lib/drawer";
import { DeleteOutlined, CheckOutlined } from "@ant-design/icons";

import type { TaskStoreClass } from "stores/TaskStore";
import AddEditTask from "../forms/AddEditTask";
import useWindowDimension from "components/util/Dimension";
const { useBreakpoint } = Grid;

dayjs.extend(utc);

const { Title } = Typography;

interface Props {
  onToggle?: (x: boolean) => void;
  taskStore?: TaskStoreClass;
  activeClientKey?: any;
  clientKey?: string;
  sessionKey?: string;
}

const TaskDrawerForm: React.FC<Props> = ({
  onToggle,
  taskStore,
  activeClientKey,
  clientKey,
  sessionKey,
}) => {
  const [isDeleteModalShown, setIsDeleteModalShown] = useState<boolean>(false);
  const { innerWidth } = useWindowDimension();
  const [form] = Form.useForm();
  const screens = useBreakpoint();

  useEffect(() => {
    if (!taskStore?.isSelectToggle) {
      setTimeout(() => {
        form?.resetFields();
      }, 500);
    }
  }, [taskStore?.isSelectToggle, taskStore?.taskDetails]);

  const toggleDrawer = (toggle: boolean) => {
    onToggle && onToggle(toggle);
    taskStore?.setSelectToggle(toggle);
    taskStore?.setTaskDetails(undefined);
  };

  const toggleDeleteModal = () => {
    taskStore?.deleteTask(taskStore?.taskDetails?.Key);
    taskStore?.setSelectToggle(false);
    taskStore?.setTaskDetails(undefined);
  };

  const handleDelete = async () => {
    setIsDeleteModalShown(false);
    const loadingMsgKey = "notification-key";
    message.loading({
      content: "Deleting...",
      key: loadingMsgKey,
      duration: 0,
    });
  };

  const handleSuccess = async () => {
    // try {
    //   await taskStore?.getTasks().then(() => {
    //     message.success(`Resynced data!`);
    //   });
    // } catch (e) {}
  };

  const handleError = () => {
    toggleDrawer(false);
    setTimeout(() => {
      message.error("Unable to fetch phone call. Does not exist or try logging in again.");
    }, 500);
  };

  const ModalHeader = () => {
    return (
      <Row>
        <Col xs={10}>
          <Title level={2}>Task</Title>
        </Col>
        <Col
          xs={14}
          style={{
            textAlign: "right",
          }}
        >
          <Space>
            {typeof taskStore?.taskDetails !== "undefined" && (
              <Button
                icon={<DeleteOutlined />}
                disabled={taskStore?.isLoading}
                style={{
                  backgroundColor: "rgb(142, 59, 70)",
                  borderColor: "rgb(142, 59, 70)",
                  color: "white",
                }}
                className="btn-action"
                onClick={toggleDeleteModal}
              >
                Delete
              </Button>
            )}
            <Button
              icon={<CheckOutlined />}
              disabled={taskStore?.isLoading}
              style={{
                backgroundColor: "rgb(255, 179, 28)",
                borderColor: "rgb(255, 179, 28)",
                color: "white",
              }}
              className="btn-action"
              shape="round"
              onClick={() => form.validateFields().then(() => form.submit())}
            >
              Save
            </Button>
          </Space>
        </Col>
      </Row>
    );
  };

  const drawerParams: DrawerProps = {
    width: screens.xs ? innerWidth : innerWidth - 250,
    placement: "right",
    closable: true,
    forceRender: false,
    destroyOnClose: true,
    title: <ModalHeader />,
    maskStyle: {},
    className: "drawer-form",
    push: {
      distance: 20,
    },
  };

  return (
    <>
      <Drawer
        onClose={() => toggleDrawer(false)}
        visible={taskStore?.isSelectToggle}
        {...drawerParams}
      >
        <AddEditTask
          activeClientKey={activeClientKey}
          clientKey={clientKey}
          sessionKey={sessionKey}
          form={form}
          onSuccess={handleSuccess}
          onError={handleError}
        />
      </Drawer>
      <Modal
        title={`Delete a task`}
        visible={isDeleteModalShown}
        onCancel={toggleDeleteModal}
        onOk={handleDelete}
      >
        <p>Are you sure you want to delete this task?</p>
      </Modal>
    </>
  );
};

export default inject("taskStore")(observer(TaskDrawerForm));
