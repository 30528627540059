import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { inject, observer } from "mobx-react";
import { Button, Form, Input, Row, Grid, Col, message, Alert, Spin } from "antd";
import {
  PushpinOutlined,
  UserOutlined,
  BankOutlined,
  MailOutlined,
  TagOutlined,
  CompassOutlined,
  ExclamationCircleOutlined,
  NumberOutlined,
} from "@ant-design/icons";
import type { FormInstance } from "antd/lib/form/hooks/useForm";

import InputField from "./items/InputField";
import SelectField from "./items/SelectField";
import PhoneNumberTable from "../tables/PhoneNumberTable";
import NotesField from "./items/NotesField";
import type { Client, ClientFormValues } from "../../interfaces/client.int";
import { ClientStoreClass } from "stores/ClientStore";

const { useBreakpoint } = Grid;

interface Props {
  form: FormInstance;
  onSuccess?: () => void;
  onError?: () => void;
  clientStore?: ClientStoreClass;
  clientCustomFields?: any;
}

const initialFormValues: ClientFormValues = {
  key: "",
  firstname: "",
  lastname: "",
  companyname: "",
  organizationName: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  postCode: "",
  country: "",
  email: "",
  clientNumber: "",
  clientStatusKey: "",
  warning: "",
  PhoneDescription1: "",
  PhoneDescription2: "",
  PhoneDescription3: "",
  PhoneDescription4: "",
  PhoneDescription5: "",
  PhoneNumber0: "",
  PhoneNumber1: "",
  PhoneNumber2: "",
  PhoneNumber3: "",
  PhoneNumber4: "",
  PhoneNumber5: "",
  SMSTextable0: undefined,
  SMSTextable1: undefined,
  SMSTextable2: undefined,
  SMSTextable3: undefined,
  SMSTextable4: undefined,
  SMSTextable5: undefined,
  notes: "",
};

const AddEditClient: React.FC<Props> = ({
  form,
  onSuccess,
  onError,
  clientStore,
  clientCustomFields,
}) => {
  const [notes, setNotes] = useState<string>();
  const [statusNotes, setStatusNotes] = useState<string>();
  var { isLoading, data } = useQuery(["client", clientStore?.activeClientKey], () => {
    return clientStore?.getClient(clientStore?.activeClientKey);
  });

  const screens = useBreakpoint();
  const formGrid = (isEnd = false, inForce = true) => {
    const marginVar = inForce ? 10 : "";
    return {
      xs: 24,
      sm: 12,
      style: {
        marginBottom: isEnd ? "" : marginVar,
      },
    };
  };

  useEffect(() => {
    if (clientStore?.activeClientKey) {
      fetchClient();
    }
    return () => {
      form.resetFields();
    };
  }, [clientStore?.activeClientKey, isLoading]);

  const fetchClient = async () => {
    try {
      // var data: Client | undefined;
      if (!clientStore?.activeClientKey) return;

      // data = await clientStore?.getClient(clientStore?.activeClientKey);

      const fillValues: ClientFormValues = {
        key: data?.Key,
        firstname: data?.FirstName,
        lastname: data?.LastName,
        companyname: data?.CompanyName,
        organizationName: data?.OrganizationName,
        addressLine1: data?.AddressLine1,
        addressLine2: data?.AddressLine2,
        city: data?.AddressCity,
        state: data?.AddressState,
        postCode: data?.AddressPostcode,
        country: data?.AddressCountry,
        email: data?.Email,
        clientNumber: data?.ClientNumber,
        clientStatusKey: data?.ClientStatusKey,
        warning: data?.Warning,
        PhoneDescription1: data?.PhoneDescription1,
        PhoneDescription2: data?.PhoneDescription2,
        PhoneDescription3: data?.PhoneDescription3,
        PhoneDescription4: data?.PhoneDescription4,
        PhoneDescription5: data?.PhoneDescription5,
        PhoneNumber0: data?.PhoneNumber0,
        PhoneNumber1: data?.PhoneNumber1,
        PhoneNumber2: data?.PhoneNumber2,
        PhoneNumber3: data?.PhoneNumber3,
        PhoneNumber4: data?.PhoneNumber4,
        PhoneNumber5: data?.PhoneNumber5,
        SMSTextable0: data?.SMSTextable0,
        SMSTextable1: data?.SMSTextable1,
        SMSTextable2: data?.SMSTextable2,
        SMSTextable3: data?.SMSTextable3,
        SMSTextable4: data?.SMSTextable4,
        SMSTextable5: data?.SMSTextable5,
      };

      form?.setFieldsValue(fillValues);
      setNotes(data?.Notes || "");
      setStatusNotes(data?.StatusNotes || "");
    } catch (e) {}
  };

  const handleSubmit = async (values: ClientFormValues) => {
    const {
      firstname,
      lastname,
      email,
      companyname,
      organizationName,
      addressLine1,
      addressLine2,
      city,
      state,
      postCode,
      country,
      clientStatusKey,
      warning,
      PhoneDescription1,
      PhoneDescription2,
      PhoneDescription3,
      PhoneDescription4,
      PhoneDescription5,
      PhoneNumber0,
      PhoneNumber1,
      PhoneNumber2,
      PhoneNumber3,
      PhoneNumber4,
      PhoneNumber5,
      SMSTextable0,
      SMSTextable1,
      SMSTextable2,
      SMSTextable3,
      SMSTextable4,
      SMSTextable5,
      notes,
    } = values;
    const data: any = {
      FirstName: firstname,
      LastName: lastname,
      Email: email,
      CompanyName: companyname,
      OrganizationName: organizationName,
      AddressLine1: addressLine1,
      AddressLine2: addressLine2,
      AddressCity: city,
      AddressState: state,
      AddressPostcode: postCode,
      AddressCountry: country,
      ClientStatusKey: clientStatusKey,
      Warning: warning,
      PhoneDescription1,
      PhoneDescription2,
      PhoneDescription3,
      PhoneDescription4,
      PhoneDescription5,
      PhoneNumber0,
      PhoneNumber1,
      PhoneNumber2,
      PhoneNumber3,
      PhoneNumber4,
      PhoneNumber5,
      SMSTextable0,
      SMSTextable1,
      SMSTextable2,
      SMSTextable3,
      SMSTextable4,
      SMSTextable5,
      CustomDateField1: clientCustomFields.CustomDateField1,
      CustomDateField2: clientCustomFields.CustomDateField2,
      CustomDateField3: clientCustomFields.CustomDateField3,
      CustomDateField4: clientCustomFields.CustomDateField4,
      CustomDateField5: clientCustomFields.CustomDateField5,
      CustomDateField6: clientCustomFields.CustomDateField6,
      CustomDateField7: clientCustomFields.CustomDateField7,
      CustomDateField8: clientCustomFields.CustomDateField8,
      CustomDateField9: clientCustomFields.CustomDateField9,
      CustomDateField10: clientCustomFields.CustomDateField10,
      CustomField01: clientCustomFields.CustomField01,
      CustomField02: clientCustomFields.CustomField02,
      CustomField03: clientCustomFields.CustomField03,
      CustomField04: clientCustomFields.CustomField04,
      CustomField05: clientCustomFields.CustomField05,
      CustomField06: clientCustomFields.CustomField06,
      CustomField07: clientCustomFields.CustomField07,
      CustomField08: clientCustomFields.CustomField08,
      CustomField09: clientCustomFields.CustomField09,
      CustomField10: clientCustomFields.CustomField10,
      CustomField11: clientCustomFields.CustomField11,
      CustomField12: clientCustomFields.CustomField12,
      CustomField13: clientCustomFields.CustomField13,
      CustomField14: clientCustomFields.CustomField14,
      CustomField15: clientCustomFields.CustomField15,
      CustomField16: clientCustomFields.CustomField16,
      CustomField17: clientCustomFields.CustomField17,
      CustomField18: clientCustomFields.CustomField18,
      CustomField19: clientCustomFields.CustomField19,
      CustomField20: clientCustomFields.CustomField20,
      CustomNotes01: clientCustomFields.CustomNotes01,
      CustomNotes02: clientCustomFields.CustomNotes02,
      CustomNotes03: clientCustomFields.CustomNotes03,
      CustomNotes04: clientCustomFields.CustomNotes04,
      CustomNotes05: clientCustomFields.CustomNotes05,
      CustomBoolean01: clientCustomFields.CustomBoolean01,
      CustomBoolean02: clientCustomFields.CustomBoolean02,
      CustomBoolean03: clientCustomFields.CustomBoolean03,
      CustomBoolean04: clientCustomFields.CustomBoolean04,
      CustomBoolean05: clientCustomFields.CustomBoolean05,
      CustomBoolean06: clientCustomFields.CustomBoolean06,
      CustomBoolean07: clientCustomFields.CustomBoolean07,
      CustomBoolean08: clientCustomFields.CustomBoolean08,
      CustomBoolean09: clientCustomFields.CustomBoolean09,
      CustomBoolean10: clientCustomFields.CustomBoolean10,
    };

    if (clientStore?.clientInfo?.Key) {
      const changedData: any = {};
      for (let line in data) {
        if (data[line] !== clientStore?.clientInfo![line as keyof Client]) {
          changedData[line] = data[line];
        }
      }
      if (Object.keys(changedData).length === 0) {
        message.warning("No Data Changed");
        return;
      }
      changedData.Key = clientStore?.clientInfo?.Key;
      await clientStore?.editClient(changedData);
    } else {
      data.Notes = notes;
      await clientStore?.addClient(data);
    }
  };

  const handleOnFinishFailed = (errorInfo: any) => {
    message.error(errorInfo.errorFields[0].errors);
  };

  return (
    <Form
      form={form}
      onFinishFailed={handleOnFinishFailed}
      initialValues={initialFormValues}
      onFinish={handleSubmit}
    >
      <Row>
        {!clientStore?.isLoading && (
          <Col className="callWarning">
            {clientStore?.clientInfo?.Warning && (
              <Alert type="warning" showIcon message={clientStore?.clientInfo?.Warning} />
            )}
          </Col>
        )}
      </Row>
      <Form.Item label="Key" name="key" hidden={true}>
        <Input id="key" />
      </Form.Item>

      <Row className="form-group" gutter={20}>
        <Col {...formGrid()}>
          <Form.Item
            name="firstname"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
            rules={[
              {
                required: true,
                message: "Please enter first name!",
              },
            ]}
          >
            <InputField prefix={<UserOutlined />} disabled={isLoading} label="First Name" />
          </Form.Item>
        </Col>
        <Col {...formGrid()}>
          <Form.Item
            name="lastname"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <InputField prefix={screens.sm ? null : <></>} disabled={isLoading} label="Last Name" />
          </Form.Item>
        </Col>
        <Col {...formGrid(true)}>
          <Form.Item
            name="companyname"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <InputField prefix={<BankOutlined />} disabled={isLoading} label="Company Name" />
          </Form.Item>
        </Col>
        <Col {...formGrid(true)}>
          <Form.Item
            name="organizationName"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <InputField
              prefix={screens.sm ? null : <></>}
              disabled={isLoading}
              label="Organization Name"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="form-group" gutter={20}>
        <Col {...formGrid()}>
          <Form.Item
            name="addressLine1"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <InputField prefix={<PushpinOutlined />} disabled={isLoading} label="Address Line 1" />
          </Form.Item>
        </Col>
        <Col {...formGrid()}>
          <Form.Item
            name="addressLine2"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <InputField
              prefix={screens.sm ? null : <></>}
              disabled={isLoading}
              label="Address Line 2"
            />
          </Form.Item>
        </Col>
        <Col {...formGrid()}>
          <Form.Item
            name="city"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <InputField prefix={<></>} disabled={isLoading} label="City" />
          </Form.Item>
        </Col>
        <Col {...formGrid()}>
          <Form.Item
            name="state"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <InputField prefix={screens.sm ? null : <></>} disabled={isLoading} label="State" />
          </Form.Item>
        </Col>
        <Col {...formGrid(false, !screens.sm)}>
          <Form.Item
            name="postCode"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <InputField prefix={<></>} disabled={isLoading} label="Postal Code" />
          </Form.Item>
        </Col>
        <Col {...formGrid(true)}>
          <Form.Item
            name="country"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <InputField prefix={screens.sm ? null : <></>} disabled={isLoading} label="Country" />
          </Form.Item>
        </Col>
        {clientStore?.clientInfo?.MapUrl?.length && (
          <Col xs={24} style={{ paddingLeft: "3.75rem" }}>
            <Button
              onClick={() => {
                window.open(clientStore?.clientInfo?.MapUrl);
              }}
              icon={<CompassOutlined />}
            >
              View on map
            </Button>
          </Col>
        )}
      </Row>

      <Row className="form-group" gutter={20} align="middle">
        <Col xs={24} sm={clientStore?.clientInfo?.ClientNumber ? 12 : 24}>
          <Form.Item
            name="email"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
            rules={[
              {
                type: "email",
                message: "Please enter a valid email!",
              },
              {
                required: true,
                message: "Please enter an email!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    !clientStore?.findDuplicateEmailFromList(value, clientStore?.clientInfo?.Key)
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("This email is already taken!"));
                },
              }),
            ]}
          >
            <InputField prefix={<MailOutlined />} disabled={isLoading} label="Email Address" />
          </Form.Item>
        </Col>
        {/*        {clientStore?.clientInfo?.ClientNumber && (
          <Col {...formGrid(true)}>
            <div>
              <label
                className="form-control-label"
                style={{
                  opacity: 0.5,
                  fontSize: "0.75rem",
                }}
              >
                Client Number
              </label>
              <div>{clientStore.clientInfo.ClientNumber}</div>
            </div>
          </Col>
        )}*/}
        <Col {...formGrid(false, !screens.sm)}>
          <Form.Item
            name="clientNumber"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <InputField
              prefix={<NumberOutlined />}
              disabled={true}
              label="Client Number"
              // value={clientStore?.clientInfo?.ClientNumber}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="form-group" gutter={20}>
        <Col {...formGrid()}>
          <Form.Item
            name="clientStatusKey"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <SelectField
              options={clientStore?.selectClientStatuses}
              prefix={<TagOutlined />}
              disabled={isLoading}
              label="Status"
              showSearch={false}
              showArrow={false}
              suffixIcon={<></>}
            />
          </Form.Item>
        </Col>
        <Col {...formGrid()}>
          <Form.Item
            name="warning"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <InputField
              prefix={<ExclamationCircleOutlined />}
              disabled={isLoading}
              label="Call Warning"
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            name="notes"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <NotesField
              noteType="client"
              disabled={isLoading}
              notes={notes}
              statusNotes={statusNotes}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* PHONE NUMBERS */}
      <PhoneNumberTable form={form} />
    </Form>
  );
};

export default inject("clientStore")(observer(AddEditClient));
