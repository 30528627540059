import React from "react";
import { inject, observer } from "mobx-react";
import dayjs from "dayjs";

import "./TimeLine.scss";
import type { ScheduleStoreClass } from "stores/ScheduleStore";

interface Props {
  scheduleStore?: ScheduleStoreClass;
}

const TimeLine: React.FC<Props> = (): React.ReactElement => {
  const minute = Number(dayjs().format("m")) / 60;
  const hour = ((Number(dayjs().format("H")) + minute) / 24) * 100;

  return <div className="time-line" style={{ top: `${hour}%` }} />;
};

export default inject("scheduleStore")(observer(TimeLine));
