import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Card, List, Modal, Form, Input, message } from "antd";
import { MessageOutlined, BugOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import "./settings-navlist.scss";

import type { AuthStoreClass } from "stores/AuthStore";

interface Props {
  authStore?: AuthStoreClass;
}

const Help: React.FC<Props> = ({ authStore }) => {
  const [isModalMessageShown, setIsModalMessageShown] = useState<boolean>(false);
  const [isSendingMessage, setIsSendingmessage] = useState<boolean>(false);
  const [isModalReportShown, setIsModalReportShown] = useState<boolean>(false);
  const [isSendingReport, setIsSendingReport] = useState<boolean>(false);
  const [messageForm] = Form.useForm();
  const [reportForm] = Form.useForm();

  const toggleModalMessage = () => {
    setIsModalMessageShown(!isModalMessageShown);
  };

  const handleSubmitMessage = async () => {
    const loadingMsgKey = "message-key";
    message.loading({
      content: "Sending...",
      key: loadingMsgKey,
      duration: 0,
    });

    setIsSendingmessage(true);

    try {
      await authStore?.sendSupport("Suggestion", messageForm.getFieldValue("message")).then(() => {
        message.success({
          content: "Successfully sent! Thank you for your submission.",
          key: loadingMsgKey,
          duration: 1,
        });
        setIsModalMessageShown(false);
        setIsSendingmessage(false);
      });
    } catch (e) {
      message.error({
        content: "Sorry, something has gone wrong. Please try again later.",
        key: loadingMsgKey,
        duration: 1,
      });
      setIsSendingmessage(false);
    }
  };

  const toggleModalReport = () => {
    setIsModalReportShown(!isModalReportShown);
  };

  const handleSubmitReport = async () => {
    const loadingMsgKey = "message-key";
    message.loading({
      content: "Sending...",
      key: loadingMsgKey,
      duration: 0,
    });

    setIsSendingReport(true);

    try {
      await authStore?.sendSupport("BugReport", reportForm.getFieldValue("report")).then(() => {
        message.success({
          content: "Successfully sent! Thank you for your submission.",
          key: loadingMsgKey,
          duration: 1,
        });
        setIsModalReportShown(false);
        setIsSendingReport(false);
      });
    } catch (e) {
      message.error({
        content: "Sorry, something has gone wrong. Please try again later.",
        key: loadingMsgKey,
        duration: 1,
      });
      setIsSendingReport(false);
    }
  };

  return (
    <Card headStyle={{ backgroundColor: "#bed1df" }} title="Help">
      <List className="nav-list">
        <List.Item>
          <List.Item.Meta title="App Version: 2.0.1.1" />
        </List.Item>
        <List.Item onClick={toggleModalMessage} className="nav-list-clickable">
          <List.Item.Meta avatar={<MessageOutlined />} title="Send us a suggestion" />
        </List.Item>
        <List.Item onClick={toggleModalReport} className="nav-list-clickable">
          <List.Item.Meta avatar={<BugOutlined />} title="Report a bug" />
        </List.Item>
        <List.Item
          onClick={() =>
            window.open(
              "https://secure.studioplussoftware.com/help/support_center_new2020.aspx",
              "_blank"
            )
          }
          className="nav-list-clickable"
        >
          <List.Item.Meta avatar={<QuestionCircleOutlined />} title="Get help online" />
        </List.Item>
      </List>

      <Modal
        title="Send us a suggestion"
        visible={isModalMessageShown}
        onOk={() => messageForm.submit()}
        onCancel={toggleModalMessage}
        confirmLoading={isSendingMessage}
      >
        <Form form={messageForm} onFinish={handleSubmitMessage}>
          <Form.Item name="message" rules={[{ required: true, message: "Field is required!" }]}>
            <Input.TextArea
              placeholder="Your suggestion here..."
              bordered={false}
              style={{ paddingLeft: 0, paddingRight: 0 }}
              disabled={isSendingMessage}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Report a bug"
        visible={isModalReportShown}
        onOk={() => reportForm.submit()}
        onCancel={toggleModalReport}
        confirmLoading={isSendingReport}
      >
        <Form form={reportForm} onFinish={handleSubmitReport}>
          <Form.Item name="report" rules={[{ required: true, message: "Field is required!" }]}>
            <Input.TextArea
              placeholder="Your detailed description of the bug here here..."
              bordered={false}
              style={{ paddingLeft: 0, paddingRight: 0 }}
              disabled={isSendingReport}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default inject("authStore")(observer(Help));
