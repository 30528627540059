import { Select } from "antd";
import React, { useState } from "react";
import "./selector.scss";

const { Option } = Select;

const Selector = (props: any) => {
  const [searchText, setSearchText] = useState("");

  const {
    width = 200,
    placeholder = "Select a option",
    options = [],
    onUpdate = (selectedValue: any) => {},
    value = "",
  } = props;

  const filteredOptions = options.filter(
    (option: any) =>
      option.label.toLowerCase().indexOf(searchText.trim().toLowerCase()) > -1
  );

  return (
    <>
      <Select
        showSearch
        style={{ width }}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={onUpdate}
        allowClear={true}
        value={value}
        onSearch={setSearchText}
        filterOption={(input, option: any) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {filteredOptions.map((option: any, optionIndex: number) => (
          <Option value={option.value} key={optionIndex}>
            {option.label}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default Selector;
