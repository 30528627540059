import React from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Form } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import InputField from "components/forms/items/InputField";
import UsersSelectField from "components/forms/items/UsersSelectField";
import { TeamOutlined, NumberOutlined, MessageOutlined } from "@ant-design/icons";

dayjs.extend(customParseFormat);

const SMSForm: React.FC<any> = ({ form, handleSubmit, messageStore, ...props }) => {
  return (
    <Form form={form}>
      <Row className="form-group" gutter={[16, 16]}>
        <Col xs={24}>
          <Row>
            <Col
              xs={24}
              style={{
                marginBottom: 10,
              }}
            >
              <Form.Item
                name="ToNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter number!",
                  },
                ]}
              >
                <InputField prefix={<NumberOutlined />} label="Send To" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xs={24}
              style={{
                marginBottom: 10,
              }}
            >
              <Form.Item name="UserKey">
                <UsersSelectField label="User" prefix={<TeamOutlined />} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              xs={24}
              style={{
                marginBottom: 10,
              }}
            >
              <Form.Item name="MessageText">
                <InputField isTextarea prefix={<MessageOutlined />} label="Message" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default inject("messageStore")(observer(SMSForm));
