import { observable, action, makeObservable } from "mobx";
import { create, persist } from "mobx-persist";
import axios, { AxiosResponse, AxiosError } from "axios";

import {
  ProductionOrderStatus,
  ProductionOrderType,
  ReOrderReason,
} from "interfaces/productionOrder.int";
import { Vendor } from "interfaces/invoice.int";

export class ProductionOrderStoreClass {
  @persist("list") productionOrderStatusList: Array<ProductionOrderStatus> = [];
  @persist("list") productionOrderTypeList: Array<ProductionOrderType> = [];
  @persist("list") reOrderReasonList: Array<ReOrderReason> = [];
  @persist("list") vendorList: Array<Vendor> = [];
  isSelectToggle: boolean = false;
  isLoading: boolean = false;
  activeSearch: any = {};
  constructor() {
    makeObservable(this, {
      init: action,
      cleanUp: action,
      isLoading: observable,
      productionOrderStatusList: observable,
      productionOrderTypeList: observable,
      reOrderReasonList: observable,
      vendorList: observable,
      getProductionOrderStatusList: action,
      getProductionOrderTypeList: action,
      getReOrderReasonList: action,
      getVendorList: action,
    });
  }

  async init() {
    await this.getReOrderReasonList();
    await this.getProductionOrderTypeList();
    await this.getProductionOrderStatusList();
    await this.getVendorList();
  }

  cleanUp() {
    this.productionOrderStatusList = [];
    this.productionOrderTypeList = [];
    this.reOrderReasonList = [];
    this.vendorList = [];
  }

  setSelectToggle(toggle: boolean) {
    this.isSelectToggle = toggle;
  }

  async getProductionOrderStatusList() {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/ProductionOrderStatuses`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.productionOrderStatusList = res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async getProductionOrderTypeList() {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/ProductionOrderTypes`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.productionOrderTypeList = res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async getReOrderReasonList() {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/ReOrderReasons`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.reOrderReasonList = res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async getVendorList() {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/Vendors`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.vendorList = res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }
}
const hydrate = create({});

const ProductionOrderStore = new ProductionOrderStoreClass();

export default ProductionOrderStore;

hydrate("productionOrder", ProductionOrderStore).then(() => {});
