import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Switch, BrowserRouter } from "react-router-dom";
import Route from "./Route";
import ClientsHubAnt from "../pages/ClientsHub/ClientsHubPageAnt";
import SchedulePage from "pages/Schedule/SchedulePage";
import DashboardPage from "pages/Dashboard/DashboardPage";
import QuickAccessPage from "pages/QuickAccess/QuickAccessPage";
import Navigation from "../components/navigation/Navigation";
import LoginPage from "../pages/Auth/LoginPage";
import TasksPage from "../pages/Tasks/TasksPage";
import MessagesPage from "../pages/Messages/MessagesPage";
import ClockPage from "pages/Clock/ClockPage";
import NotificationsPage from "pages/Notifications/NotificationsPage";
import PhoneCallsPage from "pages/PhoneCalls/PhoneCallsPage";
import RecentPage from "pages/Recent/RecentPage";
import InvoicePage from "pages/Invoice/InvoicePage";
import SettingsPage from "pages/Settings/SettingsPage";
import JoyPage from "../pages/Joy/JoyPage";
import ReportsPage from "../pages/Reports/Reports";
import PhotographerSessionPage from "../pages/Photographer/SessionInfoPage";
import PreferencesPage from "../pages/Preferences/PreferencesPage";

import Alert from "components/alerts/Alert";
import { Layout } from "antd";
import { AuthStoreClass } from "stores/AuthStore";

import SessionTimeout from "../components/session-timeout/session-timeout";
import SearchPage from "pages/Search/SearchPage";
import ResetPasswordPage from "pages/ResetPassword/ResetPasswordPage";
import WorkFlowPage from "pages/WorkFlow/WorkFlowPage";
import PersonalDashboardPage from "pages/PersonalDashboard/PersonalDashboard";

interface Props {
  authStore?: AuthStoreClass;
}

const Router: React.FC<Props> = ({ authStore }) => {
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (!sessionStorage.getItem("authenticated")) {
      authStore?.logout();
    }
    setCurrentPath(window.location.pathname);
    //addNotification();
    // getNotificationList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BrowserRouter>
      <Alert />
      <Layout>
        {currentPath == "/photographersessions" ? <></> : <Navigation />}
        <Switch>
          <Route path="/" component={LoginPage} exact={true} />
          <Route path="/resetPassword" component={ResetPasswordPage} exact={true} />
          <Route protected exact path="/home" component={DashboardPage} />
          <Route protected exact path="/quickaccess" component={QuickAccessPage} />
          <Route protected exact path="/schedule/:type?/:action?/:id?" component={SchedulePage} />
          <Route protected exact path="/clientshub" component={ClientsHubAnt} />
          <Route protected exact path="/tasks" component={TasksPage} />
          <Route protected exact path="/messages" component={MessagesPage} />
          <Route protected exact path="/notifications" component={NotificationsPage} />
          <Route protected exact path="/calls/:action?/:id?" component={PhoneCallsPage} />
          <Route protected exact path="/clock" component={ClockPage} />
          <Route protected exact path="/recent" component={RecentPage} />
          <Route protected exact path="/invoice" component={InvoicePage} />
          <Route protected exact path="/settings" component={SettingsPage} />
          <Route exact path="/preferences" component={PreferencesPage} />
          <Route protected exact path="/joy" component={JoyPage} />
          <Route protected exact path="/search" component={SearchPage} />
          <Route protected exact path="/dashboardreports/reports" component={ReportsPage} />
          <Route
            protected
            exact
            path="/dashboardreports/personaldashboard"
            component={PersonalDashboardPage}
          />
          <Route exact path="/photographersessions" component={PhotographerSessionPage} />
          <Route exact path="/workflow" component={WorkFlowPage} />
        </Switch>
      </Layout>
      <SessionTimeout />
    </BrowserRouter>
  );
};

export default inject("authStore")(observer(Router));
