import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Form, Col, Typography, Button, Spin } from "antd";
import dayjs from "dayjs";
import ClientRow from "../rows/ClientRow";
import { MessageStoreClass } from "stores/MessageStore";
import { ClientStoreClass } from "stores/ClientStore";
import { LeftCircleFilled, UserOutlined } from "@ant-design/icons";

interface Props {
  messageStore?: MessageStoreClass;
  clientStore?: ClientStoreClass;
  setShowMessageDetail?: any;
  setSelectedKey?: any;
}

const { Text } = Typography;

const ViewMessage: React.FC<Props> = ({ messageStore, clientStore, setShowMessageDetail, setSelectedKey }) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (messageStore?.smsDetails?.ClientKey) {
      clientStore?.setActiveClientKey(messageStore.smsDetails.ClientKey);
    }
    if (typeof messageStore?.smsDetails !== "undefined") {
      form?.setFieldsValue({
        ClientKey: messageStore?.smsDetails.ClientKey,
      });
    }
  }, [messageStore?.smsDetails]);

  useEffect(() => {
    return () => {
      messageStore?.setSmsDetails(undefined);
    };
  }, []);

  return (
    <Col xs={24} sm={18} className="Message_Detail">
      <Spin size={'default'} spinning={messageStore?.isLoading}>
        <Col className="MD__Panel">
          <Col className="MD__Panel--Head">
            <Col className="BackButton">
              <Button onClick={() => { setShowMessageDetail(false); setSelectedKey(''); }}><LeftCircleFilled /></Button>
            </Col>
            <Col className="MD__Panel--Head__Left">
              {messageStore?.smsDetails?.Key && messageStore?.smsDetails?.ClientKey && (
                <Col className="MD__Panel--Head__Subject">
                  {/* <Title level={3}>{getClientName()}</Title> */}
                  <ClientRow clientKey={messageStore?.smsDetails?.ClientKey} />
                </Col>
              )}
              <Col className="MD__Panel--Head__Detail">
                <Col className="MD__Panel--Head__Detail--Icon">
                  <UserOutlined />
                </Col>
                <Col className="MD__Panel--Head__Detail--Data">
                  {/* <Text><b>From:</b> {messageStore?.emailDetails?.From}</Text> */}
                  <Text><b>To:</b> {messageStore?.smsDetails?.ToNumber}</Text>
                  <Text><b>Date/Time:</b> {dayjs(messageStore?.smsDetails?.SentDateTime).format("YYYY-MM-DD / h:MM A")}</Text>
                </Col>
              </Col>
            </Col>
            <Col className="MD__Panel--Head__Right">
              {/* <Button><DeleteFilled /></Button> */}
            </Col>
          </Col>
          <Col className="MD__Panel--Body">
            <Col className="MD__Panel--Body__Title">
              {/* <Title level={4}>{messageStore?.emailDetails?.Subject}</Title> */}
              <Text>{messageStore?.smsDetails?.Incoming === false ? "Outgoing" : "Incoming"}</Text>
            </Col>
            <Col className="MD__Panel--Body__Description">
              <Text>{messageStore?.smsDetails?.MessageText}</Text>
            </Col>
          </Col>
        </Col>
      </Spin>
    </Col>
    // <>
    //   {messageStore?.smsDetails?.Key && messageStore?.smsDetails?.ClientKey && (
    //     <div className="form-group">
    //       <Form.Item
    //         name="ClientKey"
    //         hasFeedback={isLoading}
    //         validateStatus={isLoading ? "validating" : undefined}
    //       >
    //         <ClientRow clientKey={messageStore?.smsDetails?.ClientKey} />
    //       </Form.Item>
    //     </div>
    //   )}
    //   <Row className="form-group" gutter={20}>
    //     <Descriptions
    //       title="Message Information"
    //       layout="vertical"
    //       colon={false}
    //       column={2}
    //     >
    //       <Descriptions.Item label="To" labelStyle={labelStyle}>
    //         {messageStore?.smsDetails?.ToNumber}
    //       </Descriptions.Item>
    //       <Descriptions.Item label="SMS" labelStyle={labelStyle}>
    //         {messageStore?.smsDetails?.MessageText}
    //       </Descriptions.Item>
    //       <Descriptions.Item label="Date/Time" labelStyle={labelStyle}>
    //         {dayjs(messageStore?.smsDetails?.SentDateTime).format(
    //           "YYYY-MM-DD / h:MM A"
    //         )}
    //       </Descriptions.Item>
    //       <Descriptions.Item label="Direction" labelStyle={labelStyle}>
    //         {messageStore?.smsDetails?.Incoming === false
    //           ? "Outgoing"
    //           : "Incoming"}
    //       </Descriptions.Item>
    //     </Descriptions>
    //   </Row>
    // </>
  );
};

export default inject(
  "messageStore",
  "clientStore",
  "scheduleStore"
)(observer(ViewMessage));
