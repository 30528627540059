import { observable, action, makeObservable, computed } from "mobx";
import { create, persist } from "mobx-persist";
import axios, { AxiosResponse, AxiosError } from "axios";

import type {
  Client,
  ClientList,
  Snapshot,
  FamilyMember,
  ClientStatus,
  ClientGroup,
  ClientReferralProgram,
} from "interfaces/client.int";
import MessageStore from "./MessageStore";
import TaskStore from "./TaskStore";
import PhoneCallStore from "./PhoneCallStore";
import AppointmentStore from "./AppointmentStore";
import SessionStore from "./SessionStore";
import InvoiceStore from "./InvoiceStore";
import AlertStore from "./AlertStore";

export class ClientStoreClass {
  @persist("list") clientsList: Array<ClientList> = [];
  familyMembers: Array<FamilyMember> = [];
  @persist("list") clientStatuses: Array<ClientStatus> = [];
  @persist("list") clientGroups: Array<ClientGroup> = [];
  @persist("list") clientReferralPrograms: Array<ClientReferralProgram> = [];
  @persist activeClientKey: string | undefined;
  @persist activeClientNumber: string | undefined;
  @persist("object") snapshot: Snapshot | undefined;
  clientInfo: Client | undefined;
  isSelectToggle: boolean = false;
  isLoading: boolean = false;
  searchTerm: string | undefined = "";
  searchBy: any = {
    children: "Last Name",
    value: "lastName",
  };
  searchType: string = "Clients";
  organizationList: any = [];
  leadSourceList: any = [];
  perPageSize: number = 20;

  constructor() {
    makeObservable(this, {
      clientsList: observable,
      familyMembers: observable,
      clientStatuses: observable,
      clientGroups: observable,
      clientReferralPrograms: observable,
      clientInfo: observable,
      activeClientKey: observable,
      activeClientNumber: observable,
      isLoading: observable,
      searchTerm: observable,
      searchBy: observable,
      getClients: action,
      getClientsSelect: action,
      getFamilyMembers: action,
      getClient: action,
      getClientSelect: action,
      addClient: action,
      addEditFamilyMember: action,
      deleteFamilyMember: action,
      editClient: action,
      updateSearchTerm: action,
      setSearchBy: action,
      sortedClientsList: computed,
      selectClientList: computed,
      selectClientStatuses: computed,
      selectClientGroups: computed,
      selectClientReferralPrograms: computed,
      getClientDataFromList: action,
      setClientInfo: action,
      setClientsList: action,
      setActiveClientKey: action,
      setActiveClientNumber: action,
      snapshot: observable,
      getSnapshot: action,
      isSelectToggle: observable,
      setSelectToggle: action,
      clearLists: action,
      searchType: observable,
      updateSearchType: action,
      organizationList: observable,
      selectOrganization: computed,
      getOrganization: action,
      leadSourceList: observable,
      getLeadSource: action,
      getClientsByStatus: action,
      deleteClient: action,
      perPageSize: observable,
      setPerPageSize: action,
      clearClientInfo: action,
      getClientByKey: action,
      setIsLoading: action,
    });
  }

  get selectClientList() {
    return this.sortedClientsList
      ?.map((v: any) => {
        let displayName: string = "";
        if (v?.LastName == null && v?.FirstName == null)
          displayName = v?.ClientNumber ? `Client #${v?.ClientNumber}` : "Name not found";
        else {
          displayName = `${v?.LastName !== null ? `${v?.LastName},` : ""} ${v?.FirstName} ${
            v?.PhoneNumber0 ? `- ${v?.PhoneNumber0}` : ""
          }`;
        }

        return {
          label: displayName,
          value: v.Key || "",
          clientNumber: v.ClientNumber || "",
        };
      })
      .slice(0, 25);
  }

  get selectClientStatuses() {
    return this.clientStatuses?.map((cs: ClientStatus) => {
      return {
        label: cs.Description,
        value: cs.Key || "",
      };
    });
  }

  get selectClientGroups() {
    return this.clientGroups?.map((cs: ClientGroup) => {
      return {
        label: cs.Description,
        value: cs.Key || "",
      };
    });
  }

  get selectClientReferralPrograms() {
    return this.clientReferralPrograms?.map((cs: ClientReferralProgram) => {
      return {
        label: cs.Description,
        value: cs.Key || "",
      };
    });
  }

  get selectOrganization() {
    return this.organizationList?.map((cs: ClientStatus) => {
      return {
        label: cs.Description,
        value: cs.Key || "",
      };
    });
  }

  setPerPageSize(size: number) {
    this.perPageSize = size;
  }

  getClientDataFromList(clientKey?: string, field?: string): string {
    let filtered: any = this.clientsList?.filter((v: ClientList) => clientKey === v.Key)[0];
    let mapped = field ? filtered[field] : filtered;

    return mapped;
  }

  findDuplicateEmailFromList(email?: string, clientKey?: string): boolean {
    let matched: any = this.clientsList?.filter(
      (v: ClientList) => email === v.Email && clientKey !== v.Key
    )[0];
    return matched;
  }

  get sortedClientsList() {
    const sortedList = this.clientsList.slice().sort((a: any, b: any) => {
      const lastA = a.LastName?.toUpperCase() || a.FirstName?.toUpperCase();
      const lastB = b.LastName?.toUpperCase() || b.FirstName?.toUpperCase();
      if (!lastB || lastA < lastB) {
        return -1;
      } else if (!lastA || lastA > lastB) {
        return 1;
      }
      // Last names are the same, check first names
      const firstA = `${a.FirstName}`.toUpperCase();
      const firstB = `${b.FirstName}`.toUpperCase();
      if (firstA < firstB) {
        return -1;
      }
      if (firstA > firstB) {
        return 1;
      }

      return 0;
    });
    // Filter list if there is a search term
    // if (this.searchTerm.length) {
    //   return sortedList.filter((client) => {
    //     const clientName = `${client.FirstName} ${client.LastName}`;
    //     return clientName.toLowerCase().includes(this.searchTerm.toLowerCase());
    //   });
    // }
    return sortedList;
  }

  updateSearchTerm(searchTerm: string | undefined) {
    this.searchTerm = searchTerm;
  }
  updateSearchType(searchType: string) {
    this.searchType = searchType;
  }

  async getClientsSelect() {
    this.isLoading = true;
    const params: any = {};
    if (this.searchTerm) {
      params["LastName"] = this.searchTerm;
    }
    return await axios({
      method: "GET",
      url: "/Clients",
      params,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  async getClients() {
    this.isLoading = true;
    const params: any = {};
    if (this.searchTerm) {
      params[this.searchBy.value] = this.searchTerm;
    }
    return await axios({
      method: "GET",
      url: "/Clients",
      params,
    })
      .then(
        action((res: AxiosResponse) => {
          this.setClientsList(res.data);
          this.isLoading = false;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  async getClientsByStatus(StatusKey: string) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/Clients?clientStatus=${StatusKey}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  async getClientSelect(clientKey: string) {
    if (!clientKey && this.activeClientKey) return;
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/Client/${clientKey}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.clientInfo = res.data;
          //this.clientsList = [res.data]; //Commented this since it's
          // changing the entire list to the active client
          this.isLoading = false;
          return res.data;
        })
      )
      .catch(
        action((err: AxiosError) => {
          this.isLoading = false;
          // throw new Error(err.message);
        })
      );
  }

  async getClient(clientKey: string | undefined) {
    if (!clientKey && this.activeClientKey) return;
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/Client/${clientKey ? clientKey : this.activeClientKey}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.clientInfo = res.data;
          //this.clientsList = [res.data]; //Commented this since it's
          // changing the entire list to the active client
          // this.clientStatuses = this.clientInfo.ClientStatusMaintList;
          this.isLoading = false;
          return res.data;
        })
      )
      .catch(
        action((err: AxiosError) => {
          this.isLoading = false;
          // throw new Error(err.message);
        })
      );
  }

  async getClientByKey(clientKey: string | undefined) {
    if (!clientKey && this.activeClientKey) return;
    return await axios({
      method: "GET",
      url: `/Client/${clientKey ? clientKey : this.activeClientKey}`,
    })
      .then(
        action((res: AxiosResponse) => {
          return res.data;
        })
      )
      .catch(
        action((err: AxiosError) => {
          // throw new Error(err.message);
        })
      );
  }

  setClientInfo(client?: Client) {
    this.clientInfo = client;
  }

  setClientsList(newList: any) {
    this.clientsList = newList;
  }

  clearClientInfo() {
    this.clientInfo = {};
  }

  async addClient(clientInfo: any) {
    this.isLoading = true;
    return await axios({
      method: "PUT",
      url: `/Client/`,
      data: clientInfo,
    })
      .then(
        action((res: AxiosResponse) => {
          this.getClients();
          this.isSelectToggle = false;
        })
      )
      .catch((err: AxiosError) => {
        this.isSelectToggle = true;
        if (err!.response?.status == 400)
          AlertStore.setAlertMessage(
            "Client creation failed. A duplicate client was found based on your company preferences.",
            "error"
          );
        else AlertStore.setAlertMessage(err!.response!.data, "error");
        this.isLoading = false;
      });
  }

  async editClient(filteredInfo: any) {
    this.isLoading = true;
    return await axios({
      method: "PUT",
      url: "/Client",
      data: filteredInfo,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.setClientInfo({ ...res.data });
          this.getClients();
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        this.isSelectToggle = true;
        throw new Error(err.message);
      });
  }

  async deleteClient(clientKey: string | undefined) {
    this.isLoading = true;
    return await axios({
      method: "DELETE",
      url: `/Client?Key=${clientKey}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          return res;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  setActiveClientKey(clientKey: string | undefined) {
    this.activeClientKey = clientKey;
  }
  setActiveClientNumber(clientNumber?: string | undefined) {
    this.activeClientNumber = clientNumber;
  }

  async getSnapshot() {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: "/Snapshot",
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.snapshot = res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  async getFamilyMembers(clientKey?: string) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: "/ClientFamilyMembers",
      params: {
        clientKey,
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.familyMembers = res.data.FamilyMembers;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  async getClientStatuses() {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: "/clientStatuses",
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.clientStatuses = res.data;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  async getClientGroups() {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: "/ClientGroupMaints",
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.clientGroups = res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  async getClientReferralPrograms() {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: "/ClientReferralProgramMaints",
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.clientReferralPrograms = res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  async getOrganization() {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: "/organization",
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.organizationList = res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  async getLeadSource() {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: "/LeadSource",
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.leadSourceList = res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  async addEditFamilyMember(clientKey: string, familyInfo: any, familyKey?: string) {
    this.isLoading = true;

    return await axios({
      method: "PUT",
      url: "/ClientFamilyMember",
      data: {
        ...familyInfo,
        clientKey,
        Key: familyKey,
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.familyMembers = res.data.FamilyMembers;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  async deleteFamilyMember(clientKey: string, familyKey: string) {
    this.isLoading = true;
    return await axios({
      method: "DELETE",
      url: `/ClientFamilyMember/${familyKey}`,
      params: {
        clientKey,
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.familyMembers = this.familyMembers.filter((member) => member.Key !== familyKey);
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  setIsLoading(loading: boolean) {
    this.isLoading = loading;
  }

  setSelectToggle(isVisible: boolean) {
    this.isSelectToggle = isVisible;
  }

  setSearchBy(search: any) {
    this.searchBy = search;
  }

  clearLists() {
    this.setActiveClientKey(undefined);
    this.updateSearchTerm("");
    MessageStore.cleanUp();
    TaskStore.cleanUp();
    PhoneCallStore.cleanUp();
    InvoiceStore.cleanUp();
    this.clientsList = [];
    AppointmentStore.appointmentList = [];
    SessionStore.sessionList = [];
    InvoiceStore.invoiceList = [];
    this.clientStatuses = [];
    MessageStore.setMessagesList([]);
    PhoneCallStore.phoneCallList = [];
    this.snapshot = {};
  }
}

const hydrate = create({});

const ClientStore = new ClientStoreClass();

export default ClientStore;

hydrate("client", ClientStore).then((ClientStore) => {});
