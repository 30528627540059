import React, { useEffect, useState } from "react";
import "./gallery-folder-view.scss";
import { Collapse } from "antd";
import { FolderFilled, FolderOpenFilled } from "@ant-design/icons";
import MasonryGridViewer from "components/masonry-grid-viewer/masonry-grid-viewer";
import { observer, inject } from "mobx-react";
import { autorun, toJS } from "mobx";
const { Panel } = Collapse;

const GalleryFolderView = (props: any) => {
  const [folders, setFolders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const _sortFolders = (folders: any[]) => {
    let sortedFolders: any = [];
    // Check if hidden folder is available
    const hiddenFolderIndex = folders.findIndex((f) => f.name === "Hidden");
    if (hiddenFolderIndex > -1) {
      sortedFolders.push(Object.assign([], folders)[hiddenFolderIndex]);
      // Remove it from orginal array
      folders.splice(hiddenFolderIndex, 1);
    }
    // Check if favourite folder is available
    const favouriteFolderIndex = folders.findIndex(
      (f) => f.name === "Favorites"
    );
    if (favouriteFolderIndex > -1) {
      sortedFolders.push(Object.assign([], folders)[favouriteFolderIndex]);
      // Remove it from orginal array
      folders.splice(favouriteFolderIndex, 1);
    }
    sortedFolders = [...sortedFolders, ...folders];
    return sortedFolders;
  };

  const _loadFoldersFromStore = () => {
    if (props.galleryStore?.sessionFolders?.length) {
      setIsLoading(false);
      setFolders(props.galleryStore.sessionFolders);
    }
  };

  const _initiateLoad = async () => {
    setIsLoading(true);
    _loadFoldersFromStore();
    await props.galleryStore.fetchSessionImages();
    _loadFoldersFromStore();
    setIsLoading(false);
  };

  useEffect(() => {
    autorun(() => {
      const folders = toJS(props.galleryStore.sessionFolders);
      setFolders(folders);
    });
    _initiateLoad();
  }, []);

  if (isLoading) {
    return (
      <>
        <div className="loaderWrapper">
          <div className="loader"></div>
        </div>
      </>
    );
  } else {
    const trimmedValue = props.searchText.trim().toLowerCase();
    const sortedFolders = _sortFolders(Object.assign([], folders));
    const filteredFolders = sortedFolders.filter(
      (folder: any) => folder.name.toLowerCase().indexOf(trimmedValue) > -1
    );

    if (filteredFolders.length) {
      return (
        <>
          <div className="galleryFolderViewWRapper">
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <>{isActive ? <FolderOpenFilled /> : <FolderFilled />}</>
              )}
              className="site-collapse-custom-collapse"
            >
              {filteredFolders.map((folder: any, folderIndex: number) => (
                <Panel
                  header={folder.name}
                  key={folderIndex}
                  className="site-collapse-custom-panel"
                >
                  <MasonryGridViewer images={folder.images} />
                </Panel>
              ))}
            </Collapse>
          </div>
        </>
      );
    } else {
      return <div className="no-item">No Items Available</div>;
    }
  }
};

export default inject("galleryStore")(observer(GalleryFolderView));
