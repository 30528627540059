import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Form, Space, Button, Drawer, Typography, Modal, message, Grid } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import type { DrawerProps } from "antd/lib/drawer";
import { DeleteOutlined, CheckOutlined } from "@ant-design/icons";

import type { PhoneCallStoreClass } from "stores/PhoneCallStore";
import AddEditPhoneCall from "../forms/AddEditPhoneCall";
import useWindowDimension from "components/util/Dimension";
import { saveLocalStorage } from "components/util/localStorage";
import { ClientStoreClass } from "stores/ClientStore";
const { useBreakpoint } = Grid;

dayjs.extend(utc);

const { Title } = Typography;

interface Props {
  onToggle?: (x: boolean) => void;
  phoneCallStore?: PhoneCallStoreClass;
  activeClientKey?: any;
  clientStore?: ClientStoreClass;
  clientKey?: string;
}

const PhoneCallDrawerForm: React.FC<Props> = ({
  onToggle,
  phoneCallStore,
  activeClientKey,
  clientKey,
  clientStore,
}) => {
  const [isDeleteModalShown, setIsDeleteModalShown] = useState<boolean>(false);
  const { innerWidth } = useWindowDimension();
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  useEffect(() => {
    if (!phoneCallStore?.isSelectToggle) {
      setTimeout(() => {
        form?.resetFields();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneCallStore?.isSelectToggle]);

  const resetActivePhoneCall = () => {
    phoneCallStore?.setActivePhoneCall(undefined);
    phoneCallStore?.setSelectToggle(false);
  };

  const toggleDrawer = (toggle: boolean) => {
    onToggle && onToggle(toggle);
    phoneCallStore?.setSelectToggle(toggle);
    phoneCallStore?.setActivePhoneCall(undefined);
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalShown(!isDeleteModalShown);
    phoneCallStore?.setActivePhoneCall(undefined);
  };

  const handleDelete = async () => {
    setIsDeleteModalShown(false);
    const loadingMsgKey = "notification-key";
    message.loading({
      content: "Deleting...",
      key: loadingMsgKey,
      duration: 0,
    });

    await clientStore?.getClient(activeClientKey);

    if (phoneCallStore?.activePhoneCall?.Key) {
      try {
        await phoneCallStore?.deletePhoneCall(phoneCallStore?.activePhoneCall?.Key).then((res) => {
          resetActivePhoneCall();
          message.success({
            content: "Successfully deleted!",
            key: loadingMsgKey,
            duration: 1,
          });
          saveLocalStorage({
            name: "Deleted",
            value: `Phone call - ${
              clientStore?.clientInfo?.FirstName + " " + clientStore?.clientInfo?.LastName
            } ${
              phoneCallStore?.activePhoneCall?.Description
                ? "-" + phoneCallStore?.activePhoneCall?.Description
                : ""
            }`,
          });
        });
      } catch (e) {
        message.error({
          content: "Unable to delete! Try again.",
          key: loadingMsgKey,
          duration: 1,
        });
      }
    } else {
      message.error({
        content: "Unable to delete! Try again.",
        key: loadingMsgKey,
        duration: 1,
      });
    }
  };

  const handleSuccess = async () => {
    return;
    // try {
    //   await phoneCallStore?.getPhoneCallList().then(() => {
    //     resetActivePhoneCall();
    //     message.success(`Resynced data!`);
    //   });
    // } catch (e) {}
  };

  const handleError = () => {
    toggleDrawer(false);
    setTimeout(() => {
      message.error("Unable to fetch phone call. Does not exist or try logging in again.");
    }, 500);
  };

  const ModalHeader = () => {
    return (
      <Row>
        <Col xs={10}>
          <Title level={2}>Phone Call</Title>
        </Col>
        <Col
          xs={14}
          style={{
            textAlign: "right",
          }}
        >
          <Space>
            {typeof phoneCallStore?.activePhoneCall !== "undefined" && (
              <Button
                icon={<DeleteOutlined />}
                disabled={phoneCallStore?.isLoading}
                type="text"
                className="btn-action"
                style={{
                  backgroundColor: "rgb(142, 59, 70)",
                  borderColor: "rgb(142, 59, 70)",
                  color: "white",
                }}
                onClick={toggleDeleteModal}
              >
                Delete
              </Button>
            )}
            <Button
              icon={<CheckOutlined />}
              disabled={phoneCallStore?.isLoading}
              style={{
                backgroundColor: "rgb(255, 179, 28)",
                borderColor: "rgb(255, 179, 28)",
                color: "white",
              }}
              className="btn-action"
              shape="round"
              onClick={() => form.validateFields().then(() => form.submit())}
            >
              Save
            </Button>
          </Space>
        </Col>
      </Row>
    );
  };

  const drawerParams: DrawerProps = {
    width: screens.xs ? innerWidth : innerWidth - 250,
    placement: "right",
    closable: true,
    forceRender: false,
    destroyOnClose: true,
    title: <ModalHeader />,
    maskStyle: {},
    className: "drawer-form",
    push: {
      distance: 20,
    },
  };

  return (
    <>
      <Drawer
        onClose={() => toggleDrawer(false)}
        visible={phoneCallStore?.isSelectToggle}
        {...drawerParams}
      >
        <AddEditPhoneCall
          activeClientKey={activeClientKey}
          clientKey={clientKey}
          form={form}
          onSuccess={handleSuccess}
          onError={handleError}
        />
      </Drawer>
      <Modal
        title={`Delete a phone call`}
        visible={isDeleteModalShown}
        onCancel={toggleDeleteModal}
        onOk={handleDelete}
      >
        <p>Are you sure you want to delete this phone call?</p>
      </Modal>
    </>
  );
};

export default inject("phoneCallStore", "clientStore")(observer(PhoneCallDrawerForm));
