import {
  EyeInvisibleOutlined,
  FolderAddOutlined,
  FullscreenOutlined,
  HeartOutlined,
  ShoppingCartOutlined,
  HeartFilled,
  EyeOutlined,
} from "@ant-design/icons";
import Image from "components/core/image/image";
import { default as React, useState } from "react";
import EventEmitter from "utils/event-emitter";
import { inject, observer } from "mobx-react";

const isTouchDevice = !!("ontouchstart" in window || navigator.maxTouchPoints);
const ImageContainer = (props: any) => {
  const [isActionsEnabled, setIsActionsEnabled] = useState(false);
  const { image } = props;

  const _modifyImageFolder = () => {
    EventEmitter.trigger("modify-image-folders", image);
  };

  const _isHidden = () => {
    const hiddenFolder = image.folders?.find(
      (folderName: string) => folderName === "Hidden"
    );
    if (hiddenFolder) {
      return true;
    }
  };

  const _isFavourite = () => {
    const favouriteFolder = image.folders?.find(
      (folderName: string) => folderName === "Favorites"
    );
    if (favouriteFolder) {
      return true;
    }
  };

  const _toggleHiddenMode = () => {
    if (_isHidden()) {
      // Unhide it
      props.galleryStore.removeImageFromFolder("Hidden", image.fileName);
    } else {
      // Hide it
      props.galleryStore.addImageToFolder("Hidden", image.fileName);
    }
  };

  const _toggleFavouriteMode = () => {
    if (_isFavourite()) {
      // Unhide it
      props.galleryStore.removeImageFromFolder("Favorites", image.fileName);
    } else {
      // Hide it
      props.galleryStore.addImageToFolder("Favorites", image.fileName);
    }
  };

  const _showSlideView = () => {
    EventEmitter.trigger("show-slide-view", {
      groupImages: props.groupImages,
      image,
    });
  };

  const _addToCart = () => {
    EventEmitter.trigger("add-to-cart", image);
  };

  const _handleContainerHoverIn = () => {
    if (!isTouchDevice && !isActionsEnabled) {
      setIsActionsEnabled(true);
    }
  };

  const _handleContainerClickAction = () => {
    if (isTouchDevice && !isActionsEnabled) {
      setIsActionsEnabled(true);
    }
  };

  const _handleContainerBlurAction = () => {
    if (isActionsEnabled) {
      setIsActionsEnabled(false);
    }
  };

  console.log("isTouchDevice :>> ", isTouchDevice);

  return (
    <div
      className="picture_wrapper"
      key={image.thumbnailPath}
      onMouseEnter={_handleContainerHoverIn}
      onClick={_handleContainerClickAction}
      onMouseLeave={_handleContainerBlurAction}
    >
      {isActionsEnabled && (
        <div className="picture_options">
          <button className="fullscreenBtn" onClick={_showSlideView}>
            <FullscreenOutlined />
          </button>
          <div className="otherActions">
            <button className="gridAction" onClick={_addToCart}>
              <ShoppingCartOutlined />
            </button>
            <button className="gridAction" onClick={_toggleFavouriteMode}>
              {_isFavourite() ? <HeartFilled /> : <HeartOutlined />}
            </button>
            <button
              className="gridAction"
              onClick={(e) => _modifyImageFolder()}
            >
              <FolderAddOutlined />
            </button>
            <button className="gridAction" onClick={_toggleHiddenMode}>
              {_isHidden() ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            </button>
          </div>
        </div>
      )}
      <Image
        src={image.thumbnailPath}
        ratio={image.unformattedData.Height / image.unformattedData.Width}
      />
    </div>
  );
};

export default inject("galleryStore")(observer(ImageContainer));
