import React, { useEffect, useState } from "react";
import { Form, Input, Typography, Divider, DatePicker, Select, Checkbox } from "antd";
import dayjs from "dayjs";
import type { FormInstance } from "antd/lib/form/hooks/useForm";
const { Title } = Typography;
const { Option } = Select;

interface Props {
  id: string;
  form: FormInstance;
  data: any;
  type: "Add" | "Edit";
  handleSubmit: (values: any) => Promise<void>;
}

const FamilyForm: React.FC<Props> = ({ id, form, handleSubmit, data, type }) => {
  const initialValues = {
    FirstName: data.FirstName || "",
    LastName: data.LastName || "",
    Birthdate: dayjs(data.Birthdate) || null,
    Relationship: data.Relationship || "",
    DecisionMaker: data.DecisionMaker,
    Favorite: data.Favorite,
  };
  useEffect(() => {
    // This resets the form fields when editing different clients
    form.resetFields();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form
      initialValues={initialValues}
      form={form}
      id={id}
      onFinish={handleSubmit}
      layout="vertical"
      className="login-form"
      style={{ padding: "2rem" }}
    >
      <Title>{type} Family Member</Title>
      <Divider className="margin-y-sm" />
      <Form.Item className="mb-sm" label="First Name" name="FirstName">
        <Input id="firstname" />
      </Form.Item>
      <Form.Item className="mb-sm" label="Last Name" name="LastName">
        <Input id="lastname" />
      </Form.Item>
      <Form.Item label="Relationship" name="Relationship">
        <Select>
          <Option value="Brother">Brother</Option>
          <Option value="Daughter">Daughter</Option>
          <Option value="Fiance">Fiance</Option>
          <Option value="Granddaughter">Granddaughter</Option>
          <Option value="Grandfather">Grandfather</Option>
          <Option value="Grandmother">Grandmother</Option>
          <Option value="Grandson">Grandson</Option>
          <Option value="Pet">Pet</Option>
          <Option value="Sister">Sister</Option>
          <Option value="Son">Son</Option>
          <Option value="Spouse">Spouse</Option>
        </Select>
      </Form.Item>

      <Form.Item className="mb-sm" label="Birthday" name="Birthdate">
        <DatePicker size="large" style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item className="mb-sm" name="DecisionMaker" valuePropName="checked">
        <Checkbox>Decision Maker</Checkbox>
      </Form.Item>
      <Form.Item className="mb-sm" name="Favorite" valuePropName="checked">
        <Checkbox>Favorite</Checkbox>
      </Form.Item>
    </Form>
  );
};

export default FamilyForm;
