import Selector from "components/selector/selector";
import React from "react";

const ImageEnhancementsSelector = (props: any) => {
  const {
    width = 200,
    onEnhancementUpdate = () => {},
    value = "",
    allEnhancements = [],
  } = props;

  const _onUpdate = (enhancementKey: string) => {
    if (enhancementKey) {
      const selectedEnhancement = allEnhancements.find(
        (enhancement: any) => enhancement.Key === enhancementKey
      );
      onEnhancementUpdate(selectedEnhancement);
    } else {
      onEnhancementUpdate(null);
    }
  };

  var formattedEnhancements = allEnhancements?.map((enhancement: any) => ({
    value: enhancement.Key,
    label: enhancement.Description,
  }));

  if (formattedEnhancements?.length) {
    return (
      <>
        <div className="selectorWrapper">
          <p className="label">Enhancement</p>
          <Selector
            width={width}
            placeholder="Select an enhancement"
            options={formattedEnhancements}
            value={value}
            allowClear={true}
            onUpdate={_onUpdate}
          />
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default ImageEnhancementsSelector;
