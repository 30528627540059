//@ts-nocheck
import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { useQuery } from "react-query";
import { Layout, Row, Col, Card, message, Typography, Statistic, Button, Segmented } from "antd";
import { useHistory } from "react-router-dom";
import moment from "moment";
import type { Snapshot, SnapshotItems } from "interfaces/client.int";
import type { ClientStoreClass } from "stores/ClientStore";
import type { TaskStoreClass } from "stores/TaskStore";
import type { PhoneCallStoreClass } from "stores/PhoneCallStore";
import type { AuthStoreClass } from "stores/AuthStore";
import UpcomingSessions from "components/dashboard/UpcomingSessions";
import RecentNotifications from "components/dashboard/RecentNotifications";
import "./DashboardPage.scss";
import AppointmentDrawerForm from "components/drawers/AppointmentDrawerForm";
import PhoneCallDrawerForm from "components/drawers/PhoneCallDrawerForm";
import SessionDrawerForm from "components/drawers/SessionDrawerForm";
import TaskDrawerForm from "components/drawers/TaskDrawerForm";
import { NotificationStoreClass } from "stores/NotificationStore";
import DashboardTaksList from "components/dashboard/DashboardTaksList";
import DashboardPhoneCalls from "components/dashboard/DashboardPhoneCalls";
import ClientTabs from "components/tabs/ClientTabs";

interface Props {
  clientStore?: ClientStoreClass;
  taskStore: TaskStoreClass;
  phoneCallStore?: PhoneCallStoreClass;
  authStore?: AuthStoreClass;
  notificationStore?: NotificationStoreClass;
}

const { Content } = Layout;

const { Title, Text } = Typography;

const DashboardPage: React.FC<Props> = ({
  clientStore,
  taskStore,
  phoneCallStore,
  authStore,
  notificationStore,
}) => {
  const history = useHistory();
  const [snapshotTimeframe, setSnapshotTimeframe] = useState("Today");
  const snapshot: Snapshot | undefined = clientStore?.snapshot;
  const formatter: Intl.NumberFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });

  const { tasksLoading, tasksData } = useQuery(["tasks"], () => {
    return getTasks();
  });

  const { callsLoading, phoneCallData } = useQuery(["phoneCalls"], () => {
    return getPhoneCalls();
  });

  useEffect(() => {
    getSnapshot();
  }, []);

  const getSnapshot = async () => {
    await clientStore?.getSnapshot();
  };

  const getTasks = async () => {
    try {
      await taskStore?.getTasks();
    } catch (e) {
      message.warning("Unable to fetch tasks. Try logging in again.");
    }
  };

  const getPhoneCalls = async () => {
    try {
      await phoneCallStore?.getPhoneCallList();
    } catch (e) {
      message.warning("Unable to fetch phone calls. Try logging in again.");
    }
  };

  let miniChartData: Array<number> = [];
  let monthsDataWithIndex: Array<string> = [];

  if (snapshot && snapshot?.CashReceiptsByMonth) {
    Object.keys(snapshot.CashReceiptsByMonth).map((item, index) => {
      let receiptDate = moment(item, "yyyy-MM").format();
      if (moment(receiptDate).isSame(new Date(), "year")) {
        miniChartData.push(Number(snapshot?.CashReceiptsByMonth![item]));
        monthsDataWithIndex.push(item);
      }
    });
  }

  return (
    <>
      <Content className="main-content pad-top-0">
        <div className="main-content-pads">
          <Card className="Dashboard-Welcome">
            <Row gutter={20}>
              {/* <Col xs={24} sm={3}>
                <Title level={4}>Hello {authStore?.authenticatedUser.FirstName}</Title>
                <Text>Welcome Back!</Text>
              </Col> */}
              <Col xs={24} sm={24} className="Statistics">
                <Statistic
                  precision={0}
                  className="Statistics__Sales"
                  title={`Sales ${snapshotTimeframe}`}
                  value={formatter.format(
                    snapshot?.Sales ? snapshot?.Sales[snapshotTimeframe as keyof SnapshotItems] : 0
                  )}
                />
                <Statistic
                  precision={0}
                  className="Statistics__Receipts"
                  title={`Receipts ${snapshotTimeframe}`}
                  value={formatter.format(
                    snapshot?.CashReceipts
                      ? snapshot?.CashReceipts[snapshotTimeframe as keyof SnapshotItems]
                      : 0
                  )}
                />

                <Statistic
                  className="Statistics__Bookings"
                  title={`Bookings ${snapshotTimeframe}`}
                  value={
                    snapshot?.Bookings
                      ? snapshot?.Bookings[snapshotTimeframe as keyof SnapshotItems]
                      : 0
                  }
                />
                <Statistic
                  precision={0}
                  className="Statistics__Sessions"
                  title={
                    authStore?.companySettings.JobDescriptorPlural
                      ? authStore?.companySettings.JobDescriptorPlural + ` ${snapshotTimeframe}`
                      : `Sessions ${snapshotTimeframe}`
                  }
                  value={
                    snapshot?.Sessions
                      ? snapshot?.Sessions[snapshotTimeframe as keyof SnapshotItems]
                      : 0
                  }
                />
                <Segmented
                  onChange={(e: SegmentedValue) => {
                    setSnapshotTimeframe(e);
                  }}
                  defaultValue={snapshotTimeframe}
                  size="large"
                  options={["Today", "WTD", "MTD", "YTD"]}
                />
              </Col>
            </Row>
          </Card>

          <Row className="Dashboard-Layout" gutter={{ xs: 0, sm: 0, md: 10, lg: 20 }}>
            <Col className="Dashboard-Layout__Left">
              <Card
                className="Dashboard-Layout__Card Upcoming-Schedule"
                title={
                  <Col className="Dashboard-Layout__Card--Title">
                    <Title level={3}>Upcoming Schedules</Title>
                    <Text style={{ color: "white" }}>Sessions scheduled for the next 2 days</Text>
                  </Col>
                }
                actions={[
                  <Button
                    className="viewAllButton"
                    onClick={() => {
                      history.push("/schedule");
                    }}
                  >
                    View All Schedules
                  </Button>,
                ]}
              >
                <UpcomingSessions />
              </Card>
            </Col>
            <Col className="Dashboard-Layout__Right">
              <Card
                className="Dashboard-Layout__Card Home-Tasks"
                title={
                  <Col className="Dashboard-Layout__Card--Title">
                    <Title level={3}>My Tasks</Title>
                    <Text style={{ color: "white" }}>Due in the next 2 days</Text>
                  </Col>
                }
                actions={[
                  <Button
                    className="viewAllButton"
                    onClick={() => {
                      history.push("/tasks");
                    }}
                  >
                    View All Tasks
                  </Button>,
                ]}
              >
                <DashboardTaksList loading={tasksLoading} />
              </Card>
              <Row
                className="Dashboard-Layout__Right--Row"
                gutter={{ xs: 0, sm: 0, md: 10, lg: 20 }}
              >
                <Card
                  className="Dashboard-Layout__Card Home-PhoneCalls"
                  title={
                    <Col className="Dashboard-Layout__Card--Title">
                      <Title level={3}>Phone Calls</Title>
                      <Text style={{ color: "white" }}>Due in the next 2 days</Text>
                    </Col>
                  }
                  actions={[
                    <Button
                      className="viewAllButton"
                      onClick={() => {
                        history.push("/calls");
                      }}
                    >
                      View All Calls
                    </Button>,
                  ]}
                >
                  <DashboardPhoneCalls filtered loading={callsLoading} />
                </Card>
                <Card
                  className="Dashboard-Layout__Card Home-Notifications"
                  title={
                    <Col className="Dashboard-Layout__Card--Title">
                      <Title level={3}>Notifications</Title>
                      <Text style={{ color: "white" }}>
                        You have{" "}
                        <b>
                          {notificationStore?.notificationList.length}{" "}
                          {notificationStore?.notificationList.length &&
                          notificationStore?.notificationList.length > 1
                            ? "Notifications"
                            : "Notification"}
                        </b>
                      </Text>
                    </Col>
                  }
                >
                  <RecentNotifications />
                </Card>
              </Row>
            </Col>
          </Row>
        </div>
      </Content>
      <TaskDrawerForm />
      <PhoneCallDrawerForm />
      <AppointmentDrawerForm />
      <SessionDrawerForm />
      <ClientTabs />
    </>
  );
};

export default inject(
  "clientStore",
  "taskStore",
  "phoneCallStore",
  "authStore",
  "notificationStore"
)(observer(DashboardPage));
