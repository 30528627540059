import React, { useState, useEffect, useCallback } from "react";
import { inject, observer } from "mobx-react";
import dayjs, { Dayjs } from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import classNames from "classnames";
import SwiperCore, { Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import type { ScheduleStoreClass } from "stores/ScheduleStore";
import Calendar from "../util/Calendar";

import "swiper/swiper.scss";
import "./CalendarYear.scss";

interface Props {
  totalYears?: number;
  scheduleStore?: ScheduleStoreClass;
}

dayjs.extend(weekday);
dayjs.extend(localeData);
SwiperCore.use([Virtual]);

const CalendarSelectYear: React.FC<Props> = ({ scheduleStore }) => {
  const [swiperRef, setSwiperRef] = useState<SwiperCore>();

  useEffect(() => {
    if (scheduleStore?.calendarActiveSlideYearIndex) {
      swiperRef?.slideTo(scheduleStore?.calendarActiveSlideYearIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleStore?.calendarActiveSlideYearIndex]);

  const CalendarSlide = useCallback(
    ({ content }: { content: Dayjs }) => {
      let isSameDate =
        Number(scheduleStore?.calendarActiveDate.format("YYYY")) ===
        scheduleStore?.calendarActiveYear;

      return (
        <Calendar
          value={isSameDate ? scheduleStore?.calendarActiveDate : undefined}
          defaultValue={content}
          headerRender={() => <></>}
          mode="year"
          className={classNames("schedule-calendar-year", {
            "schedule-calendar-year-current": isSameDate,
          })}
          onSelect={(date: Dayjs) => {
            if (swiperRef && scheduleStore?.getCalendarSlides) {
              scheduleStore?.setCalendarActiveSlideMonthIndex(
                date
                  .startOf("month")
                  .diff(scheduleStore?.getCalendarSlides[0], "month")
              );
            }
            scheduleStore?.setCalendarMode("month");
          }}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scheduleStore?.calendarActiveSlideYearIndex, swiperRef]
  );

  return (
    <div
      className={classNames("calendar-year-container", {
        "calendar-year-container-active":
          scheduleStore?.calendarMode === "year",
      })}
    >
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        navigation
        virtual
        initialSlide={
          scheduleStore && scheduleStore?.calendarActiveSlideYearIndex
        }
        onSwiper={setSwiperRef}
        onSlideChange={(swiper) => {
          scheduleStore?.setCalendarActiveSlideYearIndex(swiper.realIndex);
        }}
      >
        {scheduleStore?.getCalendarYearSlides.map((dateJs, index) => {
          let content = dayjs(dateJs).format("YYYY");
          return (
            <SwiperSlide virtualIndex={Number(content)} key={index}>
              <CalendarSlide content={dateJs} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default inject("scheduleStore")(observer(CalendarSelectYear));
