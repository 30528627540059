import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Button, Card, Col, Empty, Row, Spin, Typography } from "antd";

import "./StatusBucket.scss";
import TaskBucket from "./TaskBucket";
import { PlusCircleFilled, ThunderboltFilled } from "@ant-design/icons";
import { TaskStoreClass } from "stores/TaskStore";

const { Title, Text } = Typography;


interface Props {
    taskStore?: TaskStoreClass;
    selectedTaskType?: string;
}

const TaskStatusBucket: React.FC<Props> = ({ taskStore, selectedTaskType }) => {

    const [taskStatusOnType, setTaskStatusOnType] = useState<any>();

    useEffect(() => {
        taskStore?.getTaskStatuses();
    }, []);

    useEffect(() => {
        taskStore?.getTasks();
    }, [taskStore]);

    const sortTaskStatusStatusOnType = async (type: any) => {
        let statusStatusOnType: any = [];
        if (taskStore?.taskStatuses) {
            for (let status of taskStore?.taskStatuses) {
                if (status.TaskTypeID == type) {
                    statusStatusOnType.push(status);
                }
            }
        }
        setTaskStatusOnType(statusStatusOnType);
    };

    useEffect(() => {
        sortTaskStatusStatusOnType(selectedTaskType);
    }, [selectedTaskType]);

    return (
        <Spin size={'default'} spinning={taskStore?.isLoading}>
            <Row className="StatusBucket" gutter={10}>
                <Col sm={24} xs={24}>
                    <Row gutter={10} className="StatusBucket__Row">
                        {taskStatusOnType ? taskStatusOnType.map((status: any) => {
                            return (
                                <Col xs={8} key={status.Key}>
                                    <Row gutter={10}>
                                        <Col sm={15} xs={24}>
                                            <Card title={<Title level={4}>{status.Description}</Title>} className="StatusBucket__Card">
                                                <TaskBucket taskStatusKey={status.Key} />
                                            </Card>
                                            <span className="connet right"></span>
                                        </Col>
                                        <Col sm={9} xs={24} className="TriggerBox">
                                            <Card className="StatusBucket__Triggers">
                                                <span className="trigger-line left"></span>
                                                <span className="trigger-line right"></span>
                                                <Text className="trigger"><ThunderboltFilled /> Change Session Date </Text>
                                                <Text className="trigger"><ThunderboltFilled /> Send Confirmation </Text>
                                                <Button icon={<PlusCircleFilled />} className="TriggerBtn">Add Trigger</Button>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        }) :
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </Row>
                </Col>
            </Row>
        </Spin>
    );

}

export default inject("taskStore")(observer(TaskStatusBucket));