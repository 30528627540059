import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Card, Affix, Grid, message, Row, Col, Spin } from "antd";
import { useHistory, useParams } from "react-router-dom";
import classnames from "classnames";

import type { PhoneCallList } from "interfaces/phonecall.int";
import type { PhoneCallStoreClass } from "stores/PhoneCallStore";
import type { LocationProps } from "interfaces/location.int";
import RenderPhoneCall from "components/calls/RenderPhoneCall";
import ClientTabs from "components/tabs/ClientTabs";
import PhoneCallDrawerForm from "components/drawers/PhoneCallDrawerForm";
import SearchInput from "components/forms/SearchInput";
import { saveLocalStorage } from "components/util/localStorage";
import ActionsPhoneCall from "components/calls/actions/ActionsPhoneCall";
import SearchDropDown from "components/search/SearchDropdown";
import { ClientStoreClass } from "stores/ClientStore";
import { UserStoreClass } from "stores/UserStore";
import SearchSelect from "components/forms/items/SearchSelect";
import { AuthStoreClass } from "stores/AuthStore";

import "./PhoneCallsPage.scss";

interface Props {
  phoneCallStore?: PhoneCallStoreClass;
  clientStore?: ClientStoreClass;
  userStore?: UserStoreClass;
  authStore?: AuthStoreClass;
}

const { useBreakpoint } = Grid;

const PhoneCallsPage: React.FC<Props> = ({ phoneCallStore, clientStore, userStore, authStore }) => {
  const [filteredList, setFilteredList] = useState<Array<PhoneCallList>>([]);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const screens = useBreakpoint();
  const history = useHistory();
  const { id, action } = useParams<LocationProps>();
  const [value, setValue] = useState<string>("");
  const [searchAutocomplete, setSearchAutocomplete] = useState<any>([]);

  const searchEntities: any = {
    PhoneCalls: [
      { value: "assignedToUser", key: "Assigned to User" },
      { value: "department", key: "Department" },
    ],
  };

  const onSearchSelect = async (value: any) => {
    let phoneCallData = { action: clientStore?.searchBy?.value, value: value.key };
    setValue(value.key);
    phoneCallStore?.setActiveSearch(phoneCallData);

    try {
      await phoneCallStore?.getPhoneCallsViaSearch();
    } catch (err) {}
  };

  useEffect(() => {
    phoneCallStore?.getDepartments();
  }, [phoneCallStore]);

  const generateSearchAutocomplete = (searchByValue: any) => {
    let options: any[] = [];
    if (searchByValue === "department") {
      options.push(
        { value: "All departments", key: "all department" },
        { value: "Unassigned", key: "un assigned" }
      );
      phoneCallStore?.departments.map((item: any) => {
        options.push({ value: item.Description, key: item.Key });
      });
      setSearchAutocomplete(options);
    } else if (searchByValue === "assignedToUser") {
      options.push({ value: "Any", key: "any" }, { value: "Unassigned", key: "unassigned" });
      userStore?.selectUserList?.map((item: any) => {
        options.push({ value: item.label, key: item.value });
      });
      setSearchAutocomplete(options);
    } else {
      setSearchAutocomplete("");
    }
  };

  useEffect(() => {
    generateSearchAutocomplete(clientStore?.searchBy.value);
    setValue("");
  }, [clientStore?.searchBy]);

  useEffect(() => {
    clientStore?.setSearchBy(searchEntities["PhoneCalls"][0]);
  }, [clientStore]);

  useEffect(() => {
    getPhoneCallList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilteredList(phoneCallStore?.sortedCallsList || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneCallStore?.sortedCallsList]);

  const getPhoneCallList = async () => {
    try {
      await phoneCallStore?.getPhoneCallList().then(() => {
        triggerDrawer();
        saveLocalStorage({
          name: "Synced",
          value: "Phone Calls",
          url: "/calls",
        });
      });
    } catch (e) {
      message.warning("Unable to establish a connection. Try logging in again.");
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchStr = event.target.value.toLowerCase();
    const filtered =
      searchStr.length > 1 && phoneCallStore?.phoneCallList
        ? phoneCallStore?.phoneCallList?.filter((v: PhoneCallList) => {
            return (
              (v.Description !== null && v.Description?.toLowerCase().indexOf(searchStr) !== -1) ||
              (v.Subject !== null && v.Subject?.toLowerCase().indexOf(searchStr) !== -1)
            );
          })
        : phoneCallStore?.sortedCallsList || [];
    setFilteredList(filtered);
  };

  const triggerDrawer = () => {
    if (action === "edit" && id) {
      phoneCallStore?.setSelectToggle(true);
      phoneCallStore?.setActivePhoneCall({
        Key: id,
      });
    }
  };

  const handleToggle = (isOpen: boolean) => {
    if (!isOpen) history.push("/calls");
  };

  return (
    <>
      <div className="ant-layout-content main-content" ref={setContainer}>
        <ActionsPhoneCall />
        <div
          className={classnames({
            "main-content-pads": screens.md,
          })}
        >
          <Card
            headStyle={{ border: "none" }}
            title={
              <>
                <Row
                  style={{ alignItems: "center" }}
                  gutter={[0, 16]}
                  align="middle"
                  justify="center"
                >
                  <Col xs={12} sm={8}>
                    <SearchDropDown
                      defaultValue={searchEntities["PhoneCalls"][0]}
                      label={"Search By"}
                      searchValues={searchEntities["PhoneCalls"]}
                      clientStore={clientStore}
                    />
                  </Col>
                  <Col xs={24} sm={16}>
                    {clientStore?.searchBy.value === "department" ||
                    clientStore?.searchBy.value === "assignedToUser" ? (
                      <SearchSelect
                        placeholder={`Please select ${
                          clientStore?.searchBy?.children
                            ? clientStore?.searchBy?.children.toLowerCase()
                            : clientStore?.searchBy?.key.toLowerCase()
                        } here to search`}
                        searchClass="search-listing"
                        searchAutocomplete={searchAutocomplete}
                        onEnter={onSearchSelect}
                        searchValue={value}
                        defaultValues={
                          clientStore?.searchBy.value === "assignedToUser"
                            ? authStore?.authenticatedUser?.Name
                            : ""
                        }
                      />
                    ) : (
                      <SearchInput onChange={handleSearch} searchClass="search-listing" />
                    )}
                  </Col>
                </Row>
              </>
            }
            bordered={false}
            className="card-listing"
          >
            <Spin spinning={phoneCallStore?.isLoading} size={"large"}>
              <Row className="CallsGrid" gutter={15}>
                {filteredList.map((call: any) => {
                  return (
                    <Col sm={24} xs={24} className="CallsGrid__Item">
                      <RenderPhoneCall item={call} />
                    </Col>
                  );
                })}
              </Row>
              {/* <List
                dataSource={filteredList}
                renderItem={(item: PhoneCallList) => <RenderPhoneCall item={item} />}
                bordered
                style={{ border: 0 }}
              /> */}
            </Spin>
          </Card>
        </div>
      </div>
      <PhoneCallDrawerForm onToggle={handleToggle} />
      <ClientTabs />
    </>
  );
};

export default inject(
  "phoneCallStore",
  "clientStore",
  "userStore",
  "authStore"
)(observer(PhoneCallsPage));
