import { List } from "antd";
import React from "react";
import "../QuickAccessPage.scss";
import { InvoiceStoreClass } from "stores/InvoiceStore";
import InputField from "components/forms/items/InputField";

interface Props {
  invoiceStore?: InvoiceStoreClass;
  invoiceArray?: any;
  setInvoiceArray?: any;
}

const QAInvoiceDetails: React.FC<Props> = ({ invoiceArray, invoiceStore, setInvoiceArray }) => {
  //item, clientArray[item].value

  return (
    <div>
      <List itemLayout="horizontal" className="client-details-list">
        {Object.keys(invoiceArray).map((item: string | number, index: any) => {
          return (
            <List.Item key={index} style={{ padding: "8px 0" }}>
              <List.Item.Meta
                title={
                  <InputField
                    // onBlur =
                    // multiline={true}
                    disabled={true}
                    prefix={invoiceArray[item]?.icon}
                    label={invoiceArray[item].label}
                    value={invoiceArray[item].value}
                    //   onChange={(e) =>
                    //     setSessionArray({
                    //       ...invoiceArray,
                    //       [item]: {
                    //         ...invoiceArray[item],
                    //         value: e.target.value,
                    //       },
                    //     })
                    //   }
                  />
                }
              />
            </List.Item>
          );
        })}
      </List>
    </div>
  );
};

export default QAInvoiceDetails;
