import { action, observable, runInAction, toJS, makeAutoObservable } from "mobx";
import { addImageToFolderApiCall, getSessionImagesApiCall, removeImageFromFolderApiCall } from "./Api";

import axios, { AxiosResponse, AxiosError } from "axios";
import ClientStore from "../ClientStore";

type tSession = {
  [key: string]: any
}

export class GalleryStoreClass {
  @observable session: tSession = {};
  @observable sessionImages: any = [];
  @observable sessionFolders: any = [];
  @observable selectedSessionKey: string = "";

  constructor() {
    makeAutoObservable(this, {
      session: observable,
      sessionImages: observable,
      sessionFolders: observable,
      setSessionImages: action,
      addImageToFolder: action,
      removeImageFromFolder: action,
      setSelectedSessionKey: action
    })
  }

  /*******    Public actions (Starts here)    ******/
  setSelectedSessionKey(sessionId: string) {
    this._resetStore();
    this.selectedSessionKey = sessionId;
  }

  getSelectedSessionKey() {
    return this.selectedSessionKey;
  }

  async fetchSessionImages() {
    // Commented this so that the images can be reloaded after upload.
    // if (!(this.session && Object.keys(this.session).length)) {
      const sessionResponse: any = await getSessionImagesApiCall(this.selectedSessionKey);
      if (sessionResponse && sessionResponse.data) {
        runInAction(() => {
          const formattedSession = this._formatSession(sessionResponse.data);
          // Once the data is fetched
          this.setSessionImages(this.selectedSessionKey, formattedSession);
        });
      }
    // }
  }

  getSession() {
    return toJS(this.session);
  }

  addImageToFolder(folderName: string, imageId: string) {
    // Make the server call
    addImageToFolderApiCall(this.selectedSessionKey, imageId, folderName);
    // Locally update
    // Update the formatted images array
    const imageIndex = this.sessionImages.findIndex((image: any) => image.fileName === imageId);
    // Add folder in image
    if (!this.sessionImages[imageIndex].folders) {
      this.sessionImages[imageIndex].folders = [];
    }
    this.sessionImages[imageIndex].folders.push(folderName);
    // Update the formatted folder array
    if (!this.sessionFolders) {
      this.sessionFolders = [];
      this.sessionFolders.push({
        unformattedData: {},
        name: folderName,
        images: [Object.assign({}, this.sessionImages[imageIndex])]
      })
    } else {
      const folderIndex = this.sessionFolders?.findIndex((folder: any) => folder.name === folderName);
      if (folderIndex < 0) {
        this.sessionFolders.push({
          unformattedData: {},
          name: folderName,
          images: [Object.assign({}, this.sessionImages[imageIndex])]
        })
      } else {
        this.sessionFolders[folderIndex].images.push(Object.assign({}, this.sessionImages[imageIndex]));
      }
    }
  }

  removeImageFromFolder(folderName: string, imageId: string) {
    // Make the server call
    removeImageFromFolderApiCall(this.selectedSessionKey, imageId, folderName);
    // Locally update
    // Update the formatted images array
    const imageIndex = this.sessionImages.findIndex((image: any) => image.fileName === imageId);
    // Remove folder from image
    if (this.sessionImages[imageIndex].folders) {
      const imageFolderIndex = this.sessionImages[imageIndex].folders.findIndex((folder: any) => folder.name === folderName);
      this.sessionImages[imageIndex].folders.splice(imageFolderIndex, 1);
      // Update the formatted folder array
      const folderIndex = this.sessionFolders.findIndex((folder: any) => folder.name === folderName);
      const folderImageIndex = this.sessionFolders[folderIndex].images.findIndex((image: any) => image.fileName === imageId);
      this.sessionFolders[folderIndex].images.splice(folderImageIndex, 1);
    }
  }


  async initImageUpload(imageCount: number, FileNames: any) {
    console.log("Image upload initiating");
    console.log("ClientStore.activeClientKey  " + ClientStore.activeClientKey);
    console.log("this.selectedSessionKey  " + this.selectedSessionKey);
    return axios({
      method: "PUT",
      url: `/ibySessionImageUpload`,
      data: {
        Action: "Start",
        imageCount: imageCount,
        FileNames: FileNames,
        SessionKey: this.selectedSessionKey,
        ClientKey: ClientStore.activeClientKey,
      },
    })
      .then((res: AxiosResponse) => {
        // console.log(res.data);
        // this.putSessionImageUploadBlobStorage(res.data, payload.Images);
        return res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async putSessionImageUploadBlobStorage(uploadURL: string, images: any, imagesLength: any) {
    console.log("Sending image to Blob storage");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "image/jpeg",
        "x-ms-blob-type": "BlockBlob",
        "Content-Length": imagesLength,
      },
      body: images,
    };

    return (
      fetch(uploadURL, requestOptions)
        .then((response) => console.log("blobResponse", response))
        //.then((data) => console.log("blobResponse2", data))
        .catch((e) => {
          console.log(e);
        })
    );
  }

  completeImageUpload(uploadJobId: any) {
    console.log("Completing Image upload ");
    return axios({
      method: "PUT",
      url: `/ibySessionImageUpload`,
      data: {
        Action: "Complete",
        UploadJobId: uploadJobId,
        SessionKey: this.selectedSessionKey,
        ClientKey: ClientStore.activeClientKey,
      },
    })
      .then((res: AxiosResponse) => {
        console.log("CompletedResponse", res);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  /*******    Public actions (Ends here)     ******/




  /*******    Private actions  (Starts here)   ******/
  setSessionImages(sessionId: string, session: any) {
    this.sessionFolders = session.formattedFolders;
    this.sessionImages = session.formattedImages;
    this.session = session;
  }

  _resetStore() {
    this.session = {};
    this.sessionFolders = [];
    this.sessionImages = [];
  }
  /*******    Private actions  (Ends here)   ******/



  /*******    Private helper methods (Starts here)    ******/
  /**
   * Format session response according to store object structure
   */
  _formatSession = (sessionResponse: any) => {
    const formattedSession: any = {
      ...sessionResponse
    };
    // Format images
    if (sessionResponse?.Images?.Images?.length) {
      formattedSession.formattedImages = sessionResponse.Images.Images.map((image: any) => ({
        unformattedData: image,
        thumbnailPath: `${sessionResponse.Images.ViewerUrl}${image.ThumbQueryString}`,
        previewPath: `${sessionResponse.Images.ViewerUrl}${image.PreviewQueryString}`,
        fileName: image.FileName,
        key: image.Key
      }))
    }
    // Format folders
    if (sessionResponse?.Groups?.length) {
      formattedSession.formattedFolders = sessionResponse.Groups.map((group: any) => ({
        unformattedData: group,
        name: group.GroupName,
        images: formattedSession.formattedImages.filter((image: any, imageIndex: number) => {
          // Link actual image objects in the folder 
          if (group.FileNames?.length) {
            if (group.FileNames.indexOf(image.fileName) > -1) {
              if (!formattedSession.formattedImages[imageIndex].folders) {
                formattedSession.formattedImages[imageIndex].folders = [];
              }
              // Update the original image object also
              formattedSession.formattedImages[imageIndex].folders.push(group.GroupName);
              return true;
            }
          }
        })
      }))
    }
    return formattedSession;
  }
  /*******    Private helper methods (Ends here)     ******/
}

const GalleryStore = new GalleryStoreClass();
export default GalleryStore;