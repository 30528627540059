import React from "react";
import { Card, Grid, Row, Col, Layout } from "antd";
import classnames from "classnames";
import ActionsMessages from "components/messages/actions/ActionsMessages";
import MessagesList from "components/lists/MessagesList";
import { inject, observer } from "mobx-react";
import { MessageStoreClass } from "stores/MessageStore";
import { AuthStoreClass } from "stores/AuthStore";
import { ClientStoreClass } from "stores/ClientStore";
// import "./MessagesPage.scss";
import { UserStoreClass } from "stores/UserStore";
import PreferencesContent from "./PreferencesContent";

const { useBreakpoint } = Grid;
const { Content } = Layout;

interface Props {
  messageStore: MessageStoreClass;
  authStore: AuthStoreClass;
  clientStore?: ClientStoreClass;
  userStore?: UserStoreClass;
}

const PreferencesPage: React.FC<Props> = ({ messageStore, authStore, clientStore, userStore }) => {
  const screens = useBreakpoint();
  return (
    <div className="ant-layout-content main-content">
      <div
        className={classnames({
          "main-content-pads": screens.md,
        })}
      >
        <Card bordered={screens.md} style={{ backgroundColor: "#F6F6F6" }} className="card-listing">
          <PreferencesContent bordered style={{ border: 0 }} />
        </Card>
      </div>
    </div>
  );
};

export default inject(
  "messageStore",
  "authStore",
  "clientStore",
  "userStore"
)(observer(PreferencesPage));
