import React from 'react';
import { Gauge } from '@ant-design/plots';

interface Props {
    chartData?: any;
}

const GuageChart: React.FC<Props> = ({ chartData }) => {
    const config = {
        percent: chartData.GaugeAcutalValue / 100,
        range: {
            ticks: [0, 1 / 3, 2 / 3, 1],
            color: ['#F4664A', '#FAAD14', '#30BF78'],
        },
        indicator: {
            pointer: {
                style: {
                    stroke: '#D0D0D0',
                },
            },
            pin: {
                style: {
                    stroke: '#D0D0D0',
                },
            },
        },
        statistic: {
            content: {
                style: {
                    fontSize: '14px',
                    lineHeight: '14px',
                },
            },
        },
    };

    return (
        <Gauge width={100} height={100} autoFit={true} {...config} />
    )
};

export default GuageChart;
