import { CloseOutlined } from "@ant-design/icons";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import "./cart-promo-applier.scss";

const CartPromoApplier = (props: any) => {
  const [promoTextInput, setPromoTextInput] = useState("");
  const [showPromoError, setShowPromoError] = useState(false);

  const _clearPromo = (code: string) => {
    props?.cartStore?.removePromoCode(code);
  };

  const _addPromo = async () => {
    try {
      await props.cartStore.addPromoCode(promoTextInput.trim());
    } catch (error) {
      setShowPromoError(true);
    }
  };

  return (
    <div className="promoCodeWrapper">
      {props.cartStore.cart.Promotions?.length ? (
        <div className="promoApplied">
          <div className="promoCodes">
            {props.cartStore.cart.Promotions.map((code: any, codeIndex: number) => {
              return (
                <div
                  className="promoCode"
                  onClick={(e) => _clearPromo(code.PromoCode)}
                  key={codeIndex}
                >
                  {code.Description}
                  <button className="closeBtn">
                    <CloseOutlined />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
      <>
        <div className="enterPromo">
          <p className="promoLabel">Have a promo/coupon code?</p>
          <div className="promoBox">
            <input
              type="text"
              value={promoTextInput}
              onChange={(e) => setPromoTextInput(e.target.value)}
            />
            {promoTextInput.trim().length ? (
              <button className="applyBtn" onClick={_addPromo}>
                Apply
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
        {showPromoError ? <div className="promoError">Not a valid promo</div> : <></>}
      </>
    </div>
  );
};

export default inject("cartStore")(observer(CartPromoApplier));
