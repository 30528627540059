import update from "immutability-helper";
import dayjs from "dayjs";

import type { ScheduleList } from "interfaces/schedule.int";
import useTimesInADay, {
  TimeObj,
  TimeObjExtra,
} from "components/util/useTimesInADay";

export interface MatrixProps {
  data: TimeObj[];
  columns: number;
}

const useTimeMatrix = (availableInDay: ScheduleList[]): MatrixProps => {
  let matrix = useTimesInADay() || [];
  let columns = 1;

  availableInDay.forEach((item, itemIndex) => {
    let startTime =
      Number(dayjs(item.StartDateTime).format("H")) +
      Number(dayjs(item.StartDateTime).format("mm")) / 60;
    let endTime =
      Number(dayjs(item.EndDateTime).format("H")) +
      Number(dayjs(item.EndDateTime).format("mm")) / 60;
    let diff = endTime - startTime;
    let key = item?.AppointmentKey || item?.SessionKey;
    let columnSource: number = 0;
    let Color: number = Number(item?.Color);

    for (let i = 0; i < diff; i++) {
      let currentTime: number = Math.floor(startTime + i);
      let extraData: TimeObjExtra = {
        isOccupied: true,
        startTime,
        endTime,
        Color
      };

      if (i === 0) {
        let currentData =
          matrix[currentTime]?.data?.sort(
            (a: TimeObjExtra, b: TimeObjExtra) => {
              let aCol = a.columnSource || 0;
              let bCol = b.columnSource || 0;
              if (aCol > bCol) return 1;
              if (aCol < bCol) return -1;
              return 0;
            }
          ) || [];

        if (itemIndex > 0 && currentData.length > 0) {
          for (let ii = 0; ii < currentData.length; ii++) {
            let currentColSource = currentData[ii].columnSource || 0;
            if (currentColSource > ii) {
              columnSource = ii;
              break;
            }

            if (ii + 1 === currentData.length) {
              columnSource = ii + 1;

              if (columnSource >= columns) {
                columns = columnSource + 1;
              }
            }
          }
        } else {
          columnSource = 0;
        }

        extraData = {
          ...extraData,
          description: item?.Description || "",
          type: item?.AppointmentKey !== null ? "appointment" : "session",
          key,
          rowCount: diff,
          originData: item,
          columnSource,
        };
      } else {
        extraData = {
          ...extraData,
          columnSource,
        };
      }

      matrix = update(matrix, {
        [currentTime]: {
          $apply: function (obj) {
            return obj.hasOwnProperty("data")
              ? update(obj, {
                data: {
                  $push: [extraData],
                },
              })
              : update(obj, {
                $set: { ...obj, data: [extraData] },
              });
          },
        },
      });
    }
  });

  return {
    data: matrix || [],
    columns,
  };
};

export default useTimeMatrix;
