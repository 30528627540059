import React from "react";
import { Input, Table, Form, Select, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
const { Option } = Select;

interface Props {
  form: FormInstance;
}

interface PhoneRecord {
  key: string;
  phoneType: string;
  number: string;
  isSMSTextable: boolean;
}

const PhoneNumberTable: React.FC<Props> = ({ form }) => {
  const columns = [
    {
      title: "Phone Type",
      dataIndex: "phoneType",
      key: "phoneType",
      render: (phoneType: string, phoneRecord: PhoneRecord) => {
        if (phoneType === "Main Phone") {
          return "Main Phone";
        }
        return (
          <Form.Item name={`PhoneDescription${phoneRecord.key}`}>
            <Select defaultValue={phoneType}>
              <Option value="CellPhone">Cell Phone</Option>
              <Option value="WorkPhone">Work Phone</Option>
              <Option value="HomePhone">Home Phone</Option>
              <Option value="Fax">Fax</Option>
              <Option value="Other">Other</Option>
            </Select>
          </Form.Item>
        );
      },
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      render: (phoneNumber: string, phoneRecord: PhoneRecord) => {
        return (
          <Form.Item label="Number" name={`PhoneNumber${phoneRecord.key}`}>
            <Input defaultValue={phoneNumber} />
          </Form.Item>
        );
      },
    },
    {
      title: "SMS",
      dataIndex: "isSMSTextable",
      key: "SMS",
      render: (isSMSTextable: boolean, phoneRecord: PhoneRecord) => {
        return (
          <Form.Item name={`SMSTextable${phoneRecord.key}`} valuePropName="checked">
            <Switch />
          </Form.Item>
        );
      },
    },
  ];

  const data = [
    {
      key: "0",
      phoneType: "Main Phone",
      number: form.getFieldValue("PhoneNumber0"),
      isSMSTextable: form.getFieldValue("SMSTextable0"),
    },
    {
      key: "1",
      phoneType: form.getFieldValue("PhoneDescription1"),
      number: form.getFieldValue("PhoneNumber1"),
      isSMSTextable: form.getFieldValue("SMSTextable1"),
    },
    {
      key: "2",
      phoneType: form.getFieldValue("PhoneDescription2"),
      number: form.getFieldValue("PhoneNumber2"),
      isSMSTextable: form.getFieldValue("SMSTextable2"),
    },
    {
      key: "3",
      phoneType: form.getFieldValue("PhoneDescription3"),
      number: form.getFieldValue("PhoneNumber3"),
      isSMSTextable: form.getFieldValue("SMSTextable3"),
    },
    {
      key: "4",
      phoneType: form.getFieldValue("PhoneDescription4"),
      number: form.getFieldValue("PhoneNumber4"),
      isSMSTextable: form.getFieldValue("SMSTextable4"),
    },
    {
      key: "5",
      phoneType: form.getFieldValue("PhoneDescription5"),
      number: form.getFieldValue("PhoneNumber5"),
      isSMSTextable: form.getFieldValue("SMSTextable5"),
    },
  ];
  return <Table pagination={false} columns={columns} dataSource={data} className="phoneNumberTable" />;
};

export default PhoneNumberTable;
