import { Affix, Col, Row } from "antd";
import SearchInput from "components/forms/SearchInput";
import SearchDropDown from "components/search/SearchDropdown";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ClientStoreClass } from "stores/ClientStore";

interface Props {
  clientStore: ClientStoreClass;
  setFlag?: any;
  setToggleActionItems?: any;
  setToggleSessionActionItems?: any;
  setToggleInvoiceActionItems?: any;
  setToggleAppointmentActionItems?: any;
}

const QuickAccessSearchComponent: React.FC<Props> = ({
  clientStore,
  setFlag,
  setToggleActionItems,
  setToggleSessionActionItems,
  setToggleInvoiceActionItems,
  setToggleAppointmentActionItems,
}) => {
  const [container] = useState<HTMLDivElement | null>(null);
  const [value, setValue] = useState<string>("");

  let timeout: ReturnType<typeof setTimeout>;

  const searchEntities: any = {
    Clients: [
      { value: "lastName", key: "Last Name" },
      { value: "firstName", key: "First Name" },
      { value: "companyName", key: "Company Name" },
      { value: "email", key: "Email" },
      { value: "phoneNumber", key: "Phone Number" },
    ],
  };

  useEffect(() => {
    clientStore?.setSearchBy({ children: "Last Name", value: "lastName" });
    clientStore?.updateSearchType("Clients");
  }, []);

  const onKey = (e: { keyCode: number }) => {
    setFlag(true);
    clearTimeout(timeout);
    if (e.keyCode === 13) {
      // Prevents loading again for enter key
      return;
    }

    timeout = setTimeout(async () => {
      if (value.length >= 3)
        try {
          clientStore?.updateSearchTerm(value);
          await clientStore?.getClients();
        } catch (err) {}
      else
        try {
          clientStore?.updateSearchTerm("");
        } catch (err) {}
    }, 500);
  };

  const onEnter = async () => {
    setFlag(true);
    setToggleActionItems(false);
    setToggleSessionActionItems(false);
    setToggleInvoiceActionItems(false);
    setToggleAppointmentActionItems(false);
    clientStore?.updateSearchTerm(value);
    try {
      await clientStore?.getClients();
    } catch (error) {
      clearTimeout(timeout);
    }
  };

  const handleChange = async (value: any) => {
    setValue(value);
    if (value == "") {
      await clientStore?.updateSearchTerm(value);
    }
  };

  return (
    <div className="quick-search-panel">
      <Row gutter={[16, 16]} className="quick-search-panel-block">
        <Col xs={8} sm={12}>
          <SearchDropDown
            defaultValue={toJS(searchEntities[`Clients`][0])}
            label={"Search By"}
            searchValues={searchEntities[`Clients`]}
            clientStore={clientStore}
          />
        </Col>

        <Col xs={16} sm={12}>
          <Affix target={() => container} offsetTop={70}>
            <SearchInput
              placeholder={`Please type client's ${clientStore?.searchBy?.children?.toLowerCase()} here to search`}
              searchClass="search-listing"
              onKeyUp={(e: any) => {
                onKey(e);
              }}
              onChange={(e) => {
                handleChange(e.target.value);
                clearTimeout(timeout);
              }}
              onPressEnter={() => {
                onEnter();
                clearTimeout(timeout);
              }}
              value={value}
            />
          </Affix>
        </Col>
      </Row>
    </div>
  );
};

export default inject("clientStore")(observer(QuickAccessSearchComponent));
