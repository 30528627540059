//@ts-nocheck
import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { inject, observer } from "mobx-react";
import { List, Typography, Row, Button, ConfigProvider, Col } from "antd";
import dayjs from "dayjs";
import { SessionStoreClass } from "stores/SessionStore";
import { ScheduleStoreClass } from "stores/ScheduleStore";
import { CameraOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { InvoiceStoreClass } from "stores/InvoiceStore";
import type { AuthStoreClass } from "stores/AuthStore";
import ProTable from "@ant-design/pro-table";
import InvoiceDrawerView from "components/drawers/InvoiceDrawerView/InvoiceDrawerView";
import enUS from "antd/lib/locale/en_US";
interface Props {
  sessionStore?: SessionStoreClass;
  scheduleStore?: ScheduleStoreClass;
  invoiceStore?: InvoiceStoreClass;
  customHandleClick?: any;
  setToggleActionItems?: any;
  viewSection?: any;
  authStore?: AuthStoreClass;
  setSessionKey?: Dispatch<SetStateAction<string>>;
}

const SearchSessionsList: React.FC<Props> = ({
  sessionStore,
  scheduleStore,
  invoiceStore,
  customHandleClick,
  setToggleActionItems,
  viewSection,
  authStore,
  setSessionKey,
}) => {
  const { Text } = Typography;

  let [defaultKey, setDefaultKey] = useState<any>("1");

  const [upcomingView, setUpcomingView] = useState("Graphical");

  const NotAvailable = () => {
    return <Text disabled>Not Available</Text>;
  };

  const sessionDetails = (field: any) => {
    if (!field) return <NotAvailable />;
    else {
      return <Text strong>{field}</Text>;
    }
  };

  let handleClick = async (item: any) => {
    sessionStore?.getSessionDetail(item.Key);
    if (setSessionKey) {
      setSessionKey(item.Key);
    }
    scheduleStore?.setActiveSchedule({ SessionKey: item.Key });
    sessionStore?.setSelectToggle(true);
  };

  let handleSessionClick = async (item: any) => {
    const session = await sessionStore?.getSessionDetail(item.Key);
    await sessionStore?.getSessionDetail(item.Key);
    invoiceStore?.setActiveInvoice(session.SessionInvoice);
    scheduleStore?.setActiveSchedule({ SessionKey: item.Key });
    setToggleActionItems(true);
  };
  let filteredUpcomingSessions = sessionStore?.sessionList
    ?.filter((session) => {
      let givenDate = dayjs(session?.StartDate).format("YYYY-MM-DD");
      return new Date(givenDate) >= new Date();
    })
    .sort((a: any, b: any) => {
      return dayjs(a.StartDate).diff(dayjs(b.StartDate));
    });

  if (customHandleClick) handleClick = handleSessionClick;

  useEffect(() => {
    if (
      viewSection ===
        (authStore?.companySettings.JobDescriptorPlural
          ? authStore?.companySettings.JobDescriptorPlural
          : "Sessions") &&
      (defaultKey === undefined || defaultKey === "1")
    ) {
      filteredUpcomingSessions?.length ? setDefaultKey("1") : setDefaultKey("2");
    } else if (
      viewSection !==
      (authStore?.companySettings.JobDescriptorPlural
        ? authStore?.companySettings.JobDescriptorPlural
        : "Sessions")
    ) {
    } else setDefaultKey("2");
  }, [viewSection]);

  const handleUpcomingView = () => {
    if (upcomingView === "Graphical") {
      setUpcomingView("Grid");
    } else {
      setUpcomingView("Graphical");
    }
  };

  const sessionColumns = [
    {
      title: `${
        authStore?.companySettings?.JobDescriptorSingular
          ? authStore?.companySettings?.JobDescriptorSingular
          : "Session"
      } Number`,
      dataIndex: "SessionNumber",
      render: (number: any) => {
        return sessionDetails(number);
      },
      sorter: (a: any, b: any) => parseInt(a.SessionNumber) - parseInt(b.SessionNumber),
    },
    {
      title: "Description",
      dataIndex: "Description",
      render: (description: any) => {
        return sessionDetails(description);
      },
    },
    {
      title: "Start Date",
      dataIndex: "StartDate",
      render: (startDate: any) => {
        return dayjs(startDate).format("MM/DD/YYYY");
      },
      sorter: (a: any, b: any) => {
        return new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime();
      },
    },
    {
      title: `${
        authStore?.companySettings.JobDescriptorSingular
          ? authStore?.companySettings.JobDescriptorSingular
          : "Session"
      } Status`,
      dataIndex: "SessionStatus",
    },
    {
      title: "Photographer Name",
      dataIndex: "PhotographerName",
      render: (name: any) => {
        return sessionDetails(name);
      },
    },
    {
      title: "Location",
      dataIndex: "Location",
      render: (location: any) => {
        return sessionDetails(location);
      },
    },
  ];

  return (
    <>
      <Row
        style={{ justifyContent: "flex-end", paddingBottom: 20, paddingLeft: 20, paddingRight: 20 }}
      >
        <Button
          onClick={() => {
            handleUpcomingView();
          }}
        >
          {upcomingView === "Graphical" ? "Grid " : "Graphical "} View
        </Button>
      </Row>
      {upcomingView === "Graphical" ? (
        <List
          dataSource={sessionStore?.sessionList}
          pagination={{
            pageSize: 20,
            showTotal(total, range) {
              return `${range[0]} - ${range[1]} of ${total} items`;
            },
          }}
          renderItem={(item: any) => {
            return (
              <List.Item onClick={() => handleClick(item)} key={item.Key}>
                <div className="quickAccessList">
                  <div className="quickAccessList__Date">
                    <label>
                      {/* <span className="quickAccessList__Date--Icon"></span> */}
                      <span className="quickAccessList__Date--Day">
                        {/* <CalendarOutlined /> */}
                        {dayjs(item.StartDate).format("DD")}
                      </span>
                      <span className="quickAccessList__Date--Year">
                        {dayjs(item.StartDate).format("MMM YYYY")}
                      </span>
                    </label>
                  </div>
                  <div className="quickAccessList__Content">
                    <div className="quickAccessList__Content--Head">
                      <Text className="sessionStatus" mark>
                        {item.SessionStatus}
                      </Text>
                      {/* <div>
                        <label>
                          <CameraOutlined />
                          {sessionDetails(item.PhotographerName)}
                        </label>
                        <label>
                          <EnvironmentOutlined />
                          {sessionDetails(item.Location)}
                        </label>
                      </div> */}
                    </div>
                    <div className="quickAccessList__Content--Body">
                      <div>
                        <p>{item.Description}</p>

                        <label>
                          <b>
                            {authStore?.companySettings.JobDescriptorSingular
                              ? authStore?.companySettings.JobDescriptorSingular + "  No"
                              : "Session No"}
                            :{" "}
                          </b>
                          {item.SessionNumber}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </List.Item>
            );
          }}
          bordered
          style={{ border: 0, backgroundColor: "#fff" }}
        ></List>
      ) : (
        <Col style={{ paddingLeft: 20, paddingRight: 20 }}>
          <ConfigProvider locale={enUS}>
            <ProTable
              columns={sessionColumns}
              className="pro-table"
              search={false}
              dataSource={sessionStore?.sessionList}
              pagination={{
                pageSize: 20,
                showTotal(total, range) {
                  return `${range[0]} - ${range[1]} of ${total} items`;
                },
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    handleClick(record);
                  },
                };
              }}
            />
          </ConfigProvider>
        </Col>
      )}
      <InvoiceDrawerView />
      {/* <SessionDrawerForm sessionKey={sessionKey} /> */}
    </>
  );
};

export default inject(
  "sessionStore",
  "scheduleStore",
  "invoiceStore",
  "authStore"
)(observer(SearchSessionsList));
