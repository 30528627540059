import { observable, action, computed, makeObservable } from "mobx";
import { create, persist } from "mobx-persist";
import axios, { AxiosResponse, AxiosError } from "axios";

import type { User, UserList } from "interfaces/user.int";

export class UserStoreClass {
  @persist("list") usersList: Array<UserList> = [];
  @persist("list") userListByDepartment: Array<UserList> = [];
  @persist("object") userInfo: User | undefined;
  isLoading: boolean = false;

  constructor() {
    makeObservable(this, {
      userInfo: observable,
      usersList: observable,
      userListByDepartment: observable,
      getUserInfo: action,
      getUsersList: action,
      getUserDataFromList: action,
      selectUserList: computed,
      selectUserListByDepartment: computed,
      selectPhotographerList: computed,
      selectAssistantList: computed,
      isLoading: observable,
      cleanUp: action,
    });
  }

  async getUserInfo(id?: string) {
    var key = id;
    if (!id) key = this.userInfo?.Key;

    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/User/${key}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.userInfo = res.data;
          this.isLoading = false;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  async getUsersList() {
    this.isLoading = true;
    const webApiUser = {
      Key: "00-000-99999",
      Name: "Web API",
      ShowOnCalendar: false,
      Photographer: false,
      Assistant: false,
      SalesRep: false,
      Appointments: false,
      Tasks: false,
      Emails: false,
      Calls: false,
    };
    return await axios({
      method: "GET",
      url: "/UserList",
    })
      .then(
        action((res: AxiosResponse) => {
          this.usersList = res.data;
          this.usersList = [...this.usersList, webApiUser];
          this.isLoading = false;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  get selectUserList() {
    return this.usersList
      .map((v: UserList) => {
        return {
          label: v.Name,
          value: v.Key,
          ShowOnCalendar: v.ShowOnCalendar,
        };
      })
      .sort((a, b) => {
        var nameA = a.label.toUpperCase();
        var nameB = b.label.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  }

  get selectUserListByDepartment() {
    return this.userListByDepartment
      .map((v: UserList) => {
        return {
          label: v.Name,
          value: v.Key,
        };
      })
      .sort((a, b) => {
        var nameA = a.label.toUpperCase();
        var nameB = b.label.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  }
  get selectPhotographerList() {
    return this.usersList
      .filter((v: UserList) => v.Photographer === true)
      .map((v: UserList) => {
        return {
          label: v.Name,
          value: v.Key,
        };
      })
      .sort((a, b) => {
        var nameA = a.label.toUpperCase();
        var nameB = b.label.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  }

  get selectAssistantList() {
    return this.usersList
      .filter((v: UserList) => v.Assistant === true)
      .map((v: UserList) => {
        return {
          label: v.Name,
          value: v.Key,
        };
      })
      .sort((a, b) => {
        var nameA = a.label.toUpperCase();
        var nameB = b.label.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  }

  getUserDataFromList(userKey: string, field?: string): UserList | string {
    let filtered: any = this.usersList?.filter((v: UserList) => userKey === v.Key)[0];
    let mapped = field ? filtered[field] : filtered;

    return mapped;
  }
  async getUsersListByDepartment(DepartmentKey: string | undefined, userSearchType: string) {
    this.isLoading = true;
    const webApiUser = {
      Key: "00-000-99999",
      Name: "Web API",
      ShowOnCalendar: false,
      Photographer: false,
      Assistant: false,
      SalesRep: false,
      Appointments: false,
      Tasks: false,
      Emails: false,
      Calls: false,
    };
    return await axios({
      method: "GET",
      url: `/userList?DepartmentId=${DepartmentKey}&userSearchType=${userSearchType}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.userListByDepartment = res.data;
          this.userListByDepartment = [...this.userListByDepartment, webApiUser];
          this.isLoading = false;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  cleanUp() {
    this.usersList = [];
  }
}

const hydrate = create({});

const UserStore = new UserStoreClass();

export default UserStore;

hydrate("user", UserStore).then(() => {});
