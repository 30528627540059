import React, { useState, useEffect } from "react";
import "./session-cart-drawer.scss";
import { Drawer } from "antd";
import EventEmitter from "utils/event-emitter";
import CartSummaryViewer from "components/cart-summary-viewer/cart-summary-viewer";
import { CloseOutlined } from "@ant-design/icons";
import ProductBrowser from "components/product-browser/product-browser";
import { inject, observer } from "mobx-react";
import CartLoader from "components/cart-loader/cart-loader";
import { SessionStoreClass } from "stores/SessionStore";

export interface SessionCartDrawerProps {
  sessionStore?: SessionStoreClass;
}

const SessionCartDrawer: React.FC<SessionCartDrawerProps> = (props: any) => {
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeView, setActiveView] = useState("product-browser");

  const _registerEventListners = () => {
    EventEmitter.listen("show-cart", () => {
      // Reset active view
      setActiveView("cart-summary");
      setIsCartVisible(true);
    });
    EventEmitter.listen("hide-cart", () => {
      setIsCartVisible(false);
    });
    EventEmitter.listen("add-to-cart", (image: any) => {
      // setIsCartVisible(false);
      setSelectedImage(image);
      setActiveView("product-browser");
      setIsCartVisible(true);
    });
  };

  const _determineDrawerWidth = () => {
    const { innerHeight, innerWidth } = window;
    if (innerHeight > innerWidth) {
      return "100vw";
    } else if (innerHeight <= 500 && innerWidth >= 500) {
      return "100vw";
    } else {
      return "50vw";
    }
  };

  const _renderActiveView = () => {
    if (isCartVisible) {
      switch (activeView) {
        case "cart-summary": {
          return <CartSummaryViewer switchToProductBrowser={_switchToProductBrowser} />;
        }
        case "product-browser": {
          return (
            <ProductBrowser
              switchToCartSummary={_switchToCartSummary}
              selectedImage={selectedImage}
            />
          );
        }
      }
    } else {
      return <></>;
    }
  };

  const _switchToProductBrowser = () => {
    setActiveView("product-browser");
  };

  const _switchToCartSummary = () => {
    setActiveView("cart-summary");
  };

  const _loadImages = async () => {
    // await props.galleryStore.fetchSessionImages("00-000-10006");
  };

  const _loadCartMetadata = () => {
    props.cartStore.fetchCartMetaData(props.sessionStore?.currentSessionDetail.PriceListKey);
  };

  useEffect(() => {
    _registerEventListners();
    _loadImages();
    _loadCartMetadata();
  }, []);

  return (
    <>
      <Drawer
        title="Basic Drawer"
        placement="right"
        closable={false}
        onClose={() => setIsCartVisible(false)}
        visible={isCartVisible}
        headerStyle={{ display: "none" }}
        width={_determineDrawerWidth()}
      >
        <div className="cartDrawerContentWrapper">
          <button className="closeBtn" onClick={(e) => setIsCartVisible(false)}>
            <CloseOutlined />
          </button>
          {_renderActiveView()}

          <CartLoader />
        </div>
      </Drawer>
    </>
  );
};

export default inject("galleryStore", "cartStore", "sessionStore")(observer(SessionCartDrawer));
