import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Button, Modal, Form, Row, Col, Grid, Popover, Menu } from "antd";
import { FilterOutlined, PlusOutlined, MoreOutlined } from "@ant-design/icons";

import type { TaskStoreClass } from "stores/TaskStore";
import FilterTask from "components/forms/FilterTask";
import CustomHeader from "components/header/CustomHeader";

interface Props {
  taskStore?: TaskStoreClass;
  onShowDrawer?: any;
}
const { useBreakpoint } = Grid;

const ActionsTasks: React.FC<Props> = ({ taskStore, onShowDrawer }: Props): React.ReactElement => {
  const [isShowFilterModal, setIsShowFilterModal] = useState<boolean>(false);
  const [filterForm] = Form.useForm();
  const screens = useBreakpoint();

  const toggleFilterModal = () => {
    setIsShowFilterModal(!isShowFilterModal);
  };

  const handleSubmitFilter = () => {
    filterForm.submit();
    setIsShowFilterModal(false);
  };

  const handleClick = () => {
    taskStore?.setSelectToggle(true);
  };

  const moreNavigation = (
    <Menu className="navigation-popover">
      <Menu.Item key="filter" onClick={toggleFilterModal} icon={<FilterOutlined />}>
        Filter
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <CustomHeader title="Tasks">
        <Col style={{ marginLeft: "auto" }}>
          <Row align="middle">
            <Col style={{ paddingRight: screens.lg ? "" : 5 }}>
              <Button
                disabled={taskStore?.isLoading}
                style={{ backgroundColor: "#708D65", borderColor: "white", color: "white" }}
                className={screens.lg ? "btn-action" : "no-label"}
                shape="round"
                onClick={handleClick}
              >
                New
              </Button>
            </Col>
            {!screens.lg && (
              <Popover
                content={moreNavigation}
                trigger="focus"
                placement="bottomRight"
                overlayClassName="navigation-popover-container"
              >
                <Button
                  icon={<MoreOutlined />}
                  type="text"
                  disabled={taskStore?.isLoading}
                  className="no-label"
                />
              </Popover>
            )}
          </Row>
        </Col>
      </CustomHeader>

      <Modal
        title="Filter by:"
        visible={isShowFilterModal}
        onCancel={toggleFilterModal}
        onOk={handleSubmitFilter}
      >
        <FilterTask form={filterForm} />
      </Modal>
    </>
  );
};
export default inject("taskStore")(observer(ActionsTasks));
