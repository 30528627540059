import React from "react";
import { inject, observer } from "mobx-react";
import dayjs from "dayjs";

import type { NotificationList } from "interfaces/notification.int";
import type { NotificationStoreClass } from "stores/NotificationStore";

import { Empty, Spin, List, Col, Typography, message } from "antd";

import { DeleteOutlined } from "@ant-design/icons";

interface Props {
  notificationStore?: NotificationStoreClass;
}

const { Title, Text } = Typography;

const RecentNotifications: React.FC<Props> = ({ notificationStore, ...props }) => {
  let filteredNotifications: Array<NotificationList> = [];
  notificationStore?.sortNotificationList.map((notification: any) => {
    if (
      dayjs(new Date()) >= dayjs(notification.VisibleStartDateTime) &&
      (!notification.VisibleEndDateTime ||
        dayjs(new Date()) <= dayjs(notification.VisibleEndDateTime))
    ) {
      filteredNotifications.push(notification);
    }
  });

  const handleDelete = async (Key: string) => {
    await notificationStore?.deleteNotification(Key).then((data: any) => {
      message.success("Notofication Removed");
    });
  };

  return (
    <>
      {notificationStore?.isLoading ? (
        <div className="dash-spinner">
          <Spin />
        </div>
      ) : filteredNotifications.length > 0 ? (
        <List
          dataSource={filteredNotifications}
          loading={notificationStore?.isLoading}
          renderItem={(item: NotificationList) => (
            <>
              <Col className="Notification">
                <Col className="Notification__Detail">
                  <Title level={3}>
                    {item.Header}
                    <span style={{ float: "right" }}>
                      <DeleteOutlined
                        style={{ color: "#8E3B46" }}
                        onClick={() => {
                          handleDelete(item.Key);
                        }}
                      />
                    </span>
                  </Title>
                  <Text>{item.Body}</Text>
                  <Text>{item.UrlText && <>- {item.UrlText}</>} </Text>
                </Col>
              </Col>
            </>
          )}
          bordered
          style={{ border: 0 }}
        />
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"You have no recent notifications. "}
        />
      )}
    </>
  );
};

export default inject("notificationStore")(observer(RecentNotifications));
