import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { AlignType } from "rc-table/lib/interface";
import {
  Descriptions,
  Row,
  Col,
  Card,
  Table,
  Divider,
  InputNumber,
  Form,
  Select,
  Modal,
  Menu,
  Button,
  Tabs,
  Input,
  Radio,
  message,
  Typography,
} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import type { InvoiceStoreClass } from "stores/InvoiceStore";
import InputField from "components/forms/items/InputField";
import { EllipsisOutlined, CheckOutlined } from "@ant-design/icons";
import SelectField from "components/forms/items/SelectField";
import "./InvoiceDrawerView.scss";
import { InvoiceDetails } from "interfaces/invoice.int";
import PaymentModal from "./PaymentModal";
import ClientRow from "components/rows/ClientRow";

const { SubMenu } = Menu;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Title } = Typography;
interface Props {
  invoiceStore?: InvoiceStoreClass;
}

dayjs.extend(customParseFormat);

const OrderDetails: React.FC<Props> = ({ invoiceStore }) => {
  const [form] = Form.useForm();
  const [showPriceListModal, setShowPriceListModal] = useState(false);
  const [showInvoiceLineItemDetail, setShowInvoiceLineItemDetail] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [invoiceLineItemDetail, setInvoiceLineItemDetail] = useState<InvoiceDetails>();
  const [tab, setTab] = useState("1");
  const [discountType, setDiscountType] = React.useState("Dollar");
  useEffect(() => {
    if (invoiceLineItemDetail?.Key) {
      const initFormValues: any = {
        ImageNumber: invoiceLineItemDetail.ImageNumber,
        Description: invoiceLineItemDetail.Description,
        ItemIdentifier: invoiceLineItemDetail.ItemIdentifier,
        Quantity: invoiceLineItemDetail.Quantity,
        ItemPrice: invoiceLineItemDetail.ItemPrice,
        ItemDiscountAmount: invoiceLineItemDetail.ItemDiscountAmount,
        ItemDiscountPercentage: invoiceLineItemDetail.ItemDiscountPercentage,
        VendorKey: invoiceLineItemDetail.VendorKey,
        OptionKey: invoiceLineItemDetail.OptionId,
        RenderProfileID: invoiceLineItemDetail.RenderProfileID,
      };

      form.setFieldsValue({ ...initFormValues });
    }
  }, [invoiceLineItemDetail]);

  const handleSubmit = async (values: any) => {
    const formValues = form.getFieldsValue(true);
    const changedData: any = {};
    for (let line in formValues) {
      if (formValues[line] !== invoiceLineItemDetail![line as keyof InvoiceDetails]) {
        changedData[line] = formValues[line];
      }
    }
    if (Object.keys(changedData).length === 0) {
      message.warning("No Data Changed");
      return;
    }

    changedData.Key = invoiceLineItemDetail?.Key;
    changedData.InvoiceKey = invoiceStore?.activeInvoice?.Key;
    await invoiceStore?.putInvoiceLineItem(changedData);
  };
  const columns = [
    {
      title: "Item No",
      dataIndex: "ItemIdentifier",
      key: "ItemIdentifier",
      width: "10%",
    },
    {
      title: "Description",
      dataIndex: "ItemDescription",
      key: "ItemDescription",
    },
    {
      title: "Image",
      dataIndex: "ImageNumber",
      key: "ImageNumber",
      width: "10%",
      render: (imageNumber: any, record: any) => {
        return (
          <Select
            defaultValue={imageNumber}
            onChange={async (newImageName: number) => {
              await invoiceStore?.putInvoiceLineItem({
                InvoiceKey: invoiceStore?.activeInvoice?.Key,
                FileName: newImageName,
                Key: record.Key,
              });
            }}
            options={invoiceStore?.sessionImages.map((image: any) => {
              return {
                label: image.ImageNumber,
                value: image.FileName,
              };
            })}
          />
        );
      },
    },
    {
      title: "Qty",
      dataIndex: "ItemQuantity",
      key: "ItemQuantity",
      width: "5%",
      render: (text: any, record: any) => {
        return (
          <InputNumber
            defaultValue={text}
            onChange={async (newQty: number | null) => {
              if (newQty === 0 && invoiceStore?.activeInvoice?.Key) {
                await invoiceStore?.deleteInvoiceLineItem(
                  invoiceStore.activeInvoice!.Key,
                  record.Key
                );
                return;
              }
              await invoiceStore?.putInvoiceLineItem({
                InvoiceKey: invoiceStore?.activeInvoice?.Key,
                Quantity: newQty,
                Key: record.Key,
              });
            }}
          />
        );
      },
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "Price",
      align: "right" as AlignType,
      render: (text: any, record: any) => {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        return formatter.format(text);
      },
    },
    {
      title: "Extended",
      dataIndex: "ItemExtended",
      align: "right" as AlignType,
      key: "ItemExtended",
      render: (text: any, record: any) => {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        return formatter.format(text);
      },
    },
    {
      width: "2.5%",
      render: (text: string, record: any) => (
        <EllipsisOutlined
          onClick={() => {
            setInvoiceLineItemDetail({ ...record });
            setShowInvoiceLineItemDetail(true);
          }}
        />
      ),
    },
  ];

  const paymentsColumns: any = [
    {
      title: "Payment Date",
      dataIndex: "PaymentDate",
      key: "PaymentDate",
      render: (text: any, record: any) => {
        return dayjs(text).format("MM/DD/YYYY");
      },
    },
    {
      title: "Method",
      dataIndex: "PaymentMethodDescription",
      key: "PaymentMethodDescription",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      render: (text: any, record: any) => {
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });
        return formatter.format(text);
      },
    },
    {
      width: "2.5%",
      render: (text: string, record: any) => (
        <EllipsisOutlined
          onClick={async () => {
            await invoiceStore?.getPayment(record.Key);
            setPaymentModal(true);
          }}
        />
      ),
    },
  ];

  const PriceListModalContent = () => (
    <Menu style={{ maxHeight: "50vh", overflowY: "scroll", overflowX: "hidden" }} mode="inline">
      {invoiceStore?.priceList?.map((group: any) => {
        return (
          <SubMenu title={group.Name}>
            {group.Items.map((item: any) => (
              <Menu.Item
                onClick={() => {
                  invoiceStore?.putInvoiceLineItem({
                    Quantity: 1,
                    InvoiceKey: invoiceStore?.activeInvoice?.Key,
                    ItemKey: item.Key,
                  });
                }}
              >
                {item.Name}
              </Menu.Item>
            ))}
          </SubMenu>
        );
      })}
    </Menu>
  );

  const GeneralTab = () => (
    <div style={{ padding: "2rem" }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item name="ItemIdentifier">
            <InputField
              bordered
              label={"Item Number"}
              value={invoiceLineItemDetail?.ItemIdentifier}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="Description">
            <InputField label={"Item Description"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="GCNumber">
            <InputField bordered label={"Gift Certificate Number"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="ImageNumber">
            <InputField
              bordered
              label={"Image Number"}
              value={invoiceLineItemDetail?.ImageNumber}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="Quantity">
            <InputField bordered label={"Quantity"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="ItemPrice">
            <InputField label="Price" />
          </Form.Item>
        </Col>

        <Radio.Group
          onChange={(e: any) => {
            setDiscountType(e.target.value);
          }}
          value={discountType}
        >
          <Radio value={"Dollar"}>$ Discount</Radio>
          <Radio value={"Percent"}>% Discount</Radio>
        </Radio.Group>
        {discountType === "Dollar" ? (
          <Col span={24}>
            <Form.Item name="ItemDiscountAmount">
              <InputField
                label={"$ Discount"}
                value={invoiceStore?.activeInvoice?.WebOrderNumber}
              />
            </Form.Item>
          </Col>
        ) : (
          <Col span={24}>
            <Form.Item name="ItemDiscountPercentage">
              <InputField
                label={"% Discount"}
                value={invoiceStore?.activeInvoice?.WebOrderNumber}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </div>
  );

  const OptionTab = () => (
    <div style={{ padding: "2rem" }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item name="VendorKey">
            <SelectField
              value={invoiceStore?.activeInvoice?.InvoiceType}
              options={invoiceLineItemDetail?.VendorMaintList?.map((list) => {
                return {
                  label: list.Name,
                  value: list.Key,
                };
              })}
              label="Vendor"
              showSearch={false}
              showArrow={false}
              suffixIcon={<></>}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="EnhancementID">
            <SelectField
              options={invoiceLineItemDetail?.PriceListItemEnhancementMaints?.map((list) => {
                return {
                  label: list.Name,
                  value: list.Key,
                };
              })}
              label="Enhancement Level"
              showSearch={false}
              showArrow={false}
              suffixIcon={<></>}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="OptionKey">
            <SelectField
              options={invoiceLineItemDetail?.PriceListItemOptionMaints?.map((list) => {
                return {
                  label: list.Name,
                  value: list.Key,
                };
              })}
              label="Option Level"
              showSearch={false}
              showArrow={false}
              suffixIcon={<></>}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="RenderProfileID">
            <SelectField
              options={invoiceStore?.renderProfiles?.map((profile) => {
                return {
                  label: profile.Name,
                  value: profile.Key,
                };
              })}
              label="Render Profile"
              showSearch={false}
              showArrow={false}
              suffixIcon={<></>}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );

  const InvoiceDetailModalContent = () => (
    <Form form={form} onFinish={handleSubmit}>
      <div>
        <Tabs
          className="client-tabs"
          activeKey={tab}
          onTabClick={(key: string) => setTab(key)}
          type="card"
          size={"small"}
        >
          <TabPane tab="General" key="1">
            <GeneralTab />
          </TabPane>
          <TabPane tab="Options" key="2">
            <OptionTab />
          </TabPane>
          <TabPane tab="Notes" key="3">
            <div style={{ padding: "2rem" }}>
              <Form.Item name="Notes">
                <InputField label={"Add Note"} />
              </Form.Item>
              <h3>Current Notes</h3>
              <TextArea rows={10} defaultValue={invoiceStore?.activeInvoice?.Notes} />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Form>
  );

  const InvoiceLineItemModalHeader = () => {
    return (
      <Row>
        <Col xs={10}>
          <Title level={3}>Invoice Line Item Detail</Title>
        </Col>
        <Col
          xs={14}
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            icon={<CheckOutlined />}
            // disabled={isLoading}
            style={{
              backgroundColor: "rgb(255, 179, 28)",
              borderColor: "rgb(255, 179, 28)",
              color: "white",
            }}
            className="btn-action"
            shape="round"
            onClick={() => form.validateFields().then(() => form.submit())}
          >
            Save
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <div style={{ padding: "2rem" }}>
      <Card
        headStyle={{ backgroundColor: "#bed1df", color: "white" }}
        title="Basic Info"
        style={{ marginBottom: "2rem" }}
      >
        <Row>
          <Col span={12} xs={24} sm={12}>
            <InputField
              label={"Invoice Number"}
              value={invoiceStore?.activeInvoice?.InvoiceNumber}
            />
          </Col>
          <Col span={12} xs={24} sm={12}>
            <InputField
              label={"Invoice Date"}
              value={dayjs(invoiceStore?.activeInvoice?.InvoiceDate).format("MM/DD/YYYY")}
            />
          </Col>
          <Col span={12} xs={24} sm={12}>
            <InputField
              label={"Invoice Description"}
              value={invoiceStore?.activeInvoice?.InvoiceDescription}
            />
          </Col>
          <Col span={12}>
            <InputField
              label={"Invoice Status"}
              value={invoiceStore?.activeInvoice?.InvoiceStatusDescription}
            />
          </Col>
          <Col span={12} className="ClientDetails">
            {invoiceStore?.activeInvoice?.ClientKey ? (
              <ClientRow clientKey={invoiceStore?.activeInvoice?.ClientKey} />
            ) : null}
          </Col>
        </Row>
      </Card>
      <Card headStyle={{ backgroundColor: "#bed1df", color: "white" }} title="Details">
        <Button
          style={{ marginBottom: "2rem" }}
          onClick={() => {
            setShowPriceListModal(true);
          }}
        >
          Add Line Item
        </Button>
        <Table
          pagination={false}
          rowKey={(record: any) => record.Key}
          columns={columns}
          dataSource={invoiceStore?.activeInvoice?.InvoiceDetails || []}
          style={{ marginBottom: "2rem" }}
        />
      </Card>

      <Row style={{ marginTop: "2rem" }} gutter={[8, 8]}>
        <Col xs={24} md={16}>
          <Card
            title="Payments and Refunds"
            headStyle={{ backgroundColor: "#bed1df", color: "white" }}
          >
            {/* <Descriptions title="Payments and Refunds"></Descriptions> */}
            <Button
              style={{ marginBottom: "2rem" }}
              onClick={() => {
                setPaymentModal(true);
              }}
            >
              Add Payment or Refund
            </Button>
            <Table
              pagination={false}
              rowKey={(record: any) => record.Key}
              columns={paymentsColumns}
              dataSource={invoiceStore?.activeInvoice?.Payments || []}
              style={{ marginBottom: "2rem" }}
            />
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card title="Order Summary" headStyle={{ backgroundColor: "#bed1df", color: "white" }}>
            <Row>
              <Col xs={8}>Subtotal: </Col>
              <Col style={{ textAlign: "end" }} xs={16}>
                {`$${
                  invoiceStore?.activeInvoice
                    ? invoiceStore?.activeInvoice?.Subtotal?.toFixed(2)
                    : "0.00"
                }`}
              </Col>
            </Row>
            <Divider />
            <Row style={{ marginBottom: "0.5rem" }}>
              <Col xs={8}>Discount: </Col>
              <Col style={{ textAlign: "end" }} xs={16}>
                {`$${
                  invoiceStore?.activeInvoice
                    ? invoiceStore?.activeInvoice?.DiscountAmount?.toFixed(2)
                    : "0.00"
                }`}
              </Col>
            </Row>
            <Row style={{ marginBottom: "0.5rem" }}>
              <Col xs={8}>Sales Tax: </Col>
              <Col style={{ textAlign: "end" }} xs={16}>
                {`$${
                  invoiceStore?.activeInvoice
                    ? invoiceStore?.activeInvoice?.Tax1Amount?.toFixed(2)
                    : "0.00"
                }`}
              </Col>
            </Row>
            <Row>
              <Col xs={8}>Shipping: </Col>
              <Col style={{ textAlign: "end" }} xs={16}>
                {`$${
                  invoiceStore?.activeInvoice
                    ? invoiceStore?.activeInvoice?.ShippingAmount?.toFixed(2)
                    : "0.00"
                }`}
              </Col>
            </Row>
            <Divider />
            <Row style={{ marginBottom: "0.5rem" }}>
              <Col xs={8}>Invoice Total: </Col>
              <Col style={{ textAlign: "end" }} xs={16}>
                {`$${
                  invoiceStore?.activeInvoice
                    ? invoiceStore?.activeInvoice?.Total?.toFixed(2)
                    : "0.00"
                }`}
              </Col>
            </Row>
            <Row style={{ marginBottom: "0.5rem" }}>
              <Col xs={8}>Total Payments: </Col>
              <Col style={{ textAlign: "end" }} xs={16}>
                {`$${
                  invoiceStore?.activeInvoice
                    ? invoiceStore?.activeInvoice?.PaymentsTotal?.toFixed(2)
                    : "0.00"
                }`}
              </Col>
            </Row>
            <Row>
              <Col xs={8}>Balance Due: </Col>
              <Col style={{ textAlign: "end" }} xs={16}>
                {`$${
                  invoiceStore?.activeInvoice
                    ? invoiceStore?.activeInvoice?.OpenBalance?.toFixed(2)
                    : "0.00"
                }`}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        visible={showPriceListModal}
        footer={null}
        onCancel={() => setShowPriceListModal(false)}
        className="priceListModal"
      >
        <PriceListModalContent />
      </Modal>
      <Modal
        visible={showInvoiceLineItemDetail}
        footer={null}
        title={<InvoiceLineItemModalHeader />}
        onCancel={() => setShowInvoiceLineItemDetail(false)}
        className="priceListModal"
      >
        <InvoiceDetailModalContent />
      </Modal>
      <PaymentModal visible={paymentModal} setPaymentModal={setPaymentModal} />
    </div>
  );
};

export default inject("invoiceStore")(observer(OrderDetails));
