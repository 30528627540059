import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Layout, Tabs, Skeleton, Empty, Button, Tooltip, Switch, message, Spin, List } from "antd";

import {
  LeftOutlined,
  CameraOutlined,
  CreditCardOutlined,
  BankOutlined,
  CalendarOutlined,
  ExclamationCircleOutlined,
  InfoOutlined,
  MailOutlined,
  PhoneOutlined,
  TagOutlined,
  UserOutlined,
  HomeOutlined,
  AlignLeftOutlined,
  ReconciliationOutlined,
  ClockCircleOutlined,
  PushpinOutlined,
  CompressOutlined,
  BorderlessTableOutlined,
} from "@ant-design/icons";

import ProCard from "@ant-design/pro-card";

import { ClientStoreClass } from "stores/ClientStore";
import type { SessionStoreClass } from "stores/SessionStore";
import type { InvoiceStoreClass } from "stores/InvoiceStore";

import "./QuickAccessPage.scss";
import "@ant-design/pro-card/dist/card.css";
import CustomHeader from "components/header/CustomHeader";

import ClientTabs from "components/tabs/ClientTabs";
import QuickAccessSearchComponent from "./QASearchComponent";
import QuickAccessClientList from "./Client/QAClientListPage";
import QAClientActionItems from "./Client/QAClientActionItems";
import QuickAccessSessionsList from "./Session/QASessionsList";
import QuickAccessInvoicesList from "./Invoice/QAInvoicesList";
import QASessionActionItems from "./Session/QASessionActionItems";
import QADetails from "./Client/QADetails";

import { Client, ClientList } from "interfaces/client.int";
import { SessionDetail } from "interfaces/session.int";
import QASessionDetails from "./Session/QASessionDetails";
import dayjs from "dayjs";
import PhoneCallDrawerForm from "components/drawers/PhoneCallDrawerForm";
import { PhoneCallStoreClass } from "stores/PhoneCallStore";
import SessionDrawerForm from "components/drawers/SessionDrawerForm";
import { AppointmentStoreClass } from "stores/AppointmentStore";
import QAAddEditSession from "./Session/QAAddEditSession";
import AppointmentDrawerForm from "components/drawers/AppointmentDrawerForm";
import QuickAccessAddEditAppointment from "./QAAddEditAppointment";
import QAInvoiceActionItems from "./Invoice/QAInvoiceActionItems";
import { TaskStoreClass } from "stores/TaskStore";
import TaskDrawerForm from "components/drawers/TaskDrawerForm";
import { ScheduleStoreClass } from "stores/ScheduleStore";
import InvoiceDrawerView from "components/drawers/InvoiceDrawerView/InvoiceDrawerView";
import QAInvoiceDetails from "./Invoice/QAInvoiceDetails";
import { UserStoreClass } from "stores/UserStore";
import SMSDrawer from "pages/Messages/SMSDrawer";
import { InvoiceList } from "interfaces/invoice.int";
import RenderInvoice from "components/invoice/RenderInvoice";
import { AuthStoreClass } from "stores/AuthStore";

interface Props {
  clientStore: ClientStoreClass;
  sessionStore: SessionStoreClass;
  userStore: UserStoreClass;
  invoiceStore: InvoiceStoreClass;
  phoneCallStore?: PhoneCallStoreClass;
  appointmentStore?: AppointmentStoreClass;
  taskStore?: TaskStoreClass;
  scheduleStore?: ScheduleStoreClass;
  authStore?: AuthStoreClass;
}

const { Content } = Layout;
const { TabPane } = Tabs;

const QuickAccessPage: React.FC<Props> = ({
  clientStore,
  sessionStore,
  userStore,
  invoiceStore,
  phoneCallStore,
  appointmentStore,
  taskStore,
  authStore,
}) => {
  let [client, setClient] = useState<any>(clientStore?.clientInfo);
  let [session, setSession] = useState<any>(sessionStore?.currentSessionDetail);
  let [invoice, setInvoice] = useState<any>(invoiceStore?.activeInvoice);

  const [flag, setFlag] = useState<boolean>(false);
  const [back] = useState<boolean>(false);
  const [toggleActionItems, setToggleActionItems] = useState<boolean>(false);
  const [toggleSessionActionItems, setToggleSessionActionItems] = useState<boolean>(false);
  const [toggleInvoiceActionItems, setToggleInvoiceActionItems] = useState<boolean>(false);
  const [, setToggleAppointmentActionItems] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>("Client");
  let [sms, setSMS] = useState<boolean | undefined>(false);
  let [viewSection, setViewSection] = useState<string>("client");
  const [clientsArray, setClientsArray] = useState<any>({});
  const [sessionArray, setSessionArray] = useState<any>({});
  const [invoiceArray, setInvoiceArray] = useState<any>({});
  let [openCalender, setOpenCalender] = useState<boolean>(false);
  let [openSMSDrawer, setOpenSMSDrawer] = useState<boolean>(false);
  let [manageImagesStatus, setManageImagesStatus] = useState<boolean>(false);
  let [openCalenderForAppointment, setOpenCalenderForAppointment] = useState<boolean>(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [newSessionInvoice, setNewSessionInvoice] = useState<boolean>(false);
  const [isQuickSale, setIsQuickSale] = useState<boolean>(false);

  //brings up the list of invoices for current active session
  const getInvoiceDetail = async () => {
    try {
      await invoiceStore?.getInvoices({
        action: "sessionKey",
        value: sessionStore?.currentSessionDetail.Key,
      });
    } catch (e) {}
  };

  //Added this effect to reload the list for Invoices related to current active session
  useEffect(() => {
    if (
      viewSection ===
        (authStore?.companySettings.JobDescriptorPlural
          ? authStore?.companySettings.JobDescriptorPlural
          : "Sessions") &&
      showInvoice
    ) {
      getInvoiceDetail();
    }
  }, [viewSection, showInvoice]);

  const handleClick = (item?: ClientList | undefined, field?: string) => {
    clientStore?.setActiveClientNumber(item?.ClientNumber || "");
    clientStore?.setActiveClientKey(item?.Key);

    clientStore?.setSelectToggle(true);
  };

  const handleTabChange = (
    key: React.SetStateAction<string>,
    field?: React.SetStateAction<string>
  ) => {
    setCurrentTab(key);
    if (field) {
      setViewSection(field);
    } else {
      setViewSection(key);
    }
  };

  const handleBackButton = async (activeKey: any) => {
    if (currentTab == "Client") {
      setToggleActionItems(false);
    }
    if (
      currentTab ==
      (authStore?.companySettings.JobDescriptorPlural
        ? authStore?.companySettings.JobDescriptorPlural
        : "Sessions")
    ) {
      if (showInvoice === true) setShowInvoice(false);
      else {
        setToggleSessionActionItems(false);
        if (!toggleSessionActionItems) handleTabChange("Client");
      }
    }
    if (currentTab == "Invoices") {
      setToggleInvoiceActionItems(false);
      if (!toggleInvoiceActionItems) {
        handleTabChange(
          authStore?.companySettings.JobDescriptorPlural
            ? authStore?.companySettings.JobDescriptorPlural
            : "Sessions"
        );
      }
    }
    if (currentTab === "Appointments") {
      setToggleAppointmentActionItems(false);
    }
  };

  const handleSave = async () => {
    let changedData: any = {};
    Object.keys(clientsArray).forEach((key, index) => {
      if (clientsArray[key].value !== clientStore?.clientInfo![key as keyof Client]) {
        changedData[key] = clientsArray[key].value;
      }
    });
    if (sms !== client?.SMSTextable0) {
      changedData.SMSTextable0 = sms;
    }

    if (Object.keys(changedData).length === 0) {
      message.warning("No Data Changed");
      return;
    }
    changedData.Key = clientStore?.clientInfo?.Key;
    await clientStore?.editClient(changedData);
    message.success("Client successfully saved!");
  };

  const handleSessionSave = async () => {
    const changedData: any = {};

    Object.keys(sessionArray).forEach((key, index) => {
      if (
        sessionArray[key].value !== sessionStore?.currentSessionDetail![key as keyof SessionDetail]
      ) {
        if (key === "StartDateTime") {
          if (
            dayjs(sessionArray[key].value).format("YYYY-MM-DDTHH:mm:ss") !==
            sessionStore?.currentSessionDetail![key as keyof SessionDetail]
          ) {
            changedData[key] = sessionArray[key].value;
          }
          if (
            dayjs(sessionArray[key].value1).format("YYYY-MM-DDTHH:mm:ss") !==
            sessionStore?.currentSessionDetail!["EndDateTime" as keyof SessionDetail]
          ) {
            changedData[key] = sessionArray[key].value1;
          }
        } else if (key === "Date") {
        } else if (key === "Confirmed") {
          changedData[key] = sessionArray[key].value;
        } else {
          changedData[key] = sessionArray[key].value;
        }
      }
    });

    if (Object.keys(changedData).length === 0) {
      message.warning("No Data Changed");
      return;
    }
    changedData.Key = sessionStore?.currentSessionDetail?.Key;
    await sessionStore?.addSession(changedData);
    message.success(
      (authStore?.companySettings.JobDescriptorSingular
        ? authStore?.companySettings.JobDescriptorSingular
        : "Session") + " successfully saved!"
    );
  };

  useEffect(() => {
    setClient(clientStore?.clientInfo);
  }, [clientStore?.clientInfo]);

  useEffect(() => {
    setSession(sessionStore?.currentSessionDetail);
  }, [sessionStore?.currentSessionDetail]);

  useEffect(() => {
    setInvoice(invoiceStore?.activeInvoice);
  }, [invoiceStore?.activeInvoice]);

  useEffect(() => {
    let startTime = dayjs(session?.StartDateTime);
    let endTime = dayjs(session?.EndDateTime);
    let date = dayjs(session?.StartDateTime).format("MM/DD/YYYY");
    setSessionArray({
      Date: {
        label: authStore?.companySettings.JobDescriptorSingular
          ? authStore?.companySettings.JobDescriptorSingular + " date"
          : "Session date",
        icon: <CalendarOutlined />,
        value: date,
        dateField: true,
      },
      Description: {
        label: "Description",
        icon: <AlignLeftOutlined />,
        value: session?.Description,
      },
      SessionTypeKey: {
        label: "Type",
        icon: <ReconciliationOutlined />,
        value: session?.SessionTypeKey,
        sessionTypeDropdown: true,
      },
      StartDateTime: {
        label: authStore?.companySettings.JobDescriptorSingular
          ? authStore?.companySettings.JobDescriptorSingular + " Time"
          : "Session Time",
        icon: <ClockCircleOutlined />,
        value: startTime,
        value1: endTime,
        timeField: true,
      },
      PrimaryPhotographerName: {
        label: "Photographer",
        icon: <CameraOutlined />,
        value: session?.PrimaryPhotographerName,
        PrimaryPhotographerNameDropdown: true,
      },
      Location: {
        label: authStore?.companySettings?.LocationDescriptorSingular
          ? authStore?.companySettings?.LocationDescriptorSingular
          : "Location",
        icon: <PushpinOutlined />,
        value: session?.Location,
      },
      StatusKey: {
        label: "Status",
        icon: <TagOutlined />,
        value: session?.StatusKey,
        sessionStatusDropdown: true,
      },
    });
    // }
  }, [session, sessionStore?.currentSessionDetail]);

  useEffect(() => {
    setSMS(client?.SMSTextable0);

    setClientsArray({
      FirstName: {
        label: "First Name",
        icon: <UserOutlined />,
        value: client?.FirstName,
        extraData: "LastName",
      },
      LastName: {
        label: "Last Name",
        icon: null,
        value: client?.LastName,
        isExtraData: true,
      },
      CompanyName: {
        label: "Company Name",
        icon: <BankOutlined />,
        value: client?.CompanyName,
      },
      OrganizationKey: {
        label: "Organization",
        icon: <InfoOutlined />,
        value: client?.OrganizationKey,
        organizationDropdown: true,
      },
      Email: {
        label: "Email",
        icon: <MailOutlined />,
        value: client?.Email,
      },
      PhoneNumber0: {
        label: "Phone Number",
        icon: <PhoneOutlined />,
        value: client?.PhoneNumber0,
        extraSwitch: (
          <div style={{ textAlign: "center" }}>
            <h5> Allow SMS</h5>
            <Switch
              defaultChecked={client?.SMSTextable0}
              onClick={(checked) => {
                setSMS(checked);
              }}
            />
          </div>
        ),
      },
      ClientStatusKey: {
        label: "Client Status",
        icon: <TagOutlined />,
        value: client?.ClientStatusKey,
        clientStatusDropdown: true,
      },
      BirthdayDate: {
        label: "Client Birthday",
        icon: <CalendarOutlined />,
        value: client?.BirthdayDate,
        isBirthday: true,
      },
      // Notes: {
      //   label: "Client Notes",
      //   icon: <FileDoneOutlined />,
      //   value: client?.Notes,
      // },
      Warning: {
        label: "Client Warning",
        icon: <ExclamationCircleOutlined />,
        value: client?.Warning,
      },
    });
  }, [client]);

  useEffect(() => {
    setInvoiceArray({
      InvoiceNumber: {
        label: "Invoice Number",
        icon: <UserOutlined />,
        value: invoice?.InvoiceNumber,
        extraData: "LastName",
      },
      InvoiceDate: {
        label: "Invoice Date",
        icon: <CalendarOutlined />,
        value: dayjs(invoice?.InvoiceDate).format("MM/DD/YYYY"),
      },
      PaymentDueDate: {
        label: "Payment Due Date",
        icon: <BankOutlined />,
        value: dayjs(invoice?.PaymentDueDate).format("MM/DD/YYYY"),
      },
      Total: {
        label: "Total",
        icon: <InfoOutlined />,
        value: `$${invoice?.Total}`,
      },
      OpenBalance: {
        label: "Open Balance",
        icon: <MailOutlined />,
        value: `$${invoice?.OpenBalance}`,
      },
      InvoiceType: {
        label: "Invoice Type",
        icon: <CompressOutlined />,
        value: invoice?.InvoiceType,
      },
      InvoiceStatusDescription: {
        label: "Invoice Status",
        icon: <BorderlessTableOutlined />,
        value: invoice?.InvoiceStatusDescription,
      },
    });
  }, [invoice]);

  const quickSaleHandler = () => {
    setIsQuickSale(true);
    invoiceStore?.addInvoice({});
    invoiceStore?.setCreatingNew(true);
    invoiceStore?.setDrawerType("detail");
    invoiceStore?.setSelectToggle(true);
  };

  return (
    <>
      <Content className="main-content quick-access-page">
        <CustomHeader title="Quick Access" />
        <ProCard split={"vertical"} style={{ height: "100%" }}>
          <ProCard split={"horizontal"}>
            {!toggleActionItems ? (
              <ProCard gutter={0} ghost className="search-section">
                <div className="search-section-left">
                  <ProCard className="search-container">
                    <QuickAccessSearchComponent
                      setToggleActionItems={setToggleActionItems}
                      setToggleSessionActionItems={setToggleSessionActionItems}
                      setToggleInvoiceActionItems={setToggleInvoiceActionItems}
                      setToggleAppointmentActionItems={setToggleAppointmentActionItems}
                      setFlag={setFlag}
                      clientStore={clientStore}
                    />
                  </ProCard>
                </div>
                <div className="search-section-right">
                  <ProCard className="search-buttons" layout="center">
                    <div className="search-buttons-block">
                      <Button
                        onClick={() => {
                          clientStore?.clearLists();
                          handleClick();
                        }}
                        className="ant-btn-primary"
                        color="secondary"
                        style={{ backgroundColor: "#708D65", borderColor: "white" }}
                      >
                        New Client
                      </Button>
                      <Button
                        className="ant-btn-secondary"
                        style={{ borderColor: "#92b0b6", color: "#92b0b6" }}
                        onClick={() => {
                          quickSaleHandler();
                        }}
                      >
                        Quick Sale
                      </Button>
                    </div>
                  </ProCard>
                </div>
              </ProCard>
            ) : (
              <ProCard gutter={0} ghost className="quick-access-inner-head">
                <ProCard className="search-container" colSpan="100%" layout="center">
                  <div className="search-buttons-block icon-buttons">
                    <Tooltip title="Back">
                      <Button
                        shape="circle"
                        size="large"
                        icon={<LeftOutlined />}
                        onClick={handleBackButton}
                        disabled={back}
                        className="ant-btn-primary"
                        style={{ backgroundColor: "#526171", borderColor: "#526171" }}
                      />
                    </Tooltip>
                    <Tooltip title="Home">
                      <Button
                        className="ant-btn-secondary"
                        shape="circle"
                        size="large"
                        icon={<HomeOutlined />}
                        onClick={() => {
                          clientStore?.clearLists();
                          setToggleActionItems(false);
                          setToggleSessionActionItems(false);
                          setShowInvoice(false);
                          setFlag(false);
                        }}
                        disabled={back}
                        style={{
                          backgroundColor: "#526171",
                          borderColor: "#526171",
                          color: "white",
                        }}
                      />
                    </Tooltip>
                  </div>
                  <h1 style={{ lineHeight: 2 }}>{client?.FirstName + " " + client?.LastName}</h1>
                </ProCard>
              </ProCard>
            )}

            <ProCard headerBordered className="quick-access-content">
              <div className="card-container">
                {!flag ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={"Please search something to display "}
                  />
                ) : toggleActionItems ? (
                  <Tabs
                    type="card"
                    activeKey={currentTab}
                    onChange={(activeKey) => handleTabChange(activeKey)}
                  >
                    <TabPane
                      tab={
                        <>
                          <UserOutlined /> Client
                        </>
                      }
                      key="Client"
                    >
                      <QAClientActionItems
                        invoiceStore={invoiceStore}
                        clientStore={clientStore}
                        handleClick={handleClick}
                        handleTabChange={handleTabChange}
                        phoneCallStore={phoneCallStore}
                        setOpenCalender={setOpenCalender}
                        setOpenCalenderForAppointment={setOpenCalenderForAppointment}
                        setOpenSMSDrawer={setOpenSMSDrawer}
                      />
                    </TabPane>
                    <TabPane
                      tab={
                        <>
                          <CameraOutlined />{" "}
                          {authStore?.companySettings.JobDescriptorPlural
                            ? authStore?.companySettings.JobDescriptorPlural
                            : "Sessions"}
                        </>
                      }
                      disabled={!toggleActionItems}
                      key={
                        authStore?.companySettings.JobDescriptorPlural
                          ? authStore?.companySettings.JobDescriptorPlural
                          : "Sessions"
                      }
                    >
                      {toggleSessionActionItems ? (
                        showInvoice ? (
                          <Skeleton loading={invoiceStore?.isLoading}>
                            <h3>
                              Invoices for {session.Description}{" "}
                              {authStore?.companySettings.JobDescriptorSingular
                                ? authStore?.companySettings.JobDescriptorSingular
                                : "Session"}
                            </h3>
                            <List
                              dataSource={invoiceStore?.invoiceList}
                              renderItem={(item: InvoiceList) => (
                                <RenderInvoice
                                  setToggleInvoiceActionItems={setToggleInvoiceActionItems}
                                  item={item}
                                  currentTab={currentTab}
                                  setCurrentTab={setCurrentTab}
                                />
                              )}
                              bordered
                              style={{ border: 0 }}
                            />
                          </Skeleton>
                        ) : (
                          <QASessionActionItems
                            sessionStore={sessionStore}
                            clientStore={clientStore}
                            handleClick={handleClick}
                            setManageImagesStatus={setManageImagesStatus}
                            setOpenCalender={setOpenCalender}
                            invoiceStore={invoiceStore}
                            showInvoice={showInvoice}
                            setShowInvoice={setShowInvoice}
                            currentTab={currentTab}
                            getInvoiceDetail={getInvoiceDetail}
                            setNewSessionInvoice={setNewSessionInvoice}
                            newSessionInvoice={newSessionInvoice}
                          />
                        )
                      ) : (
                        <Spin size={"large"} spinning={sessionStore?.isLoading}>
                          <QuickAccessSessionsList
                            sessionStore={sessionStore}
                            clientStore={clientStore}
                            setToggleActionItems={setToggleSessionActionItems}
                            viewSection={viewSection}
                          />
                        </Spin>
                      )}
                    </TabPane>
                    <TabPane
                      tab={
                        <>
                          <CreditCardOutlined /> Invoices
                        </>
                      }
                      disabled={!toggleActionItems}
                      key="Invoices"
                    >
                      {toggleInvoiceActionItems ? (
                        <Spin size={"large"} spinning={invoiceStore.isLoading}>
                          <QAInvoiceActionItems
                            invoiceStore={invoiceStore}
                            clientStore={clientStore}
                            handleClick={handleClick}
                            setManageImagesStatus={setManageImagesStatus}
                            setOpenCalender={setOpenCalender}
                            taskStore={taskStore}
                            sessionStore={sessionStore}
                            phoneCallStore={phoneCallStore}
                            //setOpenInvoice={setOpenInvoice}
                          />
                        </Spin>
                      ) : (
                        <Spin size={"large"} spinning={invoiceStore.isLoading}>
                          <QuickAccessInvoicesList
                            viewSection={viewSection}
                            invoiceStore={invoiceStore}
                            clientStore={clientStore}
                            setToggleInvoiceActionItems={setToggleInvoiceActionItems}
                            currentTab={currentTab}
                            setCurrentTab={setCurrentTab}
                          />
                        </Spin>
                      )}
                    </TabPane>
                    {/* <TabPane
                      tab={
                        <>
                          <TeamOutlined /> Appointments{" "}
                        </>
                      }
                      key="Appointments"
                      disabled={!toggleActionItems}
                    >
                      {toggleAppointmentActionItems ? (
                        <QAAppointmentActionItems
                          invoiceStore={invoiceStore}
                          handleClick={handleClick}
                          setManageImagesStatus={setManageImagesStatus}
                          setOpenCalender={setOpenCalender}
                          taskStore={taskStore}
                          sessionStore={sessionStore}
                        />
                      ) : (
                        <QAAppointmentsList
                          appointmentStore={appointmentStore}
                          setToggleAppointmentActionItems={setToggleAppointmentActionItems}
                        />
                      )}
                    </TabPane> */}
                  </Tabs>
                ) : (
                  <Spin size={"large"} spinning={clientStore?.isLoading}>
                    {/* <Skeleton loading={clientStore?.isLoading}> */}
                    {clientStore?.searchTerm ? (
                      <QuickAccessClientList
                        invoiceStore={invoiceStore}
                        setToggleActionItems={setToggleActionItems}
                        clientStore={clientStore}
                        sessionStore={sessionStore}
                        setToggleSessionActionItems={setToggleSessionActionItems}
                        setToggleInvoiceActionItems={setToggleInvoiceActionItems}
                        setToggleAppointmentActionItems={setToggleAppointmentActionItems}
                        appointmentStore={appointmentStore}
                        setCurrentTab={setCurrentTab}
                      />
                    ) : (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={"Please search something to display "}
                      />
                    )}
                    {/* </Skeleton> */}
                  </Spin>
                )}
              </div>
            </ProCard>
          </ProCard>

          {!toggleActionItems ? (
            <ProCard title="Client Details" colSpan="30%" className="quick-access-rightSide">
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={"Please select a client to view details "}
              />
            </ProCard>
          ) : (
            <ProCard
              className="quick-access-rightSide"
              title={
                toggleSessionActionItems &&
                currentTab ===
                  (authStore?.companySettings.JobDescriptorPlural
                    ? authStore?.companySettings.JobDescriptorPlural
                    : "Sessions")
                  ? authStore?.companySettings.JobDescriptorSingular
                    ? authStore?.companySettings.JobDescriptorSingular + "  Details"
                    : "Session Details"
                  : toggleInvoiceActionItems && currentTab === "Invoices"
                  ? "Invoice Details"
                  : "Client Details"
              }
              colSpan="30%"
              extra={
                <>
                  <Button
                    disabled={toggleInvoiceActionItems && currentTab === "Invoices"}
                    type="primary"
                    style={{ backgroundColor: "#ffb31c", color: "white", borderColor: "white" }}
                    onClick={() =>
                      toggleSessionActionItems &&
                      currentTab ===
                        (authStore?.companySettings.JobDescriptorPlural
                          ? authStore?.companySettings.JobDescriptorPlural
                          : "Sessions")
                        ? handleSessionSave()
                        : handleSave()
                    }
                  >
                    Save
                  </Button>
                </>
              }
            >
              <Skeleton loading={false}>
                {toggleSessionActionItems &&
                currentTab ===
                  (authStore?.companySettings.JobDescriptorPlural
                    ? authStore?.companySettings.JobDescriptorPlural
                    : "Sessions") ? (
                  <QASessionDetails
                    sessionArray={sessionArray}
                    setSessionArray={setSessionArray}
                    sessionStore={sessionStore}
                    userStore={userStore}
                  />
                ) : toggleInvoiceActionItems && currentTab === "Invoices" ? (
                  <QAInvoiceDetails
                    invoiceStore={invoiceStore}
                    invoiceArray={invoiceArray}
                    setInvoiceArray={setInvoiceArray}
                  />
                ) : (
                  <Spin size={"large"} spinning={clientStore?.isLoading}>
                    <QADetails
                      clientsArray={clientsArray}
                      setClientsArray={setClientsArray}
                      clientStore={clientStore}
                    />
                  </Spin>
                )}
              </Skeleton>
            </ProCard>
          )}
        </ProCard>
      </Content>
      <ClientTabs defaultActiveTab={viewSection === "Invoices" ? "4" : "1"} />
      <PhoneCallDrawerForm activeClientKey={client?.Key} />
      {/* {sessionStore?.isSelectToggle && <SessionDrawerForm active={"gallery"} />} */}

      <QAAddEditSession openCalender={openCalender} setOpenCalender={setOpenCalender} />
      <SMSDrawer
        visible={openSMSDrawer}
        setOpenSMSDrawer={setOpenSMSDrawer}
        authStore={authStore}
        client={client}
      />
      <QuickAccessAddEditAppointment
        openCalenderForAppointment={openCalenderForAppointment}
        setOpenCalenderForAppointment={setOpenCalenderForAppointment}
      />
      <SessionDrawerForm
        setOpenCalender={setOpenCalender}
        setOpenCalenderForAppointment={setOpenCalenderForAppointment}
        setCurrentTab={setCurrentTab}
        setToggleSessionActionItems={setToggleSessionActionItems}
        manageImagesStatus={manageImagesStatus}
      />
      <AppointmentDrawerForm
        setOpenCalender={setOpenCalender}
        setOpenCalenderForAppointment={setOpenCalenderForAppointment}
      />
      <TaskDrawerForm activeClientKey={client?.Key} />
      <InvoiceDrawerView
        ClientKey={clientStore?.activeClientKey}
        newSessionInvoice={newSessionInvoice}
        setIsQuickSale={setIsQuickSale}
        isQuickSale={isQuickSale}
      />
    </>
  );
};

export default inject(
  "clientStore",
  "taskStore",
  "phoneCallStore",
  "sessionStore",
  "userStore",
  "invoiceStore",
  "appointmentStore",
  "scheduleStore",
  "authStore"
)(observer(QuickAccessPage));
