import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Button, Modal, Form, Row, Col, Grid, Popover, Menu, Checkbox } from "antd";
import { FilterOutlined, PlusOutlined, MoreOutlined } from "@ant-design/icons";

import type { PhoneCallStoreClass } from "stores/PhoneCallStore";
import FilterPhoneCall from "components/forms/FilterPhoneCall";
import CustomHeader from "components/header/CustomHeader";

interface Props {
  phoneCallStore?: PhoneCallStoreClass;
}

const { useBreakpoint } = Grid;

const ActionsPhoneCall: React.FC<Props> = ({ phoneCallStore }: Props): React.ReactElement => {
  const [isShowFilterModal, setIsShowFilterModal] = useState<boolean>(false);
  const [filterForm] = Form.useForm();
  const screens = useBreakpoint();

  const toggleFilterModal = () => {
    setIsShowFilterModal(!isShowFilterModal);
  };

  const handleSubmitFilter = () => {
    filterForm.submit();
    setIsShowFilterModal(false);
  };

  const handleClick = () => {
    phoneCallStore?.setSelectToggle(true);
  };

  const moreNavigation = (
    <Menu className="navigation-popover">
      <Menu.Item key="filter" onClick={toggleFilterModal} icon={<FilterOutlined />}>
        Filter
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <CustomHeader title="Calls">
        <Col style={{ marginLeft: "auto" }}>
          <Row align="middle">
            <Col>
              <Checkbox
                defaultChecked={phoneCallStore?.showCompleted}
                onChange={(e: any) => {
                  phoneCallStore?.setShowCompleted(e.target.checked);
                }}
              >
                Show Completed
              </Checkbox>
            </Col>
            <Col style={{ paddingRight: screens.lg ? "" : 5 }}>
              <Button
                disabled={phoneCallStore?.isLoading}
                style={{ backgroundColor: "#708D65", borderColor: "#white", color: "white" }}
                className={screens.lg ? "btn-action" : "no-label"}
                shape="round"
                onClick={handleClick}
              >
                New
              </Button>
            </Col>
            {!screens.lg && (
              <Popover
                content={moreNavigation}
                trigger="focus"
                placement="bottomRight"
                overlayClassName="navigation-popover-container"
              >
                <Button
                  icon={<MoreOutlined />}
                  type="text"
                  disabled={phoneCallStore?.isLoading}
                  className="no-label"
                />
              </Popover>
            )}
          </Row>
        </Col>
      </CustomHeader>
      <Modal
        title="Filter by:"
        visible={isShowFilterModal}
        onCancel={toggleFilterModal}
        onOk={handleSubmitFilter}
      >
        <FilterPhoneCall form={filterForm} />
      </Modal>
    </>
  );
};
export default inject("phoneCallStore")(observer(ActionsPhoneCall));
