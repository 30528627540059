import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { List, Typography } from "antd";
import dayjs from "dayjs";

import "./StatusBucket.scss";
import { InvoiceStoreClass } from "stores/InvoiceStore";

const { Text } = Typography;

interface Props {
  invoiceStatusKey: string;
  invoiceStore?: InvoiceStoreClass;
}

const InvoiceBucket: React.FC<Props> = ({ invoiceStatusKey, invoiceStore }) => {
  const [invoiceList, setInvoiceList] = useState<any>();

  const generateInvoiceList = async () => {
    const list = await invoiceStore?.getInvoicesByStatus(invoiceStatusKey);
    setInvoiceList(list);
  };

  useEffect(() => {
    if (invoiceStatusKey) generateInvoiceList();
  }, [invoiceStatusKey]);

  return (
    <List
      dataSource={invoiceList}
      loadMore={true}
      pagination={{ pageSize: 5 }}
      renderItem={(item: any) => {
        return (
          <List.Item>
            <div className="quickAccessList">
              <div className="quickAccessList__Date">
                <label>
                  {/* <span className="quickAccessList__Date--Icon"></span> */}
                  <span className="quickAccessList__Date--Day">
                    {/* <CalendarOutlined /> */}
                    {dayjs(item.DueDate).format("DD")}
                  </span>
                  <span className="quickAccessList__Date--Year">
                    {dayjs(item.DueDate).format("MMM YYYY")}
                  </span>
                </label>
              </div>
              <div className="quickAccessList__Content">
                <div className="quickAccessList__Content--Body">
                  <div className="data-panel">
                    <div className="status-block">
                      <Text className="taskStatus red" mark>
                        {item.InvoiceType}
                      </Text>
                    </div>
                    <p>Invoice Number: {item.InvoiceNumber}</p>
                  </div>
                </div>
              </div>
            </div>
          </List.Item>
        );
      }}
      bordered
      style={{ border: 0, backgroundColor: "#fff" }}
    ></List>
  );
};

export default inject("invoiceStore")(observer(InvoiceBucket));
