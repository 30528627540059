import React from "react";
import "./masonry-grid-viewer.scss";
import ImageContainer from "components/image-container/image-container";

const MasonryGridViewer = (props: any) => {
  return (
    <>
      <div className="masonryGridWrapper">
        <div className="main">
          {props.isLoading ? (
            <>
              <div className="loaderWrapper">
                <div className="loader"></div>
              </div>
            </>
          ) : (
            <div className="main_content">
              {props.images.map((image: any, imageIndex: number) => (
                <ImageContainer
                  key={`${image.thumbnailPath}${Math.random()}`}
                  image={image}
                  groupImages={props.images}
                  imageIndex={imageIndex}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MasonryGridViewer;
