import { observable, action, makeObservable, computed, toJS } from "mobx";
import { create, persist } from "mobx-persist";
import axios, { AxiosResponse, AxiosError } from "axios";
import type { Department, Task, TaskDetails, TaskTypes } from "interfaces/task.int";
import AuthStore from "./AuthStore";
import ClientStore from "./ClientStore";
import AlertStore from "./AlertStore";
export class TaskStoreClass {
  @persist("list") tasksList: Array<Task> = [];
  @persist("object") taskDetails: TaskDetails | undefined = {};
  @persist("list") taskTypes: Array<TaskTypes> = [];
  @persist("list") taskStatuses: Array<any> = [];
  @persist("list") departments: Array<Department> = [];
  @persist showCompleted: boolean = false;
  isSelectToggle: boolean = false;
  filterUserKey: string = "";
  isLoading = false;
  activeSearch: any = {};
  constructor() {
    makeObservable(this, {
      tasksList: observable,
      taskDetails: observable,
      isLoading: observable,
      filterUserKey: observable,
      taskTypes: observable,
      taskStatuses: observable,
      showCompleted: observable,
      setShowCompleted: action,
      departments: observable,
      getDepartments: action,
      getTaskStatuses: action,
      getTasks: action,
      getTask: action,
      setTasksList: action,
      getTaskTypes: action,
      editTask: action,
      deleteTask: action,
      setTaskDetails: action,
      setUserKeyFilter: action,
      sortedTasksList: computed,
      isSelectToggle: observable,
      setSelectToggle: action,
      cleanUp: action,
      activeSearch: observable,
      setActiveSearch: action,
    });
  }

  get sortedTasksList() {
    const filteredList = this.tasksList;
    return filteredList
      .slice()
      .sort((a: any, b: any) => Date.parse(b.AssignedDate) - Date.parse(a.AssignedDate));
  }

  async getTasks(clientKey?: string, userKey?: string) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: "/Tasks",
      params: {
        clientKey: clientKey ? clientKey : undefined,
        UserKey: userKey ? userKey : AuthStore.authenticatedUser.UserKey,
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.setTasksList(res.data);
          this.isLoading = false;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  setActiveSearch(search: any) {
    this.activeSearch = search;
  }

  async loadMoreTasks(offset: number) {
    this.isLoading = true;
    const searchTerm = toJS(this.activeSearch);
    // const searchAction = searchTerm?.action;
    // const searchValue = searchTerm?.value;
    let searchParams: any = {
      fetchAmount: 25,
      offset,
    };
    searchParams[searchTerm["action"]] = searchTerm["value"];
    return await axios({
      method: "GET",
      url: "/Tasks/search",
      params: searchParams,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.setTasksList([...this.tasksList, ...res.data]);
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
        // return err.message;
      });
  }

  async getTasksViaSearch() {
    this.isLoading = true;
    const searchTerm = toJS(this.activeSearch);
    // const searchAction = searchTerm?.action;
    // const searchValue = searchTerm?.value;
    let searchParams: any = {
      fetchAmount: 25,
    };
    searchParams[searchTerm["action"]] = searchTerm["value"];
    return await axios({
      method: "GET",
      url: "/Tasks/search",
      params: searchParams,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.setTasksList(res.data);
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
        // return err.message;
      });
  }

  async getTasksByStatus(statusKey: string) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/Tasks/search?taskStatus=${statusKey}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
        // return err.message;
      });
  }

  async getTaskStatuses() {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: "/TaskStatuses",
    })
      .then(
        action((res: AxiosResponse) => {
          this.taskStatuses = res.data;
          this.isLoading = false;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        AlertStore.setAlertMessage(err.message, "error");
      });
  }

  async getDepartments() {
    return await axios({ method: "GET", url: "/Departments" })
      .then((res: AxiosResponse) => {
        this.departments = res.data;
      })
      .catch((err: AxiosError) => {
        AlertStore.setAlertMessage(err.message, "error");
      });
  }

  async getTask(taskId: string) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/Task/${taskId}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.taskDetails = res.data;
          this.isLoading = false;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  async getTaskTypes() {
    return await axios({ method: "GET", url: "/TaskTypes" })
      .then((res: AxiosResponse) => {
        this.taskTypes = res.data;
      })
      .catch((err: AxiosError) => {
        AlertStore.setAlertMessage(err.message, "error");
      });
  }

  async addTask(task: TaskDetails) {
    this.isLoading = true;
    return await axios({
      method: "PUT",
      url: `/Task`,
      data: task,
    })
      .then(
        action((res: AxiosResponse) => {
          this.getTasks("", this.filterUserKey);
          AlertStore.setAlertMessage("Successfully added task", "success");
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        AlertStore.setAlertMessage(err.message, "error");
      });
  }

  async editTask(filteredInfo: any) {
    this.isLoading = true;
    return await axios({
      method: "PUT",
      url: "/Task",
      data: filteredInfo,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.setActiveSearch({ action: "assignedToUser", value: res.data.AssignedToUserKey });
          this.getTasksViaSearch();
          this.tasksList = this.tasksList.map((task) => {
            if (task.Key === res.data.Key) {
              return res.data;
            }
            return task;
          });
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
      });
  }

  async deleteTask(taskKey?: string) {
    this.isLoading = true;
    return await axios({
      method: "DELETE",
      url: `/Task/${taskKey}`,
    }).then(() => {
      this.tasksList = this.tasksList.filter((task) => {
        return taskKey !== task.Key;
      });
    });
  }

  setTaskDetails(newTaskDetails?: TaskDetails) {
    this.taskDetails = newTaskDetails;
  }

  setUserKeyFilter(key: string) {
    this.filterUserKey = key;
  }

  setSelectToggle(toggle: boolean) {
    this.isSelectToggle = toggle;
  }

  setShowCompleted(toggle: boolean) {
    this.showCompleted = toggle;
  }

  setTasksList(tasksList: Task[]) {
    this.tasksList = tasksList;
  }

  cleanUp() {
    this.tasksList = [];
    this.taskDetails = {};
    this.taskTypes = [];
    this.filterUserKey = "";
    ClientStore.setClientInfo({});
  }
}

const hydrate = create({});

const TaskStore = new TaskStoreClass();

export default TaskStore;

hydrate("task", TaskStore).then((TaskStore) => {});
