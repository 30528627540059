import React, { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Form, Input, Switch, Row, Col, Grid, message } from "antd";
import { inject, observer } from "mobx-react";
import {
  AlignLeftOutlined,
  MedicineBoxOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  ProfileOutlined,
  CameraOutlined,
  NumberOutlined,
  TagOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import type { FormInstance } from "antd/lib/form/hooks/useForm";
import type { RangeValue } from "rc-picker/lib/interface";

import "./switch.scss";
import "./timePicker.scss";

import type { SessionDetail, SessionFormValues } from "interfaces/session.int";
import type { ScheduleStoreClass } from "stores/ScheduleStore";
import type { ClientStoreClass } from "stores/ClientStore";
import type { UserStoreClass } from "stores/UserStore";
import type { SessionStoreClass } from "stores/SessionStore";

import ClientRow from "../rows/ClientRow";
import ClientsSelectField from "./items/ClientsSelectField";
import SelectField from "components/forms/items/SelectField";
import SessionTypesSelectField from "./items/SessionTypesSelectField";
import SessionStatusSelectField from "./items/SessionStatusSelectField";
import UsersSelectField from "./items/UsersSelectField";
import LocationsSelectField from "./items/LocationsSelectField";
import NotesField from "./items/NotesField";
import DateField from "./items/DateField";
import TimeRangeField from "./items/TimeRangeField";
import InputField from "./items/InputField";
import { saveLocalStorage } from "components/util/localStorage";
import { AuthStoreClass } from "stores/AuthStore";

dayjs.extend(customParseFormat);

const { useBreakpoint } = Grid;

interface Props {
  form?: FormInstance;
  onSuccess?: () => void;
  onError?: () => void;
  clientStore?: ClientStoreClass;
  scheduleStore?: ScheduleStoreClass;
  userStore?: UserStoreClass;
  sessionStore?: SessionStoreClass;
  authStore?: AuthStoreClass;
  sessionCustomFields?: any;
  clientKey?: string;
}

const AddEditSession: React.FC<Props> = ({
  form,
  onSuccess,
  onError,
  clientStore,
  scheduleStore,
  sessionStore,
  authStore,
  sessionCustomFields,
  clientKey = "",
}): React.ReactElement => {
  let initialFormValues = {
    key: "",
    client: "",
    description: "",
    type: authStore?.companySettings?.DefaultSessionTypeID,
    photographer: "",
    assistant: "",
    location: "",
    notes: "",
    OnlineDescription: "",
    date: dayjs(scheduleStore?.activeDate),
    // time: [],
    status: "",
    ResultKey: "",
    confirmed: false,
    on_hold: false,
    redo: false,
    no_show: false,
    canceled: false,
  };
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [notes, setNotes] = useState<string>();
  const [sessionResultsOptions, setSessionResultsOptions] = useState([]);
  let [newTimeValues, setNewTimeValues] = useState<any>([
    dayjs(scheduleStore?.activeDateTime),
    dayjs(scheduleStore?.activeDateTime).add(1, "hour"),
  ]);

  initialFormValues.photographer = authStore?.authenticatedUser.UserKey || "";
  initialFormValues.client = clientKey;

  const isLoading: boolean = sessionStore?.isLoading || false;
  const screens = useBreakpoint();
  const formGrid = (isEnd = false, inForce = true) => {
    const marginVar = inForce ? 10 : "";
    return {
      xs: 24,
      sm: 12,
      style: {
        marginBottom: isEnd ? "" : marginVar,
      },
    };
  };

  useEffect(() => {
    return () => {
      scheduleStore?.setActiveScheduleDetail(undefined);
    };
    // eslint-disable-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form?.setFieldValue("date", scheduleStore?.activeDate);
  }, [scheduleStore?.activeDate]);

  // useEffect(() => {
  //   initialFormValues = { ...initialFormValues, time: newTimeValues };
  // }, [scheduleStore?.activeDateTime]);

  useEffect(() => {
    if (typeof scheduleStore?.activeSchedule !== "undefined") {
      fetchSchedule();
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleStore?.activeSchedule]);

  const fetchSchedule = async () => {
    try {
      var details: SessionDetail | undefined;
      var dateTime = scheduleStore?.getDateTimeRange;

      if (scheduleStore?.activeSchedule?.SessionKey !== undefined) {
        details = await sessionStore?.getSessionDetail(scheduleStore?.activeSchedule?.SessionKey);
        dateTime = {
          date: dayjs(details?.StartDateTime),
          time: [dayjs(details?.StartDateTime), dayjs(details?.EndDateTime)],
        };
        setNewTimeValues(dateTime.time);
      }
      if (details?.StatusKey) {
        const sessionResults = await sessionStore?.getSessionResults(details.StatusKey);
        setSessionResultsOptions(sessionResults || []);
      }

      setNotes(details?.Notes || "");

      const fillValues = {
        ...dateTime,
        key: details?.Key,
        client: details?.ClientKey,
        type: details?.SessionTypeKey,
        photographer: details?.PrimaryPhotographerKey,
        assistant: details?.PrimaryAssistantKey,
        description: details?.Description,
        location: details?.ResourceKey,
        status: details?.StatusKey,
        confirmed: details?.Confirmed || false,
        on_hold: details?.OnHold || false,
        redo: details?.Redo || false,
        no_show: details?.NoShow || false,
        canceled: details?.Canceled || false,
        OnlineDescription: details?.OnlineDescription || "",
        ResultKey: details?.ResultKey || "",
      };

      form?.setFieldsValue(fillValues);
    } catch (e) {
      onError && onError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleSubmit = async (values: SessionFormValues) => {
    const {
      key,
      client,
      description,
      type,
      photographer,
      assistant,
      notes,
      location,
      date,
      // time,
      status,
      confirmed,
      on_hold,
      redo,
      no_show,
      canceled,
      OnlineDescription,
    } = values;
    let time = newTimeValues;
    let convertDate = dayjs(date).format("YYYY-MM-DD");
    let convertStartTime = time ? dayjs(time[0]).format("HH:mm A") : "";
    let convertEndTime = time ? dayjs(time[1]).format("HH:mm A") : "";
    let StartDateTime = dayjs(`${convertDate}:${convertStartTime}`, "YYYY-MM-DD:HH:mm A").format(
      "YYYY-MM-DDTHH:mm:ss.SSS"
    );
    let EndDateTime = dayjs(`${convertDate}:${convertEndTime}`, "YYYY-MM-DD:HH:mm A").format(
      "YYYY-MM-DDTHH:mm:ss.SSS"
    );
    // let ClientNumber = client ? clientStore?.getClientDataFromList(client, "ClientNumber") : "";
    let TypeDescription = type ? sessionStore?.getSessionTypeFromList(type, "Description") : "";
    let StatusDescription = status
      ? (sessionStore?.getSessionStatusFromList(status, "Description") as string)
      : "";

    let data: SessionDetail = {
      Key: key ? key : null,
      ClientKey: client,
      Description: description,
      OnlineDescription: OnlineDescription,
      SessionTypeKey: type ? type : null,
      SessionTypeDescription: type ? TypeDescription?.toString() : null,
      PrimaryPhotographerKey: photographer,
      PrimaryAssistantKey: assistant,
      StatusKey: status,
      StatusDescription,
      Confirmed: confirmed || false,
      NoShow: no_show || false,
      Canceled: canceled || false,
      OnHold: on_hold || false,
      Redo: redo || false,
      StartDateTime,
      EndDateTime,
      ResourceKey: location ? location : undefined,
      // ClientNumber: ClientNumber?.toString(),
      CustomDate01: sessionCustomFields.CustomDate01,
      CustomDate02: sessionCustomFields.CustomDate02,
      CustomDate03: sessionCustomFields.CustomDate03,
      CustomDate04: sessionCustomFields.CustomDate04,
      CustomDate05: sessionCustomFields.CustomDate05,
      CustomDate06: sessionCustomFields.CustomDate06,
      CustomDate07: sessionCustomFields.CustomDate07,
      CustomDate08: sessionCustomFields.CustomDate08,
      CustomDate09: sessionCustomFields.CustomDate09,
      CustomDate10: sessionCustomFields.CustomDate10,
      CustomText01: sessionCustomFields.CustomText01,
      CustomText02: sessionCustomFields.CustomText02,
      CustomText03: sessionCustomFields.CustomText03,
      CustomText04: sessionCustomFields.CustomText04,
      CustomText05: sessionCustomFields.CustomText05,
      CustomText06: sessionCustomFields.CustomText06,
      CustomText07: sessionCustomFields.CustomText07,
      CustomText08: sessionCustomFields.CustomText08,
      CustomText09: sessionCustomFields.CustomText09,
      CustomText10: sessionCustomFields.CustomText10,
      CustomText11: sessionCustomFields.CustomText11,
      CustomText12: sessionCustomFields.CustomText12,
      CustomText13: sessionCustomFields.CustomText13,
      CustomText14: sessionCustomFields.CustomText14,
      CustomText15: sessionCustomFields.CustomText15,
      CustomText16: sessionCustomFields.CustomText16,
      CustomText17: sessionCustomFields.CustomText17,
      CustomText18: sessionCustomFields.CustomText18,
      CustomText19: sessionCustomFields.CustomText19,
      CustomText20: sessionCustomFields.CustomText20,
      CustomText21: sessionCustomFields.CustomText21,
      CustomText22: sessionCustomFields.CustomText22,
      CustomText23: sessionCustomFields.CustomText23,
      CustomText24: sessionCustomFields.CustomText24,
      CustomText25: sessionCustomFields.CustomText25,
      CustomText26: sessionCustomFields.CustomText26,
      CustomText27: sessionCustomFields.CustomText27,
      CustomText28: sessionCustomFields.CustomText28,
      CustomText29: sessionCustomFields.CustomText29,
      CustomText30: sessionCustomFields.CustomText30,
      CustomText31: sessionCustomFields.CustomText31,
      CustomText32: sessionCustomFields.CustomText32,
      CustomNotes01: sessionCustomFields.CustomNotes01,
      CustomNotes02: sessionCustomFields.CustomNotes02,
      CustomNotes03: sessionCustomFields.CustomNotes03,
      CustomNotes04: sessionCustomFields.CustomNotes04,
      CustomNotes05: sessionCustomFields.CustomNotes05,
      CustomNotes06: sessionCustomFields.CustomNotes06,
      CustomNotes07: sessionCustomFields.CustomNotes07,
      CustomNotes08: sessionCustomFields.CustomNotes08,
      CustomNotes09: sessionCustomFields.CustomNotes09,
      CustomNotes10: sessionCustomFields.CustomNotes10,
      CustomBoolean01: sessionCustomFields.CustomBoolean01,
      CustomBoolean02: sessionCustomFields.CustomBoolean02,
      CustomBoolean03: sessionCustomFields.CustomBoolean03,
      CustomBoolean04: sessionCustomFields.CustomBoolean04,
      CustomBoolean05: sessionCustomFields.CustomBoolean05,
      CustomBoolean06: sessionCustomFields.CustomBoolean06,
      CustomBoolean07: sessionCustomFields.CustomBoolean07,
      CustomBoolean08: sessionCustomFields.CustomBoolean08,
      CustomBoolean09: sessionCustomFields.CustomBoolean09,
      CustomBoolean10: sessionCustomFields.CustomBoolean10,
    };

    if (notes !== "") data = { ...data, Notes: notes };

    if (scheduleStore?.activeScheduleDetail) {
      let changedData: SessionDetail = {};
      for (let line in data) {
        let dataLine: any = data[line as keyof SessionDetail];
        if (
          dataLine !== scheduleStore?.activeScheduleDetail[line] &&
          ["CreateByUserKey"].indexOf(line) === -1
        ) {
          changedData[line] = dataLine;
        }
      }
      if (Object.keys(changedData).length === 0) {
        message.warning("No Data Changed");
        return;
      }

      data = { Key: key, ...changedData };
    } else {
      data = { ...data, ClientKey: clientStore?.activeClientKey };
    }

    const loadingMsgKey = "session-key";
    message.loading({ content: "Saving...", key: loadingMsgKey, duration: 0 });

    try {
      await sessionStore?.addSession(data).then(async (res: SessionDetail) => {
        message.success({
          content: `${
            authStore?.companySettings?.JobDescriptorSingular
              ? authStore?.companySettings?.JobDescriptorSingular
              : "Session"
          } successfully saved!`,
          key: loadingMsgKey,
          duration: 1,
        });
        // scheduleStore?.setFilterUserKey(authStore?.authenticatedUser?.UserKey);
        // scheduleStore?.setFilterResourceKey("");
        sessionStore?.getSessionsViaSearch();
        onSuccess && onSuccess();

        await clientStore?.getClient(res.ClientKey);

        if (data.Key) {
          saveLocalStorage({
            name: "Updated",
            value: `Session - ${
              clientStore?.clientInfo?.FirstName + " " + clientStore?.clientInfo?.LastName
            }${res.Description && ` - ${res.Description}`}`,
            // url: `/schedule/session/edit/${data.Key}`,
            type: {
              key: data.Key,
              value: "Session",
            },
          });
        } else {
          saveLocalStorage({
            name: "Added",
            value: `Session - ${
              clientStore?.clientInfo?.FirstName + " " + clientStore?.clientInfo?.LastName
            }${data.Description && ` - ${data.Description}`}`,
            // url: `/schedule/session/edit/${data.Key}`,
            type: {
              key: data.Key,
              value: "Session",
            },
          });
        }
      });
    } catch (e) {
      message.error({
        content: "Sorry, something has gone wrong. Please try again later.",
        key: loadingMsgKey,
        duration: 1,
      });
    }
  };

  return (
    <Form form={form} initialValues={initialFormValues} onFinish={handleSubmit}>
      <Form.Item label="Key" name="key" hidden={true}>
        <Input id="key" disabled={isLoading} />
      </Form.Item>

      {isEdit &&
      (clientStore?.activeClientKey ||
        (scheduleStore?.activeScheduleDetail && scheduleStore?.activeScheduleDetail?.ClientKey)) ? (
        <Row className="form-group">
          <Col {...formGrid(false, !screens.sm)}>
            <ClientRow clientKey={scheduleStore?.activeScheduleDetail?.ClientKey} />
          </Col>
          <Col {...formGrid(false, !screens.sm)}>
            <InputField
              prefix={<PhoneOutlined />}
              disabled={true}
              label="Client Phone Number"
              isTextarea={false}
              value={clientStore?.clientInfo?.PhoneNumber0}
              maxLength={60}
              readOnly={true}
            />
          </Col>
        </Row>
      ) : null}

      {(!isEdit ||
        (!scheduleStore?.activeScheduleDetail?.ClientKey && !clientStore?.activeClientKey)) && (
        <div className="form-group">
          <Form.Item
            name="client"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
            rules={[{ required: true, message: "Please select a client!" }]}
          >
            <ClientsSelectField form={form} disabled={isLoading || isEdit} />
          </Form.Item>
        </div>
      )}

      <Row className="form-group" gutter={20}>
        <Col {...formGrid(false, !screens.sm)}>
          <Form.Item
            name="date"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select date!",
            //   },
            // ]}
          >
            <DateField
              label="Date"
              prefix={<CalendarOutlined />}
              disabled={isLoading}
              suffixIcon={<></>}
              allowClear={false}
              defaultValue={dayjs(scheduleStore?.activeDate)}
            />
          </Form.Item>
        </Col>
        <Col {...formGrid(true)}>
          <Form.Item
            // name="time"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
            // rules={[
            //   {
            //     type: "array" as const,
            //     required: true,
            //     message: "Please select time!",
            //   },
            // ]}
          >
            <TimeRangeField
              label="Time"
              id={"time"}
              prefix={<ClockCircleOutlined />}
              disabled={isLoading}
              suffixIcon={<></>}
              allowClear={false}
              format="hh:mm A"
              use12Hours={true}
              defaultValue={newTimeValues}
              value={newTimeValues}
              onCalendarChange={(values: RangeValue<Dayjs>) => {
                if (values?.[0] === null || values?.[1] === null) return;
                if (values?.[0].format("hh:mm A") === values?.[1].format("hh:mm A")) {
                  setNewTimeValues([values?.[0], dayjs(values?.[0]).add(1, "hour")]);
                  form?.setFieldsValue({
                    ...form.getFieldsValue(),
                    time: [values?.[0], dayjs(values?.[0]).add(1, "hour")],
                  });
                  setNewTimeValues([values?.[0], dayjs(values?.[0]).add(1, "hour")]);
                } else {
                  setNewTimeValues(values);
                  form?.setFieldsValue({
                    ...form.getFieldsValue(),
                    time: values,
                  });
                }
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <div className="form-group">
        <Form.Item
          name="description"
          hasFeedback={isLoading}
          validateStatus={isLoading ? "validating" : undefined}
        >
          <InputField
            prefix={<AlignLeftOutlined />}
            disabled={isLoading}
            label="Description"
            maxLength={60}
          />
        </Form.Item>

        <Row gutter={20}>
          <Col {...formGrid()}>
            <Form.Item
              name="type"
              rules={[{ required: true, message: "Please select a type!" }]}
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
            >
              <SessionTypesSelectField disabled={isLoading} />
            </Form.Item>
          </Col>
          <Col {...formGrid()}>
            <Form.Item
              name="photographer"
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
              rules={[{ required: true, message: "Please select a photographer!" }]}
            >
              <UsersSelectField
                disabled={isLoading}
                prefix={<CameraOutlined />}
                type="photographer"
                label={`${
                  authStore?.companySettings?.JobUser1DescriptorSingular
                    ? authStore?.companySettings?.JobUser1DescriptorSingular
                    : "Assistant"
                }`}
              />
            </Form.Item>
          </Col>
          <Col {...formGrid()}>
            <Form.Item
              name="assistant"
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
            >
              <UsersSelectField
                disabled={isLoading}
                prefix={<MedicineBoxOutlined />}
                type="assistant"
                label={`${
                  authStore?.companySettings?.JobUser2DescriptorSingular
                    ? authStore?.companySettings?.JobUser2DescriptorSingular
                    : "Assistant"
                }`}
              />
            </Form.Item>
          </Col>
          <Col {...formGrid()}>
            <Form.Item
              name="location"
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
            >
              <LocationsSelectField
                disabled={isLoading}
                label={`${
                  authStore?.companySettings?.JobResourceDescriptorSingular
                    ? authStore?.companySettings?.JobResourceDescriptorSingular
                    : "Location"
                }`}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="notes"
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
            >
              <NotesField noteType="session" disabled={isLoading} notes={notes} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="OnlineDescription"
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
            >
              <InputField
                prefix={<AlignLeftOutlined />}
                disabled={isLoading}
                label="Online Description"
              />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <div className="form-group">
        <Row align="middle">
          <Col
            xs={24}
            md={12}
            style={{
              paddingRight: isEdit && scheduleStore?.activeScheduleDetail?.SessionNumber ? 10 : 0,
            }}
          >
            <Form.Item
              name="status"
              rules={[{ required: true, message: "Please select status!" }]}
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
            >
              <SessionStatusSelectField disabled={isLoading} />
            </Form.Item>
          </Col>
          {isEdit && scheduleStore?.activeScheduleDetail?.SessionNumber && (
            <Col {...formGrid(false, !screens.sm)}>
              <Form.Item hasFeedback={isLoading}>
                <InputField
                  prefix={<NumberOutlined />}
                  disabled={true}
                  label={
                    authStore?.companySettings?.JobDescriptorSingular
                      ? authStore?.companySettings.JobDescriptorSingular + " Number"
                      : "Session Number"
                  }
                  value={scheduleStore?.activeScheduleDetail?.SessionNumber}
                />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} md={12}>
            <Form.Item
              name="ResultKey"
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
            >
              <SelectField
                value={scheduleStore?.activeScheduleDetail?.ResultKey}
                options={sessionResultsOptions.map((result: any) => {
                  return {
                    label: result.Description,
                    value: result.Key,
                  };
                })}
                prefix={<TagOutlined />}
                label={`${
                  authStore?.companySettings.JobDescriptorSingular
                    ? authStore?.companySettings.JobDescriptorSingular
                    : "Session"
                } Result`}
                showSearch={false}
                showArrow={false}
                suffixIcon={<></>}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <Row className="form-group">
        <Col style={{ width: "3.5rem", paddingTop: 6 }}>
          <ProfileOutlined style={{ fontSize: 21 }} />
        </Col>
        <Col style={{ flexGrow: 1 }}>
          <Form.Item
            label="Cofirmed"
            name="confirmed"
            valuePropName="checked"
            className="checkedInline"
            colon={false}
          >
            <Switch disabled={isLoading} loading={isLoading} />
          </Form.Item>
          <Form.Item
            label="On Hold"
            name="on_hold"
            valuePropName="checked"
            className="checkedInline"
            colon={false}
          >
            <Switch disabled={isLoading} loading={isLoading} />
          </Form.Item>
          <Form.Item
            label="Redo"
            name="redo"
            valuePropName="checked"
            className="checkedInline"
            colon={false}
          >
            <Switch disabled={isLoading} loading={isLoading} />
          </Form.Item>
          <Form.Item
            label="No Show"
            name="no_show"
            valuePropName="checked"
            className="checkedInline"
            colon={false}
          >
            <Switch disabled={isLoading} loading={isLoading} />
          </Form.Item>
          <Form.Item
            label="Canceled"
            name="canceled"
            valuePropName="checked"
            className="checkedInline"
            colon={false}
          >
            <Switch disabled={isLoading} loading={isLoading} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default inject(
  "clientStore",
  "scheduleStore",
  "sessionStore",
  "authStore"
)(observer(AddEditSession));
