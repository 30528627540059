import React from "react";
import { Route as RDOMRoute } from "react-router-dom";
import ROUTE_METADATA from "../configs/route-based-metadata.json";
import ProtectedRoute from "./ProtectedRoute";
import { inject, observer } from "mobx-react";

/**
 * Custom route component to render metadata dynamically
 * @param props
 */
const Route: React.FC<any> = (props) => {
  // Try to fetch metadata based on the current url
  const metaData: any = ROUTE_METADATA.find(
    (route) => window.location.pathname.split("/")[1] === route.path
  );
  if (metaData && metaData.title) {
    document.title = metaData.title;
    props.routeMetadataStore.updateCurrentRouteMetadata({
      title: metaData.title,
      path: metaData.path,
    });
  } else {
    // Default title
    document.title = "myStratus";
    props.routeMetadataStore.updateCurrentRouteMetadata({
      title: "myStratus",
      path: "*",
    });
  }
  return <>{props.protected ? <ProtectedRoute {...props} /> : <RDOMRoute {...props} />}</>;
};

export default inject("routeMetadataStore")(observer(Route));
