import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Layout, Row, Col, Grid, message, Table, Card } from "antd";

import type { ClockStoreClass } from "stores/ClockStore";
import ClockInOut from "components/clock/ClockInOut";
import CurrentClock from "components/clock/CurrentClock";
import WeekClock from "components/clock/WeekClock";
// import HistoryClock from "components/clock/HistoryClock";

import "./ClockPage.scss";
import CustomHeader from "components/header/CustomHeader";
import dayjs from "dayjs";
import { TimeClockEntries } from "interfaces/clock.int";
import ClockDrawerForm from "components/drawers/ClockDrawer/ClockDrawerForm";

const { Content } = Layout;
const { useBreakpoint } = Grid;

interface Props {
  clockStore?: ClockStoreClass;
}

const ClockPage: React.FC<Props> = ({ clockStore }) => {
  const screens = useBreakpoint();
  const [recentClockData, setRecentClockData] = useState<any>();

  useEffect(() => {
    getClock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClock = async () => {
    try {
      await clockStore?.getClockList();
    } catch (e) {
      message.warning("Unable to establish a connection. Try logging in again.");
    }
  };

  useEffect(() => {
    setRecentClockData(clockStore?.clockList?.TimeClockEntries);
  }, [clockStore?.clockList?.TimeClockEntries]);

  const timeConvert = (n: any) => {
    let num = n;
    let hours = num / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    if (!rhours) return rminutes + ` minute${rminutes > 1 ? "s" : ""}`;
    if (!rminutes) return rhours + ` hour${rhours > 1 ? "s" : ""}`;
    return (
      rhours + ` hour${rhours > 1 ? "s " : " "}` + rminutes + ` minute${rminutes > 1 ? "s" : ""}`
    );
  };

  const recentClockColumns = [
    {
      title: "Time In",
      dataIndex: "StartTime",
      key: "timeIn",
      render: (time: any) => dayjs(time).format("MM/DD/YYYY hh:mm A"),
    },
    {
      title: "Time Out",
      dataIndex: "EndTime",
      key: "timeOut",
      render: (time: any, record: any) => {
        if (record.TotalTime === -1) return "--";
        else return dayjs(time).format("MM/DD/YYYY hh:mm A");
      },
    },
    {
      title: "Total",
      dataIndex: "TotalTime",
      key: "total",
      render: (minutes: any) => {
        if (minutes === -1) return "--";
        else return timeConvert(minutes);
      },
    },
    {
      title: "Time Code",
      dataIndex: "TimeCode",
      key: "timeCode",
    },
  ];

  const recentClockDataSource = recentClockData;

  const onRowClickHanlder = (recordKey: string) => {
    let record = clockStore?.clockList?.TimeClockEntries.filter(
      (record: TimeClockEntries) => record.Key == recordKey
    )[0];
    clockStore?.setActiveRecentTime(record);
    clockStore?.setSelectToggle(true);
  };

  return (
    <Content className="main-content">
      <CustomHeader title="Clock In/Out" />
      <div className="main-content-pads">
        <Row gutter={screens.sm ? 20 : 10}>
          <Col
            xs={24}
            style={{
              paddingBottom: screens.sm ? 20 : 10,
            }}
          >
            <CurrentClock />
            <ClockInOut />
          </Col>
          <Col
            xs={24}
            style={{
              paddingBottom: screens.sm ? 20 : 10,
            }}
          >
            <WeekClock />
          </Col>
          {/* <Col
            xs={24}
            sm={12}
            style={{
              paddingBottom: screens.sm ? 20 : 10,
            }}
          >
            <HistoryClock />
          </Col> */}
          <Col
            xs={24}
            style={{
              paddingBottom: screens.sm ? 20 : 10,
            }}
          >
            <Card headStyle={{ backgroundColor: "#bed1df", color: "white" }} title="Recent Time">
              <Table
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      onRowClickHanlder(record.Key);
                    }, // click row
                  };
                }}
                columns={recentClockColumns}
                dataSource={recentClockDataSource}
                pagination={false}
                rowKey="Key"
              />
            </Card>
          </Col>
        </Row>
      </div>
      <ClockDrawerForm />
    </Content>
  );
};

export default inject("clockStore")(observer(ClockPage));
