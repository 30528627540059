import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Col, Row, Typography } from "antd";
import dayjs from "dayjs";
import type { ListProps } from "antd/lib/list";

import { MessageStoreClass } from "stores/MessageStore";
import { CalendarOutlined, MailOutlined, MessageOutlined } from "@ant-design/icons";
import ViewEmail from "components/forms/ViewEmail";
import ViewMessage from "components/forms/ViewMessage";

interface Props extends ListProps<any> {
  messageStore?: MessageStoreClass;
}

const { Title, Text } = Typography;

const MessagesList: React.FC<Props> = ({ messageStore, ...props }) => {

  const [showMessageDetail, setShowMessageDetail] = useState(false);
  const [selectedKey, setSelectedKey] = useState('');

  return (
    <>
      <Row className="messagesGrid" gutter={15}>
        <Col xs={24} sm={6} className="Message__List">
          {
            messageStore?.sortedMessages.map((message: any) => {
              return (
                <Col xs={24} className="messagesGrid__Item">
                  <Col className={`Messages ${selectedKey === message.Key ? 'Selected' : ''}`}
                    onClick={() => {
                      setShowMessageDetail(false);
                      setSelectedKey(message.Key);
                      if (message.Type === "Text") {
                        // SMS Text
                        messageStore?.setEmailDetails(undefined);
                        messageStore?.getMessage(message.Key);
                      } else {
                        // Email
                        messageStore?.setSmsDetails(undefined);
                        messageStore?.getEmail(message.Key);
                      }
                      // messageStore?.setSelectToggle(true);
                      setShowMessageDetail(true);
                    }}>
                    <Col className={`Messages__Icon ${message.Type === "Text" ? 'text' : 'email'}`}>
                      {message.Type === "Text" ? <MessageOutlined /> : <MailOutlined />}
                    </Col>
                    <Col className="Messages__Content">
                      <Col className="Messages__Content--Head">
                        <Title level={4}>{message.Subject || "..."}</Title>
                        <Text className="Messages__Date">
                          <CalendarOutlined />{dayjs(message.CompleteDate).format(
                            "MM-DD-YYYY"
                          )}
                        </Text>
                      </Col>
                      <Text className="Messages__Description">{message.Description}</Text>
                    </Col>
                  </Col>
                </Col>
              )
            })
          }
        </Col>
        {showMessageDetail ?
          <>{messageStore?.emailDetails ? <ViewEmail setShowMessageDetail={setShowMessageDetail} setSelectedKey={setSelectedKey} /> : <ViewMessage setSelectedKey={setSelectedKey} setShowMessageDetail={setShowMessageDetail} />}</>
          : null}
      </Row>
      {/* <List
        dataSource={messageStore?.sortedMessages}
        renderItem={(item: any) => {
          return (
            <List.Item
              className="has-action"
              onClick={() => {
                if (item.Type === "Text") {
                  // SMS Text
                  messageStore?.getMessage(item.Key);
                } else {
                  // Email
                  messageStore?.getEmail(item.Key);
                }
                messageStore?.setSelectToggle(true);
              }}
            >
              <List.Item.Meta
                className="list-meta-float"
                title={item.Subject || "..."}
                description={<>
                  <Tag color={`${item.Type === "Text" ? '#2db7f5' : '#108ee9'}`}>{item.Type}</Tag>
                  {dayjs(item.CompleteDate).format(
                    "YYYY-MM-DD"
                  )} - {item.Description}</>}
              />
            </List.Item>
          );
        }}
        {...props}
      /> */}
    </>
  );
};

export default inject("messageStore")(observer(MessagesList));
