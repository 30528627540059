import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Card, Grid, Select, Space, Tabs, Typography } from "antd";
import { TaskStoreClass } from "stores/TaskStore";
import { AuthStoreClass } from "stores/AuthStore";

import "./WorkFlowPage.scss";
import { ClientStoreClass } from "stores/ClientStore";
import { UserStoreClass } from "stores/UserStore";
import CustomHeader from "components/header/CustomHeader";
import { Content } from "antd/lib/layout/layout";
import classnames from "classnames";
import { SessionStoreClass } from "stores/SessionStore";
import TabPane from "@ant-design/pro-card/lib/components/TabPane";
import {
  CameraOutlined,
  CreditCardOutlined,
  FileDoneOutlined,
  ProjectOutlined,
  SoundOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import TimeLine from "./Components/Timeline/Timeline";
import StatusBucket from "./Components/StatusBucket/StatusBucket";
import { TaskTypes } from "interfaces/task.int";
import TaskStatusBucket from "./Components/StatusBucket/TaskStatusBucket";
import InvoiceStatusBucket from "./Components/StatusBucket/InvoiceStatusBucket";
import ClientStatusBucket from "./Components/StatusBucket/ClientStatusBucket";
const { useBreakpoint } = Grid;
const { Text } = Typography;

interface Props {
  taskStore?: TaskStoreClass;
  authStore?: AuthStoreClass;
  clientStore?: ClientStoreClass;
  userStore?: UserStoreClass;
  sessionStore?: SessionStoreClass;
}

const WorkFlowPage: React.FC<Props> = ({
  taskStore,
  authStore,
  clientStore,
  userStore,
  sessionStore,
}) => {
  const screens = useBreakpoint();
  const [tabKey, setTabKey] = useState("task");
  const [selectedSessionType, SetSelectedSessionType] = useState<any>();
  const [selectedTaskType, SetSelectedTaskType] = useState<any>();

  const tabDropdownOption = (
    <Space className="tabSelectArea">
      <Text>Session Type: </Text>
      <Select
        className="tabSelectBox"
        options={sessionStore?.selectSessionList}
        showSearch
        placeholder="Select Session Type"
        filterOption={(input, option) => {
          let isFound: number | undefined = option?.label
            ?.toString()
            .toLowerCase()
            .indexOf(input?.toLowerCase());
          return isFound !== undefined && isFound >= 0 ? true : false;
        }}
        value={selectedSessionType}
        onChange={(type) => {
          SetSelectedSessionType(type);
        }}
      />
    </Space>
  );

  const tabDropdownOptionTasks = (
    <Space className="tabSelectArea">
      <Text>Task Type: </Text>
      <Select
        className="tabSelectBox"
        options={taskStore?.taskTypes.map((taskType: TaskTypes) => {
          return {
            label: taskType.Description || "",
            value: taskType.Key || "",
          };
        })}
        value={selectedTaskType}
        showSearch
        placeholder="Select Task Type"
        filterOption={(input, option) => {
          let isFound: number | undefined = option?.label
            ?.toString()
            .toLowerCase()
            .indexOf(input?.toLowerCase());
          return isFound !== undefined && isFound >= 0 ? true : false;
        }}
        onChange={(type) => {
          taskTypeSelectHandler(type);
        }}
      />
    </Space>
  );

  useEffect(() => {
    if (taskStore?.taskTypes) SetSelectedTaskType(taskStore?.taskTypes[0]?.Key);
  }, [taskStore?.taskTypes]);

  useEffect(() => {
    if (sessionStore?.selectSessionList) {
      SetSelectedSessionType(sessionStore?.selectSessionList[0].value);
    }
  }, [sessionStore?.selectSessionList]);

  useEffect(() => {
    if (tabKey === "session") {
      SetSelectedTaskType(sessionStore?.selectSessionList[0]?.value);
    }
  }, [tabKey]);

  const taskTypeSelectHandler = (type: any) => {
    SetSelectedTaskType(type);
  };

  const onTabChange = (key: string) => {
    setTabKey(key);
  };

  const loadTypeHandler = async () => {
    await taskStore?.getTaskTypes();
  };

  useEffect(() => {
    loadTypeHandler();
  }, []);

  return (
    <Content className="main-content">
      <CustomHeader title="WorkFlow" />
      <div
        className={classnames({
          "main-content-pads task-page": screens.md,
        })}
      >
        <Card>
          <Tabs
            tabBarExtraContent={
              tabKey === "session"
                ? tabDropdownOption
                : tabKey === "task"
                ? tabDropdownOptionTasks
                : null
            }
            type="card"
            defaultActiveKey="task"
            onChange={onTabChange}
            activeKey={tabKey}
          >
            <TabPane
              tab={
                <>
                  <TeamOutlined />
                  Clients
                </>
              }
              key="client"
            >
              <TimeLine tabKey={tabKey} />
              <ClientStatusBucket />
            </TabPane>
            <TabPane
              tab={
                <>
                  <CameraOutlined />
                  Sessions
                </>
              }
              key="session"
            >
              <TimeLine tabKey={tabKey} />
              <StatusBucket selectedSessionType={selectedSessionType} />
            </TabPane>
            <TabPane
              tab={
                <>
                  <CreditCardOutlined />
                  Invoices
                </>
              }
              key="invoice"
            >
              <TimeLine tabKey={tabKey} />
              <InvoiceStatusBucket />
            </TabPane>
            <TabPane
              tab={
                <>
                  <FileDoneOutlined />
                  Tasks
                </>
              }
              key="task"
            >
              <TimeLine tabKey={tabKey} />
              <TaskStatusBucket selectedTaskType={selectedTaskType} />
            </TabPane>
            <TabPane
              tab={
                <>
                  <ProjectOutlined />
                  Production
                </>
              }
              key="production"
            >
              Content of Production
            </TabPane>
            <TabPane
              tab={
                <>
                  <SoundOutlined />
                  Marketing Campaigns
                </>
              }
              key="marketingCampaigns"
              disabled
            >
              Content of Marketing Campaigns.
            </TabPane>
          </Tabs>
        </Card>
      </div>
    </Content>
  );
};

export default inject(
  "taskStore",
  "authStore",
  "clientStore",
  "userStore",
  "sessionStore"
)(observer(WorkFlowPage));
