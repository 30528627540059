import { observable, action, computed, makeObservable } from "mobx";
import { create, persist } from "mobx-persist";
import axios, { AxiosResponse, AxiosError } from "axios";
import dayjs from "dayjs";
import update from "immutability-helper";

import type { NotificationList } from "interfaces/notification.int";
import AuthStore from "./AuthStore";
import { saveLocalStorage } from "components/util/localStorage";

export class NotificationStoreClass {
  @persist("list") notificationList: Array<NotificationList> = [];
  selectedList: Array<string> = [];
  isLoading: boolean = false;
  isDeleteToggleActive: boolean = false;

  constructor() {
    makeObservable(this, {
      notificationList: observable,
      sortNotificationList: computed,
      addNotification: action,
      isLoading: observable,
      isDeleteToggleActive: observable,
      setDeleteToggle: action,
      selectedList: observable,
      setSelectedList: action,
      deleteNotification: action,
      cleanUp: action,
    });
  }

  async getNotificationList(userKey?: string) {
    this.isLoading = true;

    if (!userKey) {
      userKey = AuthStore?.authenticatedUser?.UserKey;
    }

    return await axios({
      method: "GET",
      url: `/Notifications`,
      params: {
        userKey,
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.notificationList = res.data;
          this.isLoading = false;
          saveLocalStorage({
            name: "Synced",
            value: `Notifications`,
          });
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async addNotification(notification: any) {
    this.isLoading = true;

    if (!notification?.UserKey) {
      notification = {
        ...notification,
        UserKey: AuthStore?.authenticatedUser?.UserKey,
      };
    }

    return await axios({
      method: "PUT",
      url: "/Notification",
      data: notification,
    })
      .then(
        action((res: AxiosResponse) => {
          this.notificationList = [...this.notificationList, res.data];
          this.isLoading = false;
        })
      )
      .catch((err: AxiosError) => {
        console.log(err.message);
        this.isLoading = false;
      });
  }

  get sortNotificationList() {
    const sortedData: Array<NotificationList> = this.notificationList
      .filter((data: NotificationList) => {
        return dayjs(data.VisibleStartDateTime).valueOf() <= dayjs().valueOf();
      })
      .sort((a, b) => {
        var nameA = a?.VisibleStartDateTime || 0;
        var nameB = b?.VisibleStartDateTime || 0;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
      .sort((a, b) => {
        return a.Urgent === b.Urgent ? 0 : a.Urgent ? -1 : 1;
      });
    return sortedData;
  }

  setDeleteToggle(toggle?: boolean) {
    this.isDeleteToggleActive = toggle ? toggle : !this.isDeleteToggleActive;
  }

  setSelectedList(key: string, action: "add" | "remove") {
    if (action === "add") {
      this.selectedList = [...this.selectedList, key];
      return;
    }

    const findIndex = this.selectedList.findIndex((v) => v === key);
    this.selectedList = update(this.selectedList, {
      $splice: [[findIndex, 1]],
    });
  }

  async deleteNotification(key: string) {
    this.isLoading = true;
    return await axios({
      method: "DELETE",
      url: `/Notification/${key}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          const findIndex = this.notificationList.findIndex((v: NotificationList) => v.Key === key);
          this.notificationList = update(this.notificationList, {
            $splice: [[findIndex, 1]],
          });
        })
      )
      .catch((err: AxiosError) => {
        console.log(err.message);
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async deleteNotifications(keys?: Array<string>) {
    this.isLoading = true;
    keys = keys ? keys : this.selectedList;
    if (keys.length <= 0) return;

    let promises = [];
    for (var i = 0; i < keys.length; i++) {
      promises.push(this.deleteNotification(keys[i]));
    }
    return await Promise.all(promises)
      .then(() => {
        this.isLoading = false;
        this.isDeleteToggleActive = false;
      })
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  cleanUp() {
    this.notificationList = [];
  }
}

const hydrate = create({});

const NotificationStore = new NotificationStoreClass();

export default NotificationStore;

hydrate("notification", NotificationStore).then(() => {});
