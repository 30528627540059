import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Col, Timeline, Typography } from "antd";
import { TaskStoreClass } from "stores/TaskStore";
import { AuthStoreClass } from "stores/AuthStore";

import "./Timeline.scss";
import { ClientStoreClass } from "stores/ClientStore";
import { UserStoreClass } from "stores/UserStore";
import { SessionStoreClass } from "stores/SessionStore";

import SessionTimeLine from "./SessionTimeLine.json";
import ClientTimeLine from "./ClientTimeLine.json";
// import TaskTimeLine from "./TaskTimeLine.json";
// import InvoiceTimeLine from "./InvoiceTimeLine.json";
import { ThunderboltFilled } from "@ant-design/icons";

const { Text } = Typography;

interface Props {
    taskStore?: TaskStoreClass;
    authStore?: AuthStoreClass;
    clientStore?: ClientStoreClass;
    userStore?: UserStoreClass;
    sessionStore?: SessionStoreClass;
    tabKey?: string;
}

const TimeLine: React.FC<Props> = ({ taskStore, authStore, clientStore, userStore, sessionStore, tabKey }) => {

    const [timeLineData, setTimeLineData] = useState<any>();

    const triggerHandler = () => {
        alert('Trigger Clicked');
    }


    useEffect(() => {
        if (tabKey === "client") {
            setTimeLineData(ClientTimeLine);
        } else {
            setTimeLineData(SessionTimeLine);
        }
        // console.log(timeLineData);
    }, [tabKey]);


    return (
        <Col className="worlflowTimeline">
            <Timeline >
                {timeLineData?.map((timeline: any) => {
                    return (
                        <Timeline.Item key={timeline.key} dot={timeline.dot == "0" ? `Day of ${tabKey}` : timeline.dot == "Created" ? `${tabKey} ` + timeline.dot : timeline.dot} className={timeline.dot == "0" ? 'day-of-session' : timeline.dot == "Created" ? 'session-created' : ''}>
                            {
                                timeline.triggers.map((trigger: any) => {
                                    return (
                                        <Text key={trigger.id} className="trigger" onClick={() => { triggerHandler() }}><ThunderboltFilled /> {
                                            trigger.name
                                        }</Text>
                                    )
                                })
                            }
                        </Timeline.Item>
                    )
                })}
            </Timeline>
        </Col>
    );
};

export default inject("taskStore", "authStore", "clientStore", "userStore", "sessionStore")(observer(TimeLine));