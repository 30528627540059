import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Space, Button, List, message, Col, Typography } from "antd";
import type { InputProps } from "antd/lib/input";
import {
  FileDoneOutlined,
  PlusOutlined,
  UnorderedListOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

import InputField from "./InputField";
import type { PhoneCallStoreClass } from "stores/PhoneCallStore";
import type { AppointmentStoreClass } from "stores/AppointmentStore";
import type { SessionStoreClass } from "stores/SessionStore";
import type { ScheduleStoreClass } from "stores/ScheduleStore";
import type { ClientStoreClass } from "stores/ClientStore";

import "./Input.scss";
import "./NotesField.scss";

const { Text } = Typography;

interface Props extends InputProps {
  noteType?: "appointment" | "session" | "call" | "client";
  notes?: string;
  phoneCallStore?: PhoneCallStoreClass;
  sessionStore?: SessionStoreClass;
  appointmentStore?: AppointmentStoreClass;
  scheduleStore?: ScheduleStoreClass;
  clientStore?: ClientStoreClass;
  statusNotes?: string;
}

const NotesField: React.FC<Props> = React.forwardRef(
  (
    {
      noteType,
      notes,
      phoneCallStore,
      sessionStore,
      appointmentStore,
      scheduleStore,
      clientStore,
      statusNotes,
      ...props
    }: Props,
    _ref
  ): React.ReactElement => {
    const [openList, setOpenList] = useState<boolean>(false);
    const [itemized, setItemized] = useState<string[]>([]);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [openStatusList, setOpenStatusList] = useState<boolean>(false);

    useEffect(() => {
      setItemized(
        notes
          ? notes
            ?.toString()
            ?.replace(/\r/g, "")
            .split(/\n/)
            .filter((v: string) => {
              return v !== "";
            })
          : []
      );
    }, [notes]);

    useEffect(() => {
      let key;
      if (noteType === "session") {
        key = scheduleStore?.activeSchedule?.SessionKey;
      } else if (noteType === "call") {
        key = phoneCallStore?.activePhoneCall?.Key;
      } else if (noteType === "client") {
        key = clientStore?.activeClientKey;
      } else {
        key = scheduleStore?.activeSchedule?.AppointmentKey;
      }

      if (key) setIsEdit(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleList = () => {
      setOpenList(!openList);
    };

    const toggleStatusList = () => {
      setOpenStatusList(!openStatusList);
    };

    const addNote = async () => {
      if (!props?.value) return;

      try {
        if (noteType === "session") {
          await sessionStore?.addSession({
            Key: scheduleStore?.activeSchedule?.SessionKey,
            Notes: props?.value.toString() || "",
          });
        } else if (noteType === "call") {
          await phoneCallStore?.addPhoneCall({
            Key: phoneCallStore?.activePhoneCall?.Key,
            Notes: props?.value.toString() || "",
          });
        } else if (noteType === "client") {
          await clientStore?.editClient({
            Key: clientStore?.activeClientKey,
            Notes: props?.value.toString() || "",
          });
        } else {
          await appointmentStore?.addAppointment({
            Key: scheduleStore?.activeSchedule?.AppointmentKey,
            Notes: props?.value.toString() || "",
          });
        }
        message.success(`Note added to ${noteType}!`);
        const item =
          dayjs().format("M/D/YYYY h:mm:ss A") + " - " + props?.value;
        setItemized([item, ...itemized]);
      } catch (e) { }
    };

    return (
      <>
        <InputField
          prefix={<FileDoneOutlined />}
          disabled={props?.disabled}
          label="Notes"
          {...props}
        />
        {isEdit && (
          <div style={{ paddingLeft: "3.75rem", marginTop: 10 }}>
            <Space>
              {props.value && (
                <Button
                  type="primary"
                  style={{ marginRight: 3 }}
                  icon={<PlusOutlined />}
                  disabled={props?.disabled}
                  onClick={() => addNote()}
                >
                  Add note
                </Button>
              )}
              <Button
                icon={openList ? <CloseOutlined /> : <UnorderedListOutlined />}
                onClick={toggleList}
                style={{ float: "right" }}
              >
                {openList ? "Close" : "View"} notes
              </Button>
              {statusNotes &&
                <Button
                  icon={openStatusList ? <CloseOutlined /> : <UnorderedListOutlined />}
                  onClick={toggleStatusList}
                  style={{ float: "right" }}
                >
                  {openStatusList ? "Close" : "View"} status notes
                </Button>
              }
            </Space>

            {openStatusList &&
              <Col className="statusNote">
                <Text>{statusNotes}</Text>
              </Col>
            }

            {openList && (
              <List
                className="list-notes"
                dataSource={itemized}
                renderItem={(item) => {
                  return <List.Item>{item}</List.Item>;
                }}
              />
            )}
          </div>
        )}
      </>
    );
  }
);

export default inject(
  "phoneCallStore",
  "appointmentStore",
  "sessionStore",
  "scheduleStore",
  "clientStore"
)(observer(NotesField));
