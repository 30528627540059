import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Form, Button, Row, Col, Layout, Card, Typography, Image } from "antd";
import { LockOutlined } from "@ant-design/icons";
import InputField from "components/forms/items/InputField";
import "pages/Auth/LoginPage.scss";
import { AuthStoreClass } from "stores/AuthStore";
const { Title } = Typography;
const { Content } = Layout;

interface Props {
  authStore?: AuthStoreClass;
}

const ResetPasswordPage: React.FC<Props> = ({ authStore }) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    if (authStore?.isAuthenticated) {
      authStore.logout();
    }
  }, []);

  const handleOnSubmit = async (values: any) => {
    const { NewPassword } = values;
    await authStore?.resetPassword(NewPassword, params.accountName, params.id);
  };

  return (
    <Content className="login-module">
      <div className="container">
        <Card>
          <Image width={160} src="/assets/logo.png" alt="logo" className="logo" preview={false} />
          <Title level={1}>Reset Your Password</Title>
          <p></p>
          <Form onFinish={handleOnSubmit} className="login-form" requiredMark="optional">
            <Form.Item
              name="NewPassword"
              hasFeedback={authStore?.isLoading}
              rules={[{ required: true, message: "Password is required" }]}
              validateStatus={authStore?.isLoading ? "validating" : undefined}
            >
              <InputField
                prefix={<LockOutlined />}
                disabled={authStore?.isLoading}
                label="NewPassword"
                className="form-control-rounded"
                type="password"
              />
            </Form.Item>
            <Row align="middle" className="action">
              <Col className="action-btn">
                <Button
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  className="btn-action"
                  loading={authStore?.isLoading}
                  size="large"
                  block
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </Content>
  );
};

export default inject("authStore")(observer(ResetPasswordPage));
