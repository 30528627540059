import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Button, Modal, Form, Col, Popover, Radio, Checkbox } from "antd";
import { FilterOutlined, UploadOutlined, PhoneOutlined } from "@ant-design/icons";
import SMSDrawer from "pages/Messages/SMSDrawer";

import type { MessageStoreClass } from "stores/MessageStore";
import FilterMessage from "components/forms/FilterMessage";
import { RadioChangeEvent } from "antd/lib/radio";
import CustomHeader from "components/header/CustomHeader";

interface Props {
  messageStore?: MessageStoreClass;
}

const ActionsMessages: React.FC<Props> = ({ messageStore }: Props): React.ReactElement => {
  const [isShowFilterModal, setIsShowFilterModal] = useState<boolean>(false);
  const [isShowFilterPopover, setIsShowFilterPopover] = useState<boolean>(false);
  const [openSMSDrawer, setOpenSMSDrawer] = useState<boolean>(false);
  const [filterForm] = Form.useForm();

  const toggleFilterModal = () => {
    setIsShowFilterModal(!isShowFilterModal);
  };

  const handleSubmitFilter = () => {
    filterForm.submit();
    setIsShowFilterModal(false);
  };

  const handleRadioChange = (e: RadioChangeEvent) => {
    messageStore?.setIncomingFilter(e.target.value);
  };

  const radioStyle = {
    // display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  return (
    <>
      <CustomHeader title="Messages">
        <Col style={{ marginLeft: "auto" }}>
          <Button
            icon={<PhoneOutlined />}
            type="text"
            onClick={() => {
              setOpenSMSDrawer(true);
            }}
          >
            Send New SMS
          </Button>
        </Col>
        <Col style={{ marginLeft: "1rem" }}>
          <Checkbox
            defaultChecked={messageStore?.includeSMS}
            onChange={(e: any) => {
              messageStore?.setIncludeSMS(e.target.checked);
            }}
          >
            Show Text Messages
          </Checkbox>
        </Col>
        <Col style={{ marginLeft: "1rem" }}>
          <Button icon={<FilterOutlined />} type="text" onClick={toggleFilterModal}>
            Filter
          </Button>
        </Col>
        <Col style={{ marginLeft: "1rem" }}>
          <Popover
            content={
              <Radio.Group
                style={{ maxWidth: "8rem" }}
                onChange={handleRadioChange}
                value={messageStore?.isIncomingFilter}
              >
                <Radio style={radioStyle} value={true}>
                  Incoming
                </Radio>
                <Radio style={radioStyle} value={false}>
                  Outgoing
                </Radio>
              </Radio.Group>
            }
            trigger="click"
            visible={isShowFilterPopover}
            onVisibleChange={(visible: boolean) => setIsShowFilterPopover(visible)}
          >
            <Button
              icon={<UploadOutlined />}
              type="text"
              onClick={() => setIsShowFilterPopover(true)}
            />
          </Popover>
        </Col>
      </CustomHeader>
      <Modal
        title="Filter by:"
        visible={isShowFilterModal}
        onCancel={toggleFilterModal}
        onOk={handleSubmitFilter}
      >
        <FilterMessage form={filterForm} />
      </Modal>
      <SMSDrawer visible={openSMSDrawer} setOpenSMSDrawer={setOpenSMSDrawer} />
    </>
  );
};

export default inject("messageStore")(observer(ActionsMessages));
