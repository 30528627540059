import React from "react";
import { inject, observer } from "mobx-react";
import type { SelectProps } from "antd/lib/select";
import { TeamOutlined } from "@ant-design/icons";

import SelectField from "./SelectField";
import type { AuthStoreClass } from "stores/AuthStore";
import type { UserStoreClass } from "stores/UserStore";

import "./Input.scss";

interface Props extends SelectProps<any> {
  type?: "photographer" | "assistant";
  label?: string;
  prefix?: React.ReactNode;
  authStore?: AuthStoreClass;
  userStore?: UserStoreClass;
  isDepartment?:Boolean;
}

const UsersSelectField: React.FC<Props> = React.forwardRef(
  (
    { type, label, prefix, authStore, userStore, isDepartment, ...props }: Props,
    _ref
  ): React.ReactElement => {
    let userList: any;
    if (type === "photographer") {
      userList = userStore?.selectPhotographerList;
    } else if (type === "assistant") {
      userList = userStore?.selectAssistantList;
    } else {
      if (isDepartment) {
        userList = userStore?.selectUserListByDepartment;
      } else userList = userStore?.selectUserList;
    }

    const defaultProps: SelectProps<any> = {
      showSearch: true,
      options: userList,
      optionFilterProp: "label",
      filterOption: (input, option) => {
        let isFound: number | undefined = option?.label
          ?.toString()
          .toLowerCase()
          .indexOf(input?.toLowerCase());
        return isFound !== undefined && isFound >= 0 ? true : false;
      },
      ...props,
    };

    return (
      <SelectField
        prefix={prefix ? prefix : <TeamOutlined />}
        disabled={props?.disabled}
        label={label ? label : "User"}
        showArrow={false}
        suffixIcon={<></>}
        {...defaultProps}
      />
    );
  }
);

export default inject("authStore", "userStore")(observer(UsersSelectField));
