import Selector from "components/selector/selector";
import React from "react";
import { AllStateAbbreviations } from "configs/static-drop-down-options";

const UsStateSelector = (props: any) => {
  const { width = 200, onStateSelect = () => {}, value = "" } = props;

  const _onUpdate = (state: string) => {
    if (state) {
      onStateSelect(state);
    } else {
      onStateSelect("");
    }
  };

  const allStates = AllStateAbbreviations.map((option: any) => ({
    value: option,
    label: option,
  }));

  return (
    <>
      <Selector
        width={width}
        placeholder="Select a state"
        options={allStates}
        value={value}
        allowClear={true}
        onUpdate={_onUpdate}
      />
    </>
  );
};

export default UsStateSelector;
