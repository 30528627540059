import React from "react";
import { inject, observer } from "mobx-react";
import { useHistory, Route } from "react-router-dom";

const ProtectedRoute: React.FC<any> = ({ authStore, component, ...otherProps }) => {
  const history = useHistory();

  if (authStore.isAuthenticated) {
    return <Route component={component} {...otherProps} />;
  } else if (authStore.isHydrated) {
    history.push("/");
    return null;
  } else {
    return null;
  }
};

export default inject("authStore")(observer(ProtectedRoute));
