import React, { useEffect, useRef, useState } from "react";
import { AutoComplete, Input } from "antd";
import classnames from "classnames";
import { DownOutlined } from "@ant-design/icons";

import type { InputProps } from "antd/lib/input";

interface Props extends InputProps {
  searchClass?: string;
  addonAfter?: React.ReactElement;
  searchAutocomplete?: any;
  onEnter?: any;
  placeholder?: any;
  searchValue?: string;
  defaultValues?: string;
}

const SearchSelect: React.FC<Props> = ({
  searchClass,
  addonAfter,
  searchAutocomplete,
  onEnter,
  placeholder,
  searchValue,
  defaultValues,
}): React.ReactElement => {
  const [value, setValue] = useState("");
  const inputRef = useRef<any>();

  const handleSelect = (option: any) => {
    setValue(option.label ? option.label : option.value);
    onEnter(option);
  };

  useEffect(() => {
    if (searchValue == "") setValue("");
  }, [searchValue]);

  useEffect(() => {
    if (defaultValues) {
      setValue(defaultValues);
    } else {
      setValue("");
    }
  }, [defaultValues]);

  return (
    <div className={classnames("search-container", searchClass)}>
      <AutoComplete
        options={searchAutocomplete}
        onSelect={(val: any, option: any) => {
          handleSelect(option);
          inputRef.current?.blur();
        }}
        filterOption={(inputValue, option: any) =>
          option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        onFocus={() => {
          setValue("");
        }}
        value={value}
        searchValue={value}
      >
        <Input
          ref={inputRef}
          suffix={<DownOutlined />}
          placeholder={placeholder}
          size="large"
          addonAfter={addonAfter}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      </AutoComplete>
    </div>
  );
};

export default SearchSelect;
