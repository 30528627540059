import Selector from "components/selector/selector";
import { inject, observer } from "mobx-react";
import React from "react";

const EffectSelector = (props: any) => {
  const { width = 200, onEffectUpdate = () => {}, value = "" } = props;

  const _onUpdate = (effectKey: string) => {
    if (effectKey) {
      onEffectUpdate(effectKey);
    } else {
      onEffectUpdate("");
    }
  };

  const effects = props.cartStore.colorizationOptions.map((option: any) => ({
    value: option.Key,
    label: option.Description,
  }));

  return (
    <>
      <div className="selectorWrapper">
        <p className="label">Effect</p>
        <Selector
          width={width}
          placeholder="Select an effect"
          options={effects}
          value={value}
          allowClear={true}
          onUpdate={_onUpdate}
        />
      </div>
    </>
  );
};

export default inject("cartStore")(observer(EffectSelector));
