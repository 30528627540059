import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { useQuery } from "react-query";
import dayjs from "dayjs";

import type { ScheduleList } from "interfaces/schedule.int";

import type { ClientStoreClass } from "stores/ClientStore";
import type { ScheduleStoreClass } from "stores/ScheduleStore";
import type { AuthStoreClass } from "stores/AuthStore";
import type { SessionStoreClass } from "stores/SessionStore";

import { Typography, message, Empty, Spin, Col } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { AppointmentStoreClass } from "stores/AppointmentStore";

interface Props {
  clientStore?: ClientStoreClass;
  scheduleStore?: ScheduleStoreClass;
  authStore?: AuthStoreClass;
  sessionStore?: SessionStoreClass;
  appointmentStore?: AppointmentStoreClass;
}

const UpcomingSessions: React.FC<Props> = ({
  clientStore,
  scheduleStore,
  authStore,
  sessionStore,
  appointmentStore,
  ...props
}) => {
  const { Title, Text } = Typography;

  const { isLoading, data } = useQuery(["calendar"], () => {
    return scheduleStore?.getCalendarList(authStore?.authenticatedUser?.UserKey);
  });
  // useEffect(() => {
  //   loadCalendarList();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const loadCalendarList = async () => {
  //   // scheduleStore?.setFilterUserKey(authStore?.authenticatedUser?.UserKey);
  //   try {
  //     await scheduleStore?.getCalendarList(authStore?.authenticatedUser?.UserKey);
  //   } catch (e) {
  //     message.warning("Sorry, couldn't fetch calendar events.");
  //   }
  // };

  if (isLoading || !data) return null;

  let filteredSchedule: Array<ScheduleList> = [];
  data.map((schedule: any) => {
    if (
      dayjs(schedule.StartDateTime).diff(dayjs(new Date()), "days") >= 0 &&
      dayjs(schedule.StartDateTime).diff(dayjs(new Date()), "days") <= 2
    ) {
      filteredSchedule.push(schedule);
    }
  });

  const _scheduleTimeline = (schedule: any) => {
    let timeline = "";
    // console.log(dayjs(schedule.StartDateTime).format("MMM, DD YYYY"));
    if (
      dayjs(schedule.StartDateTime).format("MMM, DD YYYY") !=
      dayjs(schedule.EndDateTime).format("MMM, DD YYYY")
    ) {
      timeline =
        dayjs(schedule.StartDateTime).format("MMM DD, YYYY hh:mm A") +
        " - " +
        dayjs(schedule.EndDateTime).format("MMM DD, YYYY hh:mm A");
    } else {
      timeline =
        dayjs(schedule.StartDateTime).format("MMM DD, YYYY") +
        " " +
        dayjs(schedule.StartDateTime).format("hh:mm A") +
        " - " +
        dayjs(schedule.EndDateTime).format("hh:mm A");
    }
    return timeline;
  };

  // case "Appointment":
  //       appointmentStore?.setSelectToggle(true);
  //       setCreateASession(false);
  //       break;
  //     case "Session":
  //       setCreateASession(true);
  //       sessionStore?.setSelectToggle(true);
  //       break;

  const handleClick = (item: any) => {
    if (item.SessionKey?.length > 0) {
      sessionStore?.getSessionDetail(item.SessionKey);
      scheduleStore?.setActiveSchedule({
        SessionKey: item.SessionKey,
      });
      sessionStore?.setSelectToggle(true);
    } else if (item.AppointmentKey?.length > 0) {
      appointmentStore?.getAppointmentDetail(item.AppointmentKey);
      scheduleStore?.setActiveSchedule({
        AppointmentKey: item.AppointmentKey,
      });
      appointmentStore?.setSelectToggle(true);
    }
  };

  return (
    <>
      {filteredSchedule.length > 0 ? (
        <>
          {filteredSchedule.map((schedule: any) => (
            <Col className="upcoming-schedule">
              <Col className="upcoming-schedule__icon">
                <CalendarOutlined />
              </Col>

              <Col onClick={() => handleClick(schedule)} className="upcoming-schedule__Detail">
                <Title level={4}>{schedule.Description}</Title>
                <Text>{_scheduleTimeline(schedule)}</Text>
              </Col>
            </Col>
          ))}
        </>
      ) : (
        <p style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.25)" }}>
          You have no upcoming{" "}
          {authStore?.companySettings.JobDescriptorPlural
            ? authStore?.companySettings.JobDescriptorPlural
            : "sessions"}{" "}
          for the next 2 days.
        </p>
      )}
    </>
  );
};

export default inject(
  "appointmentStore",
  "sessionStore",
  "scheduleStore",
  "authStore"
)(observer(UpcomingSessions));
