import React, { useState, useEffect } from 'react';
import { Bar } from '@ant-design/plots';

interface Props {
    chartData?: any;
}

const BarChart: React.FC<Props> = ({ chartData }) => {

    const [barChartData, setBarChartData] = useState<any>();

    let color = [];
    color.push(chartData.Report.chart_color1);
    color.push("#a8071a");

    useEffect(() => {
        if (chartData.ChartData.Chart1List) {
            let chart1List: any = [];
            for (let item of chartData.ChartData.Chart1List) {
                chart1List.push({
                    label: item.Display,
                    type: 'Current',
                    value: item.Actual,
                })
                if (item.Budget) {
                    chart1List.push({
                        label: item.Display,
                        type: 'Budget',
                        value: item.Budget,
                    })
                }
                if (item.PreviousYear) {
                    chart1List.push({
                        label: item.Display,
                        type: 'PreviousYear',
                        value: item.PreviousYear,
                    })
                }
            }
            setBarChartData(chart1List);
        }

        // alert(chartData.Report.chart_color1);

    }, [chartData.ChartData]);

    const config = {
        data: barChartData,
        isGroup: chartData?.Report?.data_comparison != "None" ? true : false,
        xField: 'value',
        yField: 'label',

        /** 自定义颜色 */
        color: color ? color : ['#1383ab', '#c52125'],
        seriesField: 'type',
        marginRatio: 0,
        autoFit: true,
        label: {
            layout: [
                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },
    };
    return (
        <>
            {barChartData ? <Bar {...config} /> : null}
        </>
    )
};

export default BarChart;
