import { CloseCircleOutlined, EditFilled, SettingTwoTone } from "@ant-design/icons";
import { Card, Col, Dropdown, Empty, Menu, Space, Typography } from "antd";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ReportStoreClass } from "stores/ReportStore";
import GuageChart from "../Charts/GuageChart";
import ProgressChart from "../Charts/ProgressChart";
const XMLParser = require('react-xml-parser');

interface Props {
    reportStore?: ReportStoreClass;
    chartData?: any;
    groupKey?: string;
    handleEditButtonClick?: any;
    handleMenuClick?: any;
}

const { Title, Text } = Typography;

const PerformancePanel: React.FC<Props> = ({ reportStore, chartData, groupKey, handleEditButtonClick, handleMenuClick }) => {
    const [reportData, setReportData] = useState<any>();
    const [GuageType, SetGuageType] = useState<any>();

    const generateGuageType = (xml: string) => {
        return new XMLParser().parseFromString(xml).children[0].children[1].value;
    }

    const generateReportData = async (Key: string) => {
        let p: any = {
            Key: Key
        };
        const report_details = await reportStore?.getDashboardReport(Key);
        const guageType = generateGuageType(report_details?.XmlSettings);
        SetGuageType(guageType);
        p.Name = report_details.Name;
        reportStore?.getPerformancePanel(Key).then((data: any) => {
            p.Data = data;
            setReportData(p);
        });
    }

    const formatChartTitle = (title: string) => {
        return title.replace(/([A-Z])/g, ' $1').trim();
    }

    useEffect(() => {
        generateReportData(chartData);
    }, [chartData, reportStore?.isSelectToggle]);

    const menu = (
        <Menu
            onClick={handleMenuClick}
        >
            <Menu.Item key="edit" disabled><EditFilled /> Edit</Menu.Item>
            <Menu.Item key="remove"><CloseCircleOutlined /> Remove</Menu.Item>
        </Menu>
    );

    return (
        <Card
            className="chartCard"
            title={
                <Space className="chartCardHead">
                    <span></span>
                    <Title level={5}>{reportData?.Name}</Title>
                    <Dropdown overlay={menu} trigger={['click']}>
                        <a onClick={() => { handleEditButtonClick('performance', groupKey, chartData) }}>
                            <SettingTwoTone />
                        </a>
                    </Dropdown>
                </Space>
            }
        >
            {reportData ?
                <>
                    {
                        reportData?.Data?.map((guage: any, index: number) => {
                            return (
                                <Col className="guagePanel" key={index}>
                                    <Col className="guagePanelHead">
                                        <Title level={5}>{formatChartTitle(guage.DataType)}</Title>
                                    </Col>
                                    <Col className="guagePanelBody">
                                        {
                                            GuageType && GuageType == "1" ? (
                                                <Col className="progressBar">
                                                    <ProgressChart chartData={guage} />
                                                    <Text>{Math.round(guage.GaugeAcutalValue) + '%'}</Text>
                                                </Col>
                                            )
                                                : <GuageChart chartData={guage} />
                                        }
                                    </Col>
                                </Col>
                            )
                        })
                    }
                </>
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </Card >
    )
}

export default inject("reportStore")(observer(PerformancePanel));