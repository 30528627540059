import { observable, makeObservable, action, computed } from "mobx";
import { create, persist } from "mobx-persist";
import axios, { AxiosResponse, AxiosError } from "axios";
import {
  AllPreferences,
  DashboardReport,
  DashboardReportName,
  ReportObject,
} from "interfaces/report.int";

const XMLParser = require("react-xml-parser");

export class PersonalDashboardStoreClass {
  @persist("list") dashboardTabs: Array<any> = [];
  @persist("list") dashboardReports: Array<DashboardReportName> = [];
  @persist("object") allPrefereneces: AllPreferences | undefined;
  @persist("list") groupBy: Array<any> = [];
  @persist("list") criteriaType: Array<any> = [];
  @persist("list") chartData: Array<any> = [];
  @persist("list") gridLayout: Array<any> = [];
  @persist("object") activeSavedReport: any = {};
  @persist("list") filterList: Array<any> = [];
  @persist("object") reportObject: ReportObject = {
    XmlSettings: "",
    SelectedColumns: "",
    DataType: 0,
    GridLayoutModified: false,
    GridLayout: "",
    Locked: false,
    isNew: false,
    LastModified: "",
    Modified: false,
    SortOrder: 0,
    Type: 2,
    Key: "",
    Name: "",
  };
  @persist("object") xmlToJsonReport: Array<any> = [];
  @persist("object") activeReportTable: any = [];
  @persist("object") viewReportData: any = {};
  @persist("object") groupedChartsData: any = [];
  @persist("object") tabsGroupedCharts: any = [];
  @persist("object") personalDashboard: any = [];
  activeTab: string = "";
  isLoading: boolean = false;
  isSelectToggle: boolean = false;
  isPerformancePanelToggle: boolean = false;
  isViewReportToggle: boolean = false;
  allowGrouping: boolean = false;
  allowAddMore: boolean = false;

  constructor() {
    makeObservable(this, {
      dashboardTabs: observable,
      isLoading: observable,
      setActiveReportTab: action,
      dashboardReports: observable,
      getDashboardReports: action,
      saveDashboardReport: action,
      savedReports: computed,
      allPrefereneces: observable,
      getAllPrefereneces: action,
      dataTypeOptions: computed,
      groupByOptions: computed,
      chartColorOptions: computed,
      chartTypeOptions: computed,
      dataComparisonOptions: computed,
      dateRangeOptions: computed,
      groupBy: observable,
      getGroupBy: action,
      criteriaType: observable,
      getCriteriaType: action,
      criteriaTypeOptions: computed,
      activeSavedReport: observable,
      getDashboardReport: action,
      filteredActiveSavedReport: computed,
      resetActiveSavedReport: action,
      isSelectToggle: observable,
      xmlToJsonReport: observable,
      isPerformancePanelToggle: observable,
      getChartData: action,
      chartData: observable,
      addChartData: action,
      setPerformancePanelToggle: action,
      setSelectToggle: action,
      upsert: action,
      removeChartData: action,
      isViewReportToggle: observable,
      setViewReportToggle: action,
      getReportTable: action,
      activeReportTable: observable,
      viewReportData: observable,
      setViewReportData: action,
      allowGrouping: observable,
      setAllowGrouping: action,
      groupedChartsData: observable,
      setGroupedChartsData: action,
      tabsGroupedCharts: observable,
      setTabsGroupedCharts: action,
      removeTabsGroupedCharts: action,
      updateTabGroupedCharts: action,
      addMoreTabGroupedCharts: action,
      allowAddMore: observable,
      setAllowAddMore: action,
      removeGroupedChartsFromChartsData: action,
      gridLayout: observable,
      setGridLayout: action,
      removeFromTabsGroup: action,
      updateChartInTabGroup: action,
      personalDashboard: observable,
      getPersonalDashboard: action,
      concatReportData: action,
      mergeGroupData: action,
      generateGridLayout: action,
      upsertLayout: action,
      updateGridLayout: action,
      performanceGaugeType: computed,
      savePerformancePanel: action,
      addPerformanceData: action,
      getPerformancePanel: action,
      filterList: observable,
      getFilterList: action,
      filterListOptions: computed,
      deleteReport: action,
    });
  }

  setGridLayout(layout: any) {
    this.gridLayout = layout;
  }

  setTabsGroupedCharts(groupChartData: any) {
    for (let report of groupChartData) {
      this.personalDashboard.XMLSettings.DockAreas =
        this.personalDashboard.XMLSettings.DockAreas.filter((group: any) => {
          return group.ObjectKeys[0] != report;
        });
    }
    // debugger
    var groupKey = new Date().getTime().toString(36);
    let layout = {
      GroupKey: groupKey,
      Height: 10,
      Moved: false,
      ObjectKeys: groupChartData,
      Static: false,
      Width: 3,
      XPosition: this.gridLayout.length * 3,
      YPosition: 0,
    };
    this.personalDashboard.XMLSettings?.DockAreas?.push(layout);
  }

  removeTabsGroupedCharts(GroupKey: string) {
    this.isLoading = true;
    let groupedCharts = this.personalDashboard.XMLSettings?.DockAreas?.find((item: any) => {
      return item.GroupKey === GroupKey;
    });
    for (let object of groupedCharts.ObjectKeys) {
      this.addChartData(object);
    }
    this.isLoading = true;
    this.personalDashboard.XMLSettings.DockAreas =
      this.personalDashboard.XMLSettings.DockAreas.filter((item: any) => item.GroupKey != GroupKey);
    this.generateGridLayout();
    this.isLoading = false;
  }

  removeFromTabsGroup(groupChartData: any) {
    for (let group of groupChartData) {
      this.removeTabsGroupedCharts(group);
    }
  }

  removeGroupedChartsFromChartsData(groupChartData: any) {
    this.chartData = this.chartData.filter((val) => !groupChartData.includes(val));
  }

  addMoreTabGroupedCharts(chartGroupData: any) {
    this.tabsGroupedCharts = chartGroupData;
  }

  updateChartInTabGroup(ChartItem: any, GroupIndex: number, ChildIndex: number) {
    this.tabsGroupedCharts[GroupIndex][ChildIndex] = ChartItem;
  }

  updateTabGroupedCharts(GroupKey: string, Key: string) {
    this.isLoading = true;
    let group = this.personalDashboard.XMLSettings?.DockAreas?.find((item: any) => {
      return item.GroupKey === GroupKey;
    });
    this.personalDashboard.XMLSettings.DockAreas =
      this.personalDashboard.XMLSettings.DockAreas.filter((item: any) => item.GroupKey != GroupKey);
    group.ObjectKeys = group.ObjectKeys.filter((key: any) => key != Key);
    this.personalDashboard.XMLSettings.DockAreas.push(group);
    this.addChartData(Key);
  }

  setAllowGrouping(allowGrouping: boolean) {
    this.allowGrouping = allowGrouping;
  }

  setAllowAddMore(allowMore: boolean) {
    this.allowAddMore = allowMore;
  }

  setSelectToggle(isVisible: boolean) {
    this.isSelectToggle = isVisible;
  }

  setPerformancePanelToggle(isVisible: boolean) {
    this.isPerformancePanelToggle = isVisible;
  }

  setViewReportToggle(isVisible: boolean) {
    this.isViewReportToggle = isVisible;
  }

  setViewReportData(reportData: object) {
    this.viewReportData = reportData;
  }

  setGroupedChartsData(chartData: any) {
    this.groupedChartsData = chartData;
  }

  upsert(array: any, element: any) {
    // (1)
    const i = array.findIndex((item: any) => item.Key === element.Key);
    if (i > -1) array[i] = element;
    // (2)
    else array.push(element);
  }

  addChartData(reportKey: string) {
    this.isLoading = true;
    let layout = {
      GroupKey: reportKey,
      Height: 10,
      Moved: false,
      ObjectKeys: [reportKey],
      Static: false,
      Width: 3,
      XPosition: this.gridLayout.length * 3,
      YPosition: 0,
    };
    this.personalDashboard.XMLSettings?.DockAreas?.push(layout);
    this.isLoading = false;
  }

  addPerformanceData(reportKey: string) {
    this.isLoading = true;
    let layout = {
      GroupKey: reportKey,
      Height: 10,
      Moved: false,
      ObjectKeys: [reportKey],
      Static: false,
      Width: 3,
      ReportType: 1,
      XPosition: this.gridLayout.length * 3,
      YPosition: 0,
    };
    this.personalDashboard.XMLSettings?.DockAreas?.push(layout);
    this.isLoading = false;
  }

  removeChartData(Key: any) {
    this.personalDashboard.XMLSettings.DockAreas =
      this.personalDashboard.XMLSettings.DockAreas.filter((item: any) => item.GroupKey != Key);
  }

  setActiveReportTab(activeTab: string) {
    this.activeTab = activeTab;
  }

  async getDashboardReports() {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/DashboardReports`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.dashboardReports = res.data;
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async saveDashboardReport(data: any) {
    this.isLoading = true;
    return await axios({
      method: "PUT",
      url: `/DashboardReports`,
      data,
    })
      .then(
        action((res: AxiosResponse) => {
          this.reportObject = res.data;
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async getDashboardReport(Key: string | undefined) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/DashboardReports/${Key}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.activeSavedReport = res.data;
          this.reportObject = res.data;
          this.isLoading = false;
          this.xmlToJsonReport = new XMLParser().parseFromString(
            res.data.XmlSettings
          ).children[0].children;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async getReportData(Key: string | undefined) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/DashboardReports/${Key}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          return new XMLParser().parseFromString(res.data.XmlSettings).children[0].children;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async getChartData(Key: string) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/DashboardReports/ChartData/${Key}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async getReportTable(Key: string) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/DashboardReports/ReportTable/${Key}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.activeReportTable = res.data;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  resetActiveSavedReport() {
    this.activeSavedReport = {};
    this.xmlToJsonReport = [];
  }

  get filteredActiveSavedReport() {
    let savedReport: any = {};
    this.xmlToJsonReport.map((data: any) => {
      savedReport[data.name] = data.value;
    });
    return savedReport;
  }

  async getAllPrefereneces() {
    return await axios({
      method: "GET",
      url: `/Reports/GetAllPreferences`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.allPrefereneces = res.data;
          return res;
        })
      )
      .catch((err: AxiosError) => {
        throw new Error(err.message);
      });
  }

  getGroupBy(datatypekey: string) {
    return axios({
      method: "GET",
      url: `/Reports/AvailableGroupBy`,
      params: {
        datatypekey,
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.groupBy = res.data;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        throw new Error(err.message);
      });
  }

  getCriteriaType(datatypekey: string) {
    return axios({
      method: "GET",
      url: `/Reports/AvailableCriteriaType`,
      params: {
        datatypekey,
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.criteriaType = res.data;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        throw new Error(err.message);
      });
  }

  getFilterList() {
    return axios({
      method: "GET",
      url: `/Filter`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.filterList = res.data;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        throw new Error(err.message);
      });
  }
  getPersonalDashboard() {
    this.isLoading = true;
    return axios({
      method: "GET",
      url: `/DashboardSettings?userDashboard=true`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.concatReportData(res.data.XMLSettings.DockAreas);
          this.personalDashboard = res.data;
          this.generateGridLayout();
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  updateGridLayout(layout: any) {
    this.personalDashboard?.XMLSettings?.DockAreas.map((a: any) => {
      const exists = layout.find((b: any) => a.GroupKey == b.i);
      if (exists) {
        a.Width = exists.w;
        a.Height = exists.h;
        a.XPosition = exists.x;
        a.YPosition = exists.y;
      }
      return a;
    });
    this.setGridLayout(layout);
  }

  generateGridLayout() {
    if (this.personalDashboard?.XMLSettings?.DockAreas) {
      for (let area of this.personalDashboard?.XMLSettings?.DockAreas) {
        let layout = {
          w: area.Width,
          h: area.Height,
          x: area.XPosition,
          y: area.YPosition,
          i: area.GroupKey,
          moved: area.Moved,
          static: area.Static,
        };
        if (area.ObjectKeys.length > 0) this.upsertLayout(this.gridLayout, layout);
      }
    }
    this.setGridLayout(this.gridLayout);
  }

  savePersonalDashboard() {
    this.isLoading = true;
    return axios({
      method: "PUT",
      url: `/DashboardSettings?userDashboard=true`,
      data: this.personalDashboard,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  upsertLayout(array: any, element: any) {
    // (1)
    const i = array.findIndex((item: any) => item.i === element.i);
    if (i > -1) array[i] = element;
    // (2)
    else array.push(element);
  }

  mergeGroupData(groupData: any) {
    let group: any = [];
    for (let chart of groupData) {
      group.push({ Key: chart });
    }
    return group;
  }

  concatReportData(personalDashboardData: any) {
    for (let cd of personalDashboardData) {
      if (cd.ObjectKeys.length > 1) {
        let group = this.mergeGroupData(cd.ObjectKeys);
        this.concatTabsGroup(this.tabsGroupedCharts, group);
        this.chartData = this.chartData.filter((val) => !group.includes(val));
      } else {
        this.upsert(this.chartData, { Key: cd.ObjectKeys[0] });
      }
    }
  }

  concatTabsGroup(array: any, element: any) {
    return array.find((it: any) => JSON.stringify(it) == JSON.stringify(element)) == undefined
      ? array.push(element)
      : null;
  }

  async deleteReport(Key: string) {
    this.isLoading = true;
    return await axios({
      method: "DELETE",
      url: `/DashboardReports?id=${Key}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async savePerformancePanel(data: any) {
    this.isLoading = true;
    return await axios({
      method: "PUT",
      url: `/DashboardReports`,
      data,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async getPerformancePanel(Key: string) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `DashboardReports/PerformanceData/${Key}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  get performanceGaugeType() {
    let performanceType: any = [];
    if (this.allPrefereneces) {
      for (let data of this.allPrefereneces.PerformanceGaugeType) {
        performanceType.push({
          label: data.Value,
          value: data.Key,
          enumvalue: data.EnumValue,
        });
      }
    }
    return performanceType;
  }

  get criteriaTypeOptions() {
    return this.criteriaType.map((v: DashboardReport) => {
      return {
        label: v.Value,
        value: v.Key,
        enumvalue: v.EnumValue,
      };
    });
  }
  get filterListOptions() {
    return this.filterList.map((v: any) => {
      return {
        label: v.Description,
        value: v.Key,
      };
    });
  }

  get groupByOptions() {
    return this.groupBy.map((v: DashboardReport) => {
      return {
        label: v.Value,
        value: v.Key,
        enumvalue: v.EnumValue,
      };
    });
  }

  get dataTypeOptions() {
    let dataType: any = [];
    if (this.allPrefereneces) {
      for (let data of this.allPrefereneces.DataTypes) {
        dataType.push({
          label: data.Value,
          value: data.Key,
          enumvalue: data.EnumValue,
        });
      }
    }

    return dataType;
  }

  get chartColorOptions() {
    let chartColor: any = [];
    if (this.allPrefereneces) {
      for (let data of this.allPrefereneces.ChartColor) {
        chartColor.push({
          label: data.Value,
          value: data.Key,
          enumvalue: data.EnumValue,
        });
      }
    }

    return chartColor;
  }

  get chartTypeOptions() {
    let chartType: any = [];
    if (this.allPrefereneces) {
      for (let data of this.allPrefereneces.ChartType) {
        chartType.push({
          label: data.Value,
          value: data.Key,
          enumvalue: data.EnumValue,
        });
      }
    }

    return chartType;
  }

  get dataComparisonOptions() {
    let dataComparison: any = [];
    if (this.allPrefereneces) {
      for (let data of this.allPrefereneces.DataComparison) {
        dataComparison.push({
          label: data.Value.replace(/([A-Z])/g, " $1").trim(),
          value: data.Key,
          enumvalue: data.EnumValue,
        });
      }
    }

    return dataComparison;
  }

  get dateRangeOptions() {
    let dateRange: any = [];
    if (this.allPrefereneces) {
      for (let data of this.allPrefereneces.DateRange) {
        dateRange.push({
          label: data.Value.replace(/([A-Z])/g, " $1").trim(),
          value: data.Key,
          enumvalue: data.EnumValue,
        });
      }
    }

    return dateRange;
  }

  get savedReports() {
    let reports = [
      {
        label: "",
        value: "",
        type: 2,
      },
    ];

    for (let item of this.dashboardReports) {
      reports.push({
        label: item.Name,
        value: item.Key,
        type: Number(item.Type),
      });
    }
    return reports;
  }
}

const hydrate = create({});

const PersonalDashboardStore = new PersonalDashboardStoreClass();

export default PersonalDashboardStore;

hydrate("personalDashboard", PersonalDashboardStore).then(() => {});
