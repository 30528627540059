import { useState, useLayoutEffect } from "react";
import debounce from "lodash.debounce";

interface Props {
  innerWidth: number;
  innerHeight: number;
}

const useWindowDimension = (): Props => {
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const innerWidth: number = hasWindow ? window.innerWidth : 0;
    const innerHeight: number = hasWindow ? window.innerHeight : 0;
    return {
      innerWidth,
      innerHeight,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState<Props>(
    getWindowDimensions()
  );

  function handleResize() {
    const debouncedSave = debounce(
      () => setWindowDimensions(getWindowDimensions()),
      100
    );
    debouncedSave();
  }

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [hasWindow]); // eslint-disable-line react-hooks/exhaustive-deps

  return windowDimensions;
};

export default useWindowDimension;
