import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Button, Card, Col, Empty, Row, Spin, Typography } from "antd";

import "./StatusBucket.scss";
import ClientBucket from "./ClientBucket";
import { PlusCircleFilled, ThunderboltFilled } from "@ant-design/icons";
import { ClientStoreClass } from "stores/ClientStore";

const { Title, Text } = Typography;

interface Props {
  clientStore?: ClientStoreClass;
}

const ClientStatusBucket: React.FC<Props> = ({ clientStore }) => {
  useEffect(() => {
    clientStore?.getClients();
  }, [clientStore]);

  return (
    <Spin size={"default"} spinning={clientStore?.isLoading}>
      <Row className="StatusBucket" gutter={10}>
        <Col sm={24} xs={24}>
          <Row gutter={10} className="StatusBucket__Row">
            {clientStore?.clientStatuses ? (
              clientStore?.clientStatuses.map((status: any) => {
                return (
                  <Col xs={8} key={status.Key}>
                    <Row gutter={10}>
                      <Col sm={15} xs={24}>
                        <Card
                          title={<Title level={4}>{status.Description}</Title>}
                          className="StatusBucket__Card"
                        >
                          <ClientBucket clientStatusKey={status.Key} />
                        </Card>
                        <span className="connet right"></span>
                      </Col>
                      <Col sm={9} xs={24} className="TriggerBox">
                        <Card className="StatusBucket__Triggers">
                          <span className="trigger-line left"></span>
                          <span className="trigger-line right"></span>
                          <Text className="trigger">
                            <ThunderboltFilled /> Change Session Date{" "}
                          </Text>
                          <Text className="trigger">
                            <ThunderboltFilled /> Send Confirmation{" "}
                          </Text>
                          <Button icon={<PlusCircleFilled />} className="TriggerBtn">
                            Add Trigger
                          </Button>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                );
              })
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default inject("clientStore")(observer(ClientStatusBucket));
