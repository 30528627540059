import React from "react";
import { inject, observer } from "mobx-react";
import { Avatar, Checkbox, Typography, message, Col, List, Row } from "antd";
import dayjs from "dayjs";

import type { PhoneCallList } from "interfaces/phonecall.int";
import type { PhoneCallStoreClass } from "stores/PhoneCallStore";
import { UserOutlined } from "@ant-design/icons";

interface Props {
  item: PhoneCallList;
  phoneCallStore?: PhoneCallStoreClass;
}

const { Title } = Typography;

const RenderPhoneCall: React.FC<Props> = ({ item, phoneCallStore }) => {
  const { Text } = Typography;

  const handleClick = () => {
    phoneCallStore?.setActivePhoneCall({
      Key: item.Key,
    });
    phoneCallStore?.setSelectToggle(true);
  };

  const onClickCompleted = async (key: any, task: any) => {
    let changedData: any = {};
    if (task.target.checked) {
      changedData["CompleteDate"] = dayjs().format("YYYY-MM-DDTHH:mm:ss");
      changedData["Completed"] = true;
    } else changedData["Completed"] = false;
    let values = { Key: key, ...changedData };
    await phoneCallStore?.editPhoneCall(values);
    message.success("Call successfully saved!");
  };

  return (
    <List.Item style={{ marginBottom: "1rem" }} className="Calls" onClick={handleClick}>
      <Row justify="center" align="middle" style={{ width: "100%" }}>
        <Col xs={3} md={2} lg={1}>
          <Avatar size={36} style={{ backgroundColor: "#b7aea2" }} icon={<UserOutlined />} />
        </Col>
        <Col xs={13} className="Calls__Content">
          <Col className="Calls__Content--Head">
            <Text className="Calls__Date">Due: {dayjs(item.DueDate).format("YYYY-MM-DD")}</Text>
            <p style={{ fontSize: "18px", margin: 0, fontWeight: 600 }}>
              {item.Description || "No Description"}
            </p>
          </Col>
        </Col>

        <Col style={{ display: "flex", justifyContent: "end" }} xs={8}>
          <Checkbox
            checked={item.Completed}
            onChange={(e) => onClickCompleted(item.Key, e)}
            className={`ant-list-item-check ${item?.Completed ? "completed" : ""}`}
          >
            {item.Completed ? "Completed" : "Mark as Complete"}
          </Checkbox>
        </Col>
      </Row>
    </List.Item>
    // <>
    //   <List.Item style={{ alignItems: "start" }} onClick={handleClick} className="has-action">
    //     <List.Item.Meta
    //       title={item.Description || "..."}
    //       description={
    //         item.DueDate !== "0001-01-01T00:00:00" ? (
    //           <Text type="secondary">Due {dayjs(item.DueDate).format("MMM DD, YYYY")}</Text>
    //         ) : (
    //           ""
    //         )
    //       }
    //       className="list-meta-float"
    //     />
    //   </List.Item>
    // </>
  );
};

export default inject("phoneCallStore")(observer(RenderPhoneCall));
