import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Button, Typography } from "antd";
import dayjs from "dayjs";
import { CaretDownOutlined } from "@ant-design/icons";

import type { ScheduleStoreClass } from "stores/ScheduleStore";
import CalendarYearFull from "../CalendarYearFull";
import CalendarNavigation from "../CalendarNavigation";

import "./ActionsCalendarYear.scss";

interface Props {
  scheduleStore?: ScheduleStoreClass;
}

const { Title } = Typography;

const ActionsCalendarYear: React.FC<Props> = ({
  scheduleStore,
}: Props): React.ReactElement => {
  const [toggleCalendar, setToggleCalendar] = useState(false);

  useEffect(() => {
    console.log(scheduleStore?.isShowTimeSelect);
  }, [scheduleStore?.isShowTimeSelect]);

  const handleToggleCalendar = () => {
    setToggleCalendar(!toggleCalendar);
  };

  return (
    <>
      <div className="actions-calendar-year">
        <Row align="middle">
          <Col>
            <CalendarNavigation
              calendar={
                scheduleStore?.isShowTimeSelect === "week"
                  ? "week"
                  : scheduleStore?.isShowTimeSelect === "month"
                  ? "month"
                  : "day"
              }
            />
          </Col>
          <Col style={{ paddingLeft: 10 }}>
            <Button
              className="btn-header-title"
              type="text"
              onClick={handleToggleCalendar}
            >
              <Title className="page-header-title">
                {dayjs(
                  scheduleStore?.calendarActiveYearMonth.toString(),
                  "YYYYMM"
                ).format("MMMM YYYY")}
              </Title>
              <CaretDownOutlined />
            </Button>
          </Col>
        </Row>
      </div>

      {toggleCalendar && (
        <>
          <div className="calendar-year-dropdown">
            <CalendarYearFull onToggleCalendar={handleToggleCalendar} />
          </div>
          <div
            className="calendar-year-dropdown-overlay"
            onClick={handleToggleCalendar}
          />
        </>
      )}
    </>
  );
};

export default inject("scheduleStore")(observer(ActionsCalendarYear));
