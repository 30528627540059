import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Button, Row, Col, Grid, Spin } from "antd";
import type { SelectProps } from "antd/lib/select";
import { UserOutlined, TeamOutlined } from "@ant-design/icons";

import SelectField from "./SelectField";
import type { ClientStoreClass } from "stores/ClientStore";
import type { ScheduleStoreClass } from "stores/ScheduleStore";

import "./Input.scss";

const { useBreakpoint } = Grid;

interface Props extends SelectProps<any> {
  scheduleStore?: ScheduleStoreClass;
  clientStore?: ClientStoreClass;
  form?: any;
}

const ClientsSelectField: React.FC<Props> = React.forwardRef(
  ({ scheduleStore, clientStore, ...props }: Props, _ref): React.ReactElement => {
    const [clientSelect, setClientSelect] = useState([]);
    const clientKey = props.form.getFieldValue("client");
    useEffect(() => {
      const init = async () => {
        const clientKey = props.form.getFieldValue("client");
        if (clientKey) {
          const client = await clientStore?.getClientSelect(clientKey);
          setClientSelect([client]);
        }
      };
      init();
    }, []);
    let timeout: ReturnType<typeof setTimeout>;
    const screens = useBreakpoint();
    const defaultProps: SelectProps<any> = {
      showSearch: true,
      options: clientStore?.isLoading
        ? [{ label: "Please search...", value: "Loading", clientNumber: "0" }]
        : clientSelect
            ?.map((v: any) => {
              let displayName: string = "";
              if (v?.LastName == null && v?.FirstName == null)
                displayName = v?.ClientNumber ? `Client #${v?.ClientNumber}` : "Name not found";
              else {
                displayName = `${v?.LastName !== null ? `${v?.LastName},` : ""} ${v?.FirstName} ${
                  v?.PhoneNumber0 ? `- ${v?.PhoneNumber0}` : ""
                }`;
              }

              return {
                label: displayName,
                value: v.Key || "",
                clientNumber: v.ClientNumber || "",
              };
            })
            .slice(0, 25),
      optionFilterProp: "label",
      filterOption: (input, option) => {
        let isFound: number | undefined = option?.label
          ?.toString()
          .toLowerCase()
          .indexOf(input?.toLowerCase());
        return isFound !== undefined && isFound >= 0 ? true : false;
      },
      onSelect: (value: string | undefined) => {
        scheduleStore?.setActiveClientKey(value);
        clientStore?.setActiveClientKey(value);
      },
      onSearch: async (value: string) => {
        clientStore?.setIsLoading(true);
        clientStore?.setSearchBy({ value: "lastName", children: "Last Name" });
        clientStore?.updateSearchTerm(value);
      },
      onKeyUp: () => {
        clearTimeout(timeout);
        timeout = setTimeout(async () => {
          const list = await clientStore?.getClientsSelect();
          setClientSelect(list || []);
        }, 1000);
      },
      onInputKeyDown: async (e) => {
        if (e.key === "Enter") {
          const list = await clientStore?.getClientsSelect();
          setClientSelect(list || []);
        }
        clearTimeout(timeout);
      },
      notFoundContent: clientStore?.isLoading ? <Spin /> : null,
      showArrow: true,
      ...props,
    };
    const handleOnClick = () => {
      clientStore?.setSelectToggle(true);
    };

    return (
      <>
        <Row align="middle" gutter={20}>
          <Col
            xs={24}
            md={12}
            style={{
              marginBottom: screens.md ? 0 : props?.value ? 20 : 0,
            }}
          >
            <SelectField
              defaultValue={props?.defaultValue}
              prefix={<UserOutlined />}
              disabled={props?.disabled}
              label="Client"
              {...defaultProps}
            />
          </Col>
          {!clientStore?.isSelectToggle && clientKey ? (
            <Col xs={10}>
              <Button icon={<TeamOutlined />} onClick={() => handleOnClick()}>
                Client Details
              </Button>
            </Col>
          ) : null}
        </Row>
      </>
    );
  }
);

export default inject("scheduleStore", "clientStore")(observer(ClientsSelectField));
