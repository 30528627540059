import React, { useEffect, useState } from "react";
import IframeResizer from "iframe-resizer-react";
import { observer, inject } from "mobx-react";
import { Result, Button } from "antd";

const CheckoutPayment = (props: any) => {
  const [orderNumber, setOrderNumber] = useState("");
  useEffect(() => {
    window.onmessage = (event: MessageEvent) => {
      if (typeof event.data === "string" && event.data.toUpperCase() === "SUCCESS") {
        setOrderNumber(props.cartStore.cart.InvoiceNumber);
        props.cartStore.cart = {};
        props.cartStore._resetStore();
        props.cartStore.successfulOrder = true;
      }
    };
  }, []);

  if (props.cartStore.successfulOrder) {
    return (
      <Result
        status="success"
        title="Thank you for your order!"
        subTitle={`Order number: ${orderNumber}`}
        extra={[
          <Button onClick={() => props.setIsVisible(false)} type="primary" key="console">
            Close window
          </Button>,
        ]}
      />
    );
  }

  return (
    <>
      <IframeResizer
        log={false}
        scrolling={false}
        src={props?.cartStore?.cart?.PaymentUrl}
        style={{ width: 1, minWidth: "100%", minHeight: 600, border: 0 }}
      />
    </>
  );
};

export default inject("cartStore", "authStore")(observer(CheckoutPayment));
