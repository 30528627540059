import React, { useState, useEffect } from "react";
import "./checkout-wizard.scss";
import { CloseCircleFilled } from "@ant-design/icons";
import CartShippingForm from "components/shipping-address-form/shipping-address-form";
import CheckoutReview from "components/checkout-review/checkout-review";
import CheckoutPayment from "components/checkout-payment/checkout-payment";
import EventEmitter from "utils/event-emitter";

const CheckoutWizard = (props: any) => {
  const [activeStep, setActiveStep] = useState(1);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    _registerEvents();
  }, []);

  useEffect(() => {
    if (!isVisible) {
      // Reset
      setActiveStep(1);
    }
  }, [isVisible]);

  const _registerEvents = () => {
    EventEmitter.listen("checkout", () => {
      setIsVisible(true);
    });
  };

  const _getProgressWidth = () => {
    return `${(activeStep / 3) * 100}%`;
  };

  const _renderStep = () => {
    switch (activeStep) {
      case 1: {
        return <CartShippingForm navigateToNextStep={() => setActiveStep(2)} />;
      }
      case 2: {
        return (
          <>
            <CheckoutReview
              navigateToNextStep={() => setActiveStep(3)}
              navigateToPrevStep={() => setActiveStep(1)}
            />
          </>
        );
      }
      case 3: {
        return (
          <>
            <CheckoutPayment
              setIsVisible={setIsVisible}
              navigateToPrevStep={() => setActiveStep(2)}
            />
          </>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  if (isVisible) {
    return (
      <>
        <div className="checkoutWizardWrapper">
          <div className="closeBtn" onClick={() => setIsVisible(false)}>
            <CloseCircleFilled />
          </div>
          <div className="progressBarWrapper">
            <div className="progressBar">
              <div className="progress" style={{ width: _getProgressWidth() }}></div>
            </div>
          </div>
          <div className="contentArea">
            <div className="secureContent">
              <div className="imagePart">
                <img src="https://image.flaticon.com/icons/png/512/214/214343.png" alt="" />
              </div>
              <div className="textPart">
                <h2>Secure Checkout</h2>
                <p>4096-bit SSL/TSL protected page</p>
              </div>
            </div>
            {_renderStep()}
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default CheckoutWizard;
