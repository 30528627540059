import { CheckOutlined, DeleteOutlined, PrinterOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  message,
  Modal,
  Row,
  Space,
  Tabs,
  Dropdown,
} from "antd";
import type { DrawerProps } from "antd/lib/drawer";
import SessionGallery from "components/session-gallery/session-gallery";
import useWindowDimension from "components/util/Dimension";
import { saveLocalStorage } from "components/util/localStorage";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { inject, observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import type { ScheduleStoreClass } from "stores/ScheduleStore";
import type { SessionStoreClass } from "stores/SessionStore";
import AddEditSession from "../forms/AddEditSession";
import "./SessionDrawerForm.scss";
import CheckoutWizard from "components/checkout-wizard/checkout-wizard";
import { ClientStoreClass } from "stores/ClientStore";
import { AuthStoreClass } from "stores/AuthStore";
import { useReactToPrint } from "react-to-print";
import ProCard from "@ant-design/pro-card";
import SessionPrintConfirmation from "../../pages/QuickAccess/Session/PrintConfirmation";
import TabPane from "@ant-design/pro-card/lib/components/TabPane";
import InputField from "components/forms/items/InputField";
import DateField from "components/forms/items/DateField";
import AppointmentDrawerForm from "components/drawers/AppointmentDrawerForm";
import PhoneCallDrawerForm from "components/drawers/PhoneCallDrawerForm";
import TaskDrawerForm from "components/drawers/TaskDrawerForm";
import { SessionCustomFields } from "interfaces/session.int";
import { PhoneCallStoreClass } from "stores/PhoneCallStore";
import { TaskStoreClass } from "stores/TaskStore";
import { InvoiceStoreClass } from "stores/InvoiceStore";
import { AppointmentStoreClass } from "stores/AppointmentStore";
// import { toJS } from "mobx";

dayjs.extend(utc);

interface Props {
  onToggle?: (x: boolean) => void;
  scheduleStore?: ScheduleStoreClass;
  sessionStore?: SessionStoreClass;
  phoneCallStore?: PhoneCallStoreClass;
  taskStore?: TaskStoreClass;
  invoiceStore?: InvoiceStoreClass;
  appointmentStore?: AppointmentStoreClass;
  galleryStore?: any;
  cartStore?: any;
  active?: string;
  sessionKey?: any;
  setOpenCalender?: any;
  setOpenCalenderForAppointment?: any;
  setCurrentTab?: any;
  setToggleSessionActionItems?: any;
  manageImagesStatus?: any;
  clientStore?: ClientStoreClass;
  authStore?: AuthStoreClass;
  clientKey?: string;
}

const SessionDrawerView: React.FC<Props> = ({
  onToggle,
  scheduleStore,
  sessionStore,
  galleryStore,
  cartStore,
  phoneCallStore,
  taskStore,
  invoiceStore,
  appointmentStore,
  active,
  sessionKey,
  setOpenCalender,
  setOpenCalenderForAppointment,
  setCurrentTab,
  setToggleSessionActionItems,
  manageImagesStatus,
  clientStore,
  authStore,
  clientKey,
}) => {
  const [isDeleteModalShown, setIsDeleteModalShown] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>(active === "gallery" ? active : "session");
  const { innerWidth } = useWindowDimension();
  const [form] = Form.useForm();
  const [showPrint] = useState(false);
  const [newInvoice, setNewInvoice] = useState(false);
  const [createOptions, setCreateOptions] = useState<any>([
    { label: "Invoice", value: "Invoice" },
    { label: "Appointment", value: "Appointment" },
    { label: "Task", value: "Task" },
    { label: "Call", value: "Call" },
  ]);

  const [sessionCustomFields, setSessionCustomFields] = useState<SessionCustomFields>({
    CustomDate01: "",
    CustomDate02: "",
    CustomDate03: "",
    CustomDate04: "",
    CustomDate05: "",
    CustomDate06: "",
    CustomDate07: "",
    CustomDate08: "",
    CustomDate09: "",
    CustomDate10: "",
    CustomText01: "",
    CustomText02: "",
    CustomText03: "",
    CustomText04: "",
    CustomText05: "",
    CustomText06: "",
    CustomText07: "",
    CustomText08: "",
    CustomText09: "",
    CustomText10: "",
    CustomText11: "",
    CustomText12: "",
    CustomText13: "",
    CustomText14: "",
    CustomText15: "",
    CustomText16: "",
    CustomText17: "",
    CustomText18: "",
    CustomText19: "",
    CustomText20: "",
    CustomText21: "",
    CustomText22: "",
    CustomText23: "",
    CustomText24: "",
    CustomText25: "",
    CustomText26: "",
    CustomText27: "",
    CustomText28: "",
    CustomText29: "",
    CustomText30: "",
    CustomText31: "",
    CustomText32: "",
    CustomNotes01: "",
    CustomNotes02: "",
    CustomNotes03: "",
    CustomNotes04: "",
    CustomNotes05: "",
    CustomNotes06: "",
    CustomNotes07: "",
    CustomNotes08: "",
    CustomNotes09: "",
    CustomNotes10: "",
    CustomBoolean01: false,
    CustomBoolean02: false,
    CustomBoolean03: false,
    CustomBoolean04: false,
    CustomBoolean05: false,
    CustomBoolean06: false,
    CustomBoolean07: false,
    CustomBoolean08: false,
    CustomBoolean09: false,
    CustomBoolean10: false,
  });

  useEffect(() => {
    setSessionCustomFields({
      CustomDate01: Number(dayjs(sessionStore?.currentSessionDetail?.CustomDate01).format("YYYY"))
        ? sessionStore?.currentSessionDetail?.CustomDate01
        : dayjs(new Date()).toString(),
      CustomDate02: Number(dayjs(sessionStore?.currentSessionDetail?.CustomDate02).format("YYYY"))
        ? sessionStore?.currentSessionDetail?.CustomDate02
        : dayjs(new Date()).toString(),
      CustomDate03: Number(dayjs(sessionStore?.currentSessionDetail?.CustomDate03).format("YYYY"))
        ? sessionStore?.currentSessionDetail?.CustomDate03
        : dayjs(new Date()).toString(),
      CustomDate04: Number(dayjs(sessionStore?.currentSessionDetail?.CustomDate04).format("YYYY"))
        ? sessionStore?.currentSessionDetail?.CustomDate04
        : dayjs(new Date()).toString(),
      CustomDate05: Number(dayjs(sessionStore?.currentSessionDetail?.CustomDate05).format("YYYY"))
        ? sessionStore?.currentSessionDetail?.CustomDate05
        : dayjs(new Date()).toString(),
      CustomDate06: Number(dayjs(sessionStore?.currentSessionDetail?.CustomDate06).format("YYYY"))
        ? sessionStore?.currentSessionDetail?.CustomDate06
        : dayjs(new Date()).toString(),
      CustomDate07: Number(dayjs(sessionStore?.currentSessionDetail?.CustomDate07).format("YYYY"))
        ? sessionStore?.currentSessionDetail?.CustomDate07
        : dayjs(new Date()).toString(),
      CustomDate08: Number(dayjs(sessionStore?.currentSessionDetail?.CustomDate08).format("YYYY"))
        ? sessionStore?.currentSessionDetail?.CustomDate08
        : dayjs(new Date()).toString(),
      CustomDate09: Number(dayjs(sessionStore?.currentSessionDetail?.CustomDate09).format("YYYY"))
        ? sessionStore?.currentSessionDetail?.CustomDate09
        : dayjs(new Date()).toString(),
      CustomDate10: Number(dayjs(sessionStore?.currentSessionDetail?.CustomDate10).format("YYYY"))
        ? sessionStore?.currentSessionDetail?.CustomDate10
        : dayjs(new Date()).toString(),
      CustomText01: sessionStore?.currentSessionDetail?.CustomText01,
      CustomText02: sessionStore?.currentSessionDetail?.CustomText02,
      CustomText03: sessionStore?.currentSessionDetail?.CustomText03,
      CustomText04: sessionStore?.currentSessionDetail?.CustomText04,
      CustomText05: sessionStore?.currentSessionDetail?.CustomText05,
      CustomText06: sessionStore?.currentSessionDetail?.CustomText06,
      CustomText07: sessionStore?.currentSessionDetail?.CustomText07,
      CustomText08: sessionStore?.currentSessionDetail?.CustomText08,
      CustomText09: sessionStore?.currentSessionDetail?.CustomText09,
      CustomText10: sessionStore?.currentSessionDetail?.CustomText10,
      CustomText11: sessionStore?.currentSessionDetail?.CustomText11,
      CustomText12: sessionStore?.currentSessionDetail?.CustomText12,
      CustomText13: sessionStore?.currentSessionDetail?.CustomText13,
      CustomText14: sessionStore?.currentSessionDetail?.CustomText14,
      CustomText15: sessionStore?.currentSessionDetail?.CustomText15,
      CustomText16: sessionStore?.currentSessionDetail?.CustomText16,
      CustomText17: sessionStore?.currentSessionDetail?.CustomText17,
      CustomText18: sessionStore?.currentSessionDetail?.CustomText18,
      CustomText19: sessionStore?.currentSessionDetail?.CustomText19,
      CustomText20: sessionStore?.currentSessionDetail?.CustomText20,
      CustomText21: sessionStore?.currentSessionDetail?.CustomText21,
      CustomText22: sessionStore?.currentSessionDetail?.CustomText22,
      CustomText23: sessionStore?.currentSessionDetail?.CustomText23,
      CustomText24: sessionStore?.currentSessionDetail?.CustomText24,
      CustomText25: sessionStore?.currentSessionDetail?.CustomText25,
      CustomText26: sessionStore?.currentSessionDetail?.CustomText26,
      CustomText27: sessionStore?.currentSessionDetail?.CustomText27,
      CustomText28: sessionStore?.currentSessionDetail?.CustomText28,
      CustomText29: sessionStore?.currentSessionDetail?.CustomText29,
      CustomText30: sessionStore?.currentSessionDetail?.CustomText30,
      CustomText31: sessionStore?.currentSessionDetail?.CustomText31,
      CustomText32: sessionStore?.currentSessionDetail?.CustomText32,
      CustomNotes01: sessionStore?.currentSessionDetail?.CustomNotes01,
      CustomNotes02: sessionStore?.currentSessionDetail?.CustomNotes02,
      CustomNotes03: sessionStore?.currentSessionDetail?.CustomNotes03,
      CustomNotes04: sessionStore?.currentSessionDetail?.CustomNotes04,
      CustomNotes05: sessionStore?.currentSessionDetail?.CustomNotes05,
      CustomNotes06: sessionStore?.currentSessionDetail?.CustomNotes06,
      CustomNotes07: sessionStore?.currentSessionDetail?.CustomNotes07,
      CustomNotes08: sessionStore?.currentSessionDetail?.CustomNotes08,
      CustomNotes09: sessionStore?.currentSessionDetail?.CustomNotes09,
      CustomNotes10: sessionStore?.currentSessionDetail?.CustomNotes10,
      CustomBoolean01: sessionStore?.currentSessionDetail?.CustomBoolean01,
      CustomBoolean02: sessionStore?.currentSessionDetail?.CustomBoolean02,
      CustomBoolean03: sessionStore?.currentSessionDetail?.CustomBoolean03,
      CustomBoolean04: sessionStore?.currentSessionDetail?.CustomBoolean04,
      CustomBoolean05: sessionStore?.currentSessionDetail?.CustomBoolean05,
      CustomBoolean06: sessionStore?.currentSessionDetail?.CustomBoolean06,
      CustomBoolean07: sessionStore?.currentSessionDetail?.CustomBoolean07,
      CustomBoolean08: sessionStore?.currentSessionDetail?.CustomBoolean08,
      CustomBoolean09: sessionStore?.currentSessionDetail?.CustomBoolean09,
      CustomBoolean10: sessionStore?.currentSessionDetail?.CustomBoolean10,
    });
  }, [scheduleStore?.activeScheduleDetail]);

  useEffect(() => {
    if (active) {
      setActiveTab("gallery");
    }
  }, [active]);

  useEffect(() => {
    if (!sessionStore?.isSelectToggle && !active) {
      setTimeout(() => {
        form?.resetFields();
        setActiveTab("session");
      }, 500);
    }
    if (sessionStore?.isSelectToggle && manageImagesStatus) {
      setActiveTab("gallery");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStore?.isSelectToggle, manageImagesStatus]);

  useEffect(() => {
    if (scheduleStore?.activeSchedule?.SessionKey) {
      galleryStore.setSelectedSessionKey(scheduleStore?.activeSchedule?.SessionKey);
      cartStore.setSelectedSessionKey(scheduleStore?.activeSchedule?.SessionKey);
    }
  }, [scheduleStore?.activeSchedule]);

  const toggleSessionDrawer = (toggle: boolean) => {
    onToggle && onToggle(toggle);
    sessionStore?.setSelectToggle(toggle);
    scheduleStore?.setActiveSchedule(undefined);
    scheduleStore?.setIsActiveTimeSelect(false);

    scheduleStore?.setActiveScheduleDetail(undefined);
    clientStore?.setActiveClientKey("");
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalShown(!isDeleteModalShown);
    scheduleStore?.setIsActiveTimeSelect(false);
  };

  const handleDelete = async () => {
    setIsDeleteModalShown(false);
    message.loading(`Deleting...`, 0.5);

    let key: string | undefined;

    if (scheduleStore?.activeSchedule?.AppointmentKey) {
      key = scheduleStore?.activeSchedule?.AppointmentKey;
    }

    if (scheduleStore?.activeSchedule?.SessionKey) {
      key = scheduleStore?.activeSchedule?.SessionKey;
    }

    try {
      await scheduleStore?.deleteSchedule(key, "Session").then(() => {
        message.success(
          `${
            authStore?.companySettings?.JobDescriptorSingular
              ? authStore?.companySettings?.JobDescriptorSingular
              : "Session"
          } successfully deleted!`
        );
        toggleSessionDrawer(false);
        sessionStore?.getSessionsViaSearch();
        handleSuccess();
        saveLocalStorage({
          name: "Deleted",
          value: `Session - ${
            clientStore?.clientInfo?.FirstName + " " + clientStore?.clientInfo?.LastName
          }`,
        });
      });
    } catch (e) {
      message.error(
        `Unable to delete ${
          authStore?.companySettings?.JobDescriptorSingular
            ? authStore?.companySettings?.JobDescriptorSingular
            : "Session"
        }. Try logging in again.`
      );
    }
  };

  const handleSuccess = async () => {
    try {
      await scheduleStore?.getCalendarList(
        scheduleStore?.filterUserKey,
        scheduleStore?.filterResourceKey
      );
      await sessionStore?.getSessionDetail(sessionStore?.sessionDetail?.Key);
      toggleSessionDrawer(false);
      // sessionStore?.setSelectToggle(true);
      setOpenCalender(false);
      setOpenCalenderForAppointment(false);
      scheduleStore?.setActiveSchedule({
        SessionKey: sessionStore?.sessionDetail?.Key,
      });
      setToggleSessionActionItems(true);
      setCurrentTab("Sessions");
    } catch (e) {}
    scheduleStore?.cleanTimeSelectCallback();
  };

  const handleError = () => {
    toggleSessionDrawer(false);
    setTimeout(() => {
      scheduleStore?.cleanTimeSelectCallback();
      message.error(
        `Unable to fetch ${
          authStore?.companySettings?.JobDescriptorSingular
            ? authStore?.companySettings?.JobDescriptorSingular
            : "Session"
        }. Does not exist or try logging in again.`
      );
    }, 500);
  };

  const invoicePrintRef = useRef<HTMLDivElement>(null);

  const handlePrintConfirmation = useReactToPrint({
    content: () => invoicePrintRef.current,
    // documentTitle: 'SessionConfirmation' + 'sessionStore?.activeInvoice?.InvoiceNumber'
    documentTitle: "SessionConfirmation17234",
  });

  const ModalHeader = () => {
    return (
      <>
        <Row gutter={20} style={{ height: "9rem" }} className="session-drawer-wrapper">
          <Col span={24}>
            <Row>
              <Col xs={24} sm={10}>
                <div className="sessionMetaDataWrapper">
                  <div className="sessionMetaIcon">
                    <img src="/assets/images/session-meta-icon.jpg" alt="" />
                  </div>
                  <div className="sessionMetaData">
                    <div>
                      <h3 className="sessionTitle">
                        {scheduleStore?.activeScheduleDetail?.Description ||
                          (authStore?.companySettings?.JobDescriptorSingular
                            ? authStore?.companySettings?.JobDescriptorSingular
                            : "Session")}
                      </h3>
                    </div>
                    <h5 className="sessionDate">
                      {scheduleStore?.activeScheduleDetail?.StartDateTime
                        ? dayjs(scheduleStore?.activeScheduleDetail?.StartDateTime).format(
                            "dddd, MMMM D, YYYY h:mm A"
                          )
                        : null}
                    </h5>
                  </div>
                </div>
              </Col>
              <Col
                xs={24}
                sm={14}
                style={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Space>
                  {scheduleStore?.activeSchedule !== undefined && (
                    <>
                      <Button
                        icon={<PrinterOutlined />}
                        // disabled={isLoading}
                        style={{
                          backgroundColor: "#16425B",
                          borderColor: "#16425B",
                          color: "white",
                        }}
                        className="ant-btn printConfirmationBtn"
                        shape="round"
                        onClick={handlePrintConfirmation}
                      >
                        Print Confirmation
                      </Button>
                      <Button
                        icon={<DeleteOutlined />}
                        className="btn-action"
                        style={{
                          backgroundColor: "#8E3B46",
                          borderColor: "#8E3B46",
                          color: "white",
                        }}
                        disabled={sessionStore?.isLoading}
                        onClick={toggleDeleteModal}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                  <Button
                    icon={<CheckOutlined />}
                    disabled={sessionStore?.isLoading}
                    className="btn-action"
                    style={{
                      backgroundColor: "rgb(255, 179, 28)",
                      borderColor: "rgb(255, 179, 28)",
                      color: "white",
                    }}
                    shape="round"
                    onClick={() =>
                      form.validateFields().then(() => {
                        form.submit();
                      })
                    }
                  >
                    Save
                  </Button>
                  {scheduleStore?.activeScheduleDetail?.Key && (
                    <Dropdown menu={menuProps} trigger={["click"]}>
                      <Button
                        className="btn-action"
                        style={{
                          backgroundColor: "#708D65",
                          borderColor: "#708D65",
                          color: "white",
                        }}
                      >
                        New
                      </Button>
                    </Dropdown>
                  )}
                </Space>
              </Col>
            </Row>
          </Col>
          {/* <Col span={24}>
            <div className="tabset">
              <input
                type="radio"
                name="tabset"
                id="sessionTab"
                aria-controls="session"
                checked={activeTab === "session"}
                readOnly
              />
              <label
                htmlFor="sessionTab"
                onClick={(e) => {
                  setActiveTab("session");
                }}
              >
                General
              </label>
              <input
                type="radio"
                name="tabset"
                id="galleryTab"
                aria-controls="gallery"
                checked={activeTab === "gallery"}
                readOnly
              />
              <label
                htmlFor="galleryTab"
                onClick={(e) => {
                  setActiveTab("gallery");
                }}
              >
                Images
              </label>
            </div>
          </Col> */}
        </Row>
      </>
    );
  };

  const drawerParams: DrawerProps = {
    width: Math.min(innerWidth, 700),
    placement: "right",
    closable: true,
    forceRender: false,
    destroyOnClose: true,
    title: <ModalHeader />,
    maskStyle: {},
    className: "drawer-form",
    push: {
      distance: 20,
    },
  };

  const handleClientAction = (option: any) => {
    switch (option.key) {
      case "Call":
        phoneCallStore?.setSelectToggle(true);

        break;
      case "Task":
        taskStore?.setSelectToggle(true);

        break;
      case "Appointment":
        appointmentStore?.setSelectToggle(true);

        break;
      case "Invoice":
        invoiceStore?.addInvoice({
          ClientKey: clientStore?.activeClientKey,
          SessionKey: scheduleStore?.activeSchedule?.SessionKey,
        });
        invoiceStore?.setCreatingNew(true);
        invoiceStore?.setDrawerType("detail");
        invoiceStore?.setSelectToggle(true);
        setNewInvoice(true);

        break;
      default:
        phoneCallStore?.setSelectToggle(false);
        taskStore?.setSelectToggle(false);
        appointmentStore?.setSelectToggle(false);
        invoiceStore?.setSelectToggle(false);
        sessionStore?.setSelectToggle(false);

        break;
    }
  };

  const items = [
    {
      label: "Call",
      key: "Call",
    },
    {
      label: "Appointment",
      key: "Appointment",
    },
    {
      label: "Task",
      key: "Task",
    },
    {
      label: "Invoice",
      key: "Invoice",
    },
  ];

  const menuProps = {
    items,
    onClick: handleClientAction,
  };

  const _renderTabView = () => {
    return (
      <>
        <section id="session">
          {/* <Row gutter={20} className="sticky-action-bar">
                <Col
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  <Space>
                    {scheduleStore?.activeSchedule !== undefined && (
                      <Button
                        icon={<DeleteOutlined />}
                        disabled={sessionStore?.isLoading}
                        type="text"
                        onClick={toggleDeleteModal}
                      >
                        Delete
                      </Button>
                    )}
                    <Button
                      icon={<CheckOutlined />}
                      disabled={sessionStore?.isLoading}
                      type="primary"
                      className="btn-action"
                      shape="round"
                      onClick={() =>
                        form.validateFields().then(() => {
                          form.submit();
                        })
                      }
                    >
                      Save
                    </Button>
                    <DropDown
                      options={createOptions}
                      selectedLabel={"Create a"}
                      onSelect={handleClientAction}
                    />
                  </Space>
                </Col>
              </Row> */}
          <Tabs defaultActiveKey={"1"} type="card" size={"small"}>
            <TabPane tab="General" key="1">
              <AddEditSession
                form={form}
                clientKey={clientKey}
                onSuccess={handleSuccess}
                onError={handleError}
                sessionCustomFields={sessionCustomFields}
              />
            </TabPane>
            <TabPane tab="Custom" key="2">
              <Row className="CustomFields">
                <Col xs={24} sm={12}>
                  <Col xs={24}>
                    <InputField
                      label="yb formal #"
                      value={sessionCustomFields.CustomText01}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText01: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="yb casual #1"
                      value={sessionCustomFields.CustomText02}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText02: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="yb casual #2"
                      value={sessionCustomFields.CustomText03}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText03: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Additional Participants"
                      value={sessionCustomFields.CustomText04}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText04: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 5"
                      value={sessionCustomFields.CustomText05}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText05: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 6"
                      value={sessionCustomFields.CustomText06}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText06: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 7"
                      value={sessionCustomFields.CustomText07}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText07: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 8"
                      value={sessionCustomFields.CustomText08}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText08: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 9"
                      value={sessionCustomFields.CustomText09}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText09: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 10"
                      value={sessionCustomFields.CustomText10}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText10: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 11"
                      value={sessionCustomFields.CustomText11}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText11: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 12"
                      value={sessionCustomFields.CustomText12}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText12: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 13"
                      value={sessionCustomFields.CustomText13}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText13: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 14"
                      value={sessionCustomFields.CustomText14}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText14: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 15"
                      value={sessionCustomFields.CustomText15}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText15: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 16"
                      value={sessionCustomFields.CustomText16}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText16: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 17"
                      value={sessionCustomFields.CustomText17}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText17: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 18"
                      value={sessionCustomFields.CustomText18}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText18: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 19"
                      value={sessionCustomFields.CustomText19}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText19: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 20"
                      value={sessionCustomFields.CustomText20}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText20: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 21"
                      value={sessionCustomFields.CustomText21}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText21: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 22"
                      value={sessionCustomFields.CustomText22}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText22: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 23"
                      value={sessionCustomFields.CustomText23}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText23: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 24"
                      value={sessionCustomFields.CustomText24}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText24: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 25"
                      value={sessionCustomFields.CustomText25}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText25: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 26"
                      value={sessionCustomFields.CustomText26}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText26: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 27"
                      value={sessionCustomFields.CustomText27}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText27: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 28"
                      value={sessionCustomFields.CustomText28}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText28: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 29"
                      value={sessionCustomFields.CustomText29}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText29: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 30"
                      value={sessionCustomFields.CustomText30}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText30: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 31"
                      value={sessionCustomFields.CustomText31}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText31: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom 32"
                      value={sessionCustomFields.CustomText32}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomText32: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <DateField
                      label="Custom Date 1"
                      suffixIcon={<></>}
                      allowClear={false}
                      value={dayjs(sessionCustomFields.CustomDate01)}
                      onChange={(e: any) => {
                        setSessionCustomFields({ ...sessionCustomFields, CustomDate01: e.$d });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <DateField
                      label="Custom Date 2"
                      suffixIcon={<></>}
                      allowClear={false}
                      value={dayjs(sessionCustomFields.CustomDate02)}
                      onChange={(e: any) => {
                        setSessionCustomFields({ ...sessionCustomFields, CustomDate02: e.$d });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <DateField
                      label="Custom Date 3"
                      suffixIcon={<></>}
                      allowClear={false}
                      value={dayjs(sessionCustomFields.CustomDate03)}
                      onChange={(e: any) => {
                        setSessionCustomFields({ ...sessionCustomFields, CustomDate03: e.$d });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <DateField
                      label="Custom Date 4"
                      suffixIcon={<></>}
                      allowClear={false}
                      value={dayjs(sessionCustomFields.CustomDate04)}
                      onChange={(e: any) => {
                        setSessionCustomFields({ ...sessionCustomFields, CustomDate04: e.$d });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <DateField
                      label="Custom Date 5"
                      suffixIcon={<></>}
                      allowClear={false}
                      value={dayjs(sessionCustomFields.CustomDate05)}
                      onChange={(e: any) => {
                        setSessionCustomFields({ ...sessionCustomFields, CustomDate05: e.$d });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <DateField
                      label="Custom Date 6"
                      suffixIcon={<></>}
                      allowClear={false}
                      value={dayjs(sessionCustomFields.CustomDate06)}
                      onChange={(e: any) => {
                        setSessionCustomFields({ ...sessionCustomFields, CustomDate06: e.$d });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <DateField
                      label="Custom Date 7"
                      suffixIcon={<></>}
                      allowClear={false}
                      value={dayjs(sessionCustomFields.CustomDate07)}
                      onChange={(e: any) => {
                        setSessionCustomFields({ ...sessionCustomFields, CustomDate07: e.$d });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <DateField
                      label="Custom Date 8"
                      suffixIcon={<></>}
                      allowClear={false}
                      value={dayjs(sessionCustomFields.CustomDate08)}
                      onChange={(e: any) => {
                        setSessionCustomFields({ ...sessionCustomFields, CustomDate08: e.$d });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <DateField
                      label="Custom Date 9"
                      suffixIcon={<></>}
                      allowClear={false}
                      value={dayjs(sessionCustomFields.CustomDate09)}
                      onChange={(e: any) => {
                        setSessionCustomFields({ ...sessionCustomFields, CustomDate09: e.$d });
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <DateField
                      label="Custom Date 10"
                      suffixIcon={<></>}
                      allowClear={false}
                      value={dayjs(sessionCustomFields.CustomDate10)}
                      onChange={(e: any) => {
                        setSessionCustomFields({ ...sessionCustomFields, CustomDate10: e.$d });
                      }}
                    />
                  </Col>
                </Col>
                <Col xs={24} sm={12}>
                  <Col xs={24}>
                    <InputField
                      label="Custom Note 1"
                      value={sessionCustomFields.CustomNotes01}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomNotes01: e.target.value,
                        });
                      }}
                      isTextarea
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom Note 2"
                      value={sessionCustomFields.CustomNotes02}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomNotes02: e.target.value,
                        });
                      }}
                      isTextarea
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom Note 3"
                      value={sessionCustomFields.CustomNotes03}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomNotes03: e.target.value,
                        });
                      }}
                      isTextarea
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom Note 4"
                      value={sessionCustomFields.CustomNotes04}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomNotes04: e.target.value,
                        });
                      }}
                      isTextarea
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom Note 5"
                      value={sessionCustomFields.CustomNotes05}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomNotes05: e.target.value,
                        });
                      }}
                      isTextarea
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom Note 6"
                      value={sessionCustomFields.CustomNotes06}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomNotes06: e.target.value,
                        });
                      }}
                      isTextarea
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom Note 7"
                      value={sessionCustomFields.CustomNotes07}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomNotes07: e.target.value,
                        });
                      }}
                      isTextarea
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom Note 8"
                      value={sessionCustomFields.CustomNotes08}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomNotes08: e.target.value,
                        });
                      }}
                      isTextarea
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom Note 9"
                      value={sessionCustomFields.CustomNotes09}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomNotes09: e.target.value,
                        });
                      }}
                      isTextarea
                    />
                  </Col>
                  <Col xs={24}>
                    <InputField
                      label="Custom Note 10"
                      value={sessionCustomFields.CustomNotes10}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomNotes10: e.target.value,
                        });
                      }}
                      isTextarea
                    />
                  </Col>
                  <Col xs={24}>
                    <Checkbox
                      checked={sessionCustomFields.CustomBoolean01}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomBoolean01: e.target.checked,
                        });
                      }}
                    >
                      Custom Boolean 1
                    </Checkbox>
                  </Col>
                  <Col xs={24}>
                    <Checkbox
                      checked={sessionCustomFields.CustomBoolean02}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomBoolean02: e.target.checked,
                        });
                      }}
                    >
                      Custom Boolean 2
                    </Checkbox>
                  </Col>
                  <Col xs={24}>
                    <Checkbox
                      checked={sessionCustomFields.CustomBoolean03}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomBoolean03: e.target.checked,
                        });
                      }}
                    >
                      Custom Boolean 3
                    </Checkbox>
                  </Col>
                  <Col xs={24}>
                    <Checkbox
                      checked={sessionCustomFields.CustomBoolean04}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomBoolean04: e.target.checked,
                        });
                      }}
                    >
                      Custom Boolean 4
                    </Checkbox>
                  </Col>
                  <Col xs={24}>
                    <Checkbox
                      checked={sessionCustomFields.CustomBoolean05}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomBoolean05: e.target.checked,
                        });
                      }}
                    >
                      Custom Boolean 5
                    </Checkbox>
                  </Col>
                  <Col xs={24}>
                    <Checkbox
                      checked={sessionCustomFields.CustomBoolean06}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomBoolean06: e.target.checked,
                        });
                      }}
                    >
                      Custom Boolean 6
                    </Checkbox>
                  </Col>
                  <Col xs={24}>
                    <Checkbox
                      checked={sessionCustomFields.CustomBoolean07}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomBoolean07: e.target.checked,
                        });
                      }}
                    >
                      Custom Boolean 7
                    </Checkbox>
                  </Col>
                  <Col xs={24}>
                    <Checkbox
                      checked={sessionCustomFields.CustomBoolean08}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomBoolean08: e.target.checked,
                        });
                      }}
                    >
                      Custom Boolean 8
                    </Checkbox>
                  </Col>
                  <Col xs={24}>
                    <Checkbox
                      checked={sessionCustomFields.CustomBoolean09}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomBoolean09: e.target.checked,
                        });
                      }}
                    >
                      Custom Boolean 9
                    </Checkbox>
                  </Col>
                  <Col xs={24}>
                    <Checkbox
                      checked={sessionCustomFields.CustomBoolean10}
                      onChange={(e: any) => {
                        setSessionCustomFields({
                          ...sessionCustomFields,
                          CustomBoolean10: e.target.checked,
                        });
                      }}
                    >
                      Custom Boolean 10
                    </Checkbox>
                  </Col>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Images" key="3">
              <section id="gallery">
                <SessionGallery />
              </section>
            </TabPane>
          </Tabs>
        </section>
      </>
    );
  };

  return (
    <>
      <div className="sessionDrawerOuterWrapper">
        <Drawer
          onClose={() => {
            sessionStore.currentSessionDetail = {};
            toggleSessionDrawer(false);
          }}
          visible={sessionStore?.isSelectToggle}
          {...drawerParams}
          className="session-drawer"
          headerStyle={{
            height: "9rem",
          }}
        >
          <div className="tab-panel-wrapper">{_renderTabView()}</div>
          <CheckoutWizard />
        </Drawer>
      </div>
      <Modal
        title={`Delete a session`}
        visible={isDeleteModalShown}
        onCancel={toggleDeleteModal}
        onOk={handleDelete}
      >
        <p>
          Are you sure you want to delete this{" "}
          {authStore?.companySettings?.JobDescriptorSingular
            ? authStore?.companySettings?.JobDescriptorSingular
            : "Session"}
          ?
        </p>
      </Modal>
      <ProCard className={`printWrapper ${showPrint ? "show" : "hide"}`}>
        <SessionPrintConfirmation
          sessionDetails={sessionStore?.currentSessionDetail}
          invoiceDetails={sessionStore?.currentSessionDetail.SessionInvoice}
          ref={invoicePrintRef}
        />
      </ProCard>
      <AppointmentDrawerForm
        sessionKey={sessionKey}
        clientKey={scheduleStore?.activeScheduleDetail?.ClientKey}
      />
      {!location.pathname.includes("tasks") && (
        <TaskDrawerForm
          sessionKey={sessionKey}
          clientKey={scheduleStore?.activeScheduleDetail?.ClientKey}
        />
      )}
      {!location.pathname.includes("calls") && (
        <PhoneCallDrawerForm
          // sessionKey={scheduleStore?.activeScheduleDetail.Key}
          clientKey={scheduleStore?.activeScheduleDetail?.ClientKey}
          activeClientKey={clientStore?.activeClientKey}
        />
      )}
    </>
  );
};

export default inject(
  "scheduleStore",
  "sessionStore",
  "galleryStore",
  "cartStore",
  "clientStore",
  "authStore",
  "invoiceStore",
  "taskStore",
  "phoneCallStore",
  "appointmentStore"
)(observer(SessionDrawerView));
