import React, { useEffect } from "react";
import { Drawer, Row, Col, Typography, Form, Space, Button } from "antd";
import { inject, observer } from "mobx-react";
import type { DrawerProps } from "antd/lib/drawer";
import useWindowDimension from "components/util/Dimension";
import SMSForm from "./SMSForm";
import { MessageStoreClass } from "stores/MessageStore";

const { Title } = Typography;

interface Props {
  messageStore?: MessageStoreClass;
  visible: boolean;
  setOpenSMSDrawer: any;
  authStore?: any;
  client?: any;
}

const SMSDrawer: React.FC<Props> = ({
  visible,
  setOpenSMSDrawer,
  messageStore,
  authStore,
  client,
  ...props
}) => {
  const { innerWidth } = useWindowDimension();
  const [form] = Form.useForm();

  useEffect(() => {
    const initFormValues: any = {
      ToNumber: client?.SMSToString,
      UserKey: authStore?.authenticatedUser.UserKey,
    };
    form.setFieldsValue({ ...initFormValues });
  }, [client, authStore]);

  const handleSubmit = () => {
    const formValues = form.getFieldsValue(true);
    messageStore?.sendSMS(formValues);
  };

  const DrawerContent = () => <SMSForm form={form} />;

  const ModalHeader = () => {
    return (
      <Row>
        <Col>
          <Title level={2}>Send SMS</Title>
        </Col>
        <Col
          xs={14}
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Space>
            <Button
              // disabled={isLoading}
              className="btn-action"
              style={{ backgroundColor: "#16425B", borderColor: "#16425B", color: "white" }}
              shape="round"
              onClick={handleSubmit}
            >
              Send
            </Button>
          </Space>
        </Col>
      </Row>
    );
  };

  const defaultParams: DrawerProps = {
    width: Math.min(innerWidth, 700),
    placement: "right",
    closable: true,
    forceRender: false,
    destroyOnClose: true,
    title: <ModalHeader />,
    maskStyle: {},
    className: "drawer-form drawer-padded",
    ...props,
  };

  return (
    <Drawer
      onClose={() => {
        setOpenSMSDrawer(false);
      }}
      {...defaultParams}
      visible={visible}
    >
      <DrawerContent />
    </Drawer>
  );
};

export default inject("messageStore")(observer(SMSDrawer));
