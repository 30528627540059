import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Button, Card, Row, Col, Modal, message } from "antd";
import GeneralForm from "./GeneralForm";

import { EditOutlined } from "@ant-design/icons";

const General: React.FC<any> = ({ authStore }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [type, setType] = useState("");

  const { CompanyName, AddressLine1, Country, PhoneNumber, Email, CompanyWebsiteUrl } =
    authStore.companyInfo;

  const ModalContent = () => {
    switch (type) {
      case "company_info":
        return <GeneralForm />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Card
        title={
          <div>
            Comapny Information{" "}
            <Button
              onClick={() => {
                setModalTitle("Company Info");
                setType("company_info");
                setIsModalOpen(true);
              }}
              type="link"
              icon={<EditOutlined />}
            ></Button>
          </div>
        }
        style={{ width: 1000 }}
      >
        <Row style={{ padding: "0.5rem 0" }}>
          <Col xs={4}>Company Name</Col>
          <Col xs={4}>{CompanyName}</Col>
        </Row>
        <Row style={{ padding: "0.5rem 0" }}>
          <Col xs={4}>Address</Col>
          <Col xs={4}>{AddressLine1}</Col>
        </Row>
        <Row style={{ padding: "0.5rem 0" }}>
          <Col xs={4}>Company Phone</Col>
          <Col xs={4}>{PhoneNumber}</Col>
        </Row>
        <Row style={{ padding: "0.5rem 0" }}>
          <Col xs={4}>Email</Col>
          <Col xs={4}>{Email}</Col>
        </Row>
        <Row style={{ padding: "0.5rem 0" }}>
          <Col xs={4}>Companny website</Col>
          <Col xs={4}>{CompanyWebsiteUrl}</Col>
        </Row>
      </Card>
      <Card
        title={
          <div>
            Location Information{" "}
            <Button
              onClick={() => setIsModalOpen(true)}
              type="link"
              icon={<EditOutlined />}
            ></Button>
          </div>
        }
        style={{ width: 1000, marginTop: "2rem" }}
      >
        <p>Comany Name: Mark Williams Studio Test</p>
        <p>Address</p>
      </Card>
      <Card
        title={
          <div>
            Location Information{" "}
            <Button
              onClick={() => setIsModalOpen(true)}
              type="link"
              icon={<EditOutlined />}
            ></Button>
          </div>
        }
        style={{ width: 1000, marginTop: "2rem" }}
      >
        <p>Comany Name: Mark Williams Studio Test</p>
        <p>Address</p>
      </Card>
      <Card
        title={
          <div>
            Location Information{" "}
            <Button
              onClick={() => setIsModalOpen(true)}
              type="link"
              icon={<EditOutlined />}
            ></Button>
          </div>
        }
        style={{ width: 1000, marginTop: "2rem" }}
      >
        <p>Comany Name: Mark Williams Studio Test</p>
        <p>Address</p>
      </Card>
      <Card
        title={
          <div>
            Location Information{" "}
            <Button
              onClick={() => setIsModalOpen(true)}
              type="link"
              icon={<EditOutlined />}
            ></Button>
          </div>
        }
        style={{ width: 1000, marginTop: "2rem" }}
      >
        <p>Comany Name: Mark Williams Studio Test</p>
        <p>Address</p>
      </Card>
      <Modal
        title={modalTitle}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => {
          message.success("Company Preferences Updated");
          setIsModalOpen(false);
        }}
      >
        <ModalContent />
      </Modal>
    </div>
  );
};

export default inject("authStore")(observer(General));
