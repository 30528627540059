import React from "react";
import { inject, observer } from "mobx-react";
import type { SelectProps } from "antd/lib/select";
import { ReconciliationOutlined } from "@ant-design/icons";

import SelectField from "./SelectField";
import type { ScheduleStoreClass } from "stores/ScheduleStore";
import type { SessionStoreClass } from "stores/SessionStore";

import "./Input.scss";

interface Props extends SelectProps<any> {
  scheduleStore?: ScheduleStoreClass;
  sessionStore?: SessionStoreClass;
}

const SessionTypesSelectField: React.FC<Props> = React.forwardRef(
  (
    { scheduleStore, sessionStore, ...props }: Props,
    _ref
  ): React.ReactElement => {
    const defaultProps: SelectProps<any> = {
      showSearch: true,
      options: sessionStore?.selectSessionList,
      optionFilterProp: "label",
      filterOption: (input, option) => {
        let isFound: number | undefined = option?.label
          ?.toString()
          .toLowerCase()
          .indexOf(input?.toLowerCase());
        return isFound !== undefined && isFound >= 0 ? true : false;
      },
      ...props,
    };

    return (
      <SelectField
        prefix={<ReconciliationOutlined />}
        disabled={props?.disabled}
        label="Types"
        showArrow={false}
        suffixIcon={<></>}
        {...defaultProps}
      />
    );
  }
);

export default inject(
  "scheduleStore",
  "sessionStore"
)(observer(SessionTypesSelectField));
