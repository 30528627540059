import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";
import { Divider, Menu, MenuProps, Select, SelectProps } from "antd";
import {
  CalendarOutlined,
  TeamOutlined,
  MessageOutlined,
  FileDoneOutlined,
  HomeOutlined,
  FieldTimeOutlined,
  PhoneOutlined,
  HistoryOutlined,
  CreditCardOutlined,
  SettingOutlined,
  SmileOutlined,
  SearchOutlined,
  AppstoreOutlined,
  CameraOutlined,
  QuestionCircleOutlined,
  DashboardOutlined,
  SyncOutlined,
  BankOutlined,
  SolutionOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";

import type { AuthStoreClass } from "stores/AuthStore";

import "./NavigationItems.scss";
import { NotificationStoreClass } from "stores/NotificationStore";

const { Item, SubMenu } = Menu;

interface Props {
  authStore?: AuthStoreClass;
  notificationStore?: NotificationStoreClass;
}

const NavigationItems: React.FC<Props> = ({ authStore, notificationStore }: Props) => {
  const rootSubmenuKeys = ["search", "dashboardreports"];
  const [openKeys, setOpenKeys] = useState([""]);
  const location = useLocation();
  const history = useHistory();
  const map: any = {
    "/home": "home",
    "/quickaccess": "quickaccess",
    "/schedule": "schedule",
    "/clientshub": "clients",
    "/messages": "messages",
    "/calls": "calls",
    "/tasks": "tasks",
    "/notifications": "notifications",
    "/clock": "clock",
    "/invoice": "invoice",
    "/recent": "recent",
    "/joy": "joy",
    "/search": "search",
    "/dashboardreports": "dashboardreports",
    "/dashboardreports/reports": "companydashboard",
    "/dashboardreports/personaldashboard": "personaldashboard",
    "/workflow": "workflow",
  };

  const searchLocation = useLocation<any>();

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleHistoryPush = (url: string) => {
    history.push(url);
    authStore?.setIsNavOpen(false);
  };

  const handleSearchHistoryPush = (url: string, searchType: string) => {
    history.push({
      pathname: url,
      state: { searchType: searchType },
    });
  };

  const handleMenuSelect = (values: any) => {
    if (
      values.key === "sessionSearch" ||
      values.key === "clientSearch" ||
      values.key === "invoiceSearch" ||
      values.key === "companydashboard" ||
      values.key === "personaldashboard"
    ) {
    } else {
      setOpenKeys([]);
    }
  };

  const options: SelectProps["options"] = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }

  const anneGeddesNavigation = (
    <>
      <Item key="features" role="heading" disabled>
        Features
      </Item>
      <Menu.Item
        key="settings"
        onClick={() => handleHistoryPush("/settings")}
        icon={<SettingOutlined />}
      >
        Settings
      </Menu.Item>
      <Menu.Item key="joy" onClick={() => handleHistoryPush("/joy")} icon={<SmileOutlined />}>
        Approve Joy Images
      </Menu.Item>
    </>
  );

  return (
    <Menu
      mode="inline"
      theme="dark"
      selectedKeys={[map[`/${location.pathname.split("/")[1]}`]]}
      className="main-navigation"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      onSelect={(values) => {
        handleMenuSelect(values);
      }}
    >
      {authStore?.accountName.toLowerCase() === "annegeddes" ? (
        anneGeddesNavigation
      ) : (
        <>
          {authStore?.locations && authStore.locations.length > 1 && (
            <Item icon={<EnvironmentOutlined />} className="locationDropdown">
              <Select
                placeholder={`All ${
                  authStore?.companySettings?.LocationDescriptorPlural
                    ? authStore?.companySettings?.LocationDescriptorPlural
                    : "Locations"
                }`}
                style={{ width: "100%" }}
                options={authStore?.sortedLocations}
                className="container-dropdown"
                defaultValue={authStore?.locationNo != "" ? authStore?.locationNo : "All locations"}
                onChange={(value, options) => {
                  authStore?.setLocation(value);
                }}
              />
            </Item>
          )}

          <Item key="home" onClick={() => handleHistoryPush("/home")} icon={<HomeOutlined />}>
            Home
          </Item>

          <Item
            key="quickaccess"
            onClick={() => handleHistoryPush("/quickaccess")}
            icon={<AppstoreOutlined />}
          >
            Quick Access
          </Item>
          <SubMenu
            key="search"
            onTitleClick={() => handleSearchHistoryPush("/search", "Clients")}
            title="Search"
            icon={<SearchOutlined />}
          >
            <Item
              className={`submenu ${
                searchLocation.state?.searchType == "Clients"
                  ? "ant-menu-item-selected"
                  : "ant-menu-item-not-selected"
              }`}
              key="clientSearch"
              onClick={() => handleSearchHistoryPush("/search", "Clients")}
              icon={<TeamOutlined />}
            >
              Clients
            </Item>
            <Item
              className={`submenu ${
                searchLocation.state?.searchType == "Sessions"
                  ? "ant-menu-item-selected"
                  : "ant-menu-item-not-selected"
              }`}
              key="sessionSearch"
              onClick={() => handleSearchHistoryPush("/search", "Sessions")}
              icon={<CameraOutlined />}
            >
              {authStore?.companySettings.JobDescriptorPlural
                ? authStore?.companySettings.JobDescriptorPlural
                : "Sessions"}
            </Item>
            <Item
              className={`submenu ${
                searchLocation.state?.searchType == "Invoices"
                  ? "ant-menu-item-selected"
                  : "ant-menu-item-not-selected"
              }`}
              key="invoiceSearch"
              onClick={() => handleSearchHistoryPush("/search", "Invoices")}
              icon={<CreditCardOutlined />}
            >
              Invoices
            </Item>
          </SubMenu>
          {/* onTitleClick={() => handleHistoryPush("/reports")} */}
          <SubMenu key="dashboardreports" title="Dashboard" icon={<DashboardOutlined />}>
            <Item
              className={`submenu ${
                history.location.pathname == "/dashboardreports/reports"
                  ? "ant-menu-item-selected"
                  : "ant-menu-item-not-selected"
              }`}
              key="companydashboard"
              onClick={() => handleHistoryPush("/dashboardreports/reports")}
              icon={<BankOutlined />}
            >
              Company
            </Item>
            <Item
              className={`submenu ${
                history.location.pathname == "/dashboardreports/personaldashboard"
                  ? "ant-menu-item-selected"
                  : "ant-menu-item-not-selected"
              }`}
              key="personaldashboard"
              onClick={() => handleHistoryPush("/dashboardreports/personaldashboard")}
              icon={<SolutionOutlined />}
            >
              Personal
            </Item>
          </SubMenu>
          <Divider style={{ backgroundColor: "white" }} />
          {process.env.NODE_ENV === "development" && (
            <Item
              key="workflow"
              onClick={() => handleHistoryPush("/workflow")}
              icon={<SyncOutlined />}
            >
              Workflow
            </Item>
          )}

          <Item
            key="schedule"
            onClick={() => handleHistoryPush("/schedule")}
            icon={<CalendarOutlined />}
          >
            Calendar
          </Item>
          <Item
            key="messages"
            onClick={() => handleHistoryPush("/messages")}
            icon={<MessageOutlined />}
          >
            Messages
          </Item>
          <Item key="calls" onClick={() => handleHistoryPush("/calls")} icon={<PhoneOutlined />}>
            Calls
          </Item>
          <Item key="tasks" onClick={() => handleHistoryPush("/tasks")} icon={<FileDoneOutlined />}>
            Tasks
          </Item>
          <Item
            key="clock"
            onClick={() => handleHistoryPush("/clock")}
            icon={<FieldTimeOutlined />}
          >
            Time Clock
          </Item>
          {/* <Item
            key="invoice"
            onClick={() => handleHistoryPush("/invoice")}
            icon={<CreditCardOutlined />}
          >
            Process Payment
          </Item> */}
          <Divider style={{ backgroundColor: "white" }} />
          <Item
            key="recent"
            onClick={() => handleHistoryPush("/recent")}
            icon={<HistoryOutlined />}
          >
            Recent
          </Item>
          <Item
            key="help"
            onClick={() =>
              window.open(
                "https://secure.studioplussoftware.com/help/support_center_new2020.aspx",
                "_blank"
              )
            }
            icon={<QuestionCircleOutlined />}
          >
            Help
          </Item>
        </>
      )}
    </Menu>
  );
};

export default inject("authStore", "notificationStore")(observer(NavigationItems));
