import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Form, Radio } from "antd";

import type { FormInstance } from "antd/lib/form/hooks/useForm";

import type { TaskStoreClass } from "stores/TaskStore";
import type { UserStoreClass } from "stores/UserStore";
import { AuthStoreClass } from "stores/AuthStore";

interface Props {
  form?: FormInstance;
  userStore?: UserStoreClass;
  taskStore?: TaskStoreClass;
  authStore?: AuthStoreClass;
}

const FilterTask: React.FC<Props> = ({
  form,
  authStore,
  taskStore,
  userStore,
}): React.ReactElement => {
  const initialFormValues = {
    user: "",
  };

  useEffect(() => {
    form?.setFieldsValue({
      user: authStore?.authenticatedUser?.UserKey,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore?.authenticatedUser]);

  const handleSubmit = (values: any) => {
    taskStore?.setUserKeyFilter(values.user);
  };

  return (
    <Form form={form} initialValues={initialFormValues} onFinish={handleSubmit}>
      <Form.Item label="User" name="user">
        <Radio.Group>
          {userStore?.selectUserList?.map((item) => {
            return (
              <Radio
                key={item.value}
                value={item.value}
                style={{ display: "block", marginTop: 5 }}
              >
                {item.label}
              </Radio>
            );
          })}
        </Radio.Group>
      </Form.Item>
    </Form>
  );
};

export default inject(
  "authStore",
  "taskStore",
  "userStore"
)(observer(FilterTask));
