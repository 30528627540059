import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";

import { Button, message } from "antd";

import ProCard from "@ant-design/pro-card";
import "../QuickAccessPage.scss";
import {
  PrinterOutlined,
  CreditCardOutlined,
  SendOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  StopOutlined,
  FileImageOutlined,
  VideoCameraAddOutlined,
} from "@ant-design/icons";

import { ClientStoreClass } from "stores/ClientStore";
import { SessionStoreClass } from "stores/SessionStore";
import { InvoiceStoreClass } from "stores/InvoiceStore";
import { AuthStoreClass } from "stores/AuthStore";
import SessionPrintConfirmation from "./PrintConfirmation";
import { inject, observer } from "mobx-react";
// import QAImageDetailPrint from "../QAImageDetailPrint";
interface Props {
  clientStore: ClientStoreClass;
  handleClick: any;
  sessionStore?: SessionStoreClass;
  invoiceStore?: InvoiceStoreClass;
  setOpenCalender?: any;
  setManageImagesStatus?: any;
  setShowInvoice?: any;
  showInvoice?: boolean
  currentTab?: string
  getInvoiceDetail?: any;
  setNewSessionInvoice?: any;
  newSessionInvoice?: boolean;
  authStore?: AuthStoreClass
}

const QASessionActionItems: React.FC<Props> = ({
  clientStore,
  sessionStore,
  invoiceStore,
  setManageImagesStatus,
  setShowInvoice,
  getInvoiceDetail,
  setNewSessionInvoice,
  newSessionInvoice,
  authStore

}) => {
  const [confirmed, setConfirmed] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [onHold, setOnHold] = useState(false);
  const [noShow, setNoShow] = useState(false);
  const [showPrint] = useState(false);

  useEffect(() => {
    if (sessionStore?.currentSessionDetail.Confirmed) setConfirmed(true);
    if (sessionStore?.currentSessionDetail.Canceled) setCancelled(true);
    if (sessionStore?.currentSessionDetail.OnHold) setOnHold(true);
    if (sessionStore?.currentSessionDetail.NoShow) setNoShow(true);
  }, [sessionStore?.currentSessionDetail]);

  const confirmHandler = async () => {
    if (confirmed) {
      setConfirmed(false);
      await sessionStore?.addSession({
        Confirmed: false,
        Key: sessionStore?.currentSessionDetail?.Key,
      });
      message.success(`${authStore?.companySettings.JobDescriptorSingular ? authStore?.companySettings.JobDescriptorSingular : "Session"} successfully updated!`);
    } else {
      setConfirmed(true);
      await sessionStore?.addSession({
        Confirmed: true,
        Key: sessionStore?.currentSessionDetail?.Key,
      });
      message.success(`${authStore?.companySettings.JobDescriptorSingular ? authStore?.companySettings.JobDescriptorSingular : "Session"} successfully updated!`);
    }
  };

  const cancelHandler = async () => {
    if (cancelled) {
      setCancelled(false);
      await sessionStore?.addSession({
        Canceled: false,
        Key: sessionStore?.currentSessionDetail?.Key,
      });
      message.success(`${authStore?.companySettings.JobDescriptorSingular ? authStore?.companySettings.JobDescriptorSingular : "Session"} successfully updated!`);
    } else {
      setCancelled(true);
      await sessionStore?.addSession({
        Canceled: true,
        Key: sessionStore?.currentSessionDetail?.Key,
      });
      message.success(`${authStore?.companySettings.JobDescriptorSingular ? authStore?.companySettings.JobDescriptorSingular : "Session"} successfully updated!`);
    }
  };

  const onHoldHandler = async () => {
    if (onHold) {
      setOnHold(false);
      await sessionStore?.addSession({
        OnHold: false,
        Key: sessionStore?.currentSessionDetail?.Key,
      });
      message.success(`${authStore?.companySettings.JobDescriptorSingular ? authStore?.companySettings.JobDescriptorSingular : "Session"} successfully updated!`);
    } else {
      setOnHold(true);
      await sessionStore?.addSession({
        OnHold: true,
        Key: sessionStore?.currentSessionDetail?.Key,
      });
      message.success(`${authStore?.companySettings.JobDescriptorSingular ? authStore?.companySettings.JobDescriptorSingular : "Session"} successfully updated!`);
    }
  };

  const noShowHandler = async () => {
    if (noShow) {
      setNoShow(false);
      await sessionStore?.addSession({
        NoShow: false,
        Key: sessionStore?.currentSessionDetail?.Key,
      });
      message.success(`${authStore?.companySettings.JobDescriptorSingular ? authStore?.companySettings.JobDescriptorSingular : "Session"} successfully updated!`);
    } else {
      setNoShow(true);
      await sessionStore?.addSession({
        NoShow: true,
        Key: sessionStore?.currentSessionDetail?.Key,
      });
      message.success(`${authStore?.companySettings.JobDescriptorSingular ? authStore?.companySettings.JobDescriptorSingular : "Session"} successfully updated!`);
    }
  };

  const invoicePrintRef = useRef<HTMLDivElement>(null);
  // const imageDetailPrintRef = useRef<HTMLDivElement>(null);

  const handlePrintConfirmation = useReactToPrint({
    content: () => invoicePrintRef.current,
    // documentTitle: 'SessionConfirmation' + 'sessionStore?.activeInvoice?.InvoiceNumber'
    documentTitle: "SessionConfirmation17234",
  });


  return (
    <>
      <ProCard gutter={[8, 8]} style={{ padding: "inherit" }} wrap ghost className="ProCardBtn">
        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
          <Button block className="ant-btn-default-green" onClick={handlePrintConfirmation}>
            <PrinterOutlined />
            <br />
            Print Confirmation
          </Button>
        </ProCard>
        {/* <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
          <Button block className="ant-btn-default-green" onClick={handleImageDetailPrint}>
            <PrinterOutlined />
            <br />
            Print Image Detail sheet
          </Button>
        </ProCard> */}

        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
          <Button
            block
            className="ant-btn-default-green"
            onClick={() => {
              setNewSessionInvoice(true);
              invoiceStore?.setDrawerType("detail");
              invoiceStore?.setSelectToggle(true);
            }}
          >
            <CreditCardOutlined />
            <br />
            New Invoice
          </Button>
        </ProCard>
        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
          <Button
            block
            className="ant-btn-default-green"
            onClick={() => {
              getInvoiceDetail();
              setShowInvoice(true);
              //   if (
              //     !invoiceStore?.activeInvoice ||
              //     Object.keys(invoiceStore.activeInvoice).length === 0
              //   ) {
              //     message.info("No invoice found for this session");
              //     return;
              //   }
              //   invoiceStore?.setDrawerType("detail");
              //   invoiceStore?.setSelectToggle(true);
            }}
          >
            <CreditCardOutlined />
            <br />
            View {authStore?.companySettings.JobDescriptorSingular ? authStore?.companySettings.JobDescriptorSingular : "Session"} Invoices
          </Button>
        </ProCard>

        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
          <Button block className="ant-btn-default-green">
            <SendOutlined />
            <br />
            Send Contract
          </Button>
        </ProCard>
        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
          <Button
            block
            className={`${confirmed ? "ant-btn-default-green-active" : "ant-btn-default-green"}`}
            onClick={() => confirmHandler()}
          >
            <CheckCircleOutlined />
            <br />
            {confirmed ? "Confirmed" : "Confirm"} {authStore?.companySettings.JobDescriptorSingular ? authStore?.companySettings.JobDescriptorSingular : "Session"}
          </Button>
        </ProCard>
        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
          <Button
            block
            className={`${cancelled ? "ant-btn-default-red-active" : "ant-btn-default-red"}`}
            onClick={() => cancelHandler()}
          >
            <CloseCircleOutlined />
            <br />
            {cancelled ? "Canceled" : "Cancel"} {authStore?.companySettings.JobDescriptorSingular ? authStore?.companySettings.JobDescriptorSingular : "Session"}
          </Button>
        </ProCard>
        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
          <Button
            block
            className={`${onHold ? "ant-btn-default-yellow-active" : "ant-btn-default-yellow"} ${confirmed ? "disabled" : ""
              }`}
            onClick={() => {
              if (!confirmed) onHoldHandler();
            }}
          >
            <ClockCircleOutlined />
            <br />
            On Hold
          </Button>
        </ProCard>
        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
          <Button
            block
            className={`${noShow ? "ant-btn-default-red-active" : "ant-btn-default-red"} ${confirmed ? "disabled" : ""
              }`}
            onClick={() => {
              if (!confirmed) noShowHandler();
            }}
          >
            <StopOutlined />
            <br />
            No Show
          </Button>
        </ProCard>
        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
          <Button
            onClick={() => {
              sessionStore?.setSelectToggle(true);
              setManageImagesStatus(true);
            }}
            block
            className="ant-btn-default-blue"
          >
            {/* <UploadOutlined /> */}
            <FileImageOutlined />
            <br />
            Manage Images
          </Button>
        </ProCard>
        {/* <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
          <Button block className="ant-btn-default-blue">
            <FileImageOutlined />
            <br />
            View Images
          </Button>
        </ProCard> */}
        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} ghost className="ProCardBtn">
          <Button
            onClick={() => {
              sessionStore?.setSelectToggle(true);
              setManageImagesStatus(false);
            }}
            block
            className="ant-btn-default-blue"
          >
            <VideoCameraAddOutlined />
            <br />
            Reschedule {authStore?.companySettings.JobDescriptorSingular ? authStore?.companySettings.JobDescriptorSingular : "Session"}
          </Button>
        </ProCard>
      </ProCard>
      <ProCard className={`printWrapper ${showPrint ? "show" : "hide"}`}>
        <SessionPrintConfirmation
          sessionDetails={sessionStore?.currentSessionDetail}
          invoiceDetails={sessionStore?.currentSessionDetail.SessionInvoice}
          ref={invoicePrintRef}
        />
      </ProCard>
    </>
  );
};

export default inject("authStore")(observer(QASessionActionItems));
