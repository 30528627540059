import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Select, message, Row, Col, Affix } from "antd";

import type { SearchProps } from "antd/lib/input";
import { InvoiceStoreClass } from "stores/InvoiceStore";
import { AuthStoreClass } from "stores/AuthStore";
import "./SearchInvoice.scss";
import SearchInput from "./SearchInput";



export interface OnSearchProps {
  action:
  | "ClientNumber"
  | "SessionNumber"
  | "InvoiceNumber"
  | "FirstName"
  | "LastName";
  value: string;
}

interface Props extends SearchProps {
  searchClass?: string;
  invoiceStore?: InvoiceStoreClass;
  authStore?: AuthStoreClass;
}

const SearchInvoice: React.FC<Props> = ({
  searchClass,
  invoiceStore,
  authStore,
  ...props
}): React.ReactElement => {
  const [action, setAction] = useState<
    | "ClientNumber"
    | "SessionNumber"
    | "InvoiceNumber"
    | "FirstName"
    | "LastName"
  >("LastName");
  const [container] = useState<HTMLDivElement | null>(null);
  const [value, setValue] = useState<string>("");

  const types = [
    {
      label: "Client Last Name",
      value: "LastName",
    },
    {
      label: "Client First Name",
      value: "FirstName",
    },
    {
      label: "Client Number",
      value: "ClientNumber",
    },
    {
      label: "Invoice Number",
      value: "InvoiceNumber",
    },
    {
      label: `${authStore?.companySettings?.JobDescriptorSingular ? authStore?.companySettings?.JobDescriptorSingular : "Session"} Number`,
      value: "SessionNumber",
    },
  ];

  const handleSearch = async (v: string) => {
    if (v === "") {
      return;
    }

    try {
      const data: OnSearchProps = { action, value: v };
      invoiceStore?.setActiveSearch(data);
      // await invoiceStore?.getInvoiceList(data);
      await invoiceStore?.getInvoices(data); // Changed the search function based on new API
    } catch (e) {
      message.error("Unable to fetch invoices. Try logging in again.");
    }
  };

  useEffect(() => {
    setValue(invoiceStore?.activeSearch?.value ? invoiceStore?.activeSearch?.value : '');
  }, [invoiceStore?.activeSearch])

  return (
    <Row
      style={{ alignItems: "center", background: '#fff' }}
      gutter={[16, 16]}
      align="middle"
      justify="center"
    >
      <Col xs={12} sm={4}>
        <Select
          options={types}
          defaultValue={invoiceStore?.activeSearch?.action}
          placeholder="Type"
          onChange={(v) => setAction(v)}
          size={"large"}
        />
      </Col>
      <Col xs={24} sm={20}>
        <Affix target={() => container} offsetTop={70}>
          <SearchInput
            searchClass="search-listing"
            onKeyUp={(e: any) => {
              handleSearch(e.target.value);
            }}
            onChange={(e: any) => {
              setValue(e.target.value);
            }}
            onPressEnter={(e: any) => {
              handleSearch(e.target.value);
              // clearTimeout(timeout);
            }}
            value={value}
          />
        </Affix>
      </Col>
    </Row>
  );
};

export default inject("invoiceStore", "authStore")(observer(SearchInvoice));
