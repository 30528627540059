import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Button, Row, Col, Grid, Modal, Typography, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import {
  getLocalStorage,
  LocalStorageProps,
  clearLocalStorage,
} from "components/util/localStorage";
import type { AuthStoreClass } from "stores/AuthStore";
import CustomHeader from "components/header/CustomHeader";

const { Title } = Typography;
const { useBreakpoint } = Grid;

interface Props {
  authStore?: AuthStoreClass;
}

const ActionsRecent: React.FC<Props> = ({ authStore }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [data, setData] = useState<Array<LocalStorageProps>>();
  const screens = useBreakpoint();

  useEffect(() => {
    const storage = getLocalStorage();
    setData(storage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore?.toggleLocalStorage]);

  const handleClear = () => {
    clearLocalStorage();
    toggleModal();
    message.success("Successfully cleared!");
  };

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <>
      <CustomHeader title="Recent">
        {data && data?.length > 0 && (
          <Col style={{ marginLeft: "auto" }}>
            <Button icon={<DeleteOutlined />} type="text" onClick={toggleModal}>
              Clear List
            </Button>
          </Col>
        )}
      </CustomHeader>
      <Modal
        title="Confirm clear"
        visible={isModalVisible}
        okText="Yes, clear now"
        onOk={handleClear}
        onCancel={toggleModal}
      >
        <p>Are you sure you want to clear the recent list?</p>
      </Modal>
    </>
  );
};

export default inject("authStore")(observer(ActionsRecent));
