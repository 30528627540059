import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Form, Input, Row, Col, Grid, message, Switch } from "antd";
import {
  AlignLeftOutlined,
  SoundOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  ProfileOutlined,
  ClusterOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  OrderedListOutlined,
  TagOutlined,
  NumberOutlined,
  DownOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import type { FormInstance } from "antd/lib/form/hooks/useForm";

import ClientsSelectField from "./items/ClientsSelectField";
import DateField from "./items/DateField";
import InputField from "./items/InputField";
import UsersSelectField from "./items/UsersSelectField";
import SelectField from "./items/SelectField";
import ClientRow from "../rows/ClientRow";
import type { Department, TaskDetails, TaskFormValues, TaskTypes } from "interfaces/task.int";
import type { TaskStoreClass } from "stores/TaskStore";
import { ClientStoreClass } from "stores/ClientStore";
import { AuthStoreClass } from "stores/AuthStore";
import { UserStoreClass } from "stores/UserStore";
const { useBreakpoint } = Grid;

const formPriority = [
  ...Array.from({ length: 10 }, (_v, k) => {
    return {
      label: k + 1,
      value: k + 1,
    };
  }),
];

interface Props {
  form?: FormInstance;
  id?: string;
  onSuccess?: () => void;
  onError?: () => void;
  taskStore?: TaskStoreClass;
  clientStore?: ClientStoreClass;
  authStore?: AuthStoreClass;
  activeClientKey?: any;
  userStore?: UserStoreClass;
  clientKey?: string;
  sessionKey?: string;
}

const AddEditTask: React.FC<Props> = ({
  form,
  id,
  onSuccess,
  onError,
  taskStore,
  clientStore,
  authStore,
  activeClientKey,
  clientKey = "",
  sessionKey = "",
  userStore,
  ...props
}): React.ReactElement => {
  const [isEdit, setIsEdit] = useState(false);
  const screens = useBreakpoint();
  const isLoading: boolean = taskStore?.isLoading || false;
  const [taskType, setTaskType] = useState("");
  const [TaskStatuses, setTaskStatuses] = useState<any>([]);
  const [isDepartmentEmpty, setIsDepartmentEmpty] = useState<Boolean>(true);
  const formGrid = (isEnd = false, inForce = true) => {
    const marginVar = inForce ? 10 : "";
    return {
      xs: 24,
      sm: 12,
      style: {
        marginBottom: isEnd ? "" : marginVar,
      },
    };
  };

  const initialFormValues: TaskFormValues = {
    key: "",
    ClientKey: clientKey,
    SessionKey: sessionKey,
    TaskSubject: "",
    TaskDescription: "",
    DueDate: "",
    CompleteDate: "",
    Department: "",
    Urgent: false,
    Personal: false,
    TaskTypeKey: "",
    TaskStatusKey: "",
    Priority: 1,
    AssignedByUserKey: authStore?.authenticatedUser.UserKey,
    AssignedToUserKey: authStore?.authenticatedUser.UserKey,
  };

  useEffect(() => {
    return () => {
      taskStore?.setTaskDetails(undefined);
    };
  }, []);

  useEffect(() => {
    setTaskStatuses([]);
    if (taskType) {
      let taskStatuses: any = [];
      taskStore?.taskStatuses.map((status: any) => {
        if (status.TaskTypeID == taskType) {
          taskStatuses.push(status);
        }
      });
      setTaskStatuses(taskStatuses);
    }
  }, [taskType]);

  const onFormLayoutChange = (values: any) => {
    if (values.TaskTypeKey) setTaskType(values.TaskTypeKey);
    if (values.AssignedToDepartmentKey != "") {
      userStore?.getUsersListByDepartment(values.AssignedToDepartmentKey, "taskUser");
      setIsDepartmentEmpty(false);
    }
  };
  useEffect(() => {
    if (typeof taskStore?.taskDetails !== "undefined") {
      setIsEdit(true);
      const d: TaskDetails = taskStore?.taskDetails;
      form?.setFieldsValue({
        key: d.Key,
        ClientKey: d.ClientKey,
        TaskSubject: d.TaskSubject,
        TaskDescription: d.TaskDescription,
        DueDate: d.DueDate ? dayjs(d.DueDate) : undefined,
        CompleteDate: d.CompleteDate ? dayjs(d.CompleteDate) : undefined,
        AssignedToDepartmentKey: d.AssignedToDepartmentKey,
        Urgent: d.Urgent,
        Personal: d.Personal,
        TaskTypeKey: d.TaskTypeKey,
        TaskStatusKey: d.TaskStatusKey,
        Priority: d.Priority,
        AssignedByUserKey: d.AssignedByUserKey,
        AssignedToUserKey: d.AssignedToUserKey,
      });
      if (taskStore?.taskDetails.TaskTypeKey) setTaskType(taskStore?.taskDetails.TaskTypeKey);
      if (taskStore?.taskDetails.AssignedToDepartmentKey !== "") {
        userStore?.getUsersListByDepartment(
          taskStore?.taskDetails.AssignedToDepartmentKey,
          "taskUser"
        );
        setIsDepartmentEmpty(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskStore?.taskDetails]);

  const handleSubmit = async (values: any) => {
    if (values.DueDate) {
      values.DueDate = dayjs(values.DueDate).format("YYYY-MM-DDTHH:mm:ss");
    }
    if (values.CompleteDate) {
      values.CompleteDate = dayjs(values.CompleteDate).format("YYYY-MM-DDTHH:mm:ss");
    }
    if (taskStore?.taskDetails?.Key) {
      let changedData: any = {};
      for (let line in values) {
        if (
          values[line as keyof TaskDetails] !== taskStore?.taskDetails[line as keyof TaskDetails]
        ) {
          changedData[line] = values[line as keyof TaskDetails];
        }
      }
      if (Object.keys(changedData).length === 0) {
        message.warning("No data changed");
        return;
      }

      values = { Key: values?.key, ...changedData };
      await taskStore?.editTask(values);
    } else {
      await taskStore?.addTask(values);
    }

    taskStore?.setSelectToggle(false);
    onSuccess && onSuccess();
  };

  return (
    <Form
      onFinish={handleSubmit}
      onValuesChange={onFormLayoutChange}
      form={form}
      initialValues={initialFormValues}
    >
      <Form.Item label="Key" name="key" hidden={true}>
        <Input id="key" disabled={isLoading} />
      </Form.Item>

      {taskStore?.taskDetails?.Key && taskStore?.taskDetails?.ClientKey ? (
        <div className="form-group">
          <Form.Item
            name="ClientKey"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <ClientRow clientKey={taskStore?.taskDetails?.ClientKey || activeClientKey} />
          </Form.Item>
        </div>
      ) : (
        <div className="form-group">
          <Form.Item
            name="ClientKey"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <ClientsSelectField form={form} />
          </Form.Item>
        </div>
      )}

      <Row className="form-group" gutter={20}>
        <Col
          xs={24}
          style={{
            marginBottom: 10,
          }}
        >
          <Form.Item
            name="TaskSubject"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
            rules={[
              {
                required: true,
                message: "Please select subject!",
              },
            ]}
          >
            <InputField prefix={<SoundOutlined />} disabled={isLoading} label="Subject" />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          style={{
            marginBottom: 10,
          }}
        >
          <Form.Item
            name="TaskDescription"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <InputField
              prefix={<AlignLeftOutlined />}
              disabled={isLoading}
              label="Description"
              isTextarea={true}
            />
          </Form.Item>
        </Col>
        <Col {...formGrid(false, !screens.sm)}>
          <Form.Item
            name="DueDate"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <DateField
              label="Due Date"
              prefix={<CalendarOutlined />}
              disabled={isLoading}
              suffixIcon={<></>}
              allowClear={false}
              placeholder=""
              showTime={false}
            />
          </Form.Item>
        </Col>
        <Col {...formGrid(true)}>
          <Form.Item
            name="CompleteDate"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <DateField
              label="Completed On"
              prefix={<CarryOutOutlined />}
              disabled={isLoading}
              suffixIcon={<></>}
              allowClear={false}
              placeholder=""
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="form-group">
        <Col style={{ width: "3.5rem", paddingTop: 6 }}>
          <ProfileOutlined style={{ fontSize: 21 }} />
        </Col>
        <Col style={{ flexGrow: 1 }}>
          <Form.Item
            label="Urgent"
            name="Urgent"
            valuePropName="checked"
            className="checkedInline"
            colon={false}
          >
            <Switch disabled={isLoading} loading={isLoading} />
          </Form.Item>
          <Form.Item
            label="Personal"
            name="Personal"
            valuePropName="checked"
            className="checkedInline"
            colon={false}
          >
            <Switch disabled={isLoading} loading={isLoading} />
          </Form.Item>
        </Col>
      </Row>

      <Row className="form-group" gutter={20}>
        <Col
          xs={taskStore?.taskDetails?.Key ? 12 : 24}
          style={{
            paddingRight: taskStore?.taskDetails?.Key ? 10 : 0,
            marginBottom: 10,
          }}
        >
          <Form.Item
            name="TaskTypeKey"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
            rules={[
              {
                required: true,
                message: "Please select type!",
              },
            ]}
          >
            <SelectField
              options={taskStore?.taskTypes.map((taskType: TaskTypes) => {
                return {
                  label: taskType.Description || "",
                  value: taskType.Key || "",
                };
              })}
              prefix={<ClusterOutlined />}
              disabled={isLoading}
              label="Task Type"
              showSearch={false}
              showArrow={false}
              suffixIcon={<DownOutlined />}
            />
          </Form.Item>
        </Col>
        <Col {...formGrid(false, !screens.sm)}>
          <Form.Item hasFeedback={isLoading}>
            <InputField
              prefix={<NumberOutlined />}
              disabled={true}
              label="Task Number"
              value={taskStore?.taskDetails?.TaskNumber}
            />
          </Form.Item>
        </Col>
        <Col {...formGrid(false, !screens.sm)}>
          <Form.Item
            name="TaskStatusKey"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <SelectField
              options={TaskStatuses.map((status: any) => {
                return {
                  label: status.Description || "",
                  value: status.Key || "",
                };
              })}
              prefix={<TagOutlined />}
              disabled={isLoading}
              label="Status"
              showSearch={false}
              showArrow={false}
              suffixIcon={<DownOutlined />}
              value={taskStore?.taskDetails?.TaskStatusKey}
            />
          </Form.Item>
        </Col>
        <Col {...formGrid(true)}>
          <Form.Item
            name="Priority"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <SelectField
              options={formPriority}
              prefix={<OrderedListOutlined />}
              disabled={isLoading}
              label="Priority"
              showSearch={false}
              showArrow={false}
              suffixIcon={<></>}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="form-group" gutter={20}>
        <Col {...formGrid()}>
          <Form.Item
            name="AssignedToDepartmentKey"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <SelectField
              options={taskStore?.departments.map((department: Department) => {
                return {
                  label: department.Description || "",
                  value: department.Key || "",
                };
              })}
              prefix={<ClusterOutlined />}
              disabled={isLoading}
              label="Department"
              showSearch={false}
              showArrow={false}
              suffixIcon={<></>}
            />
          </Form.Item>
        </Col>
        <Col {...formGrid(false, !screens.sm)}>
          <Form.Item
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
            name="AssignedByUserKey"
          >
            <UsersSelectField label="Assigned By" disabled={isLoading} prefix={<TeamOutlined />} />
          </Form.Item>
        </Col>
        <Col {...formGrid(true)}>
          <Form.Item
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
            name="AssignedToUserKey"
          >
            <UsersSelectField
              label="Assigned To"
              disabled={isLoading}
              prefix={<UsergroupAddOutlined />}
              isDepartment={!isDepartmentEmpty}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default inject("taskStore", "clientStore", "authStore", "userStore")(observer(AddEditTask));
