import React, { useState, useEffect, useCallback } from "react";
import { inject, observer } from "mobx-react";
import dayjs, { Dayjs } from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import customParseFormat from "dayjs/plugin/customParseFormat";
import classNames from "classnames";
import SwiperCore, { Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { List } from "antd";

import type { ScheduleStoreClass } from "stores/ScheduleStore";

import "swiper/swiper.scss";
import "./CalendarWeek.scss";
import CalendarRenderCell from "./CalendarRenderCell";
import { AuthStoreClass } from "stores/AuthStore";

interface Props {
  scheduleStore?: ScheduleStoreClass;
  authStore?: AuthStoreClass;
}

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
SwiperCore.use([Virtual]);

interface WeekProps {
  date: Dayjs;
  isActive: boolean;
  isMuted: boolean;
}

const CalendarWeek: React.FC<Props> = ({ scheduleStore, authStore }) => {
  const [swiperRef, setSwiperRef] = useState<SwiperCore>();

  useEffect(() => {
    if (scheduleStore?.calendarActiveSlideWeekIndex) {
      swiperRef?.slideTo(scheduleStore?.calendarActiveSlideWeekIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleStore?.calendarActiveSlideWeekIndex]);

  const getCalendarWeekRange = useCallback(
    (currentDate: Dayjs): WeekProps[] => {
      const activeDate = scheduleStore?.calendarActiveDate || dayjs(),
        currentActiveDayOfWeek = Number(currentDate.format("d"));

      const currentWeek = Array.from({ length: 7 }, (_, i) => i).map(
        (v: number) => {
          let companyOffset = 0;

          //COMMENTED THE BELOW LINES SINCE THEY BRING THE FIRST DAY
          //WRT COMPANY SETTINGS WHICH WE CURRENTLY DONOT NEED IN CALENDAR
          
          // if (scheduleStore?.isShowTimeSelect === "week") {
          //   companyOffset = authStore?.companySettings?.FirstDayOfWeek || 0;
          // }
          const diff = v - currentActiveDayOfWeek + companyOffset,
            dateInLoop = currentDate.add(diff, "day"),
            isActiveDate =
              activeDate.format("YYYYMMDD") === dateInLoop.format("YYYYMMDD"),
            calendarWeekMonth =
              scheduleStore?.getCalendarActiveSlideWeekMonth(
                swiperRef?.realIndex || 0
              ) || 0;

          var isMutedDate = true;

          if (calendarWeekMonth === Number(dateInLoop.format("YYYYMM"))) {
            isMutedDate = false;
          }

          if (activeDate.format("YYYYMM") === dateInLoop.format("YYYYMM")) {
            isMutedDate = false;
          }

          return {
            date: diff === 0 ? currentDate : dateInLoop,
            isActive: isActiveDate,
            isMuted: isMutedDate,
          };
        }
      );

      return currentWeek;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [swiperRef?.realIndex, scheduleStore?.calendarActiveDate]
  );

  const CalendarSlide = useCallback(
    ({ content }: { content: Dayjs }) => {
      const currentWeeks = getCalendarWeekRange(content);

      return (
        <List
          className="calendar-week-list"
          dataSource={currentWeeks}
          renderItem={({ date, isActive, isMuted }: WeekProps) => {
            return (
              <List.Item
                onClick={() => {
                  scheduleStore?.setCalendarActiveDate(date);
                  scheduleStore?.setActiveDate(date.format());
                  scheduleStore?.dateSelectCallback(date);
                  scheduleStore?.setIsShowTimeSelect("day");
                }}
                className={classNames({
                  "ant-list-item-active": isActive,
                  "ant-list-item-muted": isMuted,
                  "calendar-day-view":
                    scheduleStore?.isShowTimeSelect === "day",
                })}
              >
                {date.format("DD")}
                <CalendarRenderCell date={date} />
              </List.Item>
            );
          }}
        />
      );
    },
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      getCalendarWeekRange,
      scheduleStore,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      scheduleStore?.calendarActiveDate,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      scheduleStore?.calendarActiveSlideWeekIndex,
    ]
  );

  return (
    <div
      className={classNames("calendar-week-container", {
        "calendar-week-container-active":
          scheduleStore?.isShowTimeSelect === "week",
      })}
    >
      {scheduleStore?.isLoading ? (
        <></>
      ) : (
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          initialSlide={scheduleStore?.calendarActiveSlideWeekIndex}
          virtual
          onSwiper={setSwiperRef}
          onSlideChange={(swiper) => {
            scheduleStore?.setCalendarActiveSlideWeekIndex(swiper.realIndex);
          }}
        >
          {scheduleStore?.getCalendarWeekSlides.map((dateJs, index) => {
            let content = dayjs(dateJs).format("YYYYMMDD");
            return (
              <SwiperSlide virtualIndex={Number(content)} key={index}>
                <CalendarSlide content={dateJs} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </div>
  );
};

export default inject("scheduleStore", "authStore")(observer(CalendarWeek));
