import React, { useState, useRef } from "react";
import { Row, Col } from "antd";
import classNames from "classnames";
import type { Dayjs } from "dayjs";
import { RangePickerTimeProps } from "antd/es/date-picker/generatePicker";

import TimePicker from "components/util/TimePicker";
import "./Input.scss";
interface Props extends Omit<RangePickerTimeProps<Dayjs>, "picker"> {
  label?: string;
  prefix?: React.ReactNode;
}

const TimeRangeField: React.FC<Props> = React.forwardRef(
  ({ label, prefix, ...props }: Props, _ref): React.ReactElement => {
    const [focus, setFocus] = useState<boolean>(false);
    const inputRef = useRef<any>(null);

    const defaultInputProps: Omit<RangePickerTimeProps<Dayjs>, "picker"> = {
      bordered: false,
      style: {
        width: "100%",
      },
    };

    const handleBlur = () => {
      setFocus(false);
    };

    const handleFocus = () => {
      setFocus(true);
    };

    return (
      <Row
        className={classNames(props?.className, {
          "form-control isActive isFocus": true,
          isActive: true,
          isFocus: focus,
          isDisabled: props?.disabled,
        })}
      >
        {prefix && (
          <Col className="form-control-label-col" onClick={() => inputRef.current?.focus()}>
            {prefix}
          </Col>
        )}
        <Col className="form-control-content-col">
          <div className="form-control-content">
            {label && (
              <label
                htmlFor={props?.id}
                className="form-control-label"
                onClick={() => inputRef.current?.focus()}
              >
                {label}
              </label>
            )}
            <TimePicker.RangePicker
              onBlur={handleBlur}
              onFocus={handleFocus}
              ref={inputRef}
              defaultValue={props?.defaultValue}
              use12Hours={props?.use12Hours}
              format={props?.format}
              {...defaultInputProps}
              {...props}
            />
          </div>
        </Col>
      </Row>
    );
  }
);

export default TimeRangeField;
