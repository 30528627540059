import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Button, Popover, Menu, Dropdown } from "antd";
import {
  CalendarOutlined,
  FilterOutlined,
  SyncOutlined,
  PlusOutlined,
  CarryOutOutlined,
} from "@ant-design/icons";

import type { ScheduleStoreClass } from "stores/ScheduleStore";
import ScheduleDropdownSelect from "../ScheduleDropdownSelect";

interface Props {
  handleOnClick?: (action: string) => void;
  isResync?: boolean;
  scheduleStore?: ScheduleStoreClass;
}

const ActionsScheduleDesktop: React.FC<Props> = ({
  handleOnClick,
  isResync = false,
  scheduleStore,
}: Props): React.ReactElement => {
  const [showPopover, setShowPopover] = useState(false);

  const handleClick = (action: string) => {
    handleOnClick && handleOnClick(action);
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => handleClick("handleCalendarViewMonth")} key="1">
        Month View
      </Menu.Item>
      <Menu.Item onClick={() => handleClick("handleCalendarViewWeek")} key="2">
        Week View
      </Menu.Item>
      <Menu.Item onClick={() => handleClick("handleCalendarViewDay")} key="3">
        Day View
      </Menu.Item>
    </Menu>
  );

  return (
    <Row align="middle">
      <Col>
        <Button
          icon={<SyncOutlined />}
          type="text"
          disabled={isResync || scheduleStore?.isLoading}
          onClick={() => handleClick("handleResync")}
        >
          Refresh
        </Button>
      </Col>
      <Col>
        <Button
          icon={<FilterOutlined />}
          disabled={isResync || scheduleStore?.isLoading}
          type="text"
          onClick={() => handleClick("toggleFilterModal")}
        >
          Filter
        </Button>
      </Col>
      <Col>
        <Dropdown overlay={menu}>
          <Button
            icon={<CalendarOutlined />}
            disabled={isResync || scheduleStore?.isLoading}
            type="text"
          >
            {scheduleStore?.isShowTimeSelect === "week"
              ? "Week View"
              : scheduleStore?.isShowTimeSelect === "month"
              ? "Month View"
              : "Day View"}
          </Button>
        </Dropdown>
      </Col>
      <Col>
        <Button
          icon={<CarryOutOutlined />}
          disabled={isResync || scheduleStore?.isLoading}
          type="text"
          onClick={() => handleClick("handleCalendarToday")}
        >
          Today
        </Button>
      </Col>
      <Col>
        {isResync || scheduleStore?.isLoading ? (
          <Button
            disabled={true}
            className="btn-action"
            style={{ backgroundColor: "#708D65", borderColor: "white", color: "white" }}
            shape="round"
          >
            New
          </Button>
        ) : (
          <Popover
            content={<ScheduleDropdownSelect onClick={() => setShowPopover(false)} />}
            placement="bottomRight"
            overlayClassName="navigation-popover-container"
            visible={showPopover}
          >
            <Button
              className="btn-action"
              style={{ backgroundColor: "#708D65", borderColor: "white", color: "white" }}
              shape="round"
              onClick={() => setShowPopover(true)}
            >
              New
            </Button>
          </Popover>
        )}
      </Col>
    </Row>
  );
};

export default inject("scheduleStore")(observer(ActionsScheduleDesktop));
