import React from "react";
import { inject, observer } from "mobx-react";
import { Layout } from "antd";
import classnames from "classnames";

import type { AuthStoreClass } from "stores/AuthStore";
import NavigationItems from "./NavigationItems";
import Profile from "./Profile";
import Branding from "./Branding";

import "./Navigation.scss";

interface Props {
  authStore?: AuthStoreClass;
}

const { Sider } = Layout;

const Navigation: React.FC<Props> = ({ authStore }: Props) => {
  const handleToggleSide = () => {
    authStore?.setIsNavToggle();
  };

  return authStore?.isAuthenticated ? (
    <>
      <Sider
        className={classnames("main-navigation-sider new-sp-sidebar", {
          active: authStore.isNavOpen,
        })}
        collapsedWidth={0}
        collapsed={false}
        width={240}
      >
        <Branding showLogo src={`${authStore?.companySettings.Logo}`} />
        <NavigationItems />
        <Profile />
      </Sider>
      <div
        className={classnames("main-navigation-sider-overlay", {
          active: authStore.isNavOpen,
        })}
        onClick={handleToggleSide}
      ></div>
    </>
  ) : null;
};

export default inject("authStore")(observer(Navigation));
