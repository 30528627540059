import axios from "axios";
import AuthStore from "../../stores/AuthStore";
import AlertStore from "../../stores/AlertStore";

export function setAxiosLocation(locationNo: string = "") {
  axios.defaults.headers.common["locationNo"] = locationNo;
}

export default function setAxiosDefaults(accountName: string, token: string) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axios.defaults.headers.common["app_source"] = "StudioPlus.MobileWebApp_2.0";

  axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}/${accountName}`;
  axios.interceptors.request.use(
    (config) => {
      // console.log(AuthStore?.IsEnterprise)
      if (AuthStore?.IsEnterprise === true && !config?.url?.toLowerCase().includes("dashboard")) {
        if (AuthStore?.locationNo != "") {
          if (config.params !== undefined) {
            config.params.locationNumber = AuthStore?.locationNo;
          } else {
            config["params"] = { locationNumber: AuthStore?.locationNo };
          }
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401 && error?.response?.data.includes("Token")) {
        AlertStore.setAlertMessage(
          "Due to inactivity and for security purposes, you have been logged out. Please log back in.",
          "error"
        );
        AuthStore.logout();
      } else if (error?.response?.status === 401) {
        AlertStore.setAlertMessage(error.response.data, "error");
      }
      return Promise.reject(error);
    }
  );
}
