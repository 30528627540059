import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Layout, List, Card, Grid, Spin, Tabs } from "antd";
import dayjs from "dayjs";
import { RightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import classnames from "classnames";

import type { AuthStoreClass } from "stores/AuthStore";
import {
  getLocalStorage,
  LocalStorageProps,
} from "components/util/localStorage";
import ActionsRecent from "components/recent/actions/ActionsRecent";
import { PhoneCallStoreClass } from "stores/PhoneCallStore";
import PhoneCallDrawerForm from "components/drawers/PhoneCallDrawerForm";
import SessionDrawerForm from "components/drawers/SessionDrawerForm";
import { SessionStoreClass } from "stores/SessionStore";
import { ScheduleStoreClass } from "stores/ScheduleStore";
import { InvoiceStoreClass } from "stores/InvoiceStore";
import InvoiceDrawerView from "components/drawers/InvoiceDrawerView/InvoiceDrawerView";
import AppointmentDrawerForm from "components/drawers/AppointmentDrawerForm";
import { AppointmentStoreClass } from "stores/AppointmentStore";
import { ClientStoreClass } from "stores/ClientStore";
import TabPane from "@ant-design/pro-card/lib/components/TabPane";

const { Content } = Layout;
const { useBreakpoint } = Grid;

interface Props {
  authStore?: AuthStoreClass;
  phoneCallStore?: PhoneCallStoreClass;
  sessionStore?: SessionStoreClass;
  scheduleStore?: ScheduleStoreClass;
  invoiceStore?: InvoiceStoreClass;
  appointmentStore?: AppointmentStoreClass;
  clientStore?: ClientStoreClass;
}

const RecentPage: React.FC<Props> = ({ authStore, phoneCallStore, sessionStore, scheduleStore, invoiceStore, appointmentStore, clientStore }) => {
  const [data, setData] = useState<Array<LocalStorageProps>>();
  const screens = useBreakpoint();
  const history = useHistory();

  useEffect(() => {
    const storage = getLocalStorage();
    setData(storage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore?.toggleLocalStorage]);

  useEffect(() => {
    return () => {
      phoneCallStore?.setSelectToggle(false);
      sessionStore?.setSelectToggle(false);
      invoiceStore?.setSelectToggle(false);
      appointmentStore?.setSelectToggle(false);
      clientStore?.getClient(undefined);
    }
  }, [phoneCallStore, sessionStore, invoiceStore, appointmentStore, clientStore]);

  const handleRecentClick = async (item: any) => {
    switch (item?.type?.value) {
      case "PhoneCall":
        await phoneCallStore?.setActivePhoneCall({ Key: item?.type?.key });
        phoneCallStore?.setSelectToggle(true);
        break
      case "Session":
        await sessionStore?.getSessionDetail(item?.type?.key);
        await scheduleStore?.setActiveSchedule({ SessionKey: item?.type?.key });
        sessionStore?.setSelectToggle(true);
        break
      case "Invoice":
        await invoiceStore?.getInvoiceDetails(item?.type?.key);
        invoiceStore?.setDrawerType("detail");
        invoiceStore?.setSelectToggle(true);
        break
      case "Appointment":
        await appointmentStore?.getAppointmentDetail(item?.type?.key);
        await scheduleStore?.setActiveSchedule({ AppointmentKey: item?.type?.key });
        appointmentStore?.setSelectToggle(true);
        break
      default:
        item.url && history.push(item.url);
        break
    }
  }

  return (
    <Content className="main-content">
      <ActionsRecent />
      <div
        className={classnames({
          "main-content-pads": screens.md,
        })}
      >
        <Card bordered={screens.md} className="card-listing">
          <Tabs defaultActiveKey="1">
            <TabPane tab="All" key="1">
              <List
                dataSource={data}
                renderItem={(item: LocalStorageProps) => (

                  <List.Item
                    onClick={() =>
                      // item.url && history.push(item.url)
                      handleRecentClick(item)
                    }
                    className={classnames({ "has-action": item.url })}
                  >
                    <List.Item.Meta
                      title={item.value}
                      description={
                        <>
                          {item.name} on {dayjs(item.date).format("M/D/YYYY H:m A")}
                        </>
                      }
                    />
                    {item.url && (
                      <div
                        style={{
                          paddingLeft: 10,
                          opacity: 0.2,
                        }}
                      >
                        <RightOutlined />
                      </div>
                    )}
                  </List.Item>
                )}
                bordered
                style={{ border: 0 }}
              />
            </TabPane>
            <TabPane tab="Viewed" key="2">
              <List
                dataSource={data}
                renderItem={(item: LocalStorageProps) => (
                  <>
                    {item.name === 'Viewed' &&
                      <List.Item
                        onClick={() =>
                          // item.url && history.push(item.url)
                          handleRecentClick(item)
                        }
                        className={classnames({ "has-action": item.url })}
                      >
                        <List.Item.Meta
                          title={item.value}
                          description={
                            <>
                              {item.name} on {dayjs(item.date).format("M/D/YYYY H:m A")}
                            </>
                          }
                        />
                        {item.url && (
                          <div
                            style={{
                              paddingLeft: 10,
                              opacity: 0.2,
                            }}
                          >
                            <RightOutlined />
                          </div>
                        )}
                      </List.Item>
                    }
                  </>
                )}
                bordered
                style={{ border: 0 }}
              />
            </TabPane>
            <TabPane tab="Added" key="3">
              <List
                dataSource={data}
                renderItem={(item: LocalStorageProps) => (
                  <>
                    {item.name === "Added" &&
                      <List.Item
                        onClick={() =>
                          // item.url && history.push(item.url)
                          handleRecentClick(item)
                        }
                        className={classnames({ "has-action": item.url })}
                      >
                        <List.Item.Meta
                          title={item.value}
                          description={
                            <>
                              {item.name} on {dayjs(item.date).format("M/D/YYYY H:m A")}
                            </>
                          }
                        />
                        {item.url && (
                          <div
                            style={{
                              paddingLeft: 10,
                              opacity: 0.2,
                            }}
                          >
                            <RightOutlined />
                          </div>
                        )}
                      </List.Item>
                    }
                  </>
                )}
                bordered
                style={{ border: 0 }}
              />
            </TabPane>
            <TabPane tab="Updated" key="4">
              <List
                dataSource={data}
                renderItem={(item: LocalStorageProps) => (
                  <>
                    {item.name === "Updated" &&
                      <List.Item
                        onClick={() =>
                          // item.url && history.push(item.url)
                          handleRecentClick(item)
                        }
                        className={classnames({ "has-action": item.url })}
                      >
                        <List.Item.Meta
                          title={item.value}
                          description={
                            <>
                              {item.name} on {dayjs(item.date).format("M/D/YYYY H:m A")}
                            </>
                          }
                        />
                        {item.url && (
                          <div
                            style={{
                              paddingLeft: 10,
                              opacity: 0.2,
                            }}
                          >
                            <RightOutlined />
                          </div>
                        )}
                      </List.Item>
                    }
                  </>
                )}
                bordered
                style={{ border: 0 }}
              />
            </TabPane>
            <TabPane tab="Deleted" key="5">
              <List
                dataSource={data}
                renderItem={(item: LocalStorageProps) => (
                  <>
                    {item.name === "Deleted" &&
                      <List.Item
                        onClick={() =>
                          // item.url && history.push(item.url)
                          handleRecentClick(item)
                        }
                        className={classnames({ "has-action": item.url })}
                      >
                        <List.Item.Meta
                          title={item.value}
                          description={
                            <>
                              {item.name} on {dayjs(item.date).format("M/D/YYYY H:m A")}
                            </>
                          }
                        />
                        {item.url && (
                          <div
                            style={{
                              paddingLeft: 10,
                              opacity: 0.2,
                            }}
                          >
                            <RightOutlined />
                          </div>
                        )}
                      </List.Item>
                    }
                  </>
                )}
                bordered
                style={{ border: 0 }}
              />
            </TabPane>
            <TabPane tab="Synced" key="6">
              <List
                dataSource={data}
                renderItem={(item: LocalStorageProps) => (
                  <>
                    {item.name === 'Synced' &&
                      <List.Item
                        onClick={() =>
                          // item.url && history.push(item.url)
                          handleRecentClick(item)
                        }
                        className={classnames({ "has-action": item.url })}
                      >
                        <List.Item.Meta
                          title={item.value}
                          description={
                            <>
                              {item.name} on {dayjs(item.date).format("M/D/YYYY H:m A")}
                            </>
                          }
                        />
                        {item.url && (
                          <div
                            style={{
                              paddingLeft: 10,
                              opacity: 0.2,
                            }}
                          >
                            <RightOutlined />
                          </div>
                        )}
                      </List.Item>
                    }
                  </>
                )}
                bordered
                style={{ border: 0 }}
              />
            </TabPane>
          </Tabs>
        </Card>
      </div>
      <Spin size={"large"} spinning={phoneCallStore?.isLoading}><PhoneCallDrawerForm /></Spin>
      <Spin size={"large"} spinning={sessionStore?.isLoading}><SessionDrawerForm /></Spin>
      <Spin size={"large"} spinning={invoiceStore?.isLoading}><InvoiceDrawerView /></Spin>
      <Spin size={"large"} spinning={appointmentStore?.isLoading}><AppointmentDrawerForm /></Spin>
    </Content>
  );
};

export default inject("authStore", "phoneCallStore", "sessionStore", "scheduleStore", "invoiceStore", "appointmentStore", "clientStore")(observer(RecentPage));
