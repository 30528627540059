import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Row, Col, Layout, Input, Spin, Select } from "antd";
import "./JoyPage.scss";
const { Option } = Select;
const { Content } = Layout;
const { Meta } = Card;

interface Props {}

const JoyPage: React.FC<Props> = () => {
  const [unapprovedImages, setUnapprovedImages] = useState<Array<any>>([]);
  const [approvedImages, setApprovedImages] = useState<Array<any>>([]);
  const [selectedImages, setSelectedImages] = useState("unapproved");
  const [loadKey, setLoadKey] = useState("");

  useEffect(() => {
    const fetchUnapprovedImages = async () => {
      const res = await axios({
        method: "GET",
        url: "/IBYPublicImagesUnapproved",
      });

      setUnapprovedImages([...res.data]);
    };
    const fetchApprovedImages = async () => {
      const res = await axios({
        method: "GET",
        url: "/IBYPublicImagesApproved",
      });

      setApprovedImages([...res.data]);
    };
    fetchUnapprovedImages();
    fetchApprovedImages();
  }, []);

  const handleSubmit = async (imageToSubmit: any) => {
    setLoadKey(imageToSubmit.Key);
    let Description;
    if (selectedImages === "unapproved") {
      Description = unapprovedImages.find(
        (img) => img.Key === imageToSubmit.Key
      ).Description;
    } else {
      Description = approvedImages.find(
        (img) => img.Key === imageToSubmit.Key
      ).Description;
    }

    const data: any = {
      Key: imageToSubmit.Key,
      Description,
      SessionKey: imageToSubmit.SessionKey,
    };

    if (selectedImages === "unapproved") {
      data["Approved"] = "True";
    }

    await axios({
      method: "PUT",
      url: "IBYPublicImage",
      data,
    }).then((res: any) => {
      setLoadKey("");
      if (selectedImages === "unapproved") {
        setUnapprovedImages([
          ...unapprovedImages.filter(
            (image) => image.Key !== imageToSubmit.Key
          ),
        ]);
        setApprovedImages([res.data, ...approvedImages]);
      }
    });
  };

  const handleDelete = async (imageToDelete: any) => {
    setLoadKey(imageToDelete.Key);
    await axios({
      method: "DELETE",
      url: `IBYPublicImage/${imageToDelete.Key}`,
      params: {
        sessionKey: imageToDelete.SessionKey,
      },
    }).then(() => {
      setLoadKey("");
      if (selectedImages === "unapproved") {
        setUnapprovedImages([
          ...unapprovedImages.filter(
            (image) => image.Key !== imageToDelete.Key
          ),
        ]);
      } else {
        setApprovedImages([
          ...approvedImages.filter((image) => image.Key !== imageToDelete.Key),
        ]);
      }
    });
  };
  const updateDescription = (
    imageToChangeKey: string,
    newDescription: string
  ) => {
    if (selectedImages === "unapproved") {
      setUnapprovedImages([
        ...unapprovedImages.map((image) => {
          if (image.Key === imageToChangeKey) {
            return { ...image, Description: newDescription };
          }
          return image;
        }),
      ]);
    } else {
      setApprovedImages([
        ...approvedImages.map((image) => {
          if (image.Key === imageToChangeKey) {
            return { ...image, Description: newDescription };
          }
          return image;
        }),
      ]);
    }
  };

  const handleSelectChange = (value: any) => {
    setSelectedImages(value);
  };

  let content;
  if (selectedImages === "unapproved") {
    content = (
      <>
        {unapprovedImages.map((unapprovedImage) => (
          <Col key={unapprovedImage.Key}>
            <Card
              style={{
                // marginTop: "5rem",
                width: "20rem",
                height: "27.5rem",
              }}
              key={unapprovedImage.Key}
              cover={
                <>
                  <div className="bg-white"></div>
                  <div
                    style={{
                      height: "20rem",
                      width: "20rem",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundImage: `url(${unapprovedImage.Url})`,
                    }}
                  ></div>
                </>
              }
              actions={
                unapprovedImage.Key === loadKey
                  ? []
                  : [
                      <div onClick={() => handleSubmit(unapprovedImage)}>
                        Approve
                      </div>,
                      <div
                        onClick={() => handleDelete(unapprovedImage)}
                        className="text-hover-red"
                      >
                        Delete
                      </div>,
                    ]
              }
            >
              {unapprovedImage.Key === loadKey ? (
                <div style={{ textAlign: "center" }}>
                  <Spin />
                </div>
              ) : (
                <Meta
                  title="Description"
                  description={
                    <Input
                      onChange={(e) =>
                        updateDescription(unapprovedImage.Key, e.target.value)
                      }
                      defaultValue={unapprovedImage.Description}
                    />
                  }
                />
              )}
            </Card>
          </Col>
        ))}
      </>
    );
  } else {
    content = (
      <>
        {approvedImages.map((approvedImage) => (
          <Col key={approvedImage.Key}>
            <Card
              style={{
                // marginTop: "5rem",
                width: "20rem",
                height: "27.5rem",
              }}
              key={approvedImage.Key}
              cover={
                <>
                  <div className="bg-white"></div>
                  <div
                    style={{
                      height: "20rem",
                      width: "20rem",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundImage: `url(${approvedImage.Url})`,
                    }}
                  ></div>
                </>
              }
              actions={
                approvedImage.Key === loadKey
                  ? []
                  : [
                      <div onClick={() => handleSubmit(approvedImage)}>
                        Update Description
                      </div>,
                      <div
                        onClick={() => handleDelete(approvedImage)}
                        className="text-hover-red"
                      >
                        Delete
                      </div>,
                    ]
              }
            >
              {approvedImage.Key === loadKey ? (
                <div style={{ textAlign: "center" }}>
                  <Spin />
                </div>
              ) : (
                <Meta
                  title="Description"
                  description={
                    <Input
                      onChange={(e) =>
                        updateDescription(approvedImage.Key, e.target.value)
                      }
                      defaultValue={approvedImage.Description}
                    />
                  }
                />
              )}
            </Card>
          </Col>
        ))}
      </>
    );
  }
  return (
    <Content className="main-content">
      <div style={{ padding: "1rem" }}>
        <Select
          defaultValue="unapproved"
          style={{ width: 225 }}
          onChange={handleSelectChange}
        >
          <Option value="approved">Show approved images</Option>
          <Option value="unapproved">Show unapproved images</Option>
        </Select>
      </div>
      <Row gutter={[32, 32]} justify="center">
        {content}
      </Row>
    </Content>
  );
};

export default JoyPage;
