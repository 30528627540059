import React from "react";
import { inject, observer } from "mobx-react";
import { Form, Input } from "antd";

const GeneralForm: React.FC<any> = ({ authStore }) => {
  const { CompanyName, AddressLine1, Country, PhoneNumber, Email, CompanyWebsiteUrl } =
    authStore.companyInfo;

  const initialValues = {
    CompanyName: CompanyName || "",
    AddressLine1: AddressLine1 || "",
    PhoneNumber: PhoneNumber || "",
    Email: Email || "",
    CompanyWebsiteUrl: CompanyWebsiteUrl || "",
  };
  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={initialValues}
      onFinish={() => {}}
      onFinishFailed={() => {}}
      autoComplete="off"
    >
      <Form.Item label="Company Name" name="CompanyName">
        <Input />
      </Form.Item>

      <Form.Item label="Address Line 1" name="AddressLine1">
        <Input />
      </Form.Item>
      <Form.Item label="Company Phone" name="PhoneNumber">
        <Input />
      </Form.Item>
      <Form.Item label="Company Email" name="Email">
        <Input />
      </Form.Item>
      <Form.Item label="Company Website" name="CompanyWebsiteUrl">
        <Input />
      </Form.Item>
    </Form>
  );
};

export default inject("authStore")(observer(GeneralForm));
