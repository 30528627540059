import { observable, action, computed, makeObservable } from "mobx";
import { create, persist } from "mobx-persist";
import dayjs, { Dayjs } from "dayjs";
import localeData from "dayjs/plugin/localeData";
import customParseFormat from "dayjs/plugin/customParseFormat";
import update from "immutability-helper";
import axios, { AxiosResponse, AxiosError } from "axios";

import SessionStore from "./SessionStore";
import AppointmentStore from "./AppointmentStore";
import type { ScheduleList, Resource } from "interfaces/schedule.int";
import type { AppointmentDetail } from "interfaces/appointment.int";
import type { SessionDetail } from "interfaces/session.int";

declare type CalendarMode = "year" | "month";

dayjs.extend(localeData);
dayjs.extend(customParseFormat);

export class ScheduleStoreClass {
  @persist("list") scheduleListUnfiltered: Array<ScheduleList> = [];
  @persist("list") scheduleList: Array<ScheduleList> = [];
  @persist("list") resourceList: Array<Resource> = [];
  filterResourceKey: string = "";
  filterUserKey: string = "";
  activeDateTime: Dayjs = dayjs().set("hour", 8).set("minute", 0).set("second", 0); // sets Today 8am;
  activeDate: string = dayjs().format();
  activeSchedule: ScheduleList | undefined;
  activeClientKey: string | undefined;
  activeScheduleType: "Session" | "Appointment" | undefined;
  activeScheduleDetail: any = {};
  isShowTimeSelect: string = "day";
  isActiveDaySelect: boolean = false;
  isActiveTimeSelect: boolean = false;
  activeTime: number = 0;
  calendarTotalSlides: number = 480;
  calendarMode: CalendarMode = "month";
  calendarActiveDate: Dayjs = dayjs();
  calendarActiveYearMonth: number = Number(dayjs().format("YYYYMM"));
  calendarActiveYear: number = Number(dayjs().format("YYYY"));
  calendarActiveSlideWeekIndex: number = this.getCalendarWeekSlideActiveIndex;
  calendarActiveSlideMonthIndex: number = this.calendarTotalSlides / 2;
  calendarActiveSlideYearIndex: number = this.calendarTotalSlides / 24;
  filterResults: any = {};
  @persist isLoading: boolean = false;

  constructor() {
    makeObservable(this, {
      activeDate: observable,
      activeTime: observable,
      activeDateTime: observable,
      activeSchedule: observable,
      activeScheduleDetail: observable,
      activeClientKey: observable,
      activeScheduleType: observable,
      filterUserKey: observable,
      filterResourceKey: observable,

      isActiveDaySelect: observable,
      isActiveTimeSelect: observable,
      isLoading: observable,
      isShowTimeSelect: observable,
      calendarMode: observable,
      calendarActiveDate: observable,
      calendarActiveYearMonth: observable,
      calendarActiveYear: observable,
      calendarTotalSlides: observable,
      calendarActiveSlideWeekIndex: observable,
      calendarActiveSlideMonthIndex: observable,
      calendarActiveSlideYearIndex: observable,
      filterResults: observable,

      init: action,
      setLoading: action,
      scheduleList: observable,
      setActiveDate: action,
      setActiveTime: action,
      setActiveSchedule: action,
      setActiveClientKey: action,
      setActiveScheduleType: action,
      setActiveScheduleDetail: action,
      setIsActiveDaySelect: action,
      setIsActiveTimeSelect: action,
      setIsShowTimeSelect: action,
      setCalendarMode: action,
      setCalendarActiveDate: action,
      setCalendarActiveYearMonth: action,
      setCalendarActiveYear: action,
      getCalendarSlides: computed,
      setCalendarActiveSlideWeekIndex: action,
      setCalendarActiveSlideMonthIndex: action,
      setCalendarActiveSlideYearIndex: action,
      getCalendarYearSlides: computed,
      getCalendarWeekSlides: computed,
      getCalendarTotalDays: computed,
      getCalendarActiveSlideWeekMonth: action,
      setFilterResults: action,
      setFilterUserKey: action,
      setFilterResourceKey: action,

      getCalendarList: action,
      searchCalendarList: action,
      resourceList: observable,
      selectResourceList: computed,
      getResourceList: action,
      getResourceDataFromList: action,
      sortedResourceList: computed,

      getAvailableInDay: action,
      getDateTimeRange: computed,
      dateSelectCallback: action,
      timeSelectCallback: action,
      deleteSchedule: action,
      cleanTimeSelectCallback: action,
      cleanUp: action,
    });
  }

  async init() {
    // await this.getCalendarList();
    await SessionStore.init();
    await AppointmentStore.init();
  }

  setActiveDateTime(dateTime: Dayjs | null | undefined) {
    this.activeDateTime = dateTime
      ? dateTime
      : dayjs().set("hour", 8).set("minute", 0).set("second", 0);
  }

  setActiveDate(activeDate: string) {
    this.activeDate = activeDate;
    this.isActiveDaySelect = true;
  }

  setActiveTime(activeTime: number) {
    this.activeTime = activeTime;
    this.isActiveTimeSelect = true;
  }

  setIsActiveDaySelect(isActive: boolean) {
    this.isActiveDaySelect = isActive;
  }

  setIsActiveTimeSelect(isActive: boolean) {
    this.isActiveTimeSelect = isActive;
  }

  setActiveSchedule(schedule: ScheduleList | undefined) {
    this.activeSchedule = schedule;
  }

  setActiveClientKey(clientKey: string | undefined) {
    this.activeClientKey = clientKey;
  }

  setActiveScheduleType(type: "Session" | "Appointment" | undefined) {
    this.activeScheduleType = type;
  }

  setActiveScheduleDetail(schedule: AppointmentDetail | SessionDetail | undefined) {
    this.activeScheduleDetail = schedule;
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setIsShowTimeSelect(isShowTime: string) {
    this.isShowTimeSelect = isShowTime;
  }

  setCalendarMode(mode: CalendarMode) {
    this.calendarMode = mode;
  }

  setCalendarActiveDate(date: Dayjs) {
    let selectedYearDiff = date.startOf("year").diff(this.getCalendarYearSlides[0], "year");
    this.setCalendarActiveSlideYearIndex(selectedYearDiff);
    let selectedYearMonthDiff = date.startOf("month").diff(this.getCalendarSlides[0], "month");
    this.setCalendarActiveSlideMonthIndex(selectedYearMonthDiff);

    let selectedWeekDiff = date.diff(this.getCalendarWeekSlidesStartEnd[0], "week");
    this.calendarActiveSlideWeekIndex = selectedWeekDiff;

    this.setCalendarActiveYear(Number(date.format("YYYY")));

    this.setCalendarActiveYearMonth(Number(date.format("YYYYMM")));

    this.calendarActiveDate = date;
  }

  setCalendarActiveYearMonth(date: number) {
    this.calendarActiveYearMonth = date;
  }

  setCalendarActiveYear(date: number) {
    this.calendarActiveYear = date;
  }

  setCalendarActiveSlideDayIndex(idx: number, type?: "prev" | "next") {
    const current = this.calendarActiveDate;
    const index = type && type === "prev" ? current.subtract(1, "day") : current.add(1, "day");

    const currentDate: Dayjs = index;
    this.calendarActiveDate = currentDate;
    this.setCalendarActiveDate(currentDate);

    this.dateSelectCallback(currentDate);
    this.setActiveDate(currentDate.format());
  }

  setCalendarActiveSlideWeekIndex(idx: number, type?: "prev" | "next") {
    const current = this.calendarActiveSlideWeekIndex;
    const index = type ? (type === "prev" ? current - 1 : current + 1) : idx;
    this.calendarActiveSlideWeekIndex = index;
    this.calendarActiveYearMonth = this.getCalendarActiveSlideWeekMonth(index);
  }

  getCalendarActiveSlideWeekMonth(idx: number): number {
    const dateRange: { [key: string]: number } | null = {};
    for (let i = 0; i < 7; i++) {
      const dateYearMonth =
        this.getCalendarWeekSlides[idx].add(i, "day").format("YYYYMM") || dayjs().format("YYYYMM");

      if (!dateRange.hasOwnProperty(dateYearMonth)) {
        Object.assign(dateRange, { [dateYearMonth]: 1 });
        continue;
      }

      dateRange[dateYearMonth]++;

      if (dateRange[dateYearMonth] > 3) {
        return Number(dateYearMonth);
      }
    }

    return Number(dayjs().format("YYYYMM"));
  }

  setCalendarActiveSlideMonthIndex(idx: number, type?: "prev" | "next") {
    const current = this.calendarActiveSlideMonthIndex;
    const index = type ? (type === "prev" ? current - 1 : current + 1) : idx;

    this.calendarActiveYearMonth = Number(
      this.getCalendarSlides[0].add(index, "month").format("YYYYMM")
    );
    this.calendarActiveSlideMonthIndex = index;
  }

  setCalendarActiveSlideYearIndex(idx: number, type?: "prev" | "next") {
    const current = this.calendarActiveSlideYearIndex;
    const index = type ? (type === "prev" ? current - 1 : current + 1) : idx;
    this.calendarActiveSlideYearIndex = index;
    this.calendarActiveYear = Number(
      this.getCalendarYearSlides[0].add(index, "year").format("YYYY")
    );
  }

  get getMiddleMonthIdx(): number {
    return this.calendarTotalSlides / 2;
  }

  get getCalendarSlides(): Dayjs[] {
    const startDate = dayjs().startOf("month").subtract(this.getMiddleMonthIdx, "month");

    return Array.from({ length: this.calendarTotalSlides }, (_, i) => {
      return startDate.add(i, "month");
    });
  }

  get getCalendarYearSlides(): Dayjs[] {
    const startDate = dayjs()
      .startOf("year")
      .subtract(this.getMiddleMonthIdx / 12, "year");

    return Array.from({ length: this.calendarTotalSlides / 12 }, (_, i) => {
      return startDate.add(i, "year");
    });
  }

  get getCalendarTotalDays(): number {
    return this.getCalendarSlides[this.getCalendarSlides.length - 1].diff(
      this.getCalendarSlides[0],
      "day"
    );
  }

  get getCalendarWeekSlidesStartEnd(): Dayjs[] {
    const weeksCutOff = 800;
    const startDayOfWeekDate = this.getCalendarSlides[0].add(weeksCutOff, "week").set("date", 1);
    const startDayOfWeek = Number(startDayOfWeekDate.format("d"));
    const startWeek =
      startDayOfWeek === 0
        ? this.getCalendarSlides[0]
        : startDayOfWeekDate.subtract(startDayOfWeek, "day");

    const endMonth = this.getCalendarSlides[this.getCalendarSlides.length - 1].subtract(
      weeksCutOff,
      "week"
    );
    const endDay = endMonth.set("date", endMonth.daysInMonth());
    const endDayOfWeek = Number(endDay.format("d"));
    const endWeek = endDayOfWeek === 0 ? endDay : endDay.add(endDayOfWeek, "day");

    return [startWeek, endWeek];
  }

  get getCalendarWeekSlides(): Dayjs[] {
    const startWeek = this.getCalendarWeekSlidesStartEnd[0];
    const endWeek = this.getCalendarWeekSlidesStartEnd[1];
    return Array.from({ length: endWeek.diff(startWeek, "week") }, (_, i) => {
      return startWeek.add(i, "week");
    });
  }

  get getCalendarWeekSlideActiveIndex(): number {
    return this.calendarActiveDate.diff(this.getCalendarWeekSlidesStartEnd[0], "week");
  }

  setFilterResults(filter: any = {}) {
    this.filterResults = filter;
  }

  get getDateTimeRange() {
    return {
      date: this.activeDateTime,
      time: [this.activeDateTime, this.activeDateTime?.add(1, "hour")],
    };
  }

  timeSelectCallback(time: number, schedule?: ScheduleList) {
    this.activeSchedule = schedule ? schedule : undefined;
    this.activeDateTime = this.activeDateTime.set("hour", time).set("minute", 0).set("second", 0);

    this.setActiveTime(time);
    this.isActiveTimeSelect = true;
    if (schedule?.AppointmentKey) AppointmentStore?.setSelectToggle(true);
    if (schedule?.SessionKey) SessionStore?.setSelectToggle(true);
  }

  cleanTimeSelectCallback() {
    this.activeSchedule = undefined;
    this.activeScheduleDetail = undefined;
    this.isActiveTimeSelect = false;
  }

  dateSelectCallback(date: Dayjs) {
    this.setActiveDate(date.format());
    this.setActiveDateTime(
      this.activeDateTime
        .set("date", Number(date.format("D")))
        .set("year", Number(date.format("YYYY")))
        .set("month", Number(date.format("M")) - 1)
    );
  }

  async getCalendarList(userKey?: string, resourceKey?: string) {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: "/CalendarList",
      headers: {
        "Cache-Control": "no-cache",
      },
      params: {
        resourceKey: resourceKey ? resourceKey : undefined,
        userKey: userKey ? userKey : undefined,
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.scheduleList = res.data;
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  searchCalendarList(keyword: string) {
    const regex = new RegExp(keyword, "ig");
    const results = this.scheduleListUnfiltered?.filter(({ Description }) => {
      return Description?.match(regex);
    });
    this.scheduleList = results;
  }

  async deleteSchedule(key?: string, type?: "Appointment" | "Session") {
    if (!key || !type) return;

    this.isLoading = true;

    return await axios({
      method: "DELETE",
      url: `/${type}/${key}`,
    })
      .then(
        action((res: AxiosResponse) => {
          let foundFirst = this.scheduleListUnfiltered.findIndex(
            (val) => val.AppointmentKey === key || val.SessionKey === key
          );
          this.scheduleListUnfiltered = update(this.scheduleListUnfiltered, {
            $splice: [[foundFirst, 1]],
          });
          this.isLoading = false;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async addSchedule(schedule: ScheduleList) {
    this.isLoading = true;

    return await axios({
      method: "PUT",
      url: "/CalendarList",
      data: schedule,
    })
      .then(
        action((res: AxiosResponse) => {
          this.scheduleListUnfiltered = [...this.scheduleListUnfiltered, res.data];
          this.activeSchedule = res.data;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async getResourceList() {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: "/Resource",
    })
      .then(
        action((res: AxiosResponse) => {
          this.resourceList = res.data;
          this.isLoading = false;
          //console.log(res.data);
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  get selectResourceList() {
    return this.sortedResourceList?.map((v: Resource) => {
      return {
        label: v.Description,
        value: v.Key,
      };
    });
  }

  get sortedResourceList() {
    const sortedList = this.resourceList.slice().sort((a: any, b: any) => {
      const firstA = `${a.FirstName}`.toUpperCase();
      const firstB = `${b.FirstName}`.toUpperCase();
      if (firstA < firstB) {
        return -1;
      }
      if (firstA > firstB) {
        return 1;
      }
      // First names are the same, check last names
      const lastA = `${a.LastName}`.toUpperCase();
      const lastB = `${b.LastName}`.toUpperCase();
      if (lastA < lastB) {
        return -1;
      }
      if (lastA > lastB) {
        return 1;
      }

      return 0;
    });

    return sortedList;
  }

  getResourceDataFromList(key: string, field?: string): Resource | string {
    let filtered: any = this.resourceList?.filter((v: Resource) => key === v.Key)[0];
    let mapped = field ? filtered[field] : filtered;

    return mapped;
  }

  getAvailableInDay(activeDate?: Dayjs): Array<ScheduleList> {
    return (
      this.scheduleList
        .filter((value) => {
          let formattedDate: string = dayjs(value?.StartDateTime).format("YYYY-MM-DD");
          let getActiveDate: string = activeDate
            ? activeDate.format("YYYY-MM-DD")
            : dayjs(this.activeDate).format("YYYY-MM-DD");
          let formattedSelectedDate: string = getActiveDate ? getActiveDate : "";
          return formattedDate === formattedSelectedDate;
        })
        .sort((a, b) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        }) || []
    );
  }

  setFilterUserKey(userKey: string) {
    this.filterUserKey = userKey;
  }

  setFilterResourceKey(resourceKey: string) {
    this.filterResourceKey = resourceKey;
  }

  cleanUp() {
    this.activeTime = 0;
    this.activeDate = dayjs().format();
    this.activeDateTime = dayjs().set("hour", 8).set("minute", 0).set("second", 0);
    this.activeSchedule = undefined;
    this.activeScheduleType = undefined;
    this.activeClientKey = undefined;
    this.isActiveDaySelect = false;
    this.isActiveTimeSelect = false;
    this.isLoading = false;
  }
}

const hydrate = create({});

const ScheduleStore = new ScheduleStoreClass();

export default ScheduleStore;

hydrate("schedule", ScheduleStore).then(() => {});
