import { Dayjs } from "dayjs";
import * as React from "react";
import DatePicker from "./DatePicker";
import {
  PickerTimeProps,
  RangePickerTimeProps,
} from "antd/es/date-picker/generatePicker";
// import { Omit } from "antd/es/_util/type"; //Omit type is now built-in in TypeScript

const { RangePicker: InternalRangePicker } = DatePicker;

export interface TimePickerProps
  extends Omit<PickerTimeProps<Dayjs>, "picker"> {}
export interface TimeRangePickerProps
  extends Omit<RangePickerTimeProps<Dayjs>, "picker"> {}

const RangePicker = React.forwardRef<any, TimeRangePickerProps>(
  (props, ref) => (
    <InternalRangePicker {...props} picker="time" mode={undefined} ref={ref} />
  )
);
const TimePicker = React.forwardRef<any, TimePickerProps>((props, ref) => {
  return <DatePicker {...props} picker="time" mode={undefined} ref={ref} />;
});

TimePicker.displayName = "TimePicker";

type MergedTimePicker = typeof TimePicker & {
  RangePicker: typeof RangePicker;
};

(TimePicker as MergedTimePicker).RangePicker = RangePicker;

export default TimePicker as MergedTimePicker;
