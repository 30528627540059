import React from "react";
import { inject, observer } from "mobx-react";
import { Col, Grid, Button, Space, message } from "antd";
import { AlignLeftOutlined, PlusOutlined } from "@ant-design/icons";
import CustomHeader from "components/header/CustomHeader";
import { ReportStoreClass } from "stores/ReportStore";

interface Props {
  reportStore?: ReportStoreClass;
  groupCheckData?: any;
  removeGroupCheck?: boolean;
  setRemoveGroupCheck?: any;
  setGroupCheckData?: any;
  groupLength?: number;
  chartLength?: number;
  setEditChartValues?: any;
  setIsDashboardUpdated?: any;
  isDashboardUpdated?: boolean;
  resetGroupAndRemoveHandler?: any;
}

const { useBreakpoint } = Grid;

const ActionsClients: React.FC<Props> = ({
  reportStore,
  groupCheckData,
  removeGroupCheck,
  setRemoveGroupCheck,
  setGroupCheckData,
  groupLength,
  chartLength,
  setEditChartValues,
  setIsDashboardUpdated,
  isDashboardUpdated,
  resetGroupAndRemoveHandler,
}: Props): React.ReactElement => {
  const screens = useBreakpoint();

  const handleClick = () => {
    setEditChartValues({});
    reportStore?.setSelectToggle(true);
  };

  const groupChartHandler = () => {
    if (reportStore?.allowGrouping) {
      reportStore?.setAllowGrouping(false);
      if (reportStore?.groupedChartsData.length)
        reportStore?.setTabsGroupedCharts(reportStore?.groupedChartsData);
      reportStore?.setGroupedChartsData([]);
      setIsDashboardUpdated(true);
      resetGroupAndRemoveHandler();
    } else {
      reportStore?.setAllowGrouping(true);
    }
  };

  const removeGroupHandler = () => {
    if (removeGroupCheck) {
      setRemoveGroupCheck(false);
      if (groupCheckData.length) reportStore?.removeFromTabsGroup(groupCheckData);
      setGroupCheckData([]);
      setIsDashboardUpdated(true);
      resetGroupAndRemoveHandler();
    } else {
      setRemoveGroupCheck(true);
    }
  };

  const saveDashboardHandler = () => {
    reportStore?.saveCompanyDashboard().then((data: any) => {
      message.success("Dashboard Saved");
      setIsDashboardUpdated(false);
      reportStore?.getCompanyDashboard();
    });
  };

  return (
    <CustomHeader title="Company Dashboard">
      <Col style={{ marginLeft: "auto" }}>
        <Space>
          {!reportStore?.allowGrouping && groupLength && groupLength > 0 ? (
            <Button
              shape="round"
              type={screens.lg ? "primary" : "text"}
              className="RemoveGroupsBtn"
              onClick={() => removeGroupHandler()}
            >
              {removeGroupCheck ? "FINISH REMOVE" : "REMOVE GROUPS"}
            </Button>
          ) : null}
          {!removeGroupCheck && chartLength && chartLength > 1 ? (
            <Button
              shape="round"
              type={screens.lg ? "primary" : "text"}
              className="GroupChartsBtn"
              onClick={() => groupChartHandler()}
            >
              {reportStore?.allowGrouping ? "FINISH GROUPING" : "GROUP CHARTS"}
            </Button>
          ) : null}
          <Button
            shape="round"
            disabled={!isDashboardUpdated}
            // type={screens.lg ? "primary" : "text"}
            className="saveDashboardBtn"
            onClick={() => saveDashboardHandler()}
          >
            Save Dashboard
          </Button>
          <Button
            // disabled={reportStore?.isLoading}
            icon={<AlignLeftOutlined />}
            type="text"
            onClick={() => reportStore?.setPerformancePanelToggle(true)}
          >
            Performance panel
          </Button>
          <Button
            // disabled={reportStore?.isLoading}
            // type={screens.lg ? "primary" : "text"}
            className={screens.lg ? "btn-action" : "no-label"}
            style={{ backgroundColor: "#708D65", borderColor: "white", color: "white" }}
            shape="round"
            onClick={handleClick}
          >
            New
          </Button>
        </Space>
      </Col>
    </CustomHeader>
  );
};

export default inject("reportStore")(observer(ActionsClients));
