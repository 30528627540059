import React, { useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";
import {
  Row,
  Col,
  Typography,
  Drawer,
  Grid,
  Tabs,
  Input,
  Form,
  Button,
  message,
  Space,
  Modal,
  Spin,
  Checkbox,
  Dropdown,
} from "antd";
import { CheckOutlined, PrinterOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import type { DrawerProps } from "antd/lib/drawer";
import type { InvoiceStoreClass } from "stores/InvoiceStore";
import useWindowDimension from "components/util/Dimension";
import InputField from "components/forms/items/InputField";
import DateField from "components/forms/items/DateField";
import type { Client } from "interfaces/client.int";
import type { SessionDetail } from "interfaces/session.int";
import InvoiceDetails from "./InvoiceDetails";
import OrderDetails from "./OrderDetails";
import PaymentModal from "./PaymentModal";

import "./InvoiceDrawerView.scss";
import { ClientStoreClass } from "stores/ClientStore";
import type { Invoice } from "interfaces/invoice.int";
import { SessionStoreClass } from "stores/SessionStore";
import { useReactToPrint } from "react-to-print";
import ProCard from "@ant-design/pro-card";
import QAInvoicePrint from "pages/QuickAccess/Invoice/QAInvoicePrint";
import { TaskStoreClass } from "stores/TaskStore";
import { AppointmentStoreClass } from "stores/AppointmentStore";
import { PhoneCallStoreClass } from "stores/PhoneCallStore";
const { useBreakpoint } = Grid;
const { TabPane } = Tabs;

const { TextArea } = Input;

const { Title } = Typography;

interface Props {
  invoiceStore?: InvoiceStoreClass;
  clientStore?: ClientStoreClass;
  sessionStore?: SessionStoreClass;
  appointmentStore?: AppointmentStoreClass;
  phoneCallStore?: PhoneCallStoreClass;
  taskStore?: TaskStoreClass;
  cartStore?: any;
  ClientKey?: string;
  newSessionInvoice?: boolean;
  isQuickSale?: boolean;
  setIsQuickSale?: any;
}

dayjs.extend(customParseFormat);

const InvoiceDrawerView: React.FC<Props> = ({
  clientStore,
  invoiceStore,
  sessionStore,
  phoneCallStore,
  appointmentStore,
  taskStore,
  cartStore,
  ClientKey = "",
  newSessionInvoice,
  isQuickSale,
  setIsQuickSale,
}) => {
  const [billCard, setBillCard] = useState(true);
  const [shippingCard, setShippingCard] = useState(true);
  const [paymentModal, setPaymentModal] = useState(false);
  const [tab, setTab] = useState("1");
  const { innerWidth } = useWindowDimension();
  const screens = useBreakpoint();
  const [client, setClient] = useState<Client>({});
  const [billToClient, setBillToClient] = useState<Client>({});
  const [session, setSession] = useState<SessionDetail>({});
  const [deleteInvoiceModal, setDeleteInvoiceModal] = useState(false);
  const [form] = Form.useForm();
  const drawerTitle = invoiceStore?.drawerType === "payment" ? "Payment" : "Invoice Details";
  const [createOptions, setCreateOptions] = useState<any>([
    { label: "Task", value: "Task" },
    { label: "Call", value: "Call" },
    { label: "Payment", value: "Payment" },
  ]);

  const invoicePrintRef = useRef<HTMLDivElement>(null);

  const [showPrint] = useState(false);

  const handleInvoicePrint = useReactToPrint({
    content: () => invoicePrintRef.current,
    documentTitle: "Invoice" + invoiceStore?.activeInvoice?.InvoiceNumber,
  });

  const InvoiceDetailProps = {
    form,
    client,
    setClient,
    billToClient,
    setBillToClient,
    billCard,
    setBillCard,
    session,
    setSession,
    shippingCard,
    setShippingCard,
    isQuickSale,
  };

  useEffect(() => {
    return () => {
      form.resetFields();
      invoiceStore?.setActiveInvoice(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const init = async () => {
      if (newSessionInvoice) {
        await invoiceStore?.setActiveInvoice(undefined);
        setSession(sessionStore?.currentSessionDetail);
      }
      if (invoiceStore?.activeInvoice?.InvoiceKey) {
        const initFormValues: any = {
          InvoiceNumber: invoiceStore?.activeInvoice?.InvoiceNumber,
          WebOrderNumber: invoiceStore?.activeInvoice?.WebOrderNumber,
          InvoiceType: invoiceStore?.activeInvoice?.InvoiceType,
          InvoiceDate: invoiceStore?.activeInvoice?.InvoiceDate
            ? dayjs(invoiceStore?.activeInvoice?.InvoiceDate)
            : undefined,
          InvoiceDescription: invoiceStore?.activeInvoice?.InvoiceDescription,
          InvoiceStatusKey: invoiceStore?.activeInvoice?.InvoiceStatusKey,
          InvoiceStatusDueDate: invoiceStore?.activeInvoice?.InvoiceStatusDueDate
            ? dayjs(invoiceStore?.activeInvoice?.InvoiceStatusDueDate)
            : undefined,
          SalesRepKey: invoiceStore?.activeInvoice?.SalesRepKey,
          BillToClientKey: invoiceStore?.activeInvoice?.BillToClientKey,
          ShippingAddressCity: invoiceStore?.activeInvoice?.ShippingAddressCity,
          ShippingAddressCountry: invoiceStore?.activeInvoice?.ShippingAddressCountry,
          ShippingAddressLine1: invoiceStore?.activeInvoice?.ShippingAddressLine1,
          ShippingAddressLine2: invoiceStore?.activeInvoice?.ShippingAddressLine2,
          ShippingAddressName: invoiceStore?.activeInvoice?.ShippingAddressName,
          ShippingAddressPostalCode: invoiceStore?.activeInvoice?.ShippingAddressPostalCode,
          ShippingAddressState: invoiceStore?.activeInvoice?.ShippingAddressState,
          PaymentDueDate: invoiceStore?.activeInvoice?.PaymentDueDate
            ? dayjs(invoiceStore?.activeInvoice?.PaymentDueDate)
            : undefined,
          ApproximateDeliveryDate: invoiceStore?.activeInvoice?.ApproximateDeliveryDate
            ? dayjs(invoiceStore?.activeInvoice?.ApproximateDeliveryDate)
            : undefined,
          DeliveredDate:
            invoiceStore?.activeInvoice?.DeliveredDate &&
            new Date(invoiceStore?.activeInvoice?.DeliveredDate).getTime() > 0
              ? dayjs(invoiceStore?.activeInvoice?.DeliveredDate)
              : undefined,
          Notes: invoiceStore?.activeInvoice?.Notes,
          CustomField1: invoiceStore?.activeInvoice?.CustomField1,
          CustomField2: invoiceStore?.activeInvoice?.CustomField2,
          CustomField3: invoiceStore?.activeInvoice?.CustomField3,
          CustomField4: invoiceStore?.activeInvoice?.CustomField4,
          CustomField5: invoiceStore?.activeInvoice?.CustomField5,
          CustomField6: invoiceStore?.activeInvoice?.CustomField6,
          CustomField7: invoiceStore?.activeInvoice?.CustomField7,
          CustomField8: invoiceStore?.activeInvoice?.CustomField8,
          CustomField9: invoiceStore?.activeInvoice?.CustomField9,
          CustomField10: invoiceStore?.activeInvoice?.CustomField10,
          CustomDateField1:
            invoiceStore?.activeInvoice?.CustomDateField1 &&
            new Date(invoiceStore?.activeInvoice?.CustomDateField1).getTime() > 0
              ? dayjs(invoiceStore?.activeInvoice?.CustomDateField1)
              : undefined,
          CustomDateField2:
            invoiceStore?.activeInvoice?.CustomDateField2 &&
            new Date(invoiceStore?.activeInvoice?.CustomDateField2).getTime() > 0
              ? dayjs(invoiceStore?.activeInvoice?.CustomDateField2)
              : undefined,
          CustomDateField3:
            invoiceStore?.activeInvoice?.CustomDateField3 &&
            new Date(invoiceStore?.activeInvoice?.CustomDateField3).getTime() > 0
              ? dayjs(invoiceStore?.activeInvoice?.CustomDateField3)
              : undefined,
          CustomDateField4:
            invoiceStore?.activeInvoice?.CustomDateField4 &&
            new Date(invoiceStore?.activeInvoice?.CustomDateField4).getTime() > 0
              ? dayjs(invoiceStore?.activeInvoice?.CustomDateField4)
              : undefined,
          CustomDateField5:
            invoiceStore?.activeInvoice?.CustomDateField5 &&
            new Date(invoiceStore?.activeInvoice?.CustomDateField5).getTime() > 0
              ? dayjs(invoiceStore?.activeInvoice?.CustomDateField5)
              : undefined,
          CustomDateField6:
            invoiceStore?.activeInvoice?.CustomDateField6 &&
            new Date(invoiceStore?.activeInvoice?.CustomDateField6).getTime() > 0
              ? dayjs(invoiceStore?.activeInvoice?.CustomDateField6)
              : undefined,
          CustomDateField7:
            invoiceStore?.activeInvoice?.CustomDateField7 &&
            new Date(invoiceStore?.activeInvoice?.CustomDateField7).getTime() > 0
              ? dayjs(invoiceStore?.activeInvoice?.CustomDateField7)
              : undefined,
          CustomDateField8:
            invoiceStore?.activeInvoice?.CustomDateField8 &&
            new Date(invoiceStore?.activeInvoice?.CustomDateField8).getTime() > 0
              ? dayjs(invoiceStore?.activeInvoice?.CustomDateField8)
              : undefined,
          CustomDateField9:
            invoiceStore?.activeInvoice?.CustomDateField9 &&
            new Date(invoiceStore?.activeInvoice?.CustomDateField9).getTime() > 0
              ? dayjs(invoiceStore?.activeInvoice?.CustomDateField9)
              : undefined,
          CustomDateField10:
            invoiceStore?.activeInvoice?.CustomDateField10 &&
            new Date(invoiceStore?.activeInvoice?.CustomDateField10).getTime() > 0
              ? dayjs(invoiceStore?.activeInvoice?.CustomDateField10)
              : undefined,
          CustomNotesField1: invoiceStore?.activeInvoice?.CustomNotesField1,
          CustomNotesField2: invoiceStore?.activeInvoice?.CustomNotesField2,
          CustomNotesField3: invoiceStore?.activeInvoice?.CustomNotesField3,
          CustomNotesField4: invoiceStore?.activeInvoice?.CustomNotesField4,
          CustomNotesField5: invoiceStore?.activeInvoice?.CustomNotesField5,
          CustomNotesField6: invoiceStore?.activeInvoice?.CustomNotesField6,
          CustomNotesField7: invoiceStore?.activeInvoice?.CustomNotesField7,
          CustomNotesField8: invoiceStore?.activeInvoice?.CustomNotesField8,
          CustomNotesField9: invoiceStore?.activeInvoice?.CustomNotesField9,
          CustomNotesField10: invoiceStore?.activeInvoice?.CustomNotesField10,
          CustomBoolean1: invoiceStore?.activeInvoice?.CustomBoolean1,
          CustomBoolean2: invoiceStore?.activeInvoice?.CustomBoolean2,
          CustomBoolean3: invoiceStore?.activeInvoice?.CustomBoolean3,
          CustomBoolean4: invoiceStore?.activeInvoice?.CustomBoolean4,
          CustomBoolean5: invoiceStore?.activeInvoice?.CustomBoolean5,
          CustomBoolean6: invoiceStore?.activeInvoice?.CustomBoolean6,
          CustomBoolean7: invoiceStore?.activeInvoice?.CustomBoolean7,
          CustomBoolean8: invoiceStore?.activeInvoice?.CustomBoolean8,
          CustomBoolean9: invoiceStore?.activeInvoice?.CustomBoolean9,
          CustomBoolean10: invoiceStore?.activeInvoice?.CustomBoolean10,
        };
        form.setFieldsValue({ ...initFormValues });
        // invoiceStore?.setSelectToggle(true);

        if (invoiceStore?.activeInvoice?.ClientKey) {
          const ajaxClient = await clientStore?.getClientByKey(
            invoiceStore?.activeInvoice.ClientKey
          );
          await setClient(ajaxClient);
        }

        // test data

        if (
          invoiceStore?.activeInvoice &&
          invoiceStore?.activeInvoice?.BillToClientKey &&
          invoiceStore?.activeInvoice?.BillToClientKey !== invoiceStore?.activeInvoice.ClientKey
        ) {
          const ajaxClient = await clientStore?.getClientByKey(
            invoiceStore?.activeInvoice.BillToClientKey
          );
          setBillToClient(ajaxClient);
          // await clientStore?.getClient(invoiceStore?.activeInvoice.ClientKey);
        } else {
          setBillCard(false);
        }

        if (invoiceStore?.activeInvoice?.SessionKey) {
          const ajaxSession = await sessionStore?.getSessionDetail(
            invoiceStore?.activeInvoice.SessionKey
          );
          setSession(ajaxSession);
          invoiceStore?.getSessionImages(ajaxSession.Key);
          invoiceStore?.getPriceList(ajaxSession.PriceListKey);
        }
      } else {
        const initFormValues: any = {
          ClientKey,
          BillToClientKey: "",
          ShippingAddressCity: "",
          ShippingAddressCountry: "",
          ShippingAddressLine1: "",
          ShippingAddressLine2: "",
          ShippingAddressName: "",
          ShippingAddressPostalCode: "",
          ShippingAddressState: "",
        };
        if (ClientKey) {
          const ajaxClient = await clientStore?.getClientByKey(ClientKey);
          await setClient(ajaxClient);
        }
        form.setFieldsValue({ ...initFormValues });
      }
    };
    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ClientKey, invoiceStore?.activeInvoice, newSessionInvoice]);

  const toggleDrawer = (toggle: boolean) => {
    invoiceStore?.setSelectToggle(toggle);
    if (setIsQuickSale) setIsQuickSale(false);
  };

  const handleSubmit = async (values: any) => {
    const formValues = form.getFieldsValue(true);
    const changedData: any = {};
    if (invoiceStore?.activeInvoice?.Key) {
      for (let line in formValues) {
        if (formValues[line] !== invoiceStore?.activeInvoice![line as keyof Invoice]) {
          changedData[line] = formValues[line];
        }
      }
    } else {
      for (let line in formValues) {
        changedData[line] = formValues[line];
      }
    }

    if (!billCard && client.Key !== formValues["BillToClientKey"]) {
      changedData["BillToClientKey"] = invoiceStore?.activeInvoice?.ClientKey;
    }

    if (!shippingCard) {
      changedData["ShippingAddressName"] = `${client.FirstName} ${client.LastName}`;
      changedData["ShippingAddressLine1"] = client.AddressLine1;
      changedData["ShippingAddressLine2"] = client.AddressLine2;
      changedData["ShippingAddressCity"] = client.AddressCity;
      changedData["ShippingAddressState"] = client.AddressState;
      changedData["ShippingAddressPostalCode"] = client.AddressPostcode;
      changedData["ShippingAddressCountry"] = client.AddressCountry;
    }
    if (Object.keys(changedData).length === 0) {
      message.warning("No Data Changed");
      return;
    }

    changedData.Key = invoiceStore?.activeInvoice?.Key;
    await invoiceStore?.addInvoice(changedData);
    if (clientStore?.clientInfo?.ClientNumber) {
      await invoiceStore?.getInvoiceList({
        action: "ClientNumber",
        value: clientStore?.clientInfo.ClientNumber,
      });
    }
  };

  const handleClientAction = (option: any) => {
    switch (option.key) {
      case "Call":
        phoneCallStore?.setSelectToggle(true);
        break;
      case "Task":
        taskStore?.setSelectToggle(true);
        break;
      case "Payment":
        invoiceStore?.setActivePayment({});
        setPaymentModal(true);
        break;
      default:
        phoneCallStore?.setSelectToggle(false);
        taskStore?.setSelectToggle(false);
        appointmentStore?.setSelectToggle(false);
        invoiceStore?.setSelectToggle(false);
        sessionStore?.setSelectToggle(false);

        break;
    }
  };
  // console.log(paymentModal);

  const items = [
    {
      label: "Call",
      key: "Call",
    },
    {
      label: "Task",
      key: "Task",
    },
    {
      label: "Payment",
      key: "Payment",
    },
  ];

  const menuProps = {
    items,
    onClick: handleClientAction,
  };

  const ModalHeader = () => {
    return (
      <Row>
        <Col xs={8} className="invoiceDrawerTitle">
          <Title level={2}>{drawerTitle}</Title>
        </Col>
        {invoiceStore?.drawerType !== "payment" && (
          <Col
            xs={16}
            style={{
              textAlign: "right",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Space>
              <Button
                icon={<PrinterOutlined />}
                // disabled={isLoading}
                type="primary"
                className="ant-btn printConfirmationBtn"
                style={{ backgroundColor: "#16425B", borderColor: "#16425B", color: "white" }}
                shape="round"
                onClick={handleInvoicePrint}
              >
                Print Invoice
              </Button>
              <Button
                icon={<CheckOutlined />}
                // disabled={isLoading}
                className="btn-action save"
                style={{
                  backgroundColor: "rgb(255, 179, 28)",
                  borderColor: "rgb(255, 179, 28)",
                  color: "white",
                }}
                shape="round"
                onClick={() => form.validateFields().then(() => form.submit())}
              >
                Save
              </Button>

              <Dropdown menu={menuProps} trigger={["click"]}>
                <Button
                  className="btn-action"
                  style={{
                    backgroundColor: "#708D65",
                    borderColor: "#708D65",
                    color: "white",
                  }}
                >
                  New
                </Button>
              </Dropdown>
            </Space>
          </Col>
        )}

        <PaymentModal visible={paymentModal} setPaymentModal={setPaymentModal} />
        {/* <Modal
          title={<PaymentModalHeader />}
          visible={paymentModal}
          footer={null}
          onCancel={() => setPaymentModal(false)}
        >
          <Form form={paymentForm} onFinish={handlePaymentSubmit}>
            <div style={{ padding: "2rem" }}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Radio.Group
                    onChange={(e: any) => {
                      // setDiscountType(e.target.value);
                    }}
                    // value={discountType}
                  >
                    <Radio value={"Payment"}>Payment</Radio>
                    <Radio value={"Refund"}>Refund</Radio>
                  </Radio.Group>
                  <Form.Item name="PaymentMethodDescription">
                    <SelectField
                      value={invoiceStore?.activeInvoice?.InvoiceType}
                      options={[
                        { label: "Cash", value: "Cash" },
                        { label: "Check", value: "Check" },
                        { label: "Credit Card", value: "CreditCard" },
                      ]}
                      label="Payment Method"
                      showSearch={false}
                      showArrow={false}
                      suffixIcon={<></>}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="Amount">
                    <InputField bordered label={"Payment Amount"} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="PaymentDueDate">
                    <DateField
                      label={"Payment Due Date"}
                      suffixIcon={<></>}
                      allowClear={false}
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="Refundable">
                    <Checkbox
                      onChange={(e: any) => {
                        paymentForm.setFieldsValue({
                          Refundable: e.target.checked,
                        });
                      }}
                    >
                      Refundable
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="FuturePayment">
                    <Checkbox
                      onChange={(e: any) => {
                        paymentForm.setFieldsValue({
                          FuturePayment: e.target.checked,
                        });
                      }}
                    >
                      Future Payment
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="CardNumber">
                    <InputField bordered label={"Card Number"} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="ExpirationDate">
                    <DateField
                      label={"Expiration Date"}
                      suffixIcon={<></>}
                      allowClear={false}
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="ReferenceNumber">
                    <InputField bordered label={"Reference Number"} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="AuthorizationCode">
                    <InputField bordered label={"Authorization Code"} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="AuthorizationDate">
                    <DateField
                      label={"Authorization Date"}
                      suffixIcon={<></>}
                      allowClear={false}
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal> */}
      </Row>
    );
  };

  const drawerParams: DrawerProps = {
    width:
      invoiceStore?.drawerType === "payment"
        ? Math.min(innerWidth, 700)
        : screens.xs
        ? innerWidth
        : innerWidth - 250,
    placement: "right",
    closable: true,
    forceRender: false,
    destroyOnClose: true,
    title: <ModalHeader />,
    maskStyle: {},
    className: "payment-iframe-container",
    push: {
      distance: 20,
    },
  };

  const InvoiceTabs = () => (
    <Form form={form} onFinish={handleSubmit}>
      <div>
        <Tabs
          className="client-tabs"
          activeKey={tab}
          onTabClick={(key: string) => setTab(key)}
          type="card"
          size={"small"}
        >
          <TabPane tab="Order Detail" key="1">
            <OrderDetails />
          </TabPane>
          <TabPane tab="Additional Info" key="2">
            <InvoiceDetails {...InvoiceDetailProps} />
          </TabPane>

          <TabPane tab="Custom Fields" key="4">
            <div style={{ padding: "2rem" }}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomField1">
                        <InputField label={"Custom Field 1"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomField2">
                        <InputField label={"Custom Field 2"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomField3">
                        <InputField label={"Custom Field 3"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomField4">
                        <InputField label={"Custom Field 4"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomField5">
                        <InputField label={"Custom Field 5"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomField6">
                        <InputField label={"Custom Field 6"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomField7">
                        <InputField label={"Custom Field 7"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomField8">
                        <InputField label={"Custom Field 8"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomField9">
                        <InputField label={"Custom Field 9"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomField10">
                        <InputField label={"Custom Field 10"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomDateField1">
                        <DateField
                          label="Custom Date Field 1"
                          suffixIcon={<></>}
                          allowClear={false}
                          placeholder=""
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomDateField2">
                        <DateField
                          label="Custom Date Field 2"
                          suffixIcon={<></>}
                          allowClear={false}
                          placeholder=""
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomDateField3">
                        <DateField
                          label="Custom Date Field 3"
                          suffixIcon={<></>}
                          allowClear={false}
                          placeholder=""
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomDateField4">
                        <DateField
                          label="Custom Date Field 4"
                          suffixIcon={<></>}
                          allowClear={false}
                          placeholder=""
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomDateField5">
                        <DateField
                          label="Custom Date Field 5"
                          suffixIcon={<></>}
                          allowClear={false}
                          placeholder=""
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomDateField6">
                        <DateField
                          label="Custom Date Field 6"
                          suffixIcon={<></>}
                          allowClear={false}
                          placeholder=""
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomDateField7">
                        <DateField
                          label="Custom Date Field 7"
                          suffixIcon={<></>}
                          allowClear={false}
                          placeholder=""
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomDateField8">
                        <DateField
                          label="Custom Date Field 8"
                          suffixIcon={<></>}
                          allowClear={false}
                          placeholder=""
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomDateField9">
                        <DateField
                          label="Custom Date Field 9"
                          suffixIcon={<></>}
                          allowClear={false}
                          placeholder=""
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomDateField10">
                        <DateField
                          label="Custom Date Field 10"
                          suffixIcon={<></>}
                          allowClear={false}
                          placeholder=""
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomNotesField1">
                        <InputField label={"Custom Notes Field 1"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomNotesField2">
                        <InputField label={"Custom Notes Field 2"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomNotesField3">
                        <InputField label={"Custom Notes Field 3"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomNotesField4">
                        <InputField label={"Custom Notes Field 4"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomNotesField5">
                        <InputField label={"Custom Notes Field 5"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomNotesField6">
                        <InputField label={"Custom Notes Field 6"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomNotesField7">
                        <InputField label={"Custom Notes Field 7"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomNotesField8">
                        <InputField label={"Custom Notes Field 8"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomNotesField9">
                        <InputField label={"Custom Notes Field 9"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomNotesField10">
                        <InputField label={"Custom Notes Field 10"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomBoolean1" valuePropName="checked">
                        <Checkbox
                          onChange={(e) => {
                            form.setFieldsValue({ CustomBoolean1: e.target.checked });
                          }}
                        >
                          Custom Boolean 1
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomBoolean2" valuePropName="checked">
                        <Checkbox
                          onChange={(e) => {
                            form.setFieldsValue({ CustomBoolean2: e.target.checked });
                          }}
                        >
                          Custom Boolean 2
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomBoolean3" valuePropName="checked">
                        <Checkbox
                          onChange={(e) => {
                            form.setFieldsValue({ CustomBoolean3: e.target.checked });
                          }}
                        >
                          Custom Boolean 3
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomBoolean4" valuePropName="checked">
                        <Checkbox
                          onChange={(e) => {
                            form.setFieldsValue({ CustomBoolean4: e.target.checked });
                          }}
                        >
                          Custom Boolean 4
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomBoolean5" valuePropName="checked">
                        <Checkbox
                          onChange={(e) => {
                            form.setFieldsValue({ CustomBoolean5: e.target.checked });
                          }}
                        >
                          Custom Boolean 5
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomBoolean6" valuePropName="checked">
                        <Checkbox
                          onChange={(e) => {
                            form.setFieldsValue({ CustomBoolean6: e.target.checked });
                          }}
                        >
                          Custom Boolean 6
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomBoolean7" valuePropName="checked">
                        <Checkbox
                          onChange={(e) => {
                            form.setFieldsValue({ CustomBoolean7: e.target.checked });
                          }}
                        >
                          Custom Boolean 7
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomBoolean8" valuePropName="checked">
                        <Checkbox
                          onChange={(e) => {
                            form.setFieldsValue({ CustomBoolean8: e.target.checked });
                          }}
                        >
                          Custom Boolean 8
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomBoolean9" valuePropName="checked">
                        <Checkbox
                          onChange={(e) => {
                            form.setFieldsValue({ CustomBoolean9: e.target.checked });
                          }}
                        >
                          Custom Boolean 9
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item name="CustomBoolean10" valuePropName="checked">
                        <Checkbox
                          onChange={(e) => {
                            form.setFieldsValue({ CustomBoolean10: e.target.checked });
                          }}
                        >
                          Custom Boolean 10
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane tab="Notes" key="3">
            <div style={{ padding: "2rem" }}>
              <Form.Item name="Notes">
                <InputField isTextarea label={"Add Note"} />
              </Form.Item>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Form>
  );

  const DrawerContent = () =>
    invoiceStore?.drawerType === "payment" ? (
      <iframe
        src={invoiceStore?.activeInvoice?.PaymentUrl}
        title={"Payment"}
        className="payment-iframe"
      />
    ) : (
      <InvoiceTabs />
    );

  return (
    <Drawer
      onClose={() => {
        if (invoiceStore?.isCreatingNew) {
          setDeleteInvoiceModal(true);
        } else {
          toggleDrawer(false);
        }
      }}
      visible={invoiceStore?.isSelectToggle}
      {...drawerParams}
    >
      <Spin size={"large"} spinning={invoiceStore?.isLoading}>
        <DrawerContent />
        <ProCard className={`printWrapper ${showPrint ? "show" : "hide"}`}>
          <QAInvoicePrint invoiceDetails={invoiceStore?.activeInvoice} ref={invoicePrintRef} />
        </ProCard>
        <Modal
          visible={deleteInvoiceModal}
          onOk={async () => {
            setDeleteInvoiceModal(false);
            form.validateFields().then(() => form.submit());
            invoiceStore?.setCreatingNew(false);
            toggleDrawer(false);
          }}
          onCancel={async () => {
            setDeleteInvoiceModal(false);
            await invoiceStore?.deleteInvoice(invoiceStore?.activeInvoice?.InvoiceKey || "");
            invoiceStore?.setCreatingNew(false);
            toggleDrawer(false);
          }}
          cancelText="No"
          okText="Yes"
        >
          Do you wish to save changes to this invoice record?
        </Modal>
      </Spin>
    </Drawer>
  );
};

export default inject(
  "invoiceStore",
  "clientStore",
  "sessionStore",
  "userStore",
  "cartStore",
  "appointmentStore",
  "phoneCallStore",
  "taskStore"
)(observer(InvoiceDrawerView));
