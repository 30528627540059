import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { List } from "antd";
import dayjs from "dayjs";

import "./StatusBucket.scss";
import { SessionStoreClass } from "stores/SessionStore";

interface Props {
  sessionStatusKey?: any;
  sessionStore?: SessionStoreClass;
}

const Bucket: React.FC<Props> = ({ sessionStatusKey, sessionStore }) => {
  const [sessionList, setSessionList] = useState<any>([]);

  const generateTaskList = async () => {
    const list = await sessionStore?.getSessionsByStatus(sessionStatusKey);
    setSessionList(list);
  };

  useEffect(() => {
    if (sessionStatusKey) generateTaskList();
  }, [sessionStatusKey]);

  return (
    <List
      dataSource={sessionList}
      loadMore={true}
      pagination={{ pageSize: 5 }}
      renderItem={(item: any) => {
        return (
          <List.Item>
            <div className="quickAccessList">
              <div className="quickAccessList__Date">
                <label>
                  {/* <span className="quickAccessList__Date--Icon"></span> */}
                  <span className="quickAccessList__Date--Day">
                    {/* <CalendarOutlined /> */}
                    {dayjs(item.StartDate).format("DD")}
                  </span>
                  <span className="quickAccessList__Date--Year">
                    {dayjs(item.StartDate).format("MMM YYYY")}
                  </span>
                </label>
              </div>
              <div className="quickAccessList__Content">
                <div className="quickAccessList__Content--Body">
                  <div>
                    <label>
                      <b>Session No: </b>
                      {item.SessionNumber ? item.SessionNumber : "---"}
                    </label>
                    <p>{item.Description}</p>
                  </div>
                </div>
              </div>
            </div>
          </List.Item>
        );
      }}
      bordered
      style={{ border: 0, backgroundColor: "#fff" }}
    ></List>
  );
};

export default inject("sessionStore")(observer(Bucket));
