import React from "react";
import { inject, observer } from "mobx-react";
import { Typography, Drawer, Row, Col, Grid } from "antd";
import type { DrawerProps } from "antd/lib/drawer";

import type { UserStoreClass } from "stores/UserStore";
import type { ClientStoreClass } from "stores/ClientStore";
import useWindowDimension from "components/util/Dimension";
import { MessageStoreClass } from "stores/MessageStore";
import ViewEmail from "components/forms/ViewEmail";
import ViewMessage from "components/forms/ViewMessage";
const { useBreakpoint } = Grid;

interface Props extends DrawerProps {
  clientKey?: string | undefined;
  userStore?: UserStoreClass;
  clientStore?: ClientStoreClass;
  messageStore?: MessageStoreClass;
}

const { Title } = Typography;

const MessageDrawerView: React.FC<Props> = ({
  clientKey,
  children,
  userStore,
  clientStore,
  messageStore,
  ...props
}): React.ReactElement => {
  const { innerWidth } = useWindowDimension();
  const screens = useBreakpoint();
  const ModalHeader = () => {
    return (
      <Row>
        <Col>
          <Title level={2}>Message</Title>
        </Col>
      </Row>
    );
  };

  const defaultParams: DrawerProps = {
    width: screens.xs ? innerWidth : innerWidth - 250,
    placement: "right",
    closable: true,
    forceRender: false,
    destroyOnClose: true,
    title: <ModalHeader />,
    maskStyle: {},
    className: "drawer-form drawer-padded",
    ...props,
  };

  const toggleDrawer = () => {
    messageStore?.setSelectToggle(false);
    messageStore?.setEmailDetails(undefined);
    messageStore?.setSmsDetails(undefined);
  };

  return (
    <>
      <Drawer onClose={toggleDrawer} visible={messageStore?.isSelectToggle} {...defaultParams}>
        {messageStore?.emailDetails ? <ViewEmail /> : <ViewMessage />}
      </Drawer>
    </>
  );
};

export default inject("userStore", "clientStore", "messageStore")(observer(MessageDrawerView));
