import { observable, makeObservable, action } from "mobx";
import type { RouteMetadata } from "interfaces/routeMetadata.int";

export class RouteMetadataStoreClass {
  currentRouteMetadata: RouteMetadata | null = null;

  constructor() {
    makeObservable(this, {
      currentRouteMetadata: observable,
      updateCurrentRouteMetadata: action,
      getCurrentRouteMetadata: action,
    });
  }

  updateCurrentRouteMetadata(routeMetadata: RouteMetadata) {
    this.currentRouteMetadata = routeMetadata;
  }

  getCurrentRouteMetadata() {
    return this.currentRouteMetadata;
  }
}

const RouteMetadataStore = new RouteMetadataStoreClass();
export default RouteMetadataStore;
