import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Layout, Grid, message } from "antd";
import { useHistory, useParams } from "react-router-dom";

import type { ScheduleStoreClass } from "stores/ScheduleStore";
import type { SessionStoreClass } from "stores/SessionStore";
import type { AppointmentStoreClass } from "stores/AppointmentStore";
import type { LocationProps } from "interfaces/location.int";
import AppointmentDrawerForm from "components/drawers/AppointmentDrawerForm";
import SessionDrawerForm from "components/drawers/SessionDrawerForm";
import ClientTabs from "components/tabs/ClientTabs";
import CalendarDesktop from "components/schedule/CalendarDesktop";
import CalendarMobile from "components/schedule/CalendarMobile";
import { saveLocalStorage } from "components/util/localStorage";
import { AuthStoreClass } from "stores/AuthStore";
import ActionsSchedule from "components/schedule/actions/ActionsSchedule";

interface Props {
  scheduleStore: ScheduleStoreClass;
  sessionStore?: SessionStoreClass;
  appointmentStore?: AppointmentStoreClass;
  authStore?: AuthStoreClass;
}

const { Content } = Layout;
const { useBreakpoint } = Grid;

const SchedulePage: React.FC<Props> = ({
  scheduleStore,
  authStore,
  sessionStore,
  appointmentStore,
}) => {
  const screens = useBreakpoint();
  const history = useHistory();
  const { id, type, action } = useParams<LocationProps>();

  useEffect(() => {
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const init = async () => {
    scheduleStore?.setFilterUserKey(authStore?.authenticatedUser?.UserKey);
    scheduleStore?.setFilterResourceKey("");
    try {
      await scheduleStore.getCalendarList(authStore?.authenticatedUser?.UserKey);
      triggerDrawer();
    } catch (e) {
      message.warning("Unable to establish a connection. Try logging in again.");
    }
  };

  const triggerDrawer = () => {
    if (action === "edit" && type && id) {
      let typeObj: "Appointment" | "Session" = type === "appointment" ? "Appointment" : "Session";
      if (type === "appointment") {
        appointmentStore?.setSelectToggle(true);
      } else if (type === "session") {
        sessionStore?.setSelectToggle(true);
      }
      scheduleStore?.setActiveSchedule({
        [`${typeObj}Key`]: id,
      });
    }
  };

  const handleToggle = (isOpen: boolean) => {
    if (!isOpen) history.push("/schedule");
  };

  return (
    <Content className="main-content">
      <ActionsSchedule />
      {screens.md ? <CalendarDesktop /> : <CalendarMobile />}
      <AppointmentDrawerForm onToggle={handleToggle} />
      <SessionDrawerForm onToggle={handleToggle} />
      <ClientTabs />
    </Content>
  );
};

export default inject(
  "scheduleStore",
  "authStore",
  "appointmentStore",
  "sessionStore"
)(observer(SchedulePage));
