import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Form, Input, Row, Col, message } from "antd";
import { inject, observer } from "mobx-react";
import type { FormInstance } from "antd/lib/form/hooks/useForm";
import {
  AlignLeftOutlined,
  TeamOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  FlagOutlined,
  ClusterOutlined,
  ReconciliationOutlined,
  ContactsOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";

import "./switch.scss";

import type {
  Department,
  PhoneCall,
  PhoneCallFormValues,
  PhoneCallType,
} from "interfaces/phonecall.int";
import type { PhoneCallStoreClass } from "stores/PhoneCallStore";
import ClientsSelectField from "./items/ClientsSelectField";
import NotesField from "./items/NotesField";
import DateField from "./items/DateField";
import InputField from "./items/InputField";
import SelectField from "./items/SelectField";
import UsersSelectField from "./items/UsersSelectField";
import ClientRow from "../rows/ClientRow";
import { saveLocalStorage } from "components/util/localStorage";
import { AuthStoreClass } from "stores/AuthStore";
import { ClientStoreClass } from "stores/ClientStore";

dayjs.extend(customParseFormat);

const formDirection = [
  {
    label: "Outgoing",
    value: "Outgoing",
  },
  {
    label: "Incoming",
    value: "Incoming",
  },
];

const formPriority = [
  {
    label: 0,
    value: 0,
  },
  ...Array.from({ length: 10 }, (_v, k) => {
    return {
      label: k + 1,
      value: k + 1,
    };
  }),
];

interface Props {
  form?: FormInstance;
  onSuccess?: () => void;
  onError?: () => void;
  phoneCallStore?: PhoneCallStoreClass;
  authStore?: AuthStoreClass;
  activeClientKey?: any;
  clientKey?: string;
  clientStore?: ClientStoreClass;
}

const AddEditPhoneCall: React.FC<Props> = ({
  form,
  onSuccess,
  onError,
  phoneCallStore,
  authStore,
  activeClientKey,
  clientKey = "",
  clientStore,
}): React.ReactElement => {
  const [notes, setNotes] = useState<string>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const isLoading: boolean = phoneCallStore?.isLoading || false;
  const formGrid = (isEnd = false, inForce = true) => {
    const marginVar = inForce ? 10 : "";
    return {
      xs: 24,
      sm: 12,
      style: {
        marginBottom: isEnd ? "" : marginVar,
      },
    };
  };

  const initialFormValues: PhoneCallFormValues = {
    key: "",
    client: clientKey,
    user: authStore?.authenticatedUser?.UserKey,
    description: "",
    type: "",
    department: "",
    priority: 0,
    due_date: "",
    completed_date: "",
    contact: "",
    direction: "Outgoing",
    notes: "",
  };

  useEffect(() => {
    setTimeout(() => {
      if (form && phoneCallStore?.activePhoneCall?.Key) {
        setIsEdit(true);
        fetchPhoneCall();
      }
    }, 500);
  }, [phoneCallStore?.isSelectToggle]);

  useEffect(() => {
    return () => phoneCallStore?.setActivePhoneCall(undefined);
  }, []);

  const fetchPhoneCall = async () => {
    try {
      const data: PhoneCall = await phoneCallStore?.getPhoneCall();

      let initialValues = {
        key: data?.Key,
        client: data?.ClientKey,
        user: data?.UserKey,
        description: data?.Description,
        type: data?.CallType,
        department: data?.DepartmentDescription,
        departmentKey: data?.DepartmentKey,
        priority: data?.Priority,
        due_date: data?.DueDate ? dayjs(data?.DueDate) : null,
        completed_date: data?.CompletedDate ? dayjs(data?.CompletedDate) : null,
        contact: data?.Contact,
      };

      form?.setFieldsValue(initialValues);
      setNotes(data?.Notes || "");

      await clientStore?.getClient(data?.ClientKey);

      if (data?.Key) {
        saveLocalStorage({
          name: "Viewed",
          value: `Phone call - ${
            clientStore?.clientInfo?.FirstName + " " + clientStore?.clientInfo?.LastName
          }${data.Description && ` - ${data.Description}`}`,
          // url: `/calls/edit/${data.Key}`,
          type: {
            key: data.Key,
            value: "PhoneCall",
          },
        });
      }
    } catch (e) {
      onError && onError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleSubmit = async (values: PhoneCallFormValues) => {
    const {
      key,
      client,
      user,
      description,
      type,
      department,
      priority,
      due_date,
      completed_date,
      contact,
      direction,
      notes,
    } = values;

    let DueDate = due_date ? dayjs(due_date).format("YYYY-MM-DDT00:00:00") : "";
    let CompletedDate = completed_date ? dayjs(completed_date).format("YYYY-MM-DDT00:00:00") : "";

    let data: PhoneCall = {
      Key: key,
      ClientKey: client,
      Description: description,
      CallType: type,
      DepartmentKey: department,
      DepartmentDescription: department,
      UserKey: user,
      Priority: priority,
      DueDate,
      CompletedDate,
      Incoming: direction === "Incoming",
      Contact: contact,
    };

    if (notes !== "") data = { ...data, Notes: notes };

    if (phoneCallStore?.activePhoneCall) {
      let changedData: any = {};
      for (let line in data) {
        if (
          data[line as keyof PhoneCall] !== phoneCallStore?.activePhoneCall[line as keyof PhoneCall]
        ) {
          changedData[line] = data[line as keyof PhoneCall];
        }
      }
      if (Object.keys(changedData).length === 0) {
        message.warning("No data changed");
        return;
      }

      data = { Key: key, ...changedData };
    }

    const loadingMsgKey = "phoneCall-key";
    message.loading({ content: "Saving...", key: loadingMsgKey, duration: 0 });

    try {
      await phoneCallStore?.addPhoneCall(data).then(async (res: PhoneCall) => {
        message.success({
          content: "Call successfully saved!",
          key: loadingMsgKey,
          duration: 1,
        });
        onSuccess && onSuccess();

        await clientStore?.getClient(res.ClientKey);

        if (data.Key) {
          saveLocalStorage({
            name: "Updated",
            value: `Phone call -  ${
              clientStore?.clientInfo?.FirstName + " " + clientStore?.clientInfo?.LastName
            }${res.Description && ` - ${res.Description}`}`,
            // url: `/calls/edit/${res.Key}`,
            type: {
              key: res.Key,
              value: "PhoneCall",
            },
          });
        } else {
          saveLocalStorage({
            name: "Added",
            value: `Phone call - ${
              clientStore?.clientInfo?.FirstName + " " + clientStore?.clientInfo?.LastName
            }${res.Description && ` - ${res.Description}`}`,
            // url: `/calls/edit/${res.Key}`,
            type: {
              key: res.Key,
              value: "PhoneCall",
            },
          });
        }
      });
    } catch (e) {
      message.error({
        content: "Sorry, something has gone wrong. Please try again later.",
        key: loadingMsgKey,
        duration: 1,
      });
    }
  };

  return (
    <Form form={form} initialValues={initialFormValues} onFinish={handleSubmit}>
      <Form.Item label="Key" name="key" hidden={true}>
        <Input id="key" />
      </Form.Item>

      {phoneCallStore?.activePhoneCall?.Key && phoneCallStore?.activePhoneCall.ClientKey && (
        <div className="form-group">
          <Form.Item
            name="client"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
            rules={[{ required: true, message: "Please select a client!" }]}
          >
            <ClientRow
              clientKey={phoneCallStore?.activePhoneCall?.ClientKey}
              phoneNumber={phoneCallStore?.activePhoneCall?.Contact}
            />
          </Form.Item>
        </div>
      )}

      {isEdit && activeClientKey && !phoneCallStore?.activePhoneCall?.Key && (
        <div className="form-group">
          <Form.Item
            name="client"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
            // rules={[{ required: true, message: "Please select a client!" }]}
          >
            <ClientRow
              clientKey={activeClientKey}
              phoneCall={true}
              // phoneNumber={phoneCallStore?.activePhoneCall?.Contact}
            />
          </Form.Item>
        </div>
      )}
      {(!isEdit || (!phoneCallStore?.activePhoneCall?.Key && !activeClientKey)) && (
        <div className="form-group">
          <Form.Item
            name="client"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
            rules={[{ required: true, message: "Please select a client!" }]}
          >
            <ClientsSelectField form={form} disabled={isLoading || isEdit} />
          </Form.Item>
        </div>
      )}

      <div className="form-group">
        <Form.Item
          name="description"
          hasFeedback={isLoading}
          validateStatus={isLoading ? "validating" : undefined}
        >
          <InputField
            prefix={<AlignLeftOutlined />}
            disabled={isLoading}
            label="Description"
            isTextarea={true}
          />
        </Form.Item>

        <Row gutter={20}>
          <Col {...formGrid()}>
            <Form.Item
              name="type"
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
            >
              <SelectField
                options={phoneCallStore?.phoneCallTypes.map((phoneCallType: PhoneCallType) => {
                  return {
                    label: phoneCallType.Description || "",
                    value: phoneCallType.Key || "",
                  };
                })}
                prefix={<ReconciliationOutlined />}
                disabled={isLoading}
                label="Type"
                showSearch={false}
                showArrow={false}
                suffixIcon={<></>}
              />
            </Form.Item>
          </Col>
          <Col {...formGrid()}>
            <Form.Item
              name="department"
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
            >
              <SelectField
                options={phoneCallStore?.departments.map((department: Department) => {
                  return {
                    label: department.Description || "",
                    value: department.Key || "",
                  };
                })}
                prefix={<ClusterOutlined />}
                disabled={isLoading}
                label="Department"
                showSearch={false}
                showArrow={false}
                suffixIcon={<></>}
              />
            </Form.Item>
          </Col>
          <Col {...formGrid()}>
            <Form.Item
              name="user"
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
              rules={[{ required: true, message: "Please select a user!" }]}
            >
              <UsersSelectField disabled={isLoading} prefix={<TeamOutlined />} />
            </Form.Item>
          </Col>
          <Col {...formGrid()}>
            <Form.Item
              name="priority"
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
            >
              <SelectField
                options={formPriority}
                prefix={<OrderedListOutlined />}
                disabled={isLoading}
                label="Priority"
                showSearch={false}
                showArrow={false}
                suffixIcon={<></>}
              />
            </Form.Item>
          </Col>
          <Col {...formGrid()}>
            <Form.Item
              name="due_date"
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
            >
              <DateField
                label="Due Date"
                prefix={<CalendarOutlined />}
                disabled={isLoading}
                suffixIcon={<></>}
                allowClear={false}
                placeholder=""
              />
            </Form.Item>
          </Col>
          <Col {...formGrid()}>
            <Form.Item
              name="completed_date"
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
            >
              <DateField
                label="Completed Date"
                prefix={<CarryOutOutlined />}
                disabled={isLoading}
                suffixIcon={<></>}
                allowClear={false}
                placeholder=""
              />
            </Form.Item>
          </Col>
          <Col {...formGrid()}>
            <Form.Item
              name="contact"
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
            >
              <InputField prefix={<ContactsOutlined />} disabled={isLoading} label="Contact" />
            </Form.Item>
          </Col>
          <Col {...formGrid()}>
            <Form.Item
              name="direction"
              hasFeedback={isLoading}
              validateStatus={isLoading ? "validating" : undefined}
            >
              <SelectField
                options={formDirection}
                prefix={<FlagOutlined />}
                disabled={isLoading}
                label="Direction"
                showSearch={false}
                showArrow={false}
                suffixIcon={<></>}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <div className="form-group">
        <Form.Item
          name="notes"
          hasFeedback={isLoading}
          validateStatus={isLoading ? "validating" : undefined}
        >
          <NotesField noteType="call" disabled={isLoading} notes={notes} />
        </Form.Item>
      </div>
    </Form>
  );
};

export default inject("phoneCallStore", "authStore", "clientStore")(observer(AddEditPhoneCall));
