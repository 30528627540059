import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { observer, inject } from "mobx-react";
import CartItem from "components/cart-item/cart-item";
import CartPromoApplier from "components/cart-promo-applier/cart-promo-applier";
import "./checkout-review.scss";

const CheckoutReview = (props: any) => {
  return (
    <>
      <div className="checkOutReviwer">
        <div className="cartHeader">
          <h1>Order Review</h1>
        </div>
        <div className="cartItemsHeader">
          <div className="headerCell productCell">Product Name</div>
          <div className="headerCell priceCell">Price</div>
          <div className="headerCell quantityCell">Quantity</div>
          <div className="headerCell subtotalCell">Subtotal</div>
        </div>
        <div className="cartContent">
          <div className="cartItemsWrapper">
            <div className="cartsItemsBody">
              {props?.cartStore?.cart?.InvoiceDetails?.map((item: any) => {
                return <CartItem hideControls key={item.Key} item={item} />;
              })}
            </div>
          </div>
          <CartPromoApplier />
          <div className="discountWrapper">
            <p className="label">Discount:</p>
            <p className="amount">$0.00</p>
          </div>
          <div className="paymentItems">
            <p className="label">Tax:</p>
            <p className="amount">$0.00</p>
          </div>
          <div className="paymentItems">
            <p className="label">Shipping Charges:</p>
            <p className="amount">$0.00</p>
          </div>
          <div className="totalWrapper">
            <p className="label">Subtotal:</p>
            <p className="amount">
              {props.cartStore.cart.TotalStringFormatted}
            </p>
          </div>
        </div>
        <div className="checkoutWizardBottomNavigator">
          <div className="leftPart" onClick={props.navigateToPrevStep}>
            <ArrowLeftOutlined /> &nbsp; Back
          </div>
          <div className="rightPart">
            <button className="submit" onClick={props.navigateToNextStep}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default inject("cartStore", "galleryStore")(observer(CheckoutReview));
