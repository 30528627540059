import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Button, Spin } from "antd";
import { useQuery } from "react-query";
import { ClientStoreClass } from "stores/ClientStore";
import { PhoneOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import "./ClientRow.scss";
interface Props {
  clientStore?: ClientStoreClass;
  clientKey: string;
  phoneNumber?: string;
  phoneCall?: boolean;
}

const ClientRow: React.FC<Props> = ({ clientStore, clientKey, phoneNumber, phoneCall }) => {
  const { data, isLoading } = useQuery(
    ["client", clientKey],
    () => {
      return clientStore!.getClient(clientKey);
    },
    { staleTime: 1000 * 60 }
  );

  useEffect(() => {
    console.log("called in clientRow");
    return () => {
      clientStore?.setActiveClientKey("");
    };
  }, [clientKey]);

  const handleOnClick = () => {
    clientStore?.setActiveClientKey(clientKey);
    clientStore?.setSelectToggle(true);
  };

  if (isLoading) return <Spin />;
  return (
    <>
      <Row gutter={20} justify="start" align="middle">
        <Col>
          <UserOutlined style={{ fontSize: "1.6rem" }} />
        </Col>
        <Col xs={12}>
          <div style={{ display: "flex" }}>
            <div>
              <label
                className="form-control-label"
                style={{
                  opacity: 0.5,
                  fontSize: "0.75rem",
                }}
              >
                Client
              </label>
              <div>
                {data?.LastName ? `${data?.LastName}, ` : ""}
                {data?.FirstName}
              </div>
            </div>
            <Button
              style={{ marginLeft: "2rem" }}
              icon={<TeamOutlined />}
              onClick={() => handleOnClick()}
            >
              Client Details
            </Button>
          </div>
        </Col>
        {/* !clientStore?.activeClientKey &&  */}
        {!clientStore?.isSelectToggle && !phoneCall ? <Col xs={10}></Col> : null}
      </Row>
      {phoneNumber && (
        <Row className="call-client-row" justify="end">
          <Col xs={12} md={12}>
            <Button
              style={{ width: "139px" }}
              href={`tel:+${phoneNumber}`}
              icon={<PhoneOutlined />}
            >
              Call Client
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default inject("clientStore")(observer(ClientRow));
