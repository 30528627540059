import { ScheduleList } from "interfaces/schedule.int";

export interface TimeObjExtra {
  description?: string;
  type?: string;
  key?: string;
  isOccupied?: boolean;
  startTime?: number;
  endTime?: number;
  columnSource?: number;
  rowCount?: number;
  originData?: ScheduleList;
  Color?: number;
}

export interface TimeObj {
  idx: number;
  label: string;
  data?: Array<TimeObjExtra> | [];
}

const useTimesInADay = (): Array<TimeObj> => {
  const timeData: Array<TimeObj> = Array.from({ length: 24 }, (_, i) => {
    let ampm = i < 12 ? "am" : "pm";
    let halfTime = i < 12 ? i : i - 12;

    return {
      idx: i,
      label: `${halfTime === 0 ? 12 : halfTime}${ampm}`,
    };
  });

  return timeData;
};

export default useTimesInADay;
