import React from "react";
import { Row, Col } from "antd";
import { inject, observer } from "mobx-react";

import "./CalendarHeader.scss";
import { AuthStoreClass } from "stores/AuthStore";
import { ScheduleStoreClass } from "stores/ScheduleStore";

interface Props {
  authStore?: AuthStoreClass;
  scheduleStore?: ScheduleStoreClass;
}

const CalendarHeader: React.FC<Props> = ({ authStore, scheduleStore }) => {
  if (scheduleStore?.isShowTimeSelect !== "week") {
    return (
      <div className="schedule-calendar-header">
        <Row justify="space-between">
          <Col flex="auto">Sun</Col>
          <Col flex="auto">Mon</Col>
          <Col flex="auto">Tue</Col>
          <Col flex="auto">Wed</Col>
          <Col flex="auto">Thu</Col>
          <Col flex="auto">Fri</Col>
          <Col flex="auto">Sat</Col>
        </Row>
      </div>
    );
  }

  //const offset = authStore?.companySettings?.FirstDayOfWeek || 0;
  const offset =0;
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dates: any = [];

  const createDayCol = (i: number) => {
    return <Col flex="auto" key={i}>{daysOfWeek[i]}</Col>;
  };
  const createColumns = () => {
    for (let i = offset; i < 7; i++) {
      dates.push(createDayCol(i));
    }
    for (let i = 0; i < offset; i++) {
      dates.push(createDayCol(i));
    }
  };
  createColumns();
  return (
    <div className="schedule-calendar-header">
      <Row justify="space-between">
        {dates.map((date: any) => date)}
        {/* <Col flex="auto">Sun</Col>
        <Col flex="auto">Mon</Col>
        <Col flex="auto">Tue</Col>
        <Col flex="auto">Wed</Col>
        <Col flex="auto">Thu</Col>
        <Col flex="auto">Fri</Col>
        <Col flex="auto">Sat</Col> */}
      </Row>
    </div>
  );
};

export default inject("authStore", "scheduleStore")(observer(CalendarHeader));
