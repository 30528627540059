import React, { useState, useEffect, useCallback } from "react";
import { Buffer } from "buffer";
import {
  AppstoreFilled,
  AppstoreOutlined,
  FolderFilled,
  FolderOutlined,
  PlayCircleFilled,
  PlayCircleOutlined,
  RedoOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { Badge, message, Spin } from "antd";
import SessionCartDrawer from "components/drawers/session-cart-drawer/session-cart-drawer";
import GalleryFolderView from "components/gallery-folder-view/gallery-folder-view";
import GlobalImageViewer from "components/global-image-viewer/global-image-viewer";
import EventEmitter from "utils/event-emitter";
import "./session-gallery.scss";
import ImageFolderUpdateModal from "components/image-folder-update-modal/image-folder-update-modal";
import { observer, inject } from "mobx-react";
import GallerySlideViewer from "components/gallery-slide-viewer/gallery-slide-viewer";

// import Dropzone from "react-dropzone";
import { useDropzone } from "react-dropzone";

const SessionGallery = (props: any) => {
  const { galleryStore } = props;

  const [searchText, setSearchText] = useState("");
  const [activeMode, setActiveMode] = useState("grid-viewer");
  const [, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const toArrayBuffer = (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const onDrop = useCallback(async (acceptedFiles) => {
    console.log("Initiating Upload");
    setIsUploading(true);
    if (acceptedFiles) {
      // console.log(acceptedFiles);
      // setFileNames(acceptedFiles?.map(file => file.name));

      let uploadJob = await galleryStore.initImageUpload(
        acceptedFiles.length,
        acceptedFiles.map((file: any) => file.name)
      );

      if (uploadJob) {
        for (var i = 0; i < acceptedFiles.length; i++) {
          // setCurrentImageIndex(i + 1);
          let url = uploadJob.UploadUri + "/" + acceptedFiles[i].name + uploadJob.SASToken;

          let arrayBuffer: string = await toArrayBuffer(acceptedFiles[i]);
          let imgBuffer = Buffer.from(arrayBuffer);

          if (imgBuffer)
            await galleryStore.putSessionImageUploadBlobStorage(url, imgBuffer, imgBuffer.length);
        }

        galleryStore.completeImageUpload(uploadJob.UploadJobId);

        console.log("Image Upload is complete....");
        setIsUploading(false);
        message.success("Upload is complete. Please wait while the images are being processed.");

        setTimeout(() => {
          galleryStore.fetchSessionImages();
        }, 8000);

        // await sessionStore.refreshSessionDetail(
        //   sessionStore.activeSession.Key
        // );

        // // await timeout(1000);
        // setProgressBar(false);

        // setUploadMessage(true);
        // // onSuccess();
      } else {
        console.log("Upload failed!");
        message.error("Sorry, there was an error uploading the file.");
      }
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    _initiateLoad();
  }, []);

  const _initiateLoad = async () => {
    if (!props?.cartStore?.cart?.InvoiceDetails?.length) {
      setIsLoading(true);
      await props.cartStore.fetchCartMetaData(
        props.sessionStore?.currentSessionDetail.PriceListKey
      );
      await props.cartStore.fetchCart();
    }
    setIsLoading(false);
  };

  const modes = [
    {
      modeName: "refresh",
      "modeIcon:": {
        filled: <RedoOutlined />,
        outlined: <RedoOutlined />,
      },
      renderMode: (modeIcon: string) => {
        return modeIcon === "filled" ? <RedoOutlined /> : <RedoOutlined />;
      },
      onClick: () => {
        message.info("Fetching images...");
        galleryStore.fetchSessionImages();
      },
    },
    {
      modeName: "grid-viewer",
      "modeIcon:": {
        filled: <AppstoreFilled />,
        outlined: <AppstoreOutlined />,
      },
      renderMode: (modeIcon: string) => {
        return modeIcon === "filled" ? <AppstoreFilled /> : <AppstoreOutlined />;
      },
      onClick: () => {
        setActiveMode("grid-viewer");
      },
    },
    {
      modeName: "folder-viewer",
      "modeIcon:": {
        filled: <FolderFilled />,
        outlined: <FolderOutlined />,
      },
      renderMode: (modeIcon: string) => {
        return modeIcon === "filled" ? <FolderFilled /> : <FolderOutlined />;
      },
      onClick: () => {
        setActiveMode("folder-viewer");
      },
    },
    {
      modeName: "slide-autoplayer",
      "modeIcon:": {
        filled: <PlayCircleFilled />,
        outlined: <PlayCircleOutlined />,
      },
      renderMode: (modeIcon: string) => {
        return modeIcon === "filled" ? <PlayCircleFilled /> : <PlayCircleOutlined />;
      },
      onClick: () => {
        if (props?.galleryStore?.sessionImages?.length) {
          EventEmitter.trigger("show-slide-view", {
            groupImages: props?.galleryStore?.sessionImages,
            image: props?.galleryStore?.sessionImages[0],
            autoPlay: true,
          });
        }
      },
    },
    {
      modeName: "cart-viewer",
      "modeIcon:": {
        filled: <ShoppingCartOutlined />,
        outlined: <ShoppingCartOutlined />,
      },
      renderMode: (modeIcon: string) => {
        let badgeCount = props?.cartStore?.cart?.InvoiceDetails?.length;
        return (
          <Badge count={badgeCount} overflowCount={10}>
            {modeIcon === "filled" ? <ShoppingCartOutlined /> : <ShoppingCartOutlined />}
          </Badge>
        );
      },
      onClick: () => {
        if (props?.galleryStore?.sessionImages?.length) {
          EventEmitter.trigger("show-cart");
        }
      },
    },
  ];

  const _generateActionButtonClass = (mode: any) => {
    let actionButtonClass = "action";
    if (mode.modeName === activeMode) {
      actionButtonClass += " selected";
    }
    return actionButtonClass;
  };

  const _renderGalleryContent = () => {
    switch (activeMode) {
      case "grid-viewer": {
        return (
          <>
            <Spin tip="Upload files. Please wait..." spinning={isUploading}>
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <p>Drag 'n' drop files here, or click to select files</p>
                )}
              </div>
            </Spin>

            <GlobalImageViewer searchText={searchText} />
          </>
        );
      }
      case "folder-viewer": {
        return (
          <>
            <GalleryFolderView searchText={searchText} />
          </>
        );
      }
    }
  };

  return (
    <>
      <div className="galleryHeader">
        <div className="headerRightPart">
          <div className="searchWrapper">
            <input
              type="text"
              className="searchInput"
              placeholder="Search"
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
            />
            <div className="searchIcon">
              <SearchOutlined />
            </div>
          </div>
        </div>
        <div className="headerLeftPart">
          <div className="actionsWrapper">
            {modes.map((mode, modeIndex) => (
              <div
                className={_generateActionButtonClass(mode)}
                key={modeIndex}
                onClick={(e) => mode.onClick()}
              >
                <div className="filled">{mode.renderMode("filled")}</div>
                <div className="outline">{mode.renderMode("outline")}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="galleryContent">{_renderGalleryContent()}</div>
      <SessionCartDrawer />
      <ImageFolderUpdateModal />
      <GallerySlideViewer />
    </>
  );
};

export default inject("galleryStore", "cartStore", "sessionStore")(observer(SessionGallery));
