import React from "react";
import { inject, observer } from "mobx-react";
import { List, Empty } from "antd";
import dayjs from "dayjs";

import type { PhoneCallList } from "interfaces/phonecall.int";
import RenderPhoneCall from "components/calls/RenderPhoneCall";
import { PhoneCallStoreClass } from "stores/PhoneCallStore";

interface Props {
  phoneCallStore?: PhoneCallStoreClass;
  filtered?: Boolean;
}

const PhoneCallsList: React.FC<Props> = ({ phoneCallStore, ...props }) => {
  const emptyTextLocale = props.filtered
    ? "You have no phone calls for the next 2 days."
    : "You have no phone calls at this moment.";

  let filteredCalls: Array<PhoneCallList> = [];
  if (props.filtered) {
    phoneCallStore?.phoneCallList.map((phoneCall: any) => {
      if (
        !phoneCall.Completed ||
        (dayjs(phoneCall.DueDate) >= dayjs(new Date()) &&
          dayjs(phoneCall.DueDate) <= dayjs().add(2, "day"))
      ) {
        filteredCalls.push(phoneCall);
      }
    });
  } else {
    filteredCalls = phoneCallStore?.phoneCallList ? phoneCallStore?.phoneCallList : [];
  }

  // filteredCalls = phoneCallStore?.showCompleted
  //   ? filteredCalls
  //   : filteredCalls.filter((call: any) => !call.Completed);

  filteredCalls = filteredCalls.slice().sort((a: any, b: any) => {
    let dateA = new Date(a.DueDate);
    let dateB = new Date(b.DueDate);
    if (dateA.getTime() === -62135568422000) {
      dateA = new Date(8640000000000000);
    }
    if (dateB.getTime() === -62135568422000) {
      dateB = new Date(8640000000000000);
    }
    return dateA.getTime() - dateB.getTime();
  });
  return (
    <>
      {filteredCalls.length > 0 ? (
        <List
          dataSource={filteredCalls}
          loading={phoneCallStore?.isLoading}
          renderItem={(item: PhoneCallList) => <RenderPhoneCall item={item} />}
          bordered
          style={{ border: 0, backgroundColor: "#fff" }}
        />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyTextLocale} />
      )}
    </>
  );
};

export default inject("phoneCallStore")(observer(PhoneCallsList));
