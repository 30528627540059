import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Form, Space, Button, Drawer, Typography, Modal, message, Grid } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import type { DrawerProps } from "antd/lib/drawer";
import { DeleteOutlined, CheckOutlined } from "@ant-design/icons";

import useWindowDimension from "components/util/Dimension";
import { ClockStoreClass } from "stores/ClockStore";
import AddEditClock from "components/forms/AddEditClock";
const { useBreakpoint } = Grid;

dayjs.extend(utc);

const { Title } = Typography;

interface Props {
  onToggle?: (x: boolean) => void;
  clockStore?: ClockStoreClass;
  activeClientKey?: any;
}

const ClockDrawerForm: React.FC<Props> = ({ onToggle, clockStore, activeClientKey }) => {
  const [isDeleteModalShown, setIsDeleteModalShown] = useState<boolean>(false);
  const { innerWidth } = useWindowDimension();
  const [form] = Form.useForm();
  const screens = useBreakpoint();

  useEffect(() => {
    if (!clockStore?.isSelectToggle) {
      setTimeout(() => {
        form?.resetFields();
      }, 500);
    }
  }, [clockStore?.isSelectToggle, clockStore?.activeRecentTime]);

  const toggleDrawer = (toggle: boolean) => {
    onToggle && onToggle(toggle);
    clockStore?.setSelectToggle(toggle);
    clockStore?.setActiveRecentTime(undefined);
  };

  const handleSuccess = async () => {
    // try {
    //   await taskStore?.getTasks().then(() => {
    //     message.success(`Resynced data!`);
    //   });
    // } catch (e) {}
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalShown(true);
    // clockStore?.deleteClockEntry('');
  };

  const handleDelete = async () => {
    const loadingMsgKey = "notification-key";
    message.loading({
      content: "Deleting...",
      key: loadingMsgKey,
      duration: 0,
    });
    setIsDeleteModalShown(false);
    clockStore?.setSelectToggle(false);
    clockStore
      ?.deleteClockEntry(clockStore?.activeRecentTime?.UserID, clockStore?.activeRecentTime?.Key)
      .then((data: any) => {
        message.destroy(loadingMsgKey);
        clockStore?.setSelectToggle(false);
        clockStore?.setActiveRecentTime(undefined);
        clockStore?.getClockList();
        message.success("Deleted Successfully");
      })
      .catch((error) => {
        message.error("Something Went Wrong");
        clockStore?.setSelectToggle(false);
        clockStore?.setActiveRecentTime(undefined);
      });
  };

  const handleError = () => {
    toggleDrawer(false);
    setTimeout(() => {
      message.error(
        "Unable to fetch recent clock entries. Does not exist or try logging in again."
      );
    }, 500);
  };

  const ModalHeader = () => {
    return (
      <Row>
        <Col xs={10}>
          <Title level={2}>Clock</Title>
        </Col>
        <Col
          xs={14}
          style={{
            textAlign: "right",
          }}
        >
          <Space>
            {typeof clockStore?.activeRecentTime !== "undefined" && (
              <Button
                icon={<DeleteOutlined />}
                disabled={clockStore?.isLoading}
                type="text"
                onClick={toggleDeleteModal}
              >
                Delete
              </Button>
            )}
            <Button
              icon={<CheckOutlined />}
              disabled={clockStore?.isLoading}
              type="primary"
              className="btn-action"
              style={{
                backgroundColor: "rgb(255, 179, 28)",
                borderColor: "rgb(255, 179, 28)",
                color: "white",
              }}
              shape="round"
              onClick={() => form.validateFields().then(() => form.submit())}
            >
              Save
            </Button>
          </Space>
        </Col>
      </Row>
    );
  };

  const drawerParams: DrawerProps = {
    width: screens.xs ? innerWidth : innerWidth - 250,
    placement: "right",
    closable: true,
    forceRender: false,
    destroyOnClose: true,
    title: <ModalHeader />,
    maskStyle: {},
    className: "drawer-form",
    push: {
      distance: 20,
    },
  };

  return (
    <>
      <Drawer
        onClose={() => toggleDrawer(false)}
        visible={clockStore?.isSelectToggle}
        {...drawerParams}
      >
        <AddEditClock form={form} onSuccess={handleSuccess} onError={handleError} />
      </Drawer>
      <Modal
        title={`Delete a task`}
        visible={isDeleteModalShown}
        onCancel={toggleDeleteModal}
        onOk={handleDelete}
      >
        <p>Are you sure you want to delete this task?</p>
      </Modal>
    </>
  );
};

export default inject("clockStore")(observer(ClockDrawerForm));
