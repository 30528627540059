import React from "react";
import { inject, observer } from "mobx-react";
import { Card, List } from "antd";

import type { ClockStoreClass } from "stores/ClockStore";
import convertToHHmm from "components/util/ConvertToHhMm";

interface Props {
  clockStore?: ClockStoreClass;
}

const WeekClock: React.FC<Props> = ({ clockStore }: Props): React.ReactElement => {
  const { RegularMinutes, OvertimeMinutes, TotalMinutes } = clockStore?.clockList?.WeekSummary || {
    TotalMinutes: 0,
    OvertimeMinutes: 0,
    RegularMinutes: 0,
  };
  return (
    <Card headStyle={{ backgroundColor: "#bed1df", color: "white" }} title="This Week">
      <List>
        <List.Item>
          <div>Regular</div>
          <div>{convertToHHmm(RegularMinutes)}</div>
        </List.Item>
        <List.Item>
          <div>Overtime</div>
          <div>{convertToHHmm(OvertimeMinutes)}</div>
        </List.Item>
        <List.Item>
          <div>Total Time</div>
          <div>{convertToHHmm(TotalMinutes)}</div>
        </List.Item>
      </List>
    </Card>
  );
};

export default inject("clockStore")(observer(WeekClock));
