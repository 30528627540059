import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { List, Checkbox, Typography, message, Col } from "antd";
import dayjs from "dayjs";
import { TaskStoreClass } from "stores/TaskStore";
import type { ListProps } from "antd/lib/list";

interface Props extends ListProps<any> {
  taskStore?: TaskStoreClass;
  filtered?: Boolean;
  fromDashboard?: boolean;
  loading?: boolean;
}

const { Title, Text } = Typography;

const DashboardTaksList: React.FC<Props> = ({ taskStore, fromDashboard, loading, ...props }) => {
  const [taskList, setTaskList] = useState<any>();

  useEffect(() => {
    let filteredTask: any = [];
    filteredTask = taskStore?.sortedTasksList.filter((task: any) => {
      return (
        dayjs(task.DueDate) >= dayjs(new Date()) && dayjs(task.DueDate) <= dayjs().add(2, "day")
      );
    });
    setTaskList(filteredTask);
  }, [taskStore?.sortedTasksList]);

  const onClickCompleted = async (key: any, task: any) => {
    // task.stopPropagation();
    let changedData: any = {};
    if (task.target.checked) {
      changedData["CompleteDate"] = dayjs().format("YYYY-MM-DDTHH:mm:ss");
      changedData["Completed"] = true;
    } else changedData["Completed"] = false;
    let values = { Key: key, ...changedData };
    await taskStore?.editTask(values);
    message.success("Task successfully saved!");
  };

  const handleClick = async (item: any) => {
    taskStore?.getTask(item.Key);
    taskStore?.setSelectToggle(true);
  };

  return (
    <List
      dataSource={taskList}
      locale={{ emptyText: "You have no tasks due in the next 2 days" }}
      loading={loading}
      renderItem={(item: any) => {
        return (
          <Col className="homeTask">
            <Col className="homeTaskInner">
              <Col
                className="homeTask__Date"
                onClick={(e) => {
                  handleClick(item);
                }}
              >
                <Col className="homeTask__Date--Round">
                  <Title className="homeTask__Date--Day" level={2}>
                    {dayjs(item.DueDate).format("DD")}
                  </Title>
                  <Text className="homeTask__Date--Month">
                    {dayjs(item.DueDate).format("MMM YYYY")}
                  </Text>
                  <Text className="homeTask__Date--Label">Due Date</Text>
                </Col>
              </Col>
              <Col
                className="homeTask__Details"
                onClick={(e) => {
                  handleClick(item);
                }}
              >
                <Title className="homeTask__Details--Title">
                  {item.Subject || item.TaskSubject || "..."}
                </Title>
                <Col className="homeTask__Details--Status">
                  {item.Urgent == "!" || item.Urgent == "True" ? (
                    <Text className="taskStatus red" mark>
                      Urgent
                    </Text>
                  ) : null}
                  {item.Personal == "True" ? (
                    <Text className="taskStatus" mark>
                      Personal
                    </Text>
                  ) : null}
                </Col>
                <Text className="homeTask__Details--Description">{item.Description}</Text>
              </Col>
              <Col
                className="homeTask__Action"
                onClick={(e) => {
                  handleClick(item);
                }}
              >
                <Checkbox
                  checked={item.Complete}
                  // disabled={false}
                  onChange={(e) => {
                    onClickCompleted(item.Key, e);
                  }}
                  className={`homeTask__Action--Marked ${item?.Complete ? "completed" : ""}`}
                >
                  {item?.Complete ? "Completed" : "Mark as Complete"}
                </Checkbox>
              </Col>
            </Col>
          </Col>
        );
      }}
      {...props}
    />
  );
};

export default inject("taskStore")(observer(DashboardTaksList));
