import React from "react";
import { inject, observer } from "mobx-react";
import { Layout, List, Card, Grid } from "antd";
import classnames from "classnames";

import type { NotificationList } from "interfaces/notification.int";
import type { NotificationStoreClass } from "stores/NotificationStore";

import CheckboxRenderNotification from "components/notification/CheckboxRenderNotification";

const { Content } = Layout;
const { useBreakpoint } = Grid;

interface Props {
  notificationStore?: NotificationStoreClass;
}

const NotificationsPage: React.FC<Props> = ({ notificationStore }) => {
  const screens = useBreakpoint();

  // const addNotification = async () => {
  //   await notificationStore?.addNotification({
  //     Header: "header title",
  //     Body: "This is a body",
  //     Url: "https://google.com/",
  //     UrlText: "Link goes here",
  //     Urgent: true,
  //   });
  // };

  return (
    <Content className="main-content">
      <div
        className={classnames({
          "main-content-pads": screens.md,
        })}
      >
        <Card bordered={screens.md} className="card-listing">
          <List
            dataSource={notificationStore?.sortNotificationList}
            renderItem={(item: NotificationList) => (
              <CheckboxRenderNotification item={item} />
            )}
            bordered
            style={{ border: 0 }}
          />
        </Card>
      </div>
    </Content>
  );
};

export default inject("notificationStore")(observer(NotificationsPage));
