import React, { useEffect, useState } from "react";
import "./cart-summary-viewer.scss";
import { AppstoreAddOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import CartItem from "components/cart-item/cart-item";
import { observer, inject } from "mobx-react";
import CartPhotoSwitcher from "components/cart-photo-switcher/cart-photo-switcher";
import ImageEditor from "components/image-editor/image-editor";
import CartPromoApplier from "components/cart-promo-applier/cart-promo-applier";
import EventEmitter from "utils/event-emitter";

const CartSummaryViewer = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);
  const { switchToProductBrowser } = props;

  useEffect(() => {
    _initiateLoad();
  }, []);

  const _initiateLoad = async () => {
    if (!props?.cartStore?.cart?.InvoiceDetails?.length) {
      setIsLoading(true);
      await props.cartStore.fetchCartMetaData(
        props.sessionStore?.currentSessionDetail.PriceListKey
      );
      await props.cartStore.fetchCart();
    }
    setIsLoading(false);
  };

  const _updateItem = (item: any) => {
    props.cartStore.updateItem(item);
  };

  const _removeItem = (item: any) => {
    props.cartStore.removeItem(item);
  };

  const _generateItemError = (item: any) => {
    // Check if image selection allowed
    const selectedItem = props.cartStore.categories
      .map((item: any) => item.Items)
      .flat(1)
      .find((i: any) => i.Key === item.ItemKey);

    let imageSelectionAllowed = selectedItem?.AllowImageSelection;
    // Check if image edit allowed
    // let isImageEditAllowed = true;
    let isImageRequired = selectedItem?.RequireImage;
    // if (imageSelectionAllowed && !selectedItem.CropRatio) {
    //   isImageEditAllowed = false;
    // }
    // First check if any image is selected or not
    let itemErrorMessage = "";
    if (!item?.FileName?.length && imageSelectionAllowed) {
      itemErrorMessage = "Select an image";
    } else if (
      !(!!item.CropLeft || !!item.CropTop || !!item.CropWidth || !!item.CropHeight) &&
      isImageRequired
    ) {
      itemErrorMessage = "Please adjust the crop levels";
    }
    return itemErrorMessage;
  };

  const _checkout = () => {
    EventEmitter.trigger("hide-cart");
    EventEmitter.trigger("checkout");
  };

  if (isLoading || !props?.galleryStore?.sessionImages?.length) {
    return (
      <div className="cartLoaderWrapper">
        <div className="loader"></div>
      </div>
    );
  } else {
    let cartError = false;
    props?.cartStore?.cart?.InvoiceDetails?.forEach((item: any) => {
      const itemError = _generateItemError(item);
      if (itemError?.length) {
        cartError = true;
      }
    });
    return (
      <>
        <div className="cartSummaryViewerOuterWrapper">
          {props?.cartStore?.cart?.InvoiceDetails?.length ? (
            <>
              <div className="cartSummaryViewerInnerWrapper">
                <div className="cartHeader">
                  <h1>Shopping Cart</h1>
                  <div className="headerCheckoutWrapper">
                    <p className="subtotalLabel">
                      Subtotal: <span>{props.cartStore.cart.TotalStringFormatted}</span>
                    </p>
                    <button
                      className="checkoutBtn"
                      onClick={(e) => {
                        setisSubmitting(true);
                        if (!cartError) {
                          _checkout();
                        }
                      }}
                    >
                      CHECK OUT
                    </button>
                  </div>
                </div>
                {cartError && isSubmitting && (
                  <div className="errorText">Issues with the items, please check</div>
                )}
                <div className="cartItemsHeader">
                  <div className="headerCell productCell">Product Name</div>
                  <div className="headerCell priceCell">Price</div>
                  <div className="headerCell quantityCell">Quantity</div>
                  <div className="headerCell subtotalCell">Subtotal</div>
                </div>
                <div className="cartContent">
                  <div className="cartItemsWrapper">
                    <div className="cartsItemsBody">
                      {props?.cartStore?.cart?.InvoiceDetails?.map((item: any) => {
                        const itemErrorMessage = _generateItemError(item);
                        return (
                          <CartItem
                            key={item.Key}
                            item={item}
                            errorText={itemErrorMessage}
                            showError={isSubmitting && itemErrorMessage.length}
                            updateItem={_updateItem}
                            removeItem={(e: any) => _removeItem(item)}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <CartPromoApplier />
                  <div className="discountWrapper">
                    <p className="label">Discount:</p>
                    <p className="amount">$0.00</p>
                  </div>
                  <div className="totalWrapper">
                    <p className="label">Subtotal:</p>
                    <p className="amount">{props.cartStore.cart.TotalStringFormatted}</p>
                  </div>
                  <div className="cartFooter">
                    <button className="backToShoppingBtn" onClick={switchToProductBrowser}>
                      <ArrowLeftOutlined /> &nbsp; <span>Continue shopping</span>
                    </button>
                    <button
                      className="checkoutBtn"
                      onClick={(e) => {
                        setisSubmitting(true);
                        if (!cartError) {
                          _checkout();
                        }
                      }}
                    >
                      CHECK OUT
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="noItems">
                <div className="labelWrapper">
                  <h4 className="noItemHeader">No items in the cart</h4>
                  <img src="/assets/images/no-cart-icon.png" alt="alt" className="noItemImg" />
                </div>
                <button onClick={switchToProductBrowser}>
                  Browse products &nbsp; <AppstoreAddOutlined />
                </button>
              </div>
            </>
          )}
        </div>
        <CartPhotoSwitcher />
        <ImageEditor />
      </>
    );
  }
};

export default inject("cartStore", "galleryStore", "sessionStore")(observer(CartSummaryViewer));
