import React, { useState, useEffect, useCallback } from "react";
import { inject, observer } from "mobx-react";
import dayjs, { Dayjs } from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import customParseFormat from "dayjs/plugin/customParseFormat";
import classNames from "classnames";
import SwiperCore, { Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import type { ScheduleStoreClass } from "stores/ScheduleStore";
import Calendar from "../util/Calendar";
import CalendarRenderCell from "./CalendarRenderCell";

import "swiper/swiper.scss";
import "./CalendarMonth.scss";

interface Props {
  onSelect?: () => void;
  scheduleStore?: ScheduleStoreClass;
}

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
SwiperCore.use([Virtual]);

const CalendarMonth: React.FC<Props> = ({ onSelect, scheduleStore }) => {
  const [swiperRef, setSwiperRef] = useState<SwiperCore>();

  useEffect(() => {
    if (scheduleStore?.calendarActiveSlideMonthIndex) {
      swiperRef?.slideTo(scheduleStore?.calendarActiveSlideMonthIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleStore?.calendarActiveSlideMonthIndex]);

  const CalendarSlide = useCallback(
    ({ content }: { content: Dayjs }) => {
      let isSameDate =
        Number(scheduleStore?.calendarActiveDate.format("YYYYMM")) ===
        scheduleStore?.calendarActiveYearMonth;
      let activeYearMonth = Number(content.format("YYYYMM"));

      return (
        <>
          <Calendar
            value={isSameDate ? scheduleStore?.calendarActiveDate : content}
            defaultValue={content}
            headerRender={() => <>{isSameDate}</>}
            dateCellRender={(date) => <CalendarRenderCell date={date} />}
            onSelect={(date: Dayjs) => {
              let selectedYearMonth = Number(date.format("YYYYMM"));

              if (swiperRef?.realIndex) {
                if (selectedYearMonth > activeYearMonth) {
                  scheduleStore?.setCalendarActiveSlideMonthIndex(
                    swiperRef?.realIndex + 1
                  );
                } else if (selectedYearMonth < activeYearMonth) {
                  scheduleStore?.setCalendarActiveSlideMonthIndex(
                    swiperRef?.realIndex - 1
                  );
                } else {
                  scheduleStore?.setCalendarActiveSlideMonthIndex(
                    swiperRef?.realIndex
                  );
                }
              }

              scheduleStore?.setCalendarActiveDate(date);
              scheduleStore?.setActiveDate(date.format());
              scheduleStore?.dateSelectCallback(date);
              scheduleStore?.setIsShowTimeSelect("day");

              onSelect && onSelect();
            }}
            className={classNames("schedule-calendar-month", {
              "schedule-calendar-month-current": isSameDate,
            })}
          />
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scheduleStore?.calendarActiveSlideMonthIndex, swiperRef?.realIndex]
  );

  return (
    <div
      className={classNames("calendar-select-container", {
        "calendar-select-container-active":
          scheduleStore?.isShowTimeSelect === "month",
      })}
    >
      {scheduleStore?.isLoading ? (
        <Calendar
          defaultValue={scheduleStore?.calendarActiveDate || dayjs()}
          headerRender={() => <></>}
          className="schedule-calendar-month schedule-calendar-month-current"
        />
      ) : (
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          virtual
          initialSlide={scheduleStore?.calendarActiveSlideMonthIndex}
          onSwiper={setSwiperRef}
          onSlideChange={(swiper) => {
            scheduleStore?.setCalendarActiveSlideMonthIndex(swiper.realIndex);
          }}
        >
          {scheduleStore?.getCalendarSlides.map((dateJs, index) => {
            let content = dayjs(dateJs).format("YYYYMM");
            return (
              <SwiperSlide virtualIndex={Number(content)} key={index}>
                <CalendarSlide content={dateJs} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </div>
  );
};

export default inject("scheduleStore")(observer(CalendarMonth));
