import React, { useState, useRef } from "react";
import { Row, Col, Input, InputNumber } from "antd";
import classNames from "classnames";
import type { InputProps } from "antd/lib/input";
import type { TextAreaProps, TextAreaRef } from "antd/lib/input/TextArea";

import "./Input.scss";

const { TextArea } = Input;

interface Props extends InputProps {
  label?: string;
  isTextarea?: boolean;
  textareaProps?: React.ForwardRefExoticComponent<TextAreaProps & React.RefAttributes<TextAreaRef>>;
  isLogin?: boolean;
  formatter?: any;
  parser?: any;
  currency?: boolean;
}

const InputField: React.FC<Props> = React.forwardRef(
  ({ label, isTextarea, textareaProps, isLogin, ...props }: Props, _ref): React.ReactElement => {
    const [focus, setFocus] = useState<boolean>(false);
    const inputRef = useRef<any>(null);

    const defaultInputProps: InputProps = {
      bordered: false,
      autoComplete: "new-password",
    };

    const newInputProps = {
      ...defaultInputProps,
      ...props,
      prefix: false,
    };

    const handleBlur = () => {
      setFocus(false);
    };

    const handleFocus = () => {
      setFocus(true);
    };

    return (
      <Row
        className={classNames(props?.className, {
          "form-control": true,
          isActive: props?.value || props?.defaultValue || !isLogin,
          isFocus: focus,
          isDisabled: props?.disabled,
        })}
      >
        {props?.prefix && (
          <Col
            style={{ float: "left" }}
            className="form-control-label-col"
            onClick={() => inputRef.current?.focus()}
          >
            {props?.prefix}
          </Col>
        )}
        <Col className={classNames(props?.className, { "form-control-content-col": true })}>
          <div className="form-control-content">
            {label && (
              <label
                htmlFor={props?.id}
                className="form-control-label"
                onClick={() => inputRef.current?.focus()}
              >
                {label}
              </label>
            )}

            {isTextarea ? (
              <TextArea
                {...(props as object as TextAreaProps)}
                autoSize={{ minRows: 5, maxRows: 10 }}
                {...textareaProps}
                onFocus={handleFocus}
                onBlur={handleBlur}
                ref={inputRef}
                bordered={false}
              />
            ) : props.currency ? (
              <InputNumber
                value={Number(props.value)}
                parser={props.parser}
                formatter={props.formatter}
                bordered={false}
                onFocus={handleFocus}
                onBlur={handleBlur}
                ref={inputRef}
              />
            ) : (
              <Input {...newInputProps} onFocus={handleFocus} onBlur={handleBlur} ref={inputRef} />
            )}
          </div>
        </Col>
      </Row>
    );
  }
);

export default InputField;
