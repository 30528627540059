import * as React from "react";
import "./product-category-list-browser.scss";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { observer, inject } from "mobx-react";

const ProductCategoryListBrowser = (props: any) => {
  const { switchToCartSummary, browseProducts } = props;
  console.log("props?.cartStore?.categories :>> ", props?.cartStore?.categories);

  console.log(props.priceList);
  return (
    <>
      <div className="productCategoryListOuterWrapper">
        <div className="navbar">
          <button
            className="navBackBtn"
            onClick={(e) => {
              switchToCartSummary();
            }}
          >
            <ArrowLeftOutlined />
          </button>
          <h4>Select your product category</h4>
        </div>
        <div className="categoryList">
          {props?.cartStore?.categories?.map((category: any, categoryIndex: number) => (
            <div className="category" key={categoryIndex}>
              <div className="infoPart">
                <h5>{category.Name}</h5>
                <p>{category.MarketingDescription}</p>
                <button onClick={(e) => browseProducts(category)}>
                  View Products &nbsp; <ArrowRightOutlined />
                </button>
              </div>
              <div className="graphicPart">
                <img
                  src={
                    category?.ImageUrl?.length
                      ? category.ImageUrl
                      : "https://upload.wikimedia.org/wikipedia/commons/b/b9/No_Cover.jpg"
                  }
                  alt="alt"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default inject("cartStore")(observer(ProductCategoryListBrowser));
