import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Menu } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

import type { SessionStoreClass } from "stores/SessionStore";
import type { AppointmentStoreClass } from "stores/AppointmentStore";
import { ClientStoreClass } from "stores/ClientStore";
import { AuthStoreClass } from "stores/AuthStore";

interface Props {
  sessionStore?: SessionStoreClass;
  appointmentStore?: AppointmentStoreClass;
  clientStore?: ClientStoreClass;
  onClick?: () => void;
  drawerOpened?: boolean;
  appointmentCreated?: boolean;
  clientKey?: any;
  authStore?: AuthStoreClass;
}

const ScheduleDropdownSelect: React.FC<Props> = ({
  sessionStore,
  appointmentStore,
  onClick,
  drawerOpened,
  appointmentCreated,
  clientKey,
  clientStore,
  authStore,
}: Props): React.ReactElement => {
  useEffect(() => {
    if (clientKey) {
      clientStore?.getClient(clientKey);
    }
  }, [clientKey]);

  const handleOnClick = (type: "unlinkedAppointment" | "appointment" | "session") => {
    if (type === "appointment") {
      appointmentStore?.setSelectToggle(true);
      sessionStore?.setSelectToggle(false);
      appointmentStore?.setUnlinkedAppointment(false);
    }

    if (type === "unlinkedAppointment") {
      appointmentStore?.setSelectToggle(true);
      sessionStore?.setSelectToggle(false);
      appointmentStore?.setUnlinkedAppointment(true);
    }

    if (type === "session") {
      sessionStore?.setSelectToggle(true);
      appointmentStore?.setSelectToggle(false);
    }
  };

  return (
    <Menu onClick={onClick} className="navigation-popover">
      {drawerOpened ? (
        <Menu.Item
          key="session"
          icon={<CalendarOutlined className="types-session" />}
          onClick={() => handleOnClick("session")}
        >
          {clientStore?.clientInfo?.FirstName}'s{" "}
          {authStore?.companySettings?.JobDescriptorSingular
            ? authStore?.companySettings?.JobDescriptorSingular
            : "Session"}
        </Menu.Item>
      ) : appointmentCreated ? (
        <>
          <Menu.Item
            key="appointment"
            icon={<CalendarOutlined className="types-appointment" />}
            onClick={() => handleOnClick("appointment")}
          >
            {clientStore?.clientInfo?.FirstName}'s Appointment
          </Menu.Item>
        </>
      ) : (
        <>
          <Menu.Item
            key="appointment"
            icon={<CalendarOutlined className="types-appointment" />}
            onClick={() => handleOnClick("appointment")}
          >
            Appointment
          </Menu.Item>
          <Menu.Item
            key="unlinkedAppointment"
            icon={<CalendarOutlined className="types-appointment" />}
            onClick={() => handleOnClick("unlinkedAppointment")}
          >
            Unlinked Appointment
          </Menu.Item>
          <Menu.Item
            key="session"
            icon={<CalendarOutlined className="types-session" />}
            onClick={() => handleOnClick("session")}
          >
            {authStore?.companySettings?.JobDescriptorSingular
              ? authStore?.companySettings?.JobDescriptorSingular
              : "Session"}
          </Menu.Item>
        </>
      )}
    </Menu>
  );
};

export default inject(
  "sessionStore",
  "appointmentStore",
  "clientStore",
  "authStore"
)(observer(ScheduleDropdownSelect));
