import * as React from "react";
import "./product-list-viewer.scss";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import DropDown from "components/core/dropdown/dropdown";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";

const categories = [
  {
    label: "Category 1",
    value: "category1",
  },
  {
    label: "Category 2",
    value: "category2",
  },
  {
    label: "Category 3",
    value: "category3",
  },
];

const ProductListViewer = (props: any) => {
  const {
    browseCategories,
    onProductSelection,
    selectedCategory,
    onCategoryUpdate,
    selectedImage,
  } = props;

  React.useEffect(() => {}, []);

  const _updateCategory = (categoryValue: any) => {
    const selectedCategory = props?.cartStore?.categories.find(
      (category: any) => category.Key === categoryValue
    );
    if (selectedCategory) {
      onCategoryUpdate(selectedCategory);
    }
  };
  //
  const categoryOptions = toJS(props?.cartStore?.categories).map(
    (category: any) => ({
      unformattedData: category,
      label: category.Name,
      value: category.Key,
    })
  );

  const filteredItems = props?.selectedCategory?.Items.filter(
    (product: any) => {
      if (selectedImage && !product.AllowImageSelection) {
        return false;
      } else {
        return true;
      }
    }
  );

  return (
    <>
      <div className="productListOuterWrapper">
        <div className="navbar">
          <button className="navBackBtn" onClick={browseCategories}>
            <ArrowLeftOutlined />
          </button>
          <h4>Select your product</h4>
        </div>
        <div className="filterWrapper">
          <div className="filter">
            <div className="filterLabel">Category:</div>
            <div className="filterInput">
              <DropDown
                options={categoryOptions}
                selectedLabel={selectedCategory.Name}
                onSelect={_updateCategory}
              />
            </div>
          </div>
          <div className="filter">
            <div className="filterLabel">Sort By:</div>
            <div className="filterInput">
              <DropDown
                options={categories}
                selectedLabel={"Best Selling"}
                onSelect={() => {}}
              />
            </div>
          </div>
        </div>
        <div className="productList">
          {filteredItems?.map((product: any, productIndex: number) => (
            <div className="productWrapper" key={productIndex}>
              <div className="product withOutThumbnail">
                <h3>{product.Name}</h3>
                <p className="price">{product.PriceStringFormatted}</p>
                <p className="description">{product.ProductDescription}</p>
                <button
                  className="productSelectBtn"
                  onClick={(e) => onProductSelection(product)}
                >
                  View Details <ArrowRightOutlined />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default inject("cartStore")(observer(ProductListViewer));
