import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import {
  List,
  Checkbox,
  Typography,
  message,
  Empty,
  Col,
  Row,
  Card,
  Select,
  Modal,
  Button,
} from "antd";
import dayjs from "dayjs";
import { TaskStoreClass } from "stores/TaskStore";
import type { ListProps } from "antd/lib/list";

interface Props extends ListProps<any> {
  taskStore?: TaskStoreClass;
  filtered?: Boolean;
  fromDashboard?: boolean;
}

const TasksList: React.FC<Props> = ({ taskStore, fromDashboard, ...props }) => {
  const [filterOption, setFilterOption] = useState("All");
  const [taskList, setTaskList] = useState<any>();
  const [statusKey, setStatusKey] = useState<string>("");
  const [taskKey, setTaskKey] = useState<string>("");
  const [isStatusModalShown, setIsStatusModalShown] = useState<boolean>(false);
  const [offset, setOffset] = useState(25);
  const { Text } = Typography;
  const emptyTextLocale = props.filtered
    ? "You have no pending tasks for the next 2 days."
    : "You have no tasks at this moment.";

  const onClickCompleted = async (key: any, task: any) => {
    let changedData: any = {};
    if (task.target.checked) {
      changedData["CompleteDate"] = dayjs().format("YYYY-MM-DDTHH:mm:ss");
      changedData["Completed"] = true;
    } else changedData["Completed"] = false;
    let values = { Key: key, ...changedData };
    await taskStore?.editTask(values);
    message.success("Task successfully saved!");
  };

  useEffect(() => {
    let filteredTask: any = [];
    if (filterOption == "All") {
      filteredTask = taskStore?.sortedTasksList.sort(
        (a: any, b: any) => Number(a.Complete) - Number(b.Complete)
      );
      setTaskList(filteredTask);
    }
    if (filterOption == "Today") {
      filteredTask = taskStore?.sortedTasksList.filter((task: any) => {
        return dayjs(task.DueDate).format("MM/DD/YYYY") == dayjs(new Date()).format("MM/DD/YYYY");
      });
      setTaskList(filteredTask);
    }
    if (filterOption == "Completed") {
      filteredTask = taskStore?.sortedTasksList.filter((task: any) => {
        return task.Complete == true;
      });
      setTaskList(filteredTask);
    }
    if (filterOption == "PastDue") {
      filteredTask = taskStore?.sortedTasksList.filter(
        (task: any) => new Date(task.DueDate) < new Date()
      );
      setTaskList(filteredTask);
    }
    if (filterOption == "Upcoming") {
      filteredTask = taskStore?.sortedTasksList.filter(
        (task: any) => new Date(task.DueDate) > new Date()
      );
      setTaskList(filteredTask);
    }
  }, [taskStore?.sortedTasksList, filterOption]);

  const handleStatusChange = (statusKey: any, taskKey: any) => {
    setStatusKey(statusKey);
    setTaskKey(taskKey);
    setIsStatusModalShown(true);
  };

  const removeStatusModal = () => {
    setStatusKey("");
    setTaskKey("");
    setIsStatusModalShown(false);
  };

  const handleStatusSave = async () => {
    let changedData: any = {};
    changedData["TaskStatusKey"] = statusKey;
    let velues = { Key: taskKey, ...changedData };
    await taskStore
      ?.editTask(velues)
      .then((data: any) => {
        message.success("Task status successfully updated");
      })
      .catch((error: any) => {
        message.error("Something went wrong");
      });
    setStatusKey("");
    setTaskKey("");
    setIsStatusModalShown(false);
  };

  const getDefaultStatus = (statusID: string, typeID: string) => {
    if (statusID && typeID)
      return taskStore?.taskStatuses.filter(
        (task: any) => task.Key == statusID && task.TaskTypeID == typeID
      )[0]?.Description;
    return "Select status";
  };

  const statusComboBox = (taskTypeId: string) => {
    let taskStatuses: any = taskStore?.taskStatuses.filter(
      (status: any) => status.TaskTypeID == taskTypeId
    );
    let filteredStatuses: any = [];

    taskStatuses.map((status: any) => {
      filteredStatuses.push({ label: status.Description, value: status.Key });
    });

    return filteredStatuses;
  };

  const loadMore = !taskStore?.isLoading ? (
    <div
      style={{
        textAlign: "center",
        marginTop: 12,
        height: 32,
        lineHeight: "32px",
      }}
    >
      <Button
        onClick={() => {
          taskStore?.loadMoreTasks(offset);
          setOffset(offset + 25);
        }}
      >
        Load More
      </Button>
    </div>
  ) : null;

  return (
    <>
      {taskList ? (
        <>
          {!fromDashboard ? (
            <Card bordered={false} className="taskFilters">
              <Text
                className={`${filterOption == "All" ? "selected" : ""}`}
                onClick={() => {
                  setFilterOption("All");
                }}
              >
                All Tasks
              </Text>
              <Text
                className={`${filterOption == "Today" ? "selected" : ""}`}
                onClick={() => {
                  setFilterOption("Today");
                }}
              >
                Due Today
              </Text>
              <Text
                className={`${filterOption == "Upcoming" ? "selected" : ""}`}
                onClick={() => {
                  setFilterOption("Upcoming");
                }}
              >
                Upcoming
              </Text>
              <Text
                className={`${filterOption == "PastDue" ? "selected" : ""}`}
                onClick={() => {
                  setFilterOption("PastDue");
                }}
              >
                Past Due
              </Text>
              <Text
                className={`${filterOption == "Completed" ? "selected" : ""}`}
                onClick={() => {
                  setFilterOption("Completed");
                }}
              >
                Completed
              </Text>
            </Card>
          ) : null}
          <List
            dataSource={taskList}
            loadMore={loadMore}
            loading={taskStore?.isLoading}
            renderItem={(item: any) => {
              return (
                <Row className="taskListItem">
                  <Col
                    xxl={18}
                    xl={15}
                    lg={13}
                    sm={24}
                    xs={24}
                    className="taskListItem__Content"
                    onClick={async () => {
                      taskStore?.getTask(item.Key);
                      taskStore?.setSelectToggle(true);
                    }}
                  >
                    <Row>
                      <Col className="taskListItem__Date">
                        <Text className="taskListItem__Date--Day">
                          {dayjs(item.DueDate).format("DD")}
                        </Text>
                        <Text className="taskListItem__Date--MonthYear">
                          {dayjs(item.DueDate).format("MMM YYYY")}
                        </Text>
                        <Text className="taskListItem__Date--Due">Due Date</Text>
                      </Col>
                      <Col className="taskListItem__Data">
                        <Text className="taskListItem__Data--Title">
                          {item.Subject || item.TaskSubject || "..."}
                        </Text>
                        <Col className="taskListItem__Data--Status">
                          {item.Urgent == "!" || item.Urgent == "True" ? (
                            <Text className="taskStatus red" mark>
                              Urgent Task
                            </Text>
                          ) : (
                            <Text className="taskStatus" />
                          )}
                          {item.Personal == "True" ? (
                            <Text className="taskStatus" mark>
                              Personal Task
                            </Text>
                          ) : (
                            <Text className="taskStatus" />
                          )}
                        </Col>
                      </Col>
                    </Row>
                  </Col>
                  <Col xxl={6} xl={9} lg={11} sm={24} xs={24} className="taskListItem__Action">
                    {!item?.Complete && !fromDashboard && item.TaskTypeID != "" ? (
                      <Select
                        options={statusComboBox(item.TaskTypeID)}
                        defaultValue={getDefaultStatus(item.TaskStatusID, item.TaskTypeID)}
                        placeholder="Type"
                        onChange={(e) => handleStatusChange(e, item.Key)}
                        size={"large"}
                      />
                    ) : null}
                    <Checkbox
                      checked={item.Complete}
                      // disabled={false}
                      onChange={(e) => onClickCompleted(item.Key, e)}
                      className={`ant-list-item-check ${item?.Complete ? "completed" : ""}`}
                    >
                      {item?.Complete ? "Completed" : "Mark as Complete"}
                    </Checkbox>
                  </Col>
                </Row>
              );
            }}
            {...props}
          />
        </>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyTextLocale} />
      )}
      <Modal
        title={`Task status`}
        visible={isStatusModalShown}
        onCancel={removeStatusModal}
        onOk={handleStatusSave}
      >
        <p>Are you sure you want to save this status?</p>
      </Modal>
    </>
  );
};

export default inject("taskStore")(observer(TasksList));
