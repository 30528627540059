import React from "react";
import { inject, observer } from "mobx-react";
import type { SelectProps } from "antd/lib/select";
import { PushpinOutlined } from "@ant-design/icons";

import SelectField from "./SelectField";
import type { ScheduleStoreClass } from "stores/ScheduleStore";

import "./Input.scss";

interface Props extends SelectProps<any> {
  scheduleStore?: ScheduleStoreClass;
  label?: string;
}

const LocationsSelectField: React.FC<Props> = React.forwardRef(
  ({ scheduleStore, label, ...props }: Props, _ref): React.ReactElement => {
    const defaultProps: SelectProps<any> = {
      showSearch: true,
      options: scheduleStore?.selectResourceList,
      optionFilterProp: "label",
      filterOption: (input, option) => {
        let isFound: number | undefined = option?.label
          ?.toString()
          .toLowerCase()
          .indexOf(input?.toLowerCase());
        return isFound !== undefined && isFound >= 0 ? true : false;
      },
      ...props,
    };

    return (
      <SelectField
        prefix={<PushpinOutlined />}
        disabled={props?.disabled}
        label={`${label ? label : "Location"}`}
        showArrow={false}
        suffixIcon={<></>}
        {...defaultProps}
      />
    );
  }
);

export default inject("scheduleStore")(observer(LocationsSelectField));
