import { observable, action, makeObservable } from "mobx";

export class AlertStoreClass {
  alertMessage: string = "";
  alertType: "error" | "success" | "info" = "error";

  constructor() {
    makeObservable(this, {
      alertMessage: observable,
      alertType: observable,
      setAlertMessage: action,
    });
  }

  setAlertMessage(
    alertMessage: string,
    alertType: "error" | "success" | "info",
    timeShown: number = 5000
  ) {
    this.alertMessage = alertMessage;
    this.alertType = alertType;
    setTimeout(() => {
      this.alertMessage = "";
      this.alertType = "error";
    }, timeShown);
  }
}

const AlertStore = new AlertStoreClass();

export default AlertStore;
