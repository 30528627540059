import React from "react";
import { inject, observer } from "mobx-react";
import { List, Empty, Col, message, Checkbox, Typography, Row } from "antd";
import dayjs from "dayjs";

import type { PhoneCallList } from "interfaces/phonecall.int";
import { PhoneCallStoreClass } from "stores/PhoneCallStore";
import { CheckOutlined, PhoneOutlined } from "@ant-design/icons";

interface Props {
  phoneCallStore?: PhoneCallStoreClass;
  filtered?: Boolean;
  loading?: boolean;
}

const { Title, Text } = Typography;

const DashboardPhoneCalls: React.FC<Props> = ({ loading, phoneCallStore, ...props }) => {
  const emptyTextLocale = props.filtered
    ? "You have no phone calls for the next 2 days."
    : "You have no phone calls at this moment.";

  let twoDaysFromNow = new Date();
  twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 2);

  let filteredCalls: Array<PhoneCallList> = [];
  if (props.filtered) {
    phoneCallStore?.phoneCallList.map((phoneCall: any) => {
      if (
        !phoneCall.Completed &&
        Date.parse(phoneCall.DueDate) >= Date.now() &&
        Date.parse(phoneCall.DueDate) <= Date.now() + 172800000 // 172800000 is milliseconds in two days
      ) {
        filteredCalls.push(phoneCall);
      }
    });

    phoneCallStore?.phoneCallList.map((phoneCall: any) => {
      if (
        phoneCall.Completed &&
        Date.parse(phoneCall.DueDate) >= Date.now() &&
        Date.parse(phoneCall.DueDate) <= Date.now() + 172800000
      ) {
        filteredCalls.push(phoneCall);
      }
    });
  } else {
    filteredCalls = phoneCallStore?.phoneCallList ? phoneCallStore?.phoneCallList : [];
  }

  const onClickCompleted = async (key: any, task: any) => {
    phoneCallStore?.setSelectToggle(false);
    task.stopPropagation();
    let changedData: any = {};
    if (task.target.checked) {
      changedData["CompleteDate"] = dayjs().format("YYYY-MM-DDTHH:mm:ss");
      changedData["Completed"] = true;
    } else changedData["Completed"] = false;
    let values = { Key: key, ...changedData };
    await phoneCallStore?.editPhoneCall(values);
    message.success("Call successfully saved!");
  };

  const handleClick = (item: PhoneCallList) => {
    phoneCallStore?.setActivePhoneCall({
      Key: item.Key,
    });
    phoneCallStore?.setSelectToggle(true);
  };

  return (
    <>
      {filteredCalls.length > 0 ? (
        <List
          dataSource={filteredCalls}
          loading={loading}
          renderItem={(item: PhoneCallList) => {
            return (
              <Row className="PhoneCallRow">
                <Col className="PhoneCall" onClick={() => handleClick(item)}>
                  <Col className="PhoneCall__Icon">
                    {!item.Completed ? <PhoneOutlined /> : <CheckOutlined />}
                  </Col>
                  <Col className="PhoneCall__Details">
                    <Col className="PhoneCall__Details--text">
                      <Title level={3}>{`${item.ClientFirstName} ${item.ClientLastName}`}</Title>
                      <Text>{item.Description}</Text>
                      <Text>Due: {dayjs(item.DueDate).format("MM-DD-YYYY")}</Text>
                    </Col>
                    {!item.Completed && (
                      <Col className="PhoneCall__Details--Mark">
                        <Checkbox
                          checked={item.Completed}
                          onChange={(e) => {
                            onClickCompleted(item.Key, e);
                          }}
                        >
                          <CheckOutlined />
                        </Checkbox>
                      </Col>
                    )}
                  </Col>
                </Col>
              </Row>
            );
          }}
          bordered
          style={{ border: 0 }}
        />
      ) : (
        <p style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.25)" }}>{emptyTextLocale}</p>
      )}
    </>
  );
};

export default inject("phoneCallStore")(observer(DashboardPhoneCalls));
