import React, { forwardRef } from "react";
import "./PrintConfirmation.scss";
import dayjs from "dayjs";
import { Col, Row, Table, Typography } from "antd";
import { AuthStoreClass } from "stores/AuthStore";
import { inject, observer } from "mobx-react";
import { ClientStoreClass } from "stores/ClientStore";
import SessionStore from "stores/SessionStore";

var relativeTime = require("dayjs/plugin/relativeTime");
var duration = require("dayjs/plugin/duration");
dayjs.extend(relativeTime, duration);

const { Title, Paragraph } = Typography;

interface Props {
  invoiceDetails?: any;
  clientStore?: ClientStoreClass;
  authStore?: AuthStoreClass;
  sessionDetails?: any;
}

const SessionPrintConfirmation = forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => {
  const { invoiceDetails, clientStore, authStore, sessionDetails } = props;

  const billToClient = false;
  const printWord = "Invoice";

  // print head table config

  const dataSourceHead = [
    {
      key: "1",
      Image: authStore?.companySettings.Logo,
      Address: [
        authStore?.companySettings.CompanyName,
        authStore?.companySettings.CompanyAdd1,
        authStore?.companySettings.CompanyAdd2,
        authStore?.companySettings.CompanyCity,
        authStore?.companySettings.CompanyState,
        authStore?.companySettings.CompanyCountry,
        authStore?.companySettings.CompanyEmail,
      ],
      Title: `${
        authStore?.companySettings.JobDescriptorSingular
          ? authStore?.companySettings.JobDescriptorSingular
          : "Session"
      } Confirmation`,
    },
  ];

  const columnsHead = [
    {
      title: "Image",
      dataIndex: "Image",
      key: "Image",
      render: (Image: any) => <img src={`${Image}`} alt="" width={150} />,
      width: "10%",
    },
    {
      title: "Address",
      dataIndex: "Address",
      key: "Address",
      render: (Address: any) =>
        Address.map((item: any, index: any) => {
          return (
            <p key={index} style={{ textTransform: "capitalize" }}>
              {item}
            </p>
          );
        }),
    },
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
      render: (Title: any) => <h3 className="printInvoiceHeadTitle">{Title}</h3>,
    },
  ];

  const detailsData = [
    {
      Qty: "",
      Description: "",
      Price: "",
      Extended: "",
    },
  ];

  const detailsColumns = [
    {
      title: "Qty",
      dataIndex: "Qty",
      key: "Qty",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "Price",
      width: "10%",
    },
    {
      title: "Extended",
      dataIndex: "Extended",
      key: "Extended",
      width: "10%",
    },
  ];

  // Participants Table config

  const participantsData = [
    {
      Date: dayjs(sessionDetails?.StartDateTime).format("ddd, MMMM DD , YYYY"),
      StartTime: dayjs(sessionDetails?.StartDateTime).format("hh:mm A"),
      EndTime: dayjs(sessionDetails?.EndDateTime).format("hh:mm A"),
      Location: sessionDetails?.PrimaryLocation,
    },
  ];

  const participantsColumns = [
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "Start Time",
      dataIndex: "StartTime",
      key: "StartTime",
    },
    {
      title: "End Time",
      dataIndex: "EndTime",
      key: "EndTime",
    },
    {
      title: authStore?.companySettings?.LocationDescriptorSingular
        ? authStore?.companySettings?.LocationDescriptorSingular
        : "Location",
      dataIndex: "Location",
      key: "Location",
    },
  ];

  // Payment Table config

  const paymentData = [
    {
      Date: `${dayjs(invoiceDetails?.PaymentDueDate).format("MM/DD/YYYY")}`,
      Method: "Visa",
      Amount: (invoiceDetails?.PaymentsTotal ? invoiceDetails?.PaymentsTotal : 0).toLocaleString(
        "en-US",
        {
          style: "currency",
          currency: "USD",
        }
      ),
    },
  ];

  const paymentColumns = [
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "Method",
      dataIndex: "Method",
      key: "Method",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
  ];

  const calculateAge = (date: any) => {
    const startDate = new Date(date);
    const currentDate = new Date();
    const months =
      currentDate.getMonth() -
      startDate.getMonth() +
      12 * (currentDate.getFullYear() - startDate.getFullYear());

    if (months > 252) {
      return "";
    }

    if (months >= 24) {
      return Math.round(months / 12) + (Math.round(months / 12) == 1 ? " Year" : " Years");
    }

    return months + (months == 1 ? " Month" : " Months");
  };

  return (
    <Row ref={ref} className="print-confirmation">
      <Table
        className="printInvoice__Head"
        dataSource={dataSourceHead}
        columns={columnsHead}
        pagination={false}
        showHeader={false}
      />

      <Row className="printInvoiceRow">
        <Title className="printInvoiceTitle" level={5}>
          Client Information
        </Title>
      </Row>
      <Row className="printInvoiceRow clientInfo">
        {/* <Table className="printInvoice__Client" dataSource={clientData} columns={clientColumns} pagination={false} showHeader={false} /> */}
        <Col span={8}>
          {billToClient ? (
            <Paragraph className="paragraph">
              <strong>Sold to: </strong>
            </Paragraph>
          ) : null}
          <Paragraph className="paragraph">
            {clientStore?.clientInfo?.FirstName + " " + clientStore?.clientInfo?.LastName}
          </Paragraph>
          <Paragraph className="paragraph">{clientStore?.clientInfo?.AddressLine1}</Paragraph>
          <Paragraph className="paragraph">
            {clientStore?.clientInfo?.AddressLine2} {clientStore?.clientInfo?.AddressCity}
          </Paragraph>
          <Paragraph className="paragraph">
            {clientStore?.clientInfo?.AddressState} {clientStore?.clientInfo?.AddressPostcode}
          </Paragraph>
          <Paragraph className="paragraph">{clientStore?.clientInfo?.AddressCountry}</Paragraph>
          <Paragraph className="paragraph">{clientStore?.clientInfo?.Email}</Paragraph>
          {billToClient ? (
            <>
              <Paragraph className="paragraph">
                Client No: <strong>{clientStore?.clientInfo?.ClientNumber}</strong>
              </Paragraph>
              <Paragraph className="paragraph">
                Main Phone: <strong>{clientStore?.clientInfo?.PhoneNumber0}</strong>
              </Paragraph>
            </>
          ) : null}
        </Col>
        <Col span={8}>
          {billToClient ? (
            <>
              <Paragraph className="paragraph">
                <strong>
                  {authStore?.companySettings.JobDescriptorSingular
                    ? authStore?.companySettings.JobDescriptorSingular
                    : "Session"}{" "}
                  for:{" "}
                </strong>
              </Paragraph>
              <Paragraph className="paragraph">{invoiceDetails?.ShippingAddressName}</Paragraph>
              <Paragraph className="paragraph">{invoiceDetails?.ShippingAddressLine1}</Paragraph>
              <Paragraph className="paragraph">{invoiceDetails?.ShippingAddressLine2}</Paragraph>
              <Paragraph className="paragraph">
                {invoiceDetails?.ShippingAddressCity} {invoiceDetails?.ShippingAddressState}
              </Paragraph>
              <Paragraph className="paragraph">
                {invoiceDetails?.ShippingAddressCountry} {invoiceDetails?.ShippingAddressPostalCode}
              </Paragraph>
              <Paragraph className="paragraph">
                Client No:{" "}
                <strong>
                  {clientStore?.getClientDataFromList(
                    invoiceDetails?.BillToClientKey,
                    "ClientNumber"
                  )}
                </strong>{" "}
              </Paragraph>
              <Paragraph className="paragraph">
                Main Phone:{" "}
                <strong>
                  {clientStore?.getClientDataFromList(
                    invoiceDetails?.BillToClientKey,
                    "PhoneNumber0"
                  )}
                </strong>
              </Paragraph>
            </>
          ) : null}
        </Col>
        <Col span={8} className="textRight">
          <Paragraph className="paragraph">
            <label>
              {authStore?.companySettings.JobDescriptorSingular
                ? authStore?.companySettings.JobDescriptorSingular
                : "Session Number"}
              :{" "}
            </label>
            <strong>{SessionStore?.currentSessionDetail?.SessionNumber}</strong>
          </Paragraph>
          {/* <Paragraph className="paragraph">
            <label>Session Type:</label> <strong></strong>
          </Paragraph> */}
          {invoiceDetails ? (
            <>
              <Paragraph className="paragraph">
                <label>{printWord} Number:</label> <strong>{invoiceDetails?.InvoiceNumber}</strong>
              </Paragraph>
              <Paragraph className="paragraph">
                <label>{printWord} Date:</label>{" "}
                <strong>{dayjs(invoiceDetails?.InvoiceDate).format("MM/DD/YYYY")}</strong>
              </Paragraph>
            </>
          ) : null}
          {!billToClient ? (
            <Paragraph className="paragraph">
              <label>Client No:</label> <strong>{clientStore?.clientInfo?.ClientNumber}</strong>
            </Paragraph>
          ) : null}
          {invoiceDetails ? (
            <>
              <Paragraph className="paragraph">
                <label>Sales Rep:</label> <strong>{invoiceDetails?.SalesRepKey}</strong>
              </Paragraph>
            </>
          ) : null}
          {!billToClient ? (
            <Paragraph className="paragraph">
              <label>Main Phone:</label> <strong>{clientStore?.clientInfo?.PhoneNumber0}</strong>
            </Paragraph>
          ) : null}
        </Col>
      </Row>
      <Row className="printInvoiceRow">
        <Title className="printInvoiceTitle" level={5}>
          {authStore?.companySettings.JobDescriptorSingular
            ? authStore?.companySettings.JobDescriptorSingular
            : "Session"}{" "}
          Participants Information
        </Title>
        <Col span={24}>
          <Row className="fullWidth">
            <Col span={7}>
              <Title level={5}>
                {authStore?.companySettings.JobDescriptorSingular
                  ? authStore?.companySettings.JobDescriptorSingular
                  : "Session"}{" "}
                Participants:{" "}
              </Title>
            </Col>
            <Col span={17}>
              {sessionDetails?.SessionParticipants?.map((item: any, index: any) => {
                return (
                  <Paragraph key={index} className="participant">
                    {item.FamilyMember.FirstName}, {calculateAge(item.FamilyMember.Birthdate)}
                  </Paragraph>
                );
              })}
            </Col>
          </Row>
        </Col>
        <Col>
          <Title level={5}></Title>
        </Col>
        <Table
          className="printInvoice__Details"
          dataSource={participantsData}
          columns={participantsColumns}
          pagination={false}
        />
      </Row>
      {sessionDetails.PrintNotes && (
        <Row className="printInvoiceRow">
          <Title className="printInvoiceTitle" level={5}>
            Notes
          </Title>
          <Col span={24}>
            <Row className="fullWidth">
              <Col>{sessionDetails.Notes}</Col>
            </Row>
          </Col>
        </Row>
      )}
      <Row className="printInvoiceRow">
        <Title className="printInvoiceTitle" level={5}>
          Details
        </Title>
        <Table
          className="printInvoice__Details"
          dataSource={detailsData}
          columns={detailsColumns}
          pagination={false}
        />
      </Row>

      <Row className="printInvoiceRow">
        {invoiceDetails ? (
          <Col span={13}>
            <Title className="printInvoiceTitle" level={5}>
              Payments
            </Title>

            <Table
              className="printInvoice__Details"
              dataSource={paymentData}
              columns={paymentColumns}
              pagination={false}
            />
          </Col>
        ) : (
          <Col span={13}></Col>
        )}
        <Col span={1}></Col>
        <Col span={10}>
          <Row>
            <Col span={12}>
              <strong>Sub Total</strong>
            </Col>
            <Col span={12} className="textRight">
              {(invoiceDetails?.Subtotal ? invoiceDetails?.Subtotal : 0).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </Col>
          </Row>
          {invoiceDetails && invoiceDetails?.DiscountAmount ? (
            <Row>
              <Col span={12}>
                <strong>Discount</strong>
              </Col>
              <Col span={12} className="textRight">
                {(invoiceDetails?.DiscountAmount
                  ? invoiceDetails?.DiscountAmount
                  : 0
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col span={12}>
              <strong>Sales Tax (6.875%)</strong>
            </Col>
            <Col span={12} className="textRight">
              {(invoiceDetails?.Tax1Amount ? invoiceDetails?.Tax1Amount : 0).toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: "USD",
                }
              )}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <strong>Shipping</strong>
            </Col>
            <Col span={12} className="textRight">
              {(invoiceDetails?.ShippingAmount ? invoiceDetails?.ShippingAmount : 0).toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: "USD",
                }
              )}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <strong>{printWord} Total</strong>
            </Col>
            <Col span={12} className="textRight">
              {(invoiceDetails?.Total ? invoiceDetails?.Total : 0).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <strong>Total Payments</strong>
            </Col>
            <Col span={12} className="textRight">
              {(invoiceDetails?.Total ? invoiceDetails?.Total : 0).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <strong>Balance Due</strong>
            </Col>
            <Col span={12} className="textRight">
              {(invoiceDetails?.OpenBalance ? invoiceDetails?.OpenBalance : 0).toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: "USD",
                }
              )}
            </Col>
          </Row>
          <br />
        </Col>
      </Row>
      <Row className="printInvoiceFooter">
        <Paragraph>{String(dayjs().format("ddd, MMMM DD , YYYY hh:mm A"))}</Paragraph>
      </Row>
    </Row>
  );
});

export default inject("authStore", "clientStore")(observer(SessionPrintConfirmation));
