import Selector from "components/selector/selector";
import React from "react";

const ImageOptionsSelector = (props: any) => {
  const {
    width = 200,
    onOptionUpdate = () => {},
    value = "",
    allOptions = [],
  } = props;

  const _onUpdate = (optionKey: string) => {
    if (optionKey) {
      const selectedOption = allOptions.find(
        (option: any) => option.Key === optionKey
      );
      onOptionUpdate(selectedOption);
    } else {
      onOptionUpdate(null);
    }
  };

  const formattedOptions = allOptions?.map((option: any) => ({
    value: option.Key,
    label: option.Description,
  }));

  if (formattedOptions?.length) {
    return (
      <>
        <div className="selectorWrapper">
          <p className="label">Option</p>
          <Selector
            width={width}
            placeholder="Select an option"
            options={formattedOptions}
            value={value}
            allowClear={true}
            onUpdate={_onUpdate}
          />
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default ImageOptionsSelector;
