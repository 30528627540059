const EventEmitter: any = {
  events: {},
  trigger: function (event: string, data: any) {
    if (!this.events[event]) return;
    this.events[event].forEach((callback: any) => callback(data));
  },
  listen: function (event: string, callback: any) {
    if (!this.events[event]) this.events[event] = [];
    this.events[event].push(callback);
  },
  cancelAll: function (event: string) {
    if (this.events[event]) this.events[event] = [];
  },
};

export default EventEmitter;
