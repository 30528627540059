import React from "react";
import { inject, observer } from "mobx-react";
import { Col, Grid, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { ClientStoreClass } from "stores/ClientStore";
import CustomHeader from "components/header/CustomHeader";

interface Props {
  clientStore?: ClientStoreClass;
}

const { useBreakpoint } = Grid;

const ActionsClients: React.FC<Props> = ({ clientStore }: Props): React.ReactElement => {
  const screens = useBreakpoint();

  const handleClick = () => {
    clientStore?.setSelectToggle(true);
    clientStore?.setActiveClientKey("");
  };

  return (
    <CustomHeader title="Client">
      <Col style={{ marginLeft: "auto" }}>
        <Button
          icon={<PlusOutlined style={{ color: screens.lg ? "" : "#5DAEFF" }} />}
          disabled={clientStore?.isLoading}
          type={screens.lg ? "primary" : "text"}
          className={screens.lg ? "btn-action" : "no-label"}
          shape="round"
          onClick={handleClick}
        >
          Create
        </Button>
      </Col>
    </CustomHeader>
  );
};

export default inject("clientStore")(observer(ActionsClients));
