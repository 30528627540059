import { Modal } from "antd";
import Image from "components/core/image/image";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Select from "react-dropdown-select";
import EventEmitter from "utils/event-emitter";
import "./image-folder-update-modal.scss";

const ImageFolderUpdateModal = (props: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState<any>(null);
  const [selectedFolders, setSelectedFolders] = useState<any>([]);
  const [folderOptions, setFolderOptions] = useState([]);

  const _registerEventListners = () => {
    EventEmitter.listen("modify-image-folders", (image: any) => {
      setIsModalVisible(true);
      setImage(image);
    });
  };

  const _filterFolders = (folders: any) => {
    // Check if hidden folder is available
    const hiddenFolderIndex = folders.findIndex(
      (f: any) => f.name === "Hidden"
    );
    if (hiddenFolderIndex > -1) {
      // Remove it from orginal array
      folders.splice(hiddenFolderIndex, 1);
    }
    // Check if favourite folder is available
    const favouriteFolderIndex = folders.findIndex(
      (f: any) => f.name === "Favorites"
    );
    if (favouriteFolderIndex > -1) {
      // Remove it from orginal array
      folders.splice(favouriteFolderIndex, 1);
    }
    return folders;
  };

  const _setFolderOptions = () => {
    if (props.galleryStore.sessionFolders) {
      const filteredFolders = _filterFolders(
        Object.assign([], props.galleryStore.sessionFolders)
      );
      const folderOptions = filteredFolders.map((folder: any) => ({
        name: folder.name,
        value: folder.name,
        isExisting: true,
      }));
      setFolderOptions(folderOptions);
    }
  };

  const _prepareFolderOptions = async () => {
    _setFolderOptions();
  };

  const _getExistingFolders = () => {
    let existingFolders: any = [];
    if (image.folders && image.folders.length) {
      image.folders.forEach((folder: any) => {
        if (folder !== "Hidden" && folder !== "Favorites") {
          existingFolders.push(folder);
        }
      });
    }
    return existingFolders;
  };

  const _updateSelectedFolder = () => {
    const existingFolders = _getExistingFolders();
    if (existingFolders?.length) {
      const formattedFolders = existingFolders.map((folderName: string) => ({
        name: folderName,
        value: folderName,
      }));
      setSelectedFolders(formattedFolders);
    } else {
      setSelectedFolders([]);
    }
  };

  const _determineFoldersToAdd = (
    existingFolderList: any,
    currentFolderList: any
  ) => {
    const foldersToAdd: any = [];
    currentFolderList?.forEach((currentFolder: string) => {
      const isOldFolder = existingFolderList?.find(
        (existingFolder: string) => existingFolder === currentFolder
      );
      if (!isOldFolder) {
        // New folder
        foldersToAdd.push(currentFolder);
      }
    });
    return foldersToAdd;
  };

  const _determineFoldersToRemove = (
    existingFolderList: any,
    currentFolderList: any
  ) => {
    const foldersToRemove: any = [];
    existingFolderList?.forEach((existingFolder: string) => {
      const isDeletedFolder = !currentFolderList?.find(
        (currentFolder: string) => currentFolder === existingFolder
      );
      if (isDeletedFolder) {
        // New folder
        foldersToRemove.push(existingFolder);
      }
    });
    return foldersToRemove;
  };

  const _updateFolders = async () => {
    setIsLoading(true);
    const currentFolderList = selectedFolders?.map(
      (folder: any) => folder.name
    );
    const existingFolderList = _getExistingFolders();
    console.log("currentFolderList :>> ", currentFolderList);
    console.log("existingFolderList :>> ", existingFolderList);
    const foldersToAdd = _determineFoldersToAdd(
      existingFolderList,
      currentFolderList
    );
    const foldersToRemove = _determineFoldersToRemove(
      existingFolderList,
      currentFolderList
    );
    console.log("foldersToAdd :>> ", foldersToAdd);
    console.log("foldersToRemove :>> ", foldersToRemove);
    const folderModificationApiCalls: any = [];
    if (foldersToRemove.length) {
      // Existing folder exisits
      // So remove the image from it
      foldersToRemove.forEach((folder: string) => {
        folderModificationApiCalls.push(
          props.galleryStore.removeImageFromFolder(folder, image.fileName)
        );
      });
    }
    if (foldersToAdd.length) {
      // Existing folder exisits
      // So remove the image from it
      foldersToAdd.forEach((folder: string) => {
        folderModificationApiCalls.push(
          props.galleryStore.addImageToFolder(folder, image.fileName)
        );
      });
    }
    await Promise.all(folderModificationApiCalls);
    setIsLoading(false);
    setIsModalVisible(false);
  };

  useEffect(() => {
    _registerEventListners();
  }, []);

  useEffect(() => {
    if (isModalVisible) {
      _prepareFolderOptions();
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (image) {
      _updateSelectedFolder();
    } else {
      setSelectedFolders([]);
    }
  }, [image]);

  return (
    <>
      <Modal
        wrapClassName="folderManagerModalWrapper"
        title="Manage Image Folder"
        centered
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        width={800}
      >
        <div className="folderModalContentWrapper">
          {image ? (
            <>
              <div className="imageViewer">
                <Image
                  src={image.thumbnailPath}
                  ratio={
                    image.unformattedData.Height / image.unformattedData.Width
                  }
                />
              </div>
              <div className="folderManage">
                <h3>Select Folder</h3>
                <div className="folderSelector">
                  <Select
                    multi={true}
                    create={true}
                    closeOnSelect={true}
                    values={selectedFolders || []}
                    options={folderOptions}
                    labelField={"name"}
                    valueField={"value"}
                    onChange={(values) => {
                      if (values && values.length) {
                        setSelectedFolders(values);
                      } else {
                        setSelectedFolders(null);
                      }
                    }}
                    onCreateNew={(value: any) => {
                      setSelectedFolders([
                        ...selectedFolders,
                        {
                          ...value,
                        },
                      ]);
                    }}
                  />
                </div>
                {isLoading ? (
                  <div className="folderUpdateBtnLoader">Updating</div>
                ) : (
                  <button className="folderUpdateBtn" onClick={_updateFolders}>
                    Update
                  </button>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </Modal>
    </>
  );
};

export default inject("galleryStore")(observer(ImageFolderUpdateModal));
