import React from "react";
import { inject, observer } from "mobx-react";

import { ClientStoreClass } from "stores/ClientStore";
import { SessionStoreClass } from "stores/SessionStore";

import SessionsList from "components/lists/SessionsList";

interface Props {
  clientStore: ClientStoreClass;
  sessionStore: SessionStoreClass;
  setToggleActionItems?: any;
  viewSection?: any;
}

const QuickAccessSessionsList: React.FC<Props> = ({
  sessionStore,
  clientStore,
  setToggleActionItems,
  viewSection,
}) => {
  // let handleClick = () => {
  //   // clientStore?.setActiveClientNumber(item.ClientNumber || "");
  //   // clientStore?.setActiveClientKey(item.Key);
  //   // await clientStore?.getClient(item.Key);
  //   sessionStore?.getSessionDetail(item.Key);
  //   sessionStore?.setSelectToggle(true);
  //   scheduleStore?.setActiveSchedule({ SessionKey: item.Key });
  //   setToggleActionItems(true);
  // }

  // useEffect(() => {
  //   sessionStore?.getSessionList(clientStore?.activeClientKey);
  // }, [clientStore?.activeClientKey]);

  return (
    <div className="sessions-list">
      <SessionsList
        viewSection={viewSection}
        customHandleClick={true}
        setToggleActionItems={setToggleActionItems} 
      />
    </div>
  );
};

export default inject("clientStore", "sessionStore")(observer(QuickAccessSessionsList));
