import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "mobx-react";
import AuthStore from "./stores/AuthStore";
import ClientStore from "./stores/ClientStore";
import TaskStore from "./stores/TaskStore";
import UserStore from "./stores/UserStore";
import ScheduleStore from "./stores/ScheduleStore";
import SessionStore from "./stores/SessionStore";
import AppointmentStore from "./stores/AppointmentStore";
import ClockStore from "./stores/ClockStore";
import NotificationStore from "./stores/NotificationStore";
import MessageStore from "./stores/MessageStore";
import PhoneCallStore from "./stores/PhoneCallStore";
import InvoiceStore from "./stores/InvoiceStore";
import AlertStore from "./stores/AlertStore";
import * as serviceWorker from "./serviceWorker";
import RouteMetadataStore from "stores/RouteMetadataStore";
import GalleryStore from "stores/GalleryStore/GalleryStore";
import CartStore from "stores/CartStore/CartStore";
import ReportStore from "stores/ReportStore";
import ProductionOrderStore from "stores/ProductionOrderStore";
import PersonalDashboardStore from "stores/PersonalDashboardStore";
ReactDOM.render(
  <Provider
    authStore={AuthStore}
    clientStore={ClientStore}
    taskStore={TaskStore}
    scheduleStore={ScheduleStore}
    userStore={UserStore}
    sessionStore={SessionStore}
    messageStore={MessageStore}
    appointmentStore={AppointmentStore}
    clockStore={ClockStore}
    notificationStore={NotificationStore}
    phoneCallStore={PhoneCallStore}
    invoiceStore={InvoiceStore}
    routeMetadataStore={RouteMetadataStore}
    galleryStore={GalleryStore}
    cartStore={CartStore}
    alertStore={AlertStore}
    reportStore={ReportStore}
    productionOrderStore={ProductionOrderStore}
    personalDashboardStore={PersonalDashboardStore}
  >
    <App />
  </Provider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
