import React from "react";
import { inject, observer } from "mobx-react";
import { Button, List, message, Typography } from "antd";
import type { ListItemProps } from "antd/lib/list/Item";
import dayjs from "dayjs";

import type { InvoiceList } from "interfaces/invoice.int";
import type { InvoiceStoreClass } from "stores/InvoiceStore";
import { saveLocalStorage } from "components/util/localStorage";

interface Props extends ListItemProps {
  item: InvoiceList;
  invoiceStore?: InvoiceStoreClass;
  setToggleInvoiceActionItems?: any;
  setCurrentTab?: any;
  currentTab?: string;
  openInvoiceDetails?: boolean;
  setNewInvoice?: any;
}
const { Text } = Typography;
const RenderInvoice: React.FC<Props> = ({
  item,
  invoiceStore,
  setToggleInvoiceActionItems,
  setCurrentTab,
  currentTab,
  openInvoiceDetails,
  setNewInvoice,
  ...props
}) => {
  const { InvoiceKey, OpenBalance, FirstName, LastName, Description } = item;
  const handleClick = async (e: any, drawerType?: "detail" | "payment") => {
    e.stopPropagation();
    if (setNewInvoice) setNewInvoice(false);
    if (
      !InvoiceKey ||
      InvoiceKey === "" ||
      (typeof OpenBalance !== "undefined" && OpenBalance <= 0 && drawerType === "payment")
    )
      return;
    try {
      await invoiceStore?.getInvoiceDetails(InvoiceKey);
      if (drawerType) {
        invoiceStore?.setDrawerType(drawerType);
        invoiceStore?.setSelectToggle(true);
      } else {
        setToggleInvoiceActionItems(true);
        if (currentTab !== "Invoices") setCurrentTab("Invoices");
      }
      // saveLocalStorage({
      //   name: "Viewed",
      //   value: `Invoice - ${FirstName + " " + LastName} ${Description ? " - " + Description : ""}`,
      //   type: {
      //     key: InvoiceKey,
      //     value: "Invoice",
      //   },
      // });
    } catch (e) {
      message.error("Sorry, something has gone wrong. Please try again later.");
    }
  };

  return (
    <List.Item
      className="invoiceList"
      onClick={(e: any) => {
        openInvoiceDetails ? handleClick(e, "detail") : handleClick(e);
      }}
      {...props}
    >
      <div className="quickAccessList">
        <div className="quickAccessList__Date">
          <label>
            {/* <span className="quickAccessList__Date--Icon"></span> */}
            <span className="quickAccessList__Date--Day">
              {`${dayjs(item.InvoiceDate).format("DD")}`}
            </span>
            <span className="quickAccessList__Date--Year">{`${dayjs(item.InvoiceDate).format(
              "MMM YYYY"
            )}`}</span>
          </label>
        </div>
        <div className="quickAccessList__Content">
          <div className="quickAccessList__Content--Head">
            <Text className="sessionStatus" mark>
              {item.InvoiceType}
            </Text>
            <div>
              <label>
                <span>Open Balance:</span>
                <Text strong>
                  {`${item.OpenBalance?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`}
                </Text>
              </label>
              <label>
                <span>Total:</span>
                <Text strong>
                  {`${item.Total?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}`}
                </Text>
              </label>
            </div>
          </div>
          <div className="quickAccessList__Content--Body">
            <div>
              <p>Invoice Number: {item.InvoiceNumber}</p>
              <label>Payment Due on {`${dayjs(item.DueDate).format("MMM DD YYYY")}`}</label>
            </div>
            <div className="quickAccessList__Content--Body__Action">
              {item.OpenBalance > 0 && (
                <Button
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleClick(e, "payment");
                  }}
                  className="ant-btn-primary"
                  style={{ backgroundColor: "#92b0b6", borderColor: "#92b0b6" }}
                  disabled={!item.OpenBalance}
                >
                  Pay
                </Button>
              )}
              <Button
                onClick={(e: any) => {
                  e.stopPropagation();
                  handleClick(e, "detail");
                }}
                className="ant-btn-secondary"
                style={{ color: "#92b0b6", borderColor: "#92b0b6" }}
              >
                View Details
              </Button>
            </div>
          </div>
        </div>
      </div>
    </List.Item>
  );
};

export default inject("invoiceStore")(observer(RenderInvoice));
