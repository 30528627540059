import React from "react";
import { inject, observer } from "mobx-react";
import { message } from "antd";
import { AlertStoreClass } from "stores/AlertStore";

interface Props {
  alertStore?: AlertStoreClass;
}

const Alert: React.FC<Props> = ({ alertStore }) => {
  if (alertStore?.alertMessage) {
    if (alertStore?.alertType === "error") {
      message.error(alertStore.alertMessage);
    } else if (alertStore?.alertType === "success") {
      message.success(alertStore.alertMessage);
    } else if (alertStore?.alertType === "info") {
      message.info(alertStore.alertMessage);
    }
  }
  return null;
};

export default inject("alertStore")(observer(Alert));
