import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import moment from "moment";
import { inject, observer } from "mobx-react";
import { useIdleTimer } from 'react-idle-timer'
import { message } from "antd";
import { useHistory } from "react-router";

const SessionTimeout: React.FC<any> = ({ authStore }) => {
  const [events] = useState(["click", "load", "scroll"]);
  // const [second, setSecond] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();

  let warningInactiveInterval = useRef<any>(null);
  let startTimerInterval = useRef<any>(null);

  const handleOnIdle = () => {
    clearTimeout(startTimerInterval.current);
    // console.log('user is idle');
    // console.log('last active', getLastActiveTime());
    getLastActiveTime();
    if (authStore?.isAuthenticated) {
      message.warning({ content: "Your current session is going to expire in 1 minute. Press any key or anywhere to stay and continue", duration: 0, key: 1001 });
      startTimerInterval.current = setTimeout(() => {
        message.destroy();

        authStore.logout();
        message.info({ content: "Due to inactivity, your current session has ended", duration: 0, key: 1002 });

      }, 60000);
    } else {
      history.push('/home');
    }
  }

  const handleOnActive = () => {
    // console.log('user is active');
    // console.log('time remaining', getRemainingTime())
    message.destroy(1001);
    message.destroy(1002);
    getRemainingTime();
  }

  const handleOnAction = () => {
    // console.log('user did something');
    clearTimeout(startTimerInterval.current);
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  });


  // Initialize the timer, start inactive check
  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem("lastActivityTimeStamp");
      // console.log("Trigerring warning timer");
      warningInactive(storedTimeStamp);
    }, 60000);
  };

  // warning timer
  let warningInactive = (timeString: any) => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      const maxTime = 60;
      const popTime = 1;

      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.minutes();

      if (minPast > maxTime || isNaN(minPast)) resetTimer();

      if (minPast === popTime) {
        // setSecond(leftSecond); This isn't needed as the second variable is unused.
        setOpen(true);
      }

      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        setOpen(false);
        sessionStorage.removeItem("lastActivityTimeStamp");
        authStore.logout();
      }
    }, 1000);
  };

  // reset interval timer
  let resetTimer = useCallback(() => {
    // console.log("Resetting timer.");
    let timeStamp;

    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    if (authStore.isAuthenticated) {
      timeStamp = moment();
      sessionStorage.setItem("lastActivityTimeStamp", timeStamp.format());
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem("lastActivityTimeStamp");
    }
    timeChecker();
    setOpen(false);
  }, [authStore.isAuthenticated]);

  // // handle close popup
  // const handleClose = () => {
  //   setOpen(false);

  //   resetTimer();
  // };

  useEffect(() => {
    // if (authStore.isAuthenticated) {
    // events.forEach((event) => {
    //   window.addEventListener(event, resetTimer);
    // });
    // }

    // timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
    };
  }, [resetTimer, events, timeChecker]);

  // console.log(second);

  if (!isOpen) {
    return null;
  }
  return <Fragment />;
};

// export default SessionTimeout;
export default inject("authStore")(observer(SessionTimeout));
