import React from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Image, Button } from "antd";
import { MenuOutlined, LeftOutlined } from "@ant-design/icons";
import classnames from "classnames";

import type { AuthStoreClass } from "stores/AuthStore";
import { RouteMetadataStoreClass } from "stores/RouteMetadataStore";

import "./Branding.scss";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

interface Props {
  src?: string;
  height?: number;
  onHeader?: boolean;
  authStore?: AuthStoreClass;
  routeMetadataStore?: RouteMetadataStoreClass;
  showLogo?: boolean;
}

const Branding: React.FC<Props> = ({
  src,
  height = 50,
  onHeader,
  authStore,
  routeMetadataStore,
  showLogo,
}: Props) => {
  const screens = useBreakpoint();
  const handleToggleSide = () => {
    authStore?.setIsNavToggle();
  };

  return (
    <Row
      align="middle"
      className={classnames("nav-branding", {
        isOnHeader: onHeader,
      })}
    >
      <Col>
        {onHeader ? (
          <Button
            className="main-navigation-toggle"
            icon={<MenuOutlined style={{ fontSize: 22 }} />}
            type="text"
            onClick={handleToggleSide}
          />
        ) : (
          <Button
            shape="circle"
            className="main-navigation-close"
            icon={<LeftOutlined />}
            onClick={handleToggleSide}
          />
        )}
      </Col>
      {src && (
        <Col className="sidebar-logo">
          {!screens.xs || showLogo ? (
            <Image src={src} height={height} preview={false} />
          ) : (
            <h1 className="brandingText">
              {routeMetadataStore?.currentRouteMetadata?.title}
            </h1>
          )}
        </Col>
      )}
    </Row>
  );
};

export default inject("authStore", "routeMetadataStore")(observer(Branding));
