import {
  action,
  observable,
  runInAction,
  toJS,
  makeAutoObservable,
} from "mobx";
import SessionStore from "stores/SessionStore";
import {
  getPriceList,
  getColorization,
  getCart,
  addItemToCart,
  updateCartItem,
  removeCartItem,
  addPromoCodeAPI,
  deletePromoCodeAPI,
  fetchInvoiceKey,
} from "./Api";
import InvoiceStore from "stores/InvoiceStore";

export class CartStoreClass {
  categories: any = [];
  colorizationOptions: any = [];
  isLoading: boolean = false;
  cart: any = {};
  sessionKey: string = "";
  invoiceKey: string = "";
  successfulOrder: boolean = false;

  constructor() {
    makeAutoObservable(this, {
      sessionKey: observable,
      invoiceKey: observable,
      categories: observable,
      colorizationOptions: observable,
      cart: observable,
      successfulOrder: observable,
      fetchCartMetaData: action,
      fetchCart: action,
      updateItem: action,
      removeItem: action,
      addItem: action,
      getItemByKey: action,
      removePromoCode: action,
      addPromoCode: action,
      _resetStore: action,
    });
  }

  /*******    Public actions (Starts here)    ******/
  setSelectedSessionKey(sessionId: string) {
    this._resetStore();
    this.sessionKey = sessionId;
  }

  async fetchCartMetaData(priceListKey: string = "") {
    const responses: any[] = await Promise.all([
      getPriceList(priceListKey),
      getColorization(),
    ]);
    runInAction(() => {
      this.categories = responses[0]?.Groups;
      this.colorizationOptions = responses[1];
    });
  }

  async fetchCart() {
    try {
      // First check if invoice key is available
      if (!this.invoiceKey?.length) {
        // If not, fetch first
        const invoiceResponse = await fetchInvoiceKey(this.sessionKey);
        if (invoiceResponse?.length) {
          this.invoiceKey = invoiceResponse[0].InvoiceKey;
        } else {
          // Create a cart
          const newInvoice: any = await InvoiceStore.addInvoice({ ClientKey: SessionStore.currentSessionDetail.ClientKey, SessionKey:  this.sessionKey})
          this.invoiceKey = newInvoice.Key;
        }
      } 

      if (this.invoiceKey) {
        const cartResponse = await getCart(this.sessionKey, this.cart?.Key || this.invoiceKey, SessionStore.currentSessionDetail.ClientKey);
        runInAction(() => {
          this.cart = cartResponse;
        });
      }
    } catch (error) {}
  }

  async updateItem(item: any) {

    // Update on server
    const invoicePayload: any = {
      Key: item.Key,
      ItemKey: item.ItemKey,
      Quantity: item.Quantity,
      FileName: item.FileName?.length ? item.FileName : "",
      EnhancementKey: item.EnhancementKey,
      EnhancementDescription: item.EnhancementDescription,
      OptionKey: item.OptionKey,
      OptionDescription: item.OptionDescription,
      ColorizationKey: item.ColorizationKey,
      Notes: item.Notes, 
    };
    // Check optional properties
    const optionalProperties = [
      "CropRotated",
      "CropLeft",
      "CropWidth",
      "CropTop",
      "CropHeight",
    ];
    optionalProperties.forEach((optionalProperty: string) => {
      if (item.hasOwnProperty(optionalProperty)) {
        invoicePayload[optionalProperty] = item[optionalProperty];
      }
    });
    this.isLoading = true;
    this.cart = await addItemToCart(
      invoicePayload,
      this.sessionKey,
      this.cart.Key,
      SessionStore.currentSessionDetail.ClientKey,
    );
    this.successfulOrder = false;
    this.isLoading = false;
  }

  async addItem(item: any) {
    // Add to server
    this.isLoading = true;
    console.log(this.cart);
    this.cart = await updateCartItem(item, this.sessionKey, this.cart?.key || "", SessionStore.currentSessionDetail.ClientKey);
    this.isLoading = false;
  }

  async removeItem(item: any) {
    // Remove locally first
    // Find item index
    const itemIndex = this.cart.InvoiceDetails.findIndex(
      (i: any) => i.Key === item.Key
    );
    this.isLoading = true;
    this.cart = await removeCartItem(
      this.cart.InvoiceDetails[itemIndex].Key,
      this.invoiceKey
    );
    this.isLoading = false;
  }

  async addPromoCode(code: string) {
    this.isLoading = true;
    try {
      const promoCodeResponse = await addPromoCodeAPI(
        code,
        this.sessionKey,
        this.invoiceKey
      );
      console.log("promoCodeResponse :>> ", promoCodeResponse);
      await this.fetchCart();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error();
    }
  }

  async removePromoCode(code: string) {
    this.isLoading = true;
    try {
      const promoCodeResponse = await deletePromoCodeAPI(
        code,
        this.sessionKey,
        this.invoiceKey
      );
      await this.fetchCart();
      console.log("promoCodeResponse :>> ", promoCodeResponse);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      // throw new Error(error);
    }
  }

  getItemByKey(itemKey?: string) {
    return this.categories?.map((item: any) => item.Items)
      ?.flat(1)
      ?.find((item: any) => item?.Key === itemKey);
  }
  /*******    Public actions (Ends here)     ******/

  _resetStore() {
    this.cart = {};
    this.invoiceKey = "";
    this.categories = [];
    this.colorizationOptions = [];
    this.successfulOrder = false;
  }
}

const CartStore = new CartStoreClass();
export default CartStore;
