import React, { useState, useEffect } from "react";
import { List, Grid, Avatar, Select, Skeleton } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { inject, observer } from "mobx-react";
import { FixedSizeList as VList } from "react-window";

import ClientsWideView from "./ClientsWideView";
import ClientTabs from "components/tabs/ClientTabs";
import { ClientStoreClass } from "stores/ClientStore";
import { ClientList } from "interfaces/client.int";
import { Option } from "antd/lib/mentions";
const { useBreakpoint } = Grid;

interface Props {
  clientStore?: ClientStoreClass;
}

const ClientsHubSearchPage: React.FC<Props> = ({ clientStore }) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const screens = useBreakpoint();
  let timeout: ReturnType<typeof setTimeout>;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      clientStore?.clearLists();
    };
  }, []);

  const handleClick = (item: ClientList) => {
    clientStore?.setActiveClientNumber(item.ClientNumber || "");
    clientStore?.setActiveClientKey(item.Key);
    clientStore?.setSelectToggle(true);
  };

  const renderRow = (props: any) => {
    const client: ClientList = clientStore!.sortedClientsList[props.index];

    return (
      <Skeleton loading={clientStore?.isLoading}>
        <List.Item
          key={props.key}
          style={{ ...props.style, cursor: "pointer" }}
          onClick={() => handleClick(client)}
          className="has-action"
        >
          <List.Item.Meta
            avatar={<Avatar size="large" icon={<UserOutlined />} />}
            title={`${client?.LastName ? `${client?.LastName}, ` : ""}${client?.FirstName}`}
            description={client?.Email}
            // className="list-meta-float"
          />
        </List.Item>
      </Skeleton>
    );
  };

  const selectAfter = (
    <Select
      onChange={(value) => {
        clientStore?.setSearchBy({
          value: value,
          children: value.charAt(0).toUpperCase() + value.slice(1),
        });
      }}
      defaultValue={clientStore?.searchBy}
    >
      <Option value="firstName">First Name</Option>
      <Option value="lastName">Last Name</Option>
      <Option value="email">Email</Option>
      <Option value="companyName">Company Name</Option>
    </Select>
  );

  return (
    <>
      <Skeleton active loading={clientStore?.isLoading}>
        {screens.xs ? (
          <List bordered style={{ border: 0 }} dataSource={clientStore?.sortedClientsList}>
            <VList
              height={window.innerHeight}
              width={"100%"}
              itemCount={clientStore?.sortedClientsList.length || 0}
              itemSize={75}
              overscanCount={100}
            >
              {renderRow}
            </VList>
          </List>
        ) : (
          <ClientsWideView />
        )}
      </Skeleton>
      <ClientTabs />
    </>
  );
};

export default inject(
  "clientStore",
  "invoiceStore",
  "taskStore",
  "appointmentStore"
)(observer(ClientsHubSearchPage));
