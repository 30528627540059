import React from "react";
import dayjs from "dayjs";
import { inject, observer } from "mobx-react";
import { Space, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import type { ScheduleStoreClass } from "stores/ScheduleStore";

type Type = "prev" | "next";

interface Props {
  calendar?: "day" | "month" | "week" | "year";
  onClick?: (type: Type) => void;
  scheduleStore?: ScheduleStoreClass;
}

const CalendarNavigation: React.FC<Props> = ({
  calendar,
  onClick,
  scheduleStore,
}: Props): React.ReactElement => {
  const handlePaginate = (type: Type) => {
    let direction = type === "prev" ? -1 : 1;
    if (calendar === "month") {
      scheduleStore?.setCalendarActiveSlideMonthIndex(0, type);
      scheduleStore?.setCalendarActiveDate(
        scheduleStore?.calendarActiveDate.add(direction, "month")
      );
    } else if (calendar === "week") {
      scheduleStore?.setCalendarActiveSlideWeekIndex(0, type);
      scheduleStore?.setCalendarActiveDate(
        scheduleStore?.calendarActiveDate.add(direction, "week")
      );
    } else if (calendar === "day") {
      scheduleStore?.setCalendarActiveSlideDayIndex(0, type);
    } else if (calendar === "year") {
      scheduleStore?.setCalendarActiveSlideYearIndex(0, type);
      scheduleStore?.setCalendarActiveDate(
        scheduleStore?.calendarActiveDate.add(direction, "year")
      );
    } else {
      onClick && onClick(type);
    }
  };

  return (
    <Space>
      <Button
        icon={<LeftOutlined style={{ fontSize: 18 }} />}
        type="text"
        className="no-label"
        onClick={() => handlePaginate("prev")}
        style={{
          marginLeft: 0,
        }}
      />
      <Button
        icon={<RightOutlined style={{ fontSize: 18 }} />}
        type="text"
        className="no-label"
        onClick={() => handlePaginate("next")}
        style={{
          marginLeft: 0,
        }}
      />
    </Space>
  );
};

export default inject("scheduleStore")(observer(CalendarNavigation));
