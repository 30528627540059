import React from "react";

import { Drawer, DrawerProps } from "antd";
import CalendarDesktop from "components/schedule/CalendarDesktop";
import useWindowDimension from "components/util/Dimension";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import ActionsSchedule from "components/schedule/actions/ActionsSchedule";

interface Props {
  openCalenderForAppointment?: any;
  setOpenCalenderForAppointment?: any;
}

const QuickAccessAddEditAppointment: React.FC<Props> = ({
  openCalenderForAppointment,
  setOpenCalenderForAppointment,
}) => {
  const screens = useBreakpoint();
  const { innerWidth } = useWindowDimension();

  const drawerParams: DrawerProps = {
    width: screens.xs ? innerWidth : innerWidth - 250,
    placement: "right",
    closable: true,
    forceRender: false,
    destroyOnClose: true,
    title: <ActionsSchedule drawer={true} />,
    maskStyle: {},
    className: "qa-calender",
    push: {
      distance: 20,
    },
  };

  return (
    <>
      <Drawer
        placement="right"
        onClose={() => {
          setOpenCalenderForAppointment(false);
        }}
        visible={openCalenderForAppointment}
        {...drawerParams}
      >
        <CalendarDesktop openCalenderForAppointment={openCalenderForAppointment} />
      </Drawer>
    </>
  );
};

export default QuickAccessAddEditAppointment;
