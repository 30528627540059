import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import {
  Card,
  Grid,
  Row,
  Col,
  Typography,
  Button,
  Select,
  Form,
  Drawer,
  Input,
  message,
  Spin,
  Modal,
} from "antd";
import type { DrawerProps } from "antd/lib/drawer";
import { DownOutlined } from "@ant-design/icons";

import type { AuthStoreClass } from "stores/AuthStore";
import Checkbox from "antd/lib/checkbox";
import InputField from "components/forms/items/InputField";
import SelectField from "components/forms/items/SelectField";
import DateField from "components/forms/items/DateField";
import { ClientStoreClass } from "stores/ClientStore";
import { AppointmentStoreClass } from "stores/AppointmentStore";
import { UserStoreClass } from "stores/UserStore";
import { SessionStoreClass } from "stores/SessionStore";
import { Report, ReportObject } from "interfaces/report.int";
import dayjs from "dayjs";
import useWindowDimension from "components/util/Dimension";
import { PhoneCallStoreClass } from "stores/PhoneCallStore";
import { TaskStoreClass } from "stores/TaskStore";
import { MessageStoreClass } from "stores/MessageStore";
import { InvoiceStoreClass } from "stores/InvoiceStore";
import { ProductionOrderStoreClass } from "stores/ProductionOrderStore";
import { PersonalDashboardStoreClass } from "stores/PersonalDashboardStore";
import ViewPersonalReportDrawerView from "./ViewPersonalReportDrawerView";

const { Title } = Typography;

const { useBreakpoint } = Grid;

interface Props {
  onToggle?: (x: boolean) => void;
  authStore?: AuthStoreClass;
  personalDashboardStore?: PersonalDashboardStoreClass;
  clientStore?: ClientStoreClass;
  appointmentStore?: AppointmentStoreClass;
  userStore?: UserStoreClass;
  sessionStore?: SessionStoreClass;
  phoneCallStore?: PhoneCallStoreClass;
  taskStore?: TaskStoreClass;
  messageStore?: MessageStoreClass;
  invoiceStore?: InvoiceStoreClass;
  productionOrderStore?: ProductionOrderStoreClass;
  editChartValues?: any;
  setEditChartValues?: any;
  setIsDashboardUpdated?: any;
}

const PersonalDashboardPropertiesDrawerView: React.FC<Props> = ({
  onToggle,
  authStore,
  personalDashboardStore,
  clientStore,
  appointmentStore,
  userStore,
  sessionStore,
  phoneCallStore,
  taskStore,
  messageStore,
  invoiceStore,
  productionOrderStore,
  editChartValues,
  setEditChartValues,
  setIsDashboardUpdated,
}) => {
  const screens = useBreakpoint();
  const isLoading: boolean = false;
  const [form] = Form.useForm();
  const [criteriaValue, setCriteriaValue] = useState<any>([]);
  const { innerWidth } = useWindowDimension();
  const [selectedSavedReport, setSelectedReport] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [formLoading, setFormLoading] = useState(false);
  const [showChartColor2, setShowChartColor2] = useState(false);
  const [showApplyFilter, setShowApplyFilter] = useState(false);
  const [hideDateField, setHideDateField] = useState(false);
  const [alsoApplyCheck, setAlsoApplyCheck] = useState(false);
  const [showAlsoApplyCheck, setShowAlsoApplyCheck] = useState(true);
  const [showViewConfirmModal, setShowViewConfirmModal] = useState(false);
  const [showOverRide, setShowOverRide] = useState(false);
  const [selectedReportKey, setSelectedReportKey] = useState("");
  const [isCreateReport, setIsCreateReport] = useState(false);
  const [isAddtoDashboard, setIsAddtoDashboard] = useState(false);
  const [dataTypeValue, setDataTypeValue] = useState("Appointments All");
  const [dateRangeValue, setDateRangeValue] = useState("For Today");
  const [groupByValue, setGroupByValue] = useState("");
  const [studioSelectOption, setStudioSelectOption] = useState("2");
  const studioSelectionOptions = [
    {
      label: "Single Location",
      value: "0",
    },
    {
      label: "All Locations",
      value: "2",
    },
  ];

  const handleApplyFilterCheck = (e: any) => {
    if (e.target.checked) {
      setAlsoApplyCheck(true);
      setShowApplyFilter(true);
      setHideDateField(true);
    } else {
      setHideDateField(false);
      setAlsoApplyCheck(false);
      setShowApplyFilter(false);
      valueChangeHandler({ apply_filter: "" });
    }
  };

  useEffect(() => {
    if (activeTab === "1") {
      personalDashboardStore?.resetActiveSavedReport();
    }
  }, [activeTab]);

  const generateEditReportHandle = async (Key: string) => {
    setSelectedReport(Key);
    await personalDashboardStore?.getDashboardReport(Key);
    setFormLoading(false);
  };

  useEffect(() => {
    if (Object.keys(editChartValues).length && personalDashboardStore?.isSelectToggle) {
      setFormLoading(true);
      setActiveTab("2");
      generateEditReportHandle(editChartValues.Key);
    }
  }, [editChartValues, personalDashboardStore?.isSelectToggle]);

  useEffect(() => {
    personalDashboardStore?.getDashboardReports();
    personalDashboardStore?.getAllPrefereneces();
    clientStore?.getOrganization();
    clientStore?.getLeadSource();
    clientStore?.getClientGroups();
    clientStore?.getClientReferralPrograms();
    appointmentStore?.getAppointmentTypes();
    appointmentStore?.getAppointmentStatuses();
    personalDashboardStore?.resetActiveSavedReport();
    personalDashboardStore?.getFilterList();
    sessionStore?.getSessionPromotion();
    phoneCallStore?.getPhoneCallTypes();
    phoneCallStore?.getPhoneCallOutcomes();
    phoneCallStore?.getPhoneCallResults();
    taskStore?.getTaskTypes();
    taskStore?.getTaskStatuses();
    messageStore?.getEmailCategories();
    messageStore?.getEmailStatusList();
    messageStore?.getEmailTypes();
    invoiceStore?.getInvoiceProductLineList();
    productionOrderStore?.getProductionOrderStatusList();
    productionOrderStore?.getProductionOrderTypeList();
    productionOrderStore?.getReOrderReasonList();
    productionOrderStore?.getVendorList();
  }, []);

  const dates = (dateRange: string) => {
    let FromDate = dayjs();
    let ToDate = dayjs();

    switch (dateRange) {
      case "Today":
        FromDate = dayjs();
        ToDate = dayjs();

        break;
      case "ThisWeek":
        {
          let dateStart = dayjs();
          let prefsDay = authStore?.companySettings?.FirstDayOfWeek;

          while (dateStart.day() !== (prefsDay === 7 ? 0 : prefsDay)) {
            dateStart = dateStart.add(-1, "day");
          }
          let dayTo = dateStart.add(6, "day");
          FromDate = dateStart;
          ToDate = dayTo;
        }
        break;
      case "ThisWeekToDate":
        {
          let dateStart = dayjs();
          let prefsDay = authStore?.companySettings?.FirstDayOfWeek;

          while (dateStart.day() !== (prefsDay === 7 ? 0 : prefsDay)) {
            dateStart = dateStart.add(-1, "day");
          }
          FromDate = dateStart;
          ToDate = dayjs();
        }
        break;
      case "ThisMonth":
        {
          let dateStart = dayjs().startOf("M");
          let dateTo = dateStart.endOf("M");
          FromDate = dateStart;
          ToDate = dateTo;
        }

        break;
      case "ThisMonthToDate":
        {
          let dateStart = dayjs().startOf("M");
          let dateTo = dayjs();
          FromDate = dateStart;
          ToDate = dateTo;
        }
        break;
      case "ThisYear":
        {
          let fiscalYearStart = authStore?.companySettings.FiscalYearStart;
          let dateStart = dayjs().startOf("y");

          if (fiscalYearStart) {
            if (fiscalYearStart.length > 3) {
              try {
                dateStart = dayjs(
                  `${dayjs().year()}-${Number(fiscalYearStart.substring(0, 2))}-${Number(
                    fiscalYearStart.substring(2)
                  )}`
                );
              } catch (err) {
                dateStart = dayjs().startOf("y");
              }
            }
          }
          if (dateStart > dayjs()) {
            dateStart = dateStart.add(-1, "year");
          }
          let dateTo = dateStart.add(1, "year").add(-1, "day");
          FromDate = dateStart;
          ToDate = dateTo;
        }
        break;
      case "ThisYearToDate":
        {
          let fiscalYearStart = authStore?.companySettings.FiscalYearStart;
          let dateStart = dayjs().startOf("y");

          if (fiscalYearStart) {
            if (fiscalYearStart.length > 3) {
              try {
                dateStart = dayjs(
                  `${dayjs().year()}-${Number(fiscalYearStart.substring(0, 2))}-${Number(
                    fiscalYearStart.substring(2)
                  )}`
                );
              } catch (err) {
                dateStart = dayjs().startOf("y");
              }
            }
          }
          if (dateStart > dayjs()) {
            dateStart = dateStart.add(-1, "year");
          }
          let dateTo = dayjs();
          FromDate = dateStart;
          ToDate = dateTo;
        }
        break;
      case "Yesterday":
        FromDate = dayjs().add(-1, "day");
        ToDate = dayjs().add(-1, "day");
        break;
      case "LastSevenDays":
        FromDate = dayjs().add(-7, "day");
        ToDate = dayjs().add(-1, "day");
        break;
      case "LastWeek":
        {
          let dateStart = dayjs().add(-7, "day");
          let prefsDay = authStore?.companySettings?.FirstDayOfWeek;
          while (dateStart.day() !== (prefsDay === 7 ? 0 : prefsDay)) {
            dateStart = dateStart.add(-1, "day");
          }
          let dayTo = dateStart.add(6, "day");
          FromDate = dateStart;
          ToDate = dayTo;
        }
        break;
      case "LastFourWeeks":
        {
          let dateStart = dayjs().add(-28, "day");
          let prefsDay = authStore?.companySettings?.FirstDayOfWeek;

          while (dateStart.day() !== (prefsDay === 7 ? 0 : prefsDay)) {
            dateStart = dateStart.add(-1, "day");
          }
          let dayTo = dateStart.add(27, "day");
          FromDate = dateStart;
          ToDate = dayTo;
        }
        break;
      case "LastMonth":
        {
          let dateStart = dayjs().add(-1, "month").startOf("M");
          let dateTo = dateStart.endOf("M");
          FromDate = dateStart;
          ToDate = dateTo;
        }
        break;
      case "Filter":
      case "LastYear":
        {
          let fiscalYearStart = authStore?.companySettings.FiscalYearStart;
          let dateStart = dayjs().startOf("y");

          if (fiscalYearStart) {
            if (fiscalYearStart.length > 3) {
              try {
                dateStart = dayjs(
                  `${dayjs().year()}-${Number(fiscalYearStart.substring(0, 2))}-${Number(
                    fiscalYearStart.substring(2)
                  )}`
                );
              } catch (err) {
                dateStart = dayjs().startOf("y");
              }
            }
          }
          if (dateStart > dayjs()) {
            dateStart = dateStart.add(-1, "year");
          }
          dateStart = dateStart.add(-1, "year");
          let dateTo = dayjs().add(-1, "year").endOf("y");
          FromDate = dateStart;
          ToDate = dateTo;
        }
        break;
      case "Tomorrow":
        FromDate = dayjs().add(1, "day");
        ToDate = dayjs().add(1, "day");
        break;
      case "NextSevenDays":
        FromDate = dayjs().add(1, "day");
        ToDate = dayjs().add(7, "day");
        break;
      case "NextWeek":
        {
          let dateStart = dayjs().add(7, "day");
          let prefsDay = authStore?.companySettings?.FirstDayOfWeek;
          while (dateStart.day() !== (prefsDay === 7 ? 0 : prefsDay)) {
            dateStart = dateStart.add(-1, "day");
          }
          let dayTo = dateStart.add(6, "day");
          FromDate = dateStart;
          ToDate = dayTo;
        }
        break;
      case "NextFourWeeks":
        {
          let dateStart = dayjs().add(7, "day").add(-dayjs().day(), "day");

          FromDate = dateStart;
          ToDate = dateStart.add(27, "day");
        }
        break;
      case "NextMonth":
        {
          let dateStart = dayjs().add(1, "month").startOf("M");
          let dateTo = dateStart.endOf("M");
          FromDate = dateStart;
          ToDate = dateTo;
        }
        break;
      case "LastYearToDate":
        {
          let fiscalYearStart = authStore?.companySettings.FiscalYearStart;
          let dateStart = dayjs().add(-1, "year").startOf("y");

          if (fiscalYearStart) {
            if (fiscalYearStart.length > 3) {
              try {
                dateStart = dayjs(
                  `${dayjs().add(-1, "year").year()}-${Number(
                    fiscalYearStart.substring(0, 2)
                  )}-${Number(fiscalYearStart.substring(2))}`
                );
              } catch (err) {
                dateStart = dayjs().add(-1, "year").startOf("y");
              }
            }
          }
          if (dateStart > dayjs()) {
            dateStart = dateStart.add(-1, "year");
          }
          let dateTo = dayjs();
          FromDate = dateStart;
          ToDate = dateTo;
        }
        break;
    }

    return { FromDate, ToDate };
  };

  let initialFormValues: any;

  initialFormValues = {
    report_key: "",
    report_title: dataTypeValue + " " + groupByValue + " " + dateRangeValue,
    data_type: personalDashboardStore?.dataTypeOptions
      ? personalDashboardStore?.dataTypeOptions.find((i: any) => i.label === "Appointments All")
          ?.label
      : "",
    criteria_type: "None",
    criteria_value: "",
    chart_type: "Bar",
    chart_color1: "Red",
    chart_color2: "",
    group_by: "None",
    data_comparison: "None",
    date_range: "Today",
    date_from: dates("Today").FromDate,
    date_to: dates("Today").ToDate,
    apply_filter: "",
    auto_refresh_rate: "",
    studio_selection: authStore?.companySettings?.IsEnterprise === true ? "2" : "0",
    studio_no: authStore?.companySettings?.IsEnterprise === true ? "999" : "",
  };

  const fetchDataTypeDependency = (dataType: any) => {
    if (dataType) {
      personalDashboardStore?.getGroupBy(dataType.value);
      personalDashboardStore?.getCriteriaType(dataType.value);
    }
  };

  useEffect(() => {
    const d: Report = personalDashboardStore?.filteredActiveSavedReport;
    let criteriaValues = generateCriteriaType(
      personalDashboardStore?.criteriaTypeOptions
        ? personalDashboardStore?.criteriaTypeOptions?.find(
            (i: any) => i.enumvalue === Number(d.criteria_type)
          )?.value
        : ""
    );
    form?.setFieldsValue({
      criteria_value: criteriaValues
        ? criteriaValues.find((i: any) => i.value === d.criteria_value)?.label
        : "",
      criteria_type:
        personalDashboardStore?.criteriaTypeOptions || form?.getFieldValue("data_type")
          ? personalDashboardStore?.criteriaTypeOptions?.find(
              (i: any) => i.enumvalue === Number(d.criteria_type)
            )?.label
          : "",
    });
  }, [
    personalDashboardStore?.filteredActiveSavedReport,
    personalDashboardStore?.criteriaTypeOptions,
  ]);

  useEffect(() => {
    const d: Report = personalDashboardStore?.filteredActiveSavedReport;
    form?.setFieldsValue({
      group_by: personalDashboardStore?.groupByOptions
        ? personalDashboardStore?.groupByOptions?.find(
            (i: any) => i.enumvalue === Number(d.group_by)
          )?.label
        : "",
    });
  }, [personalDashboardStore?.filteredActiveSavedReport, personalDashboardStore?.groupByOptions]);

  useEffect(() => {
    if (Object.keys(personalDashboardStore?.filteredActiveSavedReport).length !== 0) {
      const d: Report = personalDashboardStore?.filteredActiveSavedReport;
      if (authStore?.companySettings?.IsEnterprise === true) {
        setStudioSelectOption(d.studio_selection);
      }
      if (d.date_range !== "100" && d.apply_filter === "True") {
        setHideDateField(false);
        setAlsoApplyCheck(true);
        setShowApplyFilter(true);
      } else if (d.date_range === "100") {
        setHideDateField(true);
        setAlsoApplyCheck(false);
        setShowApplyFilter(true);
      } else {
        setHideDateField(false);
        setAlsoApplyCheck(false);
        setShowApplyFilter(false);
      }
      fetchDataTypeDependency(
        personalDashboardStore?.dataTypeOptions.find(
          (i: any) => i.enumvalue === Number(d.data_type)
        )
      );
      if (personalDashboardStore?.chartTypeOptions[Number(d.chart_type)]?.value === "Pie") {
        setShowChartColor2(true);
      } else {
        setShowChartColor2(false);
      }
      form?.setFieldsValue({
        report_key: personalDashboardStore?.activeSavedReport?.Key || "",
        report_title: d.report_title || personalDashboardStore?.activeSavedReport?.Name,
        data_type: personalDashboardStore?.dataTypeOptions.find(
          (i: any) => i.enumvalue === Number(d.data_type)
        )?.label,
        chart_type: personalDashboardStore?.chartTypeOptions[Number(d.chart_type)]?.value,
        chart_color1: personalDashboardStore?.chartColorOptions[Number(d.chart_color1)]?.value,
        chart_color2: personalDashboardStore?.chartColorOptions[Number(d.chart_color2)]?.value,
        data_comparison:
          personalDashboardStore?.dataComparisonOptions[Number(d.data_comparison)]?.value,
        date_range:
          d.date_range === "101"
            ? personalDashboardStore?.dateRangeOptions[20]?.value
            : d.date_range === "100"
            ? personalDashboardStore?.dateRangeOptions[19]?.value
            : d.date_range === "999"
            ? personalDashboardStore?.dateRangeOptions[21]?.value
            : personalDashboardStore?.dateRangeOptions[Number(d.date_range)]?.value,
        date_from: d.date_from
          ? d.date_range === "101"
            ? dayjs(d.date_from)
            : dates(personalDashboardStore?.dateRangeOptions[Number(d.date_range)]?.value).FromDate
          : undefined,
        date_to: d.date_to
          ? d.date_range === "101"
            ? dayjs(d.date_to)
            : dates(personalDashboardStore?.dateRangeOptions[Number(d.date_range)]?.value).ToDate
          : undefined,
        apply_filter:
          d?.apply_filter === "True"
            ? personalDashboardStore?.filterListOptions.find((i: any) => i.value === d.filter_id)
                ?.label
            : "",
        auto_refresh_rate: d.auto_refresh_rate,
        studio_selection:
          authStore?.companySettings?.IsEnterprise === true
            ? studioSelectionOptions.find((i: any) => i.value === d.studio_selection)?.label
            : "0",
        studio_no:
          authStore?.companySettings?.IsEnterprise === true
            ? authStore?.sortedLocations.find((i: any) => i.value === d.studio_no)?.label
            : "",
      });
    }
  }, [personalDashboardStore?.filteredActiveSavedReport]);

  const generateXmlString = (d: any, criteriaValueList: any, values: any, data_type: any) => {
    let sendObj = {
      report_settings: {
        report_title: values?.report_title ? values?.report_title : "",
        data_type: data_type?.EnumValue ? data_type?.EnumValue : 0,
        criteria_type: personalDashboardStore?.criteriaTypeOptions
          ? personalDashboardStore?.criteriaTypeOptions?.find(
              (i: any) => i.label === values?.criteria_type
            )?.enumvalue
            ? personalDashboardStore?.criteriaTypeOptions?.find(
                (i: any) => i.label === values?.criteria_type
              )?.enumvalue
            : personalDashboardStore?.criteriaTypeOptions?.find(
                (i: any) => i.value === values?.criteria_type
              )?.enumvalue
            ? personalDashboardStore?.criteriaTypeOptions?.find(
                (i: any) => i.value === values?.criteria_type
              )?.enumvalue
            : 0
          : 0,
        criteria_value: criteriaValueList
          ? criteriaValueList?.find((i: any) => i.label === values?.criteria_value)?.value
            ? criteriaValueList?.find((i: any) => i.label === values?.criteria_value)?.value
            : criteriaValueList?.find((i: any) => i.value === values?.criteria_value)?.value
            ? criteriaValueList?.find((i: any) => i.value === values?.criteria_value)?.value
            : ""
          : "",
        chart_type: personalDashboardStore?.chartTypeOptions
          ? personalDashboardStore?.chartTypeOptions?.find(
              (i: any) => i.label === values?.chart_type
            )?.enumvalue
            ? personalDashboardStore?.chartTypeOptions?.find(
                (i: any) => i.label === values?.chart_type
              )?.enumvalue
            : personalDashboardStore?.chartTypeOptions?.find(
                (i: any) => i.value === values?.chart_type
              )?.enumvalue
            ? personalDashboardStore?.chartTypeOptions?.find(
                (i: any) => i.value === values?.chart_type
              )?.enumvalue
            : 0
          : 0,
        chart_color1: personalDashboardStore?.chartColorOptions
          ? personalDashboardStore?.chartColorOptions?.find(
              (i: any) => i.label === values?.chart_color1
            )?.enumvalue
            ? personalDashboardStore?.chartColorOptions?.find(
                (i: any) => i.label === values?.chart_color1
              )?.enumvalue
            : personalDashboardStore?.chartColorOptions?.find(
                (i: any) => i.value === values?.chart_color1
              )?.enumvalue
            ? personalDashboardStore?.chartColorOptions?.find(
                (i: any) => i.value === values?.chart_color1
              )?.enumvalue
            : 0
          : 0,
        chart_color2: personalDashboardStore?.chartColorOptions
          ? personalDashboardStore?.chartColorOptions?.find(
              (i: any) => i.label === values?.chart_color2
            )?.enumvalue
            ? personalDashboardStore?.chartColorOptions?.find(
                (i: any) => i.label === values?.chart_color2
              )?.enumvalue
            : personalDashboardStore?.chartColorOptions?.find(
                (i: any) => i.value === values?.chart_color2
              )?.enumvalue
            ? personalDashboardStore?.chartColorOptions?.find(
                (i: any) => i.value === values?.chart_color2
              )?.enumvalue
            : 0
          : 0,
        group_by: personalDashboardStore?.groupByOptions
          ? personalDashboardStore?.groupByOptions?.find((i: any) => i.label === values?.group_by)
              ?.enumvalue
            ? personalDashboardStore?.groupByOptions?.find((i: any) => i.label === values?.group_by)
                ?.enumvalue
            : personalDashboardStore?.groupByOptions?.find((i: any) => i.value === values?.group_by)
                ?.enumvalue
            ? personalDashboardStore?.groupByOptions?.find((i: any) => i.value === values?.group_by)
                ?.enumvalue
            : 0
          : 0,
        data_comparison: personalDashboardStore?.dataComparisonOptions
          ? personalDashboardStore?.dataComparisonOptions?.find(
              (i: any) => i.label === values?.data_comparison
            )?.enumvalue
            ? personalDashboardStore?.dataComparisonOptions?.find(
                (i: any) => i.label === values?.data_comparison
              )?.enumvalue
            : personalDashboardStore?.dataComparisonOptions?.find(
                (i: any) => i.value === values?.data_comparison
              )?.enumvalue
            ? personalDashboardStore?.dataComparisonOptions?.find(
                (i: any) => i.value === values?.data_comparison
              )?.enumvalue
            : 0
          : 0,
        date_range: personalDashboardStore?.dateRangeOptions
          ? personalDashboardStore?.dateRangeOptions?.find(
              (i: any) => i.label === values?.date_range
            )?.enumvalue
            ? personalDashboardStore?.dateRangeOptions?.find(
                (i: any) => i.label === values?.date_range
              )?.enumvalue
            : personalDashboardStore?.dateRangeOptions?.find(
                (i: any) => i.value === values?.date_range
              )?.enumvalue
            ? personalDashboardStore?.dateRangeOptions?.find(
                (i: any) => i.value === values?.date_range
              )?.enumvalue
            : 0
          : 0,
        date_from: values?.date_to
          ? dayjs(values?.date_from).format("MM/DD/YYYY")
          : dayjs().format("MM/DD/YYYY"),
        date_to: values?.date_to
          ? dayjs(values?.date_to).format("MM/DD/YYYY")
          : dayjs().format("MM/DD/YYYY"),
        apply_filter: showApplyFilter ? "True" : "False",
        filter_id: personalDashboardStore?.filterListOptions
          ? personalDashboardStore?.filterListOptions?.find(
              (i: any) => i.label === values?.apply_filter
            )?.value
            ? personalDashboardStore?.filterListOptions?.find(
                (i: any) => i.label === values?.apply_filter
              )?.value
            : personalDashboardStore?.filterListOptions?.find(
                (i: any) => i.value === values?.apply_filter
              )?.value
            ? personalDashboardStore?.filterListOptions?.find(
                (i: any) => i.value === values?.apply_filter
              )?.value
            : ""
          : "",
        studio_selection: d?.studio_selection
          ? d?.studio_selection
          : Number(values?.studio_selection),
        studio_no: d?.studio_no ? d?.studio_no : values?.studio_no,
        seperate_each_studio: d?.seperate_each_studio ? d?.seperate_each_studio : "False",
        show_chart: d?.show_chart ? d?.show_chart : "True",
        show_grid: d?.show_grid ? d?.show_grid : "True",
        show_detail: d?.show_detail ? d?.show_detail : "True",
        auto_refresh_rate: d?.auto_refresh_rate ? d?.auto_refresh_rate : 0,
      },
    };
    const objectToXmlString = require("object-to-xml-string");
    const obj2xml = new objectToXmlString();
    return obj2xml.convert(sendObj);
  };

  const getDataType = (values: any) => {
    const data_type = {
      EnumValue: personalDashboardStore?.dataTypeOptions
        ? personalDashboardStore?.dataTypeOptions?.find((i: any) => i.label === values?.data_type)
            ?.enumvalue
          ? personalDashboardStore?.dataTypeOptions?.find((i: any) => i.label === values?.data_type)
              ?.enumvalue
          : personalDashboardStore?.dataTypeOptions?.find((i: any) => i.value === values?.data_type)
              ?.enumvalue
          ? personalDashboardStore?.dataTypeOptions?.find((i: any) => i.value === values?.data_type)
              ?.enumvalue
          : 0
        : 0,
      Key: personalDashboardStore?.dataTypeOptions
        ? personalDashboardStore?.dataTypeOptions?.find((i: any) => i.label === values?.data_type)
            ?.value
          ? personalDashboardStore?.dataTypeOptions?.find((i: any) => i.label === values?.data_type)
              ?.value
          : personalDashboardStore?.dataTypeOptions?.find((i: any) => i.value === values?.data_type)
              ?.value
          ? personalDashboardStore?.dataTypeOptions?.find((i: any) => i.value === values?.data_type)
              ?.value
          : "TotalSales"
        : "TotalSales",
    };

    return data_type;
  };

  const getCriteriaType = (values: any) => {
    const criteriaTypeValue = personalDashboardStore?.criteriaTypeOptions
      ? personalDashboardStore?.criteriaTypeOptions?.find(
          (i: any) => i.label === values?.criteria_type
        )?.value
        ? personalDashboardStore?.criteriaTypeOptions?.find(
            (i: any) => i.label === values?.criteria_type
          )?.value
        : personalDashboardStore?.criteriaTypeOptions?.find(
            (i: any) => i.value === values?.criteria_type
          )?.value
        ? personalDashboardStore?.criteriaTypeOptions?.find(
            (i: any) => i.value === values?.criteria_type
          )?.value
        : "None"
      : "None";

    return criteriaTypeValue;
  };

  const getReportObject = (values: any, XmlSettings: string, data_type: any) => {
    const reportObject: ReportObject = {
      XmlSettings: XmlSettings,
      SelectedColumns: null,
      DataType: data_type.EnumValue,
      GridLayoutModified: false,
      GridLayout: null,
      Locked: false,
      isNew: false,
      LastModified: "",
      Modified: false,
      SortOrder: 0,
      Type: 2,
      Key: values?.report_key ? values?.report_key : "",
      Name: values?.report_title ? values?.report_title : "",
    };
    return reportObject;
  };

  const handleSubmit = async (values: any) => {
    setFormLoading(true);
    const data_type = getDataType(values);
    fetchDataTypeDependency({ value: data_type.Key });
    const criteriaTypeValue = getCriteriaType(values);
    let criteriaValueList = [];
    if (criteriaTypeValue !== "None") {
      criteriaValueList = generateCriteriaType(criteriaTypeValue);
    }

    let d: Report | undefined;
    if (personalDashboardStore?.filteredActiveSavedReport) {
      d = personalDashboardStore?.filteredActiveSavedReport;
    }

    const XmlSettings: string = `<settings version=\"1.0\">${generateXmlString(
      d,
      criteriaValueList,
      values,
      data_type
    )}</settings>`;
    const reportObject = getReportObject(values, XmlSettings, data_type);

    personalDashboardStore?.saveDashboardReport(reportObject).then(async (data: any) => {
      await personalDashboardStore?.getDashboardReports();
      let chartData: any = {};
      if (data?.Key != "") {
        chartData.Key = data?.Key;
        if (Object.keys(editChartValues).length > 0) {
          // personalDashboardStore?.getCompanyDashboard();
          message.success("Report Updated");
        } else {
          if (isAddtoDashboard) {
            message.success("Report Added to Dashboard");
            personalDashboardStore?.addChartData(data?.Key);
          } else {
            message.success("Report Saved");
          }
        }
        if (isAddtoDashboard || Object.keys(editChartValues).length > 0) toggleDrawer(false);
        setFormLoading(false);
      } else {
        form.resetFields();
        setActiveTab("2");
        setSelectedReport(data.Key);
        await personalDashboardStore?.getDashboardReport(data.Key);
        setFormLoading(false);
      }
      setIsDashboardUpdated(true);
      setIsAddtoDashboard(false);
    });
  };

  const valueChangeHandler = (changedValues: any) => {
    if (changedValues.data_type) {
      personalDashboardStore?.getGroupBy(changedValues.data_type);
      personalDashboardStore?.getCriteriaType(changedValues.data_type);
    }

    if (changedValues.date_range) {
      form?.setFieldsValue({
        date_from: dates(changedValues.date_range).FromDate,
        date_to: dates(changedValues.date_range).ToDate,
      });

      if (changedValues.date_range === "Filter") {
        setShowAlsoApplyCheck(false);
        setHideDateField(true);
        setShowApplyFilter(true);
        setAlsoApplyCheck(false);
      } else {
        form?.setFieldsValue({ apply_filter: "" });
        setShowAlsoApplyCheck(true);
        setHideDateField(false);
        setShowApplyFilter(false);
        setAlsoApplyCheck(false);
      }
    }

    if (changedValues.criteria_type) {
      form?.setFieldsValue({ criteria_value: "" });
      generateCriteriaType(changedValues.criteria_type);
    }

    if (changedValues.date_from || changedValues.date_to) {
      form?.setFieldsValue({
        date_range: personalDashboardStore?.dateRangeOptions[20]?.value,
      });
    }

    if (changedValues.chart_type === "Pie") {
      setShowChartColor2(true);
    } else {
      setShowChartColor2(false);
    }

    if (changedValues.apply_filter) {
      form?.setFieldsValue({ apply_filter: changedValues.apply_filter });
      setHideDateField(true);
      setShowApplyFilter(true);
    }
    if (changedValues.data_type) {
      setDataTypeValue(changedValues.data_type.replace(/([A-Z])/g, " $1").trim());
    }
    if (changedValues.group_by) {
      setGroupByValue("On " + changedValues.group_by.replace(/([A-Z])/g, " $1").trim());
    }
    if (changedValues.date_range) {
      setDateRangeValue("For " + changedValues.date_range.replace(/([A-Z])/g, " $1").trim());
    }
    if (changedValues.studio_selection) {
      if (changedValues.studio_selection === "2") {
        form?.setFieldsValue({
          studio_selection: changedValues.studio_selection,
          studio_no: "999",
        });
      } else {
        form?.setFieldsValue({
          studio_selection: changedValues.studio_selection,
        });
      }
      setStudioSelectOption(changedValues.studio_selection);
    }
    if (changedValues.studio_no) {
      form?.setFieldsValue({
        studio_no: changedValues.studio_no,
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      report_title: dataTypeValue + " " + groupByValue + " " + dateRangeValue,
    });
  }, [dataTypeValue, groupByValue, dateRangeValue]);

  const generateCriteriaType = (criteria: string | undefined) => {
    let criteriaName: any = [];
    switch (criteria) {
      case "ClientOrganization":
        criteriaName = clientStore?.organizationList.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "ClientLeadSource":
        criteriaName = clientStore?.leadSourceList.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "ClientGroup":
        criteriaName = clientStore?.clientGroups.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "ClientReferralProgram":
        criteriaName = clientStore?.clientReferralPrograms.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "ClientStatus":
        criteriaName = clientStore?.clientStatuses.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "AppointmentUser":
      case "PhoneCallAssignedToUser":
      case "EmailAssignedToUser":
      case "EmailCreatedByUser":
      case "TaskAssignedToUser":
      case "OrderAssignedToUser":
      case "SessionBooker":
      case "InvoiceSalesRep":
        if (userStore?.usersList) {
          for (let user of userStore?.usersList) {
            criteriaName.push({ label: user.Name, value: user.Key });
          }
        }
        setCriteriaValue(criteriaName);
        break;
      case "AppointmentType":
        criteriaName = appointmentStore?.appointmentTypesList.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "AppointmentStatus":
        criteriaName = appointmentStore?.appointmentStatusesList.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "SessionStatus":
        criteriaName = sessionStore?.sessionStatusList.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "SessionAssistant":
        criteriaName = userStore?.selectAssistantList.map((data: any) => {
          return { label: data.label, value: data.value };
        });
        setCriteriaValue(criteriaName);
        break;
      case "SessionPhotographer":
        criteriaName = userStore?.selectPhotographerList.map((data: any) => {
          return { label: data.label, value: data.value };
        });
        setCriteriaValue(criteriaName);
        break;
      case "PhoneCallType":
        criteriaName = phoneCallStore?.phoneCallTypes?.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "PhoneCallAssignedToDepartment":
      case "TaskAssignedToDepartment":
      case "EmailAssignedToDepartment":
        criteriaName = phoneCallStore?.departments?.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "EmailDirection":
        {
          const emailDirectionList = [];
          emailDirectionList.push({ label: "Incoming", value: 1 });
          emailDirectionList.push({ label: "Outgoing", value: 2 });
          criteriaName = emailDirectionList;
          setCriteriaValue(criteriaName);
        }
        break;
      case "EmailStatus":
        criteriaName = messageStore?.emailStatusList.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "EmailType":
        criteriaName = messageStore?.emailTypes.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "EmailCategory":
        criteriaName = messageStore?.emailCategories.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "TaskType":
        criteriaName = taskStore?.taskTypes.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "TaskStatus":
        criteriaName = taskStore?.taskStatuses.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "CommunicationOutcome":
        criteriaName = phoneCallStore?.phoneCallOutcomes?.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "CommunicationResult":
        criteriaName = phoneCallStore?.phoneCallResults?.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "SessionPromotion":
        criteriaName = sessionStore?.selectSessionPromotionList?.map((data: any) => {
          return { label: data.label, value: data.value };
        });
        setCriteriaValue(criteriaName);
        break;
      case "SessionPromotionCode":
        criteriaName = sessionStore?.selectSessionPromotionCodeList?.map((data: any) => {
          return { label: data.label, value: data.value };
        });
        setCriteriaValue(criteriaName);
        break;
      case "InvoiceProductLine":
        criteriaName = invoiceStore?.invoiceProductLineList?.map((data: any) => {
          return { label: data.Name, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "OrderStatus":
        criteriaName = productionOrderStore?.productionOrderStatusList?.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "OrderType":
        criteriaName = productionOrderStore?.productionOrderTypeList?.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "OrderReorderReasons":
        criteriaName = productionOrderStore?.reOrderReasonList?.map((data: any) => {
          return { label: data.Description, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      case "OrderVendor":
        criteriaName = productionOrderStore?.vendorList.map((data: any) => {
          return { label: data.Name, value: data.Key };
        });
        setCriteriaValue(criteriaName);
        break;
      default:
        setCriteriaValue([]);
    }
    return criteriaName;
  };

  const selectReportHandler = async (Key: string, option: any) => {
    setSelectedReport(option.label);
    setActiveTab("2");
    if (Key === "") {
      form.resetFields();
      setActiveTab("1");
      setStudioSelectOption("2");
    } else {
      setSelectedReportKey(Key);
      setShowOverRide(true);
    }
  };

  const handleOkReportOverRide = async () => {
    setShowOverRide(false);
    await personalDashboardStore?.getDashboardReport(selectedReportKey);
  };

  const handleCancelReportOverRide = () => {
    setShowOverRide(false);
  };

  const ModalHeader = () => {
    return (
      <Row>
        <Col>
          <Title level={2}>Report Properties</Title>
        </Col>
      </Row>
    );
  };

  const defaultParams: DrawerProps = {
    width: screens.xs ? innerWidth : innerWidth - 250,
    placement: "right",
    closable: true,
    forceRender: false,
    destroyOnClose: true,
    title: <ModalHeader />,
    maskStyle: {},
    className: "drawer-form",
    push: {
      distance: 20,
    },
  };

  const toggleDrawer = (toggle: boolean) => {
    onToggle && onToggle(toggle);
    personalDashboardStore?.setSelectToggle(toggle);
    personalDashboardStore?.resetActiveSavedReport();
    form?.resetFields();
    setActiveTab("1");
    setStudioSelectOption("2");
    setSelectedReport("");
    setEditChartValues({});
  };

  const viewReportHandler = async () => {
    setFormLoading(true);
    let values = form.getFieldsValue();
    const data_type = getDataType(values);
    fetchDataTypeDependency({ value: data_type.Key });
    const criteriaTypeValue = getCriteriaType(values);
    let criteriaValueList = [];
    if (criteriaTypeValue !== "None") {
      criteriaValueList = generateCriteriaType(criteriaTypeValue);
    }

    let d: Report | undefined;
    if (personalDashboardStore?.filteredActiveSavedReport) {
      d = personalDashboardStore?.filteredActiveSavedReport;
    }

    const XmlSettings: string = `<settings version=\"1.0\">${generateXmlString(
      d,
      criteriaValueList,
      values,
      data_type
    )}</settings>`;
    const reportObject = getReportObject(values, XmlSettings, data_type);
    personalDashboardStore?.saveDashboardReport(reportObject).then(async (data: any) => {
      if (values.report_key) {
        await personalDashboardStore?.getReportTable(personalDashboardStore?.activeSavedReport.Key);
        let reportData: any = {};
        reportData.Report = values;
        reportData.Key = values.report_key;
        await personalDashboardStore
          ?.getChartData(values.report_key)
          .then((data: any) => {
            reportData.ChartData = data;
            personalDashboardStore?.setViewReportData(reportData);
          })
          .catch((err) => {
            message.error(err);
          });
        personalDashboardStore?.setViewReportToggle(true);
      }
      setFormLoading(false);
    });
  };

  const showViewReportConfirm = () => {
    setShowViewConfirmModal(true);
  };

  const handleCancelViewModal = () => {
    setShowViewConfirmModal(false);
  };

  const saveAndViewReportHandler = () => {
    setFormLoading(true);
    setShowViewConfirmModal(false);
    let values = form.getFieldsValue();
    const data_type = getDataType(values);
    fetchDataTypeDependency({ value: data_type.Key });
    const criteriaTypeValue = getCriteriaType(values);
    let criteriaValueList = [];
    if (criteriaTypeValue !== "None") {
      criteriaValueList = generateCriteriaType(criteriaTypeValue);
    }

    let d: Report | undefined;
    if (personalDashboardStore?.filteredActiveSavedReport) {
      d = personalDashboardStore?.filteredActiveSavedReport;
    }

    const XmlSettings: string = `<settings version=\"1.0\">${generateXmlString(
      d,
      criteriaValueList,
      values,
      data_type
    )}</settings>`;
    const reportObject = getReportObject(values, XmlSettings, data_type);
    personalDashboardStore?.saveDashboardReport(reportObject).then(async (data: any) => {
      await personalDashboardStore?.getReportTable(data.Key);
      let reportData: any = {};
      reportData.Report = values;
      reportData.Key = values.report_key;
      await personalDashboardStore
        ?.getChartData(data.Key)
        .then((data: any) => {
          reportData.ChartData = data;
          personalDashboardStore?.setViewReportData(reportData);
        })
        .catch((err) => {
          message.error(err);
        });
      personalDashboardStore?.setViewReportToggle(true);
      form.resetFields();
      setActiveTab("2");
      setSelectedReport(data.Key);
      await personalDashboardStore?.getDashboardReport(data.Key);
      setFormLoading(false);
      await personalDashboardStore?.getDashboardReports();
    });
  };

  return (
    <>
      <Drawer
        title={
          <Title level={1} style={{ marginBottom: 0 }}>
            Report Properties
          </Title>
        }
        onClose={() => toggleDrawer(false)}
        visible={personalDashboardStore?.isSelectToggle}
        {...defaultParams}
      >
        <Spin size={"large"} spinning={personalDashboardStore?.isLoading || formLoading}>
          <div className="reportProperties">
            <Card className="reportProperties--Card">
              <Form
                onFinish={handleSubmit}
                onValuesChange={valueChangeHandler}
                form={form}
                initialValues={initialFormValues}
              >
                <Row>
                  <Form.Item label="report_key" name="report_key" hidden={true}>
                    <Input id="report_key" disabled={isLoading} />
                  </Form.Item>
                  <Col span={24}>
                    <Row>
                      <Col xxl={20} xl={18} lg={16} md={24}>
                        <Col className="reportList">
                          <label style={{ marginBottom: 10, display: "flex" }}>
                            Load Existing Report
                          </label>
                          <Select
                            placeholder="Select Saved Report"
                            optionFilterProp="label"
                            showSearch
                            filterOption={(input, option) => {
                              let isFound: number | undefined = option?.label
                                ?.toString()
                                .toLowerCase()
                                .indexOf(input?.toLowerCase());
                              return isFound !== undefined && isFound >= 0 ? true : false;
                            }}
                            options={personalDashboardStore?.savedReports.filter(
                              (v) => v.type === 2
                            )}
                            onChange={(e, option) => {
                              selectReportHandler(e, option);
                            }}
                            value={selectedSavedReport}
                            disabled={Object.keys(editChartValues).length > 0}
                          />
                        </Col>
                        <Row className="form-group" gutter={20}>
                          <Col
                            xs={24}
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            <Form.Item
                              name="report_title"
                              hasFeedback={isLoading}
                              validateStatus={isLoading ? "validating" : undefined}
                            >
                              <InputField disabled={isLoading} label="Report Title" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row className="form-group" gutter={20}>
                          <Col
                            xs={24}
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            <Form.Item
                              name="data_type"
                              hasFeedback={isLoading}
                              validateStatus={isLoading ? "validating" : undefined}
                            >
                              <SelectField
                                label="Data Type"
                                options={personalDashboardStore?.dataTypeOptions}
                                disabled={isLoading}
                                showSearch={false}
                                showArrow={false}
                                suffixIcon={<DownOutlined />}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row className="form-group" gutter={20}>
                          <Col
                            xs={24}
                            sm={12}
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            <Form.Item
                              name="criteria_type"
                              hasFeedback={isLoading}
                              validateStatus={isLoading ? "validating" : undefined}
                            >
                              <SelectField
                                label="Criteria Type"
                                options={personalDashboardStore?.criteriaTypeOptions}
                                disabled={
                                  isLoading || !personalDashboardStore?.criteriaTypeOptions.length
                                }
                                showSearch={false}
                                showArrow={false}
                                suffixIcon={<DownOutlined />}
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            xs={24}
                            sm={12}
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            <Form.Item
                              name="criteria_value"
                              hasFeedback={isLoading}
                              validateStatus={isLoading ? "validating" : undefined}
                            >
                              <SelectField
                                label="Criteria Value"
                                options={criteriaValue}
                                disabled={isLoading || !criteriaValue.length}
                                showSearch={false}
                                showArrow={false}
                                suffixIcon={<DownOutlined />}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row className="form-group" gutter={20}>
                          <Col
                            xs={24}
                            sm={showChartColor2 ? 8 : 12}
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            <Form.Item
                              name="chart_type"
                              hasFeedback={isLoading}
                              validateStatus={isLoading ? "validating" : undefined}
                            >
                              <SelectField
                                label="Chart Type"
                                options={personalDashboardStore?.chartTypeOptions}
                                disabled={isLoading}
                                showSearch={false}
                                showArrow={false}
                                suffixIcon={<DownOutlined />}
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            xs={24}
                            sm={showChartColor2 ? 8 : 12}
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            <Form.Item
                              name="chart_color1"
                              hasFeedback={isLoading}
                              validateStatus={isLoading ? "validating" : undefined}
                            >
                              <SelectField
                                label="Chart Color"
                                options={personalDashboardStore?.chartColorOptions}
                                disabled={isLoading}
                                showSearch={false}
                                showArrow={false}
                                suffixIcon={<DownOutlined />}
                              />
                            </Form.Item>
                          </Col>
                          {showChartColor2 ? (
                            <Col
                              xs={24}
                              sm={8}
                              style={{
                                marginBottom: 10,
                              }}
                            >
                              <Form.Item
                                name="chart_color2"
                                hasFeedback={isLoading}
                                validateStatus={isLoading ? "validating" : undefined}
                                hidden={!showChartColor2}
                              >
                                <SelectField
                                  label="Chart Color 2"
                                  options={personalDashboardStore?.chartColorOptions}
                                  disabled={isLoading}
                                  showSearch={false}
                                  showArrow={false}
                                  suffixIcon={<DownOutlined />}
                                />
                              </Form.Item>
                            </Col>
                          ) : null}
                        </Row>
                        <Row className="form-group" gutter={20}>
                          <Col
                            xs={24}
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            <Form.Item
                              name="group_by"
                              hasFeedback={isLoading}
                              validateStatus={isLoading ? "validating" : undefined}
                            >
                              <SelectField
                                label="Group By"
                                options={personalDashboardStore?.groupByOptions}
                                disabled={
                                  isLoading || !personalDashboardStore?.groupByOptions.length
                                }
                                showSearch={false}
                                showArrow={false}
                                suffixIcon={<DownOutlined />}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row className="form-group" gutter={20}>
                          <Col
                            xs={24}
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            <Form.Item
                              name="data_comparison"
                              hasFeedback={isLoading}
                              validateStatus={isLoading ? "validating" : undefined}
                            >
                              <SelectField
                                label="Data Comparison"
                                options={personalDashboardStore?.dataComparisonOptions}
                                disabled={isLoading}
                                showSearch={false}
                                showArrow={false}
                                suffixIcon={<DownOutlined />}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row className="form-group" gutter={20}>
                          <Col
                            xs={24}
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            <Form.Item
                              name="date_range"
                              hasFeedback={isLoading}
                              validateStatus={isLoading ? "validating" : undefined}
                            >
                              <SelectField
                                label="Date Range"
                                options={personalDashboardStore?.dateRangeOptions}
                                disabled={isLoading}
                                showSearch={false}
                                showArrow={false}
                                suffixIcon={<DownOutlined />}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row className="form-group" gutter={20}>
                          <Col
                            xs={24}
                            sm={12}
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            <Form.Item
                              name="date_from"
                              hasFeedback={isLoading}
                              validateStatus={isLoading ? "validating" : undefined}
                              hidden={hideDateField}
                            >
                              <DateField
                                label="From"
                                disabled={isLoading}
                                suffixIcon={<></>}
                                allowClear={false}
                                placeholder=""
                              />
                            </Form.Item>
                          </Col>
                          <Col
                            xs={24}
                            sm={12}
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            <Form.Item
                              name="date_to"
                              hasFeedback={isLoading}
                              validateStatus={isLoading ? "validating" : undefined}
                              hidden={hideDateField}
                            >
                              <DateField
                                label="To"
                                disabled={isLoading}
                                suffixIcon={<></>}
                                allowClear={false}
                                placeholder=""
                              />
                            </Form.Item>
                          </Col>
                          {showAlsoApplyCheck ? (
                            <Col
                              xs={24}
                              sm={12}
                              style={{
                                marginBottom: 10,
                              }}
                            >
                              <Checkbox
                                checked={alsoApplyCheck}
                                onChange={(e: any) => {
                                  handleApplyFilterCheck(e);
                                }}
                              >
                                Also Apply Filter
                              </Checkbox>
                            </Col>
                          ) : null}
                        </Row>
                        <Row className="form-group" gutter={20}>
                          <Col
                            xs={24}
                            style={{
                              marginBottom: 10,
                              marginTop: 20,
                            }}
                          >
                            <Form.Item
                              name="apply_filter"
                              hasFeedback={isLoading}
                              validateStatus={isLoading ? "validating" : undefined}
                              hidden={!showApplyFilter}
                            >
                              <SelectField
                                label="Filter"
                                options={personalDashboardStore?.filterListOptions}
                                disabled={isLoading}
                                showSearch={false}
                                showArrow={false}
                                suffixIcon={<DownOutlined />}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row className="form-group" gutter={20}>
                          <Col
                            xs={24}
                            style={{
                              marginBottom: 10,
                              marginTop: 20,
                            }}
                          >
                            <Form.Item
                              name="studio_selection"
                              hasFeedback={isLoading}
                              validateStatus={isLoading ? "validating" : undefined}
                              hidden={!(authStore?.companySettings?.IsEnterprise === true)}
                            >
                              <SelectField
                                label="Location Selection"
                                options={studioSelectionOptions}
                                disabled={isLoading}
                                showSearch={false}
                                showArrow={false}
                                suffixIcon={<DownOutlined />}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row className="form-group" gutter={20}>
                          <Col
                            xs={24}
                            style={{
                              marginBottom: 10,
                              marginTop: 20,
                            }}
                          >
                            <Form.Item
                              name="studio_no"
                              hasFeedback={isLoading}
                              validateStatus={isLoading ? "validating" : undefined}
                              hidden={studioSelectOption === "2" ? true : false}
                            >
                              <SelectField
                                label="Locations"
                                options={authStore?.sortedLocations}
                                disabled={isLoading}
                                showSearch={false}
                                showArrow={false}
                                suffixIcon={<DownOutlined />}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col xxl={4} xl={6} lg={8} md={24} className="reportProperties__Action">
                        <Card className="reportProperties__Action--Card">
                          <Col className="reportProperties__Action--Card__Buttons">
                            <Button
                              hidden={activeTab === "2" ? true : false}
                              onClick={() => form.validateFields().then(() => form.submit())}
                              className="ant-btn-default-blue"
                              style={{
                                backgroundColor: "rgb(146, 176, 182)",
                                borderColor: "rgb(146, 176, 182)",
                                color: "white",
                              }}
                            >
                              {activeTab === "1"
                                ? "Save Report"
                                : Object.keys(editChartValues).length > 0
                                ? "Finish Editing"
                                : "Add to Dashboard"}
                            </Button>
                            {Object.keys(editChartValues).length > 0 ? null : (
                              <Button
                                onClick={() => {
                                  setIsAddtoDashboard(true);
                                  form.validateFields().then(() => form.submit());
                                }}
                                className="ant-btn-default-blue"
                                style={{
                                  backgroundColor: "rgb(146, 176, 182)",
                                  borderColor: "rgb(146, 176, 182)",
                                  color: "white",
                                }}
                              >
                                Add to Dashboard
                              </Button>
                            )}
                            <Button
                              onClick={() => {
                                if (activeTab === "1") {
                                  showViewReportConfirm();
                                  setIsCreateReport(true);
                                } else viewReportHandler();
                              }}
                              className="ant-btn-default-blue"
                              style={{
                                backgroundColor: "rgb(146, 176, 182)",
                                borderColor: "rgb(146, 176, 182)",
                                color: "white",
                              }}
                            >
                              View Report
                            </Button>
                            <Button
                              onClick={() => {}}
                              className="ant-btn-default-blue"
                              style={{
                                backgroundColor: "rgb(146, 176, 182)",
                                borderColor: "rgb(146, 176, 182)",
                                color: "white",
                              }}
                            >
                              Help
                            </Button>
                            <Button
                              onClick={() => {}}
                              className="ant-btn-default-blue"
                              style={{
                                backgroundColor: "rgb(146, 176, 182)",
                                borderColor: "rgb(146, 176, 182)",
                                color: "white",
                              }}
                            >
                              Subscribe
                            </Button>
                            <Button
                              onClick={() => {}}
                              className="ant-btn-default-blue"
                              style={{
                                backgroundColor: "rgb(146, 176, 182)",
                                borderColor: "rgb(146, 176, 182)",
                                color: "white",
                              }}
                            >
                              View Subscriptions
                            </Button>
                          </Col>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Card>
          </div>
        </Spin>
        <ViewPersonalReportDrawerView
          isCreateReport={isCreateReport}
          setIsCreateReport={setIsCreateReport}
        />
        <Modal
          title={`View Report`}
          visible={showViewConfirmModal}
          onCancel={handleCancelViewModal}
          onOk={saveAndViewReportHandler}
        >
          <p>Are you sure you want to save and view this report?</p>
        </Modal>
        <Modal
          title={`Loading Report`}
          visible={showOverRide}
          onCancel={handleCancelReportOverRide}
          onOk={handleOkReportOverRide}
        >
          <p>
            Loading an existing report will cause you to lose your changes, do you want to continue?
          </p>
        </Modal>
      </Drawer>
    </>
  );
};

export default inject(
  "authStore",
  "personalDashboardStore",
  "clientStore",
  "appointmentStore",
  "userStore",
  "sessionStore",
  "phoneCallStore",
  "taskStore",
  "messageStore",
  "invoiceStore",
  "productionOrderStore"
)(observer(PersonalDashboardPropertiesDrawerView));
