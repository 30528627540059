import React from "react";
import { inject, observer } from "mobx-react";
import { List } from "antd";
import dayjs from "dayjs";
import { AppointmentStoreClass } from "stores/AppointmentStore";
import { ScheduleStoreClass } from "stores/ScheduleStore";

interface Props {
  appointmentStore?: AppointmentStoreClass;
  scheduleStore?: ScheduleStoreClass;
}

const AppointmentsList: React.FC<Props> = ({ appointmentStore, scheduleStore }) => {
  //comment
  return (
    <List
      dataSource={appointmentStore?.sortedAppointmentList}
      renderItem={(item: any) => {
        return (
          <List.Item
            onClick={async () => {
              appointmentStore?.getAppointmentDetail(item.Key);
              appointmentStore?.setSelectToggle(true);
              scheduleStore?.setActiveSchedule({
                AppointmentKey: item.Key,
              });

              // if (location.pathname !== "/schedule") {
              //   history.push("/schedule");
              // }
            }}
          >
            <List.Item.Meta
              style={{ alignItems: "center" }}
              title={<p style={{ marginBottom: "0" }}>{item.Description}</p>}
              description={
                <p style={{ margin: 0 }}>{dayjs(item.StartDate).format("MMM DD, YYYY")}</p>
              }
            />
          </List.Item>
        );
      }}
      bordered
      style={{ border: 0, backgroundColor: "#fff" }}
    ></List>
  );
};

export default inject("appointmentStore", "scheduleStore")(observer(AppointmentsList));
