import React, { useEffect, useState } from "react";
import "./product-browser.scss";
import ProductCategoryListBrowser from "components/product-category-list-browser/product-category-list-browser";
import ProductListViewer from "components/product-list-viewer/product-list-viewer";
import ProductItemViewer from "components/product-item-viewer/product-item-viewer";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { formatPrice } from "utils/helper-methods";

const ProductBrowser = (props: any) => {
  const { switchToCartSummary, selectedImage } = props;
  const [activeView, setActiveView] = useState("product-categories");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);

  useEffect(() => {
    _initiateLoad();
  }, []);

  const _addToCart = (productSpecs: any) => {
    const newCartItem: any = {
      Description: selectedProduct.Name,
      PriceStringFormatted: `$${formatPrice(selectedProduct.Price)}`,
      Price: selectedProduct.Price,
      ItemKey: selectedProduct.Key,
      FileName: "",
      ...productSpecs,
    };
    if (selectedImage) {
      newCartItem.FileName = toJS(selectedImage).fileName;
    }
    props.cartStore.addItem(newCartItem);
    switchToCartSummary();
  };

  const _initiateLoad = async () => {
    if (!toJS(props?.cartStore?.categories).length) {
      setIsLoading(true);
    }
    await Promise.all([
      props.cartStore.fetchCart(),
      props.cartStore.fetchCartMetaData(props.sessionStore?.currentSessionDetail.PriceListKey),
    ]);
    setIsLoading(false);
  };

  const _renderActiveView = () => {
    switch (activeView) {
      case "product-categories": {
        return (
          <ProductCategoryListBrowser
            switchToCartSummary={switchToCartSummary}
            browseProducts={(selectedCategory: any) => {
              setSelectedCategory(selectedCategory);
              setActiveView("product-list");
            }}
          />
        );
      }
      case "product-list": {
        return (
          <ProductListViewer
            selectedCategory={selectedCategory}
            browseCategories={() => setActiveView("product-categories")}
            onCategoryUpdate={(selectedCategory: any) => setSelectedCategory(selectedCategory)}
            onProductSelection={(product: any) => {
              setSelectedProduct(product);
              setActiveView("product-viewer");
            }}
            selectedImage={selectedImage}
          />
        );
      }
      case "product-viewer": {
        return (
          <ProductItemViewer
            selectedProduct={selectedProduct}
            browseProducts={() => setActiveView("product-list")}
            switchToCartSummary={switchToCartSummary}
            addToCart={(specs: any) => _addToCart(specs)}
          />
        );
      }
    }
  };

  if (isLoading) {
    return (
      <div className="cartLoaderWrapper">
        <div className="loader"></div>
      </div>
    );
  } else {
    return (
      <>
        <div className="productBrowserOuterWrapper">{_renderActiveView()}</div>
      </>
    );
  }
};

export default inject("cartStore", "sessionStore")(observer(ProductBrowser));
