import { observable, action, makeObservable, computed } from "mobx";
import { create, persist } from "mobx-persist";
import axios, { AxiosResponse, AxiosError } from "axios";
import setAxiosDefaults from "../components/axios-config";
import { setAxiosLocation } from "../components/axios-config";
import ScheduleStore from "./ScheduleStore";
import ClientStore from "./ClientStore";
import UserStore from "./UserStore";
import NotificationStore from "./NotificationStore";
import TaskStore from "./TaskStore";
import AlertStore from "./AlertStore";
import PhoneCallStore from "./PhoneCallStore";
import type { CompanySettings, CompanyInfo, LocationInfo, AuthLocation } from "interfaces/auth.int";
import InvoiceStore from "./InvoiceStore";
import SessionStore from "./SessionStore";
export class AuthStoreClass {
  @persist isAuthenticated: boolean = false;
  authErrorMessage: string = "";
  isHydrated = false;
  toggleLocalStorage = false;
  isLoading = false;
  isNavOpen: boolean = false;
  IsEnterprise: boolean | undefined = false;
  @persist accountName: string = "";
  @persist userName: string = "";
  @persist isDetailedLogging: boolean = false;
  @persist("object") authenticatedUser: any = {};
  @persist("object") companySettings: CompanySettings = {};
  @persist("object") companyInfo: CompanyInfo = {};
  @persist("list") locations: Array<AuthLocation> = [];
  @persist locationNo: string = "AllLocations";

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      isAuthenticated: observable,
      authErrorMessage: observable,
      accountName: observable,
      userName: observable,
      authenticatedUser: observable,
      isHydrated: observable,
      toggleLocalStorage: observable,
      companySettings: observable,
      companyInfo: observable,
      setToggleLocalStorage: action,
      isDetailedLogging: observable,
      setToggleDetailedLogging: action,
      getCompanySettings: action,
      getCompanyInfo: action,
      sendSupport: action,
      isNavOpen: observable,
      setIsNavToggle: action,
      setIsNavOpen: action,
      login: action,
      logout: action,
      updatePassword: action,
      resetPassword: action,
      getLocations: action,
      locations: observable,
      sortedLocations: computed,
      setLocation: action,
      locationNo: observable,
      IsEnterprise: observable,
    });
  }

  async login(accountName: string, userName: string, password: string) {
    this.isLoading = true;

    return await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/${accountName}/AuthenticatedUser?userName=${userName}`,
      headers: {
        UserPassword: password,
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.isAuthenticated = true;
          this.accountName = accountName;
          this.authenticatedUser = res.data;
          this.userName = userName;
          this.isLoading = false;
          setAxiosDefaults(this.accountName, this.authenticatedUser.AuthToken);
          ScheduleStore.init();
          UserStore.getUsersList();
          ClientStore.getClientStatuses();
          SessionStore.getSessionTypes();
          SessionStore.getSessionStatus();
          ScheduleStore.getResourceList();
          TaskStore.getTaskTypes();
          PhoneCallStore.getPhoneCallTypes();
          PhoneCallStore.getDepartments();
          NotificationStore.getNotificationList();
          InvoiceStore.getInvoiceStatuses();
          InvoiceStore.getRenderProfiles();
          TaskStore.getTaskStatuses();
          this.getCompanySettings();
          this.getLocations();
          this.getCompanyInfo();
          sessionStorage.setItem("authenticated", "true");
        })
      )
      .catch(
        action((err: AxiosError) => {
          this.isAuthenticated = false;

          setTimeout(
            action(() => {
              this.isLoading = false;
              if (err.response?.status === 401) {
                this.authErrorMessage = "Invalid Account Name, Username, or Password";
              } else if (err.response?.status === 404) {
                this.authErrorMessage = "Account Not Found";
              } else if (err.response?.status === 400) {
                this.authErrorMessage = "Incorrect Username or Password";
              }
            }),
            1000
          );
          setTimeout(
            action(() => {
              this.authErrorMessage = "";
            }),
            3000
          );
        })
      );
  }

  setLocation(locationNumber: string) {
    // this.getCompanySettings();
    setAxiosLocation(locationNumber);
    this.locationNo = locationNumber;
    ScheduleStore.init();
    UserStore.getUsersList();
    TaskStore.getTaskTypes();
    ClientStore.getClientStatuses();
    SessionStore.getSessionTypes();
    SessionStore.getSessionStatus();
    ScheduleStore.getResourceList();
    PhoneCallStore.getPhoneCallTypes();
    PhoneCallStore.getDepartments();
    NotificationStore.getNotificationList();
    InvoiceStore.getInvoiceStatuses();
    InvoiceStore.getRenderProfiles();
    TaskStore.getTaskStatuses();
    this.getLocations();
    this.getCompanyInfo();
  }

  setToggleLocalStorage() {
    this.toggleLocalStorage = !this.toggleLocalStorage;
  }

  setToggleDetailedLogging(action?: boolean) {
    this.isDetailedLogging = action ? action : !this.isDetailedLogging;
  }

  setIsNavToggle() {
    this.isNavOpen = !this.isNavOpen;
  }

  setIsNavOpen(toggle: boolean = false) {
    this.isNavOpen = toggle;
  }

  async sendSupport(RequestType: "Suggestion" | "BugReport", Message: string) {
    const data = { RequestType, Message };
    return await axios({
      method: "PUT",
      url: "/MobileSupportRequest",
      data,
    })
      .then(
        action((res: AxiosResponse) => {
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        throw new Error(err.message);
      });
  }

  async updatePassword(formValues: { OldPassword: string; NewPassword: string }) {
    return await axios({
      method: "PUT",
      url: "/UserPassword",
      headers: formValues,
      data: {
        UserKey: AuthStore.authenticatedUser.UserKey,
      },
    })
      .then(() => {
        AlertStore.setAlertMessage("Password successfully updated", "success");
      })
      .catch((err: AxiosError) => {
        AlertStore.setAlertMessage(err?.response?.data, "error");
      });
  }
  async resetPassword(NewPassword: string, accountName: string, PasswordResetGuid: string) {
    this.isLoading = true;
    return await axios({
      method: "PUT",
      baseURL: `${process.env.REACT_APP_BASE_URL}`,
      url: `/${accountName}/resetPassword`,
      data: {
        NewPassword,
        PasswordResetGuid,
      },
    })
      .then(() => {
        this.isLoading = false;

        AlertStore.setAlertMessage("Password successfully reset", "success");
      })
      .catch((err: AxiosError) => {
        this.isLoading = false;

        AlertStore.setAlertMessage(err?.response?.data, "error");
      });
  }

  async getCompanySettings() {
    return await axios({
      method: "GET",
      url: "/preferences",
    })
      .then((res: AxiosResponse) => {
        this.companySettings = res.data;
        this.IsEnterprise = this.companySettings.IsEnterprise;
      })
      .catch((err: AxiosError) => {
        AlertStore.setAlertMessage(err?.response?.data, "error");
      });
  }

  async getLocations() {
    this.locations = this.authenticatedUser.AuthorizedLocations;
    console.log(this.authenticatedUser.AuthorizedLocations);
  }
  // async getLocations() {
  //   return await axios({
  //     method: "GET",
  //     url: "/Location",
  //   })
  //     .then((res: AxiosResponse) => {
  //       this.locations = res.data;
  //     })
  //     .catch((err: AxiosError) => {
  //       AlertStore.setAlertMessage(err?.response?.data, "error");
  //     });
  // }

  get sortedLocations() {
    return this.locations?.map((v: AuthLocation) => {
      return {
        label: v.Name,
        value: v.LocationNumber,
      };
    });
  }

  async getCompanyInfo() {
    return await axios({
      method: "GET",
      url: "/ibyCompanyInfo",
    })
      .then((res: AxiosResponse) => {
        this.companyInfo = res.data;
      })
      .catch((err: AxiosError) => {
        AlertStore.setAlertMessage(err?.response?.data, "error");
      });
  }

  async logout() {
    this.isAuthenticated = false;
    this.authenticatedUser = {};
    this.userName = "";
    this.locationNo = "";
    NotificationStore.cleanUp();
    ClientStore.clearLists();
    UserStore.cleanUp();
    SessionStore.cleanUp();
    InvoiceStore.cleanUp();
    setAxiosDefaults("", "");
    setAxiosLocation("");
    sessionStorage.removeItem("authenticated");
  }
}

const hydrate = create({});

const AuthStore = new AuthStoreClass();
export default AuthStore;

hydrate("auth", AuthStore).then((AuthStore) => {
  AuthStore.isHydrated = true;
  setAxiosDefaults(AuthStore.accountName, AuthStore.authenticatedUser.AuthToken);
  setAxiosLocation(AuthStore.locationNo);
});
