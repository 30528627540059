import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Grid, Modal, Form, message } from "antd";
import dayjs from "dayjs";

import type { ScheduleStoreClass } from "stores/ScheduleStore";
import type { AuthStoreClass } from "stores/AuthStore";
import FilterCalendar from "components/forms/FilterCalendar";
import ActionsScheduleDesktop from "./ActionsScheduleDesktop";
import ActionsScheduleMobile from "./ActionsScheduleMobile";
import ActionsCalendarYear from "./ActionsCalendarYear";
import { saveLocalStorage } from "components/util/localStorage";
import CustomHeader from "components/header/CustomHeader";

interface Props {
  scheduleStore?: ScheduleStoreClass;
  authStore?: AuthStoreClass;
  drawer?: any;
}

const { useBreakpoint } = Grid;

const ActionsSchedule: React.FC<Props> = ({
  scheduleStore,
  authStore,
  drawer,
}: Props): React.ReactElement => {
  const [isResync, setIsResync] = useState<boolean>(false);
  const [isShowFilterModal, setIsShowFilterModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const screens = useBreakpoint();

  const handleResync = async () => {
    const loadingMsgKey = "message-key";
    setIsResync(true);
    scheduleStore?.setIsShowTimeSelect("month");
    message.loading({ content: "Syncing...", key: loadingMsgKey, duration: 0 });

    form?.setFieldsValue({
      userKey: authStore?.authenticatedUser?.UserKey,
    });

    try {
      await scheduleStore
        ?.getCalendarList(scheduleStore?.filterUserKey, scheduleStore?.filterResourceKey)
        .then(() => {
          message.success({
            content: "Successfully resynced!",
            key: loadingMsgKey,
            duration: 1,
          });
          saveLocalStorage({
            name: "Synced",
            value: "Schedules",
            url: "/schedule",
          });
          setIsResync(false);
        });
    } catch (e) {
      message.error({
        content: "Unable to fetch schedules. Try logging in again.",
        key: loadingMsgKey,
        duration: 1,
      });
      setIsResync(false);
    }
  };

  //handle dayView here
  const handleCalendarViewDay = () => {
    scheduleStore?.setIsShowTimeSelect("day");
    scheduleStore?.setCalendarActiveDate(scheduleStore?.calendarActiveDate);
  };
  const handleCalendarViewWeek = () => {
    scheduleStore?.setIsShowTimeSelect("week");
    scheduleStore?.setCalendarActiveDate(scheduleStore?.calendarActiveDate);
  };

  const handleCalendarViewMonth = () => {
    scheduleStore?.setCalendarMode("month");
    scheduleStore?.setIsShowTimeSelect("month");
    scheduleStore?.setCalendarActiveDate(scheduleStore?.calendarActiveDate);
  };

  const handleCalendarViewYear = () => {
    scheduleStore?.setCalendarMode("year");
    scheduleStore?.setIsShowTimeSelect("year");
    scheduleStore?.setCalendarActiveDate(scheduleStore?.calendarActiveDate);
  };

  const toggleFilterModal = () => {
    setIsShowFilterModal(!isShowFilterModal);
  };

  const handleSubmitFilter = () => {
    form.submit();
    setIsShowFilterModal(false);
  };

  const handleCalendarToday = () => {
    scheduleStore?.setCalendarActiveDate(dayjs());
    scheduleStore?.setActiveDate(dayjs().format());
  };

  const handleOnClick = (action: string) => {
    switch (action) {
      case "handleResync":
        handleResync();
        break;
      case "toggleFilterModal":
        toggleFilterModal();
        break;
      case "handleCalendarViewDay":
        handleCalendarViewDay();
        break;
      case "handleCalendarViewWeek":
        handleCalendarViewWeek();
        break;
      case "handleCalendarViewMonth":
        handleCalendarViewMonth();
        break;
      case "handleCalendarViewYear":
        handleCalendarViewYear();
        break;
      case "handleCalendarToday":
        handleCalendarToday();
        break;
    }
  };

  return (
    <>
      {!drawer ? (
        <CustomHeader>
          {screens.md && (
            <Col>
              <ActionsCalendarYear />
            </Col>
          )}
          <Col style={{ marginLeft: "auto" }}>
            {screens.lg ? (
              <ActionsScheduleDesktop isResync={isResync} handleOnClick={handleOnClick} />
            ) : (
              <ActionsScheduleMobile isResync={isResync} handleOnClick={handleOnClick} />
            )}
          </Col>
        </CustomHeader>
      ) : (
        <>
          <Row justify="space-between" align="middle">
            {screens.md && (
              <Col>
                <ActionsCalendarYear />
              </Col>
            )}
            <Col style={{ marginLeft: "auto" }}>
              {screens.lg ? (
                <ActionsScheduleDesktop isResync={isResync} handleOnClick={handleOnClick} />
              ) : (
                <ActionsScheduleMobile isResync={isResync} handleOnClick={handleOnClick} />
              )}
            </Col>
          </Row>
        </>
      )}

      <Modal
        title="Filter by:"
        visible={isShowFilterModal}
        onCancel={toggleFilterModal}
        onOk={handleSubmitFilter}
      >
        <FilterCalendar form={form} />
      </Modal>
    </>
  );
};

export default inject("scheduleStore", "authStore")(observer(ActionsSchedule));
