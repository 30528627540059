import axios, {  AxiosError } from "axios";
import AlertStore from "stores/AlertStore";

export const getPriceList = async (key: string = "00-000-10004") => {
  try {
    const response = await axios({
      method: "GET",
      url: `/ibyPriceList/${key}`
    });
    return response?.data;
  } catch (error) {

  }
}

export const getColorization = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `/ibyColorization`
    });
    return response?.data;
  } catch (error) {

  }
}

export const getCart = async (sessionKey: string, invoiceKey: string, clientKey: string = "00-000-10103") => {
  try {
    const response = await axios({
      method: "GET",
      url: `/IBYInvoice/${invoiceKey}`,
      params: {
        SessionKey: sessionKey,
        clientKey,
      }
    });
    return response?.data;
  } catch (error) {

  }
}

export const addItemToCart = async (item: any, sessionKey: string,  invoiceKey: string, clientKey: string = "00-000-10103") => {
  try {
    const response = await axios({
      method: "PUT",
      url: `/ibyInvoiceLineItem`,
      params: {
        SessionKey: sessionKey,
        clientKey,
      },
      data: {
        ...item,
        InvoiceKey: invoiceKey,
        SessionKey: sessionKey,
      }
    });
    return response?.data;
  } catch (error) {

  }
}


export const updateCartItem = async (item: any, sessionKey: string,  invoiceKey: string, clientKey: string = "00-000-10103") => {
  try {
    const response = await axios({
      method: "PUT",
      url: `/ibyInvoiceLineItem`,
      params: {
        SessionKey: sessionKey,
        clientKey,
      },
      data: {
        ...item,
        InvoiceKey: invoiceKey,
        SessionKey: sessionKey,
      }
    });
    return response?.data;
  } catch (error) {

  }
}

export const removeCartItem = async (itemKey: any, invoiceKey: string) => {
  try {
    const response = await axios({
      method: "DELETE",
      url: `/ibyInvoiceLineItem/${itemKey}`,
      params: {
        invoiceKey: invoiceKey,
      },
    });
    return response?.data;
  } catch (error) {

  }
}

export const addPromoCodeAPI = async (promoCode: string, sessionKey: string, InvoiceKey: string, clientKey: string = "00-000-10103") => {
  try {
    const response = await axios({
      method: "PUT",
      url: `/ibyInvoicePromo`,
      params: {
        SessionKey: sessionKey,
        clientKey: "00-000-10103",
      },
      data: {
        promoCode: promoCode,
        InvoiceKey: InvoiceKey,
      },
    });
    return response?.data;
  } catch (error) {
    console.log('addPromoCode error :>> ', error);
    throw new Error();
  }
}

export const deletePromoCodeAPI = async (promoCode: string, sessionKey: string,  invoiceKey: string) => {
  try {
    const response = await axios({
      method: "DELETE",
      url: `/ibyInvoicePromo`,
      params: {
        SessionKey: sessionKey,
        promoCode: promoCode,
        invoiceKey: invoiceKey,
      }
    });
    return response?.data;
  } catch (error) {
    console.log('deletePromoCode error :>> ', error);
    AlertStore.setAlertMessage("Something went wrong", "error")
    
  }
}

export const fetchInvoiceKey = async (sessionKey: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `/Invoices?sessionKey=${sessionKey}`
    });
    return response?.data;
  } catch (error) {
    console.log('fetchInvoiceKey error :>> ', error);
    throw new Error();
  }
}