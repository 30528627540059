import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import {
  Drawer,
  Tabs,
  Modal,
  Row,
  Col,
  Typography,
  Space,
  Button,
  Form,
  Card,
  Grid,
  Skeleton,
  List,
  message,
  Checkbox,
  Dropdown,
} from "antd";

import AddEditClient from "../forms/AddEditClient";
import FamilyList from "components/lists/FamilyList";
import TasksList from "components/lists/TasksList";
import TaskDrawerForm from "components/drawers/TaskDrawerForm";
import AppointmentsList from "components/lists/AppointmentsList";
import AppointmentDrawerForm from "components/drawers/AppointmentDrawerForm";
import PhoneCallsList from "components/lists/PhoneCallsList";
import PhoneCallDrawerForm from "components/drawers/PhoneCallDrawerForm";
import MessagesList from "components/lists/MessagesList";
import MessageDrawerView from "components/drawers/MessageDrawerView";
import SessionsList from "components/lists/SessionsList";
import SessionDrawerForm from "components/drawers/SessionDrawerForm";
import type { ClientStoreClass } from "stores/ClientStore";
import type { DrawerProps } from "antd/lib/drawer";
import useWindowDimension from "components/util/Dimension";
import { DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import { InvoiceStoreClass } from "stores/InvoiceStore";
import { TaskStoreClass } from "stores/TaskStore";
import { AppointmentStoreClass } from "stores/AppointmentStore";
import { PhoneCallStoreClass } from "stores/PhoneCallStore";
import { MessageStoreClass } from "stores/MessageStore";
import { SessionStoreClass } from "stores/SessionStore";
import { AuthStoreClass } from "stores/AuthStore";
import InvoiceDrawerView from "components/drawers/InvoiceDrawerView/InvoiceDrawerView";
import type { InvoiceList } from "interfaces/invoice.int";
import RenderInvoice from "components/invoice/RenderInvoice";
import { ClientCustomFields } from "interfaces/client.int";
import InputField from "components/forms/items/InputField";
import DateField from "components/forms/items/DateField";
import "./ClientTabs.scss";
import dayjs from "dayjs";
const { TabPane } = Tabs;
const { Title } = Typography;
const { useBreakpoint } = Grid;

interface Props {
  clientStore?: ClientStoreClass;
  invoiceStore?: InvoiceStoreClass;
  taskStore?: TaskStoreClass;
  appointmentStore?: AppointmentStoreClass;
  phoneCallStore?: PhoneCallStoreClass;
  messageStore?: MessageStoreClass;
  sessionStore?: SessionStoreClass;
  defaultActiveTab?: any;
  upcomingSession?: boolean;
  authStore?: AuthStoreClass;
  sessionKey?: string;
}

const ClientTabs: React.FC<Props> = ({
  clientStore,
  invoiceStore,
  taskStore,
  appointmentStore,
  phoneCallStore,
  messageStore,
  sessionStore,
  defaultActiveTab,
  upcomingSession,
  sessionKey = "",
  authStore,
}) => {
  const screens = useBreakpoint();
  const [isDeleteModalShown, setIsDeleteModalShown] = useState<boolean>(false);
  const [appointmentList, setAppointmentList] = useState({});
  const { innerWidth } = useWindowDimension();
  const [form] = Form.useForm();
  const location = useLocation();
  const [newInvoice, setNewInvoice] = useState(false);
  const [createAsession, setCreateASession] = useState(false);
  const [clientCustomFields, setClientCustomFields] = useState<ClientCustomFields>({
    CustomDateField1: "",
    CustomDateField2: "",
    CustomDateField3: "",
    CustomDateField4: "",
    CustomDateField5: "",
    CustomDateField6: "",
    CustomDateField7: "",
    CustomDateField8: "",
    CustomDateField9: "",
    CustomDateField10: "",
    CustomField01: "",
    CustomField02: "",
    CustomField03: "",
    CustomField04: "",
    CustomField05: "",
    CustomField06: "",
    CustomField07: "",
    CustomField08: "",
    CustomField09: "",
    CustomField10: "",
    CustomField11: "",
    CustomField12: "",
    CustomField13: "",
    CustomField14: "",
    CustomField15: "",
    CustomField16: "",
    CustomField17: "",
    CustomField18: "",
    CustomField19: "",
    CustomField20: "",
    CustomNotes01: "",
    CustomNotes02: "",
    CustomNotes03: "",
    CustomNotes04: "",
    CustomNotes05: "",
    CustomBoolean01: false,
    CustomBoolean02: false,
    CustomBoolean03: false,
    CustomBoolean04: false,
    CustomBoolean05: false,
    CustomBoolean06: false,
    CustomBoolean07: false,
    CustomBoolean08: false,
    CustomBoolean09: false,
    CustomBoolean10: false,
  });

  const [createOptions, setCreateOptions] = useState<any>([
    { label: "Invoice", value: "Invoice" },
    {
      label: authStore?.companySettings?.JobDescriptorSingular
        ? authStore?.companySettings?.JobDescriptorSingular
        : "Session",
      value: "Session",
    },
    { label: "Appointment", value: "Appointment" },
    { label: "Task", value: "Task" },
    { label: "Call", value: "Call" },
  ]);

  useEffect(() => {
    setClientCustomFields({
      CustomDateField1:
        Number(dayjs(clientStore?.clientInfo?.CustomDateField1).format("YYYY")) > 2000
          ? clientStore?.clientInfo?.CustomDateField1
          : dayjs(new Date()).toString(),
      CustomDateField2:
        Number(dayjs(clientStore?.clientInfo?.CustomDateField2).format("YYYY")) > 2000
          ? clientStore?.clientInfo?.CustomDateField2
          : dayjs(new Date()).toString(),
      CustomDateField3:
        Number(dayjs(clientStore?.clientInfo?.CustomDateField3).format("YYYY")) > 2000
          ? clientStore?.clientInfo?.CustomDateField3
          : dayjs(new Date()).toString(),
      CustomDateField4:
        Number(dayjs(clientStore?.clientInfo?.CustomDateField4).format("YYYY")) > 2000
          ? clientStore?.clientInfo?.CustomDateField4
          : dayjs(new Date()).toString(),
      CustomDateField5:
        Number(dayjs(clientStore?.clientInfo?.CustomDateField5).format("YYYY")) > 2000
          ? clientStore?.clientInfo?.CustomDateField5
          : dayjs(new Date()).toString(),
      CustomDateField6:
        Number(dayjs(clientStore?.clientInfo?.CustomDateField6).format("YYYY")) > 2000
          ? clientStore?.clientInfo?.CustomDateField6
          : dayjs(new Date()).toString(),
      CustomDateField7:
        Number(dayjs(clientStore?.clientInfo?.CustomDateField7).format("YYYY")) > 2000
          ? clientStore?.clientInfo?.CustomDateField7
          : dayjs(new Date()).toString(),
      CustomDateField8:
        Number(dayjs(clientStore?.clientInfo?.CustomDateField8).format("YYYY")) > 2000
          ? clientStore?.clientInfo?.CustomDateField8
          : dayjs(new Date()).toString(),
      CustomDateField9:
        Number(dayjs(clientStore?.clientInfo?.CustomDateField9).format("YYYY")) > 2000
          ? clientStore?.clientInfo?.CustomDateField9
          : dayjs(new Date()).toString(),
      CustomDateField10:
        Number(dayjs(clientStore?.clientInfo?.CustomDateField10).format("YYYY")) > 2000
          ? clientStore?.clientInfo?.CustomDateField10
          : dayjs(new Date()).toString(),
      CustomField01: clientStore?.clientInfo?.CustomField01,
      CustomField02: clientStore?.clientInfo?.CustomField02,
      CustomField03: clientStore?.clientInfo?.CustomField03,
      CustomField04: clientStore?.clientInfo?.CustomField04,
      CustomField05: clientStore?.clientInfo?.CustomField05,
      CustomField06: clientStore?.clientInfo?.CustomField06,
      CustomField07: clientStore?.clientInfo?.CustomField07,
      CustomField08: clientStore?.clientInfo?.CustomField08,
      CustomField09: clientStore?.clientInfo?.CustomField09,
      CustomField10: clientStore?.clientInfo?.CustomField10,
      CustomField11: clientStore?.clientInfo?.CustomField11,
      CustomField12: clientStore?.clientInfo?.CustomField12,
      CustomField13: clientStore?.clientInfo?.CustomField13,
      CustomField14: clientStore?.clientInfo?.CustomField14,
      CustomField15: clientStore?.clientInfo?.CustomField15,
      CustomField16: clientStore?.clientInfo?.CustomField16,
      CustomField17: clientStore?.clientInfo?.CustomField17,
      CustomField18: clientStore?.clientInfo?.CustomField18,
      CustomField19: clientStore?.clientInfo?.CustomField19,
      CustomField20: clientStore?.clientInfo?.CustomField20,
      CustomNotes01: clientStore?.clientInfo?.CustomNotes01,
      CustomNotes02: clientStore?.clientInfo?.CustomNotes02,
      CustomNotes03: clientStore?.clientInfo?.CustomNotes03,
      CustomNotes04: clientStore?.clientInfo?.CustomNotes04,
      CustomNotes05: clientStore?.clientInfo?.CustomNotes05,
      CustomBoolean01: clientStore?.clientInfo?.CustomBoolean01,
      CustomBoolean02: clientStore?.clientInfo?.CustomBoolean02,
      CustomBoolean03: clientStore?.clientInfo?.CustomBoolean03,
      CustomBoolean04: clientStore?.clientInfo?.CustomBoolean04,
      CustomBoolean05: clientStore?.clientInfo?.CustomBoolean05,
      CustomBoolean06: clientStore?.clientInfo?.CustomBoolean06,
      CustomBoolean07: clientStore?.clientInfo?.CustomBoolean07,
      CustomBoolean08: clientStore?.clientInfo?.CustomBoolean08,
      CustomBoolean09: clientStore?.clientInfo?.CustomBoolean09,
      CustomBoolean10: clientStore?.clientInfo?.CustomBoolean10,
    });
  }, [clientStore?.clientInfo]);

  useEffect(() => {
    return () => {
      setNewInvoice(false);
    };
  }, [newInvoice]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientStore?.isSelectToggle]);

  useEffect(() => {
    if (clientStore?.clientInfo?.ClientNumber) {
      invoiceStore?.getInvoiceList({
        action: "ClientNumber",
        value: clientStore?.clientInfo.ClientNumber,
      });
    }
  }, [clientStore?.clientInfo?.ClientNumber]);

  useEffect(() => {
    const getClientLists = async () => {
      if (
        clientStore?.clientInfo?.Key &&
        clientStore?.clientInfo?.ClientNumber &&
        clientStore?.isSelectToggle
      ) {
        invoiceStore?.getInvoiceList({
          action: "ClientNumber",
          value: clientStore?.clientInfo.ClientNumber,
        });
        clientStore?.getFamilyMembers(clientStore?.activeClientKey);
        if (!location.pathname.includes("task")) {
          taskStore?.getTasks(clientStore?.activeClientKey);
        }
        if (!location.pathname.includes("schedule")) {
          const appointments = await appointmentStore?.getAppointmentList(
            clientStore?.activeClientKey
          );
          setAppointmentList(appointments);
          sessionStore?.getSessionList(clientStore?.activeClientKey);
        }
        if (!location.pathname.includes("calls")) {
          phoneCallStore?.getPhoneCallList(undefined, clientStore.activeClientKey);
        }
        if (!location.pathname.includes("messages")) {
          messageStore?.getMessages(clientStore?.activeClientKey);
        }
      }
    };
    getClientLists();
  }, [clientStore?.clientInfo?.Key]);

  const toggleDrawer = (toggle: boolean) => {
    clientStore?.setSelectToggle(toggle);
    setCreateASession(false);
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalShown(!isDeleteModalShown);
  };

  const handleDelete = async () => {
    clientStore
      ?.deleteClient(clientStore?.activeClientKey)
      .then((data: any) => {
        setIsDeleteModalShown(false);
        toggleDrawer(false);
        message.success("Deleted Client");
        clientStore?.getClients();
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleSuccess = async () => {};

  const handleError = () => {};

  const items = [
    {
      label: "Call",
      key: "Call",
    },
    {
      label: "Appointment",
      key: "Appointment",
    },
    {
      label: "Session",
      key: "Session",
    },
    {
      label: "Task",
      key: "Task",
    },
    {
      label: "Invoice",
      key: "Invoice",
    },
  ];

  const handleClientAction = (option: any) => {
    switch (option.key) {
      case "Call":
        phoneCallStore?.setSelectToggle(true);
        setCreateASession(false);
        break;
      case "Task":
        taskStore?.setSelectToggle(true);
        setCreateASession(false);
        break;
      case "Appointment":
        appointmentStore?.setSelectToggle(true);
        setCreateASession(false);
        break;
      case "Session":
        setCreateASession(true);
        sessionStore?.setSelectToggle(true);
        break;
      case "Invoice":
        invoiceStore?.addInvoice({ ClientKey: clientStore?.activeClientKey });
        invoiceStore?.setCreatingNew(true);
        invoiceStore?.setDrawerType("detail");
        invoiceStore?.setSelectToggle(true);
        setNewInvoice(true);
        setCreateASession(false);
        break;
      default:
        phoneCallStore?.setSelectToggle(false);
        taskStore?.setSelectToggle(false);
        appointmentStore?.setSelectToggle(false);
        invoiceStore?.setSelectToggle(false);
        sessionStore?.setSelectToggle(false);
        setCreateASession(false);
        break;
    }
  };

  const menuProps = {
    items,
    onClick: handleClientAction,
  };

  useEffect(() => {
    if (location.pathname.includes("tasks")) {
      setCreateOptions([
        { label: "Invoice", value: "Invoice" },
        {
          label: authStore?.companySettings?.JobDescriptorSingular
            ? authStore?.companySettings?.JobDescriptorSingular
            : "Session",
          value: "Session",
        },
        { label: "Appointment", value: "Appointment" },
        { label: "Call", value: "Call" },
      ]);
    }
    if (location.pathname.includes("calls")) {
      setCreateOptions([
        { label: "Invoice", value: "Invoice" },
        {
          label: authStore?.companySettings?.JobDescriptorSingular
            ? authStore?.companySettings?.JobDescriptorSingular
            : "Session",
          value: "Session",
        },
        { label: "Appointment", value: "Appointment" },
        { label: "Task", value: "Task" },
      ]);
    }
  }, [location.pathname]);

  const ModalHeader = () => {
    return (
      <Row>
        <Col xs={8}>
          <Title level={2}>Client</Title>
        </Col>
        <Col
          xs={16}
          style={{
            textAlign: "right",
          }}
        >
          <Space>
            {clientStore?.activeClientKey && (
              <Button
                style={{ backgroundColor: "#8E3B46", borderColor: "#8E3B46", color: "white" }}
                icon={<DeleteOutlined />}
                disabled={clientStore?.isLoading}
                type="text"
                className="btn-action"
                onClick={toggleDeleteModal}
              >
                Delete
              </Button>
            )}
            <Button
              icon={<CheckOutlined />}
              className="btn-action"
              style={{ backgroundColor: "rgb(255, 179, 28)", borderColor: "white", color: "white" }}
              shape="round"
              onClick={() => form.validateFields().then(() => form.submit())}
            >
              Save
            </Button>

            {clientStore?.activeClientKey && (
              <Dropdown menu={menuProps} trigger={["click"]}>
                <Button
                  className="btn-action"
                  style={{
                    backgroundColor: "#708D65",
                    borderColor: "#708D65",
                    color: "white",
                  }}
                >
                  New
                </Button>
              </Dropdown>
            )}
          </Space>
        </Col>
      </Row>
    );
  };

  const drawerParams: DrawerProps = {
    width: screens.xs ? innerWidth : innerWidth - 250,
    placement: "right",
    closable: true,
    forceRender: false,
    destroyOnClose: true,
    title: <ModalHeader />,
    maskStyle: {},
    className: "drawer-form clientDrawer",
    push: {
      distance: 20,
    },
  };

  return (
    <>
      <Drawer
        onClose={() => toggleDrawer(false)}
        visible={clientStore?.isSelectToggle}
        {...drawerParams}
      >
        <Tabs
          className="client-tabs"
          defaultActiveKey={defaultActiveTab || "1"}
          type="card"
          size={"small"}
        >
          <TabPane tab="Basic Info" key="1">
            <AddEditClient
              form={form}
              onSuccess={handleSuccess}
              onError={handleError}
              clientCustomFields={clientCustomFields}
            />
          </TabPane>
          <TabPane tab="Family" key="2">
            <FamilyList />
          </TabPane>
          {!location.pathname.includes("schedule") && (
            <TabPane
              tab={
                authStore?.companySettings?.JobDescriptorPlural
                  ? authStore?.companySettings?.JobDescriptorPlural
                  : "Sessions"
              }
              key="3"
            >
              <Card bordered={screens.md} className="card-listing">
                <SessionsList />
              </Card>
              <SessionDrawerForm />
            </TabPane>
          )}
          <TabPane tab="Invoices" key="4">
            <div
              className={classnames({
                "main-content-pads": screens.md,
              })}
            >
              <Card bordered={screens.md} className="card-listing">
                <Skeleton loading={invoiceStore?.isLoading} active>
                  <List
                    dataSource={invoiceStore?.invoiceList}
                    renderItem={(item: InvoiceList) => (
                      <RenderInvoice
                        setNewInvoice={setNewInvoice}
                        item={item}
                        openInvoiceDetails={true}
                      />
                    )}
                    bordered
                    style={{ border: 0 }}
                  />
                </Skeleton>
              </Card>
            </div>
            {/* <InvoiceTable
              defaultActiveTab={defaultActiveTab}
              InvoiceList={invoiceStore?.invoiceList || []}
            /> */}
          </TabPane>
          {!location.pathname.includes("schedule") && (
            <TabPane tab="Appointments" key="5">
              <Card bordered={screens.md} className="card-listing">
                <AppointmentsList />
              </Card>
              <AppointmentDrawerForm />
            </TabPane>
          )}
          {!location.pathname.includes("tasks") && (
            <TabPane tab="Tasks" key="6">
              <Card bordered={screens.md} className="card-listing">
                <TasksList />
              </Card>
              <TaskDrawerForm />
            </TabPane>
          )}
          {!location.pathname.includes("calls") && (
            <TabPane tab="Calls" key="7">
              <Card bordered={screens.md} className="card-listing">
                <PhoneCallsList />
              </Card>
              <PhoneCallDrawerForm />
            </TabPane>
          )}

          {!location.pathname.includes("messages") && (
            <TabPane tab="Messages" key="8">
              <Card bordered={screens.md} className="card-listing">
                <MessagesList />
              </Card>
              <MessageDrawerView />
            </TabPane>
          )}
          <TabPane tab="Custom" key="9">
            <Row className="CustomFields">
              <Col xs={24} sm={12}>
                <Col xs={24}>
                  <InputField
                    label="did you check the sms box"
                    value={clientCustomFields.CustomField01}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField01: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="how did you hear about us"
                    value={clientCustomFields.CustomField02}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField02: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="are the existing customer"
                    value={clientCustomFields.CustomField03}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField03: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 4"
                    value={clientCustomFields.CustomField04}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField04: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 5"
                    value={clientCustomFields.CustomField05}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField05: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 6"
                    value={clientCustomFields.CustomField06}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField06: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 7"
                    value={clientCustomFields.CustomField07}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField07: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 8"
                    value={clientCustomFields.CustomField08}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField08: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 9"
                    value={clientCustomFields.CustomField09}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField09: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 10"
                    value={clientCustomFields.CustomField10}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField10: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 11"
                    value={clientCustomFields.CustomField11}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField11: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 12"
                    value={clientCustomFields.CustomField12}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField12: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 13"
                    value={clientCustomFields.CustomField13}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField13: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 14"
                    value={clientCustomFields.CustomField14}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField14: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 15"
                    value={clientCustomFields.CustomField15}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField15: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 16"
                    value={clientCustomFields.CustomField16}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField16: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 17"
                    value={clientCustomFields.CustomField17}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField17: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 18"
                    value={clientCustomFields.CustomField18}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField18: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 19"
                    value={clientCustomFields.CustomField19}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField19: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 20"
                    value={clientCustomFields.CustomField20}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomField20: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <DateField
                    label="Custom Date 1"
                    suffixIcon={<></>}
                    allowClear={false}
                    value={dayjs(clientCustomFields.CustomDateField1)}
                    onChange={(e: any) => {
                      setClientCustomFields({ ...clientCustomFields, CustomDateField1: e.$d });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <DateField
                    label="Custom Date 2"
                    suffixIcon={<></>}
                    allowClear={false}
                    value={dayjs(clientCustomFields.CustomDateField2)}
                    onChange={(e: any) => {
                      setClientCustomFields({ ...clientCustomFields, CustomDateField2: e.$d });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <DateField
                    label="Custom Date 3"
                    suffixIcon={<></>}
                    allowClear={false}
                    value={dayjs(clientCustomFields.CustomDateField3)}
                    onChange={(e: any) => {
                      setClientCustomFields({ ...clientCustomFields, CustomDateField3: e.$d });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <DateField
                    label="Custom Date 4"
                    suffixIcon={<></>}
                    allowClear={false}
                    value={dayjs(clientCustomFields.CustomDateField4)}
                    onChange={(e: any) => {
                      setClientCustomFields({ ...clientCustomFields, CustomDateField4: e.$d });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <DateField
                    label="Custom Date 5"
                    suffixIcon={<></>}
                    allowClear={false}
                    value={dayjs(clientCustomFields.CustomDateField5)}
                    onChange={(e: any) => {
                      setClientCustomFields({ ...clientCustomFields, CustomDateField5: e.$d });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <DateField
                    label="Custom Date 6"
                    suffixIcon={<></>}
                    allowClear={false}
                    value={dayjs(clientCustomFields.CustomDateField6)}
                    onChange={(e: any) => {
                      setClientCustomFields({ ...clientCustomFields, CustomDateField6: e.$d });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <DateField
                    label="Custom Date 7"
                    suffixIcon={<></>}
                    allowClear={false}
                    value={dayjs(clientCustomFields.CustomDateField7)}
                    onChange={(e: any) => {
                      setClientCustomFields({ ...clientCustomFields, CustomDateField7: e.$d });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <DateField
                    label="Custom Date 8"
                    suffixIcon={<></>}
                    allowClear={false}
                    value={dayjs(clientCustomFields.CustomDateField8)}
                    onChange={(e: any) => {
                      setClientCustomFields({ ...clientCustomFields, CustomDateField8: e.$d });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <DateField
                    label="Custom Date 9"
                    suffixIcon={<></>}
                    allowClear={false}
                    value={dayjs(clientCustomFields.CustomDateField9)}
                    onChange={(e: any) => {
                      setClientCustomFields({ ...clientCustomFields, CustomDateField9: e.$d });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <DateField
                    label="Custom Date 10"
                    suffixIcon={<></>}
                    allowClear={false}
                    value={dayjs(clientCustomFields.CustomDateField10)}
                    onChange={(e: any) => {
                      setClientCustomFields({ ...clientCustomFields, CustomDateField10: e.$d });
                    }}
                  />
                </Col>
              </Col>
              <Col xs={24} sm={12}>
                <Col xs={24}>
                  <InputField
                    label="Custom Notes 1"
                    isTextarea
                    value={clientCustomFields.CustomNotes01}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomNotes01: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom Notes 2"
                    isTextarea
                    value={clientCustomFields.CustomNotes02}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomNotes02: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom Notes 3"
                    isTextarea
                    value={clientCustomFields.CustomNotes03}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomNotes03: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom Notes 4"
                    isTextarea
                    value={clientCustomFields.CustomNotes04}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomNotes04: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom Notes 5"
                    isTextarea
                    value={clientCustomFields.CustomNotes05}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomNotes05: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <Checkbox
                    checked={clientCustomFields.CustomBoolean01}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomBoolean01: e.target.checked,
                      });
                    }}
                  >
                    Custom Boolean 1
                  </Checkbox>
                </Col>
                <Col xs={24}>
                  <Checkbox
                    checked={clientCustomFields.CustomBoolean02}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomBoolean02: e.target.checked,
                      });
                    }}
                  >
                    Custom Boolean 2
                  </Checkbox>
                </Col>
                <Col xs={24}>
                  <Checkbox
                    checked={clientCustomFields.CustomBoolean03}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomBoolean03: e.target.checked,
                      });
                    }}
                  >
                    Custom Boolean 3
                  </Checkbox>
                </Col>
                <Col xs={24}>
                  <Checkbox
                    checked={clientCustomFields.CustomBoolean04}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomBoolean04: e.target.checked,
                      });
                    }}
                  >
                    Custom Boolean 4
                  </Checkbox>
                </Col>
                <Col xs={24}>
                  <Checkbox
                    checked={clientCustomFields.CustomBoolean05}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomBoolean05: e.target.checked,
                      });
                    }}
                  >
                    Custom Boolean 5
                  </Checkbox>
                </Col>
                <Col xs={24}>
                  <Checkbox
                    checked={clientCustomFields.CustomBoolean06}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomBoolean06: e.target.checked,
                      });
                    }}
                  >
                    Custom Boolean 6
                  </Checkbox>
                </Col>
                <Col xs={24}>
                  <Checkbox
                    checked={clientCustomFields.CustomBoolean07}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomBoolean07: e.target.checked,
                      });
                    }}
                  >
                    Custom Boolean 7
                  </Checkbox>
                </Col>
                <Col xs={24}>
                  <Checkbox
                    checked={clientCustomFields.CustomBoolean08}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomBoolean08: e.target.checked,
                      });
                    }}
                  >
                    Custom Boolean 8
                  </Checkbox>
                </Col>
                <Col xs={24}>
                  <Checkbox
                    checked={clientCustomFields.CustomBoolean09}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomBoolean09: e.target.checked,
                      });
                    }}
                  >
                    Custom Boolean 9
                  </Checkbox>
                </Col>
                <Col xs={24}>
                  <Checkbox
                    checked={clientCustomFields.CustomBoolean10}
                    onChange={(e: any) => {
                      setClientCustomFields({
                        ...clientCustomFields,
                        CustomBoolean10: e.target.checked,
                      });
                    }}
                  >
                    Custom Boolean 10
                  </Checkbox>
                </Col>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Drawer>
      <Modal
        title={`Delete a client`}
        visible={isDeleteModalShown}
        onCancel={toggleDeleteModal}
        onOk={handleDelete}
      >
        <p>Are you sure you want to delete this client?</p>
      </Modal>
      <InvoiceDrawerView ClientKey={clientStore?.clientInfo?.Key} newSessionInvoice={false} />
      {createAsession ? <SessionDrawerForm clientKey={clientStore?.clientInfo?.Key} /> : null}
      <AppointmentDrawerForm sessionKey={sessionKey} clientKey={clientStore?.clientInfo?.Key} />
      {!location.pathname.includes("tasks") && (
        <TaskDrawerForm clientKey={clientStore?.clientInfo?.Key} />
      )}
      {!location.pathname.includes("calls") && (
        <PhoneCallDrawerForm
          clientKey={clientStore?.clientInfo?.Key}
          activeClientKey={clientStore?.activeClientKey}
        />
      )}
    </>
  );
};

export default inject(
  "clientStore",
  "invoiceStore",
  "taskStore",
  "appointmentStore",
  "phoneCallStore",
  "messageStore",
  "sessionStore",
  "authStore"
)(observer(ClientTabs));
