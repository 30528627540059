import React from "react";
import { inject, observer } from "mobx-react";

import type { ScheduleStoreClass } from "stores/ScheduleStore";
import CalendarHeader from "components/schedule/CalendarHeader";
import CalendarMonth from "components/schedule/CalendarMonth";
import CalendarWeek from "components/schedule/CalendarWeek";
import TimeSchedule from "components/schedule/TimeSchedule";

import "./CalendarDesktop.scss";

interface Props {
  scheduleStore?: ScheduleStoreClass;
  openCalender?: any;
  openCalenderForAppointment?: any;
}

const CalendarDesktop: React.FC<Props> = ({
  scheduleStore,
  openCalender,
  openCalenderForAppointment,
}) => {
  return (
    <div className="calendar-desktop">
      <CalendarHeader />
      {scheduleStore?.isShowTimeSelect === "week" ? (
        <>
          <CalendarWeek />
        </>
      ) : scheduleStore?.isShowTimeSelect === "month" ? (
        <>
          <CalendarMonth />
        </>
      ) : (
        <>
          <CalendarWeek />
          <TimeSchedule
            appointmentCreated={openCalenderForAppointment}
            drawerOpened={openCalender}
          />
        </>
      )}
    </div>
  );
};

export default inject("scheduleStore")(observer(CalendarDesktop));
