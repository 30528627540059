import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import {
  Row,
  Col,
  Form,
  Space,
  Button,
  Drawer,
  Typography,
  Modal,
  message,
  Grid,
  Tabs,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import type { DrawerProps } from "antd/lib/drawer";
import { DeleteOutlined, CheckOutlined } from "@ant-design/icons";

import type { ScheduleStoreClass } from "stores/ScheduleStore";
import type { AppointmentStoreClass } from "stores/AppointmentStore";
import AddEditAppointment from "../forms/AddEditAppointment";
import useWindowDimension from "components/util/Dimension";
import { saveLocalStorage } from "components/util/localStorage";
import { ClientStoreClass } from "stores/ClientStore";
import TabPane from "@ant-design/pro-card/lib/components/TabPane";
import InputField from "components/forms/items/InputField";
import DateField from "components/forms/items/DateField";
import { AppointmentCustomFields } from "interfaces/appointment.int";
const { useBreakpoint } = Grid;

dayjs.extend(utc);

const { Title } = Typography;

interface Props {
  onToggle?: (x: boolean) => void;
  scheduleStore?: ScheduleStoreClass;
  appointmentStore?: AppointmentStoreClass;
  setOpenCalender?: any;
  setOpenCalenderForAppointment?: any;
  setCurrentTab?: any;
  setToggleSessionActionItems?: any;
  clientStore?: ClientStoreClass;
  clientKey?: string;
  sessionKey?: string;
}

const AppointmentDrawerView: React.FC<Props> = ({
  onToggle,
  clientKey = "",
  sessionKey = "",
  scheduleStore,
  appointmentStore,
  setOpenCalender,
  setOpenCalenderForAppointment,
  setCurrentTab,
  setToggleSessionActionItems,
  clientStore,
}) => {
  const isLoading: boolean = appointmentStore?.isLoading || false;
  const [isDeleteModalShown, setIsDeleteModalShown] = useState<boolean>(false);
  const { innerWidth } = useWindowDimension();
  const [form] = Form.useForm();
  const screens = useBreakpoint();
  const [scheduleCustomFields, setScheduleCustomFields] = useState<AppointmentCustomFields>({
    CustomDate01: "",
    CustomDate02: "",
    CustomDate03: "",
    CustomDate04: "",
    CustomDate05: "",
    CustomText01: "",
    CustomText02: "",
    CustomText03: "",
    CustomText04: "",
    CustomText05: "",
    CustomText06: "",
    CustomText07: "",
    CustomText08: "",
    CustomText09: "",
    CustomText10: "",
    CustomBoolean01: false,
    CustomBoolean02: false,
    CustomBoolean03: false,
    CustomBoolean04: false,
    CustomBoolean05: false,
  });

  useEffect(() => {
    setScheduleCustomFields({
      CustomDate01:
        Number(dayjs(scheduleStore?.activeScheduleDetail?.CustomDate01).format("YYYY")) > 2000
          ? scheduleStore?.activeScheduleDetail?.CustomDate01
          : dayjs(new Date().toString()),
      CustomDate02:
        Number(dayjs(scheduleStore?.activeScheduleDetail?.CustomDate02).format("YYYY")) > 2000
          ? scheduleStore?.activeScheduleDetail?.CustomDate02
          : dayjs(new Date().toString()),
      CustomDate03:
        Number(dayjs(scheduleStore?.activeScheduleDetail?.CustomDate03).format("YYYY")) > 2000
          ? scheduleStore?.activeScheduleDetail?.CustomDate03
          : dayjs(new Date().toString()),
      CustomDate04:
        Number(dayjs(scheduleStore?.activeScheduleDetail?.CustomDate04).format("YYYY")) > 2000
          ? scheduleStore?.activeScheduleDetail?.CustomDate04
          : dayjs(new Date().toString()),
      CustomDate05:
        Number(dayjs(scheduleStore?.activeScheduleDetail?.CustomDate05).format("YYYY")) > 2000
          ? scheduleStore?.activeScheduleDetail?.CustomDate05
          : dayjs(new Date().toString()),
      CustomText01: scheduleStore?.activeScheduleDetail?.CustomText01,
      CustomText02: scheduleStore?.activeScheduleDetail?.CustomText02,
      CustomText03: scheduleStore?.activeScheduleDetail?.CustomText03,
      CustomText04: scheduleStore?.activeScheduleDetail?.CustomText04,
      CustomText05: scheduleStore?.activeScheduleDetail?.CustomText05,
      CustomText06: scheduleStore?.activeScheduleDetail?.CustomText06,
      CustomText07: scheduleStore?.activeScheduleDetail?.CustomText07,
      CustomText08: scheduleStore?.activeScheduleDetail?.CustomText08,
      CustomText09: scheduleStore?.activeScheduleDetail?.CustomText09,
      CustomText10: scheduleStore?.activeScheduleDetail?.CustomText10,
      CustomBoolean01: scheduleStore?.activeScheduleDetail?.CustomBoolean01,
      CustomBoolean02: scheduleStore?.activeScheduleDetail?.CustomBoolean02,
      CustomBoolean03: scheduleStore?.activeScheduleDetail?.CustomBoolean03,
      CustomBoolean04: scheduleStore?.activeScheduleDetail?.CustomBoolean04,
      CustomBoolean05: scheduleStore?.activeScheduleDetail?.CustomBoolean05,
    });
  }, [scheduleStore?.activeScheduleDetail]);

  useEffect(() => {
    if (!appointmentStore?.isSelectToggle) {
      setTimeout(() => {
        form?.resetFields();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentStore?.isSelectToggle]);

  const toggleAppointmentDrawer = (toggle: boolean) => {
    scheduleStore?.setActiveSchedule(undefined);
    clientStore?.setActiveClientKey("");
    onToggle && onToggle(toggle);
    appointmentStore?.setSelectToggle(toggle);
    scheduleStore?.setActiveScheduleDetail(undefined);
    scheduleStore?.setActiveSchedule(undefined);
    scheduleStore?.setIsActiveTimeSelect(false);
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalShown(!isDeleteModalShown);
    scheduleStore?.setIsActiveTimeSelect(false);
  };

  const handleDelete = async () => {
    setIsDeleteModalShown(false);
    message.loading(`Deleting...`, 0.5);
    let key: string | undefined;

    if (scheduleStore?.activeSchedule?.AppointmentKey) {
      key = scheduleStore?.activeSchedule?.AppointmentKey;
    }

    try {
      await scheduleStore?.deleteSchedule(key, "Appointment").then(() => {
        message.success(`Appointment successfully deleted!`);
        handleSuccess();
        saveLocalStorage({
          name: "Deleted",
          value: `Appointment - ${
            clientStore?.clientInfo?.FirstName + " " + clientStore?.clientInfo?.LastName
          }`,
        });
      });
    } catch (e) {
      message.error(`Unable to delete appointment. Try logging in again.`);
    }
  };

  const handleSuccess = async () => {
    try {
      await scheduleStore?.getCalendarList(
        scheduleStore?.filterUserKey,
        scheduleStore?.filterResourceKey
      );
      // await sessionStore?.getSessionDetail(sessionStore?.sessionDetail?.Key);
      toggleAppointmentDrawer(false);
      setOpenCalender(false);
      setOpenCalenderForAppointment(false);
      // scheduleStore?.setActiveSchedule({
      //   SessionKey: sessionStore?.sessionDetail?.Key,
      // });
      // setToggleSessionActionItems(true);
      // setCurrentTab("Sessions");
    } catch (e) {}
    scheduleStore?.cleanTimeSelectCallback();
  };

  const handleError = () => {
    toggleAppointmentDrawer(false);
    setTimeout(() => {
      scheduleStore?.cleanTimeSelectCallback();
      message.error(`Unable to fetch appointment. Does not exist or try logging in again.`);
    }, 500);
  };
  const ModalHeader = () => {
    return (
      <Row>
        <Col xs={10}>
          <Title level={2}>Appointment</Title>
        </Col>
        <Col
          xs={14}
          style={{
            textAlign: "right",
          }}
        >
          <Space>
            {scheduleStore?.activeSchedule !== undefined && (
              <Button
                icon={<DeleteOutlined />}
                disabled={isLoading}
                style={{
                  backgroundColor: "rgb(142, 59, 70)",
                  borderColor: "rgb(142, 59, 70)",
                  color: "white",
                }}
                className="btn-action"
                onClick={toggleDeleteModal}
              >
                Delete
              </Button>
            )}
            <Button
              icon={<CheckOutlined />}
              disabled={isLoading}
              style={{
                backgroundColor: "rgb(255, 179, 28)",
                borderColor: "rgb(255, 179, 28)",
                color: "white",
              }}
              className="btn-action"
              shape="round"
              onClick={() => form.validateFields().then(() => form.submit())}
            >
              Save
            </Button>
          </Space>
        </Col>
      </Row>
    );
  };

  const drawerParams: DrawerProps = {
    width: screens.xs ? innerWidth : innerWidth - 250,
    placement: "right",
    closable: true,
    forceRender: false,
    destroyOnClose: true,
    title: <ModalHeader />,
    maskStyle: {},
    className: "drawer-form",
    push: {
      distance: 20,
    },
  };

  return (
    <>
      <Drawer
        onClose={() => toggleAppointmentDrawer(false)}
        visible={appointmentStore?.isSelectToggle}
        {...drawerParams}
      >
        <Tabs defaultActiveKey={"1"} type="card" size={"small"}>
          <TabPane tab="General" key="1">
            <AddEditAppointment
              form={form}
              clientKey={clientKey}
              sessionKey={sessionKey}
              onSuccess={handleSuccess}
              onError={handleError}
              scheduleCustomFields={scheduleCustomFields}
            />
          </TabPane>
          <TabPane tab="Custom" key="2">
            <Row className="CustomFields">
              <Col xs={24} sm={12}>
                <Col xs={24}>
                  <InputField
                    label="Custom 1"
                    value={scheduleCustomFields.CustomText01}
                    onChange={(e: any) => {
                      setScheduleCustomFields({
                        ...scheduleCustomFields,
                        CustomText01: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 2"
                    value={scheduleCustomFields.CustomText02}
                    onChange={(e: any) => {
                      setScheduleCustomFields({
                        ...scheduleCustomFields,
                        CustomText02: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 3"
                    value={scheduleCustomFields.CustomText03}
                    onChange={(e: any) => {
                      setScheduleCustomFields({
                        ...scheduleCustomFields,
                        CustomText03: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 4"
                    value={scheduleCustomFields.CustomText04}
                    onChange={(e: any) => {
                      setScheduleCustomFields({
                        ...scheduleCustomFields,
                        CustomText04: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 5"
                    value={scheduleCustomFields.CustomText05}
                    onChange={(e: any) => {
                      setScheduleCustomFields({
                        ...scheduleCustomFields,
                        CustomText05: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 6"
                    value={scheduleCustomFields.CustomText06}
                    onChange={(e: any) => {
                      setScheduleCustomFields({
                        ...scheduleCustomFields,
                        CustomText06: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 7"
                    value={scheduleCustomFields.CustomText07}
                    onChange={(e: any) => {
                      setScheduleCustomFields({
                        ...scheduleCustomFields,
                        CustomText07: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 8"
                    value={scheduleCustomFields.CustomText08}
                    onChange={(e: any) => {
                      setScheduleCustomFields({
                        ...scheduleCustomFields,
                        CustomText08: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 9"
                    value={scheduleCustomFields.CustomText09}
                    onChange={(e: any) => {
                      setScheduleCustomFields({
                        ...scheduleCustomFields,
                        CustomText09: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <InputField
                    label="Custom 10"
                    value={scheduleCustomFields.CustomText10}
                    onChange={(e: any) => {
                      setScheduleCustomFields({
                        ...scheduleCustomFields,
                        CustomText10: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Col>
              <Col xs={24} sm={12}>
                <Col xs={24}>
                  <DateField
                    label="Custom Date 1"
                    suffixIcon={<></>}
                    allowClear={false}
                    value={
                      scheduleCustomFields.CustomDate01
                        ? dayjs(scheduleCustomFields.CustomDate01)
                        : null
                    }
                    onChange={(e: any) => {
                      setScheduleCustomFields({ ...scheduleCustomFields, CustomDate01: e.$d });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <DateField
                    label="Custom Date 2"
                    suffixIcon={<></>}
                    allowClear={false}
                    value={
                      scheduleCustomFields.CustomDate02
                        ? dayjs(scheduleCustomFields.CustomDate02)
                        : null
                    }
                    onChange={(e: any) => {
                      setScheduleCustomFields({ ...scheduleCustomFields, CustomDate02: e.$d });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <DateField
                    label="Custom Date 3"
                    suffixIcon={<></>}
                    allowClear={false}
                    value={
                      scheduleCustomFields.CustomDate03
                        ? dayjs(scheduleCustomFields.CustomDate03)
                        : null
                    }
                    onChange={(e: any) => {
                      setScheduleCustomFields({ ...scheduleCustomFields, CustomDate03: e.$d });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <DateField
                    label="Custom Date 4"
                    suffixIcon={<></>}
                    allowClear={false}
                    value={
                      scheduleCustomFields.CustomDate04
                        ? dayjs(scheduleCustomFields.CustomDate04)
                        : null
                    }
                    onChange={(e: any) => {
                      setScheduleCustomFields({ ...scheduleCustomFields, CustomDate04: e.$d });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <DateField
                    label="Custom Date 5"
                    suffixIcon={<></>}
                    allowClear={false}
                    value={
                      scheduleCustomFields.CustomDate05
                        ? dayjs(scheduleCustomFields.CustomDate05)
                        : null
                    }
                    onChange={(e: any) => {
                      setScheduleCustomFields({ ...scheduleCustomFields, CustomDate05: e.$d });
                    }}
                  />
                </Col>
                <Col xs={24}>
                  <Checkbox
                    checked={scheduleCustomFields.CustomBoolean01}
                    onChange={(e: any) => {
                      setScheduleCustomFields({
                        ...scheduleCustomFields,
                        CustomBoolean01: e.target.checked,
                      });
                    }}
                  >
                    Custom Boolean 1
                  </Checkbox>
                </Col>
                <Col xs={24}>
                  <Checkbox
                    checked={scheduleCustomFields.CustomBoolean02}
                    onChange={(e: any) => {
                      setScheduleCustomFields({
                        ...scheduleCustomFields,
                        CustomBoolean02: e.target.checked,
                      });
                    }}
                  >
                    Custom Boolean 2
                  </Checkbox>
                </Col>
                <Col xs={24}>
                  <Checkbox
                    checked={scheduleCustomFields.CustomBoolean03}
                    onChange={(e: any) => {
                      setScheduleCustomFields({
                        ...scheduleCustomFields,
                        CustomBoolean03: e.target.checked,
                      });
                    }}
                  >
                    Custom Boolean 3
                  </Checkbox>
                </Col>
                <Col xs={24}>
                  <Checkbox
                    checked={scheduleCustomFields.CustomBoolean04}
                    onChange={(e: any) => {
                      setScheduleCustomFields({
                        ...scheduleCustomFields,
                        CustomBoolean04: e.target.checked,
                      });
                    }}
                  >
                    Custom Boolean 4
                  </Checkbox>
                </Col>
                <Col xs={24}>
                  <Checkbox
                    checked={scheduleCustomFields.CustomBoolean05}
                    onChange={(e: any) => {
                      setScheduleCustomFields({
                        ...scheduleCustomFields,
                        CustomBoolean05: e.target.checked,
                      });
                    }}
                  >
                    Custom Boolean 5
                  </Checkbox>
                </Col>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Drawer>
      <Modal
        title={`Delete an appointment`}
        visible={isDeleteModalShown}
        onCancel={toggleDeleteModal}
        onOk={handleDelete}
      >
        <p>Are you sure you want to delete this appointment?</p>
      </Modal>
    </>
  );
};

export default inject("scheduleStore", "appointmentStore")(observer(AppointmentDrawerView));
