import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { List, Typography, Collapse, Row, Button, Table, Col } from "antd";
import dayjs from "dayjs";
import { SessionStoreClass } from "stores/SessionStore";
import { ScheduleStoreClass } from "stores/ScheduleStore";
import { CameraOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { InvoiceStoreClass } from "stores/InvoiceStore";
import type { AuthStoreClass } from "stores/AuthStore";
interface Props {
  sessionStore?: SessionStoreClass;
  scheduleStore?: ScheduleStoreClass;
  invoiceStore?: InvoiceStoreClass;
  customHandleClick?: any;
  setToggleActionItems?: any;
  viewSection?: any;
  authStore?: AuthStoreClass;
}

const SessionsList: React.FC<Props> = ({
  sessionStore,
  scheduleStore,
  invoiceStore,
  customHandleClick,
  setToggleActionItems,
  viewSection,
  authStore,
}) => {
  const { Text } = Typography;
  const { Panel } = Collapse;

  let [defaultKey, setDefaultKey] = useState<any>("1");

  const [upcomingView, setUpcomingView] = useState("Graphical");
  const [pastView, setPastView] = useState("Graphical");

  const NotAvailable = () => {
    return <Text disabled>Not Available</Text>;
  };

  const sessionDetails = (field: any) => {
    if (!field) return <NotAvailable />;
    else {
      return <Text strong>{field}</Text>;
    }
  };

  let handleClick = async (item: any) => {
    sessionStore?.getSessionDetail(item.Key);
    scheduleStore?.setActiveSchedule({ SessionKey: item.Key });
    sessionStore?.setSelectToggle(true);
  };

  let handleSessionClick = async (item: any) => {
    const session = await sessionStore?.getSessionDetail(item.Key);
    await sessionStore?.getSessionDetail(item.Key);
    invoiceStore?.setActiveInvoice(session.SessionInvoice);
    scheduleStore?.setActiveSchedule({ SessionKey: item.Key });
    setToggleActionItems(true);
  };
  let filteredUpcomingSessions = sessionStore?.sessionList
    ?.filter((session) => {
      let givenDate = dayjs(session?.StartDate).format("YYYY-MM-DD");
      return new Date(givenDate) >= new Date();
    })
    .sort((a: any, b: any) => {
      return dayjs(a.StartDate).diff(dayjs(b.StartDate));
    });

  let filteredPastSessions = sessionStore?.sessionList?.filter((session) => {
    let givenDate = dayjs(session?.StartDate).format("YYYY-MM-DD");
    return new Date(givenDate) <= new Date();
  });

  if (customHandleClick) handleClick = handleSessionClick;

  useEffect(() => {
    if (
      viewSection ===
        (authStore?.companySettings.JobDescriptorPlural
          ? authStore?.companySettings.JobDescriptorPlural
          : "Sessions") &&
      (defaultKey === undefined || defaultKey === "1")
    ) {
      filteredUpcomingSessions?.length ? setDefaultKey("1") : setDefaultKey("2");
    } else if (
      viewSection !==
      (authStore?.companySettings.JobDescriptorPlural
        ? authStore?.companySettings.JobDescriptorPlural
        : "Sessions")
    ) {
    } else setDefaultKey("2");
  }, [viewSection]);

  const handleUpcomingView = () => {
    if (upcomingView === "Graphical") {
      setUpcomingView("Grid");
    } else {
      setUpcomingView("Graphical");
    }
  };

  const handlePastView = () => {
    if (pastView === "Graphical") {
      setPastView("Grid");
    } else {
      setPastView("Graphical");
    }
  };

  const sessionColumns = [
    {
      title: `${
        authStore?.companySettings?.JobDescriptorSingular
          ? authStore?.companySettings?.JobDescriptorSingular
          : "Session"
      } Number`,
      dataIndex: "SessionNumber",
      key: "SessionNumber",
      render: (number: string) => {
        return sessionDetails(number);
      },
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      render: (description: string) => {
        return sessionDetails(description);
      },
    },
    {
      title: "Start Date",
      dataIndex: "StartDate",
      key: "StartDate",
      render: (startDate: string) => {
        return dayjs(startDate).format("MM/DD/YYYY");
      },
    },
    {
      title: `${
        authStore?.companySettings.JobDescriptorSingular
          ? authStore?.companySettings.JobDescriptorSingular
          : "Session"
      } Status`,
      dataIndex: "SessionStatus",
      key: "SessionStatus",
    },
    {
      title: "Photographer Name",
      dataIndex: "PhotographerName",
      key: "PhotographerName",
      render: (name: string) => {
        return sessionDetails(name);
      },
    },
    {
      title: "Location",
      dataIndex: "Location",
      key: "Location",
      render: (location: string) => {
        return sessionDetails(location);
      },
    },
  ];

  return (
    <>
      <Collapse
        ghost
        accordion
        onChange={(active) => {
          setDefaultKey(active);
        }}
        activeKey={defaultKey}
        defaultActiveKey={defaultKey}
      >
        <Panel
          header={`${
            authStore?.companySettings.JobDescriptorPlural
              ? "Upcoming " + authStore?.companySettings.JobDescriptorPlural
              : "Upcoming Sessions"
          }(${filteredUpcomingSessions?.length})`}
          key="1"
        >
          <Row
            style={{
              justifyContent: "flex-end",
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Button
              onClick={() => {
                handleUpcomingView();
              }}
            >
              {upcomingView === "Graphical" ? "Grid " : "Graphical "} View
            </Button>
          </Row>
          {upcomingView === "Graphical" ? (
            <List
              dataSource={filteredUpcomingSessions}
              renderItem={(item: any) => {
                return (
                  <List.Item onClick={() => handleClick(item)} key={item.Key}>
                    <div className="quickAccessList">
                      <div className="quickAccessList__Date">
                        <label>
                          {/* <span className="quickAccessList__Date--Icon"></span> */}
                          <span className="quickAccessList__Date--Day">
                            {/* <CalendarOutlined /> */}
                            {dayjs(item.StartDate).format("DD")}
                          </span>
                          <span className="quickAccessList__Date--Year">
                            {dayjs(item.StartDate).format("MMM YYYY")}
                          </span>
                        </label>
                      </div>
                      <div className="quickAccessList__Content">
                        <div className="quickAccessList__Content--Head">
                          <Text className="sessionStatus" mark>
                            {item.SessionStatus}
                          </Text>
                          <div>
                            <label>
                              <CameraOutlined />
                              {sessionDetails(item.PhotographerName)}
                            </label>
                            <label>
                              <EnvironmentOutlined />
                              {sessionDetails(item.Location)}
                            </label>
                          </div>
                        </div>
                        <div className="quickAccessList__Content--Body">
                          <div>
                            <label>
                              <b>
                                {authStore?.companySettings.JobDescriptorSingular
                                  ? authStore?.companySettings.JobDescriptorSingular + "  No"
                                  : "Session No"}
                                :{" "}
                              </b>
                              {item.SessionNumber}
                            </label>
                            <p>{item.Description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </List.Item>
                );
              }}
              bordered
              style={{ border: 0, backgroundColor: "#fff" }}
            ></List>
          ) : (
            <Col style={{ paddingLeft: 20, paddingRight: 20 }}>
              <Table
                columns={sessionColumns}
                dataSource={filteredUpcomingSessions}
                pagination={false}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      handleClick(record);
                    },
                  };
                }}
              />
            </Col>
          )}
        </Panel>
        <Panel
          header={`${
            authStore?.companySettings.JobDescriptorPlural
              ? "Past " + authStore?.companySettings.JobDescriptorPlural
              : "Past Sessions"
          }(${filteredPastSessions?.length})`}
          key="2"
        >
          <Row
            style={{
              justifyContent: "flex-end",
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Button
              onClick={() => {
                handlePastView();
              }}
            >
              {pastView === "Graphical" ? "Grid " : "Graphical "} View
            </Button>
          </Row>
          {pastView === "Graphical" ? (
            <List
              className="quickAccessListBlock"
              pagination={{
                pageSize: 20,
              }}
              dataSource={filteredPastSessions}
              renderItem={(item: any) => {
                return (
                  <List.Item onClick={() => handleClick(item)} key={item.Key}>
                    <div className="quickAccessList">
                      <div className="quickAccessList__Date">
                        <label>
                          {/* <span className="quickAccessList__Date--Icon"></span> */}
                          <span className="quickAccessList__Date--Day">
                            {/* <CalendarOutlined /> */}
                            {dayjs(item.StartDate).format("DD")}
                          </span>
                          <span className="quickAccessList__Date--Year">
                            {dayjs(item.StartDate).format("MMM YYYY")}
                          </span>
                        </label>
                      </div>
                      <div className="quickAccessList__Content">
                        <div className="quickAccessList__Content--Head">
                          <Text className="sessionStatus" mark>
                            {item.SessionStatus}
                          </Text>
                          <div>
                            <label>
                              <CameraOutlined />
                              {sessionDetails(item.PhotographerName)}
                            </label>
                            <label>
                              <EnvironmentOutlined />
                              {sessionDetails(item.Location)}
                            </label>
                          </div>
                        </div>
                        <div className="quickAccessList__Content--Body">
                          <div>
                            <label>
                              <b>
                                {authStore?.companySettings.JobDescriptorSingular
                                  ? authStore?.companySettings.JobDescriptorSingular + "  No"
                                  : "Session No"}
                                :{" "}
                              </b>
                              {item.SessionNumber}
                            </label>
                            <p>{item.Description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <List.Item.Meta
                  style={{ alignItems: "center" }}
                  avatar={
                    <Space
                      align="center"
                      direction="vertical"
                      size={[0, 0]}
                      className="calendar-space"
                    >
                      <span className="calendar-date">
                        {dayjs(item.StartDate).format("MMM DD")}
                      </span>
                      <span>{dayjs(item.StartDate).format("YYYY")}</span>
                    </Space>
                  }
                  title={
                    <p style={{ marginBottom: "0", minWidth: "70%" }}>
                      {item.Description}
                      <Text style={{ float: "right", marginTop: 15, marginRight: 15 }} mark>
                        {item.SessionStatus}
                      </Text>
                    </p>
                  }
                  description={<p style={{ margin: 0 }}>{item.SessionType}</p>}
                />
                <div className="list-item-content">
                  Photographer: {sessionDetails(item.PhotographerName)}
                  <br />
                  Location: {sessionDetails(item.Location)}
                </div> */}
                  </List.Item>
                );
              }}
              style={{ border: 0, backgroundColor: "#fff" }}
            />
          ) : (
            <Col style={{ paddingLeft: 20, paddingRight: 20 }}>
              <Table
                columns={sessionColumns}
                dataSource={filteredPastSessions}
                pagination={{ pageSize: 20 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      handleClick(record);
                    },
                  };
                }}
              />
            </Col>
          )}
        </Panel>
      </Collapse>
    </>
  );
};

export default inject(
  "sessionStore",
  "scheduleStore",
  "invoiceStore",
  "authStore"
)(observer(SessionsList));
