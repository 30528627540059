import React, { useState, useEffect, useCallback } from "react";
import { inject, observer } from "mobx-react";
import classNames from "classnames";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Badge, List, Card, Row, Col, Grid, Tooltip } from "antd";

import type { ScheduleStoreClass } from "stores/ScheduleStore";
import ScheduleDropdownSelect from "./ScheduleDropdownSelect";
import TimeLine from "./TimeLine";
import useTimesInADay, { TimeObj, TimeObjExtra } from "components/util/useTimesInADay";
import useTimeMatrix from "components/util/useTimeMatrix";
import { ScheduleList } from "interfaces/schedule.int";

import "./TimeSchedule.scss";

interface Props {
  defautlTime?: number;
  scheduleStore?: ScheduleStoreClass;
  drawerOpened?: any;
  appointmentCreated?: any;
}

interface Time {
  idx: number;
  label: string;
}

dayjs.extend(customParseFormat);

const { useBreakpoint } = Grid;

const TimeSchedule: React.FC<Props> = ({
  defautlTime = 8,
  scheduleStore,
  drawerOpened,
  appointmentCreated,
}: Props): React.ReactElement => {
  const [selectedTime, setSelectedTime] = useState<Time | null>(null);
  const [popoverActive, setPopoverActive] = useState<boolean>(false);
  const [popoverLocation, setPopoverLocation] = useState<{
    top?: number;
    left?: number;
  }>({});
  const timeData = useTimesInADay() || [];
  const defaultSelectedTime = scheduleStore?.activeDateTime;
  const matrix = useTimeMatrix(scheduleStore?.getAvailableInDay() || []);
  const matrixColumns = matrix?.columns ? 100 / matrix?.columns : 100;
  const screens = useBreakpoint();

  useEffect(() => {
    scrollTo(scheduleStore?.getAvailableInDay() || []);
    // eslint-disable-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleStore?.activeDate, scheduleStore?.scheduleList]);

  useEffect(() => {
    setSelectedTime({
      idx: Number(defaultSelectedTime?.format("H")),
      label: defaultSelectedTime?.format("ha") || "",
    });
  }, [defaultSelectedTime]);

  const scrollTo = (data: any) => {
    const selectedDateFirstAvailableTime =
      data?.length > 0 ? dayjs(data[0]?.StartDateTime).format("H") : defautlTime;

    const getRef = refs[selectedDateFirstAvailableTime].current;
    setTimeout(() => {
      getRef.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 1);
  };

  const refs = timeData.reduce((acc: any, item: Time) => {
    acc[item.idx] = React.createRef();
    return acc;
  }, {});

  const handleOnSelect = useCallback(
    (time: TimeObj, schedule?: ScheduleList, e?: React.MouseEvent) => {
      scheduleStore?.timeSelectCallback(time.idx, schedule);
    },
    [scheduleStore]
  );

  const handleOnBlur = useCallback(() => {
    setTimeout(() => {
      setPopoverActive(false);
    }, 220);
  }, []);

  const renderItemElement = useCallback(
    (item: TimeObj) => {
      const sortedData =
        item?.data?.sort((a: any, b: any) => {
          let aCol = a.columnSource || 0;
          let bCol = b.columnSource || 0;
          if (aCol > bCol) return 1;
          if (aCol < bCol) return -1;
          return 0;
        }) || [];

      Array.from(
        {
          length: matrix.columns - sortedData.length,
        },
        () => sortedData.push({})
      );
      const timeParsed = dayjs(item.label, "ha");

      const getHexaColorCode = (type: string, color: any) => {
        color >>>= 0;
        var b = color & 0xff,
          g = (color & 0xff00) >>> 8,
          r = (color & 0xff0000) >>> 16,
          a = ((color & 0xff000000) >>> 24) / 255;
        if (a == 0) a = 1;
        return "rgba(" + [r, g, b, type == "bg" ? 0.5 : a].join(",") + ")";
      };

      return (
        <div ref={refs[item.idx]}>
          <List.Item
            className={classNames("time-detail", {
              "time-detail-active": selectedTime?.idx === item.idx,
            })}
            onClick={(e) => typeof item?.data === "undefined" && handleOnSelect(item, undefined, e)}
          >
            <Card size="small" style={{ width: "100%", padding: 0 }}>
              <Row>
                <Col
                  className="time-of-day-col"
                  onClick={(e) => handleOnSelect(item, undefined, e)}
                >
                  <span>
                    {timeParsed.format("h")}{" "}
                    <span className="time-label">{timeParsed.format("a")}</span>
                  </span>
                </Col>
                <Col
                  className="time-entries-col"
                  onClick={(e) =>
                    typeof item?.data === "undefined" && handleOnSelect(item, undefined, e)
                  }
                >
                  {typeof item?.data !== "undefined" && item?.data?.length > -1 && (
                    <List
                      className="time-select-list"
                      dataSource={sortedData}
                      renderItem={(itemExtra: TimeObjExtra) => {
                        const v = itemExtra?.originData;

                        if (typeof v === "undefined") {
                          return (
                            <>
                              <List.Item
                                onClick={(e) => handleOnSelect(item, undefined, e)}
                                style={{
                                  maxWidth: `${matrixColumns}%`,
                                }}
                              ></List.Item>
                            </>
                          );
                        }
                        return (
                          <>
                            <Tooltip
                              placement="topLeft"
                              title={`${dayjs(itemExtra.originData.StartDateTime).format(
                                "hh:mm A"
                              )}-${dayjs(itemExtra.originData.EndDateTime).format("hh:mm A")} ${
                                itemExtra.originData.Description
                              }`}
                            >
                              <List.Item
                                onClick={(e) => handleOnSelect(item, v, e)}
                                style={{
                                  maxWidth: `${matrixColumns}%`,
                                }}
                              >
                                <Badge
                                  status={itemExtra.type === "appointment" ? "success" : "warning"}
                                  text={`${itemExtra.description}`}
                                  color={getHexaColorCode("line", itemExtra.Color)}
                                  className={classNames("badge-overflow", {
                                    isSuccess: itemExtra.type === "appointment",
                                    isFirst: itemExtra.key,
                                  })}
                                  style={{
                                    marginTop: itemExtra.startTime
                                      ? (itemExtra.startTime - Math.floor(itemExtra.startTime)) * 44
                                      : 0,
                                    height:
                                      typeof itemExtra?.rowCount === "undefined"
                                        ? 44
                                        : 44 * itemExtra?.rowCount,
                                    minHeight: "1.5rem",
                                    background: getHexaColorCode("bg", itemExtra.Color),
                                  }}
                                />
                              </List.Item>
                            </Tooltip>
                          </>
                        );
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Card>
          </List.Item>
        </div>
      );
    },
    [handleOnSelect, matrixColumns, matrix.columns, refs, selectedTime]
  );

  return (
    <>
      <div
        className={classNames(
          "ant-popover navigation-popover-container ant-popover-placement-bottomRight",
          {
            "ant-popover-hidden": !popoverActive,
          }
        )}
        style={{
          top: popoverLocation.top,
          left: popoverLocation.left,
          zIndex: 99,
          position: "absolute",
        }}
      >
        <div className="ant-popover-content">
          <div className="ant-popover-inner">
            <ScheduleDropdownSelect
              drawerOpened={drawerOpened}
              appointmentCreated={appointmentCreated}
              clientKey={scheduleStore?.activeClientKey}
            />
          </div>
        </div>
      </div>
      <div
        className="time-select-container"
        tabIndex={0}
        onBlur={() => handleOnBlur()}
        onClick={(e) => {
          setPopoverActive(true);
          setPopoverLocation({
            top: e.clientY - 100,
            left: e.clientX - (screens.md ? 340 : 80),
          });
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <List dataSource={matrix?.data} renderItem={renderItemElement} />
          {scheduleStore?.calendarActiveDate.format("YYMMDD") === dayjs().format("YYMMDD") && (
            <TimeLine />
          )}
        </div>
      </div>
    </>
  );
};

export default inject("scheduleStore")(observer(TimeSchedule));
