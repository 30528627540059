import React from "react";
import { RetweetOutlined, FormOutlined, SelectOutlined } from "@ant-design/icons";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import { formatPrice } from "utils/helper-methods";
import EventEmitter from "utils/event-emitter";

const CartItem = (props: any) => {
  const {
    item,
    galleryStore,
    updateItem,
    removeItem,
    errorText,
    showError,
    hideControls = false,
  } = props;
  const { sessionImages } = galleryStore;

  const _showInEditor = () => {
    EventEmitter.trigger("edit-item-image", item);
  };

  const _switchImage = () => {
    EventEmitter.trigger("switch-item-image", item);
  };

  const _selectImage = () => {
    EventEmitter.trigger("select-item-image", item);
  };

  const _updateItemCount = (count: number) => {
    if (isNaN(count) || count < 0) {
      updateItem({
        ...item,
        Quantity: 0,
        ExtendedStringFormatted: `$${formatPrice(0)}`,
      });
    } else {
      updateItem({
        ...item,
        Quantity: count,
        ExtendedStringFormatted: `$${formatPrice(count * item.Price)}`,
      });
    }
  };

  const _decreaseItemCount = () => {
    const Quantity = item.Quantity - 1;
    if (item.Quantity > 1) {
      updateItem({
        ...item,
        Quantity,
        ExtendedStringFormatted: `$${formatPrice(Quantity * item.Price)}`,
      });
    }
  };

  const _increaseItemCount = () => {
    const Quantity = item.Quantity + 1;
    updateItem({
      ...item,
      Quantity,
      ExtendedStringFormatted: `$${formatPrice(Quantity * item.Price)}`,
    });
  };

  if (sessionImages && toJS(sessionImages).length) {
    // Session images available
    const image = toJS(sessionImages).find((img: any) => img.fileName === item.FileName);
    let imageLink: any = null;
    if (image) {
      // Construct image link
      imageLink = image.thumbnailPath;
      if (item.ColorizationKey && item.ColorizationKey.length) {
        imageLink += `&colorizationKey=${item.ColorizationKey}`;
      }
    }

    // Check if image selection allowed
    const selectedItem = props.cartStore.categories
      .map((item: any) => item.Items)
      .flat(1)
      .find((i: any) => i.Key === item.ItemKey);
    let imageSelectionAllowed = selectedItem?.AllowImageSelection;
    // Check if image edit allowed
    let isImageEditAllowed = true;
    if (imageSelectionAllowed && !selectedItem.CropRatio) {
      isImageEditAllowed = false;
    }

    return (
      <>
        <div className={`cartItemRowLarge ${showError && "error"}`}>
          <div className="cartItemCell productCell">
            <p className="itemTitle">
              {item.Description} <span>{item.FileName}</span>
            </p>
            <div className="itemPreviewer">
              <div className="thumbnailViewer">
                <img
                  src={
                    imageLink ||
                    "https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-6.png"
                  }
                  alt=""
                />
              </div>
              {!hideControls && (
                <div className="imageActions">
                  {imageLink ? (
                    <>
                      <p className="imageAction" onClick={_switchImage}>
                        <RetweetOutlined />
                        <span>Swap photo</span>
                      </p>
                      {isImageEditAllowed ? (
                        <p className="imageAction" onClick={_showInEditor}>
                          <FormOutlined />
                          <span>Set crop and options</span>
                        </p>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      {imageSelectionAllowed ? (
                        <p className="imageAction" onClick={_selectImage}>
                          <SelectOutlined />
                          <span>Select photo</span>
                        </p>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  <p className="imageAction removeBtn" onClick={removeItem}>
                    <span>Remove</span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="cartItemCell priceCell">{item.PriceStringFormatted}</div>
          <div className="cartItemCell quantityCell">
            <div className="itemCountModifier">
              {hideControls ? (
                item.Quantity
              ) : (
                <>
                  <button className="decBtn" onClick={_decreaseItemCount}>
                    -
                  </button>
                  <input
                    type="text"
                    className="itemCountInput"
                    value={item.Quantity}
                    onChange={(e) => _updateItemCount(parseInt(e.target.value))}
                    onKeyPress={(event) =>
                      event.charCode == 8 || event.charCode == 0 || event.charCode == 13
                        ? null
                        : event.charCode >= 48 && event.charCode <= 57
                    }
                  />
                  <button className="incBtn" onClick={_increaseItemCount}>
                    +
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="cartItemCell subtotalCell">{item.ExtendedStringFormatted}</div>
        </div>
        <div className={`cartItemRowMobile ${showError && "error"}`}>
          <div className="itemRow">
            <div className="label">Product name</div>
            <div className="value">
              <p className="itemTitle">{item.Description}</p>
              <p className="fileName">{item.FileName}</p>
              <div className="thumbnailViewer">
                <img
                  src={
                    imageLink ||
                    "https://www.freeiconspng.com/thumbs/no-image-icon/no-image-icon-6.png"
                  }
                  alt=""
                />
              </div>
              <div className="imageActions">
                {imageLink ? (
                  <>
                    <p className="imageAction" onClick={_switchImage}>
                      <RetweetOutlined />
                      <span>Swap photo</span>
                    </p>
                    <p className="imageAction" onClick={_showInEditor}>
                      <FormOutlined />
                      <span>Set crop and options</span>
                    </p>
                  </>
                ) : (
                  <p className="imageAction" onClick={_selectImage}>
                    <SelectOutlined />
                    <span>Select photo</span>
                  </p>
                )}
                <p className="imageAction removeBtn" onClick={removeItem}>
                  <span>Remove</span>
                </p>
              </div>
            </div>
          </div>
          <div className="itemRow">
            <div className="label">Price</div>
            <div className="value">{item.PriceStringFormatted}</div>
          </div>
          <div className="itemRow">
            <div className="label">Quantity</div>
            <div className="value">
              <div className="itemCountModifier">
                <button className="decBtn" onClick={_decreaseItemCount}>
                  -
                </button>
                <input
                  type="text"
                  className="itemCountInput"
                  value={item.Quantity}
                  onChange={(e) => _updateItemCount(parseInt(e.target.value))}
                  onKeyPress={(event) =>
                    event.charCode == 8 || event.charCode == 0 || event.charCode == 13
                      ? null
                      : event.charCode >= 48 && event.charCode <= 57
                  }
                />
                <button className="incBtn" onClick={_increaseItemCount}>
                  +
                </button>
              </div>
            </div>
          </div>
          <div className="itemRow">
            <div className="label">SubTotal</div>
            <div className="value">{item.ExtendedStringFormatted}</div>
          </div>
        </div>
        {showError ? <div className="errorMessage">{errorText}</div> : <></>}
      </>
    );
  }
  return <></>;
};

export default inject("galleryStore", "cartStore")(observer(CartItem));
