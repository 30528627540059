import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { List, Typography } from "antd";
import dayjs from "dayjs";

import "./StatusBucket.scss";
import { TaskStoreClass } from "stores/TaskStore";

const { Text } = Typography;

interface Props {
  taskStatusKey: string;
  taskStore?: TaskStoreClass;
}

const TaskBucket: React.FC<Props> = ({ taskStatusKey, taskStore }) => {
  const [taskList, setTaskList] = useState<any>([]);

  const generateTaskList = async () => {
    const list = await taskStore?.getTasksByStatus(taskStatusKey);
    setTaskList(list);
  };

  useEffect(() => {
    if (taskStatusKey) generateTaskList();
  }, [taskStatusKey]);

  return (
    <>
      {taskList ? (
        <List
          dataSource={taskList ? taskList : []}
          loadMore={true}
          pagination={{ pageSize: 5 }}
          renderItem={(item: any) => {
            return (
              <List.Item>
                <div className="quickAccessList">
                  <div className="quickAccessList__Date">
                    <label>
                      {/* <span className="quickAccessList__Date--Icon"></span> */}
                      <span className="quickAccessList__Date--Day">
                        {/* <CalendarOutlined /> */}
                        {dayjs(item.DueDate).format("DD")}
                      </span>
                      <span className="quickAccessList__Date--Year">
                        {dayjs(item.DueDate).format("MMM YYYY")}
                      </span>
                    </label>
                  </div>
                  <div className="quickAccessList__Content">
                    <div className="quickAccessList__Content--Body">
                      <div className="data-panel">
                        <div className="status-block">
                          {item.Urgent == "!" || item.Urgent == "True" ? (
                            <Text className="taskStatus red" mark>
                              Urgent Task
                            </Text>
                          ) : (
                            <Text className="taskStatus" />
                          )}
                          {item.Personal == "True" ? (
                            <Text className="taskStatus" mark>
                              Personal Task
                            </Text>
                          ) : (
                            <Text className="taskStatus" />
                          )}
                        </div>
                        <p>{item.Subject || item.TaskSubject || "..."}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </List.Item>
            );
          }}
          bordered
          style={{ border: 0, backgroundColor: "#fff" }}
        ></List>
      ) : null}
    </>
  );
};

export default inject("taskStore")(observer(TaskBucket));
