import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { inject, observer } from "mobx-react";
import { Button, Modal, Radio, Form, Row, Col, Typography, message } from "antd";
import customParseFormat from "dayjs/plugin/customParseFormat";
import SelectField from "components/forms/items/SelectField";
import InputField from "components/forms/items/InputField";
import DateField from "components/forms/items/DateField";
import { CheckOutlined } from "@ant-design/icons";
import type { InvoiceStoreClass } from "stores/InvoiceStore";

const { Title } = Typography;
dayjs.extend(customParseFormat);
interface Props {
  visible: boolean;
  setPaymentModal: (visible: boolean) => void;
  invoiceStore?: InvoiceStoreClass;
}

const PaymentModal: React.FC<Props> = ({ visible, invoiceStore, setPaymentModal }) => {
  const [paymentForm] = Form.useForm();
  const [paymentType, setPaymentType] = useState("Payment");
  useEffect(() => {
    var initFormValues;
    if (invoiceStore?.activePayment.Key) {
      initFormValues = {
        PaymentMethodDescription: invoiceStore?.activePayment?.PaymentMethodDescription,
        Amount: Math.abs(invoiceStore?.activePayment?.Amount),
        PaymentDate:
          invoiceStore?.activePayment?.PaymentDate &&
          new Date(invoiceStore?.activePayment?.PaymentDate).getTime() > 0
            ? dayjs(invoiceStore?.activePayment?.PaymentDate)
            : undefined,
        PaymentDueDate:
          invoiceStore?.activePayment?.PaymentDate &&
          new Date(invoiceStore?.activePayment?.PaymentDate).getTime() > 0
            ? dayjs(invoiceStore?.activePayment?.PaymentDate)
            : undefined,
        CreditCardNumberMasked: invoiceStore?.activePayment?.CreditCardNumberMasked,
        ExpirationDate:
          invoiceStore?.activePayment?.ExpirationDate &&
          new Date(invoiceStore?.activePayment?.ExpirationDate).getTime() > 0
            ? dayjs(invoiceStore?.activePayment?.ExpirationDate)
            : undefined,
        ReferenceNumber: invoiceStore?.activePayment?.ReferenceNumber,
        PaymentType: invoiceStore?.activePayment?.PaymentType,
        AuthorizationDate:
          invoiceStore?.activePayment?.AuthorizationDate &&
          new Date(invoiceStore?.activePayment?.AuthorizationDate).getTime() > 0
            ? dayjs(invoiceStore?.activePayment?.AuthorizationDate)
            : undefined,
        AuthorizationCode: invoiceStore?.activePayment?.AuthorizationCode,
      };
      setPaymentType(invoiceStore?.activePayment?.PaymentType);
      paymentForm.setFieldsValue({ ...initFormValues });
    }
  }, [invoiceStore?.activePayment]);

  const handlePaymentSubmit = async (values: any) => {
    const formValues = paymentForm.getFieldsValue(true);
    if (!formValues.Amount) {
      message.error("Amount is required");
      return;
    }

    if (
      invoiceStore?.activeInvoice?.OpenBalance &&
      paymentType === "payment" &&
      formValues.Amount > invoiceStore?.activeInvoice!.OpenBalance
    ) {
      message.error("Amount is greater than open balance");
      return;
    }
    const changedData: any = {};
    if (invoiceStore?.activePayment?.Key) {
      for (let line in formValues) {
        if (formValues[line] !== invoiceStore?.activePayment![line]) {
          changedData[line] = formValues[line];
        }
      }
    } else {
      for (let line in formValues) {
        changedData[line] = formValues[line];
      }
    }

    if (changedData.Amount && paymentType === "Refund") {
      changedData.Amount *= -1;
    }

    if (invoiceStore?.activePayment.Key) {
      changedData.Key = invoiceStore?.activePayment.Key;
    }

    const isCreditCardPayment = changedData.PaymentMethodDescription === "CreditCard";
    changedData.InvoiceKey = invoiceStore?.activeInvoice?.Key;
    if (isCreditCardPayment && !invoiceStore?.activePayment.Key) {
      changedData.PendingPaying = true;
    } else if (!invoiceStore?.activePayment.Key) {
      changedData.PendingPaying = false;
    }
    // changedData.CustomerDeposit = true;
    // changedData.InvoiceKey = undefined;
    // changedData.ClientKey = "00-000-10103";
    setPaymentModal(false);
    await invoiceStore?.addPayment(changedData);

    if (isCreditCardPayment) {
      invoiceStore?.setDrawerType("payment");
    }
  };

  const isEditing = invoiceStore?.activePayment?.Key ? true : false;

  const PaymentModalHeader = () => (
    <Row>
      <Col xs={10} offset={1}>
        <Title level={3}>{isEditing ? "Edit Payment" : "Add Payment"}</Title>
      </Col>
      <Col
        xs={13}
        style={{
          textAlign: "right",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          icon={<CheckOutlined />}
          // disabled={isLoading}
          style={{
            backgroundColor: "rgb(255, 179, 28)",
            borderColor: "rgb(255, 179, 28)",
            color: "white",
          }}
          className="btn-action"
          shape="round"
          onClick={async () => await paymentForm.validateFields().then(() => paymentForm.submit())}
        >
          Save
        </Button>
      </Col>
    </Row>
  );

  const typeSpecificField = () => {
    switch (paymentForm.getFieldValue("PaymentMethodDescription")) {
      case "Cash":
        alert("cash");
        break;
      case "Check":
        alert("check");
        break;
      case "CreditCard":
        alert("credit card");
        break;
    }
  };

  return (
    <Modal
      title={<PaymentModalHeader />}
      visible={visible}
      footer={null}
      onCancel={() => setPaymentModal(false)}
    >
      <Form form={paymentForm} onFinish={handlePaymentSubmit}>
        <div style={{ padding: "2rem" }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item name="PaymentType">
                <Radio.Group
                  onChange={(e: any) => {
                    setPaymentType(e.target.value);
                    paymentForm.setFieldsValue({
                      PaymentType: e.target.value,
                    });
                  }}
                  defaultValue={paymentType}
                >
                  <Radio value={"Payment"}>Payment</Radio>
                  {/* <Radio value={"Refund"}>Refund</Radio> */}
                </Radio.Group>
              </Form.Item>
              <Form.Item name="PaymentMethodDescription">
                <SelectField
                  value={invoiceStore?.activeInvoice?.InvoiceType}
                  options={[
                    { label: "Cash", value: "Cash" },
                    // { label: "Check", value: "Check" },
                    { label: "Credit Card", value: "CreditCard" },
                    // { label: "Gift Certificate", value: "GiftCertificate" },
                  ]}
                  label="Payment Method"
                  showSearch={false}
                  showArrow={false}
                  suffixIcon={<></>}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="Amount">
                <InputField
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  currency
                  bordered
                  label={"Payment Amount"}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="PaymentDate">
                <DateField
                  label={"Payment Date"}
                  suffixIcon={<></>}
                  allowClear={false}
                  placeholder=""
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="PaymentDueDate">
                <DateField
                  label={"Payment Due Date"}
                  suffixIcon={<></>}
                  allowClear={false}
                  placeholder=""
                />
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item name="PaymentDueDate">
                <DateField
                  label={"Payment Due Date"}
                  suffixIcon={<></>}
                  allowClear={false}
                  placeholder=""
                />
              </Form.Item>
            </Col> */}
            {/* <Col span={24}>
              <Form.Item name="Refundable">
                <Checkbox
                  onChange={(e: any) => {
                    paymentForm.setFieldsValue({
                      Refundable: e.target.checked,
                    });
                  }}
                >
                  Refundable
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="FuturePayment">
                <Checkbox
                  onChange={(e: any) => {
                    paymentForm.setFieldsValue({
                      FuturePayment: e.target.checked,
                    });
                  }}
                >
                  Future Payment
                </Checkbox>
              </Form.Item>
            </Col> */}
            {isEditing && (
              <>
                {" "}
                <Col span={24}>
                  <Form.Item name="CreditCardNumberMasked">
                    <InputField disabled bordered label={"Card Number"} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="ExpirationDate">
                    <DateField
                      disabled
                      label={"Expiration Date"}
                      suffixIcon={<></>}
                      allowClear={false}
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="ReferenceNumber">
                    <InputField disabled bordered label={"Reference Number"} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="AuthorizationCode">
                    <InputField disabled bordered label={"Authorization Code"} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="AuthorizationDate">
                    <DateField
                      disabled
                      label={"Authorization Date"}
                      suffixIcon={<></>}
                      allowClear={false}
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default inject("invoiceStore")(observer(PaymentModal));
