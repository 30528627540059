import React, { useState, useEffect } from 'react';
import { Column } from '@ant-design/plots';

interface Props {
    chartData?: any;
}

const ColumnChart: React.FC<Props> = ({ chartData }) => {
    const [columnChartData, setColumnChartData] = useState<any>();

    let color = [];
    color.push(chartData.Report.chart_color1);
    color.push("#a8071a");

    useEffect(() => {
        if (chartData.ChartData.Chart1List) {
            let chart1List: any = [];
            for (let item of chartData.ChartData.Chart1List) {
                chart1List.push({
                    label: item.Display,
                    type: 'Current',
                    value: item.Actual,
                })
                if (item.Budget) {
                    chart1List.push({
                        label: item.Display,
                        type: 'Budget',
                        value: item.Budget,
                    })
                }
                if (item.PreviousYear) {
                    chart1List.push({
                        label: item.Display,
                        type: 'PreviousYear',
                        value: item.PreviousYear,
                    })
                }
            }
            setColumnChartData(chart1List);
        }

    }, [chartData.ChartData]);

    const config = {
        data: columnChartData,
        isGroup: chartData?.Report?.data_comparison != "None" ? true : false,
        xField: 'label',
        yField: 'value',

        /** 自定义颜色 */
        color: chartData?.Report?.data_comparison != "None" ? color : color[0],
        seriesField: chartData?.Report?.data_comparison != "None" ? 'type' : '',
        marginRatio: 0,
        autoFit: true,
        label: {
            layout: [
                {
                    type: 'interval-adjust-position',
                },
                {
                    type: 'interval-hide-overlap',
                },
                {
                    type: 'adjust-color',
                },
            ],
        },
    };
    return (
        <>
            {columnChartData ? <Column {...config} /> : null}
        </>
    )
};

export default ColumnChart;
