import React, { useState, useEffect } from "react";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Input, Radio } from "antd";
import CountrySelector from "../country-selector/country-selector";
import UsStateSelector from "../us-state-selector/us-state-selector";

const CartShippingForm = (props: any) => {
  const [formValues, setFormValues] = useState<any>({
    firstName: {
      value: "",
      isValid: false,
      isDirty: false,
    },
    lastName: {
      value: "",
      isValid: false,
      isDirty: false,
    },
    addressLine1: {
      value: "",
      isValid: false,
      isDirty: false,
    },
    addressLine2: {
      value: "",
      isValid: false,
      isDirty: false,
    },
    city: {
      value: "",
      isValid: false,
      isDirty: false,
    },
    state: {
      value: "",
      isValid: false,
      isDirty: false,
    },
    region: {
      value: "",
      isValid: false,
      isDirty: false,
    },
    zipCode: {
      value: "",
      isValid: false,
      isDirty: false,
    },
    country: {
      value: "",
      isValid: false,
      isDirty: false,
    },
  });
  const [isFormValid, setFormValid] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState("standard");
  const [useClientAddress, setUseClientAddress] = useState(false);
  const [isDisabled, setButtonStatus] = useState(true);
  const markAsTouched = (fieldName: string) => {
    formValues[fieldName].isTouched = true;
    setFormValues({ ...formValues });
    validateForm();
  };
  const updateFieldValue = (fieldName: string, value: string) => {
    formValues[fieldName].value = value;
    setFormValues({ ...formValues });
    if (formValues[fieldName].isTouched) {
      validateForm();
    }
  };

  const validateForm = () => {
    let isFormValid = true;
    Object.keys(formValues).forEach((fieldName, index) => {
      switch (fieldName) {
        case "email": {
          const emailRegex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (emailRegex.test(String(formValues.email.value).toLowerCase())) {
            formValues.email.isValid = true;
          } else {
            formValues.email.isValid = false;
            isFormValid = false;
          }
          break;
        }
        case "password": {
          if (formValues.password.value.length >= 8) {
            formValues.password.isValid = true;
          } else {
            formValues.password.isValid = false;
            isFormValid = false;
          }
          break;
        }
        // no default
      }
    });
    setFormValues({ ...formValues });
    setFormValid(isFormValid);
  };

  const _getDeliveryWarpperClass = (method: string) => {
    if (method === deliveryMethod) {
      return "deliveryMethod selected";
    } else {
      return "deliveryMethod";
    }
  };

  useEffect(() => {
    if (isFormValid) {
      setButtonStatus(false);
    } else {
      setButtonStatus(true);
    }
  }, [isFormValid]);

  console.log("formValues.country.value :>> ", formValues.country.value);

  return (
    <>
      <div className="shippingInformation">
        <div className="labelWrapper">
          <h2>Shipping information</h2>
        </div>
        <div className="clentAddressWrapper">
          <Checkbox
            value={useClientAddress}
            onChange={(e) => {
              setUseClientAddress(e.target.checked);
            }}
          >
            Use client address
          </Checkbox>
          {useClientAddress && (
            <div className="clientAddress">
              Name: <span>Demo Demo</span>
              <br />
              Street: <span>Demo Street</span>
              <br />
              Address: <span>Demo Address</span>
              <br />
              State: <span>Demo State</span>
              <br />
              Country: <span>Demo Country</span>
              <br />
            </div>
          )}
        </div>
        {!useClientAddress && (
          <div className="addressForm">
            <div className="formRow">
              <div className="inputWrapper">
                <p className="label">
                  First Name &nbsp;
                  <span className="errorText">
                    {formValues.firstName.isTouched &&
                      !formValues.firstName.isValid &&
                      "Cannot be empty"}
                  </span>
                </p>
                <Input
                  placeholder=""
                  value={formValues.firstName.value}
                  onChange={(e) =>
                    updateFieldValue("firstName", e.target.value)
                  }
                  onBlur={() => markAsTouched("firstName")}
                />
              </div>
              <div className="inputWrapper">
                <p className="label">
                  Last Name &nbsp;
                  <span className="errorText">
                    {formValues.lastName.isTouched &&
                      !formValues.lastName.isValid &&
                      "Cannot be empty"}
                  </span>
                </p>
                <Input
                  placeholder=""
                  value={formValues.lastName.value}
                  onChange={(e) => updateFieldValue("lastName", e.target.value)}
                  onBlur={() => markAsTouched("lastName")}
                />
              </div>
            </div>
            <div className="formRow">
              <div className="inputWrapper">
                <p className="label">
                  Address Line 1 &nbsp;
                  <span className="errorText">
                    {formValues.addressLine1.isTouched &&
                      !formValues.addressLine1.isValid &&
                      "Cannot be empty"}
                  </span>
                </p>
                <Input
                  placeholder=""
                  value={formValues.addressLine1.value}
                  onChange={(e) =>
                    updateFieldValue("addressLine1", e.target.value)
                  }
                  onBlur={() => markAsTouched("addressLine1")}
                />
              </div>
              <div className="inputWrapper">
                <p className="label">
                  Address Line 2 &nbsp;
                  <span className="errorText">
                    {formValues.addressLine2.isTouched &&
                      !formValues.addressLine2.isValid &&
                      "Cannot be empty"}
                  </span>
                </p>
                <Input
                  placeholder=""
                  value={formValues.addressLine2.value}
                  onChange={(e) =>
                    updateFieldValue("addressLine2", e.target.value)
                  }
                  onBlur={() => markAsTouched("addressLine2")}
                />
              </div>
            </div>
            <div className="formRow">
              <div className="inputWrapper">
                <p className="label">
                  City &nbsp;
                  <span className="errorText">
                    {formValues.city.isTouched &&
                      !formValues.city.isValid &&
                      "Cannot be empty"}
                  </span>
                </p>
                <Input
                  placeholder=""
                  value={formValues.city.value}
                  onChange={(e) => updateFieldValue("city", e.target.value)}
                  onBlur={() => markAsTouched("city")}
                />
              </div>
              <div className="inputWrapper">
                {formValues?.country?.value === "USA" ? (
                  <>
                    <p className="label">
                      State &nbsp;
                      <span className="errorText">
                        {formValues.state.isTouched &&
                          !formValues.state.isValid &&
                          "Cannot be empty"}
                      </span>
                    </p>
                    <UsStateSelector
                      width="100%"
                      onStateSelect={(state: string) =>
                        updateFieldValue("state", state)
                      }
                      value={formValues.state.value}
                    />
                  </>
                ) : (
                  <>
                    <p className="label">
                      Region &nbsp;
                      <span className="errorText">
                        {formValues.region.isTouched &&
                          !formValues.region.isValid &&
                          "Cannot be empty"}
                      </span>
                    </p>
                    <Input
                      placeholder=""
                      value={formValues.region.value}
                      onChange={(e) =>
                        updateFieldValue("region", e.target.value)
                      }
                      onBlur={() => markAsTouched("region")}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="formRow">
              <div className="inputWrapper">
                <p className="label">
                  ZIP Code &nbsp;
                  <span className="errorText">
                    {formValues.zipCode.isTouched &&
                      !formValues.zipCode.isValid &&
                      "Cannot be empty"}
                  </span>
                </p>
                <Input
                  placeholder=""
                  value={formValues.zipCode.value}
                  onChange={(e) => updateFieldValue("zipCode", e.target.value)}
                  onBlur={() => markAsTouched("zipCode")}
                />
              </div>
              <div className="inputWrapper">
                <p className="label">
                  Country &nbsp;
                  <span className="errorText">
                    {formValues.country.isTouched &&
                      !formValues.country.isValid &&
                      "Cannot be empty"}
                  </span>
                </p>
                <CountrySelector
                  width="100%"
                  onCountrySelect={(country: string) =>
                    updateFieldValue("country", country)
                  }
                  value={formValues.country.value}
                />
                {/* <Input
                    placeholder=""
                    value={formValues.country.value}
                    onChange={(e) => updateFieldValue('country', e.target.value)}
                    onBlur={() => markAsTouched('country')}
                  /> */}
              </div>
            </div>
          </div>
        )}{" "}
        <div className="labelWrapper">
          <h3>SELECT A METHOD</h3>
        </div>
        <div className="deliveryMethods">
          <div
            className={_getDeliveryWarpperClass("standard")}
            onClick={(e) => setDeliveryMethod("standard")}
          >
            <div className="buttonPart">
              <Radio checked={deliveryMethod === "standard"}>
                Standard Ground
              </Radio>
            </div>
            <div className="pricePart">$0.00</div>
          </div>
          <div
            className={_getDeliveryWarpperClass("overnight")}
            onClick={(e) => setDeliveryMethod("overnight")}
          >
            <div className="buttonPart">
              <Radio checked={deliveryMethod === "overnight"}>Overnight</Radio>
            </div>
            <div className="pricePart">$15.00</div>
          </div>
        </div>
        <div className="checkoutWizardBottomNavigator">
          <div className="leftPart">
            {/* <ArrowLeftOutlined /> &nbsp; Back */}
          </div>
          <div className="rightPart">
            <button className="submit" onClick={props.navigateToNextStep}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartShippingForm;
