import React from "react";
import "./cart-loader.scss";
import { inject, observer } from "mobx-react";

const CartLoader = (props: any) => {
  if (props.cartStore.isLoading) {
    return (
      <>
        <div className="cartLoaderWrapper">
          <div className="bottomPanel">
            <div className="loaderWarpper">
              <div className="loader"></div>
            </div>
            Please wait
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default inject("cartStore")(observer(CartLoader));
