import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Affix,
  Card,
  Grid,
  Row,
  Col,
  Empty,
  List,
  Spin,
  DatePicker,
  Button,
  Table,
  Typography,
  message,
} from "antd";
import { inject, observer } from "mobx-react";
import SearchInput from "components/forms/SearchInput";
import { toJS } from "mobx";
import { saveLocalStorage } from "components/util/localStorage";

import SearchDropDown from "components/search/SearchDropdown";
import { ClientStoreClass } from "stores/ClientStore";
import ClientHubSearchPage from "pages/ClientsHub/ClientHubSearchPage";
import { InvoiceStoreClass } from "stores/InvoiceStore";
import { SessionStoreClass } from "stores/SessionStore";
import { InvoiceList } from "interfaces/invoice.int";
import RenderInvoice from "components/invoice/RenderInvoice";
import InvoiceDrawerView from "components/drawers/InvoiceDrawerView/InvoiceDrawerView";
import SessionDrawerForm from "components/drawers/SessionDrawerForm";
import CustomHeader from "components/header/CustomHeader";
import SearchSelect from "components/forms/items/SearchSelect";
import dayjs from "dayjs";
import { AuthStoreClass } from "stores/AuthStore";
import ClientTabs from "components/tabs/ClientTabs";
import SearchSessionsList from "components/lists/SearchSessionsList";
import { Content } from "antd/lib/layout/layout";
import { ScheduleStoreClass } from "stores/ScheduleStore";

const { useBreakpoint } = Grid;
const { Text } = Typography;

interface Props {
  clientStore: ClientStoreClass;
  invoiceStore: InvoiceStoreClass;
  sessionStore: SessionStoreClass;
  authStore?: AuthStoreClass;
  scheduleStore?: ScheduleStoreClass;
}
const SearchPage: React.FC<Props> = ({
  clientStore,
  invoiceStore,
  sessionStore,
  authStore,
  scheduleStore,
}) => {
  console.log(scheduleStore);
  const [container] = useState<HTMLDivElement | null>(null);
  const location = useLocation<any>();
  const [sessionKey, setSessionKey] = useState("");

  const [value, setValue] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [searchAutocomplete, setSearchAutocomplete] = useState<any>([]);
  const [invoiceView, setInvoiceView] = useState("Graphical");

  const searchEntities: any = {
    Clients: [
      { value: "lastName", key: "Last Name" },
      { value: "firstName", key: "First Name" },
      { value: "companyName", key: "Company Name" },
      { value: "email", key: "Email" },
      { value: "city", key: "City" },
      { value: "zipCode", key: "Zip Code" },
      { value: "phoneNumber", key: "Phone Number" },
      { value: "clientStatus", key: "Client Status" },
      { value: "clientGroup", key: "Client Group" },
      { value: "organizationid", key: "Organization" },
      { value: "graduationYear", key: "Graduation Year" },
    ],
    Sessions: [
      {
        value: "LastName",
        key: "Client's Last Name",
      },
      {
        value: "SessionNumber",
        key: `${
          authStore?.companySettings.JobDescriptorSingular
            ? authStore?.companySettings.JobDescriptorSingular
            : "Session"
        } Number`,
      },
      {
        value: "StartDate",
        key: `${
          authStore?.companySettings.JobDescriptorSingular
            ? authStore?.companySettings.JobDescriptorSingular
            : "Session"
        } Date`,
      },
      {
        value: "SessionType",
        key: `${
          authStore?.companySettings.JobDescriptorSingular
            ? authStore?.companySettings.JobDescriptorSingular
            : "Session"
        } Type`,
      },
      {
        value: "SessionStatus",
        key: `${
          authStore?.companySettings.JobDescriptorSingular
            ? authStore?.companySettings.JobDescriptorSingular
            : "Session"
        } Status`,
      },
      {
        value: "CompanyName",
        key: "Company Name",
      },
      {
        value: "OnlineBookingConfirmationNo",
        key: "Booking Confirmation Number",
      },
      {
        value: "organizationid",
        key: "Organization",
      },
      {
        value: "GraduationYear",
        key: "Graduation Year",
      },
    ],
    Invoices: [
      {
        value: "InvoiceNumber",
        key: "Invoice Number",
      },
      {
        value: "invoiceStatus",
        key: "Invoice Status",
      },
      {
        value: "invoicedate",
        key: "Invoice Date",
      },
      {
        value: "invoiceduedate",
        key: "Invoice Due Date",
      },
      {
        value: "SessionNumber",
        key: `${
          authStore?.companySettings.JobDescriptorSingular
            ? authStore?.companySettings.JobDescriptorSingular
            : "Session"
        } Number`,
      },
      {
        value: "sessiondate",
        key: `${
          authStore?.companySettings.JobDescriptorSingular
            ? authStore?.companySettings.JobDescriptorSingular
            : "Session"
        } Date`,
      },
      {
        value: "LastName",
        key: "Client's Last Name",
      },
      {
        value: "ClientNumber",
        key: "Client Number",
      },
      {
        value: "weborderno",
        key: "Web Order Number",
      },
      {
        value: "billtoname",
        key: "Bill-To-Name",
      },
    ],
  };

  useEffect(() => {
    clientStore?.getOrganization();
  }, [clientStore]);

  const generateSearchAutocomplete = (searchByValue: any) => {
    let options: any[] = [];
    if (searchByValue === "SessionType") {
      sessionStore?.sessionTypesList.map((item: any) => {
        options.push({ label: item.Description, value: item.Key });
      });
      setSearchAutocomplete(options);
    } else if (searchByValue === "SessionStatus") {
      sessionStore?.sessionStatusList.map((item: any) => {
        options.push({ label: item.Description, value: item.Key });
      });
      setSearchAutocomplete(options);
    } else if (searchByValue === "clientStatus") {
      clientStore?.clientStatuses.map((item: any) => {
        options.push({ value: item.Description });
      });
      setSearchAutocomplete(options);
    } else if (searchByValue === "clientGroup") {
      clientStore?.clientGroups.map((item: any) => {
        options.push({ value: item.Description });
      });
      setSearchAutocomplete(options);
    } else if (searchByValue === "invoiceStatus") {
      invoiceStore?.invoiceStatuses.map((item: any) => {
        options.push({ value: item.Description });
      });
      setSearchAutocomplete(options);
    } else if (searchByValue === "organizationid") {
      setSearchAutocomplete(clientStore?.selectOrganization);
    } else {
      setSearchAutocomplete("");
    }
  };

  useEffect(() => {
    clientStore?.updateSearchType(location?.state?.searchType);
    clientStore?.setSearchBy(searchEntities[location?.state?.searchType][0]);
  }, [location?.state?.searchType]);

  useEffect(() => {
    generateSearchAutocomplete(clientStore?.searchBy.value);
    setValue("");
  }, [clientStore?.searchBy]);

  const screens = useBreakpoint();

  let timeout: ReturnType<typeof setTimeout>;

  const [flag, setFlag] = useState<boolean>(false);

  const onEnter = async () => {
    setFlag(true);
    switch (clientStore?.searchType) {
      case "Clients":
        clientStore?.updateSearchTerm(value);
        try {
          await clientStore?.getClients();
        } catch (error) {
          setError(true);
        }
        break;
      case "Invoices":
        const action = toJS(clientStore?.searchBy.value);
        const data = { action, value: value };
        invoiceStore?.setActiveSearch(data);
        try {
          await invoiceStore?.getInvoices(invoiceStore?.activeSearch); // Changed the search function based on new API
        } catch (err) {
          setError(true);
        }
        break;
      case "Sessions":
        const sessionData = { action: clientStore?.searchBy?.value, value: value };
        sessionStore?.setActiveSearch(sessionData);
        try {
          await sessionStore?.getSessionsViaSearch();
        } catch (err) {
          setError(true);
        }
        break;
    }
    clearTimeout(timeout);
  };

  //Re used the same function for Date Select
  const onSearchSelect = async (value: any) => {
    setFlag(true);

    if (clientStore?.searchType === "Clients") {
      //The code below gets the corresponding clientStatusKey for the given
      //value (Description), and then it hits the API with clientStatusKey
      //to get correct results.
      if (clientStore?.searchBy?.value === "clientStatus") {
        clientStore?.updateSearchTerm(
          clientStore?.clientStatuses?.find((o) => o.Description === value.value)?.Key
        );
      }
      //if the search by isn't clientStatus, search set to the value passed
      else {
        clientStore?.updateSearchTerm(value.value);
      }
      try {
        await clientStore?.getClients();
      } catch (error) {
        setError(true);
      }
    }

    if (clientStore?.searchType === "Invoices") {
      if (clientStore?.searchBy?.value === "invoiceStatus") {
        const action = toJS(clientStore?.searchBy.value);
        const data = {
          action,
          value: invoiceStore?.invoiceStatuses.find((o) => o.Description === value.value)?.Key,
        };
        invoiceStore?.setActiveSearch(data);
        try {
          await invoiceStore?.getInvoices(invoiceStore?.activeSearch);
        } catch (err) {
          setError(true);
        }
      }

      if (
        clientStore?.searchBy?.value === "invoiceduedate" ||
        clientStore?.searchBy?.value === "invoicedate" ||
        clientStore?.searchBy?.value === "sessiondate"
      ) {
        const action = toJS(clientStore?.searchBy.value);
        const data = { action, value: dayjs(value._d).format("YYYY-MM-DD") };
        invoiceStore?.setActiveSearch(data);
        try {
          await invoiceStore?.getInvoices(invoiceStore?.activeSearch);
        } catch (err) {
          setError(true);
        }
      }
    }

    const sessionData = { action: clientStore?.searchBy?.value, value: value?.value };
    //Added this piece of code to check if search criteria is based on Date
    //Functionality is then changed accordingly
    if (clientStore?.searchBy?.value === "StartDate") {
      setValue(dayjs(value._d).format("YYYY-MM-DD"));
      sessionData.value = dayjs(value._d).format("YYYY-MM-DD");
    }

    sessionStore?.setActiveSearch(sessionData);
    try {
      await sessionStore?.getSessionsViaSearch();
    } catch (err) {
      setError(true);
    }
  };

  const onKey = (e: { keyCode: number; target: { value: any } }) => {
    setFlag(true);
    clearTimeout(timeout);
    if (e.keyCode === 13) {
      // Prevents loading again for enter value
      return;
    }
    switch (clientStore?.searchType) {
      case "Clients":
        clientStore?.updateSearchTerm(value);
        timeout = setTimeout(async () => {
          try {
            await clientStore?.getClients();
          } catch (err) {
            setError(true);
          }
        }, 500);
        break;
      case "Invoices":
        const data = { action: clientStore?.searchBy?.value, value: value };
        invoiceStore?.setActiveSearch(data);
        timeout = setTimeout(async () => {
          try {
            await invoiceStore?.getInvoices(data);
          } catch (error) {
            setError(true);
          }
        }, 2000);

        break;
      case "Sessions":
        const sessionData = { action: clientStore?.searchBy?.value, value: value };
        sessionStore?.setActiveSearch(sessionData);
        timeout = setTimeout(async () => {
          try {
            await sessionStore?.getSessionsViaSearch();
          } catch (err) {
            setError(true);
          }
        }, 2000);

        break;
    }
  };

  useEffect(() => {
    setFlag(false);
    setValue("");
    setError(false);
  }, [clientStore.searchType]);

  const handleInvoiceView = () => {
    if (invoiceView === "Graphical") {
      setInvoiceView("Grid");
    } else {
      setInvoiceView("Graphical");
    }
  };

  const invoiceColumns = [
    {
      title: "Invoice Number",
      dataIndex: "InvoiceNumber",
      key: "InvoiceNumber",
      render: (number: string) => {
        return invoiceFieldHandler(number);
      },
    },
    {
      title: "Due Date",
      dataIndex: "DueDate",
      key: "DueDate",
      render: (dueDate: string) => {
        return dayjs(dueDate).format("MM/DD/YYYY");
      },
    },
    {
      title: "Invoice Type",
      dataIndex: "InvoiceType",
      key: "InvoiceType",
      render: (invoiceType: string) => {
        return invoiceFieldHandler(invoiceType);
      },
    },
    {
      title: "Open Balance",
      dataIndex: "OpenBalance",
      key: "OpenBalance",
      render: (openBalance: number) => {
        return (
          <Text strong>
            {`${openBalance.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}`}
          </Text>
        );
      },
    },
    {
      title: "Total",
      dataIndex: "Total",
      key: "Total",
      render: (total: number) => {
        return (
          <Text strong>
            {`${total.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}`}
          </Text>
        );
      },
    },
  ];

  const NotAvailable = () => {
    return <Text disabled>Not Available</Text>;
  };

  const invoiceFieldHandler = (field: any) => {
    if (!field) return <NotAvailable />;
    else {
      return <Text strong>{field}</Text>;
    }
  };

  const handleClick = async (record: any, drawerType?: "detail" | "payment") => {
    if (
      !record.InvoiceKey ||
      record.InvoiceKey === "" ||
      (typeof record.OpenBalance !== "undefined" &&
        record.OpenBalance <= 0 &&
        drawerType === "payment")
    )
      return;
    try {
      await invoiceStore?.getInvoiceDetails(record.InvoiceKey);
      if (drawerType) {
        invoiceStore?.setDrawerType(drawerType);
        invoiceStore?.setSelectToggle(true);
      }
      saveLocalStorage({
        name: "Viewed",
        value: `Invoice - ${record.FirstName + " " + record.LastName} ${
          record.Description ? " - " + record.Description : ""
        }`,
        type: {
          key: record.InvoiceKey,
          value: "Invoice",
        },
      });
    } catch (e) {
      message.error("Sorry, something has gone wrong. Please try again later.");
    }
  };

  return (
    <>
      <div className="ant-layout-content main-content search-page-content">
        <CustomHeader
          title={`Search ${
            location.state?.searchType === "Sessions"
              ? authStore?.companySettings.JobDescriptorPlural
                ? authStore?.companySettings.JobDescriptorPlural
                : "Sessions"
              : location.state?.searchType
          }`}
        />

        <Content style={{ overflowY: "hidden" }} className="main-content-pads">
          <Card
            bordered={false}
            headStyle={{ border: "none" }}
            className=""
            title={
              <>
                <Row
                  style={{ alignItems: "center" }}
                  gutter={[16, 16]}
                  align="middle"
                  justify="center"
                >
                  {/* <Col xs={12} sm={4}>
                    <SearchDropDown
                      defaultValue={clientStore?.searchType}
                      label={"Search Type"}
                      searchValues={Object.keys(searchEntities)}
                      setSearchType={handleUpdateSearchType}
                    />
                  </Col> */}
                  <Col xs={12} sm={8}>
                    <SearchDropDown
                      defaultValue={searchEntities[location?.state?.searchType][0]}
                      label={"Search By"}
                      searchValues={searchEntities[clientStore?.searchType]}
                      clientStore={clientStore}
                    />
                  </Col>
                  <Col xs={24} sm={16}>
                    {/* <Affix target={() => container} offsetTop={70}> */}
                    {clientStore?.searchBy.value === "StartDate" ||
                    clientStore?.searchBy.value === "invoicedate" ||
                    clientStore?.searchBy.value === "invoiceduedate" ||
                    clientStore?.searchBy.value === "sessiondate" ? (
                      <DatePicker
                        onChange={onSearchSelect}
                        size={"large"}
                        allowClear={false}
                        className="search-date-picker"
                        placeholder={`Please select ${clientStore?.searchBy.children}`}
                      />
                    ) : searchAutocomplete.length ? (
                      <SearchSelect
                        placeholder={`Please select ${
                          clientStore?.searchBy?.children
                            ? clientStore?.searchBy?.children.toLowerCase()
                            : clientStore?.searchBy?.key.toLowerCase()
                        } here to search`}
                        searchClass="search-listing"
                        searchAutocomplete={searchAutocomplete}
                        onEnter={onSearchSelect}
                        searchValue={value}
                      />
                    ) : (
                      <SearchInput
                        placeholder={`Please type ${
                          clientStore?.searchBy?.children
                            ? clientStore?.searchBy?.children.toLowerCase()
                            : clientStore?.searchBy?.key.toLowerCase()
                        } here to search`}
                        searchClass="search-listing"
                        onKeyUp={(e: any) => {
                          onKey(e);
                        }}
                        onChange={(e) => {
                          clientStore?.updateSearchTerm(e.target.value);
                          setValue(e.target.value);
                          setError(false);
                          clearTimeout(timeout);
                        }}
                        onPressEnter={() => {
                          onEnter();
                          clearTimeout(timeout);
                        }}
                        value={value}
                      />
                    )}
                    {/* searchAutocomplete={searchAutocomplete}
                    onEnter={onEnter} */}
                    {/* </Affix> */}
                  </Col>
                </Row>
              </>
            }
          >
            {!flag ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={"Please search for something to display "}
              />
            ) : error ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Data"} />
            ) : clientStore?.searchType === "Clients" ? (
              <ClientHubSearchPage />
            ) : clientStore?.searchType === "Sessions" ? (
              <Spin size={"large"} spinning={sessionStore?.isLoading}>
                <SearchSessionsList setSessionKey={setSessionKey} />
              </Spin>
            ) : (
              <Spin size={"large"} spinning={invoiceStore?.isLoading}>
                <Row
                  style={{
                    justifyContent: "flex-end",
                    paddingBottom: 20,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                >
                  <Button
                    onClick={() => {
                      handleInvoiceView();
                    }}
                  >
                    {invoiceView === "Graphical" ? "Grid " : "Graphical "} View
                  </Button>
                </Row>
                {invoiceView === "Graphical" ? (
                  <List
                    pagination={{
                      pageSize: 20,
                      showTotal(total, range) {
                        return `${range[0]} - ${range[1]} of ${total} items`;
                      },
                    }}
                    dataSource={invoiceStore?.invoiceList}
                    renderItem={(item: InvoiceList) => {
                      return <RenderInvoice item={item} openInvoiceDetails={true} />;
                    }}
                    style={{ border: 0 }}
                  />
                ) : (
                  <Table
                    columns={invoiceColumns}
                    dataSource={invoiceStore?.invoiceList}
                    pagination={{
                      pageSize: 20,
                      showTotal(total, range) {
                        return `${range[0]} - ${range[1]} of ${total} items`;
                      },
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => {
                          handleClick(record, "detail");
                        },
                      };
                    }}
                  />
                )}
              </Spin>
            )}
          </Card>
        </Content>
      </div>
      <InvoiceDrawerView />
      <SessionDrawerForm sessionKey={sessionKey} />
      <ClientTabs sessionKey={sessionKey} />
    </>
  );
};

export default inject(
  "clientStore",
  "invoiceStore",
  "taskStore",
  "sessionStore",
  "appointmentStore",
  "authStore",
  "scheduleStore"
)(observer(SearchPage));
