import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { autorun, toJS } from "mobx";
import ProTable from "@ant-design/pro-table";

import { Avatar, Col, ConfigProvider, List, Row, Spin, Typography } from "antd";
import enUS from "antd/lib/locale/en_US";

import { Button } from "antd";

import "./ClientHub.scss";
import "@ant-design/pro-table/dist/table.css";
import { UserOutlined } from "@ant-design/icons";

export type TableListItem = {
  key: number;
  name: string;
  containers: number;
  creator: string;
  status: string;
  createdAt: number;
  memo: string;
};

export type ClientListItem = {
  clientNumber: number;
  firstName: string;
  lastName: string;
  addressCity: string;
  addressState: string;
  addressCountry: string;
  addressPostcode: string;
  allowCallMarketing: boolean;
  allowEmailMarketing: boolean;
  allowTextMarketing: boolean;
  status: string;
  birthDate: number;
  createDate: number;
  modifiedDate: number;
  email: string;
  primaryLocationNumer: string;
  phoneNumber0: string;
  phoneNumber1: string;
};

const { Text, Title } = Typography;

const ClientsWideView = (props: any) => {
  const { clientStore } = props;
  const [, setClients] = useState([]);
  const [, setIsLoading] = useState(false);

  const [view, setView] = useState("Grid");
  const innerHeight: number = window.innerHeight;

  const compareStrings = (a: string, b: string) => {
    a = a || "";
    b = b || "";
    return a.localeCompare(b);
  };
  const clientColumns = [
    {
      title: "Client No",
      dataIndex: "ClientNumber",
      width: 20,
      fixed: true,
      sorter: (a: any, b: any) => parseInt(a.ClientNumber) - parseInt(b.ClientNumber),
    },
    {
      title: "First Name",
      dataIndex: "FirstName",
      width: 30,
      fixed: true,
      sorter: (a: any, b: any) => compareStrings(a.FirstName, b.FirstName),
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
      width: 30,
      fixed: true,
      sorter: (a: any, b: any) => compareStrings(a.LastName, b.LastName),
    },

    {
      title: "Address Line",
      width: 50,
      dataIndex: "AddressLine1",
      sorter: (a: any, b: any) => compareStrings(a.AddressLine1, b.AddressLine1),
    },
    {
      title: "Address City",
      width: 50,
      dataIndex: "AddressCity",
      sorter: (a: any, b: any) => compareStrings(a.AddressCity, b.AddressCity),
    },
    {
      title: "Address State",
      width: 30,
      dataIndex: "AddressState",
      sorter: (a: any, b: any) => compareStrings(a.AddressState, b.AddressState),
    },
    {
      title: "Address Country",
      width: 30,
      dataIndex: "AddressCountry",
      sorter: (a: any, b: any) => compareStrings(a.AddressCountry, b.AddressCountry),
    },
    {
      title: "Address Postcode",
      width: 40,
      dataIndex: "AddressPostcode",
      sorter: (a: any, b: any) => compareStrings(a.AddressPostcode, b.AddressPostcode),
    },

    {
      title: "Email",
      width: 50,
      dataIndex: "Email",
      sorter: (a: any, b: any) => compareStrings(a.Email, b.Email),
    },
    {
      title: "Modified Date",
      width: 40,
      dataIndex: "ModifiedDate",
      render: (value: any) => {
        return <span>{dateFormat(value)}</span>;
      },
    },
    {
      title: "Birth Date",
      width: 40,
      dataIndex: "BirthDate",
      render: (value: any) => {
        return <span>{dateFormat(value)}</span>;
      },
    },
    {
      title: "Create Date",
      width: 40,
      dataIndex: "CreateDate",
      render: (value: any) => {
        return <span>{dateFormat(value)}</span>;
      },
      sorter: (a: any, b: any) => {
        return new Date(a.CreateDate).getTime() - new Date(b.CreateDate).getTime();
      },
    },
    {
      title: "Phone Number",
      dataIndex: "PhoneNumber0",
      width: 40,
    },
  ];

  const _loadClientsFromStore = () => {
    if (props.clientStore?.sortedClientsList?.length) {
      setIsLoading(false);
      setClients(props.clientStore.sortedClientsList);
    }
  };

  const _initiateLoad = async () => {
    setIsLoading(true);
    // _loadClientsFromStore();
    await props.clientStore.sortedClientsList;
    _loadClientsFromStore();
    setIsLoading(false);
  };

  useEffect(() => {
    autorun(() => {
      const clients = toJS(props.clientStore.sortedClientsList);
      setClients(clients);
    });
    _initiateLoad();
  }, []);

  const handleButtonClick = () => {
    clientStore?.setActiveClientKey("");
    clientStore?.setClientInfo({});
    clientStore?.setSelectToggle(true);
  };

  const handleClick = (ClientNumber: any, Key: any) => {
    clientStore?.setActiveClientNumber(ClientNumber || "");
    clientStore?.setSelectToggle(true);
    clientStore?.setActiveClientKey(Key);
  };

  // function created to fix date format
  //called by the render function in the columns
  const dateFormat = (value: any) => {
    if (value === "-") {
      return "-";
    } else return new Date(value).toLocaleDateString("en-Us");
  };

  const handleToggleView = (view: string) => {
    setView(view);
  };

  console.log("rerendered");

  return (
    <>
      <ConfigProvider locale={enUS}>
        <Spin size={"large"} spinning={props?.clientStore?.isLoading}>
          {view === "Grid" ? (
            <ProTable
              className="pro-table"
              columns={clientColumns}
              request={(params, sorter, filter) => {
                return Promise.resolve({
                  // data: tableListDataSource,
                  data: props.clientStore.sortedClientsList,
                  success: true,
                });
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    const newRecord = toJS(record);
                    handleClick(newRecord?.ClientNumber, newRecord?.Key);
                  }, // click row
                  onDoubleClick: () => {
                    debugger;
                    const newRecord = toJS(record);
                    handleClick(newRecord?.ClientNumber, newRecord?.Key);
                  },
                };
              }}
              rowKey="key"
              pagination={{
                showQuickJumper: true,
                pageSize: props?.clientStore?.perPageSize,
                onShowSizeChange: (current, size) => {
                  props?.clientStore?.setPerPageSize(size);
                },
              }}
              scroll={{ x: 2500, y: innerHeight - 400 }}
              search={false}
              dateFormatter="string"
              defaultSize="middle"
              columnsState={{
                persistenceType: "localStorage",
                persistenceKey: "clientsListColumnsState",
              }}
              toolBarRender={() => [
                <Button
                  style={{ backgroundColor: "#708D65", borderColor: "#708D65" }}
                  onClick={handleButtonClick}
                  type="primary"
                  key="primary"
                >
                  Create Client
                </Button>,
                <Button
                  style={{
                    backgroundColor: "rgb(146, 176, 182)",
                    borderColor: "rgb(146, 176, 182)",
                    color: "white",
                  }}
                  onClick={() => handleToggleView("Graphical")}
                >
                  Graphical View
                </Button>,
              ]}
            />
          ) : (
            <Row className="clientGraphicView">
              <Col xs={24} className="clientGraphicView__Action">
                <Title level={4}>Clients</Title>
                <Button onClick={() => handleToggleView("Grid")}>Grid View</Button>
              </Col>
              <Col xs={24}>
                <List
                  itemLayout="horizontal"
                  dataSource={props.clientStore.sortedClientsList}
                  bordered={false}
                  pagination={{
                    pageSize: 10,
                  }}
                  grid={{ gutter: 16, column: 1 }}
                  renderItem={(client: any) => {
                    return (
                      <List.Item
                        onClick={() => {
                          handleClick(client?.ClientNumber, client?.Key);
                        }}
                        key={client?.Key}
                      >
                        <Row className="clientGraphical" align="middle">
                          <Col xs={4} md={2} lg={1}>
                            <Avatar
                              size={36}
                              style={{ backgroundColor: "#b7aea2" }}
                              icon={<UserOutlined />}
                            />
                          </Col>
                          <Col xs={20} md={22} lg={23} className="clientGraphical--Details">
                            <Row className="clientGraphical--Details__Top">
                              <Col sm={12} xs={24}>
                                <Text className="clientGraphical--Details__No">
                                  Client No: <b>{client.ClientNumber}</b>
                                </Text>
                              </Col>
                              <Col sm={12} xs={24}>
                                <Text className="clientGraphical--Details__Date">
                                  Created Date: <b>{dateFormat(client.CreateDate)}</b>
                                </Text>
                              </Col>
                            </Row>
                            <Title className="clientGraphical--Details__Name" level={4}>
                              {client.FirstName + " " + client.LastName}
                            </Title>
                            {client.AddressLine1 ? (
                              <Text className="clientGraphical--Details__Address">
                                {client.AddressLine1 + ", " + client.AddressCity}
                              </Text>
                            ) : (
                              <Text className="clientGraphical--Details__Address">No Address</Text>
                            )}
                          </Col>
                        </Row>
                      </List.Item>
                    );
                  }}
                />
              </Col>
            </Row>
          )}
        </Spin>
      </ConfigProvider>
    </>
  );
};

export default inject("clientStore")(observer(ClientsWideView));
