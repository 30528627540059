import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Form, Radio, message } from "antd";

import type { FormInstance } from "antd/lib/form/hooks/useForm";

import type { ScheduleStoreClass } from "stores/ScheduleStore";
import type { UserStoreClass } from "stores/UserStore";
import type { AuthStoreClass } from "stores/AuthStore";
import { Resource } from "interfaces/schedule.int";

interface Props {
  form?: FormInstance;
  userStore?: UserStoreClass;
  scheduleStore?: ScheduleStoreClass;
  authStore?: AuthStoreClass;
}

const FilterCalendar: React.FC<Props> = ({
  form,
  userStore,
  scheduleStore,
  authStore,
}): React.ReactElement => {
  const initialFormValues = {
    userResourceKey: "",
  };

  useEffect(() => {
    form?.setFieldsValue({
      userResourceKey: `u${authStore?.authenticatedUser.UserKey}`,
    });
    scheduleStore?.setFilterUserKey(authStore?.authenticatedUser.UserKey);
    scheduleStore?.setFilterResourceKey("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore?.authenticatedUser]);

  const handleSubmit = async (values: any) => {
    // First letter of value is type of key, r = resource, u = user
    let userKey = "";
    let resourceKey = "";
    if (values.userResourceKey[0] === "r") {
      resourceKey = values.userResourceKey.slice(1);
      scheduleStore?.setFilterResourceKey(resourceKey);
      scheduleStore?.setFilterUserKey("");
    } else {
      userKey = values.userResourceKey.slice(1);
      scheduleStore?.setFilterUserKey(userKey);
      scheduleStore?.setFilterResourceKey("");
    }
    try {
      await scheduleStore?.getCalendarList(userKey, resourceKey);
    } catch (e) {
      message.error("Unable to fetch schedules. Try logging in again.");
    }
  };

  return (
    <Form form={form} initialValues={initialFormValues} onFinish={handleSubmit}>
      <Form.Item
        labelAlign={"left"}
        labelCol={{ xs: 24 }}
        label="Users and Resources"
        name="userResourceKey"
      >
        <Radio.Group>
          {userStore?.selectUserList?.map((item) => {
            if (!item.ShowOnCalendar) return null;
            return (
              <Radio
                key={`u${item.value}`}
                value={`u${item.value}`}
                style={{ display: "block", marginTop: 5 }}
              >
                {item.label}
              </Radio>
            );
          })}

          {scheduleStore?.resourceList?.map((item: Resource) => {
            return (
              <Radio
                key={`r${item.Key}`}
                value={`r${item.Key}`}
                style={{ display: "block", marginTop: 5 }}
              >
                {item.Description}
              </Radio>
            );
          })}
        </Radio.Group>
      </Form.Item>

      {/* <Form.Item label="Resource" name="resourceKey">
        <Radio.Group>
          
        </Radio.Group>
      </Form.Item> */}
    </Form>
  );
};

export default inject("userStore", "scheduleStore", "authStore")(observer(FilterCalendar));
