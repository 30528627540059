import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Row, Col, Button, Typography } from "antd";
import dayjs from "dayjs";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

import type { ScheduleStoreClass } from "stores/ScheduleStore";
import CalendarHeader from "./CalendarHeader";
import CalendarMonth from "./CalendarMonth";
import CalendarWeek from "./CalendarWeek";
import TimeSchedule from "./TimeSchedule";
import CalendarNavigation from "./CalendarNavigation";

import "./CalendarMobile.scss";
import CalendarYear from "./CalendarYear";

const { Title } = Typography;

interface Props {
  scheduleStore?: ScheduleStoreClass;
}

const CalendarMobile: React.FC<Props> = ({ scheduleStore }) => {
  const [toggleCalendar, setToggleCalendar] = useState(false);

  const handleToggleCalendar = () => {
    setToggleCalendar(!toggleCalendar);
  };

  return (
    <div className="calendar-mobile">
      <div className="calendar-mobile-head">
        {scheduleStore?.calendarMode === "month" ? (
          <Row align="middle">
            <Col>
              <Button
                className="btn-header-title"
                type="text"
                onClick={handleToggleCalendar}
              >
                {toggleCalendar ? <CaretDownOutlined /> : <CaretUpOutlined />}
                <Title className="page-header-title">
                  {dayjs(
                    scheduleStore?.calendarActiveYearMonth.toString(),
                    "YYYYMM"
                  ).format("MMMM YYYY")}
                </Title>
              </Button>
            </Col>
            <Col style={{ marginLeft: "auto" }}>
              <CalendarNavigation
                calendar={toggleCalendar ? "week" : "month"}
              />
            </Col>
          </Row>
        ) : (
          <Row align="middle">
            <Col>
              <Title className="page-header-title">
                {scheduleStore?.calendarActiveYear}
              </Title>
            </Col>
            <Col style={{ marginLeft: "auto" }}>
              <CalendarNavigation calendar="year" />
            </Col>
          </Row>
        )}
      </div>

      {scheduleStore?.calendarMode === "month" ? (
        <>
          <CalendarHeader />
          {toggleCalendar ? (
            <CalendarWeek />
          ) : (
            <CalendarMonth onSelect={() => setToggleCalendar(true)} />
          )}
          <TimeSchedule />
        </>
      ) : (
        <CalendarYear />
      )}
    </div>
  );
};

export default inject("scheduleStore")(observer(CalendarMobile));
