//@ts-ignore
import React, { useRef, useState, useLayoutEffect } from "react";
import { Img } from "react-image";
import Skeleton from "react-loading-skeleton";

const Image = (props: any) => {
  const targetRef: any = useRef(null);
  const [placeholderWidth, setPlaceholderWidth] = useState(0);

  const _calculateImagePlaceholderHeight = () => {
    let placeholderHeight = 0;
    if (placeholderWidth > 0) {
      placeholderHeight = props.ratio * placeholderWidth;
    }
    return placeholderHeight;
  };

  useLayoutEffect(() => {
    if (targetRef.current) {
      setPlaceholderWidth(targetRef.current.offsetWidth);
    }
  }, []);

  return (
    <div className="imageWrapper" ref={targetRef}>
      <Img
        src={[props.src]}
        key={`${props.src}${Math.random()}`}
        loader={
          <>
            <Skeleton width={"100%"} height={_calculateImagePlaceholderHeight()} />
          </>
        }
      />
    </div>
  );
};

export default Image;
