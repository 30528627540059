import axios from "axios";

export const getSessionImagesApiCall = async (sessionId: string) => {
  try {
    const response = await axios({
      method: "GET",
      url: `/ibySession/${sessionId}`,
       params: {
        SessionKey: sessionId,
        ClientKey: "00-000-10103",
       }
    });
    return response;
  } catch (error) {
    
  }
}

export const addImageToFolderApiCall = async (sessionId: string, fileName: string, groupName: string) => {
  try {
    const response = await axios({
      method: "PUT",
      url: `/ibyImageGroupMember`,
      data: {
        FileName: fileName,
        GroupName: groupName,
        SessionKey: sessionId,
        ClientKey: "00-000-10103",
       }
    });
    return response;
  } catch (error) {
    
  }
}

export const removeImageFromFolderApiCall = async (sessionId: string, fileName: string, groupName: string) => {
  try {
    const response = await axios({
      method: "DELETE",
      url: `/ibyImageGroupMember`,
       params: {
        FileName: fileName,
        GroupName: groupName,
        SessionKey: sessionId,
        ClientKey: "00-000-10103",
       }
    });
    return response;
  } catch (error) {
    
  }
}