import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Grid, Row, Col, Typography, Button, Form, Drawer, Space, Input, message } from "antd";
import type { DrawerProps } from "antd/lib/drawer";
import { CheckOutlined, DownOutlined, SoundOutlined, TagOutlined } from "@ant-design/icons";
import InputField from "components/forms/items/InputField";
import SelectField from "components/forms/items/SelectField";
import useWindowDimension from "components/util/Dimension";
import { PersonalDashboardStoreClass } from "stores/PersonalDashboardStore";
const { Title } = Typography;
const { useBreakpoint } = Grid;

interface Props {
  onToggle?: (x: boolean) => void;
  personalDashboardStore?: PersonalDashboardStoreClass;
  setIsDashboardUpdated?: any;
}

const PersonalPerformancePanelDrawerView: React.FC<Props> = ({
  onToggle,
  personalDashboardStore,
  setIsDashboardUpdated,
}) => {
  const screens = useBreakpoint();
  const isLoading: boolean = false;
  const [form] = Form.useForm();
  const { innerWidth } = useWindowDimension();

  const initialFormValues: any = {
    panel_key: "",
    panel_type: "",
    panel_title: "",
    data_type: [],
    date_range: "",
    auto_refresh_rate: "",
  };

  useEffect(() => {
    personalDashboardStore?.getAllPrefereneces();
  }, []);

  const ModalHeader = () => {
    return (
      <Row>
        <Col xs={10}>
          <Title level={2}>Performance Panel</Title>
        </Col>
        <Col
          xs={14}
          style={{
            textAlign: "right",
          }}
        >
          <Space>
            <Button
              disabled={personalDashboardStore?.isLoading}
              type="text"
              onClick={() => toggleDrawer(false)}
            >
              Cancel
            </Button>
            <Button
              icon={<CheckOutlined />}
              disabled={personalDashboardStore?.isLoading}
              className="btn-action"
              style={{
                backgroundColor: "rgb(255, 179, 28)",
                borderColor: "rgb(255, 179, 28)",
                color: "white",
              }}
              shape="round"
              onClick={() => form.validateFields().then(() => form.submit())}
            >
              Save
            </Button>
          </Space>
        </Col>
      </Row>
    );
  };

  const defaultParams: DrawerProps = {
    width: screens.xs ? innerWidth : innerWidth - 250,
    placement: "right",
    closable: true,
    forceRender: false,
    destroyOnClose: true,
    title: <ModalHeader />,
    maskStyle: {},
    className: "drawer-form",
    push: {
      distance: 20,
    },
  };

  const toggleDrawer = (toggle: boolean) => {
    form.resetFields();
    onToggle && onToggle(toggle);
    personalDashboardStore?.setPerformancePanelToggle(toggle);
  };

  const onFormLayoutChange = (changedValues: any) => {};

  const getDataType = (values: any) => {
    let data_type: any = [];
    for (let dataType of values.data_type) {
      data_type.push({
        EnumValue: personalDashboardStore?.dataTypeOptions
          ? personalDashboardStore?.dataTypeOptions?.find((i: any) => i.label === dataType)
              ?.enumvalue
            ? personalDashboardStore?.dataTypeOptions?.find((i: any) => i.label === dataType)
                ?.enumvalue
            : personalDashboardStore?.dataTypeOptions?.find((i: any) => i.value === dataType)
                ?.enumvalue
            ? personalDashboardStore?.dataTypeOptions?.find((i: any) => i.value === dataType)
                ?.enumvalue
            : 0
          : 0,
        Key: personalDashboardStore?.dataTypeOptions
          ? personalDashboardStore?.dataTypeOptions?.find((i: any) => i.label === dataType)?.value
            ? personalDashboardStore?.dataTypeOptions?.find((i: any) => i.label === dataType)?.value
            : personalDashboardStore?.dataTypeOptions?.find((i: any) => i.value === dataType)?.value
            ? personalDashboardStore?.dataTypeOptions?.find((i: any) => i.value === dataType)?.value
            : "TotalSales"
          : "TotalSales",
      });
    }

    return data_type;
  };

  const getReportObject = (values: any, XmlSettings: string, data_type: any) => {
    const reportObject: any = {
      XmlSettings: XmlSettings,
      SelectedColumns: null,
      DataType: data_type.EnumValue,
      GridLayoutModified: false,
      GridLayout: null,
      Locked: false,
      isNew: false,
      LastModified: "",
      Modified: false,
      SortOrder: 0,
      Type: 1,
      Key: values?.panel_key ? values?.panel_key : "",
      Name: values?.panel_title ? values?.panel_title : "",
    };
    return reportObject;
  };

  const generateXmlString = (values: any, data_type: any) => {
    let sendObj = {
      report_settings: {
        report_title: values?.panel_title ? values?.panel_title : "",
        gauge_type: personalDashboardStore?.performanceGaugeType
          ? personalDashboardStore?.performanceGaugeType?.find(
              (i: any) => i.label === values?.panel_type
            )?.enumvalue
            ? personalDashboardStore?.performanceGaugeType?.find(
                (i: any) => i.label === values?.panel_type
              )?.enumvalue
            : personalDashboardStore?.performanceGaugeType?.find(
                (i: any) => i.value === values?.panel_type
              )?.enumvalue
            ? personalDashboardStore?.performanceGaugeType?.find(
                (i: any) => i.value === values?.panel_type
              )?.enumvalue
            : 0
          : 0,
        studio_selection: 0,
        studio_no: "",
        studio_group_id: "",
        auto_refresh_rate: 0,
      },
      date_range: {
        value: personalDashboardStore?.dateRangeOptions
          ? personalDashboardStore?.dateRangeOptions?.find(
              (i: any) => i.label === values?.date_range
            )?.enumvalue
            ? personalDashboardStore?.dateRangeOptions?.find(
                (i: any) => i.label === values?.date_range
              )?.enumvalue
            : personalDashboardStore?.dateRangeOptions?.find(
                (i: any) => i.value === values?.date_range
              )?.enumvalue
            ? personalDashboardStore?.dateRangeOptions?.find(
                (i: any) => i.value === values?.date_range
              )?.enumvalue
            : 0
          : 0,
      },
    };
    const objectToXmlString = require("object-to-xml-string");
    const obj2xml = new objectToXmlString();
    return obj2xml.convert(sendObj);
  };

  const generateDataTypeXml = (dataType: any) => {
    let DataType: any = [];
    for (let item of dataType) {
      DataType.push({ data_type: { value: item.EnumValue } });
    }
    const objectToXmlString = require("object-to-xml-string");
    const obj2xml = new objectToXmlString();
    const xml = obj2xml
      .convert({ DataType })
      .replaceAll("<DataType>", "")
      .replaceAll("</DataType>", "");
    return xml;
  };

  const handleSubmit = (values: any) => {
    const data_type = getDataType(values);
    const XmlSettings: string = `<settings version=\"1.0\">${generateXmlString(
      values,
      data_type
    )}${generateDataTypeXml(data_type)}</settings>`;
    const reportObject = getReportObject(values, XmlSettings, data_type);
    personalDashboardStore?.savePerformancePanel(reportObject).then((data: any) => {
      message.success("Performance Panel Saved");
      personalDashboardStore?.addPerformanceData(data.Key);
      setIsDashboardUpdated(true);
      toggleDrawer(false);
    });
  };

  return (
    <>
      <Drawer
        title={
          <Title level={1} style={{ marginBottom: 0 }}>
            Performance Panel
          </Title>
        }
        onClose={() => toggleDrawer(false)}
        visible={personalDashboardStore?.isPerformancePanelToggle}
        {...defaultParams}
      >
        <Form
          onFinish={handleSubmit}
          onValuesChange={onFormLayoutChange}
          form={form}
          initialValues={initialFormValues}
        >
          <Row className="form-group performancePanel" gutter={20}>
            <Form.Item label="panel_key" name="panel_key" hidden={true}>
              <Input id="panel_key" disabled={isLoading} />
            </Form.Item>
            <Col
              xs={24}
              style={{
                marginBottom: 10,
              }}
            >
              <Form.Item
                name="panel_type"
                hasFeedback={isLoading}
                validateStatus={isLoading ? "validating" : undefined}
                rules={[
                  {
                    required: true,
                    message: "Please select panel type!",
                  },
                ]}
              >
                <SelectField
                  label="Panel Type"
                  options={personalDashboardStore?.performanceGaugeType}
                  prefix={<TagOutlined />}
                  disabled={isLoading}
                  showSearch={false}
                  showArrow={false}
                  suffixIcon={<DownOutlined />}
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              style={{
                marginBottom: 10,
              }}
            >
              <Form.Item
                name="panel_title"
                hasFeedback={isLoading}
                validateStatus={isLoading ? "validating" : undefined}
                rules={[
                  {
                    required: true,
                    message: "Please enter panel title!",
                  },
                ]}
              >
                <InputField prefix={<SoundOutlined />} disabled={isLoading} label="Panel Title" />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={12}
              style={{
                marginBottom: 10,
              }}
            >
              <Form.Item
                name="data_type"
                hasFeedback={isLoading}
                validateStatus={isLoading ? "validating" : undefined}
                rules={[
                  {
                    required: true,
                    message: "Please select data type!",
                  },
                ]}
              >
                <SelectField
                  mode="multiple"
                  optionLabelProp="label"
                  label="Data Type"
                  options={personalDashboardStore?.dataTypeOptions}
                  prefix={<TagOutlined />}
                  disabled={isLoading}
                  showSearch={false}
                  showArrow={false}
                  suffixIcon={<DownOutlined />}
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={12}
              style={{
                marginBottom: 10,
              }}
            >
              <Form.Item
                name="date_range"
                hasFeedback={isLoading}
                validateStatus={isLoading ? "validating" : undefined}
                rules={[
                  {
                    required: true,
                    message: "Please select data range!",
                  },
                ]}
              >
                <SelectField
                  label="Date Range"
                  options={personalDashboardStore?.dateRangeOptions}
                  prefix={<TagOutlined />}
                  disabled={isLoading}
                  showSearch={false}
                  showArrow={false}
                  suffixIcon={<DownOutlined />}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="form-group" gutter={20}>
            <Col
              xs={24}
              style={{
                marginBottom: 10,
              }}
            >
              <Title level={5}>Dashboard Settings</Title>
            </Col>
            <Col
              xs={24}
              sm={12}
              style={{
                marginBottom: 10,
              }}
            >
              <Form.Item
                name="auto_refresh_rate"
                hasFeedback={isLoading}
                validateStatus={isLoading ? "validating" : undefined}
              >
                <SelectField
                  label="Auto Refresh"
                  defaultValue={"1 Minute"}
                  options={[
                    {
                      label: "1 Minute",
                      value: "123",
                    },
                    {
                      label: "10 Minutes",
                      value: "001",
                    },
                    {
                      label: "20 Minutes",
                      value: "002",
                    },
                  ]}
                  prefix={<TagOutlined />}
                  disabled={isLoading}
                  showSearch={false}
                  showArrow={false}
                  suffixIcon={<DownOutlined />}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default inject("personalDashboardStore")(observer(PersonalPerformancePanelDrawerView));
