import React from "react";
import { inject, observer } from "mobx-react";
import type { SelectProps } from "antd/lib/select";
import { TagOutlined } from "@ant-design/icons";

import SelectField from "./SelectField";
import type { ScheduleStoreClass } from "stores/ScheduleStore";
import { AppointmentStoreClass } from "stores/AppointmentStore";

interface Props extends SelectProps<any> {
  scheduleStore?: ScheduleStoreClass;
  appointmentStore?: AppointmentStoreClass;
}

const AppointmentStatusSelect: React.FC<Props> = React.forwardRef(
  ({ scheduleStore, appointmentStore, ...props }: Props, _ref): React.ReactElement => {
    const defaultProps: SelectProps<any> = {
      showSearch: true,
      options: appointmentStore?.selectAppointmentList,
      optionFilterProp: "label",
      filterOption: (input, option) => {
        let isFound: number | undefined = option?.label
          ?.toString()
          .toLowerCase()
          .indexOf(input?.toLowerCase());
        return isFound !== undefined && isFound >= 0 ? true : false;
      },
      ...props,
    };

    return (
      <SelectField
        prefix={<TagOutlined />}
        disabled={props?.disabled}
        label="Status"
        showArrow={false}
        suffixIcon={<></>}
        {...defaultProps}
      />
    );
  }
);

export default inject("scheduleStore", "appointmentStore")(observer(AppointmentStatusSelect));
