import React, { useState, useRef } from "react";
import { Row, Col, Select } from "antd";
import classNames from "classnames";
import type { SelectProps } from "antd/lib/select";

import "./Input.scss";

interface Props extends SelectProps<any> {
  label?: string;
  prefix?: React.ReactNode;
  id?: string;
  value?: string | number;
}

const SelectField: React.FC<Props> = React.forwardRef(
  ({ label, prefix, id, value, ...props }: Props, _ref): React.ReactElement => {
    const [focus, setFocus] = useState<boolean>(false);
    const inputRef = useRef<any>();

    const defaultInputProps: SelectProps<any> = {
      bordered: false,
    };

    const newInputProps = {
      ...defaultInputProps,
      ...props,
    };

    const handleBlur = () => {
      setFocus(false);
    };

    const handleFocus = () => {
      setFocus(true);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    };

    const handleSelect = () => {
      if (!props.mode && props.mode != "multiple") {
        setTimeout(() => {
          inputRef.current?.blur();
        }, 100);
      }
    };

    return (
      <Row
        className={classNames(props?.className, {
          "form-control isActive isFocus": true,
          isActive: value === "" || typeof value === "undefined" || value === null ? false : true,
          isFocus: focus,
          isDisabled: props?.disabled,
        })}
      >
        {prefix && (
          <Col className="form-control-label-col" onClick={() => inputRef.current.focus()}>
            {prefix}
          </Col>
        )}
        <Col className="form-control-content-col">
          <div className="form-control-content">
            {label && (
              <label
                htmlFor={id}
                className="form-control-label"
                onClick={() => inputRef.current.focus()}
              >
                {label}
              </label>
            )}

            <Select
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={value}
              open={focus}
              onSelect={() => {
                handleSelect();
              }}
              ref={inputRef}
              dropdownMatchSelectWidth={false}
              {...newInputProps}
            />
          </div>
        </Col>
      </Row>
    );
  }
);

export default SelectField;
