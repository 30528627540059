import { CloseCircleOutlined, EditFilled, SettingTwoTone } from "@ant-design/icons";
import { Card, Col, Dropdown, Empty, Menu, Space, Typography } from "antd";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PersonalDashboardStoreClass } from "stores/PersonalDashboardStore";
import GuageChart from "../Charts/GuageChart";

interface Props {
    personalDashboardStore?: PersonalDashboardStoreClass;
    chartData?: any;
    groupKey?: string;
    handleEditButtonClick?: any;
    handleMenuClick?: any;
}

const { Title } = Typography;

const PerformancePanel: React.FC<Props> = ({ personalDashboardStore, chartData, groupKey, handleEditButtonClick, handleMenuClick }) => {
    const [reportData, setReportData] = useState<any>();

    const generateReportData = async (Key: string) => {
        let p: any = {
            Key: Key
        };
        const report_details = await personalDashboardStore?.getDashboardReport(Key);
        p.Name = report_details.Name;
        personalDashboardStore?.getPerformancePanel(Key).then((data: any) => {
            p.Data = data;
            setReportData(p);
        });
    }

    useEffect(() => {
        generateReportData(chartData);
    }, [chartData, personalDashboardStore?.isSelectToggle]);

    const menu = (
        <Menu
            onClick={handleMenuClick}
        >
            <Menu.Item key="edit" disabled><EditFilled /> Edit</Menu.Item>
            <Menu.Item key="remove"><CloseCircleOutlined /> Remove</Menu.Item>
        </Menu>
    );

    return (
        <Card
            className="chartCard"
            title={
                <Space className="chartCardHead">
                    <span></span>
                    <Title level={5}>{reportData?.Name}</Title>
                    <Dropdown overlay={menu} trigger={['click']}>
                        <a onClick={() => { handleEditButtonClick('performance', groupKey, chartData) }}>
                            <SettingTwoTone />
                        </a>
                    </Dropdown>
                </Space>
            }
        >
            {reportData?.Data ?
                <>
                    {
                        reportData?.Data?.map((guage: any, index: number) => {
                            return (
                                <Col className="guagePanel" key={index}>
                                    <Col className="guagePanelHead">
                                        <Title level={5}>{guage.DataType}</Title>
                                    </Col>
                                    <Col className="guagePanelBody">
                                        <GuageChart chartData={guage} />
                                    </Col>
                                </Col>
                            )
                        })
                    }
                </>
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </Card >
    )
}

export default inject("personalDashboardStore")(observer(PerformancePanel));