import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import * as React from "react";

const DropDown = (props: any) => {
  const menu = (
    <Menu>
      {props.options.map((option: any, optionIndex: number) => (
        <Menu.Item key={optionIndex}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              props.onSelect(option.value);
            }}
          >
            {option.label}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        {props.selectedLabel} <DownOutlined />
      </a>
    </Dropdown>
  );
};

export default DropDown;
