import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import { Button } from "antd";

import ProCard from "@ant-design/pro-card";
import "../QuickAccessPage.scss";
import {
  PrinterOutlined,
  CreditCardOutlined,
  SendOutlined,
  ClockCircleOutlined,
  FileImageOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

import { ClientStoreClass } from "stores/ClientStore";
import { InvoiceStoreClass } from "stores/InvoiceStore";
import { TaskStoreClass } from "stores/TaskStore";
import { SessionStoreClass } from "stores/SessionStore";
import { PhoneCallStoreClass } from "stores/PhoneCallStore";
import QAInvoicePrint from "./QAInvoicePrint";
import QAImageDetailPrint from "../QAImageDetailPrint";

interface Props {
  handleClick: any;
  invoiceStore?: InvoiceStoreClass;
  clientStore?: ClientStoreClass;
  setOpenCalender?: any;
  setManageImagesStatus?: any;
  taskStore?: TaskStoreClass;
  sessionStore?: SessionStoreClass;
  phoneCallStore?: PhoneCallStoreClass;
  setOpenInvoice?: any;
}

const QAInvoiceActionItems: React.FC<Props> = ({
  handleClick,
  invoiceStore,
  clientStore,
  setOpenCalender,
  setManageImagesStatus,
  taskStore,
  sessionStore,
  phoneCallStore,
  setOpenInvoice,
}) => {
  const invoicePrintRef = useRef<HTMLDivElement>(null);
  const imageDetailPrintRef = useRef<HTMLDivElement>(null);

  const [showPrint] = useState(false);

  const handleInvoicePrint = useReactToPrint({
    content: () => invoicePrintRef.current,
    documentTitle: "Invoice" + invoiceStore?.activeInvoice?.InvoiceNumber,
  });

  const handleImageDetailPrint = useReactToPrint({
    content: () => imageDetailPrintRef.current,
    documentTitle: "ImageDetailSheet" + invoiceStore?.activeInvoice?.InvoiceNumber,
  });

  return (
    <ProCard gutter={[8, 8]} style={{ padding: "inherit" }} wrap ghost className="ProCardBtn">
      <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
        <Button block className="ant-btn-default-green" onClick={handleInvoicePrint}>
          <PrinterOutlined />
          <br />
          Print Invoice
        </Button>
      </ProCard>
      {invoiceStore?.activeInvoice?.InvoiceType != "Generic" ? (
        <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
          <Button block className="ant-btn-default-green" onClick={handleImageDetailPrint}>
            <PrinterOutlined />
            <br />
            Print Image Detail sheet
          </Button>
        </ProCard>
      ) : null}
      <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
        <Button
          onClick={() => {
            invoiceStore?.setSelectToggle(true);
           // setOpenInvoice(true);
            invoiceStore?.setDrawerType("payment");
          }}
          block
          className="ant-btn-default-green"
        >
          <CreditCardOutlined />
          <br />
          New Payment
        </Button>
      </ProCard>
      <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
        <Button
          onClick={() => {
            invoiceStore?.setSelectToggle(true);
           // setOpenInvoice(true);
            invoiceStore?.setDrawerType("detail");
          }}
          block
          className="ant-btn-default-green"
        >
          <SendOutlined />
          <br />
          Open Invoice
        </Button>
      </ProCard>
      <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
        <Button
          onClick={() => {
            phoneCallStore?.setSelectToggle(true);
          }}
          block
          className="ant-btn-default-orange"
        >
          <PhoneOutlined />
          <br />
          New Phone Call
        </Button>
      </ProCard>
      <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
        <Button
          onClick={() => {
            taskStore?.setSelectToggle(true);
          }}
          block
          className="ant-btn-default-orange"
        >
          <SendOutlined />
          <br />
          New Task
        </Button>
      </ProCard>
      <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
        <Button
          onClick={() => {
            sessionStore?.setSelectToggle(true);
            setManageImagesStatus(true);
          }}
          block
          className="ant-btn-default-orange"
        >
          <FileImageOutlined />
          <br />
          View Images
        </Button>
      </ProCard>
      <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
        <Button block className="ant-btn-default-orange">
          <ClockCircleOutlined />
          <br />
          Manage Bills
        </Button>
      </ProCard>
      {/* <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
        <Button block className="ant-btn-default-orange">
          <StopOutlined />
          <br />
          No Show
        </Button>
      </ProCard> */}
      {/* <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} className="ProCardBtn">
        <Button
          onClick={() => {
            invoiceStore?.setSelectToggle(true);
            setManageImagesStatus(true);
          }}
          block
          className="ant-btn-default-blue"
        >
          {/* <UploadOutlined /> */}
      {/* <FileImageOutlined />
          <br />
          Manage Images
        </Button>
      </ProCard>

      <ProCard colSpan={{ xs: 24, sm: 12, md: 12, lg: 8, xl: 6 }} ghost className="ProCardBtn">
        <Button
          onClick={() => {
            invoiceStore?.setSelectToggle(true);
            setManageImagesStatus(false);
          }}
          block
          className="ant-btn-default-blue"
        >
          <VideoCameraAddOutlined />
          <br />
          Reschedule Session
        </Button>
      </ProCard> */}
      <ProCard className={`printWrapper ${showPrint ? "show" : "hide"}`}>
        <QAInvoicePrint invoiceDetails={invoiceStore?.activeInvoice} ref={invoicePrintRef} />
        <QAImageDetailPrint
          invoiceDetails={invoiceStore?.activeInvoice}
          clientInfo={clientStore?.clientInfo}
          ref={imageDetailPrintRef}
        />
      </ProCard>
    </ProCard>
  );
};

export default QAInvoiceActionItems;
