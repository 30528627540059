import React, { forwardRef, useEffect, useState } from "react";
import "./Invoice/QAInvoicePrint.scss";
import dayjs from "dayjs";
import { Col, Row, Table, Typography} from "antd";
import { AuthStoreClass } from "stores/AuthStore";
import { inject, observer } from "mobx-react";
import { GalleryStoreClass } from "stores/GalleryStore/GalleryStore";

const { Title, Paragraph } = Typography;

interface Props {
  invoiceDetails?: any;
  clientInfo?: any;
  authStore?: AuthStoreClass;
  galleryStore?: GalleryStoreClass;
}

const QAImageDetailPrint = forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => {
  const { invoiceDetails, clientInfo, authStore, galleryStore } = props;

  const [pageTitle, setPageTitle] = useState("Invoice");
  const [printWord, setPrintWord] = useState("Invoice");
  const [, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (invoiceDetails?.IsUk) {
      setPageTitle("Sales Order");
      setPrintWord("Order");
    }
  }, [invoiceDetails]);

  const _initiateLoad = async () => {
    setIsLoading(true);
    await galleryStore?.fetchSessionImages();
    _getSessionImages();
  };

  const _getSessionImages = () => {
    if (galleryStore?.sessionImages?.length) {
      setImages(galleryStore.sessionImages);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (galleryStore?.selectedSessionKey != invoiceDetails?.SessionKey) {
      galleryStore?.setSelectedSessionKey(invoiceDetails?.SessionKey);
      _initiateLoad();
    } 
    
    if (!images.length) {
      _initiateLoad();
    }
  }, [galleryStore]);

  // print head table config

  const dataSourceHead = [
    {
      key: "1",
      Image: authStore?.companySettings.Logo,
      Address: [
        authStore?.companySettings.CompanyName,
        authStore?.companySettings.CompanyAdd1,
        authStore?.companySettings.CompanyAdd2,
        authStore?.companySettings.CompanyCity,
        authStore?.companySettings.CompanyState,
        authStore?.companySettings.CompanyCountry,
        authStore?.companySettings.CompanyEmail,
      ],
      Title: pageTitle,
    },
  ];

  const columnsHead = [
    {
      title: "Image",
      dataIndex: "Image",
      key: "Image",
      render: (Image: any) => <img src={`${Image}`} alt="" width={150} />,
      width: "10%",
    },
    {
      title: "Address",
      dataIndex: "Address",
      key: "Address",
      render: (Address: any) =>
        Address.map((item: any, index: any) => {
          return <p key={index} style={{ textTransform: 'capitalize' }}>{item}</p>;
        }),
    },
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
      render: (Title: any) => <h3 className="printInvoiceHeadTitle">{Title}</h3>,
    },
  ];

  // client details table config

  //commented cos of ES Lint "not being used warning"
  // const clientData = [
  //   {
  //     clientAddress: "",
  //     invoiceDetails: "",
  //   },
  // ];


  //commented cos of ES Lint Warning of not being
  // const clientColumns = [
  //   {
  //     title: "clientAddress",
  //     dataIndex: "clientAddress",
  //     key: "clientAddress",
  //     render: () => (
  //       <>
  //         {clientInfo.FirstName + " " + clientInfo.LastName} <br />
  //         {clientInfo.AddressLine1} <br />
  //         {clientInfo.AddressLine2}, {clientInfo.AddressCity},{clientInfo.AddressState},{" "}
  //         {clientInfo.AddressPostcode} <br />
  //         {clientInfo.AddressCountry} <br />
  //         <br />
  //         {clientInfo.Email}
  //       </>
  //     ),
  //   },
  //   {
  //     title: "invoiceDetails",
  //     dataIndex: "invoiceDetails",
  //     key: "Address",
  //     render: () => (
  //       <>
  //         {printWord} Number: <strong>{invoiceDetails?.InvoiceNumber}</strong> <br />
  //         {printWord} Date:{" "}
  //         <strong>{dayjs(invoiceDetails?.InvoiceDate).format("MM/DD/YYYY")}</strong> <br />
  //         Session Number: <strong>{invoiceDetails?.SessionNumber}</strong> <br />
  //         Session Date: <strong>
  //           {dayjs(invoiceDetails?.StartDateTime).format("MM/DD/YYYY")}
  //         </strong>{" "}
  //         <br />
  //         Approx. Delivery Date:
  //         <strong>
  //           {dayjs(invoiceDetails?.ApproximateDeliveryDate).format("MM/DD/YYYY")}
  //         </strong>{" "}
  //         <br />
  //         Client No: <strong>{clientInfo.ClientNumber}</strong> <br />
  //         Main Phone: <strong>{clientInfo.PhoneNumber0}</strong> <br />
  //         Work Phone: <strong>{clientInfo.PhoneNumber2}</strong>
  //       </>
  //     ),
  //   },
  // ];

  const getImageUrl = (imageNumber: any) => {
    let url: any;
    url = images.filter(
      (image: any) => image.fileName.toLowerCase().indexOf(imageNumber.trim().toLowerCase()) > -1
    )[0];
    return url?.thumbnailPath;
  };

  return (
    <Row ref={ref} className="printInvoice">
      <Table
        className="printInvoice__Head"
        dataSource={dataSourceHead}
        columns={columnsHead}
        pagination={false}
        showHeader={false}
      />

      <Row className="printInvoiceRow">
        <Title className="printInvoiceTitle" level={5}>
          Client Information
        </Title>
      </Row>
      <Row className="printInvoiceRow clientInfo">
        {/* <Table className="printInvoice__Client" dataSource={clientData} columns={clientColumns} pagination={false} showHeader={false} /> */}
        <Col span={8}>
          <Paragraph className="paragraph">
            {clientInfo?.FirstName + " " + clientInfo?.LastName}
          </Paragraph>
          <Paragraph className="paragraph">{clientInfo?.AddressLine1}</Paragraph>
          <Paragraph className="paragraph">
            {clientInfo?.AddressLine2} {clientInfo?.AddressCity}
          </Paragraph>
          <Paragraph className="paragraph">
            {clientInfo?.AddressState} {clientInfo?.AddressPostcode}
          </Paragraph>
          <Paragraph className="paragraph">{clientInfo?.AddressCountry}</Paragraph>
          <Paragraph className="paragraph">{clientInfo?.Email}</Paragraph>
        </Col>
        <Col span={8}></Col>
        <Col span={8} className="textRight">
          <Paragraph className="paragraph">
            <label>{printWord} Number:</label> <strong>{invoiceDetails?.InvoiceNumber}</strong>
          </Paragraph>
          <Paragraph className="paragraph">
            <label>{printWord} Date:</label>{" "}
            <strong>{dayjs(invoiceDetails?.InvoiceDate).format("MM/DD/YYYY")}</strong>
          </Paragraph>
          <Paragraph className="paragraph">
            <label>{authStore?.companySettings?.JobDescriptorSingular ? authStore?.companySettings?.JobDescriptorSingular : "Session"} Number:</label> <strong>{invoiceDetails?.SessionNumber}</strong>
          </Paragraph>
          <Paragraph className="paragraph">
            <label>{authStore?.companySettings?.JobDescriptorSingular ? authStore?.companySettings?.JobDescriptorSingular : "Session"} Date:</label>{" "}
            <strong>{dayjs(invoiceDetails?.StartDateTime).format("MM/DD/YYYY")}</strong>
          </Paragraph>
          <Paragraph className="paragraph">
            <label>Approx. Delivery Date:</label>
            <strong>{dayjs(invoiceDetails?.ApproximateDeliveryDate).format("MM/DD/YYYY")}</strong>
          </Paragraph>
          <Paragraph className="paragraph">
            <label>Client No:</label> <strong>{clientInfo?.ClientNumber}</strong>
          </Paragraph>
          <Paragraph className="paragraph">
            <label>Main Phone:</label> <strong>{clientInfo?.PhoneNumber0}</strong>
          </Paragraph>
          <Paragraph className="paragraph">
            <label>Work Phone:</label> <strong>{clientInfo?.PhoneNumber1}</strong>
          </Paragraph>
        </Col>
      </Row>

      <Row className="printInvoiceRow">
        <Title className="printInvoiceTitle" level={5}>
          Image Details
        </Title>
      </Row>
      <Row className="printInvoiceRow">
        {invoiceDetails?.InvoiceDetails?.map((item: any) => {
          return (
            <Col span={12} className="printImage" key={item.Key}>
              <Row>
                <Col span={10} className="printImage__Media">
                  {
                    getImageUrl(item.ImageNumber) ?
                      <img
                        src={getImageUrl(item.ImageNumber)}
                        alt="No image"
                        width={130}
                        height={100}
                      />
                      : <Col span={24} className="noImage"><Paragraph className="noMargin">No Image</Paragraph></Col>
                  }

                </Col>
                <Col span={14} className="printImage__Detail">
                  <Title level={5}>Img No: {item.ImageNumber}</Title>
                  <Paragraph>{item.ItemIdentifier}</Paragraph>
                  <Paragraph>{item.Description}</Paragraph>
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
      <Row className="printInvoiceFooter">
        <Paragraph>{String(dayjs().format("ddd, MMMM DD , YYYY hh:mm A"))}</Paragraph>
      </Row>
    </Row>
  );
});

export default inject("authStore", "galleryStore")(observer(QAImageDetailPrint));
