import React from "react";
import { inject, observer } from "mobx-react";
import { Badge } from "antd";
import { Dayjs } from "dayjs";

import type { ScheduleList } from "interfaces/schedule.int";
import type { ScheduleStoreClass } from "stores/ScheduleStore";

import "./CalendarRenderCell.scss";

interface Props {
  date?: Dayjs;
  scheduleStore?: ScheduleStoreClass;
}

const CalendarRenderCell: React.FC<Props> = ({ date, scheduleStore }) => {
  const availableMeeting: Array<ScheduleList> =
    scheduleStore?.getAvailableInDay(date) || [];

  return (
    <>
      {availableMeeting?.length > -1 && (
        <ul className="events">
          {availableMeeting.map((d: ScheduleList, i) => (
            <li key={i}>
              <Badge
                status={d.AppointmentKey ? "success" : "warning"}
                text={d.Description}
              />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default inject("scheduleStore")(observer(CalendarRenderCell));
