import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  ConfigProvider,
  List,
  message,
  Form,
  Modal,
  Button,
  Row,
  Col,
  Drawer,
  Space,
  Typography,
} from "antd";
import ProTable from "@ant-design/pro-table";
import enUS from "antd/lib/locale/en_US";
import { inject, observer } from "mobx-react";
import { ClientStoreClass } from "stores/ClientStore";
import FamilyForm from "../forms/FamilyForm";
import type { FamilyMember } from "interfaces/client.int";
import { DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import type { DrawerProps } from "antd/lib/drawer";
import useWindowDimension from "components/util/Dimension";

const { Title } = Typography;

interface Props {
  clientStore?: ClientStoreClass;
}

const FamilyList: React.FC<Props> = ({ clientStore }) => {
  const [formType, setFormType] = useState<"Add" | "Edit">("Edit");
  const [showFamilyDrawer, setShowFamilyDrawer] = useState(false);
  const [selectedFamilyMember, setSelectedFamilyMember] = useState<FamilyMember>({});
  const [deleteFamilyModal, setDeleteFamilyModal] = useState(false);
  const [familyForm] = Form.useForm();
  const { innerWidth } = useWindowDimension();

  //to bring up family list on tab click
  useEffect(() => {
    clientStore?.getFamilyMembers(clientStore?.activeClientKey);
  }, [clientStore?.activeClientKey]);

  const handleFamilySubmit = async (values: any) => {
    const { FirstName, LastName, Relationship, DecisionMaker, Favorite } = values;
    const familyData: FamilyMember = {
      FirstName,
      LastName,
      Relationship,
      Birthdate: dayjs(values.Birthdate).format("YYYY-MM-DDTHH:mm:ss"),
      DecisionMaker,
      Favorite,
    };

    const changedData: FamilyMember = {};
    if (formType === "Edit") {
      for (let line in familyData) {
        // Handles case when values[line] = null and taskDetails[line] = ""
        if (!familyData[line] && !selectedFamilyMember[line]) {
          continue;
        }
        if (familyData[line] !== selectedFamilyMember[line]) {
          changedData[line] = familyData[line];
        }
      }
      if (Object.keys(changedData).length === 0) {
        message.warning("No Data Changed");
        return;
      }
    }

    if (!clientStore?.activeClientKey) return;

    await clientStore?.addEditFamilyMember(
      clientStore?.activeClientKey,
      formType === "Edit" ? changedData : familyData,
      formType === "Edit" ? selectedFamilyMember.Key : ""
    );
    setShowFamilyDrawer(false);
    message.success(`Family Member successfully ${formType.toLowerCase()}ed!`);
  };

  const ModalHeader = () => {
    return (
      <Row>
        <Col xs={10}>
          <Title level={2}>Family</Title>
        </Col>
        <Col
          xs={14}
          style={{
            textAlign: "right",
          }}
        >
          <Space>
            {typeof clientStore?.clientInfo !== "undefined" && (
              <Button
                icon={<DeleteOutlined />}
                disabled={clientStore?.isLoading}
                type="text"
                onClick={() => setDeleteFamilyModal(true)}
              >
                Delete
              </Button>
            )}
            <Button
              icon={<CheckOutlined />}
              disabled={clientStore?.isLoading}
              type="primary"
              className="btn-action"
              shape="round"
              onClick={() => familyForm.submit()}
            >
              Save
            </Button>
          </Space>
        </Col>
      </Row>
    );
  };

  const drawerParams: DrawerProps = {
    width: Math.min(innerWidth, 700),
    placement: "right",
    closable: true,
    forceRender: false,
    destroyOnClose: true,
    title: <ModalHeader />,
    maskStyle: {},
    className: "drawer-form",
    push: {
      distance: 20,
    },
  };

  const familyColumns = [
    {
      title: "First Name",
      dataIndex: "FirstName",
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
    },
    {
      title: "Relationship",
      dataIndex: "Relationship",
    },
    {
      title: "Birthday",
      dataIndex: "Birthdate",
      render: (startDate: any) => {
        return dayjs(startDate).format("MM/DD/YYYY");
      },
    },
  ];

  const handleClick = async (item: any) => {
    setShowFamilyDrawer(true);
    setSelectedFamilyMember({ ...item });
    setFormType("Edit");
  };

  return (
    <div style={{ padding: "2rem" }}>
      <Button
        style={{ marginTop: "1rem" }}
        onClick={() => {
          setSelectedFamilyMember({});
          setShowFamilyDrawer(true);
          setFormType("Add");
        }}
      >
        Add Family Member
      </Button>
      <ConfigProvider locale={enUS}>
        <ProTable
          columns={familyColumns}
          className="pro-table"
          search={false}
          dataSource={clientStore?.familyMembers}
          pagination={{
            pageSize: 20,
            showTotal(total, range) {
              return `${range[0]} - ${range[1]} of ${total} items`;
            },
          }}
          onRow={(record, rowIndex) => {
            console.log(record);
            return {
              onClick: (event) => {
                handleClick(record);
              },
            };
          }}
        />
      </ConfigProvider>
      {/* <List
        className="mb-sm"
        style={{
          backgroundColor: "white",
          cursor: "pointer",
          overflowY: "scroll",
          maxHeight: "100%",
        }}
        bordered
        dataSource={clientStore?.familyMembers}
        renderItem={(item: any) => {
          return (
            <List.Item
              onClick={async () => {
                setShowFamilyDrawer(true);
                setSelectedFamilyMember({ ...item });
                setFormType("Edit");
              }}
              actions={[
                <DeleteOutlined
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setSelectedFamilyMember({ ...item });
                    setDeleteFamilyModal(true);
                  }}
                />,
              ]}
            >
              {item.FirstName} {item.LastName}
            </List.Item>
          );
        }}
      /> */}

      <Drawer
        onClose={() => setShowFamilyDrawer(false)}
        visible={showFamilyDrawer}
        {...drawerParams}
      >
        <FamilyForm
          id="familyForm"
          form={familyForm}
          type={formType}
          handleSubmit={handleFamilySubmit}
          data={selectedFamilyMember}
        />
      </Drawer>

      <Modal
        visible={deleteFamilyModal}
        onOk={async () => {
          if (!clientStore?.clientInfo?.Key) return;
          await clientStore?.deleteFamilyMember(
            clientStore?.clientInfo?.Key,
            selectedFamilyMember.Key!
          );
          message.info("Deleted Family Member");
          setDeleteFamilyModal(false);
          setShowFamilyDrawer(false);
        }}
        onCancel={() => setDeleteFamilyModal(false)}
      >
        <p>Are you sure you want to delete this family member?</p>
      </Modal>
    </div>
  );
};

export default inject("clientStore")(observer(FamilyList));
