import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { List, Checkbox, Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";

import type { NotificationList } from "interfaces/notification.int";
import type { NotificationStoreClass } from "stores/NotificationStore";

interface Props {
  item: NotificationList;
  notificationStore?: NotificationStoreClass;
}

const { Text } = Typography;

const CheckboxRenderNotification: React.FC<Props> = ({
  item,
  notificationStore,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    notificationStore?.setSelectedList(item.Key, isChecked ? "add" : "remove");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  const handleClick = () => {
    if (notificationStore?.isDeleteToggleActive) {
      handleChange();
    } else if (item.Url) {
      window.open(item.Url, "_blank");
    }
  };

  return (
    <List.Item onClick={handleClick} style={{ alignItems: "start" }}>
      <List.Item.Meta
        title={item.Header}
        description={
          <>
            {item.Body}
            {item.UrlText && <>- {item.UrlText}</>}
          </>
        }
      />
      {notificationStore?.isDeleteToggleActive ? (
        <div>
          <Checkbox checked={isChecked} onChange={handleChange} />
        </div>
      ) : (
        <>
          {item.Urgent && (
            <Text type="danger">
              <WarningOutlined />
            </Text>
          )}
        </>
      )}
    </List.Item>
  );
};

export default inject("notificationStore")(
  observer(CheckboxRenderNotification)
);
