import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Card, List, Affix, Grid, Skeleton } from "antd";
import classnames from "classnames";

import type { InvoiceList } from "interfaces/invoice.int";
import type { InvoiceStoreClass } from "stores/InvoiceStore";
import SearchInvoice from "components/forms/SearchInvoice";
import RenderInvoice from "components/invoice/RenderInvoice";
import InvoiceDrawerView from "components/drawers/InvoiceDrawerView/InvoiceDrawerView";
import CustomHeader from "components/header/CustomHeader";

const { useBreakpoint } = Grid;

interface Props {
  invoiceStore?: InvoiceStoreClass;
}

const InvoicePage: React.FC<Props> = ({ invoiceStore }) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const screens = useBreakpoint();

  return (
    <>
      <div className="ant-layout-content main-content" ref={setContainer}>
        <CustomHeader title="Process Payment" />
        <div
          className={classnames({
            "main-content-pads": screens.md,
          })}
        >
          <Card
            title={
              <Affix target={() => container} offsetTop={70}>
                <SearchInvoice searchClass="search-listing" />
              </Affix>
            }
            bordered={screens.md}
            className="card-listing"
          >
            <Skeleton loading={invoiceStore?.isLoading} active>
              <List
                dataSource={invoiceStore?.openBalanceInvoiceList}
                renderItem={(item: InvoiceList) => <RenderInvoice openInvoiceDetails={true} item={item} />}
                bordered
                style={{ border: 0 }}
              />
            </Skeleton>
          </Card>
        </div>
      </div>

      <InvoiceDrawerView />
    </>
  );
};

export default inject("invoiceStore")(observer(InvoicePage));
