import React, { useState, useEffect } from "react";

import "./cart-photo-switcher.scss";
import Image from "components/core/image/image";
import { inject, observer } from "mobx-react";
import EventEmitter from "utils/event-emitter";

const CartPhotoSwitcher = (props: any) => {
  const [isVisible, setIsVisible] = useState(false);
  const [item, setItem] = useState<any>(null);
  const [newImage, setNewImage] = useState<any>(null);
  const [currentMode, setCurrentMode] = useState("select");

  useEffect(() => {
    // Register events
    _registerEvents();
  }, []);

  const _registerEvents = () => {
    EventEmitter.listen("switch-item-image", (item: any) => {
      setItem(item);
      setCurrentMode("switch");
      setIsVisible(true);
    });
    EventEmitter.listen("select-item-image", (item: any) => {
      setItem(item);
      setCurrentMode("select");
      setIsVisible(true);
    });
  };

  const _getImageClass = (image: any) => {
    let imageClass = "picture_wrapper selector_image ";
    if (newImage && newImage.fileName === image.fileName) {
      imageClass += "new_image";
    } else if (item?.FileName === image.fileName) {
      imageClass += "old_image";
    }
    return imageClass;
  };

  const _selectImage = (image: any) => {
    if (image.fileName !== item?.FileName) {
      console.log("set new image");
      setNewImage(image);
    }
  };

  const _hideViewer = () => {
    setIsVisible(false);
  };

  const _onSave = () => {
    // // Prepare the data
    if (newImage?.fileName) {
      props.cartStore.updateItem({
        ...item,
        FileName: newImage?.fileName,
      });
    } else {
      _hideViewer();
    }
  };

  if (isVisible) {
    return (
      <>
        <div className="cartPhotoSwitcherOuterWrapper">
          <div className="imageViewerWrapper">
            <div className="masonryGridWrapper">
              <div className="main">
                <div className="main_content">
                  {props.galleryStore.sessionImages.map(
                    (image: any, imageIndex: number) => (
                      <div
                        className={_getImageClass(image)}
                        key={image.thumbnailPath}
                        onClick={(e) => _selectImage(image)}
                      >
                        <Image
                          key={image.thumbnailPath}
                          src={image.thumbnailPath}
                          ratio={
                            image.unformattedData.Height /
                            image.unformattedData.Width
                          }
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="actions" onClick={_hideViewer}>
            <button className="cancelBtn">Cancel</button>
            <button className="saveBtn" onClick={_onSave}>
              Apply
            </button>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default inject("galleryStore", "cartStore")(observer(CartPhotoSwitcher));
