import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import "./ViewReportDrawerView.scss";
import { Grid, Row, Col, Typography, Drawer, Card, Table, Empty } from "antd";
import type { DrawerProps } from "antd/lib/drawer";
import { ReportStoreClass } from "stores/ReportStore";
import { ClientStoreClass } from "stores/ClientStore";
import { AppointmentStoreClass } from "stores/AppointmentStore";
import { UserStoreClass } from "stores/UserStore";
import useWindowDimension from "components/util/Dimension";
import BarChart from "pages/Reports/Charts/BarChart";
import ColumnChart from "pages/Reports/Charts/ColumnChart";
import PieChart from "pages/Reports/Charts/PieChart";
import InvoiceDrawerView from "../InvoiceDrawerView/InvoiceDrawerView";
import { SessionStoreClass } from "stores/SessionStore";
import { InvoiceStoreClass } from "stores/InvoiceStore";
import SessionDrawerForm from "../SessionDrawerForm";
import ClientTabs from "components/tabs/ClientTabs";
const { Title } = Typography;
const { useBreakpoint } = Grid;

interface Props {
    onToggle?: (x: boolean) => void;
    reportStore?: ReportStoreClass;
    clientStore?: ClientStoreClass;
    appointmentStore?: AppointmentStoreClass;
    userStore?: UserStoreClass;
    sessionStore?: SessionStoreClass;
    invoiceStore?: InvoiceStoreClass;
    isCreateReport?: boolean;
    setIsCreateReport?: any;
}


const ViewReportDrawerView: React.FC<Props> = ({ onToggle, reportStore, clientStore, appointmentStore, userStore, sessionStore, invoiceStore, isCreateReport, setIsCreateReport }) => {
    const screens = useBreakpoint();
    const { innerWidth } = useWindowDimension();
    const [firstColumnValue, setFirstColumnValue] = useState('');

    useEffect(() => {
        if (reportStore?.activeReportTable?.Columns) setFirstColumnValue(reportStore?.activeReportTable?.Columns[0]);
        return () => {
            setFirstColumnValue('');
        }
    }, [reportStore?.activeReportTable])


    const ModalHeader = () => {
        return (
            <Row>
                <Col xs={10}>
                    <Title level={2}>View Report</Title>
                </Col>
            </Row>
        );
    };


    const defaultParams: DrawerProps = {
        width: screens.xs ? innerWidth : innerWidth - 250,
        placement: "right",
        closable: true,
        forceRender: true,
        destroyOnClose: true,
        title: <ModalHeader />,
        maskStyle: {},
        className: "drawer-form",
        push: {
            distance: 20,
        },
    };

    const toggleDrawer = (toggle: boolean) => {
        onToggle && onToggle(toggle);
        reportStore?.setViewReportToggle(toggle);
        if (isCreateReport) {
            reportStore?.deleteReport(reportStore?.activeSavedReport.Key);
        }
        setIsCreateReport(toggle);
    };

    const tableColumnsHandler = () => {
        let columnData: any = [
            {
                title: "Field0",
                dataIndex: `Field0`,
                key: "Field0",
                hidden: true
            }
        ];
        if (reportStore?.activeReportTable?.Columns) {
            for (let [i, data] of reportStore?.activeReportTable?.Columns.entries()) {
                columnData.push({
                    title: data,
                    dataIndex: `Field${i + 1}`,
                    key: data
                });
            }
        }
        return columnData;
    }

    const tableDataHandler = () => {
        var dataList: any = reportStore?.activeReportTable?.DataList;
        if (dataList) {
            for (let item of dataList) {
                delete item.MAPADDRESSCITY;
                delete item.MAPADDRESSCLIENTNUMBER;
                delete item.MAPADDRESSCOMPANY;
                delete item.MAPADDRESSCOUNTRY;
                delete item.MAPADDRESSLATITUDE;
                delete item.MAPADDRESSLINE1;
                delete item.MAPADDRESSLINE2;
                delete item.MAPADDRESSLONGITUDE;
                delete item.MAPADDRESSNAME1;
                delete item.MAPADDRESSNAME2;
                delete item.MAPADDRESSPOSTALCODE;
                delete item.MAPADDRESSSTATE;
            }
        }

        var columns: [{ title: string, dataIndex: string, key: string, hidden: boolean }] = tableColumnsHandler();

        //Changing Date Formats
        columns.forEach((column: {
            title: string
            , dataIndex: string
            , key: string
            , hidden: boolean
        }) => {
            if (column.title.includes('Date')
                || column.title.includes('date')
                || column.title.includes('Time')
                || column.title.includes('time')) {
                dataList.forEach((item: any) => {
                    item[column.dataIndex] = new Date(item[column.dataIndex]).toLocaleString('en-Us');;
                });

            }
        });

        return dataList;
    }

    const onRowClickHanlder = async (Key: string) => {
        switch (firstColumnValue) {
            case "Session Number":
                sessionStore?.getSessionDetail(Key);
                sessionStore?.setSelectToggle(true);
                break;
            case "Client Number":
                await clientStore?.getClient(Key);
                clientStore?.setSelectToggle(true);
                break;
            case "Invoice Number":
                await invoiceStore?.getInvoiceDetails(Key);
                invoiceStore?.setDrawerType("detail");
                invoiceStore?.setSelectToggle(true);
                break;
            default:
                break;
        }
    }

    return (
        <>
            <Drawer
                title={
                    <Title level={1} style={{ marginBottom: 0 }}>
                        View Report
                    </Title>
                }
                onClose={() => toggleDrawer(false)}
                visible={reportStore?.isViewReportToggle}
                {...defaultParams}
            >
                <Card className="viewReport">
                    <Row className="viewReportChart">
                        <Col md={10} sm={12} xs={24}>
                            {reportStore?.viewReportData?.ChartData?.Chart1List.length ?
                                <Col className="viewReportChart__full">
                                    {reportStore?.viewReportData?.Report?.chart_type === 'Bar' ?
                                        <BarChart chartData={reportStore?.viewReportData} />
                                        : reportStore?.viewReportData?.Report?.chart_type === 'Column' ?
                                            <ColumnChart chartData={reportStore?.viewReportData} />
                                            : <PieChart chartData={reportStore?.viewReportData} />
                                    }
                                </Col>
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={24} className="viewReportTable">
                            <Table
                                dataSource={tableDataHandler()}
                                columns={tableColumnsHandler()}
                                pagination={false}
                                onRow={(record: any, rowIndex: any) => {
                                    return {
                                        onClick: event => { onRowClickHanlder(record.Field0) }, // click row
                                    };
                                }}
                            />
                        </Col>
                    </Row>
                </Card>
            </Drawer>
            {
                firstColumnValue === "Invoice Number" ? <InvoiceDrawerView /> :
                    firstColumnValue === "Session Number" ? <SessionDrawerForm /> :
                        firstColumnValue === "Client Number" ? <ClientTabs /> :
                            null
            }
        </>
    );
};

export default inject("reportStore", "clientStore", "appointmentStore", "userStore", "sessionStore", "invoiceStore")(observer(ViewReportDrawerView));
