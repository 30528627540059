import React, { useState } from "react";
import "./product-item-viewer.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { observer, inject } from "mobx-react";
import EffectSelector from "components/effect-selector/effect-selector";
import ImageEnhancementsSelector from "components/image-enhancement-selector/image-enhancement-selector";
import ImageOptionsSelector from "components/image-options-selector/image-options-selector";

const ProductItemViewer = (props: any) => {
  const [activeInfoTab, setActiveInfoTab] = useState("description");
  const [itemCount, setItemCount] = useState(1);
  const [colorizationKey, setcolorizationKey] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [selectedEnhancement, setSelectedEnhancement] = useState<any>(null);
  const { browseProducts, selectedProduct, addToCart } = props;
  console.log(props);
  const _renderInfoTabContent = () => {
    switch (activeInfoTab) {
      case "description": {
        return (
          <>
            <p>{selectedProduct.ProductDescription}</p>
          </>
        );
      }
      case "features": {
        return (
          <>
            <p>{selectedProduct.ProductFeatures}</p>
          </>
        );
      }
      case "delivery": {
        return (
          <>
            <p>{selectedProduct.ProductDeliveryInfo}</p>
          </>
        );
      }
    }
  };

  const _decreaseItemCount = () => {
    if (itemCount > 0) {
      setItemCount(itemCount - 1);
    }
  };

  const _increaseItemCount = () => {
    setItemCount(itemCount + 1);
  };

  const _updateItemCount = (count: number) => {
    if (isNaN(count)) {
      setItemCount(0);
    } else {
      setItemCount(count);
    }
  };

  const _getTabClass = (tab: string) => {
    let tabClass = "tabHeader ";
    if (tab === activeInfoTab) {
      tabClass += "active";
    }
    return tabClass;
  };

  const _addToCart = () => {
    addToCart({
      Quantity: itemCount,
      ColorizationKey: colorizationKey,
      OptionKey: selectedOption ? selectedOption.Key : "",
      OptionDescription: selectedOption ? selectedOption.Description : "",
      EnhancementKey: selectedEnhancement ? selectedEnhancement.Key : "",
      EnhancementDescription: selectedEnhancement ? selectedEnhancement.Description : "",
    });
  };

  return (
    <>
      <div className="productItemViewerWrapper">
        <div className="navbar">
          <button className="navBackBtn" onClick={browseProducts}>
            <ArrowLeftOutlined />
          </button>
          <h4>Select Product</h4>
        </div>
        <div className="itemViewer">
          <h2>{selectedProduct.Name}</h2>
          <EffectSelector
            onEffectUpdate={(effectKey: string) => setcolorizationKey(effectKey)}
            value={colorizationKey}
          />
          <ImageOptionsSelector
            onOptionUpdate={(option: any) => setSelectedOption(option)}
            value={selectedOption?.Key}
            allOptions={selectedProduct.Options}
          />
          <ImageEnhancementsSelector
            onEnhancementUpdate={(enhancement: any) => setSelectedEnhancement(enhancement)}
            value={selectedEnhancement?.Key}
            allEnhancements={selectedProduct.Enhancements}
          />
          <p className="itemPrice">{selectedProduct.PriceStringFormatted}</p>
          <div className="itemCountWrapper">
            <div className="itemCountModifier">
              <button className="decBtn" onClick={_decreaseItemCount}>
                -
              </button>
              <input
                type="text"
                className="itemCountInput"
                value={itemCount}
                onChange={(e) => _updateItemCount(parseInt(e.target.value))}
                onKeyPress={(event) =>
                  event.charCode == 8 || event.charCode == 0 || event.charCode == 13
                    ? null
                    : event.charCode >= 48 && event.charCode <= 57
                }
              />
              <button className="incBtn" onClick={_increaseItemCount}>
                +
              </button>
            </div>
            <button className="addToCart" onClick={_addToCart}>
              Add To Cart
            </button>
          </div>
        </div>
        <div className="itemDataViewer">
          <div className="tabHeaders">
            <div
              className={_getTabClass("description")}
              onClick={(e) => setActiveInfoTab("description")}
            >
              <p>Description</p>
            </div>
            <div className={_getTabClass("features")} onClick={(e) => setActiveInfoTab("features")}>
              <p>Features</p>
            </div>
            <div className={_getTabClass("delivery")} onClick={(e) => setActiveInfoTab("delivery")}>
              <p>Delivery</p>
            </div>
          </div>

          <div className="tabContent">{_renderInfoTabContent()}</div>
        </div>
      </div>
    </>
  );
};

export default inject("cartStore")(observer(ProductItemViewer));
