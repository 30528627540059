import { observable, action, makeObservable } from "mobx";
import { create, persist } from "mobx-persist";
import axios, { AxiosResponse, AxiosError } from "axios";
import dayjs, { Dayjs } from "dayjs";
import update from "immutability-helper";
import customParseFormat from "dayjs/plugin/customParseFormat";

import type { Clock, TimeClockEntries } from "interfaces/clock.int";
import AuthStore from "./AuthStore";
import AlertStore from "./AlertStore";

interface ObservedClock {
  start: Dayjs;
  end: Dayjs | undefined;
}

dayjs.extend(customParseFormat);

export class ClockStoreClass {
  @persist("object") clockList: Clock | undefined;
  @persist isClockRunning: boolean = false;
  @persist("object") localObservedClock: ObservedClock | undefined;
  @persist("object") activeRecentTime: TimeClockEntries | undefined;
  clockTimer: Dayjs = dayjs("00:00:00", "HH:mm:ss");
  isLoading: boolean = false;
  isSelectToggle: boolean = false;

  constructor() {
    makeObservable(this, {
      clockList: observable,
      getClockList: action,
      updateClockList: action,
      isLoading: observable,
      isClockRunning: observable,
      clockTimer: observable,
      setClockTimer: action,
      setClockToggle: action,
      localObservedClock: observable,
      clockInOut: action,
      updateCurrentClock: action,
      activeRecentTime: observable,
      setActiveRecentTime: action,
      isSelectToggle: observable,
      setSelectToggle: action,
      deleteClockEntry: action,
    });
  }

  async getClockList(userKey?: string) {
    this.isLoading = true;

    if (!userKey) {
      userKey = AuthStore?.authenticatedUser?.UserKey;
    }

    return await axios({
      method: "GET",
      url: "/TimeClock",
      params: {
        userKey,
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.clockList = res.data;
          this.isClockRunning = res.data.ClockedIn;
          this.isLoading = false;
        })
      )
      .catch((err: AxiosError) => {
        this.clockList = undefined;
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async updateTimeClockEntries(userID: string | undefined, timeClockEntry: TimeClockEntries) {
    this.isLoading = true;
    let userKey = userID;

    if (!userKey) {
      userKey = AuthStore?.authenticatedUser?.UserKey;
    }

    return await axios({
      method: "PUT",
      url: "/TimeClock/Update",
      params: {
        userKey,
      },
      data: timeClockEntry,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async deleteClockEntry(userKey: any, timeClockId?: string) {
    this.isLoading = true;
    return await axios({
      method: "DELETE",
      url: `/TimeClock`,
      params: {
        userKey,
        timeClockId,
      },
    }).then(() => {
      this.isLoading = false;
    });
  }

  setActiveRecentTime(record: TimeClockEntries | undefined) {
    this.activeRecentTime = record;
  }

  setSelectToggle(toggle: boolean) {
    this.isSelectToggle = toggle;
  }

  updateClockList(data: Clock) {
    this.clockList = update(this.clockList, {
      $merge: data,
    });
  }

  setClockToggle(action: boolean = true) {
    this.isClockRunning = action;
    if (action) {
      this.localObservedClock = { start: dayjs(), end: undefined };
    } else {
      this.localObservedClock = update(this.localObservedClock, {
        end: { $set: dayjs() },
      });
    }
    // this.isClockRunning = !this.isClockRunning;
    // if (this.isClockRunning) {
    //   this.localObservedClock = { start: dayjs(), end: undefined };
    // } else {
    //   this.localObservedClock = update(this.localObservedClock, {
    //     end: { $set: dayjs() },
    //   });
    // }
  }

  setClockTimer(time: Dayjs) {
    this.clockTimer = time;
  }

  async clockInOut(data: Clock) {
    this.isLoading = true;

    // const newData = update(data, {
    //   TimeCode: {
    //     $set: this.clockList?.TimeCode || "Regular",
    //   },
    // });

    return await axios({
      method: "PUT",
      url: "/TimeClock",
      data: data,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          if (res.data.ClockedIn) {
            this.clockList = update(this.clockList, {
              ClockedIn: {
                $set: true,
              },
              TotalMinutes: {
                $set: res.data.TotalMinutes,
              },
            });
          } else {
            this.clockList = update(this.clockList, {
              ClockedIn: {
                $set: false,
              },
              TotalMinutes: {
                $set: res.data.TotalMinutes,
              },
              WeekSummary: {
                $merge: res.data.WeekSummary,
              },
            });
          }
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        AlertStore.setAlertMessage(err.response.data, "error");
      });
  }

  async updateCurrentClock(field: "TimeCode" | "Comment", value: string) {
    this.isLoading = true;

    var data = {
      Key: this.clockList?.Key,
      UserKey: AuthStore.authenticatedUser?.UserKey,
      Action: "ClockIn",
      [field]: value,
    };

    return await axios({
      method: "PUT",
      url: "/TimeClock",
      data,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.clockList = update(this.clockList, {
            [field]: {
              $set: res.data[field],
            },
          });
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }
}

const hydrate = create({});

const ClockStore = new ClockStoreClass();

export default ClockStore;

hydrate("clock", ClockStore).then(() => {});
