import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Col, Row, Menu, Card, Modal, message } from "antd";
import type { ListProps } from "antd/lib/list";

import { MessageStoreClass } from "stores/MessageStore";
import {
  CalendarOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import General from "./CompanyPreferences/General/General";
const { Item, SubMenu } = Menu;
interface Props extends ListProps<any> {
  messageStore?: MessageStoreClass;
}

const PreferencesContent: React.FC<Props> = ({ messageStore, ...props }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [current, setCurrent] = useState("company_general");
  let Content = () => {
    switch (current) {
      case "company_general":
        return <General isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />;
      default:
        return null;
    }
  };

  //   const modalContent =

  const onClick = (e: any) => {
    setCurrent(e.key);
  };

  return (
    <>
      <Row style={{ height: "100%" }} className="messagesGrid" gutter={15}>
        <Col xs={24} sm={6}>
          <Menu onClick={onClick} mode="inline">
            <>
              <SubMenu icon={<HomeOutlined />} key="company" title="Company">
                <Item key="company_general">General</Item>
              </SubMenu>
              <Item key="user" icon={<UserOutlined />}>
                User
              </Item>
              <Item key="iby" icon={<ShoppingCartOutlined />}>
                InspiredByYou.com
              </Item>
              <Item key="booking" icon={<CalendarOutlined />}>
                Online Booking
              </Item>
              <Item key="onlineportal" icon={<GlobalOutlined />}>
                Online Portal
              </Item>
            </>
          </Menu>
        </Col>
        <Content />
      </Row>
    </>
  );
};

export default inject("messageStore")(observer(PreferencesContent));
