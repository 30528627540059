import React, { useState, useEffect } from 'react';
import { Pie } from '@ant-design/plots';
import { Card, Space } from 'antd';

interface Props {
    chartData?: any;
}

const BarChart: React.FC<Props> = ({ chartData }) => {
    const [pieChartData, setPieChartData] = useState<any>();
    const [pieChartData2, setPieChartData2] = useState<any>();

    let color: any = [];
    color.push(chartData.Report.chart_color1);
    color.push(chartData.Report.chart_color2);

    useEffect(() => {
        if (chartData.ChartData.Chart1List.length != 0) {
            let chart1List: any = [];
            for (let item of chartData.ChartData.Chart1List) {
                chart1List.push({
                    type: item.Display,
                    value: item.Actual,
                })
            }
            setPieChartData(chart1List);
        }
        if (chartData.ChartData.Chart2List.length != 0) {
            let chart2List: any = [];
            for (let item1 of chartData.ChartData.Chart2List) {
                chart2List.push({
                    type: item1.Display,
                    value: item1.Actual,
                })
            }
            setPieChartData2(chart2List);
        }
    }, [chartData.ChartData]);

    const config = {
        appendPadding: 10,
        data: pieChartData,
        angleField: 'value',
        colorField: 'type',
        radius: 0.8,
        autoFit: true,
        label: {
            type: 'outer',
            content: '{name} {percentage}',
        },
        interactions: [
            {
                type: 'pie-legend-active',
            },
            {
                type: 'element-active',
            },
        ],
    };
    const config2 = {
        appendPadding: 10,
        data: pieChartData2,
        angleField: 'value',
        colorField: 'type',
        radius: 0.8,
        autoFit: true,
        pixelRatio: 2,
        label: {
            type: 'outer',
            content: '{name} {percentage}',
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
    };
    return (
        <>
            <Space className={`pieChartSpace ${!pieChartData2 || !pieChartData ? 'singleChart' : ''}`}>
                {pieChartData ?
                    <Card className='chart1' title='Actual'>
                        <Pie {...config} />
                    </Card>
                    : null}
                {pieChartData2 ?
                    <Card className='chart2' title={chartData?.Report?.data_comparison}>
                        <Pie {...config2} />
                    </Card>
                    : null}
            </Space>
        </>
    )
};

export default BarChart;
