import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Form, Row, Col, Grid, Input, message, Select } from "antd";
import {
  AlignLeftOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  OrderedListOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import type { FormInstance } from "antd/lib/form/hooks/useForm";
import DateField from "./items/DateField";
import InputField from "./items/InputField";
import SelectField from "./items/SelectField";
import { ClockStoreClass } from "stores/ClockStore";
import { TimeClockEntries } from "interfaces/clock.int";
import UsersSelectField from "./items/UsersSelectField";
import { AuthStoreClass } from "stores/AuthStore";
const { useBreakpoint } = Grid;

interface Props {
  form?: FormInstance;
  id?: string;
  onSuccess?: () => void;
  onError?: () => void;
  clockStore?: ClockStoreClass;
  authStore?: AuthStoreClass;
}

const AddEditClock: React.FC<Props> = ({
  form,
  id,
  onSuccess,
  onError,
  clockStore,
  authStore,
  ...props
}): React.ReactElement => {
  const screens = useBreakpoint();
  const isLoading: boolean = clockStore?.isLoading || false;

  const formGrid = (isEnd = false, inForce = true) => {
    const marginVar = inForce ? 10 : "";
    return {
      xs: 24,
      sm: 12,
      style: {
        marginBottom: isEnd ? "" : marginVar,
      },
    };
  };

  const formTimeCode = [
    { label: "Regular", value: "Regular" },
    { label: "Vacation/PTO", value: "Vacation/PTO" },
    { label: "Holiday", value: "Holiday" },
    { label: "Sick", value: "Sick" },
    { label: "Bereavement", value: "Bereavement" },
  ];

  const initialFormValues: TimeClockEntries = {
    UserID: authStore?.authenticatedUser?.UserKey,
    StartTime: "",
    EndTime: "",
    TotalTime: "0",
    TimeCode: "Regular",
    Comment: "",
    Key: "",
    ManualUserID: "",
    ClockOutLatitude: undefined,
    ClockOutLongitude: undefined,
    ClockInLatitude: undefined,
    ManualTimeStamp: "",
    ClockInApplication: "",
    Manual: undefined,
    ClockOutApplication: "",
  };

  useEffect(() => {
    return () => {
      clockStore?.setActiveRecentTime(undefined);
    };
  }, []);

  const onFormLayoutChange = (values: any) => {
    // if (values.TaskTypeKey) setTaskType(values.TaskTypeKey);
  };

  const timeConvert = (n: any) => {
    let num = n;
    let hours = num / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

    if (!rhours) return rminutes + ` minute${rminutes > 1 ? "s" : ""}`;
    if (!rminutes) return rhours + ` hour${rhours > 1 ? "s" : ""}`;
    return (
      rhours + ` hour${rhours > 1 ? "s " : " "}` + rminutes + ` minute${rminutes > 1 ? "s" : ""}`
    );
  };

  useEffect(() => {
    if (typeof clockStore?.activeRecentTime !== "undefined") {
      const d: TimeClockEntries = clockStore?.activeRecentTime;
      form?.setFieldsValue({
        Key: d.Key,
        ManualUserID: d.ManualUserID,
        ClockOutLatitude: d.ClockOutLatitude,
        ClockOutLongitude: d.ClockOutLongitude,
        ClockInLatitude: d.ClockInLatitude,
        UserID: d.UserID,
        ManualTimeStamp: d.ManualTimeStamp,
        ClockInApplication: d.ClockInApplication,
        Manual: d.Manual,
        StartTime: d.StartTime ? dayjs(d.StartTime) : undefined,
        EndTime: d.EndTime ? dayjs(d.EndTime) : undefined,
        TotalTime: d.TotalTime ? timeConvert(d.TotalTime) : undefined,
        TimeCode: d.TimeCode,
        Comment: d.Comment,
        ClockOutApplication: d.ClockOutApplication,
      });
    }
  }, [clockStore?.activeRecentTime]);

  const handleSubmit = async (values: TimeClockEntries) => {
    console.log(values);
    return;
    const {
      Key,
      ManualUserID,
      ClockOutLatitude,
      ClockOutLongitude,
      ClockInLatitude,
      UserID,
      ManualTimeStamp,
      ClockInApplication,
      Manual,
      TimeCode,
      Comment,
      ClockOutApplication,
    } = values;

    values["StartTime"] = dayjs(values["StartTime"]).format("YYYY-MM-DDTHH:mm:ss");
    values["EndTime"] = dayjs(values["EndTime"]).format("YYYY-MM-DDTHH:mm:ss");

    let startTime = values["StartTime"];
    let endTime = values["EndTime"];

    let data: TimeClockEntries = {
      Key: Key,
      ManualUserID: ManualUserID,
      ClockOutLatitude: ClockOutLatitude,
      ClockOutLongitude: ClockOutLongitude,
      ClockInLatitude: ClockInLatitude,
      UserID: UserID,
      ManualTimeStamp: ManualTimeStamp,
      ClockInApplication: ClockInApplication,
      Manual: Manual,
      TimeCode: TimeCode,
      TotalTime: clockStore?.activeRecentTime?.TotalTime,
      EndTime: endTime,
      StartTime: startTime,
      Comment: Comment,
      ClockOutApplication: ClockOutApplication,
    };

    if (clockStore?.activeRecentTime) {
      let changedData: any = {};
      for (let line in data) {
        if (
          data[line as keyof TimeClockEntries] !==
          clockStore?.activeRecentTime[line as keyof TimeClockEntries]
        ) {
          changedData[line] = data[line as keyof TimeClockEntries];
        }
      }
      if (Object.keys(changedData).length === 0) {
        message.warning("No data changed");
        return;
      } else {
        await clockStore
          ?.updateTimeClockEntries(UserID, values)
          .then((data: TimeClockEntries) => {
            message.success("Updated");
            clockStore?.setSelectToggle(false);
            clockStore?.getClockList();
          })
          .catch((error: any) => {
            message.error("Something went wrong!");
          });
      }
    } else {
      alert("Added");
    }
  };

  return (
    <Form
      onFinish={handleSubmit}
      onValuesChange={onFormLayoutChange}
      form={form}
      initialValues={initialFormValues}
    >
      <Form.Item label="Key" name="Key" hidden={true}>
        <Input id="Key" />
      </Form.Item>
      <Form.Item label="ManualUserID" name="ManualUserID" hidden={true}>
        <Input id="ManualUserID" />
      </Form.Item>
      <Form.Item label="Manual" name="Manual" hidden={true}>
        <Input id="Manual" />
      </Form.Item>
      <Form.Item label="ClockOutLatitude" name="ClockOutLatitude" hidden={true}>
        <Input id="ClockOutLatitude" />
      </Form.Item>
      <Form.Item label="ClockOutLongitude" name="ClockOutLongitude" hidden={true}>
        <Input id="ClockOutLongitude" />
      </Form.Item>
      <Form.Item label="ClockInLatitude" name="ClockInLatitude" hidden={true}>
        <Input id="ClockInLatitude" />
      </Form.Item>
      <Form.Item label="ManualTimeStamp" name="ManualTimeStamp" hidden={true}>
        <Input id="ManualTimeStamp" />
      </Form.Item>
      <Form.Item label="ClockInApplication" name="ClockInApplication" hidden={true}>
        <Input id="ClockInApplication" />
      </Form.Item>
      <Form.Item label="ClockOutApplication" name="ClockOutApplication" hidden={true}>
        <Input id="ClockOutApplication" />
      </Form.Item>

      <Row className="form-group" gutter={20}>
        <Col {...formGrid(true)} xs={24}>
          <Form.Item
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
            name="UserID"
          >
            <UsersSelectField
              label="Employee"
              disabled={isLoading}
              prefix={<UsergroupAddOutlined />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="form-group" gutter={20}>
        <Col {...formGrid(false, !screens.sm)}>
          <Form.Item
            name="StartTime"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <DateField
              label="Time In"
              prefix={<CalendarOutlined />}
              disabled={isLoading}
              suffixIcon={<></>}
              allowClear={false}
              placeholder="Select Time In"
              showTime={true}
            />
          </Form.Item>
        </Col>
        <Col {...formGrid(true)}>
          <Form.Item
            name="EndTime"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <DateField
              label="Time Out"
              prefix={<CarryOutOutlined />}
              disabled={isLoading}
              suffixIcon={<></>}
              allowClear={false}
              placeholder="Select Time Out"
              showTime={true}
            />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          style={{
            marginBottom: 10,
          }}
        >
          <Form.Item
            name="TimeCode"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
            rules={[
              {
                required: true,
                message: "Please select Time Code!",
              },
            ]}
          >
            {/* <InputField prefix={<SoundOutlined />} disabled={isLoading} label="Time Code" /> */}
            <SelectField
              options={formTimeCode}
              prefix={<OrderedListOutlined />}
              disabled={isLoading}
              label="Time Code"
              showSearch={false}
              showArrow={false}
              suffixIcon={<></>}
            />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          style={{
            marginBottom: 10,
          }}
        >
          <Form.Item
            name="TotalTime"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <InputField
              prefix={<AlignLeftOutlined />}
              disabled={true}
              label="Total"
              isTextarea={true}
            />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          style={{
            marginBottom: 10,
          }}
        >
          <Form.Item
            name="Comment"
            hasFeedback={isLoading}
            validateStatus={isLoading ? "validating" : undefined}
          >
            <InputField
              prefix={<AlignLeftOutlined />}
              disabled={isLoading}
              label="Comments"
              isTextarea={true}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default inject("clockStore", "authStore")(observer(AddEditClock));
