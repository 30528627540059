import React from 'react';
import { Progress } from '@ant-design/plots';

interface Props {
    chartData?: any;
}

const ProgressChart: React.FC<Props> = ({ chartData }) => {
    const config = {
        height: 40,
        autoFit: false,
        percent: chartData.GaugeAcutalValue / 100,
        barWidthRatio: 0.5,
        color: ['#61daab', '#E8EDF3'],
    };
    return <Progress {...config} />;
}

export default ProgressChart;
