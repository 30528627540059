import { UserOutlined } from "@ant-design/icons";
import { Avatar, List, message } from "antd";
import dayjs from "dayjs";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { AppointmentStoreClass } from "stores/AppointmentStore";
import { ClientStoreClass } from "stores/ClientStore";
import { ScheduleStoreClass } from "stores/ScheduleStore";
import { SessionStoreClass } from "stores/SessionStore";
import { AuthStoreClass } from "stores/AuthStore";
interface Props {
  clientStore: ClientStoreClass;
  setToggleActionItems?: any;
  invoiceStore?: any;
  setToggleSessionActionItems?: any;
  sessionStore?: SessionStoreClass;
  appointmentStore?: AppointmentStoreClass;
  scheduleStore?: ScheduleStoreClass;
  setCurrentTab?: any;
  setToggleInvoiceActionItems?: any;
  setToggleAppointmentActionItems?: any;
  authStore?: AuthStoreClass;
}

const QuickAccessClientList: React.FC<Props> = ({
  clientStore,
  setToggleActionItems,
  invoiceStore,
  sessionStore,
  setToggleSessionActionItems,
  appointmentStore,
  scheduleStore,
  setCurrentTab,
  setToggleInvoiceActionItems,
  setToggleAppointmentActionItems,
  authStore,
}) => {
  let [sessionList, setSessionList] = useState<any>();
  let [upcomingSessions, setUpcomingSessions] = useState<any>();
  let [pastSessions, setPastSessions] = useState<any>();
  let [recentSession, setRecentSession] = useState<any>();

  useEffect(() => {
    if (sessionList) {
      setUpcomingSessions(
        sessionList
          ?.filter((session: any) => {
            return new Date(dayjs(session?.StartDate).format("YYYY-MM-DD")) >= new Date();
          })
          .sort((a: any, b: any) => {
            return dayjs(a.StartDate).diff(dayjs(b.StartDate));
          })
      );
      setPastSessions(
        sessionList?.filter(
          (session: { StartDate: string | number | Date | dayjs.Dayjs | null | undefined }) => {
            let givenDate = dayjs(session?.StartDate).format("YYYY-MM-DD");
            return new Date(givenDate) <= new Date();
          }
        )
      );
    }
  }, [sessionList]);

  useEffect(() => {
    if (sessionList) {
      setRecentSession(upcomingSessions?.length > 0 ? upcomingSessions[0] : pastSessions[0]);
    }
  }, [upcomingSessions, pastSessions]);

  const newFunction = async () => {
    await sessionStore?.getSessionDetail(recentSession?.Key);
    scheduleStore?.setActiveSchedule({
      SessionKey: recentSession?.Key,
    });
    setToggleSessionActionItems(true);
    setCurrentTab(
      authStore?.companySettings?.JobDescriptorPlural
        ? authStore?.companySettings?.JobDescriptorPlural
        : "Client"
    );
    setToggleActionItems(true);
  };

  useEffect(() => {
    if (recentSession) {
      newFunction();
    }
  }, [recentSession]);

  const handleRecentInvoice = async (invoice: any) => {
    setToggleInvoiceActionItems(true);
    setCurrentTab("Invoices");
    setToggleActionItems(true);
  };

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={clientStore?.sortedClientsList}
        renderItem={(item) => (
          <List.Item
            actions={[
              <a
                key="list-loadmore-edit"
                onClick={async () => {
                  await sessionStore?.getSessionList(item.Key).then(async (resp) => {
                    setSessionList(resp);
                    clientStore?.setActiveClientNumber(item.ClientNumber || "");
                    clientStore?.setActiveClientKey(item.Key);
                    setTimeout(async () => {
                      setToggleSessionActionItems(true);
                      setToggleAppointmentActionItems(true);
                      setCurrentTab(
                        authStore?.companySettings?.JobDescriptorPlural
                          ? authStore?.companySettings?.JobDescriptorPlural
                          : "Client"
                      );
                      setToggleActionItems(true);
                      clientStore?.setActiveClientNumber(item.ClientNumber || "");
                      clientStore?.setActiveClientKey(item.Key);
                      await clientStore?.getClient(item.Key);
                      await clientStore?.getOrganization();
                      await appointmentStore?.getAppointmentList(item.Key);
                    }, 20);
                  });
                }}
              >
                {authStore?.companySettings.JobDescriptorSingular
                  ? "Recent " + authStore?.companySettings.JobDescriptorSingular
                  : "Recent Session"}
              </a>,
              <a
                key="list-loadmore-more"
                onClick={async () => {
                  await invoiceStore
                    ?.getInvoiceList({
                      action: "ClientNumber",
                      value: item.ClientNumber,
                    })
                    .then(async (invoices: any) => {
                      if (invoices.length) {
                        await invoiceStore
                          ?.getInvoiceDetails(invoices[0].InvoiceKey)
                          .then(async () => {
                            clientStore?.setActiveClientNumber(item.ClientNumber || "");
                            clientStore?.setActiveClientKey(item.Key);
                            setToggleActionItems(true);
                            setToggleSessionActionItems(true);
                            setToggleInvoiceActionItems(true);
                            setCurrentTab("Invoices");
                            setToggleAppointmentActionItems(true);
                            await clientStore?.getClient(item.Key);
                            await sessionStore?.getSessionList(item.Key);
                            await appointmentStore?.getAppointmentList(item.Key);
                            await clientStore?.getOrganization();
                            handleRecentInvoice(invoices[0]);
                          });
                      } else {
                        message.error("No invoice found");
                      }
                    });
                }}
              >
                Recent Invoice
              </a>,
            ]}
          >
            <List.Item.Meta
              avatar={
                <Avatar size={48} style={{ backgroundColor: "#b7aea2" }} icon={<UserOutlined />} />
              }
              title={
                <>
                  <div
                    onClick={async () => {
                      await clientStore?.getClient(item.Key);
                      clientStore?.setActiveClientNumber(item.ClientNumber || "");
                      clientStore?.setActiveClientKey(item.Key);
                      setCurrentTab("Client");
                      setToggleActionItems(true);
                      setToggleSessionActionItems(false);
                      setToggleInvoiceActionItems(false);
                      setToggleAppointmentActionItems(false);
                      await sessionStore?.getSessionList(item.Key);
                      await appointmentStore?.getAppointmentList(item.Key);
                      await invoiceStore?.getInvoiceList({
                        action: "ClientNumber",
                        value: item.ClientNumber,
                      });
                      await clientStore?.getOrganization();
                    }}
                  >
                    <h4 style={{ float: "none", color: "#b7aea2" }}>
                      {item?.ClientNumber + " - "}
                      {item.FirstName + " " + item.LastName + " "}{" "}
                    </h4>
                  </div>
                </>
              }
              description={
                <>
                  <p style={{ margin: 0 }}>
                    {item.AddressLine1}
                    {item.AddressCity && ", "}
                    {item.AddressCity}
                    {item.AddressState && ", "}
                    {item.AddressState} {item.AddressPostcode} {item.AddressCountry}
                  </p>
                  <p style={{ margin: 0 }}></p>
                </>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default inject(
  "clientStore",
  "invoiceStore",
  "sessionStore",
  "scheduleStore",
  "authStore"
)(observer(QuickAccessClientList));
