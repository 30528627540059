import React, { useState, useEffect, useCallback } from "react";
import { inject, observer } from "mobx-react";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Row, Col, Button, Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import classnames from "classnames";

import type { ScheduleStoreClass } from "stores/ScheduleStore";
import Calendar from "../util/Calendar";
import CalendarNavigation from "./CalendarNavigation";

import "./CalendarYearFull.scss";

dayjs.extend(customParseFormat);

interface Props {
  onToggleCalendar?: () => void;
  scheduleStore?: ScheduleStoreClass;
}

const { Title } = Typography;

const CalendarYearFull: React.FC<Props> = ({
  onToggleCalendar,
  scheduleStore,
}: Props): React.ReactElement => {
  const [activeMonth, setActiveMonth] = useState<Dayjs>(dayjs());
  const [months, setMonths] = useState<Dayjs[] | null>();

  useEffect(() => {
    const selectedDate = scheduleStore?.calendarActiveDate || activeMonth;
    const monthArray = Array.from({ length: 12 }, (_, i) => {
      return selectedDate
        .set("month", i)
        .set("year", Number(activeMonth.format("YYYY")));
    });

    setMonths(monthArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMonth, scheduleStore?.calendarActiveDate]);

  useEffect(() => {
    setActiveMonth(scheduleStore?.calendarActiveDate || dayjs());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleStore?.calendarActiveDate]);

  const Content = useCallback(
    ({ day }: { day: Dayjs }) => {
      const handleSelect = (date: Dayjs) => {
        scheduleStore?.setCalendarActiveDate(date);
        scheduleStore?.setActiveDate(date.format());
        scheduleStore?.dateSelectCallback(date);
        onToggleCalendar && onToggleCalendar();
      };

      return (
        <>
          <Title level={4}>{day.format("MMMM")}</Title>
          <Calendar
            headerRender={() => <></>}
            fullscreen={false}
            defaultValue={day}
            dateFullCellRender={(date) => <span>{date.format("D")}</span>}
            onSelect={handleSelect}
          />
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onToggleCalendar]
  );

  const handleNavigation = (type: "prev" | "next") => {
    if (type === "prev") {
      setActiveMonth(activeMonth.subtract(1, "year"));
    } else {
      setActiveMonth(activeMonth.add(1, "year"));
    }
  };

  const CalendarPaginate = () => {
    return (
      <Row align="middle" className="calendar-year-nav">
        <Col xs={18}>
          <Row align="middle">
            <Col>
              <CalendarNavigation onClick={(type) => handleNavigation(type)} />
            </Col>
            <Col style={{ paddingLeft: 34 }}>
              <Title className="page-header-title">
                {activeMonth.format("YYYY")}
              </Title>
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Button
            icon={<CloseOutlined />}
            type="text"
            className="no-label"
            onClick={() => onToggleCalendar && onToggleCalendar()}
            style={{
              marginLeft: "auto",
            }}
          />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <CalendarPaginate />
      <div className="calendar-year-full">
        <Row>
          {months?.map((day) => {
            return (
              <Col
                xs={8}
                lg={6}
                className={classnames("calendar-year-full-month", {
                  active: day.format("MMYYYY") === activeMonth.format("MMYYYY"),
                })}
                key={day.format("MMYYYY")}
              >
                <Content day={day} />
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
};

export default inject("scheduleStore")(observer(CalendarYearFull));
