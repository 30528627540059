import React, { useEffect } from "react";

import { Drawer, DrawerProps, message } from "antd";
import CalendarDesktop from "components/schedule/CalendarDesktop";
import useWindowDimension from "components/util/Dimension";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import ActionsSchedule from "components/schedule/actions/ActionsSchedule";

interface Props {
  openCalender?: any;
  setOpenCalender?: any;
}

const QuickAccessAddEditSession: React.FC<Props> = ({ openCalender, setOpenCalender }) => {
  const screens = useBreakpoint();
  const { innerWidth } = useWindowDimension();

  const drawerParams: DrawerProps = {
    width: screens.xs ? innerWidth : innerWidth - 250,
    placement: "right",
    closable: true,
    forceRender: false,
    destroyOnClose: true,
    title: <ActionsSchedule drawer={true} />,
    maskStyle: {},
    className: "qa-calender",
    push: {
      distance: 20,
    },
  };

  useEffect(() => {
    if (openCalender) message.info("Please pick a time slot for your session");
  }, [openCalender]);

  return (
    <>
      <Drawer
        placement="right"
        onClose={() => {
          setOpenCalender(false);
        }}
        visible={openCalender}
        {...drawerParams}
      >
        <CalendarDesktop openCalender={openCalender} />
      </Drawer>
    </>
  );
};

export default QuickAccessAddEditSession;
