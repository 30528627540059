import { observable, action, computed, makeObservable } from "mobx";
import { create, persist } from "mobx-persist";
import axios, { AxiosResponse, AxiosError } from "axios";

import ScheduleStore from "./ScheduleStore";
import ClientStore from "./ClientStore";
import type {
  AppointmentList,
  AppointmentDetail,
  AppointmentTypeList,
  AppointmentStatusList,
} from "interfaces/appointment.int";
import { saveLocalStorage } from "components/util/localStorage";

export class AppointmentStoreClass {
  @persist("list") appointmentList: Array<AppointmentList> | undefined;
  @persist("list")
  appointmentTypesList: Array<AppointmentTypeList> = [];
  isSelectToggle: boolean = false;
  isLoading: boolean = false;
  appointmentStatusesList: Array<AppointmentStatusList> = [];
  unlinkedAppointment: boolean = false;

  constructor() {
    makeObservable(this, {
      init: action,
      cleanUp: action,
      isLoading: observable,
      appointmentList: observable,
      unlinkedAppointment: observable,
      getAppointmentList: action,
      getAppointmentTypes: action,
      getAppointmentStatuses: action,
      appointmentTypesList: observable,
      appointmentStatusesList: observable,
      selectAppointmentList: computed,
      addAppointment: action,
      getAppointmentDetail: action,
      getAppointmentsFromScheduleList: action,
      isSelectToggle: observable,
      setSelectToggle: action,
      setUnlinkedAppointment: action,
      setLoading: action,
      sortedAppointmentList: computed,
    });
  }

  async init() {
    await this.getAppointmentTypes();
    await this.getAppointmentStatuses();
  }

  cleanUp() {
    this.appointmentTypesList = [];
    this.appointmentStatusesList = [];
  }

  setSelectToggle(toggle: boolean) {
    this.isSelectToggle = toggle;
  }

  async getAppointmentList(clientKey: string | null | undefined) {
    //00-000-10327
    if (clientKey === null || clientKey === undefined) return;

    this.setLoading(true);

    return await axios({
      method: "GET",
      url: `/Appointments`,
      params: {
        clientKey,
      },
    })
      .then(
        action((res: AxiosResponse) => {
          this.appointmentList = res.data;
          this.setLoading(false);
          saveLocalStorage({
            name: "Synced",
            value: `Appointments`,
          });
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.setLoading(false);
        throw new Error(err.message);
      });
  }

  async getAppointmentDetail(id: string | null | undefined) {
    //00-000-10043
    if (id === null || id === undefined) return;
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/Appointment/${id}`,
    })
      .then(
        action((res: AxiosResponse) => {
          ScheduleStore.activeScheduleDetail = res.data;
          ClientStore.setActiveClientKey(res.data.ClientKey);
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async addAppointment(appointment: AppointmentDetail) {
    this.isLoading = true;
    return await axios({
      method: "PUT",
      url: "/Appointment",
      data: appointment,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          return res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async deleteSchedule(key?: string, type?: "Appointment" | "Session") {
    if (!key || !type) return;

    this.isLoading = true;

    return await axios({
      method: "DELETE",
      url: `/${type}/${key}`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  async getAppointmentTypes() {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/AppointmentType`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.appointmentTypesList = res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }
  async getAppointmentStatuses() {
    this.isLoading = true;
    return await axios({
      method: "GET",
      url: `/AppointmentStatus`,
    })
      .then(
        action((res: AxiosResponse) => {
          this.isLoading = false;
          this.appointmentStatusesList = res.data;
        })
      )
      .catch((err: AxiosError) => {
        this.isLoading = false;
        throw new Error(err.message);
      });
  }

  getAppointmentTypeByKey(key: string) {
    return this.appointmentTypesList?.find((v: AppointmentTypeList) => {
      return v.Key === key;
    });
  }

  getAppointmentTypeFromList(key: string, field?: string): AppointmentTypeList | string {
    let filtered: any = this.appointmentTypesList?.filter(
      (v: AppointmentTypeList) => key === v.Key
    )[0];
    let mapped = field ? filtered[field] : filtered;

    return mapped;
  }

  get sortedAppointmentList() {
    const sortedList = this.appointmentList;
    return sortedList
      ?.slice()
      .sort((a: any, b: any) => Date.parse(b.StartDate) - Date.parse(a.StartDate));
  }

  get selectAppointmentList() {
    return this.appointmentTypesList
      .map((v: AppointmentTypeList) => {
        return {
          label: v.Description,
          value: v.Key,
        };
      })
      .sort((a, b) => {
        var nameA = a?.label?.toUpperCase();
        var nameB = b?.label?.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }
  setUnlinkedAppointment(unlinked: boolean) {
    this.unlinkedAppointment = unlinked;
  }

  async getAppointmentsFromScheduleList(clientKey: string) {
    const appointmentKeys: Array<string | undefined> = [];
    await this.getAppointmentList(clientKey).then((res: Array<AppointmentList>) => {
      res.map((r) => {
        appointmentKeys.push(r.Key);
        return true;
      });
    });
    if (ScheduleStore?.scheduleList.length <= 0) {
      await ScheduleStore?.getCalendarList(
        ScheduleStore.filterUserKey,
        ScheduleStore.filterResourceKey
      );
    }

    return ScheduleStore?.scheduleList
      .filter((item) => appointmentKeys.includes(item?.AppointmentKey))
      .sort((a, b) => {
        if (a.StartDateTime && b.StartDateTime) {
          if (a.StartDateTime < b.StartDateTime) {
            return -1;
          }
          if (a.StartDateTime > b.StartDateTime) {
            return 1;
          }
        }
        return 0;
      });
  }
}

const hydrate = create({});

const AppointmentStore = new AppointmentStoreClass();

export default AppointmentStore;

hydrate("appointment", AppointmentStore).then(() => {});
